import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    width: props => props.width,
  },
  input: {
    display: 'none',
  },
  lookLikeInput: {
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : 'transparent',
    border: props => props.border ? props.border : '1px solid #e7e7ed',
    boxShadow: 'none',
    borderRadius: '4px',
    width: '100%',
    padding: '6px 0',

    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }
  },
  label: {
    fontSize: props => props.fontSize ? props.fontSize : null,
    fontWeight: props => props.fontWeight ? props.fontWeight : null,
    color: props => props.color ? props.color : null,
    fontFamily: props => props.fontFamily ? props.fontFamily : null,
    textIndent: '0.4rem',
    maxWidth: '97%',
    overflow: 'hidden',
    height: '25px',
    margin: 'auto',
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    whiteSpace: "nowrap",
  },
  error: {
    color: '#DA1414',
    fontSize: '12px',
    fontWeight: '400',
    marginTop: '3px'
  }
}));

export default function FileInput(props) {
  const classes = useStyles(props);
  const allowMultiple = props.multiple || false
  return (
    <div className={classes.root}>
      <span>
        <strong className="label-input">{props.label}</strong>
      </span>
      {props.subtitle && <span><small>{props.subtitle}</small></span>}
      <div style={{display: 'flex', alignItems: 'center', width: "100%"}}>
        <input
          className={classes.input}
          disabled={props.disabled}
          id={props.id}
          type="file"
          accept={props.accept || ".jpg, .jpeg, .png, .pdf"}
          onChange={props.onChange}
          data-test={props.dataTest}
          multiple= {allowMultiple}
        />
        <label htmlFor={props.id} style={{margin: '3px 0 0 0', width: '100%', maxWidth: "60vh"}}>
          <Button
            onBlur={props.onBlur}
            onFocus={props.onBlur}
            disabled={props.disabled}
            variant="contained"
            component="span"
            classes={{
              contained:classes.lookLikeInput,
              label: classes.label
            }}
            >
             {props.value ? props.value : <span className="light-grey-text">Selecione um documento</span>}
          </Button>
        </label>
      </div>
      <span>
        <strong className={classes.error}>{props.error ? props.error : null}</strong>
      </span>
    </div>
  );
}
