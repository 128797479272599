import React, { useState, useEffect } from 'react'

import Topic from "../../../../common/topic";
import Select from "../../../../common/select";
import Input from '../../../../common/input'
import SearchBar from '../../../../common/searchBar/searchBar';
import empty from "../../../../common/validators/empty";
import {districts} from '../../../../assets/districts';

import * as professionService from '../../../../services/professions'

export default (props) => {

  const [ listProfessions, setListProfessions ] = useState([])
  const [ listProfessionalCouncils, setListProfessionalCouncils ] = useState([])

  const loadProfessions = async () => {
    const professions = await professionService.getProfessions()
    
    if(!professions.error) {
      setListProfessions(professions)
    } else {

    }
  }

  const loadProfessionalCouncils = async () => {
    const professionalCouncils = await professionService.getAllProfessionalCouncil()

    if(!professionalCouncils.error) {
      setListProfessionalCouncils(professionalCouncils)
    } else {

    }
  }

  const setProfession = (professionId) => {
    props.setProfession(professionId)
    const selected = listProfessions.find((profession) => {
      return profession.id === professionId
    })
    props.setSpecialityId(selected?.speciality?.id)
    props.setSpecialityName(selected?.speciality?.name)
  }

  useEffect(() => {
    loadProfessions()
    loadProfessionalCouncils()
  }, [])
  
  return (
    <>
      <Topic
        classesNameCol="s12 m11 offset-l1 topic-datas middle-topics"
        title="Profissão"
        subtitle="Inclua os dados de sua profissão."
      />
      <div style={{marginTop: '12px'}}>
        <Select 
          width="24%"
          label="Profissão"
          value={props.profession}
          onChange={setProfession}
          onBlur={props.setProfessionError}
          error={props.professionError.typeError}
          itemList={
            listProfessions.map((profession, index) => {
              return ({ value: profession.id, exhibit: profession.profession })
            })
          }
          dataTest="select-pf-modalidade"
        />
      </div>

      <div className='file-input' style={{marginTop: '12px'}}>
        <Select 
          width="200px"
          label="Conselho Profissional"
          value={props.professionalCouncil}
          onChange={props.setProfessionalCouncil}
          onBlur={props.setProfessionalCouncilError}
          error={props.professionalCouncilError.typeError}
          itemList={
            listProfessionalCouncils.map((professionalCouncil, index) => {
              return ({ value: professionalCouncil.id, exhibit: professionalCouncil.name })
            })
          }
          dataTest="select-pf-modalidade"
        />
        <SearchBar
          sync
          placeholder="SP"
          width="70px"
          margin="20px 10px 0px 0px"
          value={props.uf}
          onChange={props.setUf}
          onBlur={() => props.setUfError(empty(props.uf))}
          error={props.ufError.typeError}
          itemList={districts}
          iconDefault
          dataTest="input-pf-uf"
        />
      </div>

      <Input
          width="20%"
          label="Documento Profissional"
          placeholder="Documento Profissional"
          type="text"
          value={props.professionalDocumentNumber}
          onChange={(e) => props.setProfessionalDocumentNumber(e.target.value)}
          onBlur={() => props.setProfessionalDocumentNumberError(empty(props.professionalDocumentNumber))}
          error={props.professionalDocumentNumberError.typeError}
          dataTest="input-pf-crm"
        >
        </Input>
    </>
  )
}