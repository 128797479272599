import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
// import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TimePicker from '../timePicker';

const useStyles = makeStyles((theme) => ({
  root: {
        width: '100%',
        padding: '0'
  },
  item: {
        height: '40px',
        width: '75%',
        maxWidth: '320px',
        backgroundColor: "#FFFFFF",
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px',
        padding: "0px !important"
  },
  icons: {
    display: 'flex',
    height: '100%',
  },
  icon: {
      width: '80px',
  },
  replicate: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '20px 0 0 0',
  },
  checkbox: {
    padding: "0 !important",
  },
  checked: {
    color: '#DF9000 !important',
  },
  error: {
    fontSize: '12px',
    color: "#DA1414",
    paddingTop: '10px',
  }
}));

const ListDisplay = ({setTime, selectItems, workTimes, ...props}) => {

  const classes = useStyles();

  return (
    <List className={classes.root}>
      {selectItems.map((value, index) => {
        const labelId = `checkbox-list-label-${value}`;
        return (
          <div key={labelId}>
            <ListItem className={classes.item} >
              <div>
                <ListItemText id={labelId} primary={value} />
              </div>
              <div className={classes.icons}>
                <TimePicker
                  margin="0 10px"
                  setTime={(value) => setTime("beginShift",value, index)}
                  selectedTime={workTimes[index].beginShift}
                  disabled={props.checked}
                />
                <TimePicker 
                  setTime={(value) => setTime("endShift",value, index)}
                  selectedTime={workTimes[index].endShift}
                  disabled={props.checked}
                />
              </div>
            </ListItem>
            {/* {index === 0 && selectItems.length > 1 ?
              <div className={classes.replicate}>
                <Checkbox 
                  onChange={() => props.onCheck(index)}
                  checked={props.checked}
                  classes={{
                    root: classes.checkbox,
                    checked: classes.checked
                  }}
                />
                <span>Replicar horário para todos os outros dias.</span>
              </div>
              :
              null
            } */}
          </div>
        );
      })}
      {props.error && <span className={classes.error}>{props.error}</span>}
    </List>
  );
}

export default ListDisplay;
