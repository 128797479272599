import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector, useDispatch } from 'react-redux';

import * as AsyncActions from '../store/actions/async.action';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbars(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [writtenError, setError] = React.useState("");
    const error = useSelector( (state) => state.AsyncReducer.error);

    useEffect(() => {
      if(error) {
        setOpen(Boolean(error));
        setError(error);
      };
    }, [error])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        dispatch(AsyncActions.cleanError());
        return;
        }
        dispatch(AsyncActions.cleanError());
        setOpen(false);
    };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={15000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {writtenError}
        </Alert>
      </Snackbar>
    </div>
  );
}