import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import Divider from "@material-ui/core/Divider";

import Button from "../../../common/button/index";
import Loading from "../../../common/loading";
import ErrorSnack from "../../../common/snackbar";

import * as asyncActions from "../../../common/store/actions/async.action";
import graphqlApi from "../../../services/api";

import "../style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F5F5F5",
    margin: "0",
    width: "100vw",
    height: "calc(100% - 70px - 4vh)",
    paddingTop: "4vh",
    overflow: "hidden",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    width: "82%",
    margin: "auto",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  content: {
    padding: "36px",
    height: "70vh",
  },
  title: {
    fontSize: "20px",
    color: "#272833",
    fontWeight: "600",
    marginBottom: "15px",
  },
  text: {
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: "normal",
  },
  box: {
    overflow: "auto",
    height: "85%",
    margin: "25px 0",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CDCED9",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  buttonUp: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    bottom: "2vh",
  },
  icon: {
    backgroundColor: "#F9B233",
    color: "#FFFFFF",
  },
  buttons: {
    width: "82%",
    margin: "30px auto",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const getTerms = () => {
  return graphqlApi
    .post("/", {
      query: `
        query {
          getTerms(termType: SERVICE)
        }
      `,
    })
    .then((r) => {
      const result = r.data;
      if (result.errors) {
        throw new Error("Erro ao buscar os termos");
      }

      return result.data.getTerms;
    })
    .catch((error) => {
      throw new Error("Erro ao buscar os termos");
    });
};

export default function FinalPage(props) {
  const classes = useStyles();
  const boxRef = useRef(null);
  const [scrollTarget, setScrollTarget] = useState(undefined);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.AsyncReducer.loading);
  const error = useSelector((state) => state.AsyncReducer.error);

  const [term, setTerm] = useState("");
  const [termError, setTermError] = useState("");

  const trigger = useScrollTrigger({
    target: scrollTarget,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#text-start"
    );
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    setScrollTarget(boxRef.current);

    setTermError("");
    setTerm("");
    getTerms()
      .then(setTerm)
      .catch(() => setTerm("Erro ao buscar os termos"));

    return () => dispatch(asyncActions.cleanError());
  }, [scrollTarget, dispatch]);

  // ------ Sending data to API
  const personal = useSelector(
    (state) => state.PersonalDatas.finalRegister[0].pf.personalDatas
  );
  const personalData = JSON.stringify({
    name: personal.name,
    phone: personal.telephone,
  });

  let profession
  let professionalCouncil
  let rqeAttachment1;
  let rqeAttachment2;
  let speciality2AlreadyInArray
  if(props.professionalType === 'doctor') {
    profession = {
      profession: personal.profession,
      crm: personal.crm,
      uf: personal.uf.name,
      specialities: [
        {
          id: personal.specialty1.id,
          name: personal.specialty1.name,
          group: personal.specialty1.group,
          rqe: personal.rqe1 ? personal.rqe1 : null,
        },
      ],
    };

    rqeAttachment1 = personal.specialty1Titles;
    rqeAttachment2 = personal.specialty2Titles;
    speciality2AlreadyInArray = profession.specialities.some(speciality => personal.specialty2.id === speciality.id)
    if (personal.specialty2.name) {
      !speciality2AlreadyInArray && profession.specialities.push({
        id: personal.specialty2.id,
        name: personal.specialty2.name,
        group: personal.specialty2.group,
        rqe: personal.rqe2 ? personal.rqe2 : null,
      });
    }

    if (personal.generalPractitioner.selected) {
      const generalPractitionerAllreadyInArray = profession.specialities.some(speciality => personal.generalPractitioner.id === speciality.id)
      !generalPractitionerAllreadyInArray && profession.specialities.push({
        id: personal.generalPractitioner.id,
        name: personal.generalPractitioner.name,
        group: null,
        rqe: null,
      });
    }
      
  } else if(props.professionalType === 'others') {
    profession = {
      id: personal.profession,
      professionalCouncilId: personal.professionalCouncil,
      uf: personal.uf.name,
      professionalDocument: personal.professionalDocumentNumber,
    }
  }

  profession = JSON.stringify(profession);

  const careModality = personal.supportType;
  const professionalId = personal.professionalDocument;
  const proofResidence = personal.proofOfResidence;
  const certificate = personal.certificate;

  const bankData = JSON.stringify({
    bank: personal.bank.id,
    agency: personal.agency,
    account: personal.account,
  });
  const paymentOptions = JSON.stringify({
    numberOfInstallments: personal.numberPlots,
    minimumInstallmentAmount: +parseFloat(personal.minAmount).toFixed(2),
  });
  const onlyFilledPlans = personal.healthInsurances
    .filter((healthInsurance) => healthInsurance.healthOperators.name !== "")
    .map((healthInsurance) => {
      return { id: healthInsurance.healthOperators.plans[0].id };
    });
  const plans = JSON.stringify(onlyFilledPlans);
  const terms = personal.consentForm;

  const cv = useSelector(
    (state) => state.PersonalDatas.finalRegister[0].pf.curriculum
  );
  const photo = cv.photo;
  const curriculum = JSON.stringify({
    aboutMe: cv.about,
    experience: cv.experience,
    treatMedicalConditions: cv.medicalConditions,
    background: cv.formation,
    lattes: cv.linkCurriculumLattes,
  });
  let addressExams = []
  const add = useSelector((state) => state.Address.addresses);
  const halfAddresses = add.map((item) => {
    const {
      cnes,
      country,
      zipCode,
      address,
      number,
      complement,
      neighborhood,
      state,
      city,
      telephone,
      whatsapp,
      consultations,
      exams,
      // proceedings,
      otherServices,
      examsSpreadsheet
    } = item;
    const district = state;
    const phone = telephone;
    const adaptConsultations = consultations.map((consult) => {
      return { specialityId: consult.speciality.id, price: consult.price };
    });
    const adaptOtherServices = otherServices.map((other) => {
      return { specialityId: other.speciality.id, price: other.value}
    })
    

    const adaptExams = exams.map((exam) => {
      return { serviceProvidedId: exam.examId,
               preparation: exam.preparation,
               price: exam.price}
    })
    
    let medicalAppointment
    let professionalHasSpeciality
    let  unitServicesProvided
    if(props.professionalType === 'doctor') {
      medicalAppointment = { doctorHasSpecialityIdInAddres: [...adaptConsultations, ...adaptOtherServices] };
      unitServicesProvided =  [...adaptExams]
    } else if(props.professionalType === 'others') {
      professionalHasSpeciality = { professionalHasSpecialityInAddres: [...adaptConsultations, ...adaptOtherServices] };
    }
    addressExams =[...addressExams, examsSpreadsheet]

    return {
      cnes,
      country,
      zipCode,
      address,
      number,
      complement,
      neighborhood,
      district,
      city,
      phone,
      whatsapp,
      medicalAppointment,
      unitServicesProvided,
      professionalHasSpeciality,
      addressExams
    };
  });
  const addresses = JSON.stringify(halfAddresses);

  const data = new FormData();

  data.append("terms", terms);
  data.append("professionalId", professionalId);
  data.append("proofResidence", proofResidence);
  data.append("certificate", certificate);
  
  // if(props.professionalType === 'doctor'){
    if (rqeAttachment1?.name) {
      data.append("rqeAttachment1", rqeAttachment1);
    }
    if (personal?.specialty2?.name && !speciality2AlreadyInArray) {
      data.append("rqeAttachment2", rqeAttachment2);
    }
  // }
 
  data.append("photo", photo);
  data.append("personalData", personalData);
  data.append("profession", profession);
  data.append("professionalCouncil", professionalCouncil)
  data.append("careModality", careModality);
  data.append("bankData", bankData);
  data.append("paymentOptions", paymentOptions);
  data.append("plans", plans);
  data.append("curriculum", curriculum);
  data.append("addresses", addresses);

  let i = 0
  for(const adEx of addressExams){
    data.append(`addressExams_${i}`,adEx);
    i++
  }
  
  const handleSave = () => { 
    dispatch(asyncActions.sendToApi(data, props.professionalType, () => { 
      localStorage.setItem('status', 'PENDING_APPROVAL')
      props.nextStep() 
    }));
  };

  return (
    <div className={classes.root}>
      <Container className={classes.paper}>
        <Container className={classes.content} id="back-to-top-anchor">
          <Typography className={classes.title}>
            Contrato de prestação de serviços
          </Typography>
          <Divider />
          <Box my={2} className={classes.box} ref={boxRef}>
            {Boolean(term) && (
              <iframe
                id="iframe"
                title="Contrato de prestação de serviços"
                src={term}
              ></iframe>
            )}
            {Boolean(termError) && <h2>Erro ao buscar os termos</h2>}
          </Box>
          <Zoom in={trigger}>
            <div
              onClick={handleClick}
              role="presentation"
              className={classes.buttonUp}
            >
              <Fab
                className={classes.icon}
                size="small"
                aria-label="scroll back to top"
              >
                <KeyboardArrowUpIcon />
              </Fab>
            </div>
          </Zoom>
        </Container>
      </Container>
      <div className={classes.buttons}>
        {loading ? (
          <Loading width="80px" />
        ) : (
          <>
            <Button
              onClick={() => {
                const f = window.frames["iframe"];
                f.contentWindow.print();
              }}
              width="120px"
              border="1px solid #CDCED9"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 20px 0 0"
              data-test="button-termo-de-consentimento-voltar"
            >
              Imprimir
            </Button>
            <Button
              onClick={props.previousStep}
              width="120px"
              border="1px solid #CDCED9"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 20px 0 0"
              data-test="button-termo-de-consentimento-voltar"
            >
              Voltar
            </Button>
            <Button
              onClick={handleSave}
              width="120px"
              border="1px solid #DF9000"
              backgroundColor="#FFFFFF"
              color="#DF9000"
              data-test="button-termo-de-consentimento-aceitar"
              disabled={Boolean(termError)}
            >
              Aceitar
            </Button>
          </>
        )}
      </div>
      <ErrorSnack open={error} />
    </div>
  );
}
