import React from 'react';
import { format, isSameDay, isSameMinute } from 'date-fns';
import Slot from '../slot';

const Day = ({
    day,
    unavailabilities,
    eachDayOfSelectedScheduleInfo,
}) => {
    const selectedScheduleInfo = eachDayOfSelectedScheduleInfo.find(item => isSameDay(item.day, day))

    const getUnavailabilities = (shift) => unavailabilities.filter(i => isSameMinute(new Date(i.dateTimeBeginUnavailabilityUtc), shift.begin))

    const buildSlots = shift => {
        return <Slot
            active={!shift.emptySlot}
            scheduleHasProviderId={shift.scheduleHasProviderId}
            scheduleHasEmployeeId={shift.scheduleHasEmployeeId}
            scheduleGroupProviderId={shift.scheduleGroupProviderId}
            scheduleGroupEmployeeId={shift.scheduleGroupEmployeeId}
            key={format(shift.begin, 'HH:mm')}
            day={selectedScheduleInfo.day}
            timeStamp={format(shift.begin, 'HH:mm')}
            duration={shift.duration}
            unavailability={getUnavailabilities(shift)}
            spanSlots={shift.spanSlots}
        />
    }

    if (selectedScheduleInfo) {
        return (
            <div style={{ 'flex': '1 1 0%' }}>
                {selectedScheduleInfo.shifts.map(buildSlots)}
            </div>
        )
    }
    return <div style={{ 'flex': '1 1 0%' }}></div>
}

export default Day;
