import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import AddressList from '../../common/addressesPage';

import * as scheduleActions from '../../../../common/store/actions/schedule.action';

const Schedule = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    
    const select = (value) => {
        dispatch(scheduleActions.setUnit(value));
        if(value) {
            window.localStorage.setItem("Unit-Vida-Connect", value.id);
            window.localStorage.setItem("ad", value.address);
            window.localStorage.setItem("n", value.number);
            if(value.complement) window.localStorage.setItem("comp", value.complement);
        }
        history.push("/administrador/consultas/agenda");
    }

    return (
        <AddressList
            title="Agenda"
            subtitle="Selecione uma unidade para ver a agenda de consultas"
            onClick={select}
        />
    )

}

export default Schedule;
