import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';


import ModalEditExam from './modals/editExam';
import AddressList from '../common/addressesPage';
import ExamsList from './list/listsWithPagination';

import * as asyncActions from '../../../common/store/actions/async.action';
import * as unitActions from '../../../common/store/actions/units.action';

import SnackBar from '../../../common/snackbar/syncAlternative'
 

const Exam =() => {
    
    const dispatch = useDispatch();
    const providerId = window.localStorage.getItem("Prov-Vida-Connect");
    const employeeId = window.localStorage.getItem("employeeId");
    const unitId = window.localStorage.getItem("Unit-Vida-Connect");

    const examList = useSelector((state) => state.Exams.providerExams);
    const selectedProviderExam = useSelector((state) => state.Exams.selectedProviderExam.details);
    

    const [providerExams, setProviderExams] = useState([]);
    const [snack, setSnack] = useState({ open: false, message: '' });
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [selectedExam, setSelectedExam] = useState(0);

    
    const [openEditModal, setOpenEditModal] = useState(false);
    
    const triggerUpdateExamList = useSelector((state) => state.Exams.triggerUpdateExamList);
    
    let editModal = useSelector((state) => state.Exams.openEditModal)
    let selectedExamIndex = useSelector((state) => state.Exams.selectedProviderExam.payload)
    useEffect( () => {
        setProviderExams(examList);
        dispatch(asyncActions.getExamFee());
        //eslint-disable-next-line
    },[examList])
    
    useEffect( () => {
        setOpenEditModal(editModal)
        //eslint-disable-next-line
    },[editModal])
    useEffect( () => {
        setSelectedExam(selectedExamIndex)
        //eslint-disable-next-line
    },[selectedExamIndex])
    useEffect( () => {
        updateExamList({providerId, unitId})
        
        // eslint-disable-next-line
    },[providerId, unitId, triggerUpdateExamList])


    const updateExamList = async ({providerId,unitId }) => {
        try {
            const input = {
                 providerId,
                 unitId
            }
            if(unitId && providerId){
            dispatch(asyncActions.getProviderExams(input));
            dispatch(unitActions.updateFront(true));
            }
        } catch(error) {
            console.log('ERROR', error)
            dispatch(asyncActions.requestFailure("Houve um problema durante a atualização do exame."));
        }
    };

    const select = (value) => {
        setSelectedUnit(value);
        if(value) {
            window.localStorage.setItem("Unit-Vida-Connect", value.id);
            window.localStorage.setItem("ad", value.address);
            window.localStorage.setItem("n", value.number);
            if(value.complement) window.localStorage.setItem("comp", value.complement);
        }
    }

    if(selectedUnit){
        return (
            <>
             <ExamsList
                    selectedUnit={selectedUnit}
                    setSelectedUnit={select}
                    list={providerExams}
                    
                />
             
                {openEditModal && 
                    <ModalEditExam 
                        data={selectedProviderExam}
                        index={selectedExam}
                        setOpenEditModal={setOpenEditModal}
                    />
                }
                {
                    Boolean(employeeId) &&
                        <SnackBar 
                            open={snack.open}
                            errorMessage={snack.message}
                            onClose={() => setSnack({ open: false, message: '' })}
                            root={{}}
                            severity="warning"
                        />
                }
            </>
        );
    } else {
        return (
            <AddressList
                title="Exames"
                subtitle="Selecione uma unidade para ver os exames cadastrados"
                onClick={select}
            />
        )
    }
}

export default Exam;
