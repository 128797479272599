import Analytics from './analytics'
import getCurrentIp from './../services/getCurrentIp'

const registerUserProperties = ({ properties, analytics }) => {
  analytics.setUserId(properties.userId)
  analytics.setUserProperties(properties)
  analytics.logEvent('WEB_PRESTADORES_LOGGED_IN')
}

const registerUserPropertiesToAnalytics = () => {
  const userProperties = {
    providerId: window.localStorage.getItem('Prov-Vida-Connect'),
    userId: window.localStorage.getItem('User-Vida-Connect'),
    employeeId: window.localStorage.getItem('employeeId'),
    establishmentId: window.localStorage.getItem('establishmentId'),
    establishmentList: window.localStorage.getItem('estab_list'),
    userType: window.localStorage.getItem('userType'),
    environment: process.env.REACT_APP_ENVIRONMENT || 'local'
  }

  return getCurrentIp()
    .then(ip => {
      userProperties['ipAddress'] = ip
      registerUserProperties({ properties: userProperties, analytics: Analytics })
    })
    .catch(() => registerUserProperties({ properties: userProperties, analytics: Analytics }))
}

export default registerUserPropertiesToAnalytics
