import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import * as scheduleRequestsActions from "../../../common/store/actions/scheduleRequests.action";

import Loading from "../../../common/loading";
import Pagination from "../../../common/pagination";
import SnackBar from "../../../common/snackbar";
import ModalScheduling from "../modals/scheduling";
import { handlePatientName } from "../../../common/handlePatientName";

const useStyles = makeStyles((theme) => ({
  headers: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingLeft: "2.5%",
    marginBottom: "10px",
    color: "#6B6C7E",
    boxSizing: "border-box",
  },
  combo: {
    display: "flex",
    width: "100%",
    paddingLeft: "2.5%",
    height: "100%",
    boxSizing: "border-box",
  },
  line: {
    height: "40px",
    width: "100%",
    margin: "auto",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  patient: {
    width: "36%",
  },
  service: {
    width: "22%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  appointmentLocation: {
    width: "14%",
  },
  appointmentType: {
    width: "14%"
  },
  date: {
    width: "17%",
    marginLeft: "30px",
  },
  status: {
    width: "12%",
    marginLeft: "30px",
    display: "flex",
  },
  item: {
    height: "64px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #E7E7ED",
    padding: "0",
    "&.preference": {
      backgroundColor: "#FEEFEF",
      border: "1px solid #F48989",
    },
  },
  requested: {
    width: "110px",
    color: "#B95000",
    backgroundColor: "#FFF4EC",
  },
  proposed: {
    width: "110px",
    color: "#2E5AAC",
    backgroundColor: "#EEF2FA",
  },
  pagination: {
    marginTop: "45px",
  },
}));

export default () => {
  const classes = useStyles();
  const userType = window.localStorage.getItem("userType");
  const provider = JSON.parse(
    window.localStorage.getItem("Secretary-Selected-Doctor")
  );
  const employeeId = window.localStorage.getItem("employeeId");

  const providerId = provider?.providerId
    ? provider?.providerId
    : window.localStorage.getItem("Prov-Vida-Connect");

  const dispatch = useDispatch();

  let appointmentList = useSelector(
    (state) => state.ScheduleRequestsReducer.AppointmentRequests
  );
  let count = useSelector((state) => state.ScheduleRequestsReducer.count);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const firstItem = (page - 1) * itemsPerPage;
  const lastItem = firstItem + itemsPerPage;

  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const getList = async () => {
    setLoading(true);

    if (
      userType === "PROVIDER_DOCTOR" ||
      userType === "PROVIDER_OTHER_PROFESSIONALS"
    ) {
      await dispatch(
        scheduleRequestsActions.listMedicalAppointmentRequests(
          providerId,
          employeeId,
          itemsPerPage,
          page
        )
      );
    } else {
      await dispatch(
        scheduleRequestsActions.secretaryListAppointmentRequests(
          itemsPerPage,
          page,
          providerId,
          provider.employeeId
        )
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [page, itemsPerPage]);

  const loadModal = (Appointment) => {
    const status = Appointment.medicalAppointment?.status
      ? Appointment.medicalAppointment.status
      : Appointment.examAppointment[0].status;
    if (status === "REQUESTED") {
      setSelectedItem(Appointment);
      setOpenModal(true);
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const renderType = (appointment) => {
    if(appointment.appointmentType === 'HEALTH_OPERATOR') {
      if(appointment.healthOperatorRequest){
        return appointment.healthOperatorRequest?.healthOperator?.fantasyName
      } else {
        return 'Retorno Convênio'
      }
    } else {
      if(!appointment.price) {
        return 'Retorno'
      } else {
        return 'Particular'
      }
    }
  }

  return (
    <>
      <ModalScheduling
        open={openModal}
        appointments={selectedItem}
        handleClose={closeModal}
        getList={getList}
      />

      {loading && <Loading width="80px" />}

      {!loading && (
        <List>
          <div className={classes.headers}>
            <div className={classes.patient}>
              <span>Solicitante</span>
            </div>
            <div className={classes.service}>
              <span>Serviço Solicitado</span>
            </div>
            <div className={classes.date}>
              <span>Data</span>
            </div>
            <div className={classes.appointmentLocation}>
              <span>
                Modalidade
              </span>
            </div>
            <div className={classes.appointmentType}>
              <span>
                Tipo
              </span>
            </div>
            <div className={classes.status}>
              <span>Status</span>
            </div>
          </div>

          {appointmentList.map((appointment, index) => {
            const date = format(parseISO(appointment.createdAt), "dd/MM/yyyy");
            const patient = !!appointment.medicalAppointment
              ? appointment.medicalAppointment?.patient
              : appointment.examAppointment[0]?.patient;
            const patientName = handlePatientName(patient);
            return (
              <ListItem
                key={
                  appointment.medicalAppointment?.id
                    ? appointment.medicalAppointment.id
                    : appointment.examAppointment[0].id
                }
                className={classes.item}
                onClick={() => loadModal(appointment)}
                button
              >
                <div className={classes.combo}>
                  <div className={classes.line}>
                    <div className={classes.patient}>
                      <span>{patientName}</span>
                    </div>
                    <div className={classes.service}>
                      <span>
                        {appointment.medicalAppointment?.speciality
                          ? appointment.medicalAppointment.speciality.name
                          : appointment.descriptions}
                      </span>
                    </div>
                    <div className={classes.date}>
                      <span>{date}</span>
                    </div>
                    <div className={classes.appointmentLocation}>
                      {
                        (appointment.medicalAppointment.appointmentLocation === 'ONLINE')
                          ? 'Online' 
                          : 'Presencial'
                      }
                    </div>
                    <div className={classes.appointmentType}>
                      {
                        renderType(appointment.medicalAppointment)
                      }
                    </div>
                    <div className={classes.status}>
                      <span>
                        {(appointment.medicalAppointment?.status
                          ? appointment.medicalAppointment.status
                          : appointment.examAppointment[0].status) ===
                          "REQUESTED" && (
                          <Chip
                            className={classes.requested}
                            label="PENDENTE"
                          />
                        )}
                        {(appointment.medicalAppointment?.status
                          ? appointment.medicalAppointment.status
                          : appointment.examAppointment[0].status) !==
                          "REQUESTED" && (
                          <Chip className={classes.proposed} label="ENVIADO" />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </ListItem>
            );
          })}
        </List>
      )}

      <div className={classes.pagination}>
        <Pagination
          page={page}
          setPage={setPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          firstItem={firstItem}
          lastItem={lastItem}
          length={count}
          width="95%"
        />
        <SnackBar />
      </div>
    </>
  );
};
