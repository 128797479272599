import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';
import Button from '../../../common/button';
import Check from '../../../assets/check-circle-full.png';
import Loading from '../../../common/loading';
import SnackBar from '../../../common/snackbar/index';

import * as modalActions from '../../../common/store/actions/scheduledExam.action';
import * as asyncActions from '../../../common/store/actions/async.action';
import * as scheduleExamsService from '../../../services/scheduledExams'
// Material UI Styling


const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '55%',
    maxWidth: '450px',
    "& .modal-body":{
      "& div.date-appointment":{
        marginBottom: '24px',
        "& span.day-appointment":{
          marginRight:"25px"
        },
        "& img":{
          marginRight:'8px'
        }
      },
      "& div.appointment-user":{
        display:'flex',
        marginBottom:'24px',
        "& div.appointment-user-information span":{
          display: 'block',
          padding: '2px 0 ',
          marginLeft: '13px'
        },
        "& div.image-rounded": {
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          border: '1px solid #E7E7ED',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },

    }
    
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#6B6C7E',
    fontSize: '14px',
    marginTop: '16px'
  },
  responseToPatient: {
      fontSize: '18px',
  },
  success: {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
      fontWeight: 600,
  },
  box: {
      display: "inline-block",
      marginRight: "3%",
      borderRadius: "20px",
      padding: '2% 3%',
      marginBottom: '2%',
      backgroundColor: "#EEEEEE",
      color: "#424242",
      "& > a": {
          cursor: 'pointer',
      }
  }
})

// Component

const ConfirmFinishExamAttendance = (props) => {
    const dispatch = useDispatch();
    const ScheduledExam = useSelector((state) => state.ScheduledExamReducer);
    const loading = ScheduledExam.loading;
    const [isSuccess, setSuccess] = useState(false);
       
    const history = useHistory();
    const data = useSelector((state) => state.ScheduledExamReducer);
    
    const open = data.openFinish;
    const classes = useStyles(props);

    const handleClose = () => {
        dispatch(modalActions.openFinishModal(false));   
      
      };

    const handleSave = async () => {
                       
        const res = await scheduleExamsService.finishExamAppointment(props.appointmentRequestId);
        
        if (res) {
          setSuccess(true)
        } else {
          dispatch(asyncActions.requestFailure("Erro ao finalizar atendimento!"))
        }
        
      
    }

    const whatButtonsSetToRender = () => {
        if(loading) return <Loading width="50px"/>
        if(isSuccess) {
            return (
                <Button 
                onClick={
                    () => {
                       
                        handleClose();
                        history.push('/atendimento');
                    }
                }
                width="120px"
                border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
                margin="0 20px 0 0"
                >
                    Ok
                </Button>
            )
        };
        return (
            <>
                <Button 
                    onClick={handleClose}
                    width="120px"
                    border="1px solid #CDCED9"
                    backgroundColor="#FFFFFF"
                    color="#6B6C7E"
                    margin="0 20px 0 0"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={handleSave}
                    width="120px"
                    border="1px solid #DF9000"
                    backgroundColor="#FFFFFF"
                    color="#DF9000"
                >
                    Confirmar
                </Button>
            </>
        )
    }

    return (
            <div>
            <Dialog 
                open={open}
                onClose={handleClose} 
                aria-labelledby="form-dialog-title" 
                classes={{
                paper: classes.root
                }}
                TransitionComponent={Grow}
                transitionDuration={{enter: 200, exit: 0}}
            >
                <div className={`${classes.header} ${isSuccess ? classes.success : null}`}>
                <DialogTitle>
                    {isSuccess ?
                        <div><img src={Check} alt="success"/><span>Sucesso</span></div>
                        :
                        <span>Confirmar fim do atendimento</span>
                    }
                </DialogTitle>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                </div>
                <DialogContent dividers>
                <form>
                    
                      
                        
                        <div>
                        {isSuccess ?
                        <span>Sucesso ao finalizar atendimento</span>
                        :
                        <span>
                        Ao confirmar o fim do atendimento, não será possível 
                        realizar novas alterações nos anexos.
                        </span>
                    }
                            
                     
                    </div>
                    <DialogActions className={classes.extraMargin}>
                        {whatButtonsSetToRender()}
                    </DialogActions>
                </form>
                </DialogContent>
            </Dialog>
            <SnackBar/>
        </div>
    );
};

export default ConfirmFinishExamAttendance;