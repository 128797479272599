export function personalDatasTypeAccount(payload) {
  return {
    type: "INSERT_TYPE_ACCOUNT_INITIAL_REGISTER",
    payload,
  };
}

export function personalDatas(payload) {
  return {
    type: "UPDATE_INITIAL_REGISTER",
    payload,
  };
}

export function personalDatasPassword(payload) {
  return {
    type: "INSERT_PASSWORD_INITIAL_REGISTER",
    payload,
  };
}

export function personalDatasPf(payload) {
  return {
    type: "UPDATE_PF_REGISTER_DATA",
    payload,
  };
}

export function curriculumDatasPf(payload) {
  return {
    type: "UPDATE_PF_REGISTER_CURRICULUM",
    payload,
  };
}

export function waitingData() {
  return {
      type: "GET_PERSONAL_INITIAL_DATA"
  }
}

export function searchIsDone() {
  return {
    type: "PERSONAL_DATA_SEARCH_IS_DONE"
  }
}

export function clearPersonalData() {
  return {
    type: "CLEAR_PERSONAL_DATA"
  }
}
