import React, { useState, useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'

import Loading from '../../../common/loading'
import Pagination from '../../../common/pagination'
import SnackBar from './../../../common/snackbar/syncAlternative'
import procedureStatus from './../../../common/enums/procedureStatus'

import * as procedureService from '../../../services/procedures'
import { handlePatientName } from '../../../common/handlePatientName';

const useStyles = makeStyles((theme) => ({
  headers: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingLeft: "2.5%",
    marginBottom: "10px",
    color: "#6B6C7E",
    boxSizing: 'border-box',
  },
  combo: {
    display: 'flex',
    width: '100%',
    paddingLeft: '2.5%',
    height: '100%',
    boxSizing: 'border-box',
  },
  line: {
    height: '40px',
    width: "100%",
    margin: "auto",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },
  patient: {
    width: '25%',
  },
  service: {
    width: '40%',
  },
  date: {
    width: '10%',
    marginLeft: '30px',
  },
  dateNotDefined: {
    width: '10%',
    marginLeft: '30px',
    backgroundColor: '#E7E7ED'
  },
  status: {
    width: '15%',
    marginLeft: '30px',
    display: 'flex',
  },
  item: {
    height: '64px',
    backgroundColor: "#FFFFFF",
    border: '1px solid #E7E7ED',
    padding: '0',
    "&.preference": {
      backgroundColor: '#FEEFEF',
      border: '1px solid #F48989'
    }
  },

  requested: {
    width: '140px',
    color: '#B95000',
    backgroundColor: '#FFF4EC'
  },
  budget: {
    width: '140px',
    color: '#2E5AAC',
    backgroundColor: '#EEF2FA'
  },
  choose: {
    width: '140px'

  },
  scheduling: {
    width: '140px',
    color: '#B95000',
    backgroundColor: '#FFF4EC'
  },
  confirm: {
    width: '140px'
  },
  payment: {
    width: '140px',
    color: '#DA1414',
    backgroundColor: '#FEEFEF'
  },
  ok: {
    width: '140px',
    color: '#287D3C',
    backgroundColor: '#EDF9F0'
  },
  pagination: {
    marginTop: '45px'
  }
}));

export default () => {
  const classes = useStyles()
  const history = useHistory()

  const employeeId = window.localStorage.getItem('employeeId')

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])

  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [snack, setSnack] = useState({ open: false, message: '', severity: 'info' })

  const firstItem = (page - 1) * limit
  const lastItem = firstItem + limit

  const goToBudget = (procedureRequest) => history.push(`/solicitacoes/orcamentos/${procedureRequest.id}`)

  const proceduresRequestStatusMap = {
    [procedureStatus.BUDGET_SAVED]: {
      label: 'SALVO',
      className: classes.requested,
      click: goToBudget,
    },
    [procedureStatus.AWAITING_BUDGET]: {
      label: 'ENVIADO',
      className: classes.budget,
      click: goToBudget,
    },
    [procedureStatus.CHOOSE_A_BUDGET]: {
      label: 'SELEÇÃO',
      className: classes.choose,
      click: goToBudget,
    },
    [procedureStatus.AWAITING_DATE]: {
      label: 'AGENDAMENTO',
      className: classes.scheduling,
      click: goToBudget,
    },
    [procedureStatus.CONFIRM_SCHEDULE]: {
      label: 'CONFIRMAR',
      className: classes.confirm,
      click: goToBudget,
    },
    [procedureStatus.PENDING_PAYMENT]: {
      label: 'PAGAMENTO',
      className: classes.payment,
      click: goToBudget,
    },
    [procedureStatus.OK]: {
      label: 'OK',
      className: classes.ok,
      click: goToBudget,
    },
    [procedureStatus.PENDING_TOKEN_VALIDATION]: {
      label: 'OK',
      className: classes.ok,
      click: goToBudget,
    },
    [procedureStatus.FINISHED]: {
      label: 'FINALIZADO',
      className: classes.ok,
      click: goToBudget,
    },
  }

  const RenderChip = ({ status }) => {
    const proceduresRequestStatus = proceduresRequestStatusMap[status]
    if (!proceduresRequestStatus) return (
      <Chip
        label="-"
      />
    )
    return (
      <Chip
        className={proceduresRequestStatus.className}
        label={proceduresRequestStatus.label}
      />
    )
  }

  const loadProcedures = async () => {
    setLoading(true)
    const procedures = await procedureService.getProcedureRequests(page, limit, employeeId)
    if (!procedures?.error) {
      setList(procedures.rows)
      setCount(procedures.count)
    } else {
      setSnack({ open: true, message: 'Erro ao carregar os pedidos de procedimentos', severity: 'error' })
    }
    setLoading(false)
  }

  useEffect(() => {
    loadProcedures()
    // eslint-disable-next-line
  }, [page, limit])

  return (
    <>
      { loading &&
        <Loading width="80px" />
      }

      { !loading &&
        <List>
          <div className={classes.headers}>
            <div className={classes.patient}>
              <span>
                Paciente
              </span>
            </div>
            <div className={classes.service}>
              <span>
                Procedimento
              </span>
            </div>
            <div className={classes.date}>
              <span>
                Data do procedimento
              </span>
            </div>
            <div className={classes.date}>
              <span>
                Pedido em
              </span>
            </div>
            <div className={classes.status}>
              <span>
                Status
              </span>
            </div>
          </div>

          {list.map((procedureRequest) => {
            const date = procedureRequest.dateTime ? format(parseISO(procedureRequest.dateTime), "dd/MM/yyyy HH:mm:ss") : 'Não definida'
            const createdAt = format(parseISO(procedureRequest.createdAt), "dd/MM/yyyy")
            return (
              <ListItem
                key={procedureRequest.id}
                className={classes.item}
                onClick={() => proceduresRequestStatusMap[procedureRequest.status].click(procedureRequest)}
                button
              >
                <div className={classes.combo}>
                  <div className={classes.line}>
                    <div className={classes.patient}>
                      <span>
                        {handlePatientName(procedureRequest.medicalAppointment.patient)}
                      </span>
                    </div>
                    <div className={classes.service}>
                      <span>
                        {procedureRequest.serviceProvided.description}
                      </span>
                    </div>
                    <div className={classes.date}>
                      <span>
                        {date}
                      </span>
                    </div>
                    <div className={classes.date}>
                      <span>
                        {createdAt}
                      </span>
                    </div>
                    <div className={classes.status}>
                      <span>
                        <RenderChip
                          status={procedureRequest.status}
                          classes={classes}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </ListItem>
            )
          })}
        </List>
      }
      <div className={classes.pagination}>
        <Pagination
          page={page}
          setPage={setPage}
          itemsPerPage={limit}
          setItemsPerPage={setLimit}
          firstItem={firstItem}
          lastItem={lastItem}
          length={count}
          width="95%"
        />
      </div>
      <div>
        <SnackBar
          open={snack.open}
          errorMessage={snack.message}
          onClose={() => setSnack({ open: false, message: '', severity: 'info' })}
          root={{}}
          severity={snack.severity}
        />
      </div>
    </>
  )
}
