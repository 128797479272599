import React, { useState } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';

import { Avatar } from '@material-ui/core'
import Button from '../../../common/button'
import Loading from '../../../common/loading'
import Check from '../../../assets/check-circle-full.png'

import * as procedureService from '../../../services/procedures'
import { handlePatientName } from "../../../common/handlePatientName";

const useStyles = makeStyles(() => ({
  close: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  success: {
    backgroundColor: '#EDF9F0',
    color: '#287D3C'
  },
  icon: {
    marginRight: 20
  },
  root: {
    width: '800px',
    maxWidth: '580px',
    "& .modal-body":{
      "& div.date-appointment":{
        marginBottom: '24px',
        "& span.day-appointment":{
          marginRight:"25px"
        },
        "& img":{
          marginRight:'8px'
        }
      },
      "& div.appointment-user":{
        display:'flex',
        marginBottom:'24px',
        "& div.appointment-user-information span":{
          display: 'block',
          padding: '2px 0 ',
          marginLeft: '13px'
        },
        "& div.image-rounded": {
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          border: '1px solid #E7E7ED',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },

    }
    
  },
}))

const InitProcedureModal = ({open, handleClose, procedure, reload}) => {
    const classes = useStyles()
    
    const [ isSuccess, setSuccess ] = useState(false);
    const [ loading, setLoading ] = useState(false)

    const whatButtonsSetToRender = () => {
      if(loading) return <Loading width="50px"/>
      if(isSuccess) {
        return (
          <Button
            onClick={handleClose}
            width="120px"
            border="1px solid #CDCED9"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            margin="0 20px 0 0"
          >
            Ok
          </Button>
        )
      };
      return (
        <>
          <Button 
            onClick={handleClose}
            width="120px"
            border="1px solid #CDCED9"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            margin="0 20px 0 0"
          >
            Cancelar
          </Button>
          <Button
            onClick={startProcedureAttendance}
            width="120px"
            border="1px solid #DF9000"
            backgroundColor="#FFFFFF"
            color="#DF9000"
          >
            Confirmar
          </Button>
        </>
      )
    }

    const startProcedureAttendance = async () => {
      setLoading(true)
      const start = await procedureService.startProcedureAttendance(procedure.id)
      setLoading(false)
      if(start) {
        setSuccess(true)
        reload()
      } else {

      }
    }

    return (
      <div>
        <Dialog 
          open={open}
          onClose={handleClose} 
          aria-labelledby="form-dialog-title" 
          classes={{
            paper: classes.root
          }}
          TransitionComponent={Grow}
          transitionDuration={{enter: 200, exit: 0}}
        >
          <DialogTitle
            className={isSuccess ? classes.success : ''}
          >
            <>
            { isSuccess &&
              <>
                <img src={Check} alt="success" className={classes.icon}/>
                <b>Atendimento iniciado </b>
              </>
            }
            { !isSuccess &&
              <b>
                Deseja iniciar o atendimento desse paciente?
              </b>
            }
            <IconButton 
              aria-label="close" 
              onClick={handleClose}
              className={classes.close}
            >
              <CloseIcon />
            </IconButton>
            </>
          </DialogTitle>  

          <DialogContent dividers>
            <div className="modal-body">
              <div className="appointment-user">
                <div className="image-rounded">
                  <Avatar/>
                </div>
                <div className="appointment-user-information">
                  { procedure && 
                    <>
                      <span>
                        <b>{ handlePatientName(procedure?.medicalAppointment.patient)}</b>
                      </span>
                      <span>
                        { procedure?.medicalAppointment.patient.user.email }
                      </span>
                    </>
                  }
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            {whatButtonsSetToRender()}
          </DialogActions>
        </Dialog>
        {/* <SnackBar/> */}
      </div>
    );
};

export default InitProcedureModal;