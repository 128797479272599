import axios from 'axios'
export const saveExamSpreadsheet = async (payload) => {
    const urlRest = `${process.env.REACT_APP_API_URL}/servicesProvided/saveProviderExams`;
        try {
    
         const res = await axios({
                method: "post",
                url: urlRest,
                data: payload,
            });
    
         if(res.data){
         return {success: true}
         }
         else{
            return {success: false, message: "Houve um erro interno, por favor, tente novamente mais tarde."}    
         }
        } catch (err) {
            if(err.response.data.statusCode === 500){
                return {success: false, message: "Houve um erro interno, por favor, tente novamente mais tarde."}    
            }
            else{
            return {success: false, message: err.response.data.message}
            }
        }
    };