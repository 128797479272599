export function openStartModal(payload) {
  return {
    type: "OPEN_START_CONSULTATION_MODAL",
    payload,
  };
}

export function openFinishModal(payload) {
  return {
    type: "OPEN_FINISH_CONSULTATION_MODAL",
    payload,
  };
}

export function openDispensePatientModal(payload) {
  return {
    type: "OPEN_DISPENSE_PATIENT_MODAL",
    payload,
  };
}

export function openConsentModal(payload) {
  return {
    type: "OPEN_CONSENT_MODAL",
    payload,
  };
}

export function openReferralModal(payload) {
  return {
    type: "OPEN_REFERRAL_MODAL",
    payload,
  };
}

export function openSurgicalProcedureModal(payload) {
  return {
    type: "OPEN_SURGICAL_PROCEDURE_MODAL",
    payload,
  };
}

export function registerSurgicalProcedure(payload) {
  return {
    type: "REGISTER_SURGICAL_PROCEDURE",
    payload,
  };
}

export function updateSurgicalProcedure(payload) {
  return {
    type: "UPDATE_SURGICAL_PROCEDURE",
    payload,
  };
}

export function clearSurgicalProcedure(payload) {
  return {
    type: "CLEAR_SURGICAL_PROCEDURE",
    payload,
  };
}

export function openMedicalReportModal(payload) {
  return {
    type: "OPEN_MEDICAL_REPORT_MODAL",
    payload,
  };
}

export function openPrescriptionModal(payload) {
  return {
    type: "OPEN_PRESCRIPTION_MODAL",
    payload,
  };
}

export function openSickNoteModal(payload) {
  return {
    type: "OPEN_SICK_NOTE_MODAL",
    payload,
  };
}

export function openExamRequestModal(payload) {
  return {
    type: "OPEN_EXAM_REQUEST_MODAL",
    payload,
  };
}
export function openExamRegisteredRequestModal(payload) {
  return {
    type: "OPEN_EXAM_REGISTERED_REQUEST_MODAL",
    payload,
  };
}

export function openDoctorPrescriptionSpecial(payload) {
  return {
    type: "OPEN_DOCTOR_PRESCRIPTION_SPECIAL_MODAL",
    payload
  }
}

export function openDoctorPrescriptionAntimicrobials(payload) {
  return {
    type: "OPEN_DOCTOR_PRESCRIPTION_ANTIMICROBIALS",
    payload
  }
}

export function weAreLive(payload) {
  return {
    type: "WE_ARE_LIVE",
    payload,
  };
}

export function consultationIsRunning(payload) {
  return {
    type: "CONSULTATION_IS_RUNNING",
    payload,
  };
}

export function storeData(payload) {
  return {
    type: "STORE_START_CONSULTATION_DATA",
    payload,
  };
}

export function participantEntered(payload) {
  return {
    type: "PARTICIPANT_ENTERED",
    payload,
  };
}

export function setTwilioToken(payload) {
  return {
    type: "SET_TWILIO_TOKEN",
    payload,
  };
}

export function setConsultationData(payload) {
  return {
    type: "SET_CONSULTATION_DATA",
    payload,
  };
}

export function clearConsultationData(payload) {
  return {
    type: "CLEAR_CONSULTATION_DATA",
    payload,
  };
}

export function setCountPrescription() {
  return {
    type: "SET_COUNT_PRESCRIPTION"
  };
}

export function setCountDoctorPrescriptionAntimicrobials() {
  return {
    type: "SET_COUNT_DOCTOR_PRESCRIPTION_ANTIMICROBIALS"
  };
}

export function setCountDoctorPrescriptionSpecial() {
  return {
    type: "SET_COUNT_DOCTOR_PRESCRIPTION_SPECIAL"
  };
}

export function setCountSickNote() {
  return {
    type: "SET_COUNT_SICK_NOTE"
  };
}

export function setCountExamRequest() {
  return {
    type: "SET_COUNT_EXAM_REQUEST"
  };
}

export function setCountMedicalReport() {
  return {
    type: "SET_COUNT_MEDICAL_REPORT"
  };
}

export function resetCountersPrescription() {
  return {
    type: "RESET_COUNTERS_PRESCRIPTION"
  };
}

export function sendConsultationData() {
  return {
    type: "SEND_CONSULTATION_DATA",
  };
}

export function requestSuccess(payload) {
  return {
    type: "REQUEST_CONSULTATION_SUCCESS",
    payload,
  };
}

export function stopWaiting(payload) {
  return {
    type: "STOP_WAITING_CONSULTATION",
    payload,
  };
}

export function saveMedicalHistory(payload) {
  return {
    type: "SAVE_MEDICAL_HISTORY",
    payload,
  };
}

export function updateStatusConsultation(payload) {
  return {
    type: "UPDATE_STATUS_CONSULTATION",
    payload,
  };
}

export function getConsentTermsOfDoctor(payload) {
  return {
    type: "GET_TERMS_OF_DOCTOR",
    payload,
  };
}

export function setConsultationLocal(payload) {
  return {
    type: "SET_CONSULTATION_LOCAL",
    payload
  }
}

export function deleteConsultationExam(payload) {
  return {
    type: "DELETE_CONSULTATION_EXAM",
    payload,
  };
}
