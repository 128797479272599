import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grow } from '@material-ui/core';

import axios from "axios";

import Input from '../../../../common/input';
import Button from '../../../../common/button';
import InputCurrency from '../../../../common/inputCurrency';
import Select from '../../../../common/select';
import TextArea from '../../../../common/textarea';
import SnackBar from '../../../../common/snackbar';
import Loading from '../../../../common/loading';
import Check from '../../../../assets/check-circle-full.png';
import empty from '../../../../common/validators/empty';
import SearchServiceProvided from '../../../../common/SearchServiceProvided'

import * as examsActions from "../../../../common/store/actions/registerExams";
import * as asyncActions from '../../../../common/store/actions/async.action';

const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '34%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '12px',
  },
  success: {
    backgroundColor: "#EDF9F0",
    color: "#287D3C",
    fontWeight: 600,
  },
})

const ModalAddExam = ({data, index, ...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  
  const providerId = window.localStorage.getItem("Prov-Vida-Connect", );
  const unitId = window.localStorage.getItem("Unit-Vida-Connect");

  const openModal = useSelector((state) =>  state.Exams.openAddModal);
  const taxExamFee = useSelector( (state) => state.Exams.taxExamFee);

  const [open, setOpen] = useState(false);

  const [examCode, setExamCode] = useState("");
  const [examCodeError, setExamCodeError] = useState("");

  const [examId, setExamId] = useState("");
  
  const [name, setName] = useState("");

  const [examsFromApi, setExamsFromApi] = useState({});
  const [examError, setExamError] = useState("");
  const [examLoading, setExamLoading] = useState(false);

  const [preparation, setPreparation] = useState("");
  const [preparationError, setPreparationError] = useState("");

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect( () => {
      setOpen(openModal);
  },[openModal]);

  useEffect(() => {
    setExamCode(examsFromApi.code)
    setExamId(examsFromApi.id)
    setName(examsFromApi.name)
    setPreparation(preparation);
    setPrice(price);
    setExamCodeError("")
    //eslint-disable-next-line
  }, [examsFromApi])


  const handleClose = () => {
    
    setExamCode("")
    setName("");
    setPreparation("");
    setPrice("");
    setExamCodeError("")
    setPriceError("")
    setPreparationError("")
    setLoading(false);
    setSuccess(false);
    dispatch(examsActions.openAddModal(false));
  };
  const urlGraphQl = `${process.env.REACT_APP_API_URL}/graphql`;

  const addProviderExam= async ({providerId, examId, unitId, preparation, needScheduling, price}) => {
    try {
      console.log('UNIT', unitId)
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query:
          `mutation RegisterProviderHasServiceProvidedMutation($registerProviderHasServiceProvidedInput: ProviderServicesProvidedInput!) {
            registerProviderHasServiceProvided(input: $registerProviderHasServiceProvidedInput) {
              serviceProvided {
                description
                code
                category
              }
              id
              price
              preparation
              needScheduling
              active
            }
          }
          `,
          variables: {
            registerProviderHasServiceProvidedInput: {
              providerId,
              UnitId: unitId,
              serviceProvidedId: examId,
              preparation,
              needScheduling,
              price: parseFloat(price)
            }
          }
        }
      });
      if(res.data.data) {
     
        const payload = res.data.data.registerProviderHasServiceProvided;
        dispatch(examsActions.RegisterProviderExam(payload));
        setSuccess(true);
      } else {
        
        const errors = res.data.errors[0]
        console.log(errors.message)
        const message = errors.message === 'Este prestador ja registrou este exame' ? errors.message : "Houve um problema durante a inclusão do exame."
        dispatch(asyncActions.requestFailure(message))
        return false
      };
      return true
    } catch(error) {
      setPreparationError(empty(preparation).typeError)
      dispatch(asyncActions.requestFailure("Houve um problema durante a inclusão do exame."))
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    
      try {
        if(empty(examCode).typeError){
          setExamCodeError(empty(examCode).typeError)
          setLoading(false);
          return
        }

        if(isNaN(price) )
        {         
          
          setPriceError(empty('').typeError)
          setLoading(false);
          return
        }
        setPriceError('')
        if(empty(preparation).typeError){
          setPreparationError(empty(preparation).typeError)
          setLoading(false);
          return
        }
        setPreparationError('')
        
        const success = await  addProviderExam({providerId, examId, preparation, unitId, needScheduling: true, price});

        setLoading(false);
        if (success){
        setSuccess(true)
        }
      } catch(error) {
        console.log('ERROR add', error)
        dispatch(asyncActions.requestFailure("Houve um problema durante a atualização do exame."))
        setLoading(false);
      }
  }

  return (
    <div>
      <Dialog 
        open={open}
        onClose={handleClose} 
        aria-labelledby="form-dialog-title" 
        classes={{
          paper: classes.root
        }}
        TransitionComponent={Grow}
        transitionDuration={{enter: 200, exit: 0}}
      >
        <div className={`${classes.header} ${success ? classes.success : null}`}>
          <DialogTitle>
            {success ?
              <div><img src={Check} alt="success"/><span>Exame adicionado com sucesso</span></div>
              :
              <span>Exame</span>
            }
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          <form onSubmit={props.handleSubmit}>
            
            <div className="modal-body">
            <Select
                        label="Tabela"
                        InputProps={{readOnly: true}}                            
                        value={'TUSS'}
                        itemList={[
                          {value: "TUSS", exhibit: "TUSS" },
                        ]}
                      />
      <SearchServiceProvided
        onChange={setExamsFromApi}
        error={setExamError}
        isLoading={setExamLoading}
        value={name}
      />
      {Boolean(examError) && <span>{examError}</span>}
      {!examLoading && <span>Carregando exames...</span>}
              
              <Input
                width="100%"
                label="Código do exame"
                value={examCode}
                InputProps={{readOnly: true}} 
             
                error={examCodeError}
                
              />
             
              <InputCurrency
                width="100%"
                label="Valor em reais (R$)"
                value={price}
                onChange={setPrice}
                error={priceError}
                styles={{fontSize: '12px',
                marginTop: '3px',
                color: "#DA1414"}}
                dataTest="modal-cadastrar-consulta-input-preco"
              />
               <DialogContentText className={classes.lettering}>
                O valor cobrado pela plataforma será de: R$ {((taxExamFee/100)*price).toFixed(2)}
              </DialogContentText>
          
               <TextArea
                width="93%"
                label="Preparo para o exame"
                placeholder="Instruções para o preparo"       
                rows={8}
                onChange={setPreparation}
                onBlur={() => setPreparationError(empty(preparation).typeError)}
                error={preparationError}
                max={250}
              />
             
              <SnackBar />
            </div>
            <DialogActions className={classes.extraMargin}>
              {loading ? 
                <Loading width="80px"/>
                :
                success ?
                  (<Button 
                  onClick={handleClose}
                  width="120px"
                  border="1px solid #DF9000"
                  backgroundColor="#FFFFFF"
                  color="#DF9000"
                  >
                    Ok
                  </Button>)
                  :
                  (
                    <>
                      <Button 
                        onClick={handleClose}
                        width="120px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                        margin="0 20px 0 0"
                      >
                        Cancelar
                      </Button>
                      <Button 
                        onClick={handleSubmit}
                        width="120px"
                        border="1px solid #DF9000"
                        backgroundColor="#FFFFFF"
                        color="#DF9000"
                      >
                        Salvar
                      </Button>
                    </>
                  )
              }
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalAddExam;
