import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


import Button from '../../../common/button';

import * as modalActions from '../../../common/store/actions/consultation.action';
import * as asyncActions from '../../../common/store/actions/async.action';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';
import { format } from "date-fns";
import { handlePatientName } from "../../../common/handlePatientName";

// Material UI Styling

const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '55%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  term: {
    lineHeight: '150%',
    fontSize: '18px'
  },
  consentTerm: {
      border: '1px solid #E7E7ED',
      borderRadius: '4px',
      padding: '4%',
      "& > h3": {
          marginTop: 0,
          paddingBottom: '16px',
          borderBottom: '1px solid #E7E7ED'
      },
      "& > p": {
          fontSize: '16px'
      }
  }
})

// Component

const StartConsultationModal = (props) => {
    const dispatch = useDispatch();
    const consentData = useSelector((state) => state.ConsultationReducer);
    const term = consentData.terms
    const open = consentData.openConsent;
    const providerId = localStorage.getItem('Prov-Vida-Connect')
    const patientId = consentData.data.patient.id
    const approvedConsentTerm = consentData.data.approvedConsentTerm || new Date()
    const patientName =  handlePatientName(consentData.data.patient)
    
    const docData = JSON.parse(window.localStorage.getItem("docData"));
    const provData = JSON.parse(window.localStorage.getItem("providerData"));
    const doctorName = docData?.name || provData?.name
    const doctorCrm = docData?.crm || ''

    const classes = useStyles(props);

    const handleClose = () => {
        dispatch(modalActions.openConsentModal(false));
    }

    const [termOfConsent, setTermOfConsent] = useState("")

    useEffect(() => {
        dispatch(asyncActions.getTermsOfDoctor({ patientId, providerId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, providerId])

    useEffect(() => {
        setTermOfConsent(term)
    }, [term])

    return (
            <div>
            <Dialog 
                open={open}
                onClose={handleClose} 
                aria-labelledby="form-dialog-title" 
                classes={{
                paper: classes.root
                }}
                TransitionComponent={Grow}
                transitionDuration={{enter: 200, exit: 0}}
            >
                <div className={classes.header}>
                <DialogTitle>
                    Termo de consentimento
                </DialogTitle>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                </div>
                <DialogContent dividers>
                <form>
                    <div className="modal-body">
                        <p className={classes.term}>Eu, <strong>{patientName}</strong>, 
                        declaro que estou 
                        de acordo com o Termo de Consentimento, fornecido pelo médico <strong>{doctorName}</strong>, 
                        portador do CRM <strong>{doctorCrm}</strong>.<br/><br/> Aceito em <strong>{format(new Date(approvedConsentTerm), 'dd/MM/yyyy, HH:mm:ss')}</strong>.
                        </p>
                        <div className={classes.consentTerm}>
                            <h3>Termo de Consentimento do prestador</h3>
                            <a href={termOfConsent} target="_blank" rel="noopener noreferrer">Clique para ver o termo</a>
                        </div>
                    </div>
                    <DialogActions className={classes.extraMargin}>
                    <Button 
                        onClick={handleClose}
                        width="120px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                        margin="0 20px 0 0"
                    >
                        Ok
                    </Button>
                    </DialogActions>
                </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default StartConsultationModal;
