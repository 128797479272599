import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grow } from '@material-ui/core';

import api from '../../../../services/api';

import Button from '../../../../common/button';
import SnackBar from '../../../../common/snackbar';
import Loading from '../../../../common/loading';
import Check from '../../../../assets/check-circle-full.png';


import * as consultationsActions from "../../../../common/store/actions/registerConsultations";
import * as asyncActions from '../../../../common/store/actions/async.action';

const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '34%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  },
  success: {
    backgroundColor: "#EDF9F0",
    color: "#287D3C",
    fontWeight: 600,
  },
})

const ModalDeleteSpeciality = ({data, index, ...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const employeeId = window.localStorage.getItem("employeeId");
  const unitHasSpecialitiesId = window.localStorage.getItem("unitHasSpecialitiesId");
  const unitId = window.localStorage.getItem("Unit-Vida-Connect");
  let specialityId = "";
  if(data) specialityId = data.speciality.id;

  const openModal = useSelector((state) => state.RegisterConsultations.openDeleteModal)

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect( () => {
    if(openModal) {
      setOpen(openModal);
    }
  },[openModal]);

  const handleClose = () => {
    props.setOpenDeleteModal(false);
    dispatch(consultationsActions.openDeleteModal(false));
  };


  const deleteProviderConsultation = async ({specialityId, unitId}) => {
    try{
      const res = await api({
        data: {
            query:
                `
                    mutation($input: UpdateUnitHasProvidersInput!) {
                        alternateUnitHasProviderActive (input: $input)
                    }
                `,
            variables:{
                input: {
                    specialityId,
                    unitId,
                }
            }
        }
      });
      if (res.data.errors) {
        let _message = 'Houve um problema ao deletar essa especialidade.Tente novamente, por favor.'
        for (const error of res.data.errors) {
          const { status, message } = error.extensions.exception
          if (status >= 400 && status < 500) _message = message
        }

        setLoading(false);
        dispatch(asyncActions.requestFailure(_message || 'Houve um problema ao deletar essa especialidade.Tente novamente, por favor.'));
        return
      }

      if(res.data.data) {
        dispatch(consultationsActions.deleteConsultation(index));
        setSuccess(true);
        setLoading(false);
      };
    } catch(error) {
      setLoading(false);
      dispatch(asyncActions.requestFailure("Houve um problema ao deletar essa especialidade.Tente novamente, por favor."));
    }
  }

  const deleteEmployeeConsultation = async ({unitHasSpecialitiesId, employeeId}) => {
    try {
      const res = await api({
        data: {
            query:
                `
                    mutation($input: AlternateDoctorAppointmentActivityInput!) {
                        alternateDoctorAppointmentActivity (input: $input)
                    }
                `,
            variables:{
                input: {
                    unitHasSpecialitiesId,
                    employeeId,
                }
            }
        }
      });
      if(res.data.data) {
        dispatch(consultationsActions.deleteConsultation(index));
        setSuccess(true);
        setLoading(false);
      };
    } catch(error) {
      dispatch(asyncActions.requestFailure("Houve um problema ao deletar essa especialidade.Tente novamente, por favor."));
      setLoading(false);
    }

  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
      try {

        if(employeeId) {
          await deleteEmployeeConsultation({unitHasSpecialitiesId, employeeId});
        } else {
          await deleteProviderConsultation({specialityId, unitId});
        };

      } catch(error) {
        dispatch(asyncActions.requestFailure("Houve um problema ao deletar essa especialidade.Tente novamente, por favor."))
        setLoading(false);
      }
  }

  return (
    <div>
      <Dialog 
        open={open}
        onClose={handleClose} 
        aria-labelledby="form-dialog-title" 
        classes={{
          paper: classes.root
        }}
        TransitionComponent={Grow}
        transitionDuration={{enter: 200, exit: 0}}
      >
        <div className={`${classes.header} ${success ? classes.success : null}`}>
          <DialogTitle>
            {success ?
              <div><img src={Check} alt="success"/><span> Especialidade excluída com sucesso</span></div>
              :
              <span>Excluir especialidade</span>
            }
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          <form onSubmit={props.handleSubmit}>
            <div className="modal-body">
              <DialogContentText className={classes.lettering}>
                  Deseja mesmo excluir a especialidade dessa unidade?
              </DialogContentText>
              <SnackBar />
            </div>
            <DialogActions className={classes.extraMargin}>
              {loading ? 
                <Loading width="80px"/>
                :
                success ?
                  (<Button 
                  onClick={handleClose}
                  width="120px"
                  border="1px solid #DF9000"
                  backgroundColor="#FFFFFF"
                  color="#DF9000"
                  >
                    Ok
                  </Button>)
                  :
                  (
                    <>
                      <Button 
                        onClick={handleClose}
                        width="120px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                        margin="0 20px 0 0"
                      >
                        Não
                      </Button>
                      <Button 
                        onClick={handleSubmit}
                        width="120px"
                        border="1px solid #DF9000"
                        backgroundColor="#FFFFFF"
                        color="#DF9000"
                      >
                        Sim
                      </Button>
                    </>
                  )
              }
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalDeleteSpeciality;
