import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    master: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        border: "none",
        margin: props => props.margin ? props.margin : "12px 0 0 0",
        width: props => props.width,
    },
    root: {
        height: "40px",
        marginTop: '3px',
        border: '1px solid #e7e7ed',
        borderRadius: '4px',
        paddingRight: '0px !important',
        position: 'relative',
        backgroundColor: props => props.backgroundColor ? props.backgroundColor : 'transparent',
    },
    underline: {
        '&:before': {
            borderBottom: 'none !important'
        },
        '&:after': {
            borderBottom: '1px solid #f9b233'
        }
    },
    adornment: {
        position: 'absolute',
        width: '25%',
        textAlign: 'end',
        right: '5px',
    },
    input: {
        maxWidth: '80%',
    },
    popupIndicator: {
        width: '30px',
        '&:hover': {
            background: 'transparent'
        },
    },
    popupIndicatorOpen: {
        transform: 'rotate(0)'
    },
    displayNone: {
        display: 'none'
    },
    error: {
        fontSize: '0.75rem',
        marginTop: '3px',
        color: "#DA1414"
    },
})

const SearchBar = (props) => {
    const dispatch = useDispatch();
    const asyncResponse = useSelector((state) => state.AsyncReducer.response[props.type]);
    const [open, setOpen] = useState(false);
    const [partialText, setPartialText] = useState("");
    const loading = useSelector((state) => state.AsyncReducer.loading)
    const loadingOn = loading && open;

    const classes = useStyles(props);

    React.useEffect(() => {
        setPartialText(props.value)
    }, [props.value]);

    React.useEffect(() => {
        if (props.onInputChange) props.onInputChange(partialText)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partialText])

    const onOpenHandler = () => {
        setOpen(true);
        if (props.asyncAction) {
            dispatch(props.asyncAction(props.payload));
        }
    }

    return (
        <Grid container className={classes.master}>
            <Grid item>
                <span>
                    <strong className="label-input">{props.label}</strong>
                </span>
                <Autocomplete
                    id={`asynchronous-demo-${Math.random()}`}
                    openOnFocus
                    autoHighlight
                    inputValue={partialText}
                    onChange={(event, value) => {
                        if (value) {
                            props.onChange(value);
                        } else {
                            props.onChange(props.initialState || { name: "" })
                        }
                    }}
                    onInputChange={(event, inputValue, reason) => {
                        const newValue = props.value || reason === "input" ? inputValue : ''
                        setPartialText(newValue)
                    }
                    }
                    classes={{
                        endAdornment: classes.adornment,
                        clearIndicator: classes.displayNone,
                        popupIndicator: classes.popupIndicator,
                        popupIndicatorOpen: classes.popupIndicatorOpen,
                    }}
                    open={open}
                    onOpen={() => {
                        onOpenHandler();
                    }}
                    onClose={(event, reason) => {
                        setOpen(false);
                    }}
                    getOptionSelected={(option, value) => {
                        return option.name === value.name
                    }
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionDisabled={(option) => {
                        if (props.alreadyChoosen) {
                            const picked = props.alreadyChoosen.reduce((acc, currValue) => {
                                return acc || currValue.name === option.name
                            }, false)
                            return picked;
                        } else {
                            return false;
                        }

                    }}
                    options={props.sync ? props.itemList : asyncResponse}
                    noOptionsText="-"
                    loading={loading}
                    loadingText="Carregando..."
                    popupIcon={loadingOn ? <CircularProgress color="inherit" size={20} /> : props.iconDefault ? null : <SearchIcon />}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onBlur={props.onBlur}
                            placeholder={props.placeholder}
                            value={props.value}
                            disabled={props.disabled}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                                classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                    input: classes.input
                                },
                                readOnly: props.readOnly
                            }}
                            inputProps={{ ...params.inputProps, "data-test": props.dataTest }}
                        />
                    )}
                />
                <div className={classes.error}>
                    <span>{props.error}</span>
                </div>
            </Grid>
        </Grid>
    );
}
export default SearchBar;
