export function openStartModal(payload) {
    return {
      type: "OPEN_START_CONSULTATION_MODAL",
      payload,
    };
  }
  
  export function openFinishModal(payload) {
    return {
      type: "OPEN_FINISH_CONSULTATION_MODAL",
      payload,
    };
  }
  
  export function openDispensePatientModal(payload) {
    return {
      type: "OPEN_DISPENSE_PATIENT_MODAL",
      payload,
    };
  }
  
  
  
  export function storeData(payload) {
    return {
      type: "STORE_START_CONSULTATION_DATA",
      payload,
    };
  }
  
 
  export function setConsultationData(payload) {
    return {
      type: "SET_CONSULTATION_DATA",
      payload,
    };
  }
  
  export function clearConsultationData(payload) {
    return {
      type: "CLEAR_CONSULTATION_DATA",
      payload,
    };
  }
  
 
  
  export function sendConsultationData() {
    return {
      type: "SEND_CONSULTATION_DATA",
    };
  }
  
  export function requestSuccess(payload) {
    return {
      type: "REQUEST_CONSULTATION_SUCCESS",
      payload,
    };
  }
  
  export function updateStatusConsultation(payload) {
    return {
      type: "UPDATE_STATUS_CONSULTATION",
      payload,
    };
  }
  

  