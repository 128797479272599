import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

import HorizontalList from '../../../common/horizontalList'
import ConsultationHistory from './tabs/consultationsHistoric';
import ExamsHistory from './tabs/examsHistoric';
import ProceduresHistory from './tabs/proceduresHistoric';


const useStyles = makeStyles({
  table: {
    marginBottom: '30px',
    "& tbody": {
      backgroundColor: '#FFF'
    }
  },
  screen: {
    backgroundColor: "#F7F8F9",
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  root: {
    width: '95%',
    padding: '3vh 0',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 600
    }
  }
})

export default () => {
  const classes = useStyles();

  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)
  
  function handleListOptions(){
   let listOptions = []
    const employeeId = window.localStorage.getItem('employeeId')
    if(!!employeeId){
     listOptions = [
      { text:"Consultas" },
    ]
  }
    else
    {
     listOptions = [
      { text:"Consultas" },
      { text:"Exames" },
      { text:"Procedimentos" },
    ]
  }
  return listOptions
  }
  const listOptions = handleListOptions()
  const [ selectedCategory, setSelectedCategory ] = useState(Number(query?.tab || 0))
  
  useEffect(() => {
    query.tab = selectedCategory
    const newQuery = queryString.stringify(query)
    history.replace(`${location.pathname}?${newQuery}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  return (
    <div className={classes.screen}>
      <div className={classes.root}>
      <HorizontalList 
              width="90%"
              listItems={listOptions}
              selectedList={selectedCategory}
              setSelectedList={setSelectedCategory}
            />

        <div className={classes.table}>

        { selectedCategory === 0 &&
            <ConsultationHistory
            />
          } 
        
        { selectedCategory === 1 &&
            <ExamsHistory
            />
        }
        { selectedCategory === 2 &&
            <ProceduresHistory
            />
        }
        </div>
      
        </div>
    </div>
  )
}
