import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Topic from '../../../../common/topic'
import ComplexList from '../../../../common/itemList/listForComplexObjects';
import ModalRegisterConsultationButton from '../../../../common/modal/consultation/registerConsultation';

import * as consultation from "../../../../common/store/actions/registerConsultations";

export const Consultations = (props) => {
  const dispatch = useDispatch()
  const consultations = useSelector((state) => state.RegisterConsultations.consultations);
  
  const handleUpdateConsultation = (index) => {
    dispatch(consultation.alterActive(index));
    dispatch(consultation.openModal(true));
  }

  const deleteConsultation = (index, value) => {
    dispatch(consultation.deleteConsultation(index));
  }

  return (
    <>
      <div className="form-layout first-field-topic">
        <div className="side-by-side">
          <Topic
            classesNameCol="topic-datas"
            title="Consultas"
            subtitle="Cadastre aqui as consultas que realizará neste endereço"
            classesNameSubtitle="text-black"
          />
          <ModalRegisterConsultationButton
            data-target="modal-register-consultation"
            buttonMargin="30px 0 0 0"
            professionalType={props.professionalType}
          >
            Adicionar
          </ModalRegisterConsultationButton>
        </div>
        <div className="service-register">
          <div className="register-services">
            <ComplexList 
              fullList={consultations} 
              firstParam={"speciality"}
              secondParam={"name"}
              firstColumnWidth="100%"
              menuItems={
                [
                  {name: "Editar essa Consulta", handleItem: handleUpdateConsultation}
                ]
              }
              deleteItem={deleteConsultation}
            />
          </div>
        </div>
      </div>
    </>
  )
}