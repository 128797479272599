import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ModalBase from '../modalBaseAddress';

import empty from "../../validators/empty";
import cepValidator from "../../validators/cep";
import telphoneValidator from "../../validators/telephone";
import maskCep from "../../mask/cep";
import maskTelephone from "../../mask/telephone";
import Input from "../../input";
import SnackBar from '../../snackbar/syncAlternative';

import "../style.css";

import sameAddress from '../../validators/sameAddress';

import * as addressAction from "../../store/actions/registerAddress";

const ModalRegisterAddress = (props) => {
  const addresses = useSelector((state) => state.Address.addresses);
  const dispatch = useDispatch();

  const [cnes, setCnes] = useState("");
  const [cnesError, setCnesError] = useState({ error: true, typeError: "" });

  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState({
    error: true,
    typeError: "",
  });

  const [zipCode, setCep] = useState("");
  const [cepError, setCepError] = useState({ error: true, typeError: "" });

  const [address, setAddress] = useState("");

  const [number, setNumber] = useState("");
  const [numberError, setNumberError] = useState({
    error: true,
    typeError: "",
  });

  const [complement, setComplement] = useState("");


  const [neighborhood, setNeighborhood] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const [telephone, setTelephone] = useState("");
  const [telephoneError, setTelephoneError] = useState({
    error: true,
    typeError: "",
  });

  const [whatsapp, setWhatsapp] = useState("");
  const [whatsappError, setWhatsappError] = useState({
    error: true,
    typeError: "",
  });

  const [sameAddressError, setSameAddressError] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const handleClose = () => {
    setCnes("");
    setCnesError({error: true, typeError: ""})
    setCountry("");
    setCountryError({error: true, typeError: ""})
    setCep("");
    setCepError({error: true, typeError: ""});
    setAddress("");
    setNumber("");
    setNumberError({error: true, typeError: ""});
    setComplement("");
    setNeighborhood("");
    setState("");
    setCity("");
    setTelephone("");
    setTelephoneError({error: true, typeError: ""});
    setWhatsapp("");
    setWhatsappError({error: true, typeError: ""});
    setOpenSnack(false);
  }

  async function handleValidateCep() {
    setCepError({ error: false, typeError: "" });
    if (empty(zipCode).error) {
      setAddress("");
      setNeighborhood("");
      setState("");
      setCity("");
      return setCepError({
        error: true,
        typeError: "Esse Campo é obrigatório!",
      });
    }
    const addressesDetails = await cepValidator(zipCode);
    if (addressesDetails) {
      setAddress(addressesDetails.logradouro);
      setNeighborhood(addressesDetails.bairro);
      setState(addressesDetails.uf);
      setCity(addressesDetails.localidade);
    } else {
      setAddress("");
      setNeighborhood("");
      setState("");
      setCity("");
      return setCepError({ error: true, typeError: "CEP inválido" });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if(sameAddressError) {
      setOpenSnack(true);
    }
    setCnesError(empty(cnes));
    setCountryError(empty(country));
    setNumberError(empty(number));
    setTelephoneError(telphoneValidator(telephone));
    setWhatsappError(telphoneValidator(whatsapp));

    await handleValidateCep();

    if (
        cnesError.error ||
        countryError.error ||
        cepError.error ||
        numberError.error ||
        telephoneError.error ||
        whatsappError.error ||
        sameAddressError
    ) { 
        return false;
    } else {
        const payload = {
          cnes,
          country,
          zipCode,
          address,
          number,
          complement,
          neighborhood,
          state,
          city,
          telephone,
          whatsapp,
          consultations: [],
          exams: [],
          proceedings: [],
          otherServices: [],
        };
        setCnes("");
        setCountry("");
        setCep("");
        setAddress("");
        setNumber("");
        setComplement("");
        setNeighborhood("");
        setState("");
        setCity("");
        setTelephone("");
        setWhatsapp("");

        dispatch(addressAction.registerAddress(payload));
        dispatch(addressAction.alterActiveAddress(addresses.length));
        dispatch(addressAction.toggleRegisterServices());
        
        return true;
    }
  }

  return (
    <ModalBase handleSubmit={handleSubmit} onClose={handleClose} buttonMargin={props.buttonMargin} title="Cadastrar Endereço" width="585px">
              <Input
                label={<span>CNES <small>(Para consultar o seu CNES acesse o link <a href="http://cnes.datasus.gov.br/" target="_blank" rel="noopener noreferrer">http://cnes.datasus.gov.br/</a>)</small></span>}
                placeholder="CNES"
                type="text"
                value={cnes}
                onChange={(e) => setCnes(e.target.value)}
                onBlur={() => setCnesError(empty(cnes))}
                error={cnesError.typeError}
                dataTest="modal-cadastrar-endereco-input-cnes"
              />
              <div className="file-input">
                <Input
                  width="204px"
                  label="País"
                  placeholder="País"
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  onBlur={() => setCountryError(empty(country))}
                  error={countryError.typeError}
                  dataTest="modal-cadastrar-endereco-input-pais"
                />
                <Input
                  width="204px"
                  label="CEP"
                  placeholder="CEP"
                  type="text"
                  value={zipCode}
                  onChange={(e) => setCep(maskCep(e.target.value))}
                  onBlur={() => handleValidateCep()}
                  error={cepError.typeError}
                  dataTest="modal-cadastrar-endereco-input-cep"
                />
              </div>
              <div className="file-input">
                <Input
                  width="428px"
                  label="Endereço"
                  placeholder="Endereço"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  dataTest="modal-cadastrar-endereco-input-endereco"
                />
                <Input
                  width="92px"
                  label="Número"
                  placeholder="Núm."
                  type="text"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  onBlur={() => {
                    setNumberError(empty(number));
                    const error = setSameAddressError(sameAddress(addresses,{zipCode, number}));
                    if(error) {
                      setOpenSnack(true)
                    } else {
                      setOpenSnack(false)
                    }
                  }}
                  error={numberError.typeError}
                  dataTest="modal-cadastrar-endereco-input-numero"
                />

                <Input
                  width="113px"
                  label="Complemento"
                  placeholder="Compl."
                  type="text"
                  value={complement}
                  onChange={(e) => setComplement(e.target.value)}
                  onBlur={() => {
                    setNumberError(empty(number));
                    const error = setSameAddressError(sameAddress(addresses,{zipCode, number, complement}));
                    if(error) {
                      setOpenSnack(true)
                    } else {
                      setOpenSnack(false)
                    }
                  }}
                  dataTest="modal-cadastrar-endereco-input-complemento"
                />
              </div>
              <div className="file-input">
                <Input
                  width="316px"
                  label="Bairro"
                  placeholder="Bairro"
                  type="text"
                  value={neighborhood}
                  onChange={(e) => setNeighborhood(e.target.value)}
                  dataTest="modal-cadastrar-endereco-input-bairro"
                />
                <Input
                  width="92px"
                  label="Estado"
                  placeholder="UF"
                  type="text"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  dataTest="modal-cadastrar-endereco-input-uf"
                />
                <Input
                  width="204px"
                  label="Cidade"
                  placeholder="Cidade"
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  dataTest="modal-cadastrar-endereco-input-cidade"
                />
              </div>
              <div className="file-input">
                <Input
                  width="204px"
                  label="Telefone"
                  placeholder="Telefone"
                  type="text"
                  value={maskTelephone(telephone)}
                  onChange={(e) => setTelephone(maskTelephone(e.target.value))}
                  onBlur={() => setTelephoneError(telphoneValidator(telephone))}
                  error={telephoneError.typeError}
                  dataTest="modal-cadastrar-endereco-input-phone"
                />
                <Input
                  width="204px"
                  label="Whatsapp"
                  placeholder="Whatsapp"
                  type="text"
                  value={maskTelephone(whatsapp)}
                  onChange={(e) => setWhatsapp(maskTelephone(e.target.value))}
                  onBlur={() => setWhatsappError(telphoneValidator(whatsapp))}
                  error={whatsappError.typeError}
                  dataTest="modal-cadastrar-endereco-input-whatsapp"
                />
              </div>
              <SnackBar 
                open={openSnack}
                errorMessage="Endereço já cadastrado. Insira um novo para continuar."
                onClose={setOpenSnack}
              />
    </ModalBase>
  );
};

export default ModalRegisterAddress;
