import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const styles = makeStyles({
  root: {
    "&.MuiInputBase-root": {
      border: "1px solid #e7e7ed",
      borderRadius: "4px",
      height: "40px",
      width: (props) => props.width,
      "& input": {
        textIndent: "0.4rem",
      },
      "& svg": {
        cursor: "pointer",
      },
    },
  },
  classSpan: {
    fontSize: "0.8rem",
    color: "#da1414",
  },
});
export default (props) => {
  const [visualizationPassword, setVisualizationPassword] = useState(false);
  const classes = styles(props);

  const toggleVisualizationPassword = () => {
    setVisualizationPassword(!visualizationPassword);
  };
  return (
    <div>
      <div>
        <strong className="label-input">{props.label}</strong>
      </div>
      <Tooltip title={props.tooltip || ""}>
        <Input
          className={classes.root}
          name={props.name}
          date-test={props.datatest}
          type={visualizationPassword ? "text" : "password"}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          endAdornment={
            <InputAdornment position="start">
              {visualizationPassword && (
                <VisibilityIcon onClick={toggleVisualizationPassword} />
              )}
              {!visualizationPassword && (
                <VisibilityOffIcon onClick={toggleVisualizationPassword} />
              )}
            </InputAdornment>
          }
          fullWidth={props.fullWidth}
          width={props.width}
        ></Input>
      </Tooltip>
      {props.link ? (
        <div>
          <span className="forgot-password-text">
            <a href={props.link}>{props.linkText} </a>
          </span>
        </div>
      ) : null}
      <span className={classes.classSpan}> {props.error}</span>
    </div>
  );
};
