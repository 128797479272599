import React, { useState, Fragment, useEffect } from 'react'

import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { gql, useApolloClient } from '@apollo/client'

import cpfMask from './../mask/cpf'
import InputCurrency from './../inputCurrency'
import Button from './../button'
import cpfValidator from './../validators/cpf'
import SnackBar from './../snackbar/syncAlternative'

const theme = createMuiTheme({
  palette: {
    primary: { main: "#DF9000" },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 700,
    fontStyle: 'normal',
    color: '#272833',
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 600,
    fontStyle: 'normal',
    color: '#6B6C7E',
  },
  descriptionSubtitle: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#6B6C7E'
  },
  label: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#272833'
  },
  helperNotFoundMessage: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    color: '#DA1414'
  }
}));

const QUERY_GET_PROVIDER_BY_CPF = gql`
  query($cpf: String!) {
    getProviderByCpf(cpf: $cpf) {
      id
      user {
        id
        name
        cpf
      }
      doctor {
        id
        crm
        uf
      }
    } 
  }
`

const MUTATION_SEND_EMAIL_INVITE = gql`
  mutation($email: String!) {
    sendEmailInvite(email: $email) {
      emailExists
      invitationSent
      message
    }
  }
`

export default function MedicalTeamBudget(props) {
  const classes = useStyles(props);

  const api = useApolloClient()

  const sendEmailInvite = async _email => {
    try {
      const request = await api.mutate({ mutation: MUTATION_SEND_EMAIL_INVITE, variables: { email: _email } })
      if (request.errors || request.error) return false
      return request.data.sendEmailInvite
    } catch (error) {
      return false
    }
  }

  const getHelperFromApi = async (cpf) => {
    try {
      const result = await api.query({ query: QUERY_GET_PROVIDER_BY_CPF, variables: { cpf } })
      if (result.error || result.errors) return false

      const provider = result.data.getProviderByCpf

      if (!provider) {
        return {
          id: null,
          name: null,
          crm: null,
          notFound: true
        }
      }

      return {
        id: provider.id,
        name: provider.user.name,
        cpf: provider.user.cpf,
        crm: `${provider.doctor.crm}-${provider.doctor.uf}`,
        notFound: false
      }
    } catch (error) {
      return false
    }
  }

  const userData = JSON.parse(window.localStorage.getItem("userData"));
  const [price, setPrice] = useState(props.price || 0)
  const [helper, setHelper] = useState(props.helper || [])
  const [openInviteDialog, setOpenInviteDialog] = useState(false)
  const [emailInvite, setEmailInvite] = useState('')
  const [snackInviteDialog, setSnackInviteDialog] = useState({ open: false, message: '', severity: 'success', duration: 5000 })
  const [snack, setSnack] = useState({ open: false, message: '', severity: 'success', duration: 5000 })

  const doctor = JSON.parse(localStorage.getItem('docData'))
  const doctorName = doctor.name
  const doctorCrm = `${doctor.crm}-${doctor.uf}`
  const doctorCpf = cpfMask(doctor.cpf)

  useEffect(() => {
    if (props.handleChange) props.handleChange({
      price: Number(price),
      helper: helper.filter(item => item.cpf && item.id && item.name && item.crm)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, helper])

  const getHelperByCpf = async cpf => {
    return await getHelperFromApi(cpf)
  }

  const cleanCpfMask = cpf => cpf.replace(/\.|-/g, '')

  const handleInputHelperCpf = async ({ cpf, index }) => {
    if (cpf.length > 11) return

    helper[index] = { ...helper[index], cpf }
    setHelper([...helper])

    if (cpf.length < 11) return

    if (userData.cpf === cpf) {
      setSnack({
        message: 'Você não pode adicionar você mesmo como auxiliar',
        severity: 'warning',
        open: true
      })
      return
    }

    const validateCpf = cpfValidator(cpf)
    if (validateCpf.error) {
      setSnack({
        message: 'CPF inválido',
        severity: 'info',
        open: true
      })
      return
    }

    const getHelper = await getHelperByCpf(cpf)
    if (!getHelper) {
      setSnack({
        message: 'Erro ao buscar auxiliar',
        severity: 'error',
        open: true
      })
      return
    }

    helper[index] = {
      ...helper[index],
      cpf,
      id: getHelper?.id,
      name: getHelper?.name,
      price: helper[index]?.price || 0,
      crm: getHelper?.crm,
      notFound: Boolean(getHelper.notFound)
    }

    setHelper([...helper])
  }

  const handleInputHelperData = ({ index, key, value }) => {
    if (!helper[index]) {
      setSnack({
        message: 'Erro ao atualizar os dados',
        severity: 'error',
        open: true
      })
      return
    }

    helper[index] = {
      ...helper[index],
      [key]: value
    }

    setHelper([...helper])
  }

  const NewHelperComponent = (item, index) => (
    <Grid container style={{ marginBottom: 20, borderTop: index > 0 && '1px solid #E7E7ED', paddingTop: index > 0 && 20 }} key={index}>
      <Grid item xs={12} sm={12} md={5}>
        <Grid item>
          <Typography className={classes.label}>CPF</Typography>
        </Grid>
        <Grid item>
          <TextField
            autoFocus
            variant="outlined"
            placeholder="CPF do auxiliar"
            value={cpfMask(item.cpf || '')}
            onChange={(e) => handleInputHelperCpf({ cpf: cleanCpfMask(e?.target?.value || ''), index })}
            fullWidth
            disabled={props.disableFormToEdition}
          />
          {
            Boolean(item.notFound) &&
            <Grid item style={{ marginTop: 20 }}>
              <Typography className={classes.helperNotFoundMessage}>Profissional ainda não cadastrado na plataforma do Vida Connect.</Typography>
              <Button
                onClick={() => setOpenInviteDialog(true)}
                onBlur={() => { }}
                children="Enviar convite"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
                border="1px solid #CDCED9;"
              />
            </Grid>
          }
          {
            !Boolean(item.notFound) &&
            <Grid container>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid item>
                  <Typography className={classes.label}>Nome</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    placeholder="Nome do auxiliar"
                    value={item.name || ''}
                    onChange={(e) => handleInputHelperData({ index, key: 'name', value: e?.target?.value || '' })}
                    fullWidth
                    disabled={props.disableFormToEdition}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={5} style={{ marginTop: 20 }}>
                <Grid item>
                  <Typography className={classes.label}>CRM</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    variant="outlined"
                    placeholder="CRM"
                    value={item.crm || ''}
                    onChange={(e) => handleInputHelperData({ index, key: 'crm', value: e?.target?.value || '' })}
                    fullWidth
                    disabled={props.disableFormToEdition}
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ marginLeft: 20, marginTop: 20 }}>
                <Grid item>
                  <Typography className={classes.label}>Valor em reais</Typography>
                </Grid>
                <Grid item style={{ marginTop: -15 }}>
                  <InputCurrency
                    variant="outlined"
                    placeholder="R$ XX,XX"
                    value={item.price}
                    onChange={(e) => handleInputHelperData({ index, key: 'price', value: Number(e) || 0 })}
                    fullWidth
                    readOnly={props.disableFormToEdition}
                    marginTop={'0'}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  )

  // TODO: Chamar API para fazer invite
  const handleSendInvite = async () => {
    const sendInvite = await sendEmailInvite(emailInvite)

    console.log(sendInvite)

    // Se dá erro ao enviar
    if (!sendInvite) {
      setSnackInviteDialog({
        message: 'Erro ao enviar email. Por favor, tente novamente mais tarde',
        severity: 'error',
        open: true,
        duration: undefined
      })
      return
    }

    // Se dá erro ao enviar porque o email ja esta cadastrado
    if (sendInvite.emailExists) {
      setSnackInviteDialog({
        message: sendInvite.message,
        severity: 'error',
        open: true,
        duration: undefined
      })
      return
    }

    // Se dá erro ao enviar
    if (!sendInvite.invitationSent) {
      setSnackInviteDialog({
        message: 'Erro ao enviar email. Por favor, tente novamente mais tarde',
        severity: 'error',
        open: true,
        duration: undefined
      })
      return
    }

    // Se sucesso ao enviar email
    if (sendInvite.invitationSent) {
      setSnackInviteDialog({
        message: sendInvite.message,
        severity: 'success',
        open: true,
        duration: 5000
      })
      setTimeout(() => {
        setOpenInviteDialog(false)
        setEmailInvite('')
      }, 5000)
      return
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Grid container className={classes.root}>
          <Grid item>
            <Typography className={classes.subtitle1} style={{ marginBottom: 15 }}>
              Orçamento Da Equipe Médica
            </Typography>
            <Typography className={classes.descriptionSubtitle} style={{ marginBottom: 15 }}>
              Preencha os dados da equipe cirurgica desse procedimento
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.subtitle2} style={{ marginBottom: 15 }}>
              Cirurgião
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.label}>Nome</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={7}>
            <TextField
              variant="outlined"
              placeholder="Nome do médico"
              value={doctorName}
              InputProps={{ readOnly: true }}
              style={{ width: '89.5%' }}
              disabled={props.disableFormToEdition}
            />
          </Grid>
          <Grid container style={{ marginTop: 12, marginBottom: 40 }}>
            <Grid item xs={12} sm={6} md={2}>
              <Grid item>
                <Typography className={classes.label}>CRM</Typography>
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  placeholder="CRM do médico"
                  value={doctorCrm}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled={props.disableFormToEdition}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={2} style={{ marginLeft: 20 }}>
              <Grid item>
                <Typography className={classes.label}>CPF</Typography>
              </Grid>
              <Grid item>
                <TextField
                  variant="outlined"
                  placeholder="CPF do médico"
                  value={doctorCpf}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled={props.disableFormToEdition}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={2} style={{ marginLeft: 20 }}>
              <Grid item>
                <Typography className={classes.label}>Valor em reais</Typography>
              </Grid>
              <Grid item style={{ marginTop: -15 }}>
                <InputCurrency
                  variant="outlined"
                  placeholder="R$ XX,XX"
                  value={price}
                  onChange={setPrice}
                  fullWidth
                  marginTop={'0'}
                  readOnly={props.disableFormToEdition}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.subtitle2}>
                Auxiliar
              </Typography>
              <Typography className={classes.descriptionSubtitle} style={{ marginBottom: 16 }}>
                {
                  helper.length > 0
                    ? `${helper.length} auxiliares adicionados`
                    : 'Nenhum auxiliar adicionado'
                }
              </Typography>
            </Grid>
            {helper.length > 0 && helper.map(NewHelperComponent)}
            <Grid item xs={12}>
              <Button
                onClick={() => setHelper([...helper, { id: null, name: null, price: 0, crm: null, cpf: null, notFound: false }])}
                onBlur={() => { }}
                startIcon={<AddIcon />}
                children="Adicionar Auxiliar"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
                border="1px solid #CDCED9;"
                disabled={props.disableFormToEdition}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
      {/* Modal de invite */}
      <Fragment>
        <Dialog open={openInviteDialog} aria-labelledby="form-dialog-invite" fullWidth={true} maxWidth={'sm'}>
          <DialogTitle id="form-dialog-title">Convidar para ser parceiro</DialogTitle>
          <DialogContent>
            <Typography className={classes.label}>E-mail</Typography>
            <TextField
              variant="outlined"
              value={emailInvite}
              onChange={event => setEmailInvite(event.target.value)}
              fullWidth
              disabled={props.disableFormToEdition}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenInviteDialog(false)
                setEmailInvite('')
              }}
              children="Cancelar"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              border="1px solid #CDCED9;"
              disabled={props.disableFormToEdition}
            />
            <Button
              onClick={handleSendInvite}
              children="Enviar"
              backgroundColor="#FFFFFF"
              color="#DF9000"
              border="1px solid #DF9000;"
              disabled={props.disableFormToEdition}
            />
          </DialogActions>
          <SnackBar
            open={snackInviteDialog.open}
            errorMessage={snackInviteDialog.message}
            severity={snackInviteDialog.severity}
            durantion={snackInviteDialog.duration || 5000}
            onClose={() => setSnackInviteDialog({ ...snackInviteDialog, open: false })}
          />
        </Dialog>
      </Fragment>
      <SnackBar
        open={snack.open}
        errorMessage={snack.message}
        severity={snack.severity}
        durantion={snack.duration || 5000}
        onClose={() => setSnack({ ...snack, open: false })}
      />
    </ThemeProvider>
  )
}
