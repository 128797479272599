import React from 'react'
import { View, Text } from '@react-pdf/renderer'

import { DoctorData } from './doctorData'
import { styles } from '../styles'

export const MedicalReport = ({documentPayload}) => {
    
    const {
        patientName,
        patientCpf,
        text,
    } = documentPayload
    
    return(
        <View style={styles.text}>
            <Text>Paciente: <Text style={styles.textBold}>{patientName}</Text></Text>
            <Text>CPF: <Text style={styles.textBold}>{patientCpf}</Text></Text>
            <Text style={[styles.topSeparator, styles.textBold]}>Relatório:</Text>
            <Text style={ { minHeight: 300 }}>{text}</Text>
            <DoctorData documentPayload={documentPayload}/>
        </View>
    )
}