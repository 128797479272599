import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { DoctorData } from './doctorData'
import { styles } from '../styles'
import { DispensedProduct, Interchangeability, ManualDispensation } from './specialFields'

const localStyles = StyleSheet.create({
    sale: {
        flexDirection: 'row',
        fontSize: 12,
        lineHeight: 1.5,
    },
    saleTitle: {
        borderWidth: 1,
        lineHeight: 1,
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold'
    },
    saleCard: {
        width: '50%'
    }
})

export const SpecialControlRecipe = ({documentPayload}) => {

    const { addressEmitter, 
            cityEmitter, 
            ufEmitter, 
            telephoneEmitter, 
            doctorName, 
            crm, 
            crmUf,
            emission,
            patientName,
            patientCpf,
            patientAddress,
            prescription,
            identifierBuyerName,
            identifierBuyerRg,
            identifierBuyerCompanyEmitterRg,
            identifierBuyerAddress,
            identifierBuyerCity,
            identifierBuyerUf,
            identifierBuyerTelephone,
            pharmaceuticalName,
            pharmaceuticalCrf,
            pharmaceuticalUf,
            pharmacyName,
            pharmacyAddress,
            pharmacyCity,
            pharmacyUf,
            pharmacyCnpj,
            pharmacyTelephone,
            medicineName1,
            laboratory1,
            lotNumber1,
            qtyBoxes1,
            numberRegistry1,
            medicineName2,
            laboratory2,
            lotNumber2,
            qtyBoxes2,
            numberRegistry2,
            medicineName3,
            laboratory3,
            lotNumber3,
            qtyBoxes3,
            numberRegistry3,
            medicineIntercambiality1,
            medicineIntercambialitySubstituted1,
            medicineIntercambiality2,
            medicineIntercambialitySubstituted2,
            medicineIntercambiality3,
            medicineIntercambialitySubstituted3,
    } = documentPayload
    
    const doctorPayload = {
        address: addressEmitter,
        city: cityEmitter,
        addressUf: ufEmitter,
        phone: telephoneEmitter,
        doctorName,
        crm, 
        crmUf,
        emission
    }

    return(
        <View style={styles.text}>
            
            <View style={styles.bordered}>
                <Text style={[styles.bordered, styles.textBold, {textAlign: 'center', lineHeight: 1}]}>
                    Identificação do Emitente</Text>
                <DoctorData documentPayload={doctorPayload}/>
            </View>
            
            <View style={styles.topSeparator}>
                <Text>Paciente: <Text style={styles.textBold}>{patientName}</Text></Text>
                <Text>CPF: <Text style={styles.textBold}>{patientCpf}</Text></Text>
            </View>
            <View>
                <Text>Endereço Completo: {patientAddress}</Text>
                <Text style={[styles.textBold, styles.topSeparator]}>Prescrição:</Text>
                <Text style={{minHeight: 220}}>{prescription}</Text>
            </View>

            <View style={localStyles.sale} wrap={false}>
                <View style={[styles.bordered, localStyles.saleCard]}>
                    <View style={localStyles.saleTitle}><Text>Identificação do Comprador</Text></View>
                    <View style={styles.margined}>
                        <View style={{minHeight: 40}}>
                            <Text>Nome Completo: {identifierBuyerName}</Text>
                        </View>
                        <Text>RG: {identifierBuyerRg}</Text>
                        <Text>Órgão Emissor: {identifierBuyerCompanyEmitterRg}</Text>
                        <View style={{minHeight: 40}}>
                            <Text>Endereço Completo: {identifierBuyerAddress}</Text>
                        </View>
                        <View style={styles.flexRow}>
                            <Text style={{width: '80%'}}>Cidade: {identifierBuyerCity}</Text>
                            <Text style={{width: '20%'}}>UF: {identifierBuyerUf}</Text>
                        </View>
                        <Text>Telefone: {identifierBuyerTelephone}</Text>
                    </View>
                </View>
                
                <View style={[styles.bordered, localStyles.saleCard]}>
                    <View style={localStyles.saleTitle}><Text>Identificação do Fornecedor</Text></View>
                    <View style={styles.margined}>
                        <View style={{minHeight: 40}}>
                            <Text>Nome Farmacêutico(a): {pharmaceuticalName}</Text>
                        </View>
                        
                        <View style={styles.flexRow}>
                            <Text style={{width: '80%'}}>CRF: {pharmaceuticalCrf}</Text>
                            <Text style={{width: '20%'}}>UF: {pharmaceuticalUf}</Text>
                        </View>
                        <Text>Nome Farmácia: {pharmacyName}</Text>
                        <View style={{minHeight: 40}}>
                            <Text>Endereço: {pharmacyAddress}</Text>
                        </View>
                        <View style={styles.flexRow}>
                            <Text style={{width: '80%'}}>Cidade: {pharmacyCity}</Text>
                            <Text style={{width: '20%'}}>UF: {pharmacyUf}</Text>
                        </View>
                        {/* <View style={{flexDirection: 'row'}}> */}
                            <Text>CNPJ: {pharmacyCnpj}</Text>
                            <Text>Telefone: {pharmacyTelephone}</Text>
                        {/* </View> */}
                        <Text style={{marginTop: 20, textAlign: 'center'}}>Assinatura do Farmacêutico</Text>
                        
                    </View>
                </View>

            </View>

            <View wrap={false}>
                <View style={styles.seassonTitle}>
                    <Text style={{margin: 'auto', lineHeight: 1}}>
                        Dados do(s) Produto(s) Dispensado(s)
                    </Text>
                </View>
                <View style={styles.smallText}>
                    <DispensedProduct 
                        medicineName={medicineName1}
                        laboratory={laboratory1}
                        lotNumber={lotNumber1}
                        qtyBoxes={qtyBoxes1}
                        numberRegistry={numberRegistry1}
                    />
                    <DispensedProduct 
                        medicineName={medicineName2}
                        laboratory={laboratory2}
                        lotNumber={lotNumber2}
                        qtyBoxes={qtyBoxes2}
                        numberRegistry={numberRegistry2}
                    />
                    <DispensedProduct 
                        medicineName={medicineName3}
                        laboratory={laboratory3}
                        lotNumber={lotNumber3}
                        qtyBoxes={qtyBoxes3}
                        numberRegistry={numberRegistry3}
                    />
                </View>
            </View>

            <View wrap={false}>
                <View style={styles.seassonTitle}>
                    <Text style={{margin: 'auto', lineHeight: 1}}>
                        Informações Sobre Intercambialidade
                    </Text>
                </View>
                <View style={styles.smallText} >
                    <Interchangeability 
                        medicineIntercambiality={medicineIntercambiality1}
                        medicineIntercambialitySubstituted={medicineIntercambialitySubstituted1}
                    />
                    <Interchangeability 
                        medicineIntercambiality={medicineIntercambiality2}
                        medicineIntercambialitySubstituted={medicineIntercambialitySubstituted2}
                    />
                    <Interchangeability 
                        medicineIntercambiality={medicineIntercambiality3}
                        medicineIntercambialitySubstituted={medicineIntercambialitySubstituted3}
                    />
                </View>
            </View>

            <ManualDispensation/>

        </View>

    )
}