import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Select } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { InputBase } from '@material-ui/core';
import empty from '../validators/empty';
import './style.css';

const useStyles = makeStyles({
  formControl: {
    display: 'flex',
    minWidth: props => props.minWidth ? props.minWidth : 120,
    width: props => props.width,
    marginTop: '3px'
  },
  root: {
    borderRadius: 4,
    textIndent: "0.4rem",
    height: "3rem",
    border: props => props.border ? props.border : null,
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.4'
  },
  error: {
    color: '#DA1414',
    fontSize: '12px',
    fontWeight: '400',
    marginTop: '3px'
  }
});

export default (props) => {
  const classes = useStyles(props);
  return (
    <div className={`${props.classesNameRow || ""}`}>
      <div className={`${props.classesNameCol || ""}`}>
        <span>
          <strong className="label-input">{props.label}</strong>
        </span>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            value={props.value}
            displayEmpty
            onChange={(e) => {
              props.onChange(e.target.value);
              props.onBlur(empty(e.target.value));
            }}
            input={<InputBase />}
            className={`selection ${classes.root}`}
            inputProps={{"data-test":props.dataTest}}
            disabled={props.disabled}
          >
            <MenuItem value="" selected disabled>Selecione</MenuItem>
            {
              props.itemList.map( (item, index) => {
                return (
                  <MenuItem
                    className={item.disabled ? classes.disabled : null}
                    key={`${index} ${item.value}`}
                    value={item.value}
                  >
                    {item.exhibit}
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        <span>
          <strong className={classes.error}>{props.error ? props.error : null}</strong>
        </span>
      </div>
    </div>
  )
};
