export default Object.freeze({
  CONSULTA: 'CONSULTA',
  OUTROS: 'OUTROS',
  PROCEDIMENTOS_DIAGNOSTICOS: 'PROCEDIMENTOS_DIAGNOSTICOS',
  PROCEDIMENTOS_TERAPEUTICOS: 'PROCEDIMENTOS_TERAPEUTICOS',
  EXAMES_LABORATORIAIS: 'EXAMES_LABORATORIAIS',
  INSUMOS_E_MATERIAIS: 'INSUMOS_E_MATERIAIS',
  HEMODERIVADOS: 'HEMODERIVADOS',
  MEDICINA_TRANSFUSIONAL: 'MEDICINA_TRANSFUSIONAL',
  EXAMES_DE_IMAGEM: 'EXAMES_DE_IMAGEM',
  OUTROS_PROFISSIONAIS: 'OUTROS_PROFISSIONAIS',
})
