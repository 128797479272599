import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, List, ListItem, Avatar, Chip, Tooltip } from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import { useHistory } from 'react-router-dom';

import * as scheduleExamsService from '../../../services/scheduledExams'
import * as scheduledExamActions from '../../../common/store/actions/scheduledExam.action'

import FilterMenu from '../../../common/selectList/alternativeWithCheckbox';
import * as validateTokenActions from '../../../common/store/actions/validateToken.action'

import ValidateTokenModal from '../../scheduledExams/modals/validateToken'
import StartExamAppointmentModal from '../../scheduledExams/modals/startExamAppointmentModal'
import { handlePatientName } from '../../../common/handlePatientName';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0',
    fontWeight: '400',
    paddingBottom: '60px',
  },
  headers: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingLeft: "2.5%",
    color: "#6B6C7E",
    boxSizing: 'border-box',
  },
  item: {
    height: '64px',
    backgroundColor: "#FFFFFF",
    border: '1px solid #E7E7ED',
    padding: '0',
  },
  combo: {
    display: 'flex',
    width: '100%',
    paddingLeft: '2.5%',
    height: '100%',
    boxSizing: 'border-box',
  },
  line: {
    height: '40px',
    width: "100%",
    margin: "auto",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },
  date: {
    width: '15%',
  },
  hour: {
    width: '10%',
  },
  type: {
    width: '13%',
    marginLeft: '30px',
  },
  patient: {
    width: '28%',
    marginLeft: '30px',
    display: 'flex',
  },
  exam: {
    width: '28%',
    marginLeft: '30px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  text: {
    marginLeft: '3%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& > .cpf": {
      color: "#6B6C7E"
    }
  },
  status: {
    width: '25%',
    marginLeft: '30px',
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',
    ".makeStyles-headers-33 > &": {
      justifyContent: "flex-start",
    },
    "& .box": {
      width: '90%',
    },
    "& .PENDING_TOKEN_VALIDATION": {
      backgroundColor: "#F7F8F9",
      color: "#6B6C7E",
    },
    "& .TOKEN_VALIDATED": {
      backgroundColor: "#EEF2FA",
      color: "#2E5AAC",
    },
    "& .REJECTED_TRIAGE": {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .PENDING_RESULTS": {
      backgroundColor: "#FEEFEF",
      color: "#DF9000",
    },
    "& .RESULTS_SENDED": {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
    },
    "& .PENDING_PAYMENT": {
      backgroundColor: "#FFF4EC",
      color: "#B95000",
    }
  },
}))




export const ScheduledExams = ({ page, limit, date, setLoading, setCount }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [selectedDoctor, setDoctor] = useState(false);
  const history = useHistory();
  const [statusSelected, setStatusSelected] = useState([
    'TOKEN_VALIDATED',
    'PENDING_TOKEN_VALIDATION',
    'PENDING_PAYMENT',
    'PENDING_RESULTS',
    'RESULTS_SENDED',
  ])

  const statusUpdated = useSelector((state) => state.ScheduledExamReducer.statusConsultation);
  const doctorsList = useSelector(state => state.SecretariesReducer.doctors)

  const userType = localStorage.getItem('userType')
  const employeeId = localStorage.getItem('employeeId')

  const [listConsultations, setListConsultations] = useState([])
  const [selectedConsultation, setSelectedConsultation] = useState()

  const loadConsultations = async () => {
    const providerId = selectedDoctor || null
    const consultations = await scheduleExamsService.getExamAppointmentsDaily(
      providerId,
      userType,
      page,
      limit,
      statusSelected,
      employeeId,
      date
    )

    if (!consultations.error) {
      setListConsultations(consultations.patientsList)
      setCount(consultations.totalPatientsOnDay)
    }

    else {
      console.log(consultations.error)
    }
  }

  const selectPatient = (consultation) => () => {
    consultation.dateTimeBeginAppointmentUtc = consultation.examAppointment[0]?.dateTimeBeginAppointmentUtc
    setSelectedConsultation(consultation)
    switch (consultation.examAppointment[0]?.status) {
      case "TOKEN_VALIDATED":
        if (userType === 'PROVIDER_DOCTOR') {

          window.localStorage.setItem('patientId', consultation.patient.id);
          dispatch(scheduledExamActions.storeData(consultation));
          dispatch(scheduledExamActions.openStartModal(true));
        }
        break;
      case "PENDING_RESULTS":
      case "RESULTS_SENDED":
        if (userType === 'PROVIDER_DOCTOR') {
          dispatch(scheduledExamActions.storeData(consultation));
          history.push('/atendimento/exame');
        }
        break;
      case "PENDING_TOKEN_VALIDATION": {
        dispatch(validateTokenActions.storeValidateTokenData(consultation))
        dispatch(validateTokenActions.openModal(true))
        break
      }
      default:
        break;
    }
  };

  const RenderChip = ({ status }) => {
    const statusConversion = {
      PENDING_TOKEN_VALIDATION: "Aguardando paciente",
      PENDING_PAYMENT: "Aguardando pagamento",
      TOKEN_VALIDATED: "Token validado",
      PENDING_RESULTS: 'Resultado pendente',
      RESULTS_SENDED: 'Resultado enviado'

    }

    return (
      <Chip
        size='small'
        label={statusConversion[status]}
        className={`${status} box`}
      />
    )
  }

  useEffect(() => {
    setDoctor(doctorsList.map(doctor => doctor.provider.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorsList])

  useEffect(() => {
    if ((selectedDoctor && userType === "PROVIDER_SECRETARY") || userType === "PROVIDER_DOCTOR")
      loadConsultations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, date, statusUpdated, statusSelected, selectedDoctor])

  return (
    <>
      <List className={classes.root}>
        <div className={classes.headers}>
          <div className={classes.date}>
            Data
          </div>
          <div className={classes.hour}>
            Hora
          </div>
          <div className={classes.patient}>
            Paciente
          </div>
          <div className={classes.exam}>
            Exame
          </div>
          <div className={classes.status}>

            Status
            <FilterMenu
              selectItems={
                [
                  { label: "Token Validado", value: "TOKEN_VALIDATED" },
                  { label: "Aguardando Paciente", value: "PENDING_TOKEN_VALIDATION" },
                  { label: "Aguardando Pagamento", value: "PENDING_PAYMENT" },
                  { label: 'Resultado Pendente', value: "PENDING_RESULTS" },
                  { label: 'Resultado Enviado', value: 'RESULTS_SENDED' }
                ]
              }
              selected={statusSelected}
              setSelected={setStatusSelected}
            />

          </div>
        </div>

        {listConsultations.map((consultation, index) => {
          const date = format(parseISO(consultation.examAppointment[0]?.dateTimeBeginAppointmentUtc), "dd/MM/yyyy");
          const hour = format(parseISO(consultation.examAppointment[0]?.dateTimeBeginAppointmentUtc), 'HH:mm');

          return (
            <ListItem
              className={classes.item}
              key={consultation.id}
              onClick={selectPatient(consultation)}
              dense
              button
            >
              <div className={classes.combo}>
                <div className={classes.line}>
                  <div className={classes.date}>
                    {date}
                  </div>

                  <div className={classes.hour}>
                    {hour}
                  </div>
                  <div className={classes.patient}>
                    <Avatar />
                    <div className={classes.text}>
                      <span>{handlePatientName(consultation.patient)} </span>
                      <span className="cpf">{consultation.patient.user.email}</span>
                    </div>

                  </div>

                  <div className={classes.exam}>
                    <Tooltip title={consultation.allServicesProvidedDescription} arrow placement="left">
                      <span>{consultation.allServicesProvidedDescription}</span>
                    </Tooltip>
                  </div>

                  <div className={classes.status}>
                    <div className="box" >
                      <RenderChip
                        status={consultation.examAppointment[0]?.status}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ListItem>
          )
        })
        }
      </List>


      <StartExamAppointmentModal
        consultation={selectedConsultation}
      />

      <ValidateTokenModal
        consultation={selectedConsultation}
      />

    </>
  )
}
