import React from 'react'
import { pdf } from '@react-pdf/renderer'
import axios from 'axios';

import { RootPdf } from './rootPdf'

export async function generatePdf(documentPayload, authenticationPayload){
  
  const {fileName, user, providerId, medicalAppointmentId, patientId, documentType} = authenticationPayload

  const file = await pdf(<RootPdf documentPayload={documentPayload}/>).toBlob()

  const data = new FormData();
  data.append("file", file, "file");
  data.append("fileName", JSON.stringify(fileName));
  data.append("user", JSON.stringify(user));
  data.append("providerId", providerId);
  data.append("medicalAppointmentId", medicalAppointmentId);
  data.append("patientId", patientId);
  data.append("documentType", documentType);
  try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/certification/techcert`, data, {
          headers: {
              "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
      })
      return res.data;
  } catch(error) {
      console.log(error);
  }
}