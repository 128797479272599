import graphqlApi from '../../services/api';

export const leavingARoom = async (room, setLive) => {
    room.localParticipant.tracks.forEach((publication) => {
            publication.track.stop();
            room.localParticipant.unpublishTrack(publication.track);
    });
    room.disconnect();
    setLive(false);
}

export const letApiKnowDoctorIsOut = async (medicalAppointmentId) => {
    const res = await graphqlApi({
        data: {
            query: 
            `
                mutation($medicalAppointmentId: ID!) {
                    removeDoctorFromVideochat(medicalAppointmentId: $medicalAppointmentId)
                }
            `,
            variables: {
                medicalAppointmentId
            }
        }
    });
    return res;
}