import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import AddressList from '../../common/addressesPage';
import DoctorsList from '../../common/doctor/doctorsPage';

import * as scheduleActions from '../../../../common/store/actions/schedule.action';

const Schedule = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [selectedProvider, setProvider] = useState(false);

    const getProviderId = (value) => {
        const providerId = value.provider.id; 
        window.localStorage.setItem("Prov-Vida-Connect", providerId);
        // window.localStorage.setItem("Prov-Vida-Connect", value.providerId);
        window.localStorage.setItem("Secretary-Selected-Doctor", JSON.stringify(value))
        setProvider(value?.provider);
    }

    const select = (value) => {
        dispatch(scheduleActions.setUnit(value));
        if(value) {
            window.localStorage.setItem("Unit-Vida-Connect", value.id);
            window.localStorage.setItem("ad", value.address);
            window.localStorage.setItem("n", value.number);
            if (Boolean(value?.employeeId)) window.localStorage.setItem("employeeId", value.employeeId);
            else window.localStorage.removeItem('employeeId')
            if (value.complement) window.localStorage.setItem("comp", value.complement);
            if (value?.establishmentId) window.localStorage.setItem("establishmentId", value.establishmentId)
        }
        history.push("/administrador/consultas/agenda");
    }

    if(Boolean(selectedProvider)) {
        return (
            <AddressList
                title="Agenda"
                subtitle="Selecione uma unidade para ver a agenda de consultas"
                provider={selectedProvider}
                onClick={select}
            />
        )
    }

    return (
        <DoctorsList
            title="Médicos"
            subtitle="Selecione o médico que você deseja acessar a agenda"
            onClick={getProviderId}
        />
    );
}

export default Schedule;
