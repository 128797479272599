import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { format } from 'date-fns'
import Button from '@material-ui/core/Button';
import { handlePatientName } from '../../../../common/handlePatientName';


export default (props) => {
  const {
    open,
    handleClose,
    modalContent,
  } = props

  const handleDocumentClick = (item) => {
    window.open(item.file)
  }
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {modalContent?.allServicesProvidedDescription}<br />
              Paciente: { handlePatientName(modalContent?.examAppointment[0]?.patient)}<br />
              Em: {format(new Date(modalContent?.examAppointment[0]?.dateTimeBeginAppointmentUtc || '2020-01-01'), 'dd/MM/yyyy - HH:mm')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
       
            <div style={{ marginTop: 40 }}>
              <h3><strong>Resultados de exames</strong></h3>
              {!modalContent?.examAppointment?.length ? 'Não há informação' : modalContent?.examAppointment?.map((item, index) => (
                
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '25px'}} key={index}>
                    {item.providerHasServicesProvided.serviceProvided.description}:
                    {item.examAppointmentFiles.map((itemFile, index) => (
                  <Button
                    variant="outlined"
                    style={{ borderColor: '#DF9000', color: '#DF9000', marginTop: 10, maxWidth: '50%' }}
                    onClick={() => handleDocumentClick(itemFile)}
                  >
                    {itemFile.originalName}
                  </Button>
                  
                    ))}
                </div>
              ))}
            </div>
        
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            OK
              </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
