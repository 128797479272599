import React from 'react'

import Button from '../button'

export default ({fontWeight, fontSize}) => {
  const handleClick = () => {
    if (!process.env.REACT_APP_EXAMS_SPREADSHEET) {
      console.log('ENV REACT_APP_EXAMS_SPREADSHEET not found ')
      return
    }
    window.open(process.env.REACT_APP_EXAMS_SPREADSHEET, '_blank')
  }

  return (
    <Button
      width="190px"
      heigth="32px"
      border="1px solid #CDCED9"
      backgroundColor="#FFFFFF"
      color="#6B6C7E"
      margin="0 5px 0 0"
      fontSize= {fontSize || "14px"}
      fontWeight= {fontWeight || "400"}
      padding="6px 12px"
      onClick={handleClick}
      children="Baixar planilha"
    />
  )
}
