import empty from "./empty";

export default (fileInput, maxSizeAccept) => {
  if (empty(fileInput.name).error) {  
    return { error: true, typeError: "Esse Campo é obrigatório!" };
  } else if (fileInput.size / (1024  * 1024) > maxSizeAccept) {
    return { error: true, typeError: `O arquivo selecionado é maior que o tamanho máxímo aceito(${maxSizeAccept}MB)` };
  }
  return { error: false, typeError: "" }; 
}

export const validationFileType = (typeFile, typesAccepted) => {
  if (Array.isArray(typesAccepted)) return typesAccepted.includes(typeFile)

  return typeFile === typesAccepted
}