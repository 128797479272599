const INITIAL_STATE = {
    secretaries: [],
    doctors: [],
    activeIndex: undefined,
    openAddModal: false,
    loading: false,
}

const secretariesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_SECRETARY":
            return({
                ...state,
                secretaries: [...state.secretaries, action.payload]
            })
        case "DELETE_SECRETARY":
            return {
                ...state,
                secretaries: state.secretaries.filter(
                  (el, index) => index !== action.payload
                ),
            };
        case "UPDATE_SECRETARY":
            return {
                ...state,
                secretaries: state.secretaries.map((el, index) => {
                    if(index === state.activeIndex) {
                        return {...el, ...action.payload}
                    }
                    return el
                }),
            }
        case "OPEN_ADD_MODAL_SECRETARIES":
            return {
                ...state,
                openAddModal: action.payload
            }
        case "ALTER_ACTIVE":
            return{
                ...state,
                activeIndex: action.payload
            }
        case "GET_SECRETARIES_LIST":
            return {
                ...state,
                loading: true,
            }
        case "GET_DOCTORS_LIST":
            return {
                ...state,
                loading: true,
            }
        case "SET_SECRETARIES_LIST":
            return {
                ...state,
                secretaries: action.payload
            }
        case "SET_DOCTORS_LIST":
            return {
                ...state,
                doctors: action.payload,
            }
        case "STOP_WAITING_SECRETARY_DATA":
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
}

export default secretariesReducer;