import axios from "axios";
import apiGraphql from "../../../services/api";
import * as personalDataActions from "../actions/personalDatas";
import * as consultationActions from "../actions/registerConsultations";
import * as startConsultationActions from "../actions/consultation.action";
import * as scheduleActions from "../actions/schedule.action";
import * as unitActions from "../actions/units.action";
import * as triageActions from "../actions/triage.action";
import * as secretaryActions from "../actions/secretaries.action";
import * as medicalAppointmentsActions from '../actions/medicalAppointments'
import * as virtuaActions from '../actions/virtua.action'
import * as examActions from '../actions/registerExams'
import * as scheduledExamsActions from '../actions/scheduledExam.action'
import format from "date-fns/format";
import { handlePatientName } from "../../handlePatientName";

axios.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      const token = window.localStorage.getItem("Token-Vida-Connect");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

const urlGraphQl = `${process.env.REACT_APP_API_URL}/graphql`;
const urlDoctorRest = `${process.env.REACT_APP_API_URL}/provider/complete-register-doctor`;
const urlOtherRest = `${process.env.REACT_APP_API_URL}/provider/complete-register-other-professionals`

export const requestSent = () => {
  return {
    type: "REQUEST_SENT",
  };
};

export const requestSuccess = (payload) => {
  return {
    type: "REQUEST_SUCCESS",
    payload,
  };
};

export const requestFailure = (payload) => {
  return {
    type: "REQUEST_FAILURE",
    payload,
  };
};

export function sendData(data) {
  return {
    type: "SEND_DATA",
    data,
  };
}

export function loginData(payload) {
  return {
    type: "REGISTER_LOGIN_DATA",
    payload,
  };
}

export function cleanError() {
  return {
    type: "CLEAN_ERROR",
  };
}

export function login(insertedEmail, insertedPassword, history) {
  return async (dispatch) => {
    dispatch(requestSent());
    window.localStorage.clear();
    try {
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `mutation($login:LoginProviderInput!) {
                        loginProvider(input:$login){
                            sessionToken
                            user {
                                id
                                name
                                cpf
                                email
                                phone
                                status
                                type
                                provider {
                                    id
                                    doctor {
                                        id
                                        crm
                                        uf
                                        bank
                                        agency
                                        account
                                        careModality
                                        numberOfInstallments
                                        minimumInstallmentAmount
                                    
                                    }
                                    secretary {
                                        id
                                    }
                                    professionalHasProvider {
                                      id
                                      uf
                                      bank
                                      agency
                                      account
                                      careModality
                                      professionalCouncilId
                                      minimumInstallmentAmount
                                      numberOfInstallments
                                      professionalCouncil {
                                        name
                                      }
                                      profession {
                                        profession
                                      }
                                    }
                                }
                            }
                        }
                    }`,
          variables: {
            login: {
              email: insertedEmail,
              password: insertedPassword,
            },
          },
        },
      });

      if (res.data.data) {
        const userData = res.data.data.loginProvider;
        const userId = userData.user.id;
        const token = userData.sessionToken;
        const name = userData.user.name;
        const email = userData.user.email;
        const cpf = userData.user.cpf;
        const phone = userData.user.phone;
        const status = userData.user.status;
        const type = userData.user.type;
        window.localStorage.setItem("Token-Vida-Connect", token);
        window.localStorage.setItem("User-Vida-Connect", userId);
        const user = { name, email, cpf, phone };
        window.localStorage.setItem("userData", JSON.stringify(user));
        window.localStorage.setItem("status", status);
        window.localStorage.setItem("userType", type);
        dispatch(
          personalDataActions.personalDatasPf({
            name,
            email,
            cpf,
            telephone: phone,
          })
        );
        const providerData = userData.user.provider;

        if (status === "OK" && providerData) {
          console.log(providerData)
          if (type === "PROVIDER_DOCTOR") {
            dispatch(isAnEmployee());
            const providerId = providerData.id;
            const doctorId = providerData.doctor.id;
            const crm = providerData.doctor.crm;
            const uf = providerData.doctor.uf;
            const bank = providerData.doctor.bank;
            const agency = providerData.doctor.agency;
            const account = providerData.doctor.account;
            const supportType = providerData.doctor.careModality;
            const numberPlots = providerData.doctor.numberOfInstallments;
            const minAmount = providerData.doctor.minimumInstallmentAmount;
            let specialities = null;
            if (providerData.doctor.specialities)
              specialities = providerData.doctor.specialities;
            window.localStorage.setItem("Prov-Vida-Connect", providerId);
            const docData = {
              name,
              doctorId,
              email,
              cpf,
              crm,
              uf,
              supportType,
              bank,
              account,
              agency,
              numberPlots,
              minAmount,
            };
            window.localStorage.setItem("docData", JSON.stringify(docData));
            dispatch(
              personalDataActions.personalDatasPf({
                crm,
                uf,
                bank,
                agency,
                account,
                supportType,
                numberPlots,
                minAmount,
                specialities,
              })
            );
          }

          if (type === "PROVIDER_SECRETARY") {
            const secretaryId = providerData.secretary.id;
            const secData = { secretaryId, name, email };
            window.localStorage.setItem("secData", JSON.stringify(secData));
            dispatch(
              personalDataActions.personalDatasPf({
                name,
                email,
              })
            );
          }

          if (type === 'PROVIDER_OTHER_PROFESSIONALS') {
            dispatch(isAnEmployee());
            const providerId = providerData.id;
            const professionalId = providerData.professionalHasProvider.id;
            const uf = providerData.professionalHasProvider.uf;
            const bank = providerData.professionalHasProvider.bank;
            const agency = providerData.professionalHasProvider.agency;
            const account = providerData.professionalHasProvider.account;
            const supportType = providerData.professionalHasProvider.careModality;
            const numberPlots = providerData.professionalHasProvider.numberOfInstallments;
            const minAmount = providerData.professionalHasProvider.minimumInstallmentAmount;
            let specialities = null;
            if (providerData.professionalHasProvider.specialities)
              specialities = providerData.professionalHasProvider.specialities;
            
            const provData = {
              name,
              professionalId,
              email,
              cpf,
              uf,
              supportType,
              bank,
              account,
              agency,
              numberPlots,
              minAmount,
            };
            window.localStorage.setItem("providerData", JSON.stringify(provData));
            
              window.localStorage.setItem(
              'profession', 
              providerData.professionalHasProvider.profession.profession
            )
            window.localStorage.setItem("Prov-Vida-Connect", providerId);
            
            dispatch(
              personalDataActions.personalDatasPf({
                
                uf,
                bank,
                agency,
                account,
                supportType,
                numberPlots,
                minAmount,
                specialities,
              })
            );
          }

        }

        switch (status) {
          case "OK":
            history.push("/administrador");
            break;
          case "EMAIL_VERIFIED":
            history.push("/cadastro/pf");
            break;
          case "PENDING_APPROVAL":
            history.push("/cadastro/pf/aguardando");
            break;
          default:
            dispatch(
              requestFailure(
                "Você ainda precisa validar seu e-mail. Se você já validou seu e-mail e preencheu o formulário, seu cadastro na plataforma foi recusado."
              )
            );
            history.push("/");
            break;
        }

        dispatch(requestSuccess());
      } else {
        dispatch(requestFailure("Email ou senha inválidos."));
      }
    } catch (error) {
      console.log(error);
      dispatch(
        requestFailure(
          "Houve um problema durante o seu login. Tente novamente."
        )
      );
    }
  };
}

function waitingTokenValidation() {
  return {
    type: "WAITING_TOKEN_VALIDATION",
  };
}

export const checkToken = (history, route, status) => {
  return async (dispatch) => {
    dispatch(waitingTokenValidation());
    try {
      const isValid = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                    query {
                        checkSessionTokenProvider
                    }
                    `,
        },
      });
      if (isValid.data.errors) {
        history.push(route);
        window.localStorage.clear();
      } else {
        dispatch(isAnEmployee());
        const route = window.location.href.split("/");
        if (route[3] === "") {
          history.push("/administrador");
          dispatch(requestSuccess());
          return;
        }
        switch (status) {
          case "OK":
            break;
          case "EMAIL_VERIFIED":
            history.push("/cadastro/pf");
            break;
          case "PENDING_APPROVAL":
            history.push("/cadastro/pf/aguardando");
            break;
          default:
            history.push("/");
            break;
        }
      }

      dispatch(requestSuccess());
    } catch (error) {
      dispatch(requestFailure(error));
    }

  };
};

export function checkPreRegister(payload) {
  return async (dispatch) => {
    try {
      dispatch(personalDataActions.waitingData());
      dispatch(requestSent());
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                        query($userId: String!) {
                            checkPreRegister(userId: $userId) {
                                name
                                email
                                cpf
                                phone
                            }
                        }
                    `,
          variables: {
            userId: `${payload}`,
          },
        },
      });
      const data = res.data.data.checkPreRegister;
      const name = data.name;
      const email = data.email;
      const cpf = data.cpf;
      const phone = data.phone;
      dispatch(
        personalDataActions.personalDatasPf({
          name,
          email,
          cpf,
          telephone: phone,
        })
      );
      dispatch(requestSuccess());
      dispatch(personalDataActions.searchIsDone());
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema ao carregar suas informações automaticamente. Insira-as manualmente, por favor."
        )
      );
      dispatch(personalDataActions.searchIsDone());
    }
  };
}

export function isAnEmployee(payload) {
  return async (dispatch) => {
    try {
      dispatch(requestSent());
      const res = await axios({
        method: "POST",
        url: urlGraphQl,
        data: {
          query: `
                        query {
                            searchWhereProviderIsAnEmployee {
                                id
                                establishment {
                                    id
                                    clinic {
                                        tradingName
                                        companyName
                                    }
                                    hospital {
                                        tradingName
                                        companyName
                                    }
                                }
                            }
                        }
                    `,
        },
      });
      const status = localStorage.getItem('status')
      if (status === 'OK') {
        const establishmentsList = res.data.data.searchWhereProviderIsAnEmployee.map(
          (estab) => {
            const data = estab.establishment;
            return {
              name: data?.clinic?.tradingName || data?.hospital?.tradingName,
              employeeId: estab.id,
              establishmentId: data.id,
              type: "employee",
            };
          }
        );
        establishmentsList.unshift({
          name: "Particular",
          employeeId: "",
          establishmentId: "",
          type: "private",
        });
        window.localStorage.setItem(
          "estab_list",
          JSON.stringify(establishmentsList)
        );
        if (!localStorage.getItem('estabName')) {
          window.localStorage.setItem("establishmentId", "");
          window.localStorage.setItem("employeeId", "");
          window.localStorage.setItem("estabName", "Particular");
        }
      }

    } catch (error) {
      dispatch(
        requestFailure(
          "Não foi possível verificar se você já tem um convite ativo de alguma clínica ou hospital."
        )
      );
    }
  };
}

export function getSpecialities() {
  return async (dispatch) => {
    dispatch(requestSent());
    try {
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                        query {
                            listSpecialities(input: {limit:10000} ) {
                                rows {
                                    id
                                    name
                                    group
                                    requireRqe
                                }
                            }
                        }
                    `,
        },
      });
      dispatch(requestSuccess());
      const data = res.data.data.listSpecialities.rows;
      dispatch(setResponse({ answerType: "specialities", data }));
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um erro de conexão com nosso servidor.Tente novamente, por favor."
        )
      );
    }
  };
}

export function listDoctorsBySpeciality(specialityId) {
  return async (dispatch) => {
    dispatch(requestSent());
    try {
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
            query {
              listDoctorsBySpeciality(specialityId: "${specialityId}" ) {
                  provider {
                    id
                    user {
                      name
                    }
                    providerHasSpecialities {
                      speciality {
                        id
                      }
                    }
                  }
                }
            }
        `,
        },
      });

      dispatch(requestSuccess());
      const data = res.data.data.listDoctorsBySpeciality.map(doctor => ({
        id: doctor.provider.id,
        name: doctor.provider.user.name,
        specialities: doctor.provider.providerHasSpecialities
      }));
      dispatch(setResponse({ answerType: "doctors", data }));
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um erro de conexão com nosso servidor.Tente novamente, por favor."
        )
      );
    }
  };
}

export function getHealthOperators() {
  return async (dispatch) => {
    dispatch(requestSent());
    try {
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                        query {
                            listHealthOperators(input:{}) {
                                rows {
                                    id
                                    name: fantasyName
                                    companyName
                                    plans {
                                        id
                                        name
                                        healthOperatorId
                                    }
                                }
                            }
                        }
                    `,
        },
      });
      const data = res.data.data.listHealthOperators.rows;
      dispatch(setResponse({ answerType: "healthOperators", data }));
      dispatch(requestSuccess());
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um erro de conexão com nosso servidor.Tente novamente, por favor."
        )
      );
    }
  };
}

export const sendToApi = (payload, type, successAction) => {
  const urlRest = type === 'doctor' ? urlDoctorRest : urlOtherRest
  return async (dispatch) => {
    try {
      dispatch(requestSent());
      await axios({
        method: "post",
        url: urlRest,
        data: payload,
      });
      dispatch(requestSuccess());
      successAction();
    } catch (error) {
      const result = error.response;
      if (result) {
        const status = result.status;

        if (status === 400) {
          dispatch(
            requestFailure(
              result.data.message ||
              "Houve um erro de conexão com nosso servidor.Tente novamente, por favor."
            )
          );
        }
        return;
      }
      dispatch(
        requestFailure(
          "Houve um erro de conexão com nosso servidor.Tente novamente, por favor."
        )
      );
    }
  };
};

export function setResponse(payload) {
  return {
    type: "SET_ASYNC_RESPONSE",
    payload,
  };
}

export function createSchedule({
  successAction,
  beginSchedule,
  endSchedule,
  appointmentDuration,
  daysOfWeek,
  providerId,
  unitId,
}) {
  return async (dispatch) => {
    dispatch(requestSent());
    try {
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `mutation(
                            $input: ScheduleHasProviderInput!
                        ) {
                            createProviderSchedule(input: $input)
                            {
                                id
                            }
                        }`,
          variables: {
            input: {
              beginSchedule,
              endSchedule,
              appointmentDuration,
              daysOfWeek,
              providerId,
              unitId,
            },
          },
        },
      });
      if (!res.data.data) {
        const error = res.data.errors[0].message;
        dispatch(requestFailure(error));
      } else {
        dispatch(requestSuccess());
        successAction();
      }
    } catch (err) {
      dispatch(
        requestFailure(
          "Houve um problema na criação da sua agenda.Tente novamente, por favor."
        )
      );
    }
  };
}

export function createEmployeeSchedule({
  successAction,
  beginSchedule,
  endSchedule,
  appointmentDuration,
  daysOfWeek,
  employeeId,
  unitId,
}) {
  return async (dispatch) => {
    dispatch(requestSent());
    try {
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `mutation(
                            $input: ScheduleHasEmployeeInput!
                        ) {
                            createEmployeeSchedule(input: $input)
                            {
                                id
                            }
                        }`,
          variables: {
            input: {
              beginSchedule,
              endSchedule,
              appointmentDuration,
              daysOfWeek,
              employeeId,
              unitId,
            },
          },
        },
      });
      if (!res.data.data) {
        dispatch(
          requestFailure(
            "Houve um problema na criação da sua agenda.Tente novamente, por favor."
          )
        );
      } else {
        dispatch(requestSuccess());
        successAction();
      }
    } catch (err) {
      dispatch(
        requestFailure(
          "Houve um problema na criação da sua agenda.Tente novamente, por favor."
        )
      );
    }
  };
}

export function getSchedules(payload) {
  return async (dispatch) => {
    dispatch(scheduleActions.waitingResults());
    dispatch(requestSent());
    try {
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                        query($providerId: String!, $unitId: String!) {
                            getProviderSchedules(providerId: $providerId, unitId:$unitId) {
                                schedule{
                                    id
                                    scheduleGroupProviderId
                                    beginSchedule
                                    endSchedule
                                    appointmentDuration
                                    dayOfWeek
                                    beginShift
                                    beginShiftUtc
                                    endShift
                                    endShiftUtc
                                    providerId
                                    unitId
                                }
                                unavailabilities {
                                    id
                                    scheduleGroupProviderId
                                    beginUnavailability
                                    dateTimeBeginUnavailabilityUtc
                                    endUnavailability
                                    dateTimeEndUnavailabilityUtc
                                    date
                                    active
                                    status
                                    medicalAppointment {
                                        id
                                        patient {
                                            socialName
                                            user {name}
                                        }
                                    }
                                }
                            }
                        }
                    `,
          variables: {
            providerId: `${payload.providerId}`,
            unitId: `${payload.unitId}`,
          },
        },
      });
      if (res.data.data) {
        const scheduleData = res.data.data.getProviderSchedules.map(
          (fullSchedule) => fullSchedule.schedule
        );
        const appointmentsData = res.data.data.getProviderSchedules.map(
          (fullSchedule) => fullSchedule.unavailabilities
        );

        dispatch(scheduleActions.updateSchedule(scheduleData));
        dispatch(scheduleActions.updateAppointments(appointmentsData));
        
        dispatch(requestSuccess());
      } else {
        dispatch(scheduleActions.updateSchedule([]));
        dispatch(scheduleActions.searchIsDone());
        dispatch(requestSuccess());
      }
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na atualização da sua agenda. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function getEmployeeSchedules(payload) {
  return async (dispatch) => {
    dispatch(scheduleActions.waitingResults());
    dispatch(requestSent());
    try {
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                        query($employeeId: String!, $unitId: String!) {
                            getEmployeeSchedules(employeeId: $employeeId, unitId:$unitId) {
                                schedule{
                                    id
                                    scheduleGroupEmployeeId
                                    beginSchedule
                                    endSchedule
                                    appointmentDuration
                                    dayOfWeek
                                    beginShift
                                    beginShiftUtc
                                    endShift
                                    endShiftUtc
                                    employeeId
                                    unitId
                                }
                                unavailabilities {
                                    id
                                    scheduleGroupEmployeeId
                                    beginUnavailability
                                    dateTimeBeginUnavailabilityUtc
                                    endUnavailability
                                    dateTimeEndUnavailabilityUtc
                                    date
                                    active
                                    status
                                    medicalAppointment {
                                        id
                                        patient {
                                            socialName
                                            user {name}
                                        }
                                    }
                                }
                            }
                        }
                    `,
          variables: {
            employeeId: `${payload.employeeId}`,
            unitId: `${payload.unitId}`,
          },
        },
      });
      if (res.data.data) {
        const scheduleData = res.data.data.getEmployeeSchedules.map(
          (fullSchedule) => fullSchedule.schedule
        );
        const appointmentsData = res.data.data.getEmployeeSchedules.map(
          (fullSchedule) => fullSchedule.unavailabilities
        );
        const approvedOrPendingPatients = appointmentsData.map(
          (appointmentsSchedule) => {
            return appointmentsSchedule.filter((appointment) => {
              return (
                appointment.status.includes("OK") ||
                appointment.status.includes("PENDING")
              );
            });
          }
        );
        dispatch(scheduleActions.updateSchedule(scheduleData));
        dispatch(scheduleActions.updateAppointments(appointmentsData));
        dispatch(scheduleActions.schedulePatients(approvedOrPendingPatients));
        dispatch(requestSuccess());
      } else {
        dispatch(scheduleActions.updateSchedule([]));
        dispatch(scheduleActions.searchIsDone());
        dispatch(requestSuccess());
      }
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na atualização da sua agenda. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function getUnits(payload) {
  return async (dispatch) => {
    try {
      dispatch(unitActions.waitingUnits());
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                        query($input: ListUnitsForProvidersInput!) {
                            listUnitsForProviders(input: $input) {
                                rows {
                                    id
                                    address
                                    number
                                    complement
                                }
                            }
                        }
                    `,
          variables: {
            input: {
              providerId: `${payload}`,
            },
          },
        },
      });
      const data = res.data.data.listUnitsForProviders.rows;
      dispatch(unitActions.setUnits(data));
      dispatch(requestSuccess());
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar suas unidades. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function getUnitsForSecretaries(providerId, employeeId = null) {
  return async (dispatch) => {
    try {
      dispatch(unitActions.waitingUnits());
      let params = `providerId: "${providerId}"`
      if (Boolean(employeeId)) params += `, employeeId: "${employeeId}"`
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
            query {
              listProvidersUnitsForSecretary(${params}) {
                    rows {
                        id
                        address
                        number
                        complement
                        employeeId
                        establishmentId
                    }
                }
            }
          `
        },
      });
      const data = res.data.data.listProvidersUnitsForSecretary.rows;
      dispatch(unitActions.setUnits(data));
      dispatch(requestSuccess());
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar suas unidades. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function listSpecialitiesOfUnitForProviders({ unitId, providerId, page, limit }) {
  return async (dispatch) => {
    try {
      dispatch(unitActions.waitingUnits());
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
            query($input: ListSpecialitiesOfUnitForProvidersInput!) {
              listSpecialitiesOfUnitForProviders(input: $input) {
                count
                rows {
                  id
                  providerHasSpecialityId
                  providerHasSpecialities {
                    id
                    specialityId
                    speciality {
                      id
                      name
                      group
                      description
                    } 
                    active
                  }
                  price
                  active
                }
              } 
            }
          `,
          variables: {
            input: {
              unitId,
              providerId,
              page,
              limit
            },
          },
        },
      });
      if (res.data.errors) {
        dispatch(
          requestFailure(
            "Houve um problema na hora de pegar suas unidades. Tente novamente, por favor."
          )
        );
        dispatch(scheduleActions.searchIsDone());
        return
      }
      const data = res.data.data.listSpecialitiesOfUnitForProviders.rows;

      const providerSpecialities = data.filter((row) => {
        return row.providerHasSpecialities
      }).map(row => row.providerHasSpecialities.speciality)

      dispatch(unitActions.updateSpecialitiesForUnits([...providerSpecialities]));
      dispatch(requestSuccess());
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar suas unidades. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function listSpecialitiesOfUnitForEstablishments({ unitId, establishmentId, page, limit }) {
  return async (dispatch) => {
    try {
      dispatch(unitActions.waitingUnits());
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
            query($input: ListSpecialitiesOfUnitForEstablishmentsInput!) {
              listSpecialitiesOfUnitForEstablishments(input: $input) {
                count
                rows {
                  id
                  establishmentId
                  specialityId
                  specialities {
                    id
                    name
                    group
                    description
                  }
                  price
                }
              }
            }
          `,
          variables: {
            input: {
              unitId,
              establishmentId,
              page,
              limit
            },
          },
        },
      });
      if (res.data.errors) {
        dispatch(
          requestFailure(
            "Houve um problema na hora de pegar suas unidades. Tente novamente, por favor."
          )
        );
        dispatch(scheduleActions.searchIsDone());
        return
      }
      const data = res.data.data.listSpecialitiesOfUnitForEstablishments.rows;
      dispatch(unitActions.updateSpecialitiesForUnits(data.map(i => i.specialities)));
      dispatch(requestSuccess());
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar suas unidades. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function getEmployeeUnits({ employeeId, establishmentId }) {
  return async (dispatch) => {
    try {
      dispatch(unitActions.waitingUnits());
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
            query($employeeId: ID!) {
              listUnitsForEmployee(employeeId: $employeeId) {
                id
                unit {
                  id
                  address
                  number
                  complement
                  establishmentId
                  unitHasSpecialities {
                    id
                    establishments {
                      id
                    }
                  }
                }
              }
            }
          `,
          variables: {
            employeeId,
          },
        },
      });
      const data = res.data.data.listUnitsForEmployee;
      const selectedEstablishmentUnits = data.filter((estab) => {
        return estab.unit.establishmentId === establishmentId;
      });
      const addresses = selectedEstablishmentUnits.map(
        (unit) => unit.unit
      );
      dispatch(unitActions.setUnits(addresses));
      dispatch(requestSuccess());
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar suas unidades. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function getChoosenUnitConsultations(payload) {
  return async (dispatch) => {
    try {
      dispatch(consultationActions.waitingData());
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                        query($input: ListSpecialitiesOfUnitForProvidersInput!){
                            listSpecialitiesOfUnitForProviders(input:$input) {
                                rows {
                                    id
                                    active
                                 
                                    providerHasSpecialities {
                                      speciality {
                                          id
                                          name
                                          group
                                      }
                                  }
                                    price
                                }
                            }
                        }   
                    `,
          variables: {
            input: {
              unitId: `${payload.unitId}`,
              providerId: `${payload.providerId}`,
            },
          },
        },
      });
      const data = res.data.data.listSpecialitiesOfUnitForProviders.rows;

      const providerSpecialities = data.filter(item => {
        return item.providerHasSpecialities
      }).map((item) => {
        return {
          speciality: item.providerHasSpecialities.speciality,
          price: item.price,
          active: item.active,
        };
      });
      dispatch(
        consultationActions.setChoosenUnitConsultations(
          [...providerSpecialities]
        )
      );
      dispatch(requestSuccess());
    } catch (error) {
      console.log(error)
      dispatch(
        requestFailure(
          "Houve um erro tentando buscar as especialidades dessa unidade. Tente novamente"
        )
      );
    }
  };
}

export function getAppointmentFee() {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
            query($type: [TaxType!]!) {
              getTaxesByTypes(type: $type) {
                              type
                              value
                            }
                        }
                    `,
          variables: {
            type: ["APPOINTMENT_FEE_PROVIDER", "APPOINTMENT_FEE_PATIENT"]
          },
        },
      });
      const taxAppointmentFeeProvider = res.data.data.getTaxesByTypes.filter(tax =>
        tax.type === 'APPOINTMENT_FEE_PROVIDER')[0].value
      const taxAppointmentFeePatient = res.data.data.getTaxesByTypes.filter(tax =>
        tax.type === 'APPOINTMENT_FEE_PATIENT')[0].value

      dispatch(consultationActions.setConsultationFee({ taxAppointmentFeeProvider, taxAppointmentFeePatient }));
    } catch (error) {
      console.log(error);
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar suas unidades. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function getEmployeeChoosenUnitConsultations({
  establishmentId,
  employeeId,
  unitId,
  providerId
}) {
  return async (dispatch) => {
    try {
      dispatch(unitActions.waitingUnits());
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
            query($employeeId: ID!) {
              listUnitsForEmployee(employeeId: $employeeId) {
                id
                active
                unit {
                  id
                  establishmentId
                  unitHasSpecialities {
                    id
                    price
                    active
                    establishments {
                      id
                    }
                    specialities {
                      id
                      name
                      group
                    }
                  }
                }
              }
            }
          `,
          variables: {
            employeeId,
          },
        },
      });

      const doctorSpecialitiesRes = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
          query {
            listDoctorSpecialitiesByUnit(
              input: {
                providerId: "${providerId}"
                establishmentId: "${establishmentId}"
                employeeId: "${employeeId}"
                unitId: "${unitId}"
              }
             
            ) 
            {
              id
              name
              active  
            }
          }
        `,
        },
      });
      const doctorSpecialities = doctorSpecialitiesRes.data.data.listDoctorSpecialitiesByUnit
      
      const data = res.data.data.listUnitsForEmployee;
      const selectedEstablishmentUnits = data.filter((estab) => {
        return estab.unit.establishmentId === establishmentId;
      });
      const selectedUnit = selectedEstablishmentUnits.filter((unit) => {
        return unit.unit.id === unitId;
      });

      const selectedUnitSpecialities = []

      for (const _selectedUnit of selectedUnit) {
        for (const unitHasSpecialities of _selectedUnit.unit.unitHasSpecialities) {
          selectedUnitSpecialities.push({
            speciality: unitHasSpecialities.specialities,
            price: unitHasSpecialities.price,
            unitHasSpecialityId: unitHasSpecialities.id,
            active: _selectedUnit.active && unitHasSpecialities.active,
          })
        }
      }
      dispatch(
        consultationActions.setChoosenUnitConsultations(
          selectedUnitSpecialities.filter(x => doctorSpecialities.find(y => y.name === x.speciality.name))
        )
      );
      dispatch(requestSuccess());
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar suas unidades. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}


export function getProviderExams({
  providerId,
  unitId
}) {
  return async (dispatch) => {
    try {
      dispatch(unitActions.waitingUnits());
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `query Query($provider: ID!, $unit: ID!) {
            getProvidersHasServicesProvided(providerId: $provider, UnitId: $unit) {
              id
              serviceProvided {
                description
                code
                category
              }
              price
              preparation
              needScheduling
              active
            }
          }
          `,
          variables: {
            provider: providerId,
            unit: unitId
          },
        },
      });
      
      const providerServicesProvided = res.data.data.getProvidersHasServicesProvided
     dispatch(
        examActions.setChoosenProviderExams(
          providerServicesProvided
        )
      );
      dispatch(requestSuccess());
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de retornar seus exames. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}
export function getExamFee() {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
          query Query($getTaxByType: TaxType!) {
            getTaxByType(type: $getTaxByType)
          }
                    `,
          variables: {
            getTaxByType: "EXAM_FEE_PROVIDER"
          },
        },
      });
      
      const taxExamFee = res.data.data.getTaxByType

      dispatch(examActions.setExamFee(taxExamFee));
    } catch (error) {
      console.log(error);
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar suas unidades. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}
export function updateFront(payload) {
  return {
    type: "UPDATE_FRONT",
    payload,
  };
}

export function getPatientsList({
  providerId,
  medicalAppointmentId,
  userType,
  page = 1,
  limit = 10,
  status = [
    'OK',
    'PENDING_TOKEN_VALIDATION',
    'PENDING_TRIAGE',
    'PENDING_PAYMENT'
  ],
  date = new Date()
}) {
  return async (dispatch) => {
    try {
      dispatch(scheduleActions.waitingResults());

      const queryRequest = `count
            rows {
              id
              date
              beginAppointment
              dateTimeBeginAppointmentUtc
              dateTimeEndAppointmentUtc
              appointmentType
              status
              price
              appointmentLocation
              token
              providerId
              establishmentId
              employeeId
              payment {
                id
                status
              }
              medicalAppointmentReturnGenerated {
                id
              }
              patient {
                id
                socialName
                birth
                gender
                user {
                  name
                  email
                  cpf
                }
              }
              provider {
                id
                doctor {
                  crm
                  uf
                }
              }
              triage {
                id
                status
                symptoms
                drugs
                allergies
                diseases
                attachmentHasTriage {
                  file
                  uri
                }
              }
            }
              `;
      let res = "";

      if (userType === "PROVIDER_SECRETARY") {
        res = await axios({
          method: "post",
          url: urlGraphQl,
          data: {
            query: `
              query($input: MedicalAppointmentsSecretaryDailyInput!) {
                medicalAppointmentsSecretaryDaily(input: $input) {
                  ${queryRequest}
                }
              }
            `,
            variables: {
              input: { providersId: providerId, page, limit, status, date: format(date, 'yyyy-MM-dd') },
            },
          },
        });
        const totalPatientsOnDay = res.data.data?.medicalAppointmentsSecretaryDaily.count || 0;
        const patientsList = res.data.data?.medicalAppointmentsSecretaryDaily.rows || [];

        dispatch(medicalAppointmentsActions.setTotalPatientsOnDay(totalPatientsOnDay));
        dispatch(medicalAppointmentsActions.setPatientsList(patientsList));
        dispatch(startConsultationActions.storeData(patientsList[0]));
        dispatch(updateFront(false));
        dispatch(requestSuccess());
      } else {
        res = await axios({
          method: "post",
          url: urlGraphQl,
          data: {
            query: `
                            query($input: MedicalAppointmentsDailyInput!) {
                              medicalAppointmentsDaily(input: $input) {
                                        ${queryRequest}
                                }
                            }
                        `,
            variables: {
              input: {
                medicalAppointmentId,
                page,
                limit,
                status,
                date: format(date, 'yyyy-MM-dd')
              },
            },
          },
        });
        const totalPatientsOnDay = res.data.data.medicalAppointmentsDaily.count;
        const patientsList = res.data.data.medicalAppointmentsDaily.rows;

        dispatch(medicalAppointmentsActions.setTotalPatientsOnDay(totalPatientsOnDay));
        dispatch(medicalAppointmentsActions.setPatientsList(patientsList));
        dispatch(startConsultationActions.storeData(patientsList[0]));
        dispatch(updateFront(false));
        dispatch(requestSuccess());
      }
    } catch (error) {
      console.log(error)
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar sua lista de triagem. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function approveTriage({ approves, medicalAppointmentId }) {
  return async (dispatch) => {
    try {
      dispatch(triageActions.waitingResults(true));
      const res = await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
              mutation {
                approvesOrRejectTriage(approves: ${approves}, medicalAppointmentId: "${medicalAppointmentId}")
              }
          `,
        },
      });
      if (!res.data.data)
        throw Error("Houve um problema na hora de aprovar essa triagem.");
      const userType = window.localStorage.getItem("userType");
      dispatch(getPatientsList({ userType }));
      dispatch(triageActions.isSuccess(true));
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de aprovar essa triagem. Tente novamente, por favor."
        )
      );
      dispatch(triageActions.isSuccess(false));
    }
  };
}

export function cancelMedicalAppointment({
  medicalAppointmentId,
  reason,
  providerId,
  employeeId,
  unitId,
}) {
  return async (dispatch) => {
    try {
      await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                        mutation($input: UpdateMedicalAppointmentStatusInput!) {
                            cancelMedicalAppointment(input: $input)
                        }
                    `,
          variables: {
            input: {
              medicalAppointmentId,
              reason,
            },
          },
        },
      });
      dispatch(scheduleActions.openSlotModal({ open: false }));
      if (employeeId) {
        dispatch(getEmployeeSchedules({ employeeId, unitId }));
      } else {
        dispatch(getSchedules({ providerId, unitId }));
      }
      const userType = window.localStorage.getItem("userType");
      dispatch(getPatientsList({ userType }));
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de cancelar essa consulta. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function createUnavailability({
  scheduleGroupProviderId,
  scheduleHasProviderId,
  date,
  status,
  beginUnavailability,
  endUnavailability,
  dateTimeBeginUnavailabilityUtc,
  dateTimeEndUnavailabilityUtc,
}) {
  return async (dispatch) => {
    try {
      dispatch(scheduleActions.waitingResults());
      await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                            mutation($input: UnavailabilityInput!) {
                                createUnavailabilityProvider(input: $input) {
                                    id
                                }
                            }
                        `,
          variables: {
            input: {
              scheduleGroupProviderId,
              scheduleHasProviderId,
              date,
              dateTimeBeginUnavailabilityUtc,
              dateTimeEndUnavailabilityUtc,
              status,
              beginUnavailability,
              endUnavailability,
            },
          },
        },
      });
      dispatch(scheduleActions.openSlotModal({ open: false }));
      dispatch(scheduleActions.updateCalendarFront(true));
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de desativar esse espaço na sua agenda. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function createEmployeeUnavailability({
  scheduleGroupEmployeeId,
  scheduleHasEmployeeId,
  date,
  status,
  beginUnavailability,
  endUnavailability,
  dateTimeBeginUnavailabilityUtc,
  dateTimeEndUnavailabilityUtc,
}) {
  return async (dispatch) => {
    try {
      dispatch(scheduleActions.waitingResults());
      await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                            mutation($input: UnavailabilityInput!) {
                                createUnavailabilityEstablishment(input: $input) {
                                    id
                                }
                            }
                        `,
          variables: {
            input: {
              scheduleGroupEmployeeId,
              scheduleHasEmployeeId,
              date,
              dateTimeBeginUnavailabilityUtc,
              dateTimeEndUnavailabilityUtc,
              status,
              beginUnavailability,
              endUnavailability,
            },
          },
        },
      });
      dispatch(scheduleActions.openSlotModal({ open: false }));
      dispatch(scheduleActions.updateCalendarFront(true));
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de desativar esse espaço na sua agenda. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function updateUnavailability({
  status,
  unavailabilityProviderId,
  providerId,
  unitId,
}) {
  return async (dispatch) => {
    try {
      dispatch(scheduleActions.waitingResults());
      await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                        mutation($input: UpdateUnavailabilityProviderStatusInput!) {
                            updateUnavailabilityProviderStatus(input: $input) {
                                scheduleGroupProviderId
                            }
                        }
                    `,
          variables: {
            input: {
              status,
              unavailabilityProviderId,
            },
          },
        },
      });
      dispatch(scheduleActions.openSlotModal({ open: false }));
      dispatch(scheduleActions.updateCalendarFront(true));
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de reativar esse espaço na sua agenda. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function updateEmployeeUnavailability({
  status,
  unavailabilityEstablishmentId,
  employeeId,
  unitId,
}) {
  return async (dispatch) => {
    try {
      dispatch(scheduleActions.waitingResults());
      await axios({
        method: "post",
        url: urlGraphQl,
        data: {
          query: `
                        mutation($input: UpdateUnavailabilityEstablishmentStatusInput!) {
                            updateUnavailabilityEstablishmentStatus(input: $input) {
                                id
                            }
                        }
                    `,
          variables: {
            input: {
              status,
              unavailabilityEstablishmentId,
            },
          },
        },
      });
      dispatch(scheduleActions.openSlotModal({ open: false }));
      dispatch(scheduleActions.updateCalendarFront(true));
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de reativar esse espaço na sua agenda. Tente novamente, por favor."
        )
      );
      dispatch(scheduleActions.searchIsDone());
    }
  };
}

export function finishConsultation({
  medicalAppointmentId,
  clinicalHistory,
  diagnosticHypothesis,
  physicalExam,
  treatment,
  isNecessaryReloadPatientList = false,
  examsRequests
}) {
  return async (dispatch) => {
    try {
      await apiGraphql({
        data: {
          query: `
                        mutation($input: FinishMedicalAppointmentInput!) {
                            finishMedicalAppointent(input: $input)
                        }
                    `,
          variables: {
            input: {
              medicalAppointmentId,
              clinicalHistory,
              diagnosticHypothesis,
              physicalExam,
              treatment,
              examsRequests
            },
          },
        },
      });
      dispatch(startConsultationActions.requestSuccess(true));
      dispatch(startConsultationActions.stopWaiting(true));

      if (isNecessaryReloadPatientList) {
        const userType = window.localStorage.getItem("userType");
        dispatch(getPatientsList({ userType }));
      }

    } catch (error) {
      console.log(error);
      dispatch(
        requestFailure(
          "Houve um problema na hora de finalizar a consulta. Tente novamente, por favor."
        )
      );
      dispatch(startConsultationActions.requestSuccess(false));
      dispatch(startConsultationActions.stopWaiting(true));

    }
  };
}

export function finishVideoChat(medicalAppointmentId) {
  return async (dispatch) => {
    try {
      await apiGraphql({
        data: {
          query: `
                mutation {
                    removeDoctorFromVideochat(medicalAppointmentId: "${medicalAppointmentId}")
        
                }
                    `,
        },
      });

      dispatch(startConsultationActions.requestSuccess(true));
      dispatch(startConsultationActions.stopWaiting(true));
    }
    catch (error) {
      console.log(error);
      dispatch(
        requestFailure(
          "Houve um problema para dispensar o paciente. Tente novamente, por favor."
        )
      );
      dispatch(startConsultationActions.requestSuccess(false));
      dispatch(startConsultationActions.stopWaiting(true));
    }
  }
}

export function validateMedicalAppointmentToken(medicalAppointmentId, token, providersId) {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
                mutation {
                  validateMedicalAppointmentToken(token: "${token}" ,medicalAppointmentId: "${medicalAppointmentId}")
        
                }
                    `,
        },
      });
      if (res.data.data.validateMedicalAppointmentToken) {
        const userType = window.localStorage.getItem("userType");
        dispatch(getPatientsList({ userType, providerId: providersId }));
      }
      return res.data.data.validateMedicalAppointmentToken
    }
    catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema para validar o token. Tente novamente, por favor."
        )
      );
      dispatch(startConsultationActions.requestSuccess(false));
      dispatch(startConsultationActions.stopWaiting(true));
    }
  }
}

export function initMedicalAppointmentPresential(medicalAppointmentId) {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
                mutation {
                  initMedicalAppointmentPresential(medicalAppointmentId: "${medicalAppointmentId}")
        
                }
                    `,
        },
      });

      return res.data.data.initMedicalAppointmentPresential
    }
    catch (error) {
      console.log(error);
      dispatch(
        requestFailure(
          "Houve um problema para iniciar a consulta. Tente novamente, por favor."
        )
      );
      dispatch(startConsultationActions.requestSuccess(false));
      dispatch(startConsultationActions.stopWaiting(true));
    }
  }
}

export function createMedicalAppointmentReturn({
  patientId,
  providerId,
  medicalAppointmentId,
  careModality,
  expirationDate,
}) {
  return async (dispatch) => {
    try {
      await apiGraphql({
        data: {
          query: `
                        mutation($input: MedicalAppointmentReturnInput!) {
                            createMedicalAppointmentReturn(input: $input)
                        }
                    `,
          variables: {
            input: {
              medicalAppointmentId,
              patientId,
              providerId,
              careModality,
              expirationDate,
            },
          },
        },
      });
      dispatch(startConsultationActions.requestSuccess(true));
      dispatch(startConsultationActions.stopWaiting(true));
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema para registrar o pedido da consulta de retorno. Tente novamente, por favor."
        )
      );
      dispatch(startConsultationActions.requestSuccess(false));
      dispatch(startConsultationActions.stopWaiting(true));
    }
  };
}

export function getDoctorsList(secretaryId) {
  return async (dispatch) => {
    secretaryActions.getDoctorsList();
    try {
      const res = await apiGraphql({
        data: {
          query: `
                        query($secretaryId: String!) {
                            listSecretaryDoctors(secretaryId: $secretaryId) {
                              id
                              secretaryId
                              establishmentId
                              employeeId
                              establishment{
                                clinic{
                                  companyName
                                }
                              }
                              provider {
                                id
                                userId
                                doctor {
                                  id
                                  active
                                  status
                                  payload
                                  crm
                                  uf
                                  bank
                                  agency
                                  account
                                  careModality
                                  professionalId
                                  proofResidence
                                  numberOfInstallments
                                  minimumInstallmentAmount
                                  termsOfAgreement
                                  photo
                                  aboutMe
                                  experience
                                  treatMedicalConditions
                                  background
                                  lattes
                                  providerId
                                }
                                user {
                                  name
                                }
                                employees {
                                  id
                                  active
                                  providerId
                                  userId
                                  establishmentId
                                }
                              }
                            }
                        }
                    `,
          variables: {
            secretaryId,
          },
        },
      });
      const doctorsList = res.data.data.listSecretaryDoctors;
      dispatch(secretaryActions.saveDoctorsList(doctorsList));
      dispatch(secretaryActions.stopWaitingData());
    } catch (error) {
      dispatch(
        requestFailure(
          //"Houve um problema na hora de pegar a listagem dos médicos. Tente novamente, por favor."
          "Não encontramos nenhuma agenda para você no momento."
        )
      );
      dispatch(secretaryActions.stopWaitingData());
    }
  };
}

export function getSecretariesList(providerId) {
  return async (dispatch) => {
    dispatch(secretaryActions.saveSecretariesList([]));
    secretaryActions.getSecretariesList();
    try {
      const res = await apiGraphql({
        data: {
          query: `
                        query($providerId: String!) {
                            listDoctorSecretaries(providerId: $providerId) {
                                name
                                id
                                active
                                status
                            }
                        }
                    `,
          variables: {
            providerId,
          },
        },
      });
      let secretaryList = [];
      if (res.data.data) secretaryList = res.data.data.listDoctorSecretaries;
      dispatch(secretaryActions.saveSecretariesList(secretaryList));
      dispatch(secretaryActions.stopWaitingData());
    } catch (error) {
      console.log(error);
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar a listagem das secretárias. Tente novamente, por favor."
        )
      );
      dispatch(secretaryActions.stopWaitingData());
    }
  };
}

export function getClinicSecretariesList(establishmentId, employeeId) {
  return async (dispatch) => {
    dispatch(secretaryActions.saveSecretariesList([]));
    secretaryActions.getSecretariesList();
    try {
      const res = await apiGraphql({
        data: {
          query: `
                        query($establishmentId: String!, $employeeId:String!) {
                          listClinicSecretaries(
                            establishmentId: $establishmentId,
                            employeeId: $employeeId) {
                                name
                                id
                                active
                                status
                            }
                        }
                    `,
          variables: {
            establishmentId,
            employeeId
          },
        },
      });
      let secretaryList = [];
      if (res.data.data) secretaryList = res.data.data.listClinicSecretaries;
      dispatch(secretaryActions.saveSecretariesList(secretaryList));
      dispatch(secretaryActions.stopWaitingData());
    } catch (error) {
      console.log(error);
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar a listagem das secretárias. Tente novamente, por favor."
        )
      );
      dispatch(secretaryActions.stopWaitingData());
    }
  };
}

export function getTermsOfDoctor({ providerId, patientId }) {
  return async (dispatch) => {
    secretaryActions.getSecretariesList();
    try {
      const res = await apiGraphql({
        data: {
          query: `
            query($providerId: ID!, $patientId: ID!) {
              getTermsOfDoctor(providerId: $providerId, patientId: $patientId) {
                termsOfAgreement
              }
            }
          `,
          variables: {
            providerId,
            patientId
          },
        },
      });
      if (res.data.errors) {
        dispatch(
          requestFailure(
            "Houve um problema na hora de pegar a listagem das secretárias. Tente novamente, por favor."
          )
        );
        dispatch(secretaryActions.stopWaitingData());
        return
      }
      const data = res.data.data.getTermsOfDoctor;
      dispatch(startConsultationActions.getConsentTermsOfDoctor(data.termsOfAgreement));
    } catch (error) {
      console.log(error);
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar a listagem das secretárias. Tente novamente, por favor."
        )
      );
    }
  };
}

export function getPatientHistory(patientId) {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: 
            `query($patientId: ID!) {
                getMedicalAppointmentsForProvider(patientId: $patientId) {
                  id
                  date
                  dateTimeBeginAppointmentUtc
                  patientAuthorizesMedicalRecord
                  status
                  medicalAppointmentReturn {
                    id
                    patientId
                    providerId
                    medicalAppointmentId
                    appointmentType
                    expirationDate
                    active
                  }
                  medicalAppointmentReturnGenerated {
                    id
                    patientId
                    providerId
                    medicalAppointmentId
                    medicalAppointmentReturnId
                  }
                  speciality {
                    name
                  }
                  provider {
                    id
                    user {
                      name
                    }
                    doctor {
                      id
                      crm
                      uf
                    }
                  }
                  clinicalHistory
                  physicalExam
                  diagnosticHypothesis
                  treatment
                  documents {
                    id
                    documentType
                  }
                }
            }`,
          variables: {
            patientId,
          },
        },
      });
      dispatch(
        startConsultationActions.saveMedicalHistory(
          res.data.data.getMedicalAppointmentsForProvider
        )
      );
      dispatch(startConsultationActions.stopWaiting(true));
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema na hora de pegar o histórico do paciente. Tente recarregar a página, por favor."
        )
      );
      dispatch(startConsultationActions.stopWaiting(true));
    }
  };
}

export function inviteSecretaryForClinic({
  name,
  email,
  cpf,
  doctorProviderId,
  establishmentId,
  employeeId
}) {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
                        mutation($input: InviteSecretaryForClinicInput!) {
                          inviteClinicSecretary(input: $input) {
                                secretary {
                                    name
                                    active
                                }
                                secretaryHasProvider {
                                    secretaryId
                                }
                            }
                        }
                    `,
          variables: {
            input: {
              name,
              email,
              doctorProviderId,
              cpf,
              establishmentId,
              employeeId
            },
          },
        },
      });
      if (res.data.data) {
        const name = res.data.data.inviteClinicSecretary.secretary.name;
        const active = res.data.data.inviteClinicSecretary.secretary.active;
        const secretaryId =
          res.data.data.inviteClinicSecretary.secretaryHasProvider.secretaryId;
        dispatch(secretaryActions.addSecretary({ name, active, secretaryId }));
        return true;
      } else {
        if (res.data.errors) {
          const status = res.data?.errors[0]?.extensions?.exception?.status || 500
          if (status === 409) {
            throw Error(
              "Já existe um prestador com este email"
            );
          }
        }
        throw Error(
          "Houve um problema na adição da secretária. Tente novamente, por favor."
        );
      }
    } catch (error) {
      if (error.message === 'Já existe um prestador com este email') {
        dispatch(
          requestFailure(error.message)
        );
        return
      }
      dispatch(
        requestFailure(
          "Houve um problema na hora de enviar o email de confirmação para a sua secretária. Tente novamente, por favor."
        )
      );
    }
  }
};

export function inviteSecretaryForDoctor({ name, email, cpf, doctorProviderId }) {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
                        mutation($input: InviteSecretaryForDoctorInput!) {
                            inviteDoctorSecretary(input: $input) {
                                secretary {
                                    name
                                    active
                                }
                                secretaryHasProvider {
                                    secretaryId
                                }
                            }
                        }
                    `,
          variables: {
            input: {
              name,
              email,
              doctorProviderId,
              cpf,
            },
          },
        },
      });
      if (res.data.data) {
        const name = res.data.data.inviteDoctorSecretary.secretary.name;
        const active = res.data.data.inviteDoctorSecretary.secretary.active;
        const secretaryId =
          res.data.data.inviteDoctorSecretary.secretaryHasProvider.secretaryId;
        dispatch(secretaryActions.addSecretary({ name, active, secretaryId }));
        return true;
      } else {
        if (res.data.errors) {
          const status = res.data?.errors[0]?.extensions?.exception?.status || 500
          if (status === 409) {
            throw Error(
              "Já existe um prestador com este email"
            );
          }
        }
        throw Error(
          "Houve um problema na adição da secretária. Tente novamente, por favor."
        );
      }
    } catch (error) {
      if (error.message === 'Já existe um prestador com este email') {
        dispatch(
          requestFailure(error.message)
        );
        return
      }
      dispatch(
        requestFailure(
          "Houve um problema na hora de enviar o email de confirmação para a sua secretária. Tente novamente, por favor."
        )
      );
    }
  }
};

export function virtuaGetQueue(limit = 10, page = 1) {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
            query {
              getQueueWithPagination(limit: ${limit}, page: ${page}){
                count
                rows {
                  id
                  preference
                  createdAt
                  patient {
                    user {
                      name
                      cpf
                      email
                      phone
                    }
                  }
                }
              }
            }
          `
        },
      });
      if (res.data.data) {
        const queue = res.data.data.getQueueWithPagination.rows.map(item => ({
          id: item.id,
          preference: item.preference,
          createdAt: item.createdAt,
          patientName: item.patient.user.name,
          patientCpf: item.patient.user.cpf,
          patientEmail: item.patient.user.email,
          patientPhone: item.patient.user.phone,
        }))

        dispatch(virtuaActions.setQueue(queue));
        return res.data.data.getQueueWithPagination
      } else {
        throw Error(
          "Houve um problema para capturar os pacientes na fila. Tente novamente, por favor."
        );
      }
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema para capturar os pacientes na fila. Tente novamente, por favor."
        )
      );
    }
  }
};

export function getQueueInfo() {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
            query {
              virtuaQueueInfo{
                countPatients
                countDoctors
                doctorPerPatient
                tme
              }
            }
          `
        },
      });
      if (res.data.data) {
        return dispatch(virtuaActions.setQueueInfo(res.data.data.virtuaQueueInfo));
      } else {
        throw Error(
          "Houve um problema para capturar as informações da fila. Tente novamente, por favor."
        );
      }
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema para capturar as informações da fila. Tente novamente, por favor."
        )
      );
    }
  }
};

export function virtuaRegisterDoctorOnline() {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
            mutation {
              virtuaRegisterDoctorOnline
            }
          `
        },
      });
      if (res.data.data) {
        return dispatch(virtuaActions.setAvailable(true));
      } else {
        if (res.data.errors[0].message === "This doctor dont have an active register on virtua") {
          dispatch(
            requestFailure(
              "Não foi localizado seu registro no virtua."
            )
          )
          return false
        }
        dispatch(
          requestFailure(
            "Houve um problema para registrar como disponível. Tente novamente, por favor."
          )
        );
        return false
      }
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema para registrar como disponível. Tente novamente, por favor."
        )
      );
    }
  }
};

export function virtuaRegisterDoctorOffline() {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
            mutation {
              virtuaRegisterDoctorOffline
            }
          `
        },
      });
      if (res.data.data) {
        return dispatch(virtuaActions.setAvailable(false));
      } else {
        dispatch(
          requestFailure(
            "Houve um problema para registrar como indisponível. Tente novamente, por favor."
          )
        );
        return false
      }
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema para registrar como indisponível. Tente novamente, por favor."
        )
      );
    }
  }
};

export function virtuaIsDoctorOnline() {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
            query {
              virtuaIsDoctorOnline
            }
          `
        },
      });
      if (res.data.data) {
        return dispatch(virtuaActions.setAvailable(res.data.data.virtuaIsDoctorOnline));
      } else {
        dispatch(
          requestFailure(
            "Houve um problema para verificar disponibilidade. Tente novamente, por favor."
          )
        );
        return false
      }
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema para verificar disponibilidade. Tente novamente, por favor."
        )
      );
    }
  }
}

export function virtuaCaptureNextPatient() {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
            mutation {
              capture {
                room {
                  roomName
                  identity
                }
                medicalAppointment {
                  id
                  date
                  beginAppointment
                  appointmentLocation
                }
                triage {
                  id
                  symptoms
                  drugs
                  allergies
                  diseases
                  attachmentHasTriage {
                    id
                    bucket
                    file
                    uri
                  }
                }
                patient {
                  id
                  socialName
                  birth
                  gender
                  user {
                    name
                    email
                    cpf
                  }
                }
              }
            }
          `
        },
      });
      if (res.data.data) {
        if (!res.data.data.capture) {
          dispatch(
            requestFailure(
              "Não há pacientes na fila no momento!"
            )
          );
          return false
        }
        const docData = JSON.parse(window.localStorage.getItem('docData'));
        const doctorName = docData.name
        const doctorCrm = `${docData.crm}-${docData.uf}`
        const medicalAppointment = res.data.data.capture.medicalAppointment
        const patient = res.data.data.capture.patient
        const triage = res.data.data.capture.triage
        const room = res.data.data.capture.room

        const consultationInfo = {
          date: medicalAppointment.date,
          hour: medicalAppointment.beginAppointment,
          patient: {
            socialName: handlePatientName(patient),
            email: patient.user.email,
            id: patient.id,
            user: patient.user,
            gender: patient.gender,
            birth: patient.birth
          },
          doctor: {
            name: doctorName,
            crm: doctorCrm
          },
          symptoms: triage.symptoms,
          drugs: triage.drugs,
          allergies: triage.allergies,
          diseases: triage.diseases,
          attachmentHasTriage: triage.attachmentHasTriage,
          triageId: triage.id,
          appointmentLocation: 'ONLINE',
          medicalAppointmentId: medicalAppointment.id

        }
        return {
          room,
          consultationInfo
        }
      } else {
        dispatch(
          requestFailure(
            "Houve um problema para capturar um paciente da fila. Tente novamente, por favor."
          )
        );
        return false
      }
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema para capturar um paciente da fila. Tente novamente, por favor."
        )
      );
    }
  }
};

export function virtuaGetHistoric(limit, page) {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
            query($page: Float, $limit: Float) {
              virtuaHistoricAppointmentsWithPagination(page:$page,limit:$limit) {
                count
                rows {
                  id
                  medicalAppointment {
                    id
                    patientId
                    providerId
                    unitId
                    specialityId
                    beginAppointment
                    endAppointment
                    dateTimeBeginAppointmentUtc
                    dateTimeEndAppointmentUtc
                    date
                    price
                    tax
                    taxProvider
                    appointmentType
                    token
                    reason
                    clinicalHistory
                    physicalExam
                    diagnosticHypothesis
                    treatment
                    establishmentId
                    doctorHasAttended
                    patientHasAttended
                    provider {
                      id
                      active
                      status
                      user {
                        id
                        name
                        email
                        cpf
                        phone
                        status
                        type
                      }
                      createdAt
                      updatedAt
                    } 
                    patient {
                      id
                      gender
                      birth
                      socialName
                      user {
                        id
                        name
                        email
                        cpf
                        phone
                      }
                    } 
                    healthOperatorRequest {
                      id
                      createdAt
                      healthOperator {
                        id
                        fantasyName
                        companyName
                      }
                    } 
                    triage {
                      id
                      status
                      symptoms
                      drugs
                      allergies
                      diseases
                      attachmentHasTriage {
                        id
                        bucket
                        file
                        uri
                        triageId
                      }
                    } 
                    speciality {
                      id
                      name
                    } 
                    referral {
                      id
                      specialityId
                      speciality {
                        id
                        name
                      }
                    } 
                    establishment {
                      id
                      clinic {
                        id
                        tradingName
                        companyName
                      }
                    } 
                    documents {
                      id
                      documentType
                      documentId
                    } 
                    status
                    medicalAppointmentReturnGenerated {
                      id
                    } 
                    medicalAppointmentReturn {
                      id
                      patientId
                      providerId
                      medicalAppointmentId
                      appointmentType
                      expirationDate
                      active
                      careModality
                    } 
                  }
                  queueId
                  beneficiaryId
                  capturedAt
                  enterInQueueAt
                  virtuaDoctorId
                  virtuaUserId
                }
              }  
            }
          `,
          variables: { page, limit }
        },
      });
      if (res.data.data) {
        const listHistoric = res.data.data.virtuaHistoricAppointmentsWithPagination.rows
        dispatch(virtuaActions.setHistoric(listHistoric));
        return res.data.data.virtuaHistoricAppointmentsWithPagination
      } else {
        dispatch(
          requestFailure(
            "Houve um problema para listar o histórico. Tente novamente, por favor."
          )
        );
        return false
      }
    } catch (error) {
      dispatch(
        requestFailure(
          "Houve um problema para listar o histórico. Tente novamente, por favor."
        )
      );
    }
  }
};

export function validateExamAppointmentToken(appointmentRequestId, token, providersId)
{ return async (dispatch)  => {
      try {
        
      
        const res = await apiGraphql({
          data: {
            query: `
                  mutation {
                    validateExamAppointmentToken(token: "${token}" ,appointmentRequestId: "${appointmentRequestId}")
          
                  }
                      `,
          },
        });
        if (res.data.data.validateExamAppointmentToken) {
        
          dispatch(scheduledExamsActions.updateStatusConsultation(`${Date.now()}-LastUpdate`))
        
          return res.data.data.validateExamAppointmentToken  
        }
        else{
          return false
        }
      } catch (error) {
        
        
          dispatch(
            requestFailure(
              "Houve um problema para validar o token. Tente novamente, por favor."
            )
          ); 
        }
       
      }
      

    }
