import React from "react";
import { Grid, TextareaAutosize} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    border: "none",
    margin: "12px 0 0 0",
    width: props => props.width,

    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
      marginTop: 6,
      color: "#000000",
      fontSize: 10
    },

    '& .MuiInput-underline::before': {
      borderBottom: 'none !important'
    },

    '& .MuiInput-underline::after': {
      borderBottom: '1px solid #f9b233 !important'
    }
  },
  textArea: {
    "& > textarea": {
      outline: 'none',
    },
    "& span.info": {
      color: 'rgb(107, 108, 126)'
    }
  },
  inputs: {
    height:"40px",
    border: '1px solid #e7e7ed',
  },
  error: {
    color: '#DA1414',
    fontSize: '12px',
    fontWeight: '400',
    marginTop: '3px'
  }
});

const TextArea = (props) => {
  const classes = useStyles(props);
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <span>
          <strong className="label-input">{props.label}</strong>
        </span>
        <div className={classes.textArea}>
          <span className="info">{props.info}</span>
          <TextareaAutosize
            onChange={(e) => props.onChange(e.target.value)}
            onBlur={props.onBlur}
            value={props.value}
            rows={props.rows ? props.rows : 1}
            data-test={props.dataTest}
            placeholder={props.placeholder ? props.placeholder : ""}
            maxLength={props.max}
            disabled={props.disabled}
          />
          {props.link ?         
            <span className="forgot-password-text">
              <Link to={props.link}>{props.linkText} </Link>
            </span>
            :
            null
          }
        </div>
        <span>
          <strong className={classes.error}>{props.error ? props.error : null}</strong>
        </span>
      </Grid>
    </Grid>
  )
};

export default React.memo(TextArea);
