import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { parse } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import SlotModal from "../slotsModal";

import Topic from "../../../../common/topic";
import Button from "../../../../common/button";
import CreateScheduleModal from "../modals";
import Calendar from "../../../../common/calendar";
import Scheduler from "../../../../common/scheduler";
import List from "../../../../common/itemList/minimalList";
import Loading from "../../../../common/loading";
import SnackBar from "../../../../common/snackbar";
import PatientsList from "../lists/index";

import * as asyncActions from "../../../../common/store/actions/async.action";
import * as scheduleActions from "../../../../common/store/actions/schedule.action";

import graphqlApi from "../../../../services/api";

const useStyles = makeStyles({
  root: {
    width: "95%",
    height: "calc(100vh - 70px - 60px)",
    maxHeight: "calc(100vh - 70px - 60px)",
    minWidth: "1215px",
    boxSizing: "border-box",
    margin: "3vh auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    width: "100%",
  },
  container: {
    marginTop: "2vh",
    width: "100%",
    height: "68vh",
    maxHeight: "72vh",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  leftSide: {
    height: "100%",
    width: "25vw",
    minWidth: "310px",
    maxWidth: "325px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginRight: "30px",
  },
  scheduleHint: {
    height: "100%",
    overflowY: "auto",
    border: "1px solid #E7E7ED",
    borderRadius: "10px",
    marginTop: "24px",
    "& > div": {
      fontWeight: "600",
      textAlign: "center",
      marginTop: "16px",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CDCED9",
      outline: "1px solid slategrey",
      borderRadius: "5px",
    },
  },
  rightSide: {
    height: "100%",
    minWidth: "800px",
    width: "100%",
    border: "1px solid #E7E7ED",
    borderRadius: "10px",
    backgroundColor: "#F7F8F9",
  },
  scheduler: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100%",
    overflow: "hidden",
  },
});

const Schedule = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const providerId = window.localStorage.getItem("Prov-Vida-Connect");
  const userType = window.localStorage.getItem("userType");
  let userData = JSON.parse(
    window.localStorage.getItem("userData") || { name: "" }
  );
  if (userType === "PROVIDER_SECRETARY") {
    userData = JSON.parse(
      window.localStorage.getItem("Secretary-Selected-Doctor") || {}
    );
  }
  const employeeId = window.localStorage.getItem("employeeId");
  const establishmentId = window.localStorage.getItem("establishmentId");
  const unitId = window.localStorage.getItem("Unit-Vida-Connect");

  const specialitiesForUnit = useSelector(
    (state) => state.UnitsReducer.specialitiesForUnit
  );

  const address =
    useSelector((state) => state.ScheduleReducer.unit.address) ||
    window.localStorage.getItem("ad");
  const number =
    useSelector((state) => state.ScheduleReducer.unit.number) ||
    window.localStorage.getItem("n");
  const complement =
    useSelector((state) => state.ScheduleReducer.unit.complement) ||
    window.localStorage.getItem("comp");
  const error = useSelector((state) => state.AsyncReducer.error);
  const queryIsDone = useSelector((state) => state.ScheduleReducer.done);
  const updateFront = useSelector(
    (state) => state.ScheduleReducer.updateCalendarFront
  );
  const allRegisteredSchedules = useSelector(
    (state) => state.ScheduleReducer.registeredSchedules
  );
  const beginAndEnd = allRegisteredSchedules.map((schedule) => {
    return {
      start: parse(
        schedule[0].beginSchedule.slice(0, 10),
        "yyyy-MM-dd",
        new Date(),
        { locale: ptBR }
      ),
      end: parse(
        schedule[0].endSchedule.slice(0, 10),
        "yyyy-MM-dd",
        new Date(),
        { locale: ptBR }
      ),
    };
  });
  const unavailabilities = useSelector(
    (state) => state.ScheduleReducer.appointmentsAndUnavailabilities
  );

  const [selectedSchedule, setSelectedSchedule] = useState(0);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);

  const [specialities, setSpecialities] = useState("");

  function getSpecialitiesAttendedByDoctor(
    providerId,
    establishmentId,
    employeeId,
    unitId,
    page = 1,
    limit = 3
  ) {
    if (!limit) limit = 3;
    if (!page) page = 1;

    graphqlApi({
      data: {
        query: `
        query {
          listDoctorSpecialitiesByUnit(
            input: {
              providerId: "${providerId}"
              establishmentId: "${establishmentId}"
              employeeId: "${employeeId}"
              unitId: "${unitId}"
            }
            page: ${page}
            limit: ${limit}
          ) 
          {
            id
            name
            active  
          }
        }
      `,
      },
    })
      .then((res) => {
        const resultSpeciality = res.data.data.listDoctorSpecialitiesByUnit;
        setSpecialities(
          resultSpeciality.map((speciality) => speciality.name).join("/")
        );
      })
      .catch((err) => console.log(err));
  }

  // Updating the schedule when we first access this page
  useEffect(() => {
    if (updateFront) {
      setLoading(true);
      if (employeeId) {
        dispatch(asyncActions.getEmployeeSchedules({ employeeId, unitId }));
        getSpecialitiesAttendedByDoctor(
          providerId,
          establishmentId,
          employeeId,
          unitId
        );
      } else {
        dispatch(asyncActions.getSchedules({ providerId, unitId }));
        // Pega as especialidades que o médico atende neste endereço
        dispatch(
          asyncActions.listSpecialitiesOfUnitForProviders({
            providerId,
            unitId,
            page: 1,
            limit: 3,
          })
        );
      }
      dispatch(scheduleActions.updateCalendarFront(false));
    }
    // eslint-disable-next-line
  }, [updateFront, providerId, unitId]);

  useEffect(() => {
    setSpecialities(
      specialitiesForUnit.map((speciality) => speciality.name).join("/")
    );
  }, [specialitiesForUnit]);

  // Updating the start calendar day when the getSchedules return
  useEffect(() => {
    if (allRegisteredSchedules.length > 0) {
      setSelectedDate(beginAndEnd[selectedSchedule].start);
      setLoading(false);
    } else {
      //Didn't find any registered schedule. We still need to stop loading if the query is over
      if (queryIsDone) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [allRegisteredSchedules, selectedSchedule, queryIsDone]);

  useEffect(() => {
    return function clearSchedule() {
      dispatch(scheduleActions.cleanSchedule());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => dispatch(scheduleActions.updateCalendarFront(true));
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <div className={classes.loading}>
        <Loading width="80px" />
        {error ? <SnackBar /> : null}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>
          <Button
            startIcon={<NavigateBeforeIcon />}
            width="91px"
            heigth="32px"
            border="1px solid #CDCED9"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            margin="0 20px 0 0"
            fontSize="14px"
            fontWeight="400"
            padding="6px 12px"
            onClick={() => props.history.push("/administrador/minha-agenda")}
          >
            Voltar
          </Button>
          <Topic
            className={classes.titleItem}
            marginTop="0"
            title={`${address}, ${number} ${
              complement ? ", " + complement : ""
            }`}
            subtitle={
              Boolean(specialities)
                ? `${userData.name} - ${specialities}`
                : `${userData.name}`
            }
          />
        </div>
        {allRegisteredSchedules.length > 0 && (
          <CreateScheduleModal text="Adicionar agenda" buttonWidth="200px" />
        )}
      </div>
      <div className={classes.container}>
        <div className={classes.leftSide}>
          <div className={classes.calendar}>
            <Calendar
              onPick={setSelectedDate}
              selectedDate={selectedDate}
              interval={
                beginAndEnd[selectedSchedule] || { start: null, end: null }
              }
            />
          </div>
          <div className={classes.scheduleHint}>
            <div>
              <span>Agendas Publicadas</span>
              <List
                fullList={beginAndEnd}
                firstParam="start"
                secondParam="end"
                editItem={() => {}}
                selected={selectedSchedule}
                setSelected={setSelectedSchedule}
                onPick={setSelectedDate}
                height="30px"
                width="95%"
                fontSize="12px"
                margin="16px auto"
                border="none"
              />
            </div>
          </div>
        </div>
        <div className={classes.rightSide}>
          <div className={classes.scheduler}>
            <Scheduler
              onPick={setSelectedDate}
              selectedDate={selectedDate}
              schedule={allRegisteredSchedules}
              selectedSchedule={selectedSchedule}
              interval={beginAndEnd[selectedSchedule]}
              unavailabilities={unavailabilities}
            />
            <SlotModal />
          </div>
        </div>
      </div>

      <PatientsList />

      {error && <SnackBar />}
    </div>
  );
};

export default Schedule;
