/* eslint-disable react/jsx-no-target-blank */
import React, { memo } from "react";

import Topic from "../../../../common/topic";
import InputFile from "../../../../common/fileInput";

import inputFileValidator, { validationFileType } from '../../../../common/validators/fileInput'

const AgreementTerms = (props) => {

  const onDelete = (clearFile) => {
    clearFile({ name: "" })
  }



  const handleChangeInputFile = (value, onChange, typesAccepted) => {
    if (!value) return onChange({name: ''})
  
    const typeIsAccpted =  validationFileType(value.type,typesAccepted)
    if (!typeIsAccpted) {
      onChange({name: ''})
      props.setOpenSyncSnackBar(true)
      props.setErrorMessageSyncSnackBar('O tipo do arquivo selecionado não é aceito para esse campo!')
      return
    } 
    return onChange(value)
  }

  const typesAcceptedConsentForm = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']
  return (
    <React.Fragment>
      <div>
        <Topic
          classesNameCol="topic-datas middle-topics"
          title="Termo de consentimento (telemedicina)"
          subtitle="O termo de consentimento é de responsabilidade do médico. Você poderá fazer o upload do seu termo anexando no campo abaixo. Caso você prefira, poderá utilizar o modelo sugerido pelo Vida Connect."
        />
      </div>
      <div>
        <br/>
        <a href="https://storage.googleapis.com/public-files-vida-connect/TCLE%20Telemedicina%20VIDA%20CONNECT%20.pdf" target="_blank"><span style={{ color: 'red', fontWeight: 'bold' }}>{'Clique para baixar termo sugerido pelo Vida Connect'.toUpperCase()}</span></a>
        <br/>
      </div>
      <InputFile
        id="consentForm"
        width="38%"
        type="file"
        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .txt"
        onChange={(e) =>
          handleChangeInputFile(e.target.files[0], props.setConsentForm, typesAcceptedConsentForm)
        } 
        value={props.consentForm.name}
        onBlur={() =>
          props.setConsentFormError(inputFileValidator(props.consentForm, 25))
        }
        onDelete={() => onDelete(props.setConsentForm)}
        error={props.consentFormError.typeError}
        dataTest="input-pf-termo-consentimento"
      />
      <small>Formatos aceitos: png, jpg, jpeg, pdf, doc, docx e txt. Tamanho máximo: 25MB</small>
    </React.Fragment>
  );
};

export default memo(AgreementTerms);
