const INITIAL_STATE = {
  active: 0,
  openModal: false,
  openEditModal: false,
  openDeleteModal: false,
  openAddModal: false,
  consultations: [],
  availableSpecialities: [],
  taxAppointmentFeeProvider: null,
  taxAppointmentFeePatient: null,
  waitingData: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "REGISTER_CONSULTATIONS":
      return {
        ...state,
        consultations: [...state.consultations, action.payload],
      };
    case "RESET_CONSULTATIONS":
      return {
        ...state,
        consultations: action.payload
      }
    case "ALTER_ACTIVE_CONSULTATION":
      return {
        ...state,
        active: action.payload,
      };
    case "DELETE_CONSULTATION":
      return {
        ...state,
        consultations: state.consultations.filter(
          (consultation, index) => index !== action.payload
        ),
      };
    case "UPDATE_CONSULTATION":
      return {
        ...state,
        consultations: state.consultations.map((consultation, index) =>
          index === action.payload
            ? (consultation = action.details)
            : consultation
        ),
      };
    case "DELETE_ALL_CONSULTATIONS":
      return {
        ...state,
        consultations: [],
      };
    case "OPEN_MODAL_CONSULTATION":
      return {
        ...state,
        openModal: action.payload
      }
    case "OPEN_EDIT_MODAL_CONSULTATION":
      return {
        ...state,
        openEditModal: action.payload
      }
    case "OPEN_DELETE_MODAL_CONSULTATION":
      return {
        ...state,
        openDeleteModal: action.payload
      }
    case "OPEN_ADD_MODAL_CONSULTATION":
      return {
        ...state,
        openAddModal: action.payload
      }
    case "SET_AVAILABLE_SPECIALITIES":
      return {
        ...state,
        availableSpecialities: action.payload,
      }
    case "WAITING_CONSULTATIONS_DATA":
      return {
        ...state,
        waitingData: true,
      }
    case "SET_CHOOSEN_UNIT_CONSULTATIONS":
      return { 
        ...state, 
        consultations: action.payload,
        waitingData: false,
      };
    case "SET_CONSULTATION_FEE":
      return {
        ...state,
        taxAppointmentFeeProvider: action.payload.taxAppointmentFeeProvider,
        taxAppointmentFeePatient: action.payload.taxAppointmentFeePatient
      }
    default:
      return state;
  }
}
