import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Loading from '../../loading';

import * as unitActions from '../../../common/store/actions/units.action';
import * as scheduleActions from '../../../common/store/actions/schedule.action';
import graphqlApi from '../../../services/api'
import SnackBar from '../../snackbar/syncAlternative'

const useStyles = makeStyles(() => ({
  container: {
    width: '18vw',
  },
  menuCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1% 2%',
    boxSizing: 'border-box',
  },
  name: {
    fontSize: '14px',
    color: "#000",
    fontWeight: '600',
    "& > h3": {
      marginBottom: '2%',
    }
  },
  email: {
    fontSize: '12px',
    color: '#6B6C7E',
    fontWeight: 'normal',
    "& > p": {
      margin: '2% 0 0 0',
    }
  },
  type: {
    fontSize: '10px',
    color: '#6B6C7E',
    fontWeight: 'normal',
    "& > p": {
      margin: '2vh 0 0 0',
    }
  },
  consultationType: {
    boxShadow: '0px 4px 8px rgba(39, 40, 51, 0.2)',
    borderRadius: '10.5px',
    border: '1px solid #F1F2F5',
    backgroundColor: "#FFF",
    marginTop: '2vh',
    "& > div": {
      fontSize: '10px',
      color: '#000',
      fontWeight: 'normal',
      padding: '5px 10px',
      "&::before": {
        display: 'inline-block',
        content: '""',
        backgroundColor: "#287D3C",
        width: '8px',
        height: '8px',
        marginRight: '8px',
        borderRadius: '50%',
      }
    }
  },
  attachedTo: {
    fontSize: '10px',
    color: '#6B6C7E',
    fontWeight: 'normal',
    width: '100%',
    margin: '2vh auto 0px',
  },
  institutions: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: '0px auto',
  },
  listItem: {
    paddingLeft: '0px',
    width: '100%',
    fontSize: '14px',
    fontWeight: '600',
  },
  selectedItem: {
    backgroundColor: "#F9B233",
  },
  divider: {
    borderTop: '1px solid #A7A9BC',
    marginTop: '1vh',
    "& > li": {
      width: '100%',
      padding: '8px 0 0 0',
      display: 'flex',
      justifyContent: 'center',
    }
  },
  exit: {
    justifyContent: 'center',
  }
}));

export default function SimpleMenu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false)
  const [doctorInVirtua, setDoctorInVirtua] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [establishmentsList, setEstablishmentsList] = useState([]);
  const [userData, setUserData] = useState("");
  const [openSnack, setOpenSnack] = useState(false);

  const userType = window.localStorage.getItem("userType");
  const list = window.localStorage.getItem('estab_list');
  const selectedEstablishment = window.localStorage.getItem("establishmentId");
  const estabName = window.localStorage.getItem('estabName')


  const appointmentType = {
    "PRESENTIAL_AND_ONLINE": "Online e Presencial",
    "PRESENTIAL": "Presencial",
    "ONLINE": "Online"
  }

  useEffect(() => {
    const listEstablishments = JSON.parse(list)
    if (Array.isArray(listEstablishments && estabName !== 'Virtua')) {
      const hasEstablishmentInList = listEstablishments.some(establishment => selectedEstablishment === establishment.establishmentId)
      if (!hasEstablishmentInList) {
        userType !== "PROVIDER_SECRETARY" && window.localStorage.setItem("establishmentId", "");
        userType !== "PROVIDER_SECRETARY" && window.localStorage.setItem("employeeId", "");
        window.localStorage.setItem("estabName", "Particular");
      }
    }
  }, [list, selectedEstablishment, userType, estabName])

  useEffect(() => {
    if (props.status === "OK") {
      if (userType === "PROVIDER_DOCTOR") {
        const { supportType, name, email } = JSON.parse(window.localStorage.getItem("docData"));
        setUserData({ name, email, supportType: appointmentType[supportType] });
      };
      if (userType === "PROVIDER_SECRETARY") {
        const { name, email } = JSON.parse(window.localStorage.getItem("secData"));
        setUserData({ name, email });
      }
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (list) {
      let convertedJSON = JSON.parse(list)
      if (doctorInVirtua) {
        convertedJSON.splice(1, 0, { name: 'Virtua', employeeId: '' })
      }
      setEstablishmentsList(convertedJSON);
    }
  }, [list, doctorInVirtua])

  useEffect(() => {
    const checkDoctorOnVirtua = () => {
      setLoading(true)
      graphqlApi.post('/', {
        query: `
          query {
              virtuaDoctorCheck
          }
        `
      }).then(res => {
        const doctorVirtua = res.data.data.virtuaDoctorCheck
        if (doctorVirtua) setDoctorInVirtua(true)
        else setDoctorInVirtua(false)

        setLoading(false)
      }).catch(err => {
        setOpenSnack(true)
        console.log(err)
        setLoading(false)
      })

    }
    if (Boolean(anchorEl) && userType === 'PROVIDER_DOCTOR') {
      checkDoctorOnVirtua()
    }
  }, [anchorEl, userType])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectEstablishment = ({ establishmentId, employeeId, name }) => {
    if (name === 'Virtua') {
      window.localStorage.setItem("establishmentId", '');
      window.localStorage.setItem("employeeId", '');
      window.localStorage.setItem("estabName", 'Virtua')
    }
    else {
      window.localStorage.setItem("establishmentId", establishmentId);
      window.localStorage.setItem("employeeId", employeeId);
      window.localStorage.setItem("estabName", name)
    }
    dispatch(unitActions.updateFront(true));
    dispatch(scheduleActions.cleanSchedule());
    name !== 'Virtua' ? history.push("/administrador") : history.push('/fila');
    handleClose();
  }

  const getProfession = () => {
    if (userType === 'PROVIDER_DOCTOR') return 'MÉDICO(A)'
    else if (userType === 'PROVIDER_SECRETARY') return 'SECRETÁRIA(O)'

    else if (userType === 'PROVIDER_OTHER_PROFESSIONALS') {
      const profession = window.localStorage.getItem('profession')
      return profession
    }
  }

  return (
    <div>
      <IconButton
        edge="start"
        aria-label="account of current user"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.container }}
      >
        {props.status === "OK" ?
          <div className={classes.menuCard}>
            {loading && <Loading width='50px' />}
            {!loading && (
              <>

                <Avatar />
                <div className={classes.name}><h3>{userData.name}</h3></div>
                <div className={classes.email}><p>{userData.email}</p></div>
                <div className={classes.type}>
                  {/* {userType === "PROVIDER_DOCTOR" ? <p>MÉDICO(A)</p> : <p>SECRETÁRIA(O)</p>} */}
                  <p> {getProfession()} </p>
                </div>
                {userData.supportType && <div className={classes.consultationType}>
                  <div>{userData.supportType}</div>
                </div>}
                <div className={classes.institutions}>
                  { (userType === "PROVIDER_DOCTOR" || userType === "PROVIDER_OTHER_PROFESSIONALS") ?
                    <span className={classes.attachedTo}>Instituições Vinculadas</span>
                    :
                    null
                  }
                  {
                    (userType === "PROVIDER_DOCTOR" || userType === "PROVIDER_OTHER_PROFESSIONALS") &&
                    establishmentsList.map((estab, index) => {
                      const selected =
                        (estab.establishmentId === selectedEstablishment && estabName !== 'Virtua') ||
                        (estabName === 'Virtua' && index === 1);

                      return (
                        <MenuItem
                          key={index}
                          classes={{ root: classes.listItem }}
                          className={selected ? classes.selectedItem : null}
                          onClick={() => selectEstablishment({
                            name: estab.name,
                            establishmentId: estab.establishmentId,
                            employeeId: estab.employeeId
                          })}
                        >
                          {estab.name}
                        </MenuItem>
                      )
                    })
                  }
                </div>
              </>
            )}
          </div>
          :
          null
        }
        {!loading && (
          <div className={props.status === "OK" ? classes.divider : null}>
            <MenuItem
              classes={{ root: classes.listItem }}
              className={classes.exit}
              onClick={() => {
                handleClose();
                history.push("/");
                window.localStorage.clear();
                dispatch(scheduleActions.cleanSchedule());
              }}
            >Sair
            </MenuItem>
          </div>
        )}
      </Menu>
      <SnackBar
        open={openSnack}
        errorMessage="Houve um erro ao tentar verificar se o profisisonal possui cadastro no Virtua. Tente novamente por favor!"
        onClose={setOpenSnack}
      />
    </div>
  );
}
