const INITIAL_STATE = {
  open: false,
  loading: false,
  isSuccess: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "OPEN_TRIAGE_MODAL":
      return { 
        ...state, 
        open: action.payload,
        isSuccess: false,
      };
    case "WAITING_TRIAGE_APPROVAL":
      return {
        ...state,
        loading: action.payload,
      }
    case "IS_SUCCESS":
      return {
        ...state,
        isSuccess: action.payload,
        loading: false,
      }
    default:
      return state;
  }
}
