import React from 'react';
import { makeStyles, List, ListItem, Tooltip} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import EditMenu from '../editMenu';

const useStyles = makeStyles((theme) => ({
  root: {
        width: '100%',
        padding: '0',
        fontWeight: '400',
  },
  item: {
        height: '64px',
        backgroundColor: "#FFFFFF",
        border: '1px solid #E7E7ED',
        padding: '0',
  },
  combo: {
    display: 'flex',
    width: '95%',
    margin: 'auto',
    height: '100%',
  },
  icons: {
    display: 'flex',
    width: '7%'
  },
  icon: {
    width: '48px'
  },
  headers: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    width: "88%",
    marginLeft: "2.5%",
    color: "#6B6C7E"
  },
  line: {
    height: '40px',
    width: "93%",
    margin: "auto",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },
  code: {
    width: props => props.firstColumnWidth ? props.firstColumnWidth : '60px',
  },
  name: {
    width: props => props.secondColumnWidth ? props.secondColumnWidth : '27%',
    marginLeft: '30px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  table: {
    width: props => props.thirdColumnWidth ? props.thirdColumnWidth : '24%',
    marginLeft: '30px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
    
  },
  price: {
    width: props => props.forthColumnWidth ? props.forthColumnWidth : '10%',
    marginLeft: '30px',
  },
  schedule: {
    width: props => props.fifthColumnWidth ? props.fifthColumnWidth : '18%',
    marginLeft: '30px',
    borderRadius: '12.5px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ".makeStyles-headers-33 > &": {
      justifyContent: "flex-start",
    }
  },
  with: {
    backgroundColor: "#F7F8F9",
    fontSize: "10px",
  },
  without: {
    backgroundColor: "#EEF2FA",
    fontSize: "10px",
    color: "#2E5AAC"
  }
}));



const ListDisplay = (props) => {

  const classes = useStyles(props);
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  //console.log('PROPOS', props)
  
  //console.log('parentObject', props.parentObject)
  
  const fullList = props.fullList
 // console.log('fullList', props.fullList)
  
 // console.log('FULL LIST', props.parentObject[fullList])
  return (
    <List className={classes.root}>
      <div className={classes.headers}>  
        <div className={classes.code}>  
          <span id={[props.firstHeader]}>
            {props.firstHeader}
          </span>
        </div>
      
      </div>
      {props.parentObject[fullList].map((value, index) => {
        const labelId = `checkbox-label-${value[props.firstParam]}-${value?.id}`;
      //  console.log('ERROR', value)
        return (
          <ListItem className={classes.item} key={labelId} role={undefined} dense button onClick={handleToggle(value)}>
            <div className={classes.combo}>  
              <div className={classes.line}>  
                <div className={classes.code}>  
                  <span id={value[props.firstParam]}>
                    {value[props.firstParam]}
                  </span>
                </div>
            
              </div>
              <div className={classes.icons}>
                <IconButton
                  disableRipple
                  className={classes.icon} 
                  edge="end" aria-label="delete" 
                  onClick={() => props.deleteItem({parentObject: props.parentObject, value ,index})}
                >
                  <DeleteIcon />
                </IconButton>
                { props.menuItems && <EditMenu
                  items={props.menuItems}
                  index={index}
                />}
                { value.error && 
                  <Tooltip 
                  title={<h2>Houve um erro ao incluir o arquivo, por favor, tente novamente.</h2>}
                  arrow placement="right">
                    <ErrorIcon
                    style={{fill: "#F9B233"}}
                    />
                    </Tooltip>
                }
              </div>
            </div> 
          </ListItem>
        );
      })}
    </List>
  );
}

export default ListDisplay;