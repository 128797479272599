import React, { memo } from "react";

import Topic from "../../../../common/topic";
import Input from "../../../../common/input";
import SearchBar from '../../../../common/searchBar/searchBar';

import empty from "../../../../common/validators/empty";

import {banks} from '../../../../assets/banks';

import VMasker from 'vanilla-masker'

const BankData = (props) => {
  const getMask = (bank) => {
    const object = banks.find(b => b.name === bank)
    if (!object) return { agency: 'SSSSSSSSSSSSSSS', account: 'SSSSSSSSSSSSSSS-S' }
    return object.mask
  }

  const maskAccount = (value) => {
    if (value.length < 2) return value
    return value.replace(/[^A-Za-z0-9]/g, "").replace(/(.*)(.)/g ,'$1-$2')
  }

  const validateAgency = (value, length) => {
    const _empty = empty(value)
    if (_empty.error) return _empty
    return value.length !== length ? { error: true, typeError: "Número da agência inválido" } : { error: false, typeError: "" }
  }

  return (
    <React.Fragment>
      <div className="row">
        <Topic
          classesNameCol="topic-datas middle-topics"
          title="Dados Bancários"
          subtitle="Informe os seus dados bancários para o recebimento dos serviços
              prestados."
        />
      </div>
      <div className="file-input">
        <SearchBar
          sync
          width="24%"
          label="Banco"
          placeholder="Banco"
          backgroundColor="#FFFFFF"
          type="text"
          value={props.bank}
          onChange={props.setBank}
          onBlur={() => props.setBankError(empty(props.bank))}
          error={props.bankError.typeError}
          itemList={banks}
          dataTest="input-pf-banco"
          readOnly={props.readOnly}
        />
        <Input
          width="8%"
          label="Agencia"
          placeholder="Ag."
          backgroundColor={props.readOnly ? "#f7f8f9":"#FFFFFF"}
          border= {props.readOnly ? "none": null}
          type="text"
          value={props.agency}
          onChange={(e) => props.setAgency(VMasker.toPattern(e.target.value, getMask(props.bank).agency))}
          onBlur={() => props.setAgencyError(validateAgency(props.agency, getMask(props.bank).agency.length))}
          InputProps={{readOnly: props.readOnly}}
          error={props.agencyError.typeError}
          dataTest="input-pf-agencia"
        />
        <Input
          width="12%"
          label="Conta"
          placeholder="Conta"
          backgroundColor={props.readOnly ? "#f7f8f9":"#FFFFFF"}
          border= {props.readOnly ? "none": null}
          type="text"
          value={props.account}
          inputProps={{ maxLength: getMask(props.bank).account.length }}
          onChange={(e) => props.setAccount(maskAccount(e.target.value))}
          onBlur={() => props.setAccountError(empty(props.account))}
          InputProps={{readOnly: props.readOnly}}
          error={props.accountError.typeError}
          dataTest="input-pf-conta"
        />
      </div>
    </React.Fragment>
  );
};

export default memo(BankData);
