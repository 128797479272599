import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';

import Button from '../../../common/button';
import Input from '../../../common/input';
import Check from '../../../assets/check-circle-full.png';
import Loading from '../../../common/loading';
import SearchServiceProvided from './../../../common/SearchServiceProvided'
import ServicesProvidedEnum from '../../../common/enums/servicesProvided'

import * as modalActions from '../../../common/store/actions/consultation.action';

const useStyles = makeStyles({
  root: {
    width: props => props.width ? props.width : '55%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  term: {
    lineHeight: '150%',
    fontSize: '18px'
  },
  consentTerm: {
    border: '1px solid #E7E7ED',
    borderRadius: '4px',
    padding: '4%',
    "& > h3": {
      marginTop: 0,
      paddingBottom: '16px',
      borderBottom: '1px solid #E7E7ED'
    },
    "& > p": {
      fontSize: '16px'
    }
  },
  success: {
    backgroundColor: "#EDF9F0",
    color: "#287D3C",
    fontWeight: 600,
  },
  error: {
    fontSize: '14px',
    color: '#DA1414'
  }
})

const SurgicalProcedureModal = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);

  const medicalAppointmentId = window.localStorage.getItem("appointId");
  const patientId = window.localStorage.getItem("patientId");
  const providerId = window.localStorage.getItem("Prov-Vida-Connect");

  const consentData = useSelector((state) => state.ConsultationReducer);
  const open = consentData.openSurgicalProcedure;

  const [surgicalProcedure, setSurgicalProcedure] = useState({ code: "", name: "", id: "" });
  const [surgicalProcedureError, setSurgicalProcedureError] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingListProcedures, setLoadingListProcedures] = useState(false)

  const registerSurgicalProcedure = async () => {
    setLoading(true);
    const newSurgicalProcedure = {
      medicalAppointmentId,
      patientId,
      providerId,
      surgicalProcedure: { ...surgicalProcedure, name: surgicalProcedure._name, budget: null }
    }
    dispatch(modalActions.registerSurgicalProcedure(newSurgicalProcedure));
    setSuccess(true)
    setLoading(false);
    return true
  }

  const validateIfSurgicalProcedureIsAlreadyRegistered = (id) => (consentData.surgicalProcedure || []).some(item => item.surgicalProcedure.id === id)

  const handleClick = () => {
    if (!surgicalProcedure.id) {
      setSurgicalProcedureError('Esse campo é obrigatório!')
      return
    }

    if (validateIfSurgicalProcedureIsAlreadyRegistered(surgicalProcedure.id)) {
      setSurgicalProcedureError('Este procedimento já foi solicitado nesta consulta')
      return
    }

    registerSurgicalProcedure({});
  }

  const handleOnlyClose = () => {
    setSurgicalProcedure({ code: "", name: "", id: "" });
    setSurgicalProcedureError("");
    setSuccess(false);
    setLoading(false);
    setError(false);
    dispatch(modalActions.openSurgicalProcedureModal(false));
  }

  const handleClose = () => {
    const procedureCode = surgicalProcedure.code
    const procedureName = surgicalProcedure.name
    handleOnlyClose()
    if (props.onClose) props.onClose({ procedureCode, procedureName })
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOnlyClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.root
        }}
        TransitionComponent={Grow}
        transitionDuration={{ enter: 200, exit: 0 }}
      >
        <div className={`${classes.header} ${success ? classes.success : null}`}>
          <DialogTitle>
            {
              success ?
                <div>
                  <div>
                    <img src={Check} alt="success" />
                    <span> Procedimento adicionado</span>
                  </div>
                  <div>
                    <small>Agora é necessário preencher o orçamento.</small>
                  </div>
                </div>
                :
                <span>Adicionar procedimento</span>
            }
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleOnlyClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          <form>
            <div className="modal-body">
              <SearchServiceProvided
                onChange={setSurgicalProcedure}
                error={surgicalProcedureError}
                isLoading={setLoadingListProcedures}
                category={[ServicesProvidedEnum.OUTROS, ServicesProvidedEnum.PROCEDIMENTOS_DIAGNOSTICOS, ServicesProvidedEnum.PROCEDIMENTOS_TERAPEUTICOS]}
              />
              {!loadingListProcedures && <small>Carregando procedimentos...</small>}
              <Input
                width="100%"
                label="Nome do procedimento"
                value={surgicalProcedure.code}
                readOnly={true}
              />
            </div>
            <div className={classes.error}>{error}</div>
            <DialogActions className={classes.extraMargin}>
              {
                success ?
                  <Button
                    onClick={handleClose}
                    width="120px"
                    border="1px solid #CDCED9"
                    backgroundColor="#FFFFFF"
                    color="#6B6C7E"
                    margin="0 20px 0 0"
                  >
                    Ok
                  </Button>
                  :
                  loading ?
                    <Loading width="50px" />
                    :
                    <Button
                      onClick={handleClick}
                      width="100%"
                      border="1px solid #CDCED9"
                      backgroundColor="#FFFFFF"
                      color="#6B6C7E"
                      margin="0 20px 0 0"
                    >
                      Registrar pedido
                    </Button>
              }
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SurgicalProcedureModal;
