import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

// import * as personalDatasActions from '../../../common/store/actions/personalDatas';

import Topic from "../../../common/topic";
import Input from "../../../common/input";
import Avatar from "../../../common/avatar";
// import Currency from '../../../common/inputCurrency';
// import ChangeEmailModal from './modals/changeEmail';
// import ChangePasswordModal from './modals/changePassword';
import MyButton from "../../../common/button";
// import BankData from '../../../pages/providerRegistration/pf/form/bankData';
// import InstallmentOptions from '../../../pages/providerRegistration/pf/form/installmentOptions';
// import HealthInsurance from '../../../pages/providerRegistration/pf/form/healthInsurance';

import empty from "../../../common/validators/empty";
import telephoneMask from "../../../common/mask/telephone";
import cpfMask from "../../../common/mask/cpf";
// import emailValidator from '../../../common/validators/optionalEmail';
// import telephoneValidator from '../../../common/validators/telephone';
import SearchBar from "./../../../common/searchBar/searchBar";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
import VMasker from "vanilla-masker";
import CircularProgress from "@material-ui/core/CircularProgress";

import graphqlApi from "./../../../services/api";
import restApi from "./../../../services/restApi";

import { banks } from "../../../assets/banks";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

const useStyles = makeStyles({
  container: {
    marginTop: "14px",
    display: "flex",
    alignItems: "center",
  },
  sideBySide: {
    display: "flex",
    alignItems: "flex-end",
  },
  myButtonStyle: {
    minWidth: "77px",
    minHeight: "32px",
    border: "1px solid #DF9000",
    backgroundColor: "#FFFFFF",
    color: "#DF9000",
    margin: "0 0 0 22px",
  },
});

const getProfile = () =>
  graphqlApi
    .post("/", {
      query: `
        query {
            doctorProfile {
                photo
                name
                cpf
                email
                phone
                crm
                bankData {
                    bank
                    agency
                    account
                }
                minimumInstallmentAmount
                numberOfInstallments
            }
        }
    `,
    })
    .then((res) => {
      const result = res.data;
      if (result.errors) {
        throw new Error("Erro ao buscar as informações");
      }
      return result.data.doctorProfile;
    })
    .catch(() => {
      throw new Error("Erro ao buscar as informações");
    });

  const getProfessionalProfile = () =>
  graphqlApi
    .post("/", {
      query: `
        query {
            providerProfile {
                photo
                name
                cpf
                email
                phone
                profession
                council
                document
                bankData {
                    bank
                    agency
                    account
                }
                minimumInstallmentAmount
                numberOfInstallments
            }
        }
    `,
    })
    .then((res) => {
      const result = res.data;
      if (result.errors) {
        throw new Error("Erro ao buscar as informações");
      }
      return result.data.providerProfile;
    })
    .catch(() => {
      throw new Error("Erro ao buscar as informações");
    });

const Profile = (props) => {
  const classes = useStyles();
  const { name, cpf, email, phone } = JSON.parse(
    window.localStorage.getItem("userData")
  );
  const userType = window.localStorage.getItem("userType");
  if (userType === "PROVIDER_DOCTOR") {
    // var {crm, bank, account, agency, numberPlots, minAmount} = JSON.parse(window.localStorage.getItem("docData"));
    var { crm, bank, account, agency } = JSON.parse(
      window.localStorage.getItem("docData")
    );
  }
  const isProvider = userType === "PROVIDER_DOCTOR" || userType === "PROVIDER_OTHER_PROFESSIONALS"

  const [openEditModal, setOpenEditModal] = useState(false);
  const [doctorError, setDoctorError] = useState("");
  const [doctor, setDoctor] = useState()

  const [bankEdit, setBankEdit] = useState(doctor?.bankData?.bank);
  const [bankError, setBankError] = useState({ error: true, typeError: "" });

  const [agencyEdit, setAgencyEdit] = useState(doctor?.bankData?.agency);
  const [agencyError, setAgencyError] = useState({
    error: true,
    typeError: "",
  });

  const [accountEdit, setAccountEdit] = useState(doctor?.bankData?.account);
  const [accountError, setAccountError] = useState({
    error: true,
    typeError: "",
  });

  const [nameEdit, setNameEdit] = useState(doctor?.name);
  const [nameError, setNameError] = useState({
    error: true,
    typeError: "",
  });

  const [phoneEdit, setPhoneEdit] = useState(doctor?.phone);
  const [phoneError, setPhoneError] = useState({
    error: true,
    typeError: "",
  });

  const [editMEssage, setEditMessage] = useState({
    error: true,
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [editPhotoModal, setEditPhotoModal] = useState(false);
  const [uploadMessage, setUploadMessage] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {
    if (userType === "PROVIDER_DOCTOR") {
      getProfile()
        .then(setDoctor)
        .catch(() => setDoctorError("Erro ao buscar as informações"));
    } else if(userType === 'PROVIDER_OTHER_PROFESSIONALS') {
      getProfessionalProfile()
        .then(setDoctor)
        .catch(() => setDoctorError("Erro ao buscar as informações"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNameEdit(doctor?.name);
    setPhoneEdit(doctor?.phone);
    setBankEdit(doctor?.bankData?.bank);
    setAgencyEdit(doctor?.bankData?.agency);
    setAccountEdit(doctor?.bankData?.account);
  }, [doctor]);

  const getMask = (bank) => {
    const object = banks.find((b) => b.name === bank);
    if (!object)
      return { agency: "SSSSSSSSSSSSSSS", account: "SSSSSSSSSSSSSSS-S" };
    return object.mask;
  };

  const maskAccount = (value) => {
    if (value.length < 2) return value;
    return value.replace(/[^A-Za-z0-9]/g, "").replace(/(.*)(.)/g, "$1-$2");
  };

  const validateAgency = (value, length) => {
    const _empty = empty(value || '');
    if (_empty.error) return _empty;
    return value.length !== length
      ? { error: true, typeError: "Número da agência inválido" }
      : { error: false, typeError: "" };
  };

  // const curriculumInfos = useSelector( (state) => state.PersonalDatas.finalRegister[0].pf.curriculum );
  // const dispatch = useDispatch();

  // const [secondEmail, setSecondEmail] = useState("");
  // const [secondEmailError, setSecondEmailError] = useState({
  //     error: true,
  //     typeError:""
  // });

  // const [telephone, setTelephone] = useState(personal.telephone);
  // const [telephoneError, setTelephoneError] = useState({
  //     error: true,
  //     typeError: ""
  // })

  // const [numberPlots, setNumberPlots] = useState(personal.numberPlots);
  // const [minAmount, setMinAmount] = useState(personal.minAmount);

  // const [healthInsurances, setHealthInsurances] = useState(
  //   personal.healthInsurances
  // );

  // const deleteHealthInsurance = (index) => {
  //     const filtered = healthInsurances.filter( (element, i) => {
  //       return i !== index;
  //     })
  //     setHealthInsurances(filtered);
  //   }

  // useEffect(() => {
  // setSecondEmailError(emailValidator(secondEmail));
  // setBankError(empty(bank.name));
  // setAgencyError(empty(agency));
  // setAccountError(empty(account));
  // eslint-disable-next-line
  // },[])

  // function handleSave() {
  //     // setSecondEmailError(emailValidator(secondEmail));
  //     setTelephoneError(telephoneValidator(telephone));
  //     setBankError(empty(bank));
  //     setAgencyError(empty(agency));
  //     setAccountError(empty(account));

  //     if(
  //         // secondEmailError.error ||
  //         telephoneError.error ||
  //         bankError.error ||
  //         agencyError.error ||
  //         accountError.error
  //     ) {
  //         return "Have some errors yet.";
  //     } else {
  //         const payload = {
  //             // secondEmail,
  //             telephone,
  //             bank,
  //             agency,
  //             account,
  //             numberPlots,
  //             minAmount,
  //             healthInsurances,
  //           };
  //           dispatch(personalDatasActions.personalDatasPf(payload));
  //           return "Good to go."
  //     }
  // }

  const handleConfirm = () => {
    setLoading(true);
    const bankCode = banks.find((bank) => bank.name === bankEdit);
    if (!bankCode || !bankCode.code) {
      setEditMessage({ error: true, message: "Erro ao editar os dados" });
      return;
    }

    const objectEdit = {
      name: nameEdit,
      phone: phoneEdit.replace(/[^\d+]/g, ""),
      agency: agencyEdit,
      account: accountEdit,
      bank: bankEdit,
      bankCode: bankCode.code,
    };

    return graphqlApi
      .post("/", {
        query: `
                mutation($name: String!, $phone: String!, $bank: String!, $bankCode: String!, $agency: String!, $account: String!) {
                    updateDoctor(input:{name: $name, phone: $phone, agency: $agency, account: $account, bank: $bank, bankCode: $bankCode})
                }
            `,
        variables: objectEdit,
      })
      .then((res) => {
        const result = res.data;
        if (result.errors) {
          setEditMessage({ error: true, message: "Erro ao editar os dados" });
          setLoading(false);
          return;
        }
        setDoctor({
          ...doctor,
          bankData: {
            ...doctor?.bankData,
            agency: objectEdit.agency,
            account: objectEdit.account,
            bank: objectEdit.bank,
          },
          name: objectEdit.name,
          phone: objectEdit.phone,
        });
        setLoading(false);
        setEditMessage({ error: false, message: "Dados editados com sucesso" });
        // setInterval(() => {
        //     setOpenEditModal(false)
        //     setEditMessage({ error: false, message: '' })
        // }, 1500)
      })
      .catch(() => {
        setEditMessage({ error: true, message: "Erro ao editar os dados" });
        setLoading(false);
      });
  };

  const [itChanged, setItChanged] = useState(false);

  const handleDisableConfirm = () => {
    const agencyValidation = validateAgency(
      agencyEdit,
      getMask(bankEdit).agency.length
    );

    if (itChanged && agencyValidation.error) return true;
    if (agencyValidation.error) return true;
    if (itChanged) return false;

    return false;
  };

  const handleUpload = ({ target }) => {
    setLoading(true);
    const fileReader = new FileReader();
    const file = target.files[0];

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setUploadMessage({
        error: true,
        message: "Formato inválido. Só é aceito jpg, jpeg e png",
      });
      return;
    }

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      const form = new FormData();
      form.append("photo", e.target.result);
      form.append("originalname", file.name);
      const resource = userType === "PROVIDER_DOCTOR" ? "doctor" : "provider"
      return restApi
        .post(
          process.env.REACT_APP_API_URL + `/${resource}/change-profile-photo`,
          form
        )
        .then(() => {
          setUploadMessage({
            error: false,
            message: "Foto alterada com sucesso",
          });
          setDoctor({ ...doctor, photo: e.target.result });
          setLoading(false);
          setEditPhotoModal(false);
        })
        .catch((err) => {
          const status = err.response.status;

          const statusMessage = {
            400: "Há algo de errado com a imagem. Os formatos aceitos são jpg, jpeg, png e o tamanho limite do arquivo é de 30mb",
            404: "Médico não encontrado para fazer a alteração da foto",
          };

          setUploadMessage({
            error: true,
            message:
              statusMessage[status] ||
              "Houve um erro ao processar a imagem. Por favor, tente novamente mais tarde.",
          });
          setLoading(false);
          setEditPhotoModal(false);
        });
    };
  };

  return (
    <div className="background-grey">
      {Boolean(doctorError) && <Alert severity="error">{doctorError}</Alert>}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Topic classesNameCol="topic-datas firt-topic" title="Meu Perfil" />
        {isProvider && (
          <Button
            onClick={() => setOpenEditModal(true)}
            style={{
              cursor: "pointer",
              padding: "0",
              minWidth: "0",
              marginLeft: "8px",
              fontSize: "small",
              alignSelf: "flex-end",
            }}
            name="edit profile"
          >
            <CreateOutlinedIcon />
          </Button>
        )}
      </div>

      <div>
        <span>
          <strong>Foto</strong>
        </span>
        <div className={classes.container}>
          <Avatar src={doctor?.photo || null} width={"80px"} />
          {isProvider && (
            <MyButton
              styled="customized"
              onClick={() => setEditPhotoModal(true)}
            >
              Alterar
            </MyButton>
          )}

          {/* <Button 
                            // onClick={handleClose}
                            width="77px"
                            height="32px"
                            border="1px solid #CDCED9"
                            backgroundColor="#FFFFFF"
                            color="#6B6C7E"
                            margin="0 0 0 11px"
                        >
                            Remover
                        </Button> */}
        </div>
      </div>

      <Input
        width="50%"
        label="Nome"
        type="text"
        value={doctor?.name || name}
        InputProps={{ readOnly: true }}
        border="none"
        backgroundColor="#f7f8f9"
      />

      <Input
        width="32%"
        label="CPF"
        type="text"
        value={cpfMask(doctor?.cpf || cpf)}
        InputProps={{ readOnly: true }}
        border="none"
        backgroundColor="#f7f8f9"
      />
      <div className={classes.sideBySide}>
        <Input
          width="50%"
          label="Email Principal"
          type="email"
          value={doctor?.email || email}
          InputProps={{ readOnly: true }}
          border="none"
          backgroundColor="#f7f8f9"
        />
        {/* <ChangeEmailModal /> */}
      </div>

      {/* <Input
                    width="32%"
                    label="Email Secundário"
                    placeholder="Digite seu Email"
                    type="email"
                    value={props.secondEmail}
                    onChange={(e) => setSecondEmail(e.target.value)}
                    onBlur={() => setSecondEmailError(emailValidator(secondEmail))}
                    error={secondEmailError.typeError}
                /> */}

      {/* <div>
                    <span><strong>Senha</strong></span>
                    <ChangePasswordModal />
                </div> */}

      {userType !== "PROVIDER_SECRETARY" && (
        <Input
          width="18%"
          label="Telefone"
          placeholder="(11) 00000-0000"
          // backgroundColor="#FFFFFF"
          type="text"
          InputProps={{ readOnly: true }}
          border="none"
          backgroundColor="#f7f8f9"
          value={telephoneMask(doctor?.phone || phone)}
          // onChange={(e) => setPhoneEdit(e.target.value)}
          // onBlur={() =>
          //     setTelephoneError(telephoneValidator(telephone))
          // }
          // error={telephoneError.typeError}
        />
      )}

      {isProvider && (
        <>
          <div>
            <Topic
              classesNameCol="topic-datas middle-topics"
              title="Profissão"
              subtitle="Seus dados referentes a profissão"
            />
          </div>
          <Input
            width="32%"
            label="Profissão"
            type="text"
            value={ doctor ? doctor.profession || "Médico(a)" : ""}
            InputProps={{ readOnly: true }}
            border="none"
            backgroundColor="#f7f8f9"
          />
          <Input
            width="10%"
            label={doctor ? doctor.council || "CRM" : ""}
            type="text"
            value={doctor ? doctor.document || doctor.crm || crm : ""}
            InputProps={{ readOnly: true }}
            border="none"
            backgroundColor="#f7f8f9"
          />
          {/* <div>
                            <BankData
                                bank={bank.name}
                                // setBank={setBank}
                                // bankError={bankError}
                                // setBankError={setBankError}
                                agency={agency}
                                // setAgency={setAgency}
                                // agencyError={agencyError}
                                // setAgencyError={setAgencyError}
                                account={account}
                                // setAccount={setAccount}
                                // accountError={accountError}
                                // setAccountError={setAccountError}
                                readOnly
                            />
                        </div> */}
          <div className="file-input">
            <Input
              width="15%"
              label="Banco"
              type="text"
              value={doctor?.bankData?.bank || bank}
              InputProps={{ readOnly: true }}
              border="none"
              backgroundColor="#f7f8f9"
            />
            <Input
              width="10%"
              label="Agência"
              type="text"
              value={doctor?.bankData?.agency || agency}
              InputProps={{ readOnly: true }}
              border="none"
              backgroundColor="#f7f8f9"
            />
            <Input
              width="20%"
              label="Conta"
              type="text"
              value={doctor?.bankData?.account || account}
              InputProps={{ readOnly: true }}
              border="none"
              backgroundColor="#f7f8f9"
            />
          </div>
          {/* <div>
                            <InstallmentOptions
                                numberPlots={numberPlots}
                                setNumberPlots={setNumberPlots}
                                value={minAmount}
                                setMinAmount={setMinAmount}
                            />
                        </div> */}
          {/* <div className="file-input">
                            <Input
                                width="15%"
                                label="Quantidade de parcelas"
                                type="text"
                                value={doctor.numberOfInstallments || numberPlots}
                                InputProps={{readOnly: true}}
                                border= "none"
                                backgroundColor="#f7f8f9"
                            />
                            <Currency
                                width="20%"
                                classesNameCol="s4 m2 input-side"
                                backgroundColor="#FFFFFF"
                                label="Valor mínimo da parcela"
                                type="number"
                                value={doctor.minimumInstallmentAmount ? Number.isInteger(doctor.minimumInstallmentAmount) ? `${doctor.minimumInstallmentAmount},00` : doctor.minimumInstallmentAmount : Number.isInteger(minAmount) ? `${minAmount},00` : minAmount}
                                onChange={() => {}}
                                readOnly={true}
                                dataTest="input-pf-valor-minimo"
                            />
                        </div> */}
          {/* <div>
                            <HealthInsurance
                                healthInsurances={healthInsurances}
                                setHealthInsurances={setHealthInsurances}
                                deleteItem={deleteHealthInsurance}
                            />
                        </div> */}

          {/* <Button
                                onClick={handleSave}
                                width="100px"
                                border="1px solid #DF9000"
                                backgroundColor="#FFFFFF"
                                color="#DF9000"
                                margin="3px 0 0 0"
                            >
                                Salvar
                        </Button> */}
        </>
      )}
      <Dialog
        open={openEditModal}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Editar Perfil</DialogTitle>
        <DialogContent>
          {Boolean(editMEssage.message) && (
            <Alert severity={editMEssage.error ? "error" : "success"}>
              {editMEssage.message}
            </Alert>
          )}
          <Input
            fullWidth
            type="text"
            label="Nome"
            disabled
            inputProps={{ readOnly: true }} // To go back to edit the field {readOnly: loading}
            value={nameEdit}
            onChange={(e) => {
              setNameEdit(e.target.value);
              setItChanged(e.target.value.length !== 0 ? true : false);
            }}
            onBlur={() => setNameError(empty(nameEdit))}
            error={nameError.typeError}
          />
          <Input
            label="Telefone"
            type="text"
            fullWidth
            inputProps={{ readOnly: loading }}
            value={telephoneMask(phoneEdit || '')}
            onChange={(e) => {
              setPhoneEdit(telephoneMask(e.target.value));
              setItChanged(e.target.value.length !== 0 ? true : false);
            }}
            onBlur={() => setPhoneError(empty(phoneEdit))}
            error={phoneError.typeError}
          />
          <SearchBar
            sync
            width="100%"
            label="Banco"
            placeholder="Banco"
            backgroundColor="#FFFFFF"
            type="text"
            value={bankEdit}
            onChange={(e) =>
              !e.id ? setBankEdit(bankEdit) : setBankEdit(e.id)
            }
            onBlur={() => setBankError(empty(bankEdit))}
            itemList={banks}
            dataTest="input-pf-banco"
            error={bankError.typeError}
            readOnly={loading}
          />
          <Input
            label="Agência"
            type="text"
            fullWidth
            inputProps={{ readOnly: loading }}
            value={agencyEdit}
            onChange={(e) => {
              setAgencyEdit(
                VMasker.toPattern(e.target.value, getMask(bankEdit).agency)
              );
            }}
            onBlur={() =>
              setAgencyError(
                validateAgency(agencyEdit, getMask(bankEdit).agency.length)
              )
            }
            error={agencyError.typeError}
          />
          <Input
            label="Conta"
            type="text"
            fullWidth
            value={accountEdit}
            inputProps={{
              maxLength: getMask(bankEdit).account.length,
              readOnly: loading,
            }}
            onChange={(e) => {
              setAccountEdit(maskAccount(e.target.value));
              setItChanged(e.target.value.length !== 0 ? true : false);
            }}
            onBlur={() => setAccountError(empty(accountEdit))}
            error={accountError.typeError}
          />
        </DialogContent>
        <DialogActions>
          {loading && <CircularProgress color={"inherit"} size={30} />}
          {!loading && editMEssage.error ? (
            <>
              <MyButton
                styled="customized"
                onClick={() => setOpenEditModal(false)}
                disabled={loading}
                color="primary"
              >
                Cancelar
              </MyButton>

              <MyButton
                styled="customized"
                onClick={handleConfirm}
                disabled={handleDisableConfirm()}
                color="primary"
              >
                Confirmar
              </MyButton>
            </>
          ) : (
            <MyButton
              styled="customized"
              onClick={() => {
                setOpenEditModal(false);
                setEditMessage({ error: true, message: "" })
              }}
              disabled={loading}
              color="primary"
            >
              Fechar
            </MyButton>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={editPhotoModal}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Alterar foto de perfil</DialogTitle>
        {Boolean(uploadMessage.message) && (
          <Alert severity={uploadMessage.error ? "error" : "success"}>
            {uploadMessage.message}
          </Alert>
        )}
        <DialogContent>
          <div align="center">
            <input
              accept=".png, .jpg, .jpeg,"
              style={{ display: "none" }}
              id="contained-button-file"
              type="file"
              onChange={handleUpload}
            />
            <label htmlFor="contained-button-file">
              <Button
                style={{
                  backgroundColor: "#F9B23A",
                  fontWeight: "1000",
                  fontFamily: "Roboto",
                  borderRadius: "4px",
                  fontSize: "0.875rem",
                }}
                variant="contained"
                component="span"
                disabled={loading}
              >
                Escolher foto
              </Button>
            </label>
            <br />
            <label htmlFor="contained-button-file">
              <small>
                Formatos aceitos: png, jpg e jpeg. Tamanho máximo: 20MB
              </small>
            </label>
          </div>
        </DialogContent>
        <DialogActions>
          {loading && <CircularProgress color={"inherit"} size={30} />}
          {!loading && (
            <MyButton
              onClick={() => setEditPhotoModal(false)}
              disabled={loading}
              color="primary"
              styled="customized"
            >
              Cancelar
            </MyButton>
          )}
          {/* {Boolean(newPhoto) &&
                    <Button onClick={handleConfirm} disabled={loading} color="primary">
                        Confirmar
                    </Button>} */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
