const INITIAL_STATE = {
    loading: false,
    waitingTokenValidation: true,
    error: false,
    updateFront: true,
    response:{
      specialities:[{name:""}],
      healthOperators:[{name:""}],
      doctors:[{name: ""}]
    },
  };
  
  export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
      case "REQUEST_SENT":
        return {
          ...state,
          loading: true
        }
      case "WAITING_TOKEN_VALIDATION": 
        return {
          ...state,
          waitingTokenValidation: true,
        }
      case "REQUEST_SUCCESS":
        return {
          ...state,
          loading: false,
          waitingTokenValidation: false,
          error: false,
        }
      case "REQUEST_FAILURE":
        return {
          ...state,
          loading: false,
          waitingTokenValidation: false,
          error: action.payload
        }
      case "CLEAN_ERROR":
        return {
          ...state,
          error: false
        };
      case "UPDATE_FRONT":
        return {
          ...state,
          updateFront: action.payload
        }
      case "SET_ASYNC_RESPONSE":
        return{
          ...state,
          response: {...state.response, [action.payload.answerType]: action.payload.data} 
        }
      default:
        return state;
    }
  }
  