import React, { memo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from "../../../../common/searchBar/searchBar";
import Button from '../../../../common/button'
import Topic from "../../../../common/topic";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import * as asyncActions from '../../../../common/store/actions/async.action';

const useStyles = makeStyles({
  icon: {
    width: '40px',
    height: '40px',
    alignSelf: 'flex-end'
  },
  error: {
    color:"#DA1414",
    fontSize:"0.75rem",
    marginTop: '3px',
  }
})

const HealthInsurance = (props) => {
  const classes = useStyles();
  return (
    <div className="row">
      <div className="row">
        <Topic
          classesNameCol="s12 m11 offset-l1 topic-datas middle-topics"
          title="Convênios"
          subtitle="Informe os convênios aceitos em sua empresa."
        />
      </div>
      <span className={classes.error}>{props.error}</span>
      <div className="col s11 offset-l1">
        {props.healthInsurances.map((healthInsurance, index) => (
          <div key={index}>
            <div className="file-input">
              <SearchBar
                  type="healthOperators"
                  width="30%"
                  label="Convênio"
                  placeholder="Nome do convênio"
                  value={props.healthInsurances[index].healthOperators.name}
                  onChange={(value) => {
                    const all = [...props.healthInsurances];
                    const newValue = { 
                      healthOperators:value, 
                      plan: value.plans[0]
                    };
                    all.splice(index,1,newValue);
                    props.setHealthInsurances(all);
                  }}
                  onBlur={() => {}}
                  error={""}
                  asyncAction={asyncActions.getHealthOperators}
                  dataTest="input-pf-convenio"
              />
              {/* <SearchBar
                  sync
                  type="healthOperatorPlans"
                  width="30%"
                  label="Plano"
                  placeholder="Nome do plano"
                  value={props.healthInsurances[index].plan.name}
                  onChange={(value) => {
                    const all = [...props.healthInsurances];
                    const healthOperator = all[index];
                    const newValue = {...healthOperator, plan:value};
                    all.splice(index,1,newValue);
                    props.setHealthInsurances(all);
                  }}
                  onBlur={() => props.healthInsurancesCheck(props.healthInsurances)}
                  error={""}
                  itemList={props.healthInsurances[index].healthOperators.plans}
                  dataTest="input-pf-plano"
                  alreadyChoosen={props.healthInsurances.map( (healthInsurance) => {
                    return healthInsurance.plan
                  })}
              /> */}
              <IconButton className={classes.icon} edge="end" aria-label="delete" onClick={() => props.deleteItem(index)} data-test="input-pf-delete-convenio">
                <DeleteIcon />
              </IconButton>
              </div>
          </div>
        ))}

        <Button
          width="30%"
          margin="32px 0 0 0"
          backgroundColor="#FFFFFF"
          border="1px solid #CDCED9"
          className="btn-add-health-insurances space-above-item-less details"
          onClick={(e) => {
            e.preventDefault();
            props.setHealthInsurances([
              ...props.healthInsurances,
              { healthOperators: 
                { name: "", plans: [{name: ""}]}, 
                // plan: {name: ""} 
              },
            ]);
          }}
          dataTest="button-pf-adicionar-convenio"
        >
          Adicionar Convênio
          <i className="material-icons right black-text">add</i>
        </Button>
      </div>
    </div>
  );
};

export default memo(HealthInsurance);
