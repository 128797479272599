import { creatingChatWindow } from './connectingToARoom';
import { createLocalVideoTrack } from 'twilio-video';

export const muteTrack = (room, kind) => {
        room.localParticipant.tracks.forEach((publication) => {
            if(kind === "video" && publication.kind === "video") {
                publication.track.stop();
                room.localParticipant.unpublishTrack(publication.track);
            };
            if(kind === "audio" && publication.kind === "audio") {
                publication.track.disable();
            }
        });
};

export const enableTrack = (room, kind, localVideoRef) => {
    let localTracks = [];
    room.localParticipant.tracks.forEach( async (publication) => {
        localTracks.push(publication.track);
        if(kind === "audio" && publication.kind === "audio") {
            publication.track.enable();
        };
        if(kind === "video") {
            const localVideoTrack = await createLocalVideoTrack();
            const videoPublication = await room.localParticipant.publishTrack(localVideoTrack);
            await creatingChatWindow(localVideoRef, [localVideoTrack]);
            console.log('Successfully unmuted your video:', videoPublication);
        }
    });
};