import React from 'react';
import apiGraphql from '../../../services/api';
import { useDispatch } from 'react-redux';

import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/core/styles';

import * as asyncActions from '../../../common/store/actions/async.action';

// import RadioButtons from '../../../common/radioButton/radioButton';
// import Select from '../../../common/select';

const useStyles = makeStyles({
    file: {
        gridArea: "file",
        margin: "2% 3%"
    },
    time: {
        fontSize: "14px",
        color: "#DF9000"
    },
    selectedSpeciality: {
        fontSize: "20px",
        fontWeight: '600',
        color: "#272833",
        marginBottom: '30px',
    },
    topic: {
        marginBottom: "30px",
        fontSize: '16px',
        "& > .title": {
            fontWeight: '600',
        },
        "& > p": {
            lineHeight: '20px',
            color: "#6B6C7E",
            marginTop: '5px'
        }
    },
    retorno: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "flex-start",
        marginTop: '20px',
        "& > div": {
            width: '30%'
        }
    },
    files: {
        display: 'flex',
        marginTop: "2vh",
        gap: '3vw',
    },
    pdfFile: {
        display: 'flex',
        padding: '1%',
        borderRadius: '4px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        "& > div": {
            fontSize: '14px'
        },
        "&:hover": {
            cursor: 'pointer',
            backgroundColor: "#F9B233",
            color: "#FFF",
        }
    }
})

const FullFile = ({records, selectedRecord, ...props}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const doctorData = JSON.parse(window.localStorage.getItem("docData"));
    const record = records[selectedRecord] || [];
    let countExamRequest =  0
    let countDoctorPrescriptionAntimicrobials = 0
    let countDoctorPrescriptionSpecial = 0
    let countMedicalReport = 0
    let countDoctorPrescription = 0
    let countSickNote = 0

    const downloadFile = async (id, documentType) => {
        try {
            const res = await apiGraphql({
                data: {
                    query:
                    `
                        mutation($id: ID!, $documentType: DocumentTypeEnum!) {
                            getDocumentDownloadLink(id: $id, documentType: $documentType)
                        }
                    `,
                    variables: {
                        id,
                        documentType,
                    }
                }
            });
            if(res.data.data) {
                window.open(`${res.data.data.getDocumentDownloadLink}`, "_blank");
            } else {
                dispatch(asyncActions.requestFailure("Houve um problema na hora de baixar o arquivo. Tente novamente, por favor."));
            }
            
        } catch(error) {
            dispatch(asyncActions.requestFailure("Houve um problema na hora de baixar o arquivo. Tente novamente, por favor."));
        }
    }

    const handleGetDocumentApi = (documentType) => {
        
        switch (documentType) {
            case 'DOCTOR_PRESCRIPTION_ANTIMICROBIALS':
                countDoctorPrescriptionAntimicrobials++
                return `Receituário antimicrobianos ${countDoctorPrescriptionAntimicrobials}`;
            case 'DOCTOR_PRESCRIPTION_SPECIAL':
                countDoctorPrescriptionSpecial ++
                return `Receituário de controle especial ${countDoctorPrescriptionSpecial}`;
            case 'EXAM_REQUEST':
                countExamRequest ++
                return `Solicitação de Exames ${countExamRequest}`;    
            case 'MEDICAL_REPORT':
                countMedicalReport ++
                return `Relatório Médico ${countMedicalReport}`;
            case 'DOCTOR_PRESCRIPTION':
                countDoctorPrescription ++
                return `Prescrição ${countDoctorPrescription}`;
            case 'SICK_NOTE':
                countSickNote ++
                return `Atestado Médico ${countSickNote}`;
            default:
                return ''        
        }
    }

    const doctorName = record?.provider?.user?.name || doctorData?.name
    const buildCrm = (_record, _doctorData) => {
        if (_record?.provider?.doctor?.crm && _record?.provider?.doctor?.uf) {
            return `${_record.provider.doctor.crm}-${_record.provider.doctor.uf}`
        }
        return `${_doctorData.crm}-${_doctorData.uf}`
    }
    const crm = buildCrm(record, doctorData)


    return (    
        <div className={classes.file}>
            <div>
                <span className={classes.time}>{records[selectedRecord]?.registeredAt || ''}</span>
                <h2 className={classes.selectedSpeciality}>{records[selectedRecord].speciality.name}</h2>
            </div>
            <div className={classes.topic}>
                <span className="title">Médico</span>
                <p>
                    {doctorName}<br/>CRM: {crm}
                </p>
            </div>
            <div className={classes.topic}>
                <span className="title">História Clínica</span>
                <p>
                    {record.clinicalHistory}
                </p>
            </div>
            <div className={classes.topic}>
                <span className="title">Exame Físico</span>
                <p>
                    {record.physicalExam}
               </p>
            </div>
            <div className={classes.topic}>
                <span className="title">Hipótese Diagnóstica</span>
                <p>
                    {record.diagnosticHypothesis}
               </p>
            </div>
            <div className={classes.topic}>
                <span className="title">Tratamento</span>
                <p>
                    {record.treatment}
               </p>
            </div>
            {/* <div className={classes.topic}>
                <span className="title">Retorno</span>
                <div className={classes.retorno}>
                    <div>
                        <RadioButtons 
                            label="Precisa de retorno?"
                            width="42%"
                            margin="0px"
                        />
                    </div>
                    <div>
                        <Select
                            label="O retorno deverá ser feito em até:"
                            onChange={() => {}}
                            onBlur={() => {}}
                            error=""
                            value=""
                            itemList={[
                                {value: 15, exhibit: "15 dias"},
                                {value: 30, exhibit: "30 dias"},
                                {value: 45, exhibit: "45 dias"},
                                {value: 60, exhibit: "60 dias"},
                            ]}
                        />
                    </div>
                        <div>
                        <Select
                            label="Tipo de retorno"
                            onChange={() => {}}
                            onBlur={() => {}}
                            error=""
                            value=""
                            itemList={[
                                {value: "PRESENCIAL_OU_ONLINE", exhibit: "Presencial ou Online"},
                                {value: "ONLINE", exhibit: "Online"},
                                {value: "PRESENTIAL", exhibit: "Presencial"},
                            ]}
                        />
                    </div>
                </div>
            </div> */}
            <div className={classes.topic}>
                <span className="title">Arquivos enviados ao paciente</span>
                <div className={classes.files}>
                    {record.documents && record.documents.map( (file, index) => {
                        const id = file.id;
                        const documentType = file.documentType;
                        return (
                            <div 
                                className={classes.pdfFile} 
                                key={index}
                                onClick={() => downloadFile(id, documentType)}
                            >
                                <DescriptionIcon fontSize={"large"} />
                                <div>{handleGetDocumentApi(documentType)}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
};

export default FullFile;
