export function RegisterOtherServices(payload) {
  return {
    type: "REGISTER_OTHERSERVICES",
    payload: payload,
  };
}

export function ResetOtherServices(payload) {
  return {
    type: "RESET_OTHER_SERVICES",
    payload
  }
}

export function alterActive(index) {
  return {
    type: "ALTER_ACTIVE_OTHERSERVICES",
    payload: index,
  };
}

export function deleteOtherServices(index) {
  return {
    type: "DELETE_OTHERSERVICES",
    payload: index,
  };
}

export function updateOtherServices(index, details) {
  return {
    type: "UPDATE_OTHERSERVICES",
    payload: index,
    details,
  };
}

export function deleteAllOtherServices() {
  return { type: "DELETE_ALL_OTHER_SERVICES" };
}

export function openModal(payload) {
  return {
    type: "OPEN_MODAL_OTHER",
    payload
  }
}