import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import budgetStatus from './../../../common/enums/budgetStatus'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30
  },
  hospitalName: {
    marginBottom: 6
  },
  hospitalNameText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    color: '#272833'
  },
  price: {
    marginBottom: 6
  },
  priceText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    color: '#6B6C7E'
  },
  waitingBudget: {
    marginBottom: 6
  },
  waitingBudgetText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    color: '#6B6C7E'
  },
  iconSuccess: {
    color: 'green'
  },
  iconRepproval: {
    color: 'red'
  }
}));

export default (props) => {
  const classes = useStyles()

  const BudgetAnsweredComponent = data => (
    <Grid container className={classes.price}>
      <Typography className={classes.priceText}>{Number(data.price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
    </Grid>
  )

  const BudgetSendedComponent = () => (
    <Grid container className={classes.waitingBudget}>
      <Typography className={classes.waitingBudgetText}>Aguardando orçamento</Typography>
    </Grid>
  )

  const showComponentPerStatus = status => {
    const components = {
      [budgetStatus.BUDGET_SENDED]: BudgetSendedComponent,
      [budgetStatus.BUDGET_ANSWERED]: BudgetAnsweredComponent,
      [budgetStatus.BUDGET_APPROVED]: BudgetAnsweredComponent,
      [budgetStatus.BUDGET_CANCELED]: BudgetAnsweredComponent,
    }

    return components[status]
  }

  return (
    <>
      {(props.hospitals || []).map((hospital, index) => (
        <Grid container className={classes.root} key={index}>
          <Grid container className={classes.hospitalName}>
            <Typography className={classes.hospitalNameText}>Opção {index + 1} - {hospital.name}</Typography>
          </Grid>
          {showComponentPerStatus(hospital.status)(hospital)}
        </Grid>
      ))}
    </>
  )
}
