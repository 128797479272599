const INITIAL_STATE = {
  count: 0,
  AppointmentRequests: [],
  
}

export default function (state = INITIAL_STATE, action) {
  switch(action.type) {

    case "SET_APPOINTMENT_REQUESTS_COUNT":
      return {
        ...state,
        count: action.payload
      }
    
    case "SET_APPOINTMENT_REQUESTS":
      return {
        ...state,
        AppointmentRequests: action.payload
      }

    default:
      return state
  }
}