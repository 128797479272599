const INITIAL_STATE = {
  units:[],
  waitingData: false,
  update: true,
  specialitiesForUnit: [{
    providerHasSpecialities: {
      speciality: {
        name: ""
      }
    }
  }]
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_UNITS":
      return { 
        ...state, 
        units: action.payload,
        waitingData: false,
      };
    case "SET_SPECIALITIES_FOR_UNITS":
      return { 
        ...state, 
        specialitiesForUnit: action.payload,
        waitingData: false,
      };
    case "WAITING_UNITS":
      return {
        ...state,
        waitingData:true,
      }
    case "UPDATE_UNITS_FRONT":
      return {
        ...state,
        update: action.payload,
      }
    default:
      return state;
  }
}
