import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30
  },
  hospitalName: {
    marginBottom: 6
  },
  hospitalNameText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    color: '#6B6C7E'
  },
}));

export default (props) => {
  const classes = useStyles()

  console.log(props)

  return (
    <>
      {!props.hospital ? (
        <Grid container className={classes.root}>
          <Grid container className={classes.hospitalName}>
            <Typography className={classes.hospitalNameText}>Paciente ainda não escolheu uma opção de hospital</Typography>
          </Grid>
        </Grid >
      ) : (
        <Grid container className={classes.root}>
          <Grid container className={classes.hospitalName}>
            <Typography className={classes.hospitalNameText}>Paciente optou pelo hospital <span style={{ fontWeight: 'bold' }}>{props.hospital.establishment.hospital.tradingName}</span></Typography>
          </Grid>
        </Grid >
      )
      }
    </>
  )
}
