import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import AddExam from '../modals/addExam';
import Topic from '../../../../common/topic';
import Pagination from '../../../../common/pagination';
import SnackBar from '../../../../common/snackbar';
import SnackBarSync from '../../../../common/snackbar/syncAlternative';
import Button from '../../../../common/button';
import InputFileButton from "../../../../common/fileInput/fileInputButton";
import { validationFileType } from '../../../../common/validators/fileInput'
import * as examsActions from '../../../../common/store/actions/registerExams';
import * as examsService from '../../../../services/exams'
import * as asyncActions from '../../../../common/store/actions/async.action';
import EditMenu from '../../../../common/editMenu';
import DownloadSpreadsheet from '../../../../common/DownloadSpreadsheet';
import Loading from '../../../../common/circular-progress';


const useStyles = makeStyles({
  screen: {
    backgroundColor: '#f7f8f9',
    height: 'calc(100vh - 70px)',
    maxHeight: "100%",
    width: "100%",
    padding: '30px',
    boxSizing: 'border-box',
  },
  table: {
    marginBottom: '30px',
    "& tbody": {
      backgroundColor: '#FFF',

    }
  },
  title: {

    display: 'flex',
    alignItems: 'center',

  },
  button: {
    marginLeft: 'auto',
    display: "flex",
    flexDirection: "row"
  },

  pagination: {
    position: "absolute",
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  pageSelection: {
    width: "40%",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },
  items: {
    marginLeft: "50px",
  },
  icon: {
    width: '80px',
  },
  scroll: {
    maxHeight: "calc(77vh - 72px)",
    overflowY: "auto",
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#CDCED9',
      outline: '1px solid slategrey',
      borderRadius: '5px'
    }
  }
})



const ListWithPagination = ({ selectedUnit, setSelectedUnit, modal, list }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const firstItem = (page * itemsPerPage) - (itemsPerPage);
  const lastItem = (firstItem + itemsPerPage);
  const [snack, setSnack] = useState({ open: false, message: '', severity: 'success' })
  const [ loading, setLoading] = useState(false)

  const handleUpdateExam = async (index) => {
    const selectedIndex = index + ((page - 1) * itemsPerPage)

    dispatch(examsActions.getSelectedProviderExam(selectedIndex, list[selectedIndex]))
    dispatch(examsActions.openEditModal(true));
  }
  const handleExamCategory = (category) => {
    switch (category) {
      case 'OUTROS':
        return 'Outros'


      case 'EXAMES_DE_IMAGEM':
        return 'Exames de imagem'


      case 'EXAMES_LABORATORIAIS':
        return 'Exames laboratoriais';

      case 'HEMODERIVADOS':
        return 'Hemoderivados';

      case 'INSUMOS_E_MATERIAIS':
        return 'Insumos e materiais';

      case 'CONSULTA':
        return 'Consulta';

      case 'OUTROS_PROFISSIONAIS':
        return 'Outros profissionais';

      case 'PROCEDIMENTOS_DIAGNOSTICOS':
        return 'Procedimentos diagnósticos'

      case 'PROCEDIMENTOS_TERAPEUTICOS':
        return 'Procedimentos terapêuticos'

      default:
        return ''

    }
  }


  const handleAddExam = async () => {

    dispatch(examsActions.openAddModal(true));
  };

  const handleChangeInputFile = async (value, typesAccepted) => {
    setLoading(true)
    if (!value)
      return dispatch(asyncActions.requestFailure("Erro ao incluir planilha, por favor, tente novamente."))

    const typeIsAccpted = validationFileType(value.type, typesAccepted)

    if (!typeIsAccpted) {
      dispatch(asyncActions.requestFailure("O tipo do arquivo selecionado não é aceito para esse campo!"))
      return
    }

    const data = createFormData(value)

    const res = await handleSave(data)

    saveSpreadsheetResponse(res)
    setLoading(false)
    return
  }
  const createFormData = (file) => {
    const data = new FormData();
    const providerId = window.localStorage.getItem("Prov-Vida-Connect",);
    const unitId = window.localStorage.getItem("Unit-Vida-Connect");

    data.append("providerId", providerId);
    data.append("unitId", unitId);
    data.append("file", file);

    return data
  }

  const handleSave = async (data) => {
    return examsService.saveExamSpreadsheet(data);
  };

  const saveSpreadsheetResponse = (res) => {
    if (res.success) {
      dispatch(examsActions.triggerUpdateExamList(new Date().getTime()));
      setSnack({ open: true, message: "Planilha incluída com sucesso.", severity: 'success' })
    } else {
      dispatch(asyncActions.requestFailure(res.message))
    }
  }

  const typesAccepted = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  return ( 
    <>
   {loading && <Loading size={80} />} 
   {!loading && (
    <div className={classes.screen}>
      <div className={classes.table}>
        <div className={classes.title}>
          <Button
            startIcon={<NavigateBeforeIcon />}
            width="91px"
            heigth="32px"
            border="1px solid #CDCED9"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            margin="0 20px 0 0"
            fontSize="14px"
            fontWeight="400"
            padding="6px 12px"
            onClick={() => setSelectedUnit(null)}
          >
            Voltar
                    </Button>
          <Topic
            marginTop="0"
            title={selectedUnit ? selectedUnit.address : ""}
          />
          <div className={classes.button}>

            <DownloadSpreadsheet />

            <InputFileButton
              id="photo"
              width="190px"
              accept=".xlsx"
              onChange={(e) =>
                handleChangeInputFile(e.target.files[0], typesAccepted)
              }
              value={"Importar planilha"}
              dataTest="input-pf-photo"
              heigth="32px"
              border="1px solid #CDCED9"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 0 0 0"
              fontSize="15px"
              fontWeight="400"
              padding="6px 12px"
            />

            <Button
              width="190px"
              heigth="32px"
              border="1px solid #CDCED9"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 0 0 5px"
              fontSize="14px"
              fontWeight="400"
              padding="6px 12px"
              onClick={handleAddExam}
            >
              Adicionar Exame
                    </Button>
          </div>
        </div>
        <div>

          <AddExam />
        </div>
        {modal}
      </div>
      <div className={`service-register ${classes.scroll}`}>

        <TableContainer>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Cód.</TableCell>
                <TableCell>Exame</TableCell>
                <TableCell>Categoria do exame</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Agendamento</TableCell>


              </TableRow>
            </TableHead>
            <TableBody >

              {list.slice(firstItem, lastItem).map((row, index) => {
                let background
                let category = handleExamCategory(row.serviceProvided.category)
                row.active ? background = "" : background = "#CDCED9"
                return (
                  <TableRow key={row.serviceProvided.code} style={{ backgroundColor: background }}>
                    <TableCell>{row.serviceProvided.code}</TableCell>
                    <TableCell>{row.serviceProvided.description}</TableCell>
                    <TableCell>{category}</TableCell>
                    <TableCell>{`R$: ${row.price}`}</TableCell>
                    <TableCell> {row.needScheduling ? 'COM AGENDAMENTO' : 'SEM AGENDAMENTO'} </TableCell>
                    <TableCell>
                      <div className={classes.icons}>
                        <EditMenu
                          items={[
                            { name: "Editar exame", handleItem: handleUpdateExam }
                          ]}
                          index={index}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}

            </TableBody>

            <TableFooter>
              <TableRow>

              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

      </div>
      <Pagination
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        firstItem={firstItem}
        lastItem={lastItem}
        length={list.length}
      />
      <SnackBar />
      <SnackBarSync
        open={snack.open}
        errorMessage={snack.message}
        onClose={() => setSnack({ open: false, message: '.', severity: 'success' })}
        root={{}}
        severity={snack.severity}
        />

    </div>
   )}
  </>
  )
}

export default ListWithPagination;
