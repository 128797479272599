import React from 'react'
import { View, Text, StyleSheet } from '@react-pdf/renderer'

import { styles } from '../styles'

const localStyles = StyleSheet.create({
    float: {
        flexDirection: 'row'
    },
    lot: {
        width: '60%'
    },
})

export const DispensedProduct = ({ medicineName, laboratory, lotNumber, qtyBoxes, numberRegistry }) => {
    return(
        <View style={styles.bordered}>
            <View style={styles.margined}>
                <Text>Nome do Medicamento: {medicineName}</Text>
                <Text>Laboratório: {laboratory}</Text>
                <View style={localStyles.float}>
                    <Text style={localStyles.lot}>Número do Lote: {lotNumber}</Text>
                    <Text>Quantidade de Caixas: {qtyBoxes}</Text>
                </View>
                <Text style={{lineHeight: 1}}>Número de Registro da Receita no Livro de Receituário: {numberRegistry}</Text>
                <Text>(Quando Medicamento Manipulado)</Text>
            </View>
        </View>
    )
}

export const Interchangeability = ({medicineIntercambiality, medicineIntercambialitySubstituted}) => {
    return (
        <View style={styles.bordered}>
            <View style={styles.margined}>
                <Text>O Medicamento: {medicineIntercambiality}</Text>
                <Text>Foi Substituído Pelo Genérico: {medicineIntercambialitySubstituted}</Text>
                <Text>De Acordo com a Lei 9787/99.</Text>
            </View>
        </View>
    )
}

export const ManualDispensation = () => {
    return(
        <View wrap={false} style={styles.smallText}>
            <View style={styles.seassonTitle}>
                <Text style={{margin: 'auto', lineHeight: 1}}>
                    Para Dispensação Manual
                </Text>
            </View>
            <View style={[styles.smallText, styles.bordered]}>
                <View style={styles.margined}>
                    <View style={{marginBottom: 20}}>
                        <Text>Data:__________________________________</Text>
                    </View>
                    <View style={{marginBottom: 20}}>
                        <Text>Assinatura do Comprador:_____________________________________</Text>
                    </View>
                    <Text>Assinatura do Farmacêutico:__________________________________</Text>
                </View>
            </View>
        </View>
    )
}