import React from "react";
import { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Topic from "../../../../common/topic";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import { format } from "date-fns";
import Icon from "@material-ui/core/Icon";
import Alert from "@material-ui/lab/Alert";
import ReplayIcon from "@material-ui/icons/Replay";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import graphqlApi from "./../../../../services/api";
import HistoricDetailsDialog from "./../../../../common/historicDetailsDialog";
import Pagination from "../../../../common/pagination";
import { handlePatientName } from "../../../../common/handlePatientName";
const useStyles = makeStyles({
  table: {
    marginBottom: "30px",
    "& tbody": {
      backgroundColor: "#FFF",
    },
  },
});

const themeCircularProgress = createMuiTheme({
  palette: {
    primary: { main: "#DF9000" },
  },
});

export default function ConsultationHistory(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [getHistoricError, setGetHistoricError] = useState("");
  const [rows, setRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [modalContent, setModalConten] = useState({
    dateTimeBeginAppointmentUtc: new Date(),
    doctorHasAttended: true,
    patientHasAttended: true,
    status: "",
    patient: {
      socialName: "",
      user: {
        name: "",
      },
    },
    clinicalHistory: "",
    physicalExam: "",
    diagnosticHypothesis: "",
    treatment: "",
    speciality: {
      name: "",
    },
    triage: {
      id: "",
      status: "",
      symptoms: "",
      drugs: "",
      allergies: "",
      diseases: "",
      attachmentHasTriage: [
        {
          id: "",
          bucket: "",
          file: "",
          uri: "",
          triageId: "",
        },
        {
          id: "",
          bucket: "",
          file: "",
          uri: "",
          triageId: "",
        },
      ],
    },
    referral: {
      speciality: {
        name: "",
      },
    },
    documents: [{ documentType: "", documentId: "" }],
  });

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const firstItem = page * itemsPerPage - itemsPerPage;
  const lastItem = firstItem + itemsPerPage;
  const employeeId = window.localStorage.getItem("employeeId");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getHistoric = useCallback((_page, _limit, _employeeId = null) => {
    setLoading(true);
    return graphqlApi
      .post("/", {
        query: `
          query($page: Float, $limit: Float, $employeeId: String) {
            listMedicalAppointmentByDoctor(input:{ page: $page, limit: $limit, employeeId: $employeeId}) {
              rows {
                id
                patientId
                providerId
                unitId
                specialityId
                beginAppointment
                endAppointment
                dateTimeBeginAppointmentUtc
                dateTimeEndAppointmentUtc
                date
                price
                tax
                taxProvider
                appointmentType
                reason
                clinicalHistory
                physicalExam
                diagnosticHypothesis
                treatment
                establishmentId
                doctorHasAttended
                patientHasAttended
                status
                appointmentLocation
                employeeId
                patient {
                  id
                  gender
                  birth
                  socialName
                  user {
                    id
                    name
                    email
                    cpf
                    phone
                  } 
                }
                healthOperatorRequest {
                  id
                  createdAt
                  healthOperator {
                    id
                    fantasyName
                    companyName
                  } 
                }
                triage {
                  id
                  status
                  symptoms
                  drugs
                  allergies
                  diseases
                  attachmentHasTriage {
                    id
                    bucket
                    file
                    uri
                    triageId
                  } 
                }
                speciality {
                  id
                  name
                }
                referral {
                  id
                  specialityId
                  speciality {
                    id
                    name
                  }
                }
                establishment {
                  id
                  clinic {
                    id
                    tradingName
                    companyName
                  } 
                }
                documents {
                  id
                  documentType
                  documentId
                }
                medicalAppointmentReturnGenerated {
                  id
                }
  
                medicalAppointmentReturn {
                  expirationDate
                }
              }
              count
            } 
          }
        `,
        variables: {
          page: _page,
          limit: _limit,
          employeeId: _employeeId,
        },
      })
      .then((res) => {
        const result = res.data;
        if (result.errors) {
          setGetHistoricError(
            "Houve um erro ao buscar o histórico de consultas"
          );
          setLoading(false);
          return;
        }

        setCount(result.data.listMedicalAppointmentByDoctor.count);
        setRows(
          result.data.listMedicalAppointmentByDoctor.rows.filter((item) => {
            if (employeeId)
              return item.employeeId !== null && item.employeeId === employeeId;
            return item.employeeId === null;
          })
        );

        setLoading(false);
      })
      .catch((err) => {
        setGetHistoricError("Houve um erro ao buscar o histórico de consultas");
        setLoading(false);
      });
  });

  useEffect(() => {
    getHistoric(page, itemsPerPage, employeeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, itemsPerPage, employeeId]);

  const handleClickLine = (row) => {
    setModalConten(row);
    setOpen(true);
  };

  const findingHistoric = () => {
    return (
      <div style={{ marginTop: 30 }}>
        <ThemeProvider theme={themeCircularProgress}>
          <CircularProgress color={"primary"} size={50} />
        </ThemeProvider>
      </div>
    );
  };

  const handleStatus = (status, maxDateToReturn) => {
    if (maxDateToReturn)
      return `Consulta finalizada. O paciente pode retornar até ${format(
        new Date(maxDateToReturn),
        "dd/MM/yyyy"
      )}`;
    const apiStatus = {
      UNATTENDED: "Consulta não realizada",
      FINISHED: "Consulta realizada",
      CANCELED: "Consulta cancelada",
    };
    if (!apiStatus[status]) status = "UNATTENDED";
    return apiStatus[status];
  };

  const renderType = (appointment) => {
    if(appointment.appointmentType === 'HEALTH_OPERATOR') {
      if(appointment.healthOperatorRequest){
        return appointment.healthOperatorRequest?.healthOperator?.fantasyName
      } else {
        return 'Retorno Convênio'
      }
    } else {
      if(!appointment.price) {
        return appointment.medicalAppointmentReturnGenerated ? 'Particular' : 'Virtua'
      } else {
        return 'Particular'
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="side-by-side">
        <Topic
          title="Histórico de consulta"
          subtitle={`Histórico de consultas que você realizou.`}
          marginTop="0px"
          subMarginTop="11px"
        />
      </div>

      {loading ? (
        findingHistoric()
      ) : (
        <>
          <TableContainer>
            {Boolean(getHistoricError) && (
              <Alert severity="error">{getHistoricError}</Alert>
            )}
            {!rows.length && (
              <Alert severity="info">
                Não encontramos nenhuma consulta que você tenha realizado
              </Alert>
            )}
            {!!rows.length && (
              <Alert severity="info" style={{ marginTop: "20px" }}>
                O ícone
                <ReplayIcon
                  style={{
                    fontSize: "1.1rem",
                    color: "#DF9000",
                    margin: "0px 5px",
                  }}
                />
                representa uma Consulta de Retorno!
              </Alert>
            )}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Paciente</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell style={{ paddingLeft: "0" }}>Visualizar</TableCell>
                  <TableCell>Atendimento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {handlePatientName(row.patient)}
                      {row.medicalAppointmentReturnGenerated && (
                        <ReplayIcon
                          style={{ margin: "8px 0 0 10px", color: "#DF9000" }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {format(
                        new Date(row.dateTimeBeginAppointmentUtc),
                        "dd/MM/yyyy - HH:mm"
                      )}
                    </TableCell>

                    <TableCell>
                      {handleStatus(
                        row.status,
                        row.medicalAppointmentReturn?.expirationDate
                      )}
                    </TableCell>
                    <TableCell>
                      {renderType(row)}
                    </TableCell>
                    <TableCell onClick={() => handleClickLine(row)}>
                      <Icon style={{ cursor: "pointer", alignSelf: "center" }}>
                        remove_red_eye
                      </Icon>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={
                          row.appointmentLocation === "ONLINE"
                            ? "ONLINE"
                            : "PRESENCIAL"
                        }
                        size="small"
                        variant="outlined"
                        style={{ fontSize: ".7rem" }}
                      ></Chip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TableFooter>
                <TableRow></TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <HistoricDetailsDialog
            open={open}
            handleClose={handleClose}
            modalContent={modalContent}
          />
          <div className={classes.pagination}>
            <Pagination
              page={page}
              setPage={setPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              firstItem={firstItem}
              lastItem={lastItem}
              length={count}
              width="95%"
            />
          </div>
        </>
      )}
    </>
  );
}
