import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from './../../../common/button'
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import FormControl from '@material-ui/core/FormControl';

const theme = createMuiTheme({
  palette: {
    primary: { main: "#DF9000" },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));

export default (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(props.open);
  const [value, setValue] = useState('');

  useEffect(() => setOpen(props.open), [props.open])

  const handleSelect = (event) => {
    setValue(event.target.value)
    // props.select(event.target.value)
  }

  const handleSave = (item) => {
    props.select(item)
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Convênios</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecione um dos convênios listados
          </DialogContentText>
          {
            props.healthOperators.length ?
            (
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Selecione um convênio</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value}
                  onChange={handleSelect}
                  autoWidth={true}
                >
                  {props.healthOperators.map(item => (
                    <MenuItem key={item.planId} value={item}>{item.fantasyName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) :
            (
              <p>Você já cadastrou todos os convênios parceiros do Vida Connect</p>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.handleClose}
            width="200px"
            // border="1px solid #CDCED9"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
          >
            Cancelar
          </Button>
          {
            props.healthOperators.length ?
            (
              <Button
                onClick={() => handleSave(value)}
                width="200px"
                // border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
              >
                Salvar
              </Button>
            ) :
            (
              <Button
                onClick={props.handleClose}
                width="200px"
                // border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
              >
                Ok
              </Button>
            )
          }
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
