import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import ModalBase from '../modalBaseWithouFlow';
import ModalUpdateExam from './updateExam'
import empty from "../../validators/empty";
import Input from "../../input";
import TextArea from '../../textarea';
import InputCurrency from '../../inputCurrency';

import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles';

import "../style.css";
import * as exam from "../../store/actions/registerExams";

import setTussCodeMask from '../../mask/tussCode'

import Select from '../../select';
import SnackBar from '../../snackbar';
import SearchServiceProvided from '../../SearchServiceProvided'

import * as examsActions from "../../store/actions/registerExams";
import * as asyncActions from '../../store/actions/async.action';

const useStyles = makeStyles({
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  }
})

const ModalRegisterExam = (props) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  useEffect(() => {
    dispatch(asyncActions.getExamFee());
    //eslint-disable-next-line
  }, [])


  const examExistsState = useSelector((state) => state.Exams.examExists);
  const taxExamFee = useSelector((state) => state.Exams.taxExamFee);

  const [examId, setExamId] = useState("");

  const [preparation, setPreparation] = useState("");
  const [preparationError, setPreparationError] = useState("");

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");

  const [examCode, setExamCode] = useState("");
  const [examCodeError, setExamCodeError] = useState("");

  const [name, setName] = useState("");


  const [examsFromApi, setExamsFromApi] = useState({});
  const [examError, setExamError] = useState("");

  const [examLoading, setExamLoading] = useState(false);

  const openModal = useSelector((state) => state.Exams.openModal);
  let examData = useSelector((state) => state.Exams.exams);
    
  useEffect(() => {
    setExamCode(examsFromApi.code)
    setExamId(examsFromApi.id)
    setName(examsFromApi.name)
    setExamCodeError("")
    dispatch(exam.GetExam(examsFromApi.id))
    // eslint-disable-next-line
  }, [examsFromApi])


  function handleSubmit(e) {
    e.preventDefault();
    if (isNaN(price) || empty(price).typeError || empty(examCode).typeError || empty(preparation).typeError) {
      setExamCodeError(empty(examCode).typeError);
      
      setPriceError(empty(''));
      setPreparationError(empty(preparation));
      return
    }

    const payload = { examCode, name, preparation, needToSchedule: true, price, examId };
      if (examExistsState > 0) {
        dispatch(asyncActions.requestFailure("Este exame já foi incluído na lista."))
        return
      }
      dispatch(exam.RegisterExam(payload));

    dispatch(exam.openModal(false));
    dispatch(exam.alterActive(examData.length + 1));
    setExamCode("")
    setName("");
    
    setPreparation("");
    setPrice(0);
    setExamCodeError("")
    setPriceError("")
    setPreparationError("")

    return true;
  }


  const handleClose = () => {

    setExamCode("")
    setName("");
    setPreparation("");
    setPrice("");
    setExamCodeError("")
    setPriceError("")
    setPreparationError("")
    dispatch(examsActions.openModal(false));
  };

  return (
     !openModal ? (
    <ModalBase title="Cadastrar Exame" 
        buttonWidth={props.buttonWidth}
        buttonText={props.buttonText}
        handleSubmit={handleSubmit}
        open={openModal}
        onClose={handleClose}
        disabled={props.disabled}>
      <Select
        label="Tabela"
        InputProps={{ readOnly: true }}
        value={'TUSS'}
        itemList={[
          { value: "TUSS", exhibit: "TUSS" },
        ]}
      />
     
      <SearchServiceProvided
        onChange={setExamsFromApi}
        error={setExamError}
        isLoading={setExamLoading}
        value={name}
      />
      {Boolean(examError) && <span>{examError}</span>}
      {!examLoading && <span>Carregando exames...</span>}
      <Input
        width="100%"
        label="Código do exame"
        value={examCode}
        onChange={(e) => setExamCode(setTussCodeMask(e.target.value))}
        error={examCodeError}
        InputProps={{ readOnly: true }}
      />
      <InputCurrency
        width="100%"
        label="Valor em reais (R$)"
        value={price}
        onChange={setPrice}
        error={priceError.typeError}
        styles={{
          fontSize: '12px',
          marginTop: '3px',
          color: "#DA1414"
        }}
        dataTest="modal-cadastrar-consulta-input-preco"
      />
      <DialogContentText className={classes.lettering}>
        O valor cobrado pela plataforma será de: R$ {((taxExamFee / 100) * price).toFixed(2)}
      </DialogContentText>

      <TextArea
        width="93%"
        label="Preparo para o exame"
        placeholder="Instruções para o preparo"
        rows={8}
        value={preparation}
        onChange={setPreparation}
        onBlur={() => setPreparationError(empty(preparation).typeError)}
        error={preparationError.typeError}
        max={250}
      />

      <SnackBar />

    </ModalBase>
  ) : (<ModalUpdateExam />))
      
};

export default ModalRegisterExam;
