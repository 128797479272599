import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : '#F5F5F5',
    height: '100px',
  },
  wholePackage: {
    width: '87.5%',
    height: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stepper: {
    width: '49%'
  },
  clear: {
    backgroundColor: 'transparent',
    padding: '0'
  },
  button: {
    border: '1px solid #CDCED9',
    color: '#6B6C7E',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '600',
    '&:hover': {
        backgroundColor: '#F9B233',
        borderColor: "transparent",
        color: '#FFFFFF'
      },
  },
  icon: {
    width: '32px',
    height: '32px',
    padding: '0',
    color:'#E7E7ED',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
      fill: '#6B6C7E',
      fontWeight: '600',
      marginTop: '10px'
  },
  iconActive: {
    color:'#F9B233 !important',
    '& .MuiStepIcon-text': {
        fill: '#FFFFFF',
    }
  },
  iconCompleted: {
      color:'#6B6C7E !important'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const StepByStep = (props) => {
  const classes = useStyles(props);
  const activeStep = props.activeStep;
  const steps = props.steps || [];

  const handleNext = () => {
    props.handleAdvance();
  };

  const handleBack = () => {
    props.handleBack();
  };

  return (
    <div className={classes.root}>
        <div className={classes.wholePackage}>
            <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
                data-test={`button-stepper-anterior-${activeStep}`}
            >
                <ArrowBackIosIcon fontSize={'small'}/>
                Anterior
            </Button>
            {props.stepper ? 
              <div className={classes.stepper}>
                  <Stepper className={classes.clear} activeStep={activeStep}>
                      {steps.map((label, index) => (
                      <Step key={index} className={classes.clear}>
                          <StepLabel
                              classes={{
                                  iconContainer: classes.icon,
                              }}
                              StepIconProps={{
                                  classes: {
                                      root: classes.icon,
                                      text: classes.text,
                                      active: classes.iconActive,
                                      completed: classes.iconCompleted
                                  }
                              }}
                          >
                              {label}
                          </StepLabel>
                      </Step>
                      ))}
                  </Stepper>
              </div>
              :
              null
            }
            <Button className={classes.button} onClick={handleNext} disabled={props.disableForward ? props.disableForward : false} data-test={`button-stepper-proximo-${activeStep}`}>
                {activeStep === steps.length - 1 ? 'Avançar' : 'Avançar'}
                <ArrowForwardIosIcon fontSize={'small'}/>
            </Button>
        </div>
    </div>
  );
}

export default StepByStep;