export const addTimeSpan = (payload) => {
    return {
        type: "ADD_TIME_SPAN",
        payload
    }
}

export const addDaysOfWeek = (payload) => {
    return {
        type: "ADD_DAYS_OF_WEEK",
        payload
    }
}

export const addDaysHours = (payload) => {
    return {
        type: "ADD_DAY_HOURS",
        payload
    }
}

export const addDuration = (payload) => {
    return {
        type: "ADD_DURATION",
        payload
    }
}

export const openCreateScheduleModal = (payload) => {
    return {
        type: "OPEN_CREATE_SCHEDULE_MODAL",
        payload
    }
}

export const openSlotModal = (payload) => {
    return {
        type: "OPEN_SLOT_MODAL",
        payload
    }
}

export const searchIsDone = () => {
    return {
        type: "STOP_WAITING_FOR_SCHEDULE_RESULTS",
    }
}

export const updateScheduleFront = (payload) => {
    return {
        type: "UPDATE_SCHEDULE_FRONT",
        payload
    }
}

export const updateCalendarFront = (payload) => {
    return {
        type: "UPDATE_CALENDAR_FRONT",
        payload
    }
}

export const cleanSchedule = () => {
    return {
        type: "CLEAN_SCHEDULE"
    }
}

export const setUnit = (payload) => {
    return {
        type: "SET_UNIT_SCHEDULE",
        payload
    }
}

export function updateSchedule(payload){
    return {
        type:"UPDATE_SCHEDULE",
        payload
    }
}

export function updateAppointments(payload){
    return {
        type:"UPDATE_APPOINTMENTS_AND_UNAVAILABILITIES",
        payload
    }
}

export function schedulePatients(payload){
    return {
        type:"SCHEDULE_PATIENTS",
        payload
    }
}

export function waitingResults() {
    return {
        type: "WAITING_SCHEDULE_RESULTS",
    }
}