import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {format} from 'date-fns';


// import empty from '../../../common/validators/empty';
// import telphoneValidator from "../../../common/validators/telephone";
import cnpjValidator from '../../../common/validators/cnpj'
import maskTelephone from "../../../common/mask/telephone";
import maskCnpj from '../../../common/mask/cnpj'

import SnackBar from '../../../common/snackbar/index';
import Input from '../../../common/input';
import TextArea from '../../../common/textarea';
import Button from '../../../common/button';

import * as consultationActions from '../../../common/store/actions/consultation.action';
import * as asyncActions from '../../../common/store/actions/async.action';

import { generatePdf } from '../../../utils/certification/pdfGenerator'
import {documentSignature} from '../../../utils/certification/certification';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';
import Loading from "../../../common/loading";
import { handlePatientName } from "../../../common/handlePatientName";
import cpfMask from "../../../common/mask/cpf";

// Material UI Styling

const useStyles = makeStyles( {
    root: {
        width: props => props.width ? props.width : '70vw',
        maxWidth: "70vw !important",
        fontSize: "inherit",
    },
    header: {
        display: 'flex',
        justifyContent: "space-between"
    },
    topic: {
        marginBottom: "30px",
        fontSize: '16px',
        "& > .title": {
            fontWeight: '600',
        },
        "& > p": {
            lineHeight: '20px',
            color: "#6B6C7E",
            marginTop: '5px'
        }
    },
    retorno: {
        "& > div": {
            width: '100%',
            marginTop: "5%"
        }
    },
    success: {
        backgroundColor: "#EDF9F0",
        color: "#287D3C",
        fontWeight: 600,
    },
    extraMargin: {
        margin: '50px 0 21px 0',
        padding: 0
    },
    error: {
        fontSize: '12px',
        color: "#DA1414",
    },
    pdf: {
        height: '60vh',
    },
    hasBorder: {
        border: '1px solid #000',
        marginTop:"10px",
        "& .topic-title": {
            width: '100%',
            borderBottom: '1px solid #000',
            "& h3":{
                textAlign:"center"
            }
        },
        "& .topic-content": {
            padding:'10px',
        },
        "& .border":{
            borderBottom:'1px solid #000'
        }
    },
    indetifier: {
        display:'flex',
        marginBottom:'3 0px',
        justifyContent:'space-between',
        "& div":{
            maxWidth:'490px'
        }
    }
    
});

// Component

const DoctorPrescriptionSpecial = (props) => {
    const dispatch = useDispatch();
    const providerId = window.localStorage.getItem("Prov-Vida-Connect");
    const medicalAppointmentId = window.localStorage.getItem("appointId");
    const patientId = window.localStorage.getItem("patientId");
    
    const docData = JSON.parse(window.localStorage.getItem("docData"));
    const provData = JSON.parse(window.localStorage.getItem("providerData"));
    const  docName = docData?.name || provData?.name
    const email = docData?.email || provData?.email
    const cpf = docData?.cpf || provData?.cpf
    const crm = docData?.crm || ''
    const crmUf = docData?.uf || provData?.uf
    
    const data = useSelector((state) => state.ConsultationReducer)
    const open = data.openDoctorPrescriptionSpecial;
    const classes = useStyles(props);

    const [renderPDF, setRenderPDF] = useState(false);

    const [addressEmitter, setAddressEmitter] = useState('')
    const [cityEmitter, setCityEmitter] = useState('')
    const [ufEmitter, setUfEmitter] = useState('')
    const [telephoneEmitter, setTelephoneEmitter] = useState('')

    const [patientAddress, setPatientAddress] = useState('')
    const [prescription, setPrescription] = useState('')

    const [identifierBuyerName, setIdentifierBuyerName] = useState('')
    const [identifierBuyerRg, setIdentifierBuyerRg ] = useState('')
    const [identifierBuyerCompanyEmitterRg, setIdentifierBuyerCompanyEmitterRg] = useState('')
    const [identifierBuyerAddress, setIdentifierBuyerAddress] = useState('')
    const [identifierBuyerCity, setIdentifierBuyerCity] = useState('')
    const [identifierBuyerUf, setIdentifierBuyerUf] = useState('')
    const [identifierBuyerTelephone, setIdentifierBuyerTelephone] = useState('')

    const [pharmaceuticalName, setPharmaceuticalName] = useState('')
    const [pharmaceuticalCrf, setPharmaceuticalCrf] = useState('')
    const [pharmaceuticalUf, setPharmaceuticalUf] = useState('')
    const [pharmacyName, setPharmacyName] = useState('')
    const [pharmacyAddress, setPharmacyAddress] = useState('')
    const [pharmacyCity, setPharmacyCity] = useState('')
    const [pharmacyUf, setPharmacyUf] = useState('')
    const [pharmacyCnpj, setPharmacyCnpj] = useState('')
    const [pharmacyCnpjError, setPharmacyCnpjError] = useState({error:false, typeError:''})
    const [pharmacyTelephone, setPharmacyTelephone] = useState('')

    const [medicineName1, setMedicineName1] = useState('')
    const [laboratory1, setLaboratory1] = useState('')
    const [lotNumber1, setLotNumber1] = useState('')
    const [qtyBoxes1, setQtyBoxes1] = useState('')
    const [numberRegistry1, setNumberRegistry1] = useState('')
    const [medicineName2, setMedicineName2] = useState('')
    const [laboratory2, setLaboratory2] = useState('')
    const [lotNumber2, setLotNumber2] = useState('')
    const [qtyBoxes2, setQtyBoxes2] = useState('')
    const [numberRegistry2, setNumberRegistry2] = useState('')
    const [medicineName3, setMedicineName3] = useState('')
    const [laboratory3, setLaboratory3] = useState('')
    const [lotNumber3, setLotNumber3] = useState('')
    const [qtyBoxes3, setQtyBoxes3] = useState('')
    const [numberRegistry3, setNumberRegistry3] = useState('')

    const [medicineIntercambiality1, setMedicineIntercambiality1 ] = useState('')
    const [medicineIntercambialitySubstituted1, setMedicineIntercambialitySubstituted1 ] = useState('')
    const [medicineIntercambiality2, setMedicineIntercambiality2 ] = useState('')
    const [medicineIntercambialitySubstituted2, setMedicineIntercambialitySubstituted2 ] = useState('')
    const [medicineIntercambiality3, setMedicineIntercambiality3 ] = useState('')
    const [medicineIntercambialitySubstituted3, setMedicineIntercambialitySubstituted3 ] = useState('')

    const [success, setSuccess] = useState(false);

    const [patientName, setPatientName] = useState("");
    const [patientCpf, setPatientCpf] = useState("")
    
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        if(data.data.patient) {
            const patName = handlePatientName(data.data?.patient, false)
            setPatientName(patName);
            setPatientCpf(cpfMask(data.data.patient.user.cpf))
        }

        if(data.local) {
            let address = data.local.address || ''
            if(data.local.number) address += `, ${data.local.number}`
            if(data.local.neighborhood) address += `, ${data.local.neighborhood}`
            if(data.local.complement) address += `, ${data.local.complement}`
            
            setAddressEmitter(address)
            setCityEmitter(data.local.city || '')
            setUfEmitter(data.local.district || '')
            setTelephoneEmitter(data.local.phone || '')
        }

        //eslint-disable-next-line
    }, [data])

    const handleClose = () => {
        setAddressEmitter('')
        setCityEmitter('')
        setUfEmitter('')
        setTelephoneEmitter('')
        setPatientAddress('')
        setPrescription('')
        setIdentifierBuyerName('')
        setIdentifierBuyerRg('')
        setIdentifierBuyerCompanyEmitterRg('')
        setIdentifierBuyerAddress('')
        setIdentifierBuyerCity('')
        setIdentifierBuyerUf('')
        setIdentifierBuyerTelephone('')
        setPharmaceuticalName('')
        setPharmaceuticalCrf('')
        setPharmaceuticalUf('')
        setPharmacyName('')
        setPharmacyAddress('')
        setPharmacyCity('')
        setPharmacyUf('')
        setPharmacyCnpj('')
        setPharmacyTelephone('')
        setMedicineName1('')
        setLaboratory1('')
        setLotNumber1('')
        setQtyBoxes1('')
        setNumberRegistry1('')
        setMedicineName2('')
        setLaboratory2('')
        setLotNumber2('')
        setQtyBoxes2('')
        setNumberRegistry2('')
        setMedicineName3('')
        setLaboratory3('')
        setLotNumber3('')
        setQtyBoxes3('')
        setNumberRegistry3('')
        setMedicineIntercambiality1('')
        setMedicineIntercambialitySubstituted1('')
        setMedicineIntercambiality2('')
        setMedicineIntercambialitySubstituted2('')
        setMedicineIntercambiality3('')
        setMedicineIntercambialitySubstituted3('')
        setRenderPDF(false)
        setSuccess(false)
        setLoading(false)
        dispatch(consultationActions.openDoctorPrescriptionSpecial(false));
    }

    const handleSave = async () => {
        setLoading(true);
        const documentPayload  = {
            title:"Receituário de controle especial",
            doctorName: docName,
            crm,
            crmUf,
            addressEmitter,
            cityEmitter,
            ufEmitter,
            telephoneEmitter,
            patientName,
            patientCpf,
            patientAddress,
            prescription,
            identifierBuyerName,
            identifierBuyerRg,
            identifierBuyerCompanyEmitterRg,
            identifierBuyerAddress,
            identifierBuyerCity,
            identifierBuyerUf,
            identifierBuyerTelephone,
            pharmaceuticalName,
            pharmaceuticalCrf,
            pharmaceuticalUf,
            pharmacyName,
            pharmacyAddress,
            pharmacyCity,
            pharmacyUf,
            pharmacyCnpj,
            pharmacyTelephone,
            medicineName1,
            laboratory1,
            lotNumber1,
            qtyBoxes1,
            numberRegistry1,
            medicineName2,
            laboratory2,
            lotNumber2,
            qtyBoxes2,
            numberRegistry2,
            medicineName3,
            laboratory3,
            lotNumber3,
            qtyBoxes3,
            numberRegistry3,
            medicineIntercambiality1,
            medicineIntercambialitySubstituted1,
            medicineIntercambiality2,
            medicineIntercambialitySubstituted2,
            emission: format(new Date(), 'dd/MM/yyyy')
        };
        const fileName="Receituário-de-controle-especial.pdf";
        const user = {name: docName, email, identifier: cpf};

        const authenticationPayload = {
            fileName,
            user,
            providerId,
            medicalAppointmentId,
            patientId,
            documentType: "DOCTOR_PRESCRIPTION_SPECIAL",
        };
        try {
            const {embedUrl} = await generatePdf(documentPayload, authenticationPayload);
            setRenderPDF(true);
            await documentSignature("pdf", embedUrl, setSuccess, dispatch, consultationActions.setCountDoctorPrescriptionSpecial);
            consultationActions.setCountDoctorPrescriptionSpecial()//teste
            setLoading(false);
        } catch(error) {
            dispatch(asyncActions.requestFailure("Não foi possível exibir o PDF para assinatura agora."));
            setLoading(false);
        };
    }

    return (
            <div>
                <Dialog 
                    open={open}
                    onClose={handleClose} 
                    aria-labelledby="form-dialog-title" 
                    classes={{
                    paper: classes.root
                    }}
                    TransitionComponent={Grow}
                    transitionDuration={{enter: 200, exit: 0}}
                    disableBackdropClick={renderPDF || loading}
                    disableEscapeKeyDown={renderPDF || loading}
                >
                <div className={`${classes.header} ${success ? classes.success : null}`}>
                    <DialogTitle>
                    {renderPDF ?
                      <span>Assine o documento</span>:
                      <span>RECEITUÁRIO DE CONTROLE ESPECIAL</span>
                    }
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogContent dividers>
                    {renderPDF ?
                        <div id="pdf" className={classes.pdf}></div>
                        :
                      <div>
                          <div className={classes.hasBorder}>
                              <div className="topic-title">
                                  <h3>IDENTIFICAÇÃO DO EMITENTE</h3>
                              </div>
                              <div className="topic-content">
                                <Input
                                label="Nome Completo"
                                InputProps={{readOnly: true}}
                                type="text"
                                value={docName}
                                backgroundColor={"#F7F8F9"}
                                border={"none"}
                                fontSize="14px !important"
                                />
                                <div className="file-input">
                                    <Input
                                        label="CRM"
                                        width='80%'
                                        InputProps={{readOnly: true}}
                                        type="text"
                                        value={crm}
                                        backgroundColor={"#F7F8F9"}
                                        border={"none"}
                                        fontSize="14px !important"
                                    />
                                    <Input
                                        label="UF"
                                        width="15%"
                                        InputProps={{readOnly: true}}
                                        type="text"
                                        value={crmUf}
                                        backgroundColor={"#F7F8F9"}
                                        border={"none"}
                                        fontSize="14px !important"
                                    />
                        
                                </div>
                                <Input
                                    label="Endereço Completo"
                                    type="text"
                                    value={addressEmitter}
                                    onChange={(e) => setAddressEmitter(e.target.value)}
                                    fontSize="14px !important"
                                />
                                <div className="file-input">
                                    <Input
                                        label="Cidade"
                                        width='80%'
                                        type="text"
                                        value={cityEmitter}
                                        onChange={(e) => setCityEmitter(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <Input
                                        label="UF"
                                        width="15%"
                                        type="text"
                                        value={ufEmitter}
                                        onChange={(e) => setUfEmitter(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                </div>
                                <div className="file-input">
                                    <Input
                                        label="Telefone"
                                        width='70%'
                                        type="text"
                                        value={telephoneEmitter}
                                        onChange={(e) => setTelephoneEmitter(maskTelephone(e.target.value))}
                                        fontSize="14px !important"
                                    />
                                    <Input
                                        label="Data"
                                        width="25%"
                                        InputProps={{readOnly: true}}
                                        type="text"
                                        value={format(new Date(), 'dd/MM/yyyy')}
                                        backgroundColor={"#F7F8F9"}
                                        border={"none"}
                                        fontSize="14px !important"
                                    />
                                </div>
                            </div>
                          </div>

                            <div className="file-input">
                                <Input
                                    width="75%"
                                    label="Nome Paciente"
                                    InputProps={{readOnly: false}}
                                    onChange={(e) => setPatientName(e.target.value)}
                                    type="text"
                                    value={patientName}
                                    backgroundColor={"#F7F8F9"}
                                    border={"none"}
                                    fontSize="14px !important"
                                />
                                <Input
                                    width="25%"
                                    label="CPF"
                                    InputProps={{readOnly: false}}
                                    // onChange={(e) => setPatientCpf(cpfMask(e.target.value))}
                                    type="text"
                                    value={patientCpf}
                                    backgroundColor={"#F7F8F9"}
                                    border={"none"}
                                    fontSize="14px !important"
                                />
                            </div>  
                            <Input
                              label="Endereço Completo"
                              type="text"
                              value={patientAddress}
                              onChange={(e) => setPatientAddress(e.target.value)}  
                              fontSize="14px !important"
                            />
                            <TextArea
                              width="95%"
                              label="Prescrição"
                              placeholder=""
                              value={prescription}
                              onChange={setPrescription}
                              rows={6}
                            />
                            <div className={classes.indetifier}>
                                <div className={classes.hasBorder}>
                                    <div className="topic-title">
                                        <h3>IDENTIFICAÇÃO DO COMPRADOR</h3>
                                    </div>
                                    <div className="topic-content">
                                        <Input
                                            label="Nome Completo"
                                            type="text"
                                            value={identifierBuyerName}
                                            onChange={(e) => setIdentifierBuyerName(e.target.value)}                                        
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="RG"
                                            type="text"
                                            value={identifierBuyerRg}
                                            onChange={(e) => setIdentifierBuyerRg(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="Órgão Emissor"
                                            type="text"
                                            value={identifierBuyerCompanyEmitterRg}
                                            onChange={(e) => setIdentifierBuyerCompanyEmitterRg(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="Endereço Completo"
                                            type="text"
                                            value={identifierBuyerAddress}
                                            onChange={(e) => setIdentifierBuyerAddress(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <div className="file-input">
                                            <Input
                                                label="Cidade"
                                                width="80%"
                                                type="text"
                                                value={identifierBuyerCity}
                                                onChange={(e) => setIdentifierBuyerCity(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                            <Input
                                                label="UF"
                                                width="15%"
                                                type="text"
                                                value={identifierBuyerUf}
                                                onChange={(e) => setIdentifierBuyerUf(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                        </div>
                                        <Input
                                            label="Telefone"
                                            type="text"
                                            value={identifierBuyerTelephone}
                                            onChange={
                                                (e) => setIdentifierBuyerTelephone(maskTelephone(e.target.value))
                                            }
                                            fontSize="14px !important"
                                        />
                                    </div>
                                </div>
                                <div className={classes.hasBorder}>
                                    <div className="topic-title">
                                        <h3>IDENTIFICAÇÃO DO FORNECEDOR</h3>
                                    </div>
                                    <div className="topic-content">
                                        <Input
                                            label="Nome Farmacêutico(a)"
                                            type="text"
                                            value={pharmaceuticalName}
                                            onChange={(e) => setPharmaceuticalName(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <div className="file-input">
                                            <Input
                                                label="CRF"
                                                width="80%"
                                                type="text"
                                                value={pharmaceuticalCrf}
                                                onChange={(e) => setPharmaceuticalCrf(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                            <Input
                                                label="UF"
                                                width="15%"
                                                type="text"
                                                value={pharmaceuticalUf}
                                                onChange={(e) => setPharmaceuticalUf(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                        </div>
   
                                        <Input
                                            label="Nome Farmácia"
                                            type="text"
                                            value={pharmacyName}
                                            onChange={(e) => setPharmacyName(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="Endereço"
                                            type="text"
                                            value={pharmacyAddress}
                                            onChange={(e) => setPharmacyAddress(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <div className="file-input">
                                            <Input
                                                label="Cidade"
                                                width="80%"
                                                type="text"
                                                value={pharmacyCity}
                                                onChange={(e) => setPharmacyCity(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                            <Input
                                                label="UF"
                                                width="15%"
                                                type="text"
                                                value={pharmacyUf}
                                                onChange={(e) => setPharmacyUf(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                        </div>
                                        <div className="file-input">
                                            <Input
                                                label="CNPJ"
                                                type="text"
                                                value={pharmacyCnpj}
                                                onChange={(e) => setPharmacyCnpj(maskCnpj(e.target.value))}
                                                onBlur={() => setPharmacyCnpjError(cnpjValidator(pharmacyCnpj))}
                                                error={pharmacyCnpjError.typeError}
                                                fontSize="14px !important"
                                            />
                                            <Input
                                                label="Telefone"
                                                type="text"
                                                value={pharmacyTelephone}
                                                onChange={
                                                    (e) => setPharmacyTelephone(maskTelephone(e.target.value))
                                                }
                                                fontSize="14px !important"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.hasBorder}>
                                <div className="topic-title">
                                    <h3>DADOS DO(S) PRODUTO(S) DISPENSADOS</h3>
                                </div>
                                <div className="topic-content">
                                    <Input
                                        label="Nome do Medicamento"
                                        type="text"
                                        value={medicineName1}
                                        onChange={(e) => setMedicineName1(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <Input
                                        label="Laboratório"
                                        type="text"
                                        value={laboratory1}
                                        onChange={(e) => setLaboratory1(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                        <Input
                                            label="Número do Lote"
                                            type="text"
                                            value={lotNumber1}
                                            onChange={(e) => setLotNumber1(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                         <Input
                                            label="Quantidade de Caixas"
                                            type="text"
                                            value={qtyBoxes1}
                                            onChange={(e) => setQtyBoxes1(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                    <Input
                                        label="Número de Registro da Receita no Livro de Receituário
                                        (Quando Medicamento Manipulado)"
                                        type="text"
                                        value={numberRegistry1}
                                        onChange={(e) => setNumberRegistry1(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                </div>
                            </div>

                            <div className={classes.hasBorder}>
                                <div className="topic-content">
                                <Input
                                        label="Nome do Medicamento"
                                        type="text"
                                        value={medicineName2}
                                        onChange={(e) => setMedicineName2(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <Input
                                        label="Laboratório"
                                        type="text"
                                        value={laboratory2}
                                        onChange={(e) => setLaboratory2(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                        <Input
                                            label="Número do Lote"
                                            type="text"
                                            value={lotNumber2}
                                            onChange={(e) => setLotNumber2(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                         <Input
                                            label="Quantidade de Caixas"
                                            type="text"
                                            value={qtyBoxes2}
                                            onChange={(e) => setQtyBoxes2(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                    <Input
                                        label="Número de Registro da Receita no Livro de Receituário
                                        (Quando Medicamento Manipulado)"
                                        type="text"
                                        value={numberRegistry2}
                                        onChange={(e) => setNumberRegistry2(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                </div>
                            </div>

                            <div className={classes.hasBorder}>
                                <div className="topic-content">
                                <Input
                                        label="Nome do Medicamento"
                                        type="text"
                                        value={medicineName3}
                                        onChange={(e) => setMedicineName3(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <Input
                                        label="Laboratório"
                                        type="text"
                                        value={laboratory3}
                                        onChange={(e) => setLaboratory3(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                        <Input
                                            label="Número do Lote"
                                            type="text"
                                            value={lotNumber3}
                                            onChange={(e) => setLotNumber3(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                         <Input
                                            label="Quantidade de Caixas"
                                            type="text"
                                            value={qtyBoxes3}
                                            onChange={(e) => setQtyBoxes3(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                    <Input
                                        label="Número de Registro da Receita no Livro de Receituário
                                        (Quando Medicamento Manipulado)"
                                        type="text"
                                        value={numberRegistry3}
                                        onChange={(e) => setNumberRegistry3(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                </div>
                            </div>

                            <div className={classes.hasBorder}>
                                <div className="topic-title">
                                    <h3>INFORMAÇÕES SOBRE INTERCAMBIALIDADE</h3></div>
                                <div className="topic-content border">
                                    <Input
                                        label="De Acordo Com a Lei 9787/99, o Medicamento:"
                                        type="text"
                                        value={medicineIntercambiality1}
                                        onChange={(e) => setMedicineIntercambiality1(e.target.value)}
                                        fontSize="14px !important"
                                        />
                                        <Input
                                            label="Foi Substituído pelo Genérico:"
                                            type="text"
                                            value={medicineIntercambialitySubstituted1}
                                            onChange={(e) => setMedicineIntercambialitySubstituted1(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                </div>
                                <div className="topic-content border">
                                <Input
                                        label="De Acordo Com a Lei 9787/99, o Medicamento:"
                                        type="text"
                                        value={medicineIntercambiality2}
                                        onChange={(e) => setMedicineIntercambiality2(e.target.value)}
                                        fontSize="14px !important"
                                        />
                                        <Input
                                            label="Foi Substituído pelo Genérico:"
                                            type="text"
                                            value={medicineIntercambialitySubstituted2}
                                            onChange={(e) => setMedicineIntercambialitySubstituted2(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                </div>
                                <div className="topic-content">
                                     <Input
                                        label="De Acordo Com a Lei 9787/99, o Medicamento:"
                                        type="text"
                                        value={medicineIntercambiality3}
                                        onChange={(e) => setMedicineIntercambiality3(e.target.value)}
                                        fontSize="14px !important"
                                        />
                                     <Input
                                        label="Foi Substituído pelo Genérico:"
                                        type="text"
                                        value={medicineIntercambialitySubstituted3}
                                        onChange={(e) => setMedicineIntercambialitySubstituted3(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                </div>
                            </div>
                          <DialogActions className={classes.extraMargin}>
                              {success ?
                                  <Button 
                                      onClick={handleClose}
                                      width="120px"
                                      border="1px solid #DF9000"
                                      backgroundColor="#FFFFFF"
                                      color="#DF9000"
                                  >
                                      Ok
                                  </Button>
                                  :
                                  loading ?
                                      <Loading width="50px" />
                                      :
                                      <>
                                          <Button 
                                              onClick={handleClose}
                                              width="120px"
                                              border="1px solid #CDCED9"
                                              backgroundColor="#FFFFFF"
                                              color="#6B6C7E"
                                              margin="0 20px 0 0"
                                          >
                                              Não
                                          </Button>
                                          <Button
                                              width="120px"
                                              border="1px solid #DF9000"
                                              backgroundColor="#FFFFFF"
                                              color="#DF9000"
                                              onClick={handleSave}
                                          >
                                              Sim
                                          </Button>
                                      </>
                              }
                          </DialogActions>
                      </div>
                    }
                    <SnackBar />   
                </DialogContent>
                </Dialog>
            </div>
    );
};

export default memo(DoctorPrescriptionSpecial);
