import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import Button from '@material-ui/core/Button';
import Button from '../../../../common/button';
import graphqlApi from '../../../../services/api';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import { useDispatch } from 'react-redux';
import * as asyncActions from '../../../../common/store/actions/async.action';
import ConfirmDialog from './../../../../common/confirmDialog'
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//MuiButton-text
const useStyles = makeStyles((theme) => ({
  root: {
        width: '100%',
        padding: '0',
        fontWeight: '400',
  },
  item: {
        height: '64px',
        backgroundColor: "#FFFFFF",
        border: '1px solid #E7E7ED',
        padding: '0',
  },
  combo: {
    display: 'flex',
    width: '95%',
    margin: 'auto',
    height: '100%',
  },
  icons: {
    display: 'flex',
    width: '7%'
  },
  icon: {
    width: '48px'
  },
  headers: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    width: "88%",
    marginLeft: "2.5%",
    marginBottom: "10px",
    color: "#6B6C7E"
  },
  line: {
    height: '40px',
    width: "100%",
    margin: "auto",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },
  code: {
    width: props => props.firstColumnWidth ? props.firstColumnWidth : '100%',
    display: "flex",
    justifyContent: "space-between"
  },
  notActive: {
    opacity: 0.3,
    border: "none"
  },
  alertItem: {
    color: "#1976d2"
  }
}));



const ListDisplay = (props) => {
  
  const classes = useStyles(props);
  const [snack, setSnack] = useState({});
  const dispatch = useDispatch();
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [rowClicked, setRowClicked] = useState(null)
  
  const handleCancelDelete = () => {
    setOpenConfirmDeleteModal(false)
    setRowClicked({})
  }

  const handleClickDeleteIcon = (row) => {
    setOpenConfirmDeleteModal(true)
    setRowClicked(row)
  }

  const handleCloseSnack = (event, reason) => {

    if (reason === 'clickaway') {
      return;
    }

    const configSnack = {
      open: false,
      message: '',
      typeALert: ''
    }
    setSnack(configSnack);
    
  };

  function loadListOfSecretaries() {
    const providerId = window.localStorage.getItem("Prov-Vida-Connect");
    const currentEstablishment = window.localStorage.getItem('estabName')
    const establishmentId = window.localStorage.getItem("establishmentId")
    const employeeId = window.localStorage.getItem("employeeId")

    if (currentEstablishment === "Particular") {
        dispatch(asyncActions.getSecretariesList(providerId));
    } else {
        dispatch(asyncActions.getClinicSecretariesList(establishmentId, employeeId));
    }
  }

  const handleToggleButtonActivatySecretary = (secretary) => {    
    const providerId = window.localStorage.getItem('Prov-Vida-Connect');
    if (secretary.active) {      
      inactivateSecretary(secretary.id, providerId);
    } else {
      activateSecretary(secretary.id, providerId);
    }
  }

  async function activateSecretary (secretaryId, providerId) {

    const establishmentId = window.localStorage.getItem("establishmentId");

    const mutation = `mutation {
      activateSecretary(
        secretaryHasProviderId: "${providerId}",
        secretaryId: "${secretaryId}",
        establishmentId: "${establishmentId}"
      ) 
      { 
        id, 
        active, 
        status, 
        secretaryId, 
        providerId, 
        establishmentId, 
        employeeId 
      }
    }`;

    await graphqlApi({
      data: {
        query: mutation
      },
    }).then(res => {

      const secretaryHasProvider = res.data.data.activateSecretary;
      if (secretaryHasProvider.active) {

        //props.reloadSecretaryList();
        loadListOfSecretaries();

        const configSnack = {
          open: true,
          message: 'Secretária ativada com sucesso!',
          typeALert: 'success'
        }
        setSnack(configSnack);

      } else {

        throw new Error(`
          Erro ao tentar ativar secretária. 
          Tente novamente ou contate o administrador
        `);

      }    

    }).catch(err => {

      console.error(err);
      const configSnack = {
        open: true,
        message: 'Não foi possível concluir a operação',
        typeALert: 'error'
      }
      setSnack(configSnack);
      
    });

  }

  async function inactivateSecretary (secretaryId, providerId) {

    
    const establishmentId = window.localStorage.getItem("establishmentId");

    const mutation = `mutation {
      disableSecretary(
        secretaryHasProviderId: "${providerId}",
        secretaryId: "${secretaryId}",
        establishmentId: "${establishmentId}"
      ) 
      { 
        id, 
        active, 
        status, 
        secretaryId, 
        providerId, 
        establishmentId, 
        employeeId 
      }
    }`;   

    await graphqlApi({
      data: { query: mutation },
    }).then(res => {

      const secretaryHasProvider = res.data.data.disableSecretary;

      if (!secretaryHasProvider.active) {
        loadListOfSecretaries();
        //props.reloadSecretaryList();
        const configSnack = {
          open: true,
          message: 'Secretária desativada com sucesso',
          typeALert: 'success'
        }
        setSnack(configSnack);

      } else {

        throw new Error(`
          Erro ao tentar desativar secretária. 
          Tente novamente ou contate o administrador
        `);

      }
      

    }).catch(err => {

      console.error(err);
      const configSnack = {
        open: true,
        message: 'Não foi possível concluir a operação',
        typeALert: 'error'
      }
      setSnack(configSnack);

    });
  }  

  async function deleteSecretary () {

    const establishmentId = window.localStorage.getItem("establishmentId");
    const providerId = window.localStorage.getItem('Prov-Vida-Connect');
    const secretaryId = rowClicked.id
    const mutation = `mutation {
      deleteSecretaryForProvider(
        secretaryHasProviderId: "${providerId}",
        secretaryId: "${secretaryId}",
        establishmentId: "${establishmentId}"
      ) 
      { 
        id, 
        active, 
        status, 
        secretaryId, 
        providerId, 
        establishmentId, 
        employeeId 
      }
    }`;   

    await graphqlApi({
      data: { query: mutation },
    }).then(res => {

      const secretaryHasProvider = res.data.data.deleteSecretaryForProvider;

      if (!secretaryHasProvider.active) {
        loadListOfSecretaries();
        const configSnack = {
          open: true,
          message: 'Secretária excluída com sucesso',
          typeALert: 'success'
        }
        setSnack(configSnack);

      } else {

        throw new Error(`
          Erro ao tentar excluir secretária. 
          Tente novamente ou contate o administrador
        `);

      }
      
      setOpenConfirmDeleteModal(false)
    }).catch(err => {

      console.error(err);
      const configSnack = {
        open: true,
        message: 'Não foi possível concluir a operação',
        typeALert: 'error'
      }
      setSnack(configSnack);
      setOpenConfirmDeleteModal(false)
    });
  } 
  const renderSecretaryActivityButton = (secretary) => {    
    
    switch(secretary.status) {
      case 'OK':
        return (
          <>
          <Button            
            border="1px solid #CDCED9"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            onClick={() => handleToggleButtonActivatySecretary(secretary)}
          >
            {secretary.active ? "Desativar" : "Ativar"}
          </Button>
          <IconButton
           disableRipple
           className={classes.icon} 
           edge="end" aria-label="delete" 
           onClick={() => handleClickDeleteIcon(secretary)}
         >
           <DeleteIcon />
         </IconButton>
         </>
        );
      case 'PENDING_REGISTRATION':
        return (
          <span className={classes.alertItem}>Cadastro pendente</span>
        )
      default:
        return '';
    }
  }


  return (
    <>
    <List className={classes.root}>
      <div className={classes.headers}>  
        <div className={classes.code}>  
          <span id={[props.firstHeader]}>
            {props.firstHeader}
          </span>
        </div>
      </div>
      {props.fullList.map((value, index) => {
        const labelId = `checkbox-label-${value[props.firstParam]}-${value[props.secondParam]}-${value[props.thirdParam]}`;
        const secretary = props.fullList[index];
        return (
          <ListItem className={`${classes.item}`} key={labelId} role={undefined} dense button>
            <div className={classes.combo}>  
              <div className={classes.line}>  
                <div className={classes.code}>
                  
                  <span className={`${value.active ? null : classes.notActive}`} id={value[props.firstParam]}>
                    {value[props.firstParam]}
                  </span>
                  <div style={{display: 'flex'}}>
                  {renderSecretaryActivityButton(secretary)}
                  </div>
                 
                
                  <Snackbar open={snack.open} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity={snack.typeALert}>
                      {snack.message}
                    </Alert>
                  </Snackbar>
                  
                </div>
              </div>
            </div> 
          </ListItem>
        );
      })}
    </List>
    <ConfirmDialog
            open={openConfirmDeleteModal}
            handleCancel={handleCancelDelete}
            handleConfirm={deleteSecretary}
            title="Deseja realmente excluir?"
            contentText="Deseja realmente excluir esta secretária? Não será possível reativar."
            item={rowClicked}
          />
    </>
  );
}

export default ListDisplay;