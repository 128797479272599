import React from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import CalendarIcon from '../../assets/calendar.png';

import './style.css';

const useStyles = makeStyles({
  root: {
    borderRadius: '10px'
  },
  input: {
    width: props => props.width ? props.width : "224px",
    margin: props => props.margin ? props.margin : "5px 0 0 0 !important",
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : null,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& .MuiInput-root': {
      height: '40px',
      border: "1px solid #e7e7ed !important",
      borderRadius: '4px',
    },
    '& > .MuiFormControl-root > p': {
      backgroundColor: "#f8f8f8",
      display: 'block',
      position: 'absolute',
      top: '100%',
      whiteSpace: 'nowrap',
    }
  }
});

class LocalizedUtils extends DateFnsUtils {
    getWeekdays() {
        return ["D","S","T", "Q", "Q", "S", "S"]
    }
}

function BasicDatePicker(props) {

  const classes = useStyles(props);

  return (
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={ptBR}>
      <div className={classes.input}>
        <KeyboardDatePicker
          disableToolbar
          disablePast={props.disabledPast}
          readOnly={props.readOnly}
          autoOk
          variant={"inline"}
          PopoverProps={{classes: {paper: classes.root}}}
          value={props.value}
          onChange={(date) => props.onChange(date)}
          minDate={props.minDate}
          format="dd-MM-yyyy"
          keyboardIcon={<img src={CalendarIcon} alt="Escolha a data" />}
          mask="__-__-____"
          invalidDateMessage="Formato de data inválido."
          minDateMessage="Data menor que o dia mínimo (hoje)."
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default BasicDatePicker;