import React from 'react'

import SurgicalProcedureBudget from '../../../../common/surgicalProcedureBudget'

export default (props) => {
  return (
    <SurgicalProcedureBudget
      procedureName={props.procedure.name}
      procedureCode={props.procedure.code}
      open={props.open}
      budget={props.budget}
      onSave={props.onSave}
      onFinish={props.onFinish}
      onClose={props.onClose}
      disableFormToEdition={props.disableFormToEdition}
    />
  )
}
