import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import HorizontalList from './../horizontalList';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

import HospitalBudget from './hospitalBudget'
import MedicalTeamBudget from './medicalTeamBudget'
import Button from './../button'
import SnackBar from './../snackbar/syncAlternative'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'transparent',
    color: '#272833'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  subtitle: {
    marginTop: theme.spacing(-5),
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
  tabs: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5vh 0',
    alignItems: 'stretch',
    alignContent: 'stretch'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SurgicalProcedureBudget(props) {
  const classes = useStyles();

  // const [open, setOpen] = useState(props.open || false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [procedureName, setProcedureName] = useState(props.procedureName || 'Nome do procedimento');
  const [procedureCode, setProcedureCode] = useState(props.procedureCode || 'Código do procedimento');
  const [hospitalBudget, setHospitalBudget] = useState(props?.budget?.hospitalBudget || {});
  const [medicalTeamBudget, setMedicalTeamBudget] = useState(props?.budget?.medicalTeamBudget || {});
  const [hasChange, setHasChange] = useState(true)
  const [openConfirmToClose, setOpenConfirmToClose] = useState(false)
  const [snack, setSnack] = useState({ open: false, message: '', severity: 'success', duration: 5000 })
  const [openConfirmFinishToClose, setOpenConfirmFinishToClose] = useState(false)
  const [disableFormToEdition, setDisableFormToEdition] = useState(props.disableFormToEdition || false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (props.budget?.hospitalBudget) setHospitalBudget(props.budget.hospitalBudget)
    if (props.budget?.medicalTeamBudget) setMedicalTeamBudget(props.budget.medicalTeamBudget)
  }, [props.budget])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setProcedureName(props.procedureName || 'Nome do procedimento'), [props.procedureName])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setProcedureCode(props.procedureCode || 'Código do procedimento'), [props.procedureCode])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setDisableFormToEdition(props.disableFormToEdition || false), [props.disableFormToEdition])

  const resetState = () => {
    setSelectedTab(0)
    setHospitalBudget({})
    setMedicalTeamBudget({})
    setHasChange(true)
    setOpenConfirmToClose(false)
    setSnack({ open: false, message: '', severity: 'success', duration: 5000 })
    setOpenConfirmFinishToClose(false)
  }

  // useEffect(() => setOpen(props.open || false), [props.open])

  const listOptions = [
    { text: 'Orçamento hospitalar' },
    { text: 'Orçamento da equipe médica' }
  ]

  const handleClose = () => {
    if (disableFormToEdition) {
      if (props.onClose) props.onClose()
      return
    }

    if (hasChange) {
      setOpenConfirmToClose(true)
      return
    }
    if (props.onSave) props.onSave({ hospitalBudget, medicalTeamBudget, procedure: { name: procedureName, code: procedureCode }, finish: false })
    if (props.onClose) props.onClose()
    resetState()
  }

  const handleCloseConfirmToClose = ({ save }) => {
    if (save) {
      if (props.onSave) props.onSave({ hospitalBudget, medicalTeamBudget, procedure: { name: procedureName, code: procedureCode }, finish: false })
    }
    setOpenConfirmToClose(false)
    if (props.onClose) props.onClose()
    resetState()
    // setOpen(false)
  }

  const handleSave = () => {
    if (disableFormToEdition) return
    if (props.onSave) props.onSave({ hospitalBudget, medicalTeamBudget, procedure: { name: procedureName, code: procedureCode }, finish: false })
    setHasChange(false)
  }

  const handleButtonFinishBudget = () => {
    if (disableFormToEdition) return
    if (hasChange) {
      setSnack({
        message: 'Para finalizar o orçamento, primeiro você precisa salvá-lo.',
        severity: 'error',
        open: true,
      })
      return
    }

    const budgetValidation = validateBudget()
    if (!budgetValidation.isValid) {
      setSnack({
        message: budgetValidation.message || 'Houve um erro ao salvar o orçamento. Por favor, tente novamente mais tarde',
        severity: 'error',
        open: true,
      })
      return
    }

    setOpenConfirmFinishToClose(true)
  }

  const handleFinishBudget = () => {
    if (hasChange) {
      setOpenConfirmFinishToClose(false)
      setSnack({
        message: 'Para finalizar o orçamento, primeiro você precisa salvá-lo.',
        severity: 'error',
        open: true,
      })
      return
    }

    const budgetValidation = validateBudget()
    if (!budgetValidation.isValid) {
      setOpenConfirmFinishToClose(false)
      setSnack({
        message: budgetValidation.message || 'Houve um erro ao salvar o orçamento. Por favor, tente novamente mais tarde',
        severity: 'error',
        open: true,
      })
      return
    }

    if (props.onFinish) props.onFinish({ hospitalBudget, medicalTeamBudget, procedure: { name: procedureName, code: procedureCode }, finish: true })
    if (props.onClose) props.onClose()
    // setOpen(false)
    resetState()
    // setOpenConfirmFinishToClose(false)
  }

  const validateData = validations => {
    const objectToReturnValidation = {
      isValid: true,
      errorMessage: null
    }

    for (const validation of validations) {
      if (Boolean(validation.condition)) {
        objectToReturnValidation.isValid = false
        objectToReturnValidation.errorMessage = validation.errorMessage
        break
      }
    }

    return objectToReturnValidation
  }

  const validateHospitalBudget = () => {
    const {
      hasOpmeOrAditionalMaterials,
      opmeOrAditionalMaterials,
      hasHospitalization,
      hospitalizationQuantities,
      hasBloodProducts,
      bloodProducts,
      hasSpecialMedications,
      specialMedications,
      hospitalsSelected,
    } = hospitalBudget

    const validations = [
      {
        condition: !Object.keys(hospitalBudget)?.length,
        errorMessage: 'É necessário o preenchimento do orçamento hospitalar'
      },
      {
        condition: hasOpmeOrAditionalMaterials && (!Boolean(opmeOrAditionalMaterials.opme) && !Boolean(opmeOrAditionalMaterials.aditionalMaterials)),
        errorMessage: 'Ao selecionar que precisa de OPME ou outros materiais, você precisa preencher os campos'
      },
      {
        condition: hasHospitalization && (hospitalizationQuantities < 1 || !Boolean(hospitalizationQuantities)),
        errorMessage: 'Ao selecionar que precisa de internação em UTI, o número de diárias precisa ser pelo menos 1'
      },
      {
        condition: hasBloodProducts && !Boolean(bloodProducts),
        errorMessage: 'Ao selecionar que precisa de hemoderivados, você precisa preencher os campos'
      },
      {
        condition: hasSpecialMedications && !Boolean(specialMedications),
        errorMessage: 'Ao selecionar que precisa de medicamentos especiais, você precisa preencher os campos'
      },
      {
        condition: !hospitalsSelected?.length,
        errorMessage: 'É necessário selecionar pelo menos um hospital'
      }
    ]

    return validateData(validations)
  }

  const validateMedicalTeamBudget = () => {
    const validations = [
      {
        condition: !Object.keys(medicalTeamBudget).length,
        errorMessage: 'É necessário o preenchimento do orçamento da equipe médica'
      },
      {
        condition: !Boolean(medicalTeamBudget?.price) || medicalTeamBudget?.price === 0,
        errorMessage: 'O valor do cirurgião é obrigatório'
      },
      {
        condition: (medicalTeamBudget?.helper || [{ price: 0 }]).some(item => !Boolean(item.price) || item.price === 0),
        errorMessage: 'Nenhum auxiliar pode ficar com o valor zerado'
      }
    ]

    return validateData(validations)
  }

  const validateBudget = () => {
    const hospitalBudgetValidation = validateHospitalBudget()
    if (!hospitalBudgetValidation.isValid) return { isValid: false, message: hospitalBudgetValidation.errorMessage }

    const medicalTeamBudgetValidation = validateMedicalTeamBudget()
    if (!medicalTeamBudgetValidation.isValid) return { isValid: false, message: medicalTeamBudgetValidation.errorMessage }

    return { isValid: true, message: '' }
  }

  const handleHospitalBudgetChange = (hospitalBugetData) => {
    setHospitalBudget(hospitalBugetData)
    setHasChange(true)
  }

  const handleMedicalTeamBudgetChange = (medicalTeamBudgetData) => {
    setMedicalTeamBudget(medicalTeamBudgetData)
    setHasChange(true)
  }

  return (
    <div>
      <Dialog fullScreen open={props.open || false} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" className={classes.title}>
              <b>Solicitação de orçamento</b>
            </Typography>
            {!disableFormToEdition &&
              <>
                <div style={{ marginRight: 20 }}>
                  <Button disabled={!hasChange} onClick={handleSave}>
                    Salvar
              </Button>
                </div>
                <div>
                  <Button disabled={hasChange} onClick={handleButtonFinishBudget}>
                    Finalizar orçamento
              </Button>
                </div>
              </>
            }
          </Toolbar>
        </AppBar>
        <List>
          <ListItem>
            <Typography variant="h6">
              <b>Detalhes do procedimento</b>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="subtitle1">
              <b>{procedureName}</b> - {procedureCode}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="subtitle2">
              Preencha todos os campos de informação sobre o procedimento realizado<br />
              Você pode salvar o estado do orçamento para completar e enviar mais tarde.
            </Typography>
          </ListItem>
          <ListItem>
            <HorizontalList
              width="100%"
              listItems={listOptions}
              setSelectedList={setSelectedTab}
              selectedList={selectedTab}
            />
          </ListItem>
          <ListItem>
            {listOptions[selectedTab].text === 'Orçamento hospitalar' && (
              <HospitalBudget
                {...hospitalBudget}
                disableFormToEdition={disableFormToEdition}
                handleChange={handleHospitalBudgetChange}
              />
            )}
            {listOptions[selectedTab].text === 'Orçamento da equipe médica' && (
              <MedicalTeamBudget
                {...medicalTeamBudget}
                disableFormToEdition={disableFormToEdition}
                handleChange={handleMedicalTeamBudgetChange}
              />
            )}
          </ListItem>
        </List>
      </Dialog>
      {/* Modal de confirmação quando usuário fecha o form sem salvar */}
      <div>
        <Dialog open={openConfirmToClose}>
          <DialogTitle>Atenção!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vocẽ não salvou o estado deste orçamento.<br />
              Se você optar por fechar sem salvar, tudo que foi preenchido será perdido e o pedido de procedimento não será realizado.<br />
              Se optar por salvar, você pode continuar preenchendo o orçamento mais tarde para realizar o envio.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCloseConfirmToClose({ save: false })} backgroundColor="transparent" color="#F9B233" border="1px solid #F9B233">
              Fechar sem salvar
            </Button>
            <Button onClick={() => handleCloseConfirmToClose({ save: true })} color="white">
              Fechar e salvar
            </Button>
          </DialogActions>
        </Dialog>
        {/* Modal de confirmação quando usuário quer finalizar o orçamento */}
        <Dialog open={openConfirmFinishToClose}>
          <DialogTitle>Deseja realmente finalizar este orçamento?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>Atenção: </b>Após o envio não é mais possível editá-lo.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmFinishToClose(false)} backgroundColor="transparent" color="#F9B233" border="1px solid #F9B233">
              Cancelar
            </Button>
            <Button onClick={handleFinishBudget} color="white">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <SnackBar
        open={snack.open}
        errorMessage={snack.message}
        severity={snack.severity}
        durantion={snack.duration || 5000}
        onClose={() => setSnack({ ...snack, open: false })}
      />
    </div>
  );
}
