import React from 'react';
import { Route, Switch } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import Profile from './profile';
import Schedule from './schedule/optionsToRender';
import Calendar from './schedule/optionsToRender/schedule';
import Secretaries from './secretary';
import Consultations from './consultations';
import Exams from './exams';
import Historic from './historic';
import HealthOperator from './healthOperator';

import List from '../../common/sideList';

const useStyles = makeStyles({
    root: {
        display:'flex'
    },
    list: {
        height: 'calc(100vh - 70px)',
        width: '270px',
        borderRight: '1px solid #E7E7ED',
    },
})

const Administrator = (props) => {
    const pathPrefix = props.match.path;
    const path = props.location.pathname;
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            {path.slice(-7) !== "/agenda" ?
                <div className={classes.list}>
                    <List 
                        {...props}
                        links={[
                            '/',
                            '/minha-agenda',
                            '/secretarias', 
                            '/consultas',
                            '/exames', 
                            '/historico',
                            '/convenios',
                        ]}/>
                </div>
                :
                null
            }
            <Switch>
                <Route path={`${pathPrefix}/minha-agenda`} exact component={Schedule}/>
                <Route path={`${pathPrefix}/secretarias`} exact component={Secretaries}/>
                <Route path={`${pathPrefix}/historico`} exact component={Historic}/>
                <Route path={`${pathPrefix}/convenios`} exact component={HealthOperator}/>
                <Route path={`${pathPrefix}/consultas`} exact component={Consultations}/>
                <Route path={`${pathPrefix}/exames`} exact component={Exams}/>
                <Route path={`${pathPrefix}/consultas/agenda`} exact component={Calendar}/>
                <Route path={`${pathPrefix}`} exact component={Profile}/>
            </Switch>
        </div>
    )

}

export default Administrator;
