import React from "react";
import { useDispatch } from 'react-redux';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DialogContentText } from "@material-ui/core";
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../../../../common/button';

import * as scheduleActions from '../../../../common/store/actions/schedule.action';

// Material UI Styling

const useStyles = makeStyles( {
  root: {
    width: '34%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: "#EDF9F0",
    borderBottom: '1px solid #E7E7ED',
  },
  title: {
    color:"#287D3C",
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '10px'
    }
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  },  
  buttonBg: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F7F8F9',
    display: 'flex',
    justifyContent: "flex-end",
    padding: '22px',
    marginTop: '28px',
    boxSizing: 'border-box',
  }
})

const ModalProposeSuccess = (props) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  
  return (
    <>      
        <div className={classes.header}>
          <DialogTitle>
            <div className={classes.title}>
              <CheckCircleIcon/>
              <span>Agenda Criada</span>
            </div>
          </DialogTitle>
          <IconButton aria-label="close" onClick={() => props.handleClose()}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
            <div className="modal-body">
              <DialogContentText>
                Sua agenda foi criada com sucesso. Clique em "OK" para acessá-la agora. 
              </DialogContentText>
            </div>
        </DialogContent>
        <div className={classes.buttonBg}>
            <Button
              onClick={() => {
                dispatch(scheduleActions.cleanSchedule());
                dispatch(scheduleActions.openCreateScheduleModal(false));
                dispatch(scheduleActions.updateCalendarFront(true));
              }}
              width="120px"
              border="1px solid #CDCED9"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 20px 0 0"
            >
              OK
            </Button>
        </div>
    </>
  );
};

export default ModalProposeSuccess;