import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { makeStyles, Avatar } from '@material-ui/core'

import InputFile from "../../common/fileInput";
import Topic from "../../common/topic";
import Button from "../../common/button";
import inputFileValidator, { validationFileType } from '../../common/validators/fileInput'
import ComplexList from '../../common/itemList/listWithParent';
import * as scheduleExamsService from '../../services/scheduledExams'
import * as scheduledExamActions from '../../common/store/actions/scheduledExam.action'
import * as asyncActions from '../../common/store/actions/async.action';
import Loading from '../../common/loading';
import ConfirmFinishExamAttendance from './modals/confirmFinishExamAttendance'
import { handlePatientName } from '../../common/handlePatientName';

const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: "#F7F8F9",
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  root: {
    width: '95%',
    padding: '3vh 0',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 600
    }
  },
  header:{
    display: 'flex',
    marginLeft: '30px',
    marginTop: "30px",
    justifyContent: 'space-between'
  },
  subReader:{
    display: 'flex',
    marginLeft: '30px',
    marginTop: "50px",
    justifyContent: 'space-between',
    
  },
  requestData: {
    display: 'flex',
    marginTop: '15px',
   
    "& > span":{ marginLeft: '15px', marginTop: '10px'},
    "& > .div":{ marginLeft: '10px'},
   
   justifyContent: 'space-between',
  },
  fileList:{
    display: 'flex',
    marginTop: "20px",
    
    maxWidth: '45%'
  },
  topic: {
    marginBottom: "30px",
    marginRight:"30px",
    fontSize: '16px',
    position: 'absolute',
    "& > .title": {
        fontWeight: '600',
    },
    "& > p": {
        lineHeight: '20px',
        color: "#6B6C7E",
        marginTop: '5px'
    }
},
  guide: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5vh 0'
  }
}));



const ExamsConsultation = () => {
  const classes = useStyles()
    
  const ScheduledExamReducer = useSelector((state) => state.ScheduledExamReducer.data);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(ScheduledExamReducer.examAppointment[0].status)
  const [loadingButton, setLoadingButton] = useState(false);
  const [examAppointments, setExamAppointments] = useState([])  
  const [patient, setPatient] = useState([])  
  const [examRequests, setExamRequests] = useState([])  


   useEffect( () => {
  scheduleExamsService.getExamAppointmentsAttendanceInfo(ScheduledExamReducer.id).then((appointmentRequest) =>{
    
    
    const { examAppointment } = appointmentRequest.rows[0]

    setPatient(appointmentRequest.rows[0].patient)
    setExamAppointments(examAppointment)
    const examRequestsReduced = examAppointment.reduce((acc, exam) => {
    
      if(acc.findIndex(x => x.provider.id === exam.examRequest.provider.id) < 0){
        acc = [...acc, exam.examRequest]
      }
   
      return acc
  }, [])

  setExamRequests(examRequestsReduced)
    
  })
    // eslint-disable-next-line
  }, []) 


  const handleSave = async  (data) => {
    return scheduleExamsService.createExamAppointmentFile(data);
  };
  const handleInactivateExamAppointmentFile =async ({ parentObject,index, value}) => {
    setLoading(true)
    const examAppointment = parentObject
    const examAppointmentFile = value
    

    const inactivate = await scheduleExamsService.handleInactivateExamAppointmentFile(examAppointmentFile.id);
   
    if(inactivate){    
    const examAppointmetsToUpdate = deleteExamAppointmentFileFromExamAppointments(examAppointment, examAppointmentFile)
    setExamAppointments(examAppointmetsToUpdate)
    }
    setLoading(false)
    
  }
  const deleteExamAppointmentFileFromExamAppointments = (examAppointment, examAppointmentFile) => {
    let examAppointmetsToUpdate = examAppointments
   
    const examAppointmentsIndex= examAppointmetsToUpdate.findIndex(x => x.id === examAppointment.id)
    const examAppointmentFiltered = examAppointmetsToUpdate[examAppointmentsIndex].examAppointmentFiles.filter(x => x.id !== examAppointmentFile.id)
    
    examAppointmetsToUpdate[examAppointmentsIndex].examAppointmentFiles = examAppointmentFiltered

   return examAppointmetsToUpdate
  }

  const handleSendResults = async() => {
    setLoadingButton(true)
    const res= await scheduleExamsService.changeExamAppointmentStatusToResultsSended(ScheduledExamReducer.id);

    if(res && !res.error){
      setStatus("RESULTS_SENDED")
    }else{
      
      dispatch(asyncActions.requestFailure(`Ocorreu um erro ao enviar resultados, por favor, tente novamente`))
    }
    setLoadingButton(false)
  }
  const handleFinishExamAppointment = async() => {
    if(checkIfAllAppointmentsHavefiles())
    dispatch(scheduledExamActions.openFinishModal(true));
    else
    dispatch(asyncActions.requestFailure("Para finalizar o atendimento, todos os exames devem receber um resultado."))
  }
  const checkIfAllAppointmentsHavefiles = () => {
        for(const examAppointment of examAppointments){
          
            if(!!!examAppointment.examAppointmentFiles || examAppointment.examAppointmentFiles.length === 0){
              return false
            }
        }

        return true
  }
  const handleChangeInputFile =async  (value, typesAccepted, examAppointment) => {
    if (!value) return 

    setLoading(true)
    const index = examAppointments.findIndex(x => x.id === examAppointment.id)  
    
    for(const item of value){
      
     const fileAccepted = validateFileIsAccepted(item, typesAccepted)
     
    if (fileAccepted) { 
     
      const examAppointmentFile = createExamAppointmentFileFormData(item, examAppointment.id) 
      let res = await handleSave(examAppointmentFile)
      handleCreateExamAppointmentFileApiResponse(res, index, item)
 
    }
     
  }
    
    setLoading(false)

  }
  const validateFileIsAccepted = (file, typesAccepted, maxSize =20) => {
    const typeIsAccpted =  validationFileType(file.type,typesAccepted)
    const maxSizeAccept =  inputFileValidator(file, maxSize)
    
    if(!typeIsAccpted){
      
      dispatch(asyncActions.requestFailure(`O tipo do arquivo selecionado não é aceito para esse campo! ${file.name}`))
      return false
    }
    if(maxSizeAccept.error){
      
      dispatch(asyncActions.requestFailure( `${maxSizeAccept.typeError}, ${file.name}`))
      return false
    }
    if(typeIsAccpted && !maxSizeAccept.error){
        return true
    }
  }
  const createExamAppointmentFileFormData = (file, examAppointmentId) => {
    const data = new FormData();

    data.append("examAppointmentId", examAppointmentId);  
    data.append("file", file);

    return data
  }

  const AddNewAppointmentFileOnExamAppointments = (res, index ) => {
    let examAppointmetsToUpdate = examAppointments
    let fileList = []
   
    if(!!examAppointmetsToUpdate[index].examAppointmentFiles){
      examAppointmetsToUpdate[index].examAppointmentFiles = [...examAppointmetsToUpdate[index].examAppointmentFiles, res]
     
    }else{
      fileList = [...fileList, res]
      examAppointmetsToUpdate[index].examAppointmentFiles = fileList
      
    }
    return examAppointmetsToUpdate
  }

  const handleCreateExamAppointmentFileApiResponse = (res, index, item) => {
    if(res)
    {
     const examAppointmetsToUpdate = AddNewAppointmentFileOnExamAppointments(res, index)
     setExamAppointments(examAppointmetsToUpdate) 
  }
  else{
    
    setLoading(false)
    
    dispatch(asyncActions.requestFailure(`Houve um erro ao incluir um dos exames ${item.name}`))
  }
  }

  const typesAcceptedFile = ['image/png', 'image/jpg', 'image/jpeg','application/pdf',
   'application/msword',
   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
   'text/plain']

  return (
    <>
    
    <div className={classes.screen}>
        <div className={classes.root}>
          <div className={classes.header}>
          <h1>{handlePatientName(patient)}</h1>
          
          {!loadingButton &&
          status &&
          (status === "TOKEN_VALIDATED" || status === "PENDING_RESULTS" )&& 
           <Button
                   
                        width="200px"
                        heigth="32px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                        margin="0 20px 0 0"
                        fontSize="14px"
                        fontWeight="400"
                        padding="6px 12px"
                       onClick={handleSendResults}
                    >
                        Enviar Resultados
                    </Button> 
            } 
            {        
          status &&
          status === "RESULTS_SENDED" && 
              <Button
                   
                        width="200px"
                        heigth="32px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                        margin="0 20px 0 0"
                        fontSize="14px"
                        fontWeight="400"
                        padding="6px 12px"
                        onClick={handleFinishExamAppointment}
                    >
                        Finalizar Atendimento
                    </Button>
              }
              {loadingButton &&
              <div> <Loading width='30px' /> </div>}
              
        
        </div>
          <div className={classes.subReader}>
          <div>
            <Topic
              classesNameCol="topic-datas firt-topic"
              title="Suba o arquivo dos resultados do exame"
              subtitle={"Para receber o valor referente ao exame, é necessário subir o resultado do mesmo para a "
              +"\n\n\n\n plataforma do Vida Connect."}
            />
          </div>
            <div style={{right: '0', marginRight: "150px"}} className={classes.topic}>
            
            <span style={{marginBottom: "100px"}} className="title">Dados da solicitação</span>

            { !!examRequests  && examRequests.map(examRequest =>     
                 (
                   <div  key={examRequest.id}>
                   <div className={classes.requestData}>
                  <Avatar 
                  style={{marginTop: "0px", marginRight: "10px"}} 
                  src={examRequest.provider.doctor.photo || null}
                  />      
                           <Topic 
              marginTop = "0"
              classesNameCol="topic-datas firt-topic"
              title={examRequest.provider.user.name }
              subtitle={ `CRM: ${examRequest.provider.doctor.uf}-${examRequest.provider.doctor.crm}`}
            />
           
                    
                  </div>
           
                  </div>
                    )
            )
          }   
            </div>
          </div>   
          <form style={{marginTop: "100px"}} className="form-layout">
        <div className="form-content">

        {loading && <Loading width='100px' />}
         { !!examAppointments  && examAppointments.map(examAppointment => {
            
        return (
          
          <div key ={examAppointment.id}>
            
            {!loading &&
          <InputFile 
            key ={examAppointment.id}
            id={examAppointment.id}
            width="32%"
            label={examAppointment?.providerHasServicesProvided?.serviceProvided?.description}
            subtitle="Formatos aceitos: png, jpg, jpeg, pdf, doc, docx, txt Tamanho máximo: 20MB"
            accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .txt"
            onChange={(e) =>
              handleChangeInputFile(e.target.files, typesAcceptedFile, examAppointment)
            }
            value={'Selecione os arquivos para o exame acima'}
            //error={fileError.typeError}
            dataTest="input-pf-photo"
            multiple={true}
          />
          }
          <div className={classes.fileList}>
         
           {!loading &&
            !!examAppointment.examAppointmentFiles && 
            examAppointment.examAppointmentFiles.length > 0 && <ComplexList 
                           parentObject = {examAppointment}
                           fullList={"examAppointmentFiles"} 
                           firstHeader={`Resultados ${examAppointment?.providerHasServicesProvided?.serviceProvided?.description}`}
                           firstParam={'originalName'}
                           firstColumnWidth="100%"
                           deleteItem={handleInactivateExamAppointmentFile}
                           />} 
         </div>
         </div>
         )})
        } 
          
        </div>
      </form>

        <div>
          
          </div>

        
        <ConfirmFinishExamAttendance
        appointmentRequestId={ScheduledExamReducer.id}
        />
        </div>
    </div>
    </>
  )
}

export default ExamsConsultation