// 'Itaú', 'Bradesco', 'Caixa Econômica', 'Banco do Brasil', 'Santander', 'Banrisul', 'Sicredi', 'Sicoob', 'Inter', 'BRB', 'Via Credi', 'Neon', 'Votorantim', 'Nubank', 'Pagseguro', 'Banco Original', 'Safra', 'Modal', 'Banestes','Unicred','Gerencianet Pagamentos do Brasil'



export const banks = [
    {code: '104', id:"Caixa Econômica", name:"Caixa Econômica", mask: { agency: '9999', account: 'SSS9999999-S' }},
    {code: '001', id:"Banco do Brasil", name:"Banco do Brasil", mask: { agency: '9999-S', account: '99999999-S' }},
    {code: '341', id:"Itaú", name:"Itaú", mask: { agency: '9999', account: '99999-S' }},
    {code: '237', id:"Bradesco", name:"Bradesco", mask: { agency: '9999-S', account: '9999999-S' }},
    {code: '033', id:"Santander", name:"Santander", mask: { agency: '9999', account: '99999999-S' }},
    {code: '041', id:"Banrisul", name:"Banrisul", mask: { agency: '9999', account: '999999999-S' }},
    {code: '748', id:"Sicredi", name:"Sicredi", mask: { agency: '9999', account: '999999S' }},
    {code: '756', id:"Sicoob", name:"Sicoob (Bancoob)", mask: { agency: '9999', account: '999999999-S' }},
    {code: '077', id:"Inter", name:"Inter", mask: { agency: '9999', account: '999999999-S' }},
    {code: '070', id:"BRB", name:"BRB", mask: { agency: '9999', account: '999999999-S' }},
    {code: '085', id:"Via Credi", name:"Via Credi", mask: { agency: '9999', account: '99999999999-S' }},
    {code: '655', id:"Neon", name:"Neon", mask: { agency: '9999', account: '9999999-S' }},
    {code: '655', id:"Votorantim", name:"Votorantim", mask: { agency: '9999', account: '9999999-S' }},
    {code: '260', id:"Nubank", name:"Nubank", mask: { agency: '9999', account: '9999999999-S' }},
    {code: '290', id:"Pagseguro", name:"Pagseguro", mask: { agency: '9999', account: '99999999-S' }},
    {code: '212', id:"Banco Original", name:"Banco Original", mask: { agency: '9999', account: '9999999-S' }},
    {code: '422', id:"Safra", name:"Safra", mask: { agency: '9999', account: '99999999-S' }},
    {code: '746', id:"Modal", name:"Modal", mask: { agency: '9999', account: '999999999-S' }},
    {code: '021', id:"Banestes", name:"Banestes", mask: { agency: '9999', account: '99999999-S' }},
    {code: '136', id:"Unicred", name:"Unicred", mask: { agency: '9999', account: '99999999-S' }},
    {code: '364', id:"Gerencianet Pagamentos do Brasil", name:"Gerencianet Pagamentos do Brasil", mask: { agency: '9999', account: '99999999-S' }},
]



// export const banks = [
//     {id:"104", name:"104-CAIXA ECONÔMICA FEDERAL"},
//     {id:"001", name:"001-BANCO DO BRASIL"},
//     {id:"341", name:"341-ITAÚ"},
//     {id:"237", name:"237-BRADESCO"},
//     {id:"033", name:"033-SANTANDER"},
//     {id:"260", name:"260-NUBANK"},
//     {id:"041", name:"041-BANRISUL"},
//     {id:"318", name:"318-BMG S.A."},
//     {id:"208", name:"208-BTG PACTUAL S.A."},
//     {id:"335", name:"335-BANCO DIGIO"},
//     {id:"323", name:"323-MERCADO PAGO"},
//     {id:"290", name:"290-PAGSEGURO"},
//     {id:"077", name:"077-BANCO INTER"},
//     {id:"212", name:"212-ORIGINAL S.A."},
//     {id:"707", name:"707-DAYCOVAL"},
//     {id:"654", name:"654-A.J. RENNER S.A."},
//     {id:"246", name:"246-ABC do BRASIL S.A."},
//     {id:"213", name:"213-ARBI S.A."},
//     {id:"019", name:"019-AZTECA DO BRASIL S.A."},
//     {id:"025", name:"025-BANCO ALFA"},
//     {id:"241", name:"241-Banco Classico S.A"},
//     {id:"083", name:"083-BANCO DA CHINA BRASIL S.A."},
//     {id:"300", name:"300-BANCO DE LA NACION ARGENTINA"},
//     {id:"495", name:"495-BANCO DE LA PROVINCIA DE BUENOS AIRES"},
//     {id:"494", name:"494-BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY"},
//     {id:"037", name:"037-BANCO DO ESTADO DO PARÁ S.A"},
//     {id:"456", name:"456-BANCO TOKYO MITSUBISH UFJ BRASIL S.A"},
//     {id:"370", name:"370-BANCO WESTLB DO BRASIL"},
//     {id:"756", name:"756-BANCOOB"},
//     {id:"047", name:"047-BANESE"},
//     {id:"033", name:"033-BANESPA"},
//     {id:"021", name:"021-BANESTES"},
//     {id:"719", name:"719-BANIF-BANCO INTERNACIONAL DO FUNCHAL (BRASIL) S.A"},
//     {id:"755", name:"755-BANK OF AMERICA MERRILL LYNCH BANCO MULTIPLO S.A."},
//     {id:"740", name:"740-BARCLAYS S.A."},
//     {id:"003", name:"003-BASA"},
//     {id:"107", name:"107-BBM S.A"},
//     {id:"081", name:"081-BBN BANCO BRASILEIRO DE NEGOCIOS S.A"},
//     {id:"250", name:"250-BCV - BANCO DE CREDITO E VAREJO S.A"},
//     {id:"036", name:"036-BEM"},
//     {id:"122", name:"122-BERJ S.A"},
//     {id:"078", name:"078-BES INVESTIMENTO DO BRASIL SA - BANCO DE INVESTIM."},
//     {id:"739", name:"739-BGN S.A."},
//     {id:"320", name:"320-BIC BANCO"},
//     {id:"096", name:"096-BM&F DE SERV. DE LIQUIDACAO E CUSTODIA S.A"},
//     {id:"394", name:"394-BMC S.A."},
//     {id:"004", name:"004-BNB"},
//     {id:"752", name:"752-BNP PARIBAS BRASIL S.A"},
//     {id:"017", name:"017-BNY MELLON S.A."},
//     {id:"248", name:"248-BOA VISTA INTERATLANTICO S.A"},
//     {id:"218", name:"218-BONSUCESSO S.A."},
//     {id:"069", name:"069-PN BRASIL BANCO MULTIPLO S.A"},
//     {id:"065", name:"065-BRACCE S.A."},
//     {id:"225", name:"225-BRASCAN S.A."},
//     {id:"125", name:"125-BRASIL PLURAL S.A. BANCO MULTIPLO"},
//     {id:"070", name:"070-BRB"},
//     {id:"092", name:"092-BRICKELL S A CREDITO, FINANCIAMENTO E INVESTIMENTO"},
//     {id:"263", name:"263-CACIQUE S.A."},
//     {id:"412", name:"412-CAPITAL S.A."},
//     {id:"040", name:"040-CARGILL S.A"},
//     {id:"112", name:"112-CC UNICRED BRASIL CENTRAL"},
//     {id:"084", name:"084-CC UNIPRIME NORTE DO PARANA"},
//     {id:"114", name:"114-CECOOPES-CENTRAL DAS COOP DE ECON E CRED MUTUO DO"},
//     {id:"085", name:"085-CECREDI"},
//     {id:"266", name:"266-CEDULA S.A."},
//     {id:"233", name:"233-CIFRA S.A."},
//     {id:"745", name:"745-CITIBANK"},
//     {id:"090", name:"090-COOPERATIVA CENTRAL DE CREDITO DO ESTADO DE SP"},
//     {id:"097", name:"097-COOPERATIVA CENTRAL DE CREDITO NOROESTE BRASILEIRO"},
//     {id:"089", name:"089-COOPERATIVA DE CREDITO RURAL DA REGIAO DA MOGIANA"},
//     {id:"075", name:"075-CR2 S.A"},
//     {id:"098", name:"098-CREDIALIANCA COOPERATIVA DE CREDITO RURAL"},
//     {id:"222", name:"222-CREDIT AGRICOLE BRASIL S.A."},
//     {id:"505", name:"505-CREDIT SUISSE (BRASIL) S.A."},
//     {id:"487", name:"487-DEUTSCHE BANK S. A. - BANCO ALEMAO"},
//     {id:"214", name:"214-DIBENS S.A."},
//     {id:"265", name:"265-FATOR S.A."},
//     {id:"224", name:"224-FIBRA"},
//     {id:"626", name:"626-FICSA S.A."},
//     {id:"121", name:"121-GERADOR S.A."},
//     {id:"612", name:"612-GUANABARA S.A."},
//     {id:"062", name:"062-HIPERCARD BANCO MULTIPLO S.A"},
//     {id:"399", name:"399-HSBC"},
//     {id:"063", name:"063-IBI"},
//     {id:"604", name:"604-INDUSTRIAL DO BRASIL S. A."},
//     {id:"653", name:"653-INDUSVAL S.A."},
//     {id:"492", name:"492-ING BANK N.V."},
//     {id:"630", name:"630-INTERCAP S.A."},
//     {id:"249", name:"249-Investcred Unibanco"},
//     {id:"074", name:"074-J. SAFRA S.A."},
//     {id:"376", name:"376-J.P. MORGAN S.A."},
//     {id:"217", name:"217-JOHN DEERE S.A."},
//     {id:"488", name:"488-JPMORGAN CHASE BANK"},
//     {id:"076", name:"076-KDB DO BRASIL S.A"},
//     {id:"757", name:"757-KEB DO BRASIL S.A."},
//     {id:"600", name:"600-Luso Brasileiro"},
//     {id:"243", name:"243-MAXIMA S.A."},
//     {id:"389", name:"389-MERCANTIL DO BRASIL"},
//     {id:"746", name:"746-MODAL S.A."},
//     {id:"066", name:"066-MORGAN STANLEY DEAN WITTER S.A"},
//     {id:"014", name:"014-NATIXIS BRASIL S.A. - BANCO MòLTIPLO"},
//     {id:"753", name:"753-NBC BANK BRASIL S.A.- BANCO MULTIPLO"},
//     {id:"045", name:"045-OPPORTUNITY S.A."},
//     {id:"079", name:"079-ORIGINAL DO AGRONEGOCIO S.A."},
//     {id:"623", name:"623-PANAMERICANO"},
//     {id:"254", name:"254-PARANA BANCO S.A."},
//     {id:"611", name:"611-PAULISTA"},
//     {id:"613", name:"613-PECUNIA S.A."},
//     {id:"094", name:"094-PETRA S.A."},
//     {id:"643", name:"643-PINE S.A."},
//     {id:"735", name:"735-POTTENCIAL S.A."},
//     {id:"747", name:"747-RABOBANK INTERNATIONAL BRASIL S.A."},
//     {id:"088", name:"088-RANDON S.A."},
//     {id:"633", name:"633-RENDIMENTO S.A."},
//     {id:"741", name:"741-RIBEIRÃO PRETO"},
//     {id:"120", name:"120-RODOBENS S.A"},
//     {id:"453", name:"453-RURAL"},
//     {id:"072", name:"072-RURAL MAIS S.A"},
//     {id:"422", name:"422-SAFRA"},
//     {id:"751", name:"751-SCOTIABANK BRASIL S.A BANCO MULTIPLO"},
//     {id:"743", name:"743-SEMEAR S.A."},
//     {id:"748", name:"748-SICREDI"},
//     {id:"749", name:"749-SIMPLES S.A."},
//     {id:"366", name:"366-SOCIETE GENERALE BRASIL S.A"},
//     {id:"637", name:"637-SOFISA S.A."},
//     {id:"464", name:"464-SUMITOMO MITSUI BRASILEIRO S.A."},
//     {id:"082", name:"082-TOPAZIO S.A."},
//     {id:"634", name:"634-Triangulo"},
//     {id:"230", name:"230-UNICARD BANCO MULTIPLO S.A"},
//     {id:"091", name:"091-UNICRED CENTRAL RS - CENTRAL DE COOP ECON CRED MUT"},
//     {id:"087", name:"087-UNICRED CENTRAL SANTA CATARINA"},
//     {id:"099", name:"099-UNIPRIME CENTRAL - CENTRAL INT DE COOP DE CRED LTD"},
//     {id:"655", name:"655-VOTORANTIM"},
//     {id:"610", name:"610-VR S.A."},
//     {id:"119", name:"119-WESTERN UNION DO BRASIL S.A."},
//     {id:"124", name:"124-WOORI BANK DO BRASIL S.A"},
// ]
