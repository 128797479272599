import graphqlApi from '../../services/api';

export async function getRoom(medicalAppointmentId) {
    const res = await graphqlApi({
        data: {
            query:
            `
                mutation($medicalAppointmentId: ID!) {
                    initMedicalAppointmentOnline( medicalAppointmentId: $medicalAppointmentId) {
                        roomName 
                        identity
                    }
                }
            `,
            variables: {
                medicalAppointmentId
            }
        }
    });
    return res.data.data.initMedicalAppointmentOnline;
}

export async function getTwilioToken(roomName, identity, medicalAppointmentId) {
    const res = await graphqlApi({
        data: {
            query:
            `
                mutation($input: GenerateTwilioAccessTokenInput! ) {
                    generateAccessToken(input: $input) {
                        token
                    }
                }
            `,
            variables: {
                input: {
                    roomName,
                    identity,
                    medicalAppointmentId
                }
            }
        }
    });
    return res.data.data.generateAccessToken;
}
