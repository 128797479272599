import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" icon={null} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  filled: {
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : null,
  }
}));

export default function CustomizedSnackbars(props) {
    const classes = useStyles(props);

  return (
    <div className={props.root || classes.root}>
      <Snackbar open={props.open} autoHideDuration={props.durantion || 5000} onClose={() => props.onClose(false)}>
        <Alert
          classes={{root: classes.filled}}
          severity={props.severity ? props.severity : "error"} 
          onClose={() => props.onClose(false)}
        >
          { props.errorMessage ? 
            props.errorMessage 
            : 
            "Ocorreu um erro na sua solicitação. Por favor, tente novamente!"
          }
        </Alert>
      </Snackbar>
    </div>
  );
}
