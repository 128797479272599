const INITIAL_STATE = {
  waitingData: true,
  error: false,
  initialRegister: [
    {
      typeAccount: "",
      cpf: "",
      name: "",
      email: "",
      telephone: "",
      password: "",
    },
  ],

  finalRegister: [
    {
      pf: {
        personalDatas: {
          cpf: "",
          name: "",
          email: "",
          telephone: "",
          doctor: "",
          crm: "",
          uf: {name:""},
          specialty1: {name:""},
          rqe1: "",
          specialty1Titles: {name:""},
          specialty2: {name:""},
          rqe2: "",
          specialty2Titles: {name:""},
          generalPractitioner: {    
            id: '',
            name: '',
            group: null,
            requireRqe: false,
            selected: false
          },
          profession: "",
          professionalCouncil: "",
          specialityId: "",
          specialityName: "",
          professionalDocumentNumber: "",
          supportType: "",
          professionalDocument: {name:""},
          proofOfResidence: {name:""},
          certificate: {name:""},
          bank: {name:""},
          agency: "",
          account: "",
          numberPlots: 1,
          minAmount: 0.01,
          healthInsurances: [{ healthOperators: {name: "", plans: [{name: ""}]}, plan: {name: ""} }],
          consentForm: {name:""},
        },
        curriculum: {
          photo: {name:""},
          about: "",
          experience: "",
          medicalConditions: "",
          formation: "",
          linkCurriculumLattes: "",
        },
      },
    },
  ],
  dataFromLogin: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "INSERT_TYPE_ACCOUNT_INITIAL_REGISTER":
      return {
        ...state,
        initialRegister: [
          ...state.initialRegister,
          (state.initialRegister[0].typeAccount = action.payload),
        ],
      };
    case "GET_PERSONAL_INITIAL_DATA":
      return {
        ...state,
        waitingData: true
      }
    case "PERSONAL_DATA_SEARCH_IS_DONE":
      return {
        ...state,
        waitingData: false
      }
    case "UPDATE_INITIAL_REGISTER":
      return { 
          ...state, 
          initialRegister: [
            {
              ...state.initialRegister[0],
              ...action.payload
            },
          ]
        };
    case "INSERT_PASSWORD_INITIAL_REGISTER":
      return {
        ...state,
        initialRegister: [
          ...state.initialRegister,
          (state.initialRegister[0].password = action.payload),
        ],
      };

    case "UPDATE_PF_REGISTER_DATA":
      return {
        ...state,
        finalRegister: [
          { ...state.finalRegister[0],
            pf: {
              ...state.finalRegister[0].pf,
              personalDatas: {
                ...state.finalRegister[0].pf.personalDatas,
                ...action.payload
              }
            }
          }
        ],
        dataFromLogin: action.payload,
        waitingData: false,
        error: false,
      };

    case "UPDATE_PF_REGISTER_CURRICULUM":
      return {
        ...state,
        finalRegister: [
          { ...state.finalRegister[0],
            pf: {
              ...state.finalRegister[0].pf,
              curriculum: {
                ...state.finalRegister[0].pf.curriculum,
                ...action.payload
              }
            }
          }
        ]
      };
      case "CLEAR_PERSONAL_DATA":
        return {
          ...state,
          ...INITIAL_STATE,
        }

    default:
      return state;
  }
}
