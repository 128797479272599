import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'

import { Typography } from '@material-ui/core'
import { format } from 'date-fns'
import DateTime from '../../../common/datetimepicker'
import Button from '../../../common/button'
import procedureRequestStatus from '../../../common/enums/procedureStatus'

import * as procedureService from '../../../services/procedures'

const useStyles = makeStyles(({
  marginTop: {
    marginTop: 30
  }
}))

export default (props) => {
  const classes = useStyles()

  const { budget, setBudget, setSnack } = props

  const [proposeDate1, setProposeDate1] = useState(budget.proposedDate1 || null)
  const [proposeDate2, setProposeDate2] = useState(budget.proposedDate2 || null)
  const [proposeDate3, setProposeDate3] = useState(budget.proposedDate3 || null)

  const proposeProcedureDates = async () => {
    if (!proposeDate1) {
      setSnack({
        open: true,
        message: 'Pelo menos a primeira opção de data deve ser indicada para o procedimento',
        severity: 'error'
      })
    } else {
      const proposal = await procedureService.proposeProcedureDates(budget.id, proposeDate1, proposeDate2, proposeDate3)
      if (!proposal.error && proposal) {
        setSnack({
          open: true,
          message: 'Data(s) proposta(s) com sucesso.',
          severity: 'success'
        })
        setBudget({ ...budget, status: procedureRequestStatus.CONFIRM_SCHEDULE })
      } else {
        setSnack({
          open: true,
          message: proposal.message,
          severity: 'error'
        })
      }
    }

  }

  return (
    <>
      {
        budget.status === procedureRequestStatus.CONFIRM_SCHEDULE || budget.status === procedureRequestStatus.AWAITING_DATE ?
          <div>
            <Typography variant="subtitle1">
              <b>Solicitar Agendamento</b>
            </Typography>
            <Typography variant="subtitle1">
              Defina uma data e hora para o procedimento
            </Typography>

            <div className={classes.marginTop}>
              <Typography>Opção 1</Typography>
              <DateTime
                value={proposeDate1}
                setValue={setProposeDate1}
                disabled={budget.status !== procedureRequestStatus.AWAITING_DATE}
              />
            </div>

            <div className={classes.marginTop}>
              <Typography>Opção 2</Typography>
              <DateTime
                value={proposeDate2}
                setValue={setProposeDate2}
                disabled={budget.status !== procedureRequestStatus.AWAITING_DATE}
              />
            </div>

            <div className={classes.marginTop}>
              <Typography>Opção 3</Typography>
              <DateTime
                value={proposeDate3}
                setValue={setProposeDate3}
                disabled={budget.status !== procedureRequestStatus.AWAITING_DATE}
              />
            </div>

            <div className={classes.marginTop}>
              <Button
                border="1px solid #CDCED9"
                backgroundColor="#FFF"
                color="#6B6C7E"
                onClick={proposeProcedureDates}
                disabled={budget.status !== procedureRequestStatus.AWAITING_DATE}
              >
                Enviar Solicitação de agendamento
              </Button>
            </div>
          </div>
          :
          <div>
            <Typography variant="subtitle1">
              Data escolhida: {format(new Date(budget.dateTime), 'dd/MM/yyyy HH:mm')}
            </Typography>
          </div>
      }
    </>
  )
}
