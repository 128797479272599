import React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Topic from '../../../../common/topic';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import { format } from 'date-fns'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import ReplayIcon from '@material-ui/icons/Replay';
import Chip from '@material-ui/core/Chip'
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import graphqlApi from './../../../../services/api'
import HistoricDetailsDialog from '../modals/examHistoricModal'
import Pagination from '../../../../common/pagination';
import { handlePatientName } from '../../../../common/handlePatientName';
const useStyles = makeStyles({
    table: {
      marginBottom: '30px',
      "& tbody": {
        backgroundColor: '#FFF'
      }
    },

  })
  
  const themeCircularProgress = createMuiTheme({
    palette: {
      primary: { main: "#DF9000" },
    },
  });

export default function ExamsHistory(props){
    const classes = useStyles();

//const {page, itemsPerPage} = props

    const [loading, setLoading] = useState(false);
    const [getHistoricError, setGetHistoricError] = useState('')
    const [rows, setRows] = useState([]);

    const [open, setOpen] = useState(false);
    const [modalContent, setModalConten] = useState({
      dateTimeBeginAppointmentUtc: new Date(),
      examAppointment:[]
    })
  
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const firstItem = (page*itemsPerPage)-(itemsPerPage);
    const lastItem = (firstItem + itemsPerPage);
    const employeeId = window.localStorage.getItem('employeeId')

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getHistoric = useCallback((_page, _limit,) => {
      setLoading(true)
      return graphqlApi.post('/', {
        query: `
          query($page: Float, $limit: Float) {
            listExamAppointmentByDoctor(input:{ page: $page, limit: $limit }) {
              rows {
                id
                patientId
                providerHasServiceProvidedId
                allServicesProvidedDescription
                examAppointment{
                  id
                  dateTimeBeginAppointmentUtc
                  status
                  patient{
                    socialName
                    user {
                      name
                    }
                  }

                  examAppointmentFiles{
                    originalName
                    file
                  }

                  providerHasServicesProvided{
                    id
  
                    serviceProvided{
                      description
                    }
                  }
                }
                
                beginAppointment
                endAppointment
                dateTimeBeginAppointmentUtc
                dateTimeEndAppointmentUtc
                date
                price
                tax
                taxProvider
                
                reason
                
                
                doctorHasAttended
                patientHasAttended
                status
                
                examAppointmentFiles{
                  originalName
                  file
                }
                providerHasServicesProvided{
                  id

                  serviceProvided{
                    description
                  }
                }
                patient {
                  id
                  gender
                  birth
                  socialName
                  user {
                    id
                    name
                    email
                    cpf
                    phone
                  } 
                }
              }
              count
            } 
          }
        `,
        variables: {
          page: _page,
          limit: _limit
        }
      })
        .then(res => {
          const result = res.data
          if (result.errors) {
            setGetHistoricError('Houve um erro ao buscar o histórico de exames')
            setLoading(false)
            return
          }
          console.log('EXAMES', result.data.listExamAppointmentByDoctor)
          setCount(result.data.listExamAppointmentByDoctor.count)
          setRows(result.data.listExamAppointmentByDoctor.rows)
          setLoading(false)
        })
        .catch(err => {
          setGetHistoricError('Houve um erro ao buscar o histórico de exames')
          setLoading(false)
          
        })
    })
  
    useEffect(() => {
      getHistoric(page, itemsPerPage, employeeId)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, itemsPerPage, employeeId])


    const handleClickLine = (row) => {
        setModalConten(row)
        setOpen(true);
      }

    const findingHistoric = () => {
        return (
          <div style={{ marginTop: 30 }}>
            <ThemeProvider theme={themeCircularProgress}>
              <CircularProgress color={'primary'} size={50} />
            </ThemeProvider>
          </div>
        )
      }

      const handleStatus = (status) => {
        
        const apiStatus = {
          'UNATTENDED': 'Exame não realizado',
          'FINISHED': 'Exame realizado',
          'CANCELED': 'Exame cancelado',
        }
        if (!apiStatus[status]) status = 'UNATTENDED'
        return apiStatus[status]
      }

      const handleClose = () => {
        setOpen(false);
      };
    return (
        <>
        <div className="side-by-side">
            <Topic
              title="Histórico de exames"
              subtitle={`Histórico de exames que você realizou.`}
              marginTop="0px"
              subMarginTop="11px"
            />
          </div>

          {loading ? findingHistoric() :
            <>
              <TableContainer>
                {Boolean(getHistoricError) && <Alert severity="error">{getHistoricError}</Alert>}
                {!rows.length && <Alert severity="info">Não encontramos nenhum exame que você tenha realizado</Alert>}
         
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Paciente</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Exame</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell style={{paddingLeft: "0"}}>Visualizar</TableCell>
                      <TableCell>Atendimento</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {handlePatientName(row.examAppointment[0].patient)}
                          {row.medicalAppointmentReturnGenerated && (
                            <ReplayIcon style={{margin:"8px 0 0 10px", color:'#DF9000'}} />
                          )}
                        </TableCell>
                        <TableCell>{format(new Date(row.examAppointment[0].dateTimeBeginAppointmentUtc), 'dd/MM/yyyy - HH:mm')}</TableCell>
                        <TableCell>{row.allServicesProvidedDescription}</TableCell>
                        <TableCell>{handleStatus(row.examAppointment[0].status)}</TableCell>
                        <TableCell onClick={() => handleClickLine(row)}><Icon style={{ cursor: 'pointer', alignSelf: "center"}}>remove_red_eye</Icon></TableCell>
                        <TableCell>
                          <Chip
                          label={'PRESENCIAL'}
                          size="small"
                          variant="outlined"
                          style={{fontSize: ".7rem"}}
                          ></Chip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
          
                <TableFooter>
                  <TableRow>
                  </TableRow>
                </TableFooter>
              </Table>
              </TableContainer>
              <HistoricDetailsDialog
            open={open}
            handleClose={handleClose}
            modalContent={modalContent}
        />
           <div className={classes.pagination}>
          <Pagination 
              page={page}
              setPage={setPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              firstItem={firstItem}
              lastItem={lastItem}
              length={count}
              width="95%"
          />
        </div>
            </>
          }
        </>
    )
}