export function RegisterProceeding(payload) {
  return {
    type: "REGISTER_PROCEEDING",
    payload: payload,
  };
}

export function ResetProceeding(payload) {
  return {
    type: "RESET_PROCEEDING",
    payload: payload,
  };
}

export function alterActive(index) {
  return {
    type: "ALTER_ACTIVE_PROCEEDING",
    payload: index,
  };
}

export function deleteProceeding(index) {
  return {
    type: "DELETE_PROCEEDING",
    payload: index,
  };
}

export function updateProceeding(index, details) {
  return {
    type: "UPDATE_PROCEEDING",
    payload: index,
    details,
  };
}

export function deleteAllProceedings() {
  return { type: "DELETE_ALL_PROCEEDINGS" };
}

export function openModal(payload) {
  return {
    type: "OPEN_MODAL_PROCEEDING",
    payload
  }
}