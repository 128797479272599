import React, { useState } from 'react'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import queryString from 'query-string'

import { useLocation } from 'react-router-dom'

import HorizontalList from '../../common/horizontalList'
import Scheduling from './tabs/scheduling'
import Procedures from './tabs/procedures'

import DoctorsList from '../administrator/common/doctor/doctorsPage';


const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: "#F7F8F9",
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  root: {
    width: '95%',
    padding: '3vh 0',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 600
    }
  },
  guide: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5vh 0'
  }
}));

export default () => {
  const classes = useStyles();
  const userType = window.localStorage.getItem("userType");

  const location = useLocation()
  const query = queryString.parse(location.search)

  const [selectedDoctor, setDoctor] = useState(false);

  const [selectedCategory, setCategory] = useState(isNaN(Number(query?.tab)) ? 0 : Number(query.tab))
  const listOptions = [
    { text: 'Agendamento' },
  ]

  if (userType !== 'PROVIDER_SECRETARY') listOptions.push({ text: 'Orçamentos' })

  const getProviderId = (value) => {
    window.localStorage.setItem("Prov-Vida-Connect", value?.providerId || value?.provider?.id);
    window.localStorage.setItem("Secretary-Selected-Doctor", JSON.stringify(value))
    setDoctor(value?.providerId || value?.provider?.id);
  }

  return (
    <div className={classes.screen}>
      <div className={classes.root}>
        

        { !selectedDoctor && 
          userType !== "PROVIDER_DOCTOR" && 
          userType !== "PROVIDER_OTHER_PROFESSIONALS"  &&
          
          <DoctorsList
            title="Médicos"
            subtitle="Selecione o médico que você deseja acessar as solicitações"
            onClick={getProviderId}
          />
        }

        { (
            selectedDoctor || 
            userType === "PROVIDER_DOCTOR" || 
            userType === "PROVIDER_OTHER_PROFESSIONALS"
          ) &&
          
          <>
            <h1>
              { userType !== "PROVIDER_DOCTOR" && 
                userType !== "PROVIDER_OTHER_PROFESSIONALS"  &&
                <IconButton 
                  aria-label="delete" 
                  className={classes.margin} 
                  size="medium" 
                  onClick={() => setDoctor(false)}
                >
                  <ArrowBackIcon fontSize="inherit" />
                </IconButton>
              }
              Solicitações
            </h1>
            <div className={classes.guide}>
              <HorizontalList 
                width="90%"
                listItems={listOptions}
                setSelectedList={setCategory}
                selectedList={selectedCategory}
              />
            </div>
            { selectedCategory === 0 &&
              <Scheduling />
            }
            { selectedCategory === 1 &&
              <Procedures />
            }
            
          </>
        } 

      </div>
    </div>
  )
}
