import React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Topic from '../../../../common/topic';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import { format } from 'date-fns'
import Icon from '@material-ui/core/Icon';
import Alert from '@material-ui/lab/Alert';
import Chip from '@material-ui/core/Chip'
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import graphqlApi from './../../../../services/api'
import HistoricDetailsDialog from '../modals/procedureHistoricModal'
import Pagination from '../../../../common/pagination';
import { handlePatientName } from '../../../../common/handlePatientName';
const useStyles = makeStyles({
    table: {
      marginBottom: '30px',
      "& tbody": {
        backgroundColor: '#FFF'
      }
    },
  })
  
  const themeCircularProgress = createMuiTheme({
    palette: {
      primary: { main: "#DF9000" },
    },
  });

export default function ProceduresHistory(){
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [getHistoricError, setGetHistoricError] = useState('')
    const [rows, setRows] = useState([]);

    const [open, setOpen] = useState(false);
    const [modalContent, setModalConten] = useState({})
  
    
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const firstItem = (page*itemsPerPage)-(itemsPerPage);
    const lastItem = (firstItem + itemsPerPage);
    const employeeId = window.localStorage.getItem('employeeId')

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getHistoric = useCallback((_page, _limit) => {
      setLoading(true)
      return graphqlApi.post('/', {
        query: `
          query($page: Float, $limit: Float, $status: [ProcedureRequestStatusEnum!]) {
            listProcedureRequestsByProvider(input:{ page: $page, limit: $limit, status:$status }) {
              rows {
                id
                dateTime
                reportFile
                status
                medicalAppointment{
                  patient{
                    socialName
                    user {
                      name
                    }
                  }
                }
                serviceProvided{
                  description
                }
                }
              
              count
            } 
          }
        `,
        variables: {
          page: _page,
          limit: _limit,
          status: ['FINISHED', 'CANCELED']
        }
      })
        .then(res => {
          const result = res.data
          if (result.errors) {
            setGetHistoricError('Houve um erro ao buscar o histórico de procedimentos')
            setLoading(false)
            return
          }
          
          setCount(result.data.listProcedureRequestsByProvider.count)
          
          
          const rows = result.data.listProcedureRequestsByProvider.rows
          const rowsSorted = rows.sort((a,b)=> new Date(b.dateTime).getTime()-new Date(a.dateTime).getTime() )
          setRows(rowsSorted)
          setLoading(false)
        })
        .catch(err => {
          setGetHistoricError('Houve um erro ao buscar o histórico de procedimentos')
          setLoading(false)
          
        })
    })
  
    useEffect(() => {
      getHistoric(page, itemsPerPage, employeeId)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, itemsPerPage, employeeId])


    const handleClickLine = (row) => {
        setModalConten(row)
        setOpen(true);
      }

    const findingHistoric = () => {
        return (
          <div style={{ marginTop: 30 }}>
            <ThemeProvider theme={themeCircularProgress}>
              <CircularProgress color={'primary'} size={50} />
            </ThemeProvider>
          </div>
        )
      }

      const handleStatus = (status, maxDateToReturn) => {
        
        const apiStatus = {
          'UNATTENDED': 'Procedimento não realizado',
          'FINISHED': 'Procedimento realizado',
          'CANCELED': 'Procedimento cancelado',
        }
        if (!apiStatus[status]) status = 'UNATTENDED'
        return apiStatus[status]
      }

      const handleClose = () => {
        setOpen(false);
      };
    return (
        <>
        <div className="side-by-side">
            <Topic
              title="Histórico de procedimentos"
              subtitle={`Histórico de procedimentos que você realizou.`}
              marginTop="0px"
              subMarginTop="11px"
            />
          </div>

          {loading ? findingHistoric() :
            <>
              <TableContainer>
                {Boolean(getHistoricError) && <Alert severity="error">{getHistoricError}</Alert>}
                {!rows.length && <Alert severity="info">Não encontramos nenhum procedimento que você tenha realizado</Alert>}
         
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Paciente</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Procedimento</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell style={{paddingLeft: "0"}}>Visualizar</TableCell>
                      <TableCell>Atendimento</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {handlePatientName(row.medicalAppointment.patient)}
                       
                        </TableCell>
                        <TableCell>{format(new Date(row.dateTime), 'dd/MM/yyyy - HH:mm')}</TableCell>
                        <TableCell>{row.serviceProvided.description}</TableCell>
                        <TableCell>{handleStatus(row.status)}</TableCell>
                        <TableCell onClick={() => handleClickLine(row)}><Icon style={{ cursor: 'pointer', alignSelf: "center"}}>remove_red_eye</Icon></TableCell>
                        <TableCell>
                          <Chip
                          label={'PRESENCIAL'}
                          size="small"
                          variant="outlined"
                          style={{fontSize: ".7rem"}}
                          ></Chip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
          
                <TableFooter>
                  <TableRow>
                  </TableRow>
                </TableFooter>
              </Table>
              </TableContainer>
              <HistoricDetailsDialog
            open={open}
            handleClose={handleClose}
            modalContent={modalContent}
        />
           <div className={classes.pagination}>
          <Pagination 
              page={page}
              setPage={setPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              firstItem={firstItem}
              lastItem={lastItem}
              length={count}
              width="95%"
          />
        </div>
            </>
          }
        </>
    )
}