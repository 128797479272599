import React, { useState, useEffect } from 'react'

import { format, differenceInCalendarYears } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Typography from '@material-ui/core/Typography'

import Button from './../../../common/button'
import SnackBar from './../../../common/snackbar/syncAlternative'
import Loading from './../../../common/loading'
import * as procedureService from '../../../services/procedures'
import procedureStatus from '../../../common/enums/procedureStatus'
import budgetStatus from '../../../common/enums/budgetStatus'
import SurgicalProcedureBudgetModal from './../modals/budget'
import StepOne from './stepOne'
import StepTwo from './stepTwo'
import StepThree from './stepThree'
import StepFour from './stepFour'
import StepFive from './stepFive'
import StepSix from './stepSix'
import { handlePatientName } from '../../../common/handlePatientName';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  header: {
    marginTop: 30,
    marginBottom: 28,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headerItem: {
    marginLeft: 30,
    marginRight: 30,
  },
  patientName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '100%'
  },
  patientAgeAndGender: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    color: '#6B6C7E'
  },
  main: {
    borderTop: '1px solid #E7E7ED'
  },
  historic: {
    width: '30%',
    borderRight: '1px solid #F1F2F5',
    boxSizing: 'border-box',
    height: '100vh'
  },
  historicContent: {
    marginTop: 25
  },
  historicHeader: {
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: 30
  },
  historicMain: {
    justifyContent: "flex-start",
    alignItems: "center",
  },
  historicCardItem: {
    width: '100%',
    border: '1px solid #F1F2F5',
    backgroundColor: '#FFFFFF'
  },
  historicCardItemActive: {
    backgroundColor: '#F7F8F9'
  },
  historicCardItemCursorAuto: {
    cursor: 'auto'
  },
  historicCardItemCursorPointer: {
    cursor: 'pointer'
  },
  historicCard: {
    marginLeft: 24,
    marginRight: 24,
    marginTop: 22,
    marginBottom: 23,
  },
  historicCardStatus: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    color: '#272833'
  },
  historicCardDescription: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    color: '#272833'
  },
  historicCardService: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#6B6C7E'
  },
  historicHeaderText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    borderBottom: '2px solid #FEC65A',
    color: '#272833'
  },
  budget: {
    justifyContent: "flex-start",
    alignItems: "center",
    width: '70%',
    height: '100vh'
  },
  budgetContent: {
    marginLeft: 30,
    marginTop: 30,
    paddingBottom: 20
  },
  budgetHeader: {
    marginBottom: 30
  },
  date: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#6B6C7E'
  },
  procedureName: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
  },
  procedureCode: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#30313F'
  },
  stepItem: {
    marginTop: 30,
    marginBottom: 30,
  },
  stepTitle: {
    marginBottom: 30
  },
  stepTitleText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
  },
  stepTitleActive: {
    color: '#272833'
  },
  stepTitleInactive: {
    color: '#A7A9BC'
  },
  stepContent: {}
}));

const genderMap = {
  MALE: 'Homem',
  FEMALE: 'Mulher'
}

const calcAge = birthdate => differenceInCalendarYears(new Date(), new Date(birthdate))

export default () => {
  const classes = useStyles()
  let { id } = useParams()
  const history = useHistory()

  const employeeId = window.localStorage.getItem('employeeId')
  const [loading, setLoading] = useState(false)
  const [snack, setSnack] = useState({ open: false, message: '', severity: 'info' })
  const [openBudget, setOpenBudget] = useState(false);
  const [budget, setBudget] = useState({ hospitalBudget: {}, medicalTeamBudget: {} });
  const [procedure, setProcedure] = useState({ name: '', code: '', table: '' });
  const [patient, setPatient] = useState({ name: '', gender: '', age: '' })
  const [createdAt, setCreatedAt] = useState('')
  const [disableFormToEdition, setDisableFormToEdition] = useState(false)
  const [historic, setHistoric] = useState([])
  const [stepsActiveOrInactive, setStepsActiveOrInactive] = useState({
    stepOne: {
      class: classes.stepTitleInactive,
      active: false,
      text: ''
    },
    stepTwo: {
      class: classes.stepTitleInactive,
      active: false,
      text: ''
    },
    stepThree: {
      class: classes.stepTitleInactive,
      active: false,
      text: ''
    },
    stepFour: {
      class: classes.stepTitleInactive,
      active: false,
      text: ''
    },
    stepFive: {
      class: classes.stepTitleInactive,
      active: false,
      text: ''
    },
    stepSix: {
      class: classes.stepTitleInactive,
      active: false,
      text: ''
    }
  })

  const loadProcedures = async () => {
    const page = 1
    const limit = 10
    const procedures = await procedureService.getProcedureRequests(page, limit, employeeId)
    if (!procedures?.error) {
      setHistoric(procedures.rows)
    } else {
      setSnack({ open: true, message: 'Erro ao carregar os pedidos de procedimentos', severity: 'error' })
    }
  }

  const loadProcedureRequestById = async (procedureRequestId) => {
    const procedureRequest = await procedureService.getProcedureRequestById(procedureRequestId)

    const patient = procedureRequest.medicalAppointment.patient
    setCreatedAt(format(new Date(procedureRequest.createdAt), "'Solicitado em:' dd 'de' MMMM 'de' yyyy", { locale: ptBR }))

    if (!procedureRequest?.error) {
      setPatient({
        name: handlePatientName(patient),
        gender: genderMap[patient.gender],
        age: calcAge(patient.birth)
      })

      const hospitalBudget = {
        hasOpmeOrAditionalMaterials: false,
        opmeOrAditionalMaterials: { opme: null, aditionalMaterials: null },
        specialEquipment: null,
        dailyQuantities: null,
        hasHospitalization: false,
        hospitalizationQuantities: null,
        hasSpecialMedications: false,
        specialMedications: null,
        hasBloodProducts: false,
        bloodProducts: null,
        hospitalsSelected: [],
      }

      const medicalTeamBudget = {
        price: 0,
        helper: []
      }

      for (const budget of procedureRequest.budgets) {
        hospitalBudget.hasOpmeOrAditionalMaterials = budget.hospital_has_opme_or_aditional_materials
        hospitalBudget.opmeOrAditionalMaterials.opme = budget.hospital_opme
        hospitalBudget.opmeOrAditionalMaterials.aditionalMaterials = budget.hospital_aditional_materials
        hospitalBudget.specialEquipment = budget.hospital_special_equipment
        hospitalBudget.dailyQuantities = budget.hospital_daily_quantities
        hospitalBudget.hasHospitalization = budget.hospital_has_hospitalization
        hospitalBudget.hospitalizationQuantities = budget.hospital_hospitalization_quantities
        hospitalBudget.hasSpecialMedications = budget.hospital_has_special_medications
        hospitalBudget.specialMedications = budget.hospital_special_medications
        hospitalBudget.hasBloodProducts = budget.hospital_has_blood_products
        hospitalBudget.bloodProducts = budget.hospital_blood_products
        hospitalBudget.hospitalsSelected.push({
          id: budget.establishment.id,
          checked: true,
          name: budget.establishment.hospital.tradingName,
          anesthetist: budget.anesthetist
        })

        medicalTeamBudget.price = budget.providerPrice
        medicalTeamBudget.helper = budget.helpers.map(helper => ({
          id: helper.provider.id,
          cpf: helper.provider.user.id,
          name: helper.provider.user.name,
          price: helper.price,
          crm: `${helper.provider.doctor.crm}-${helper.provider.doctor.uf}`,
          notFound: false
        }))
      }

      setProcedure({
        name: procedureRequest.serviceProvided.description,
        code: procedureRequest.serviceProvided.code,
        table: procedureRequest.serviceProvided.importType
      })

      setBudget({ ...procedureRequest, hospitalBudget, medicalTeamBudget })
    } else {
      setSnack({ open: true, message: 'Erro ao carregar dados do orçamento', severity: 'error' })
    }
  }

  const finishBudget = async (procedureRequestId) => {
    const request = await procedureService.sendBudget(procedureRequestId)
    if (!request?.error) {
      setSnack({ open: true, message: 'Orçamento finalizado com sucesso!', severity: 'success' })
      redirectToBack()
    } else {
      setSnack({ open: true, message: request?.error?.message || 'Erro ao finalizar orçamento, por favor, tente novamente mais tarde', severity: 'error' })
    }
  }

  const saveBudget = async data => {
    const { budgetFromForm, budgetFromApi } = data

    const helper = budgetFromForm.medicalTeamBudget.helper.map(h => ({ providerId: h.id, price: h.price }))

    const dataToSendApi = {
      procedureRequestId: budgetFromApi.id,
      budget: {
        hospital_has_opme_or_aditional_materials: budgetFromForm.hospitalBudget.hasOpmeOrAditionalMaterials,
        hospital_opme: !budgetFromForm.hospitalBudget.hasOpmeOrAditionalMaterials ? null : budgetFromForm.hospitalBudget.opmeOrAditionalMaterials.opme,
        hospital_aditional_materials: !budgetFromForm.hospitalBudget.hasOpmeOrAditionalMaterials ? null : budgetFromForm.hospitalBudget.opmeOrAditionalMaterials.aditionalMaterials,
        hospital_special_equipment: !Boolean(budgetFromForm.hospitalBudget.specialEquipment) ? null : budgetFromForm.hospitalBudget.specialEquipment,
        hospital_daily_quantities: budgetFromForm.hospitalBudget.dailyQuantities,
        hospital_has_hospitalization: budgetFromForm.hospitalBudget.hasHospitalization,
        hospital_hospitalization_quantities: !budgetFromForm.hospitalBudget.hasHospitalization ? null : budgetFromForm.hospitalBudget.hospitalizationQuantities,
        hospital_has_special_medications: budgetFromForm.hospitalBudget.hasSpecialMedications,
        hospital_special_medications: !budgetFromForm.hospitalBudget.hasSpecialMedications ? null : budgetFromForm.hospitalBudget.specialMedications,
        hospital_has_blood_products: budgetFromForm.hospitalBudget.hasBloodProducts,
        hospital_blood_products: !budgetFromForm.hospitalBudget.hasBloodProducts ? null : budgetFromForm.hospitalBudget.bloodProducts,
        providerPrice: budgetFromForm.medicalTeamBudget?.price || null,
        helpersPrice: helper.length === 0 ? 0 : (helper.length > 1 ? helper : [...helper, { price: 0 }]).reduce((current, accumulator) => current.price + accumulator.price),
        providerId: budgetFromApi.medicalAppointment.provider.id,
        procedureRequestId: budgetFromApi.id,
        serviceProvidedId: budgetFromApi.serviceProvided.id,
        establishments: (budgetFromForm.hospitalBudget?.hospitalsSelected || []).map(hospital => ({ establishmentId: hospital.establishmentId || hospital.id, anesthetist: hospital.anesthetist })),
      },
      helpers: helper
    }
    const request = await procedureService.saveProcedureRequestState(dataToSendApi)
    if (!request?.error) {
      setSnack({ open: true, message: 'Orçamento salvo com sucesso!', severity: 'success' })
    } else {
      setOpenBudget(false)
      setSnack({ open: true, message: request?.error?.message || 'Erro ao salvar orçamento, por favor, tente novamente mais tarde', severity: 'error' })
    }
  }

  const redirectToBack = () => history.push('/solicitacoes?tab=1')

  const continueAnsweringForm = status => status === 'BUDGET_SAVED'

  const getIfStepIsActiveOrInactive = status => {
    const stepSix = [procedureStatus.OK, procedureStatus.PENDING_TOKEN_VALIDATION, procedureStatus.FINISHED]
    const stepFive = [procedureStatus.PENDING_PAYMENT, ...stepSix]
    const stepFour = [procedureStatus.AWAITING_DATE, procedureStatus.CONFIRM_SCHEDULE, ...stepFive]
    const stepThree = [procedureStatus.CHOOSE_A_BUDGET, ...stepFour]
    const stepTwo = [procedureStatus.AWAITING_BUDGET, ...stepThree]
    const stepOne = [procedureStatus.BUDGET_SAVED, ...stepTwo]

    const checkStepOne = stepOne.includes(status)
    const checkStepTwo = stepTwo.includes(status)
    const checkStepThree = stepThree.includes(status)
    const checkStepFour = stepFour.includes(status)
    const checkStepFive = stepFive.includes(status)
    const checkStepSix = stepSix.includes(status)

    return {
      stepOne: {
        class: checkStepOne ? classes.stepTitleActive : classes.stepTitleInactive,
        active: checkStepOne,
        text: 'Etapa 1 - Detalhamento do procedimento'
      },
      stepTwo: {
        class: checkStepTwo ? classes.stepTitleActive : classes.stepTitleInactive,
        active: checkStepTwo,
        text: 'Etapa 2 - Orçamento hospitalar'
      },
      stepThree: {
        class: checkStepThree ? classes.stepTitleActive : classes.stepTitleInactive,
        active: checkStepThree,
        text: 'Etapa 3 - Escolha do orçamento hospitalar'
      },
      stepFour: {
        class: checkStepFour ? classes.stepTitleActive : classes.stepTitleInactive,
        active: checkStepFour,
        text: 'Etapa 4 - Agendamento'
      },
      stepFive: {
        class: checkStepFive ? classes.stepTitleActive : classes.stepTitleInactive,
        active: checkStepFive,
        text: 'Etapa 5 - Pagamento'
      },
      stepSix: {
        class: checkStepSix ? classes.stepTitleActive : classes.stepTitleInactive,
        active: checkStepSix,
        text: 'Etapa 6 - Realização do procedimento'
      },
    }
  }

  useEffect(() => {
    setLoading(true)
    loadProcedureRequestById(id)
    loadProcedures()
    setStepsActiveOrInactive(getIfStepIsActiveOrInactive(budget))
    setLoading(false)
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    setLoading(true)
    setStepsActiveOrInactive(getIfStepIsActiveOrInactive(budget.status))
    setLoading(false)
    // eslint-disable-next-line
  }, [budget])

  const openBudgetForm = disableEditForm => {
    setDisableFormToEdition(disableEditForm)
    setOpenBudget(true)
  }

  const onCloseSurgicalProcedureBudget = () => {
    setBudget({ ...budget })
    setOpenBudget(false)
  }

  const onSaveOrFinishSurgicalProcedureBudget = async data => {
    setLoading(true)

    const finish = data?.finish
    const hospitalBudget = data?.hospitalBudget
    const medicalTeamBudget = data?.medicalTeamBudget

    setProcedure(data.procedure)
    setBudget({ ...budget, hospitalBudget, medicalTeamBudget })

    if (finish) {
      await finishBudget(budget.id)
    } else {
      await saveBudget({ budgetFromForm: data, budgetFromApi: budget })
    }
    setLoading(false)
  }

  const selectNewBudget = _id => {
    history.replace(`/solicitacoes/orcamentos/${_id}`)
  }

  const getStepText = (status) => {
    const map = {
      [procedureStatus.BUDGET_SAVED]: stepsActiveOrInactive.stepOne.text,
      [procedureStatus.AWAITING_BUDGET]: stepsActiveOrInactive.stepTwo.text,
      [procedureStatus.CHOOSE_A_BUDGET]: stepsActiveOrInactive.stepThree.text,
      [procedureStatus.AWAITING_DATE]: stepsActiveOrInactive.stepFour.text,
      [procedureStatus.CONFIRM_SCHEDULE]: stepsActiveOrInactive.stepFour.text,
      [procedureStatus.PENDING_PAYMENT]: stepsActiveOrInactive.stepFive.text,
      [procedureStatus.OK]: stepsActiveOrInactive.stepSix.text,
    }

    return map[status]
  }

  return (
    <>
      { loading &&
        <Loading width="80px" />
      }

      { !loading &&
        <Grid container className={classes.root}>
          <Grid container className={classes.header}>
            <Grid item className={classes.headerItem}>
              <Button
                border="1px solid #CDCED9"
                backgroundColor="#FFF"
                color="#6B6C7E"
                onClick={redirectToBack}
              >
                <ArrowBackIosIcon /> Voltar
            </Button>
            </Grid>
            <Grid item className={classes.headerItem}>
              <Typography className={classes.patientName}>{patient.name}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.patientAgeAndGender}>
                {patient.gender}, {patient.age} {patient.age === 1 ? 'ano' : 'anos'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.main}>
            <Grid item className={classes.historic}>
              <Grid item className={classes.historicContent}>
                <Grid container className={classes.historicHeader}>
                  <Typography className={classes.historicHeaderText}>Minhas últimas solicitações</Typography>
                </Grid>
                <Grid item className={classes.historicMain}>
                  {historic.map(item => (
                    <Grid
                      item
                      key={item.id}
                      className={`
                        ${classes.historicCardItem} 
                        ${item.id === id ? classes.historicCardItemActive : ''} 
                        ${item.id === id ? classes.historicCardItemCursorAuto : classes.historicCardItemCursorPointer}
                      `}
                      onClick={() => item.id !== id && selectNewBudget(item.id)}
                    >
                      <Grid item className={classes.historicCard}>
                        <Typography className={classes.historicCardStatus}>{getStepText(item.status)}</Typography>
                        <Typography className={classes.historicCardDescription}>{item.serviceProvided.description}</Typography>
                        <Typography className={classes.historicCardService}>{item.serviceProvided.importType}: {item.serviceProvided.code}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.budget}>
              <Grid item className={classes.budgetContent}>
                <Grid item className={classes.budgetHeader}>
                  <Grid item><Typography className={classes.date}>{createdAt}</Typography></Grid>
                  <Grid item><Typography className={classes.procedureName}>{procedure.name}</Typography></Grid>
                  <Grid item><Typography className={classes.procedureCode}>{procedure.table}: {procedure.code}</Typography></Grid>
                </Grid>
                <Grid item>
                  <Grid item className={classes.stepItem}>
                    <Grid item className={classes.stepTitle}>
                      <Typography className={`${classes.stepTitleText} ${stepsActiveOrInactive.stepOne.class}`}>{stepsActiveOrInactive.stepOne.text}</Typography>
                    </Grid>
                    {stepsActiveOrInactive.stepOne.active &&
                      <Grid item className={classes.stepContent}>
                        <StepOne continueAnsweringForm={continueAnsweringForm(budget.status)} openForm={openBudgetForm} />
                      </Grid>
                    }
                  </Grid>
                  <Grid item className={classes.stepItem}>
                    <Grid item className={classes.stepTitle}>
                      <Typography className={`${classes.stepTitleText} ${stepsActiveOrInactive.stepTwo.class}`}>{stepsActiveOrInactive.stepTwo.text}</Typography>
                    </Grid>
                    {stepsActiveOrInactive.stepTwo.active &&
                      <Grid item className={classes.stepContent}>
                        <StepTwo
                          hospitals={
                            budget.budgets
                              .filter(i => [budgetStatus.BUDGET_SENDED, budgetStatus.BUDGET_ANSWERED, budgetStatus.BUDGET_APPROVED, budgetStatus.BUDGET_CANCELED].includes(i.status))
                              .map(item => ({
                                name: item.establishment.hospital.tradingName,
                                price: item.establishmentPrice,
                                status: item.status
                              }))
                          }
                        />
                      </Grid>
                    }
                  </Grid>
                  <Grid item className={classes.stepItem}>
                    <Grid item className={classes.stepTitle}>
                      <Typography className={`${classes.stepTitleText} ${stepsActiveOrInactive.stepThree.class}`}>{stepsActiveOrInactive.stepThree.text}</Typography>
                    </Grid>
                    {stepsActiveOrInactive.stepThree.active &&
                      <Grid item className={classes.stepContent}>
                        <StepThree hospital={budget.budgets.find(i => i.status === budgetStatus.BUDGET_APPROVED)} />
                      </Grid>
                    }
                  </Grid>
                  <Grid item className={classes.stepItem}>
                    <Grid item className={classes.stepTitle}>
                      <Typography className={`${classes.stepTitleText} ${stepsActiveOrInactive.stepFour.class}`}>{stepsActiveOrInactive.stepFour.text}</Typography>
                    </Grid>
                    {stepsActiveOrInactive.stepFour.active &&
                      <Grid item className={classes.stepContent}>
                        <StepFour
                          budget={budget}
                          setBudget={setBudget}
                          setSnack={setSnack} 
                        />
                      </Grid>
                    }
                  </Grid>
                  <Grid item className={classes.stepItem}>
                    <Grid item className={classes.stepTitle}>
                      <Typography className={`${classes.stepTitleText} ${stepsActiveOrInactive.stepFive.class}`}>{stepsActiveOrInactive.stepFive.text}</Typography>
                    </Grid>
                    {stepsActiveOrInactive.stepFive.active &&
                      <Grid item className={classes.stepContent}>
                        <StepFive budget={budget} />
                      </Grid>
                    }
                  </Grid>
                  <Grid item className={classes.stepItem}>
                    <Grid item className={classes.stepTitle}>
                      <Typography className={`${classes.stepTitleText} ${stepsActiveOrInactive.stepSix.class}`}>{stepsActiveOrInactive.stepSix.text}</Typography>
                    </Grid>
                    {stepsActiveOrInactive.stepSix.active &&
                      <Grid item className={classes.stepContent}>
                        <StepSix budget={budget} />
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }

      <SurgicalProcedureBudgetModal
        procedure={procedure}
        open={openBudget}
        budget={budget}
        disableFormToEdition={disableFormToEdition}
        onSave={onSaveOrFinishSurgicalProcedureBudget}
        onFinish={onSaveOrFinishSurgicalProcedureBudget}
        onClose={onCloseSurgicalProcedureBudget}
      />

      <SnackBar
        open={snack.open}
        errorMessage={snack.message}
        onClose={() => setSnack({ ...snack, open: false })}
        root={{}}
        severity={snack.severity}
      />
    </>
  )
}
