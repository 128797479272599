import apiGraphql from "../../../services/api";
import * as asyncActions from './async.action'

export function listMedicalAppointmentRequests(limit=10, page=1) {
  return async (dispatch) => {
    try{
      const res = await apiGraphql({
        data: {
          query: `
            query {
              listMedicalAppointmentRequests(
                input: {
                  page: ${page}
                  limit: ${limit}
                }
              ) {
                count,
                rows {
                  id,
                  status,
                  appointmentLocation
                  speciality{ name },
                  patient { id, socialName },
                  medicalAppointmentRequest {
                    id,
                    closestDate,
                    initialDate,	
                    finalDate,	
                    daysOfWeek,
                    startTime,
                    endTime,
                    proposedDate1,
                    proposedDate2,
                    acceptedDate,
                    createdAt
                  }
                }
              }
            }
          `
        }
      })
      const medicalAppointmentRequests = res.data.data
      dispatch({
        type: "SET_MEDICAL_APPOINTMENT_REQUESTS_COUNT",
        payload: medicalAppointmentRequests.listMedicalAppointmentRequests.count
      })
      //dispatch(this.setMedicalAppointmentRequests(medicalAppointmentRequests.listMedicalAppointmentRequests.rows))
      dispatch({
        type: "SET_MEDICAL_APPOINTMENT_REQUESTS",
        payload: medicalAppointmentRequests.listMedicalAppointmentRequests.rows
      })

    } catch (error) {
      dispatch(
        asyncActions.requestFailure(
          "Erro ao tentar listar as solicitações. Tente novamente, por favor."
        )
      );
    }
  }
}

export function secretaryListMedicalAppointmentRequests(limit=10, page=1, providerId) {
  return async (dispatch) => {
    try{
      const res = await apiGraphql({
        data: {
          query: `
            query {
              secretaryListMedicalAppointmentRequests(
                input: {
                  providerId: "${providerId}",
                  page: ${page}
                  limit: ${limit}
                }
              ) {
                count,
                rows {
                  id,
                  status,
                  speciality{ name },
                  patient { id, socialName },
                  medicalAppointmentRequest {
                    id,
                    closestDate,
                    initialDate,	
                    finalDate,	
                    daysOfWeek,
                    startTime,
                    endTime,
                    proposedDate1,
                    proposedDate2,
                    acceptedDate,
                    createdAt
                  }
                }
              }
            }
          `
        }
      })
      const medicalAppointmentRequests = res.data.data
      dispatch({
        type: "SET_MEDICAL_APPOINTMENT_REQUESTS_COUNT",
        payload: medicalAppointmentRequests.secretaryListMedicalAppointmentRequests.count
      })
      //dispatch(this.setMedicalAppointmentRequests(medicalAppointmentRequests.listMedicalAppointmentRequests.rows))
      dispatch({
        type: "SET_MEDICAL_APPOINTMENT_REQUESTS",
        payload: medicalAppointmentRequests.secretaryListMedicalAppointmentRequests.rows
      })

    } catch (error) {
      dispatch(
        asyncActions.requestFailure(
          "Erro ao tentar listar as solicitações. Tente novamente, por favor."
        )
      );
    }
  }
}

export function proposeDates (requestId, proposedDate1, proposedDate2) {
  return async (dispatch) => {
    try{
      dispatch(asyncActions.requestSent());
      const res = await apiGraphql({
        data: {
          query: `
            mutation {
              proposeDatesToMedicalAppointmentRequest (
                medicalAppointmentId: "${requestId}",
                proposedDate1: "${proposedDate1}",
                proposedDate2: "${proposedDate2}"
              )
              {
                id,
                status,
                speciality{ name },
                patient { id, socialName },
                medicalAppointmentRequest {
                  id,
                  closestDate,
                  initialDate,	
                  finalDate,	
                  daysOfWeek,
                  startTime,
                  endTime,
                  proposedDate1,
                  proposedDate2,
                  acceptedDate,
                  createdAt
                }
              }
            }
          `
        }
      })
      if(res.data.data !== null) {
        await dispatch({
          type: "SET_LAST_CHANGED_MEDICAL_APPOINTMENT",
          payload: res.data.data.proposeDatesToMedicalAppointmentRequest
        })
      } else {
        const error = res.data.errors[0]
        dispatch(
          asyncActions.requestFailure(
            error.message
          )
        )
      }
      dispatch(asyncActions.requestSuccess())
    } catch (error) {
      dispatch(
        asyncActions.requestFailure(
          "Houve um problema ao tentar enviar as datas."
        )
      )
    }
  }
}

export function denyRequest(medicalAppointmentId) {
  return async (dispatch) => {
    try{
      dispatch(asyncActions.requestSent());
      const res = await apiGraphql({
        data: {
          query: `
            mutation {
              denyMedicalAppointmentRequest ( medicalAppointmentId: "${medicalAppointmentId}" ) 
              {
                id,
                status,
                speciality{ name },
                patient { id, socialName },
                medicalAppointmentRequest {
                  id,
                  closestDate,
                  initialDate,	
                  finalDate,	
                  daysOfWeek,
                  startTime,
                  endTime,
                  proposedDate1,
                  proposedDate2,
                  acceptedDate,
                  createdAt
                }
              }
            }
          `
        }
      })
      if(res.data.data) {
        await dispatch({
          type: "SET_LAST_CHANGED_MEDICAL_APPOINTMENT",
          payload: res.data.data.denyMedicalAppointmentRequest
        })
      }
      dispatch(asyncActions.requestSuccess())

    } catch (error) {
      dispatch(
        asyncActions.requestFailure(
          "Houve um problema na hora de pegar a listagem dos médicos. Tente novamente, por favor."
        )
      )
    }
  }
}
