import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import HorizontalList from '../../common/horizontalList'
import ConsultationTab from './tabs/consultation'
import ListPatientsWaitingTab from './tabs/listPatientsWaiting'
import * as asyncActions from '../../common/store/actions/async.action'

const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: "#F7F8F9",
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  root: {
    width: '95%',
    padding: '3vh 0',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 600
    }
  },
  guide: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5vh 0'
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const [selectedCategory, setCategory] = useState(0);
  const [avaiableToService, setAvaiableToService] = useState(false)
  const listOptions = [
    { text: 'Consulta' },
    { text: 'Aguardando' }
  ]

  useEffect(() => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      dispatch(asyncActions.virtuaRegisterDoctorOffline())
    });
    return () => {
      // dispatch(asyncActions.virtuaRegisterDoctorOffline())
    }
    //eslint-disable-next-line
  },[])

  return (
    <div className={classes.screen}>
      <div className={classes.root}>
        <h1>Fila de atendimento</h1>
        <div className={classes.guide}>
          <HorizontalList 
            width="90%"
            listItems={listOptions}
            setSelectedList={setCategory}
            selectedList={selectedCategory}
          />
        </div>
        {listOptions[selectedCategory].text === 'Consulta' && (
          <ConsultationTab 
            avaiableToService={avaiableToService} 
            setAvaiableToService={setAvaiableToService}
          />
        )}
        {listOptions[selectedCategory].text === 'Aguardando' && (
          <ListPatientsWaitingTab />
          
        )}

      </div>
    </div>
  )
}