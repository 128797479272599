export function openModal(payload) {
  return {
      type: "OPEN_VALIDATE_TOKEN_MODAL",
      payload
  }
}

export function storeValidateTokenData(payload) {
  return {
      type: "STORE_VALIDATE_TOKEN_DATA",
      payload
  }
}

export function isSuccess(payload) {
  return {
      type: "IS_SUCCESS_VALIDATE_TOKEN",
      payload
  }
}

export function waitingResults(payload) {
  return {
      type: "WAITING_VALIDATE_TOKEN_APPROVAL",
      payload
  }
}
