import axios from "axios";
import graphqlApi from './api'

export const getProcedureRequests = async (page, limit, employeeId = null) => {
  try {
    const res = await graphqlApi({
      data: {
        query: `query ($input: ListProcedureRequestsByUserInput){
          listProcedureRequestsByProvider (input: $input) {
            count,
            rows {
              id
              active
              status
              dateTime
              createdAt
              reportFile
              serviceProvided {
                description
                importType
                code
              }
              medicalAppointment{
                id
                providerId
                provider{
                  user {
                    name
                  }
                }
                patient {
                  socialName
                  user {
                    name
                  }
                }
              }
            }
          }
        }`,
        variables: {
          input: { page, limit }
        }
      }
    })

    if (!res.data.data.errors) {
      res.data.data.listProcedureRequestsByProvider.rows = res.data.data.listProcedureRequestsByProvider.rows.filter(() => !Boolean(employeeId))
      return res.data.data.listProcedureRequestsByProvider
    } else {
      return { error: res.data.data.errors }
    }

  } catch (error) {
    return { error }
  }
}

export const getProcedureRequestById = async (procedureRequestId) => {
  try {
    const res = await graphqlApi({
      data: {
        query: `query($procedureRequestId: ID!) {
          listProcedureRequestById(procedureRequestId: $procedureRequestId) {
            id
            active
            status
            proposedDate1
            proposedDate2
            proposedDate3
            dateTime
            createdAt
            reportFile
            serviceProvided {
              description
              code
              importType
            }
            budgets {
              id
              active
              status
              hospital_has_opme_or_aditional_materials
              hospital_opme
              hospital_aditional_materials
              hospital_special_equipment
              hospital_daily_quantities
              hospital_has_hospitalization
              hospital_hospitalization_quantities
              hospital_has_special_medications
              hospital_special_medications
              hospital_has_blood_products
              hospital_blood_products
              providerPrice
              establishmentPrice
              helpersPrice
              helpers {
                id
                active
                price
                provider {
                  id
                  user {
                    id
                    name
                    cpf
                  }
                  doctor {
                    id
                    crm
                    uf
                  }
                }
              }
              establishment {
                id
                hospital {
                  tradingName
                  companyName
                } 
              }
              anesthetist
            }
            medicalAppointment {
              id
              provider {
                id
                user {
                  name
                } 
              }
              patient {
                id
                socialName
                gender
                birth
                user {name}
              }
            }
          } 
        }`,
        variables: {
          procedureRequestId
        }
      }
    })

    if (!res.data.data.errors) {
      return res.data.data.listProcedureRequestById
    } else {
      return { error: res.data.data.errors }
    }

  } catch (error) {
    return { error }
  }
}

export const getProceduresDaily = async(date, status, page = 1 , limit = 10, employeeId = null, doctorsList = null) => {
  try {

    if(!status)
      status = ['OK', 'PENDING_TOKEN_VALIDATION', 'PENDING_PAYMENT', 'ON_PROGRESS'] // 
      
    const res = await graphqlApi({
      data: {
        query: `
          query proceduresDaily($input:ProceduresDailyInput!){
            proceduresDaily(input: $input){
              count,
              rows {
                id
                dateTime
                status
                medicalAppointment{
                  patient {
                    id
                    socialName
                    user {
                      cpf
                      email
                      name
                    }
                  }
                  provider {
                    user{
                      name
                    }
                    doctor{
                      crm
                      uf
                    }
                  }
                }
                serviceProvided{
                  id
                  description
                }
              }
            }
          }
        `,
        variables: {
          input: { date, status, page, limit, providerId: !doctorsList?.length ? undefined : doctorsList }
        }
      }
    })

    if(res.data.errors) {
      return { error: true, message: res.data.errors[0].message }
    } else {
      res.data.data.proceduresDaily.rows = res.data.data.proceduresDaily.rows.filter(() => !Boolean(employeeId))
      return res.data.data.proceduresDaily
    }
  } catch (error) {
    return { error: true, message: 'Ocorreu um erro ao tentar buscar a lista de procedimentos'}
  }
}

export const sendBudget = async (procedureRequestId) => {
  try {
    const res = await graphqlApi({
      data: {
        query: `mutation($procedureRequestId: String!) {
          sendBudget(procedureRequestId: $procedureRequestId) {
            id
            active
            status
            dateTime
          } 
        }`,
        variables: {
          procedureRequestId
        }
      }
    })

    if (!res?.data?.errors) {
      return res.data.data.sendBudget
    } else {
      if (res?.data?.errors[0]) {
        return {
          error: {
            errors: res.data.errors,
            message: res.data.errors[0]?.message || 'Erro ao finalizar orçamento, por favor, tente novamente mais tarde'
          },
        }
      }
      return { error: res.data.errors }
    }

  } catch (error) {
    return { error }
  }
}

export const saveProcedureRequestState = async (input) => {
  try {
    const res = await graphqlApi({
      data: {
        query: `mutation($input: SaveProcedureRequestStateInput!) {
          saveProcedureRequestState(input: $input)
        }`,
        variables: {
          input
        }
      }
    })

    if (!res?.data?.errors) {
      return res.data.data.saveProcedureRequestState
    } else {
      if (res?.data?.errors[0]) {
        return {
          error: {
            errors: res.data.errors,
            message: res.data.errors[0]?.message || 'Erro ao salvar orçamento, por favor, tente novamente mais tarde'
          },
        }
      }
      return { error: res.data.errors }
    }

  } catch (error) {
    return { error }
  }
}

export const proposeProcedureDates = async (procedureRequestId, proposedDate1, proposedDate2, proposedDate3) => {
  try{
    const res = await graphqlApi({
      data: {
        query: `
          mutation proposeProcedureDates($input:ProposeProcedureDatesInput!){
            proposeProcedureDates(input: $input)
          }
        `,
        variables: {
          input: { procedureRequestId, proposedDate1, proposedDate2, proposedDate3 }
        },
      }
    })
    
    if (res.data.errors) {
      return { error: true, message: res.data.errors[0].message }
    } else {
      return  res.data.data.proposeProcedureDates
    }

  } catch (error) {
    return { error: true, message: 'Ocorreu um erro ao tentar enviar a data para este procedimento'}
  }
}

export const validateProcedureToken = async (procedureRequestId, token) => {
  try {

    const res = await graphqlApi({
      data: {
        query: `
          mutation {
            validateProcedureToken(
              procedureRequestId: "${procedureRequestId}",
              token: "${token}"
            )
          }
        `
      }
    })

    if(res.data.errors) {
      return { error: true, message: res.data.errors[0].message }
    } else {
      return res.data.data.validateProcedureToken
    }
  } catch (error) {
    return { error: true, message: 'Ocorreu um erro ao tentar validar este token.'}
  }
}

export const startProcedureAttendance = async (procedureRequestId) => {
  try {
    const res = await graphqlApi({
      data: {
        query: `
          mutation {
            startProcedureAttendance(
              procedureRequestId: "${procedureRequestId}"
            )
          }
        `
      }
    })

    if(res.data.errors) {
      return { error: true, message: res.data.errors[0].message }
    } else {
      return res.data.data.startProcedureAttendance
    }

  } catch (error) {
    return { error: true, message: 'Ocorreu um erro ao tentar inicializar o atendimento'}
  }
}

export const finalizeProcedureAttendance = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}/procedure/finalize-procedure`;
  try {
    await axios({
      method: "post",
      url: url,
      data: payload,
      // data: { teste: 'a' }
    });
  
    return true
  } catch (error) {
    console.log(error)
    return false
  }
  
}
