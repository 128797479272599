import React, {memo} from "react";

import Topic from "../../../../common/topic";
import Select from "../../../../common/select";

const SupportType = (props) => {
  return (
    <React.Fragment>
      <div className="row">
        <Topic
          classesNameCol="s12 m11 offset-l1 topic-datas middle-topics"
          title="Modalidade de Atendimento"
          subtitle="Informe se prestará atendimento de consulta online e/ou presencial."
          warning="Obs: Para o atendimento por telemedicina (online) é necessário possuir certificado digital."
        />
      </div>
      <div style={{marginTop:"12px"}}>
        <Select 
          width="24%"
          label="Modalidade"
          value={props.supportType}
          onChange={props.setSupportType}
          onBlur={props.setSupportTypeError}
          error={props.supportTypeError.typeError}
          itemList={[
            {value:"ONLINE", exhibit:"Online"},
            {value:"PRESENTIAL_AND_ONLINE", exhibit:"Online e Presencial"},
            {value:"PRESENTIAL", exhibit:"Presencial"},
          ]}
          dataTest="select-pf-modalidade"
        />
      </div>      
    </React.Fragment>
  );
};

export default memo(SupportType);