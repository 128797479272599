import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CreateScheduleModal from '../../pages/administrator/schedule/modals';
import Day from '../../common/calendarDays';
import Topic from '../../common/topic';
import withoutSchedule from '../../assets/withoutSchedule.png';
import {
    getDate,
    parse,
    parseISO,
    isSameDay,
    getMonth,
    getYear,
    startOfWeek,
    getDay,
    addDays,
    addWeeks,
    subWeeks,
    differenceInMinutes,
    addMinutes,
    isBefore,
    format,
    isAfter,
    differenceInHours,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'
import ptBR from 'date-fns/locale/pt-BR';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Button from '../button';

const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
const weekDayFromApiTranslation = {
    "SUN": 0,
    "MON": 1,
    "TUE": 2,
    "WED": 3,
    "THU": 4,
    "FRI": 5,
    "SAT": 6,
}
const useStyles = makeStyles({
    root: {
        width: '100%',
        maxHeight: '100%',
        borderRadius: '10px',
        backgroundColor: "#FFFFFF",
        display: 'flex',
        flexDirection: 'column',
    },
    monthAndYear: {
        width: '100%',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        borderBottom: '1px solid #e7e7ed',
        '& > span': {
            fontSize: '14px',
            fontWeight: '600',
        },
    },
    dayWeek: {
        display: 'flex',
        width: '99.6%',
        "& div:last-child": {
            width: '100%'
        },
        justifyContent: 'space-between',
        marginRight: '12px',
        "& div": {
            width: '100%'
        }
    },
    sideBySide: {
        display: 'flex',
    },
    preView: {
        display: 'flex',
        height: 'calc(72vh - 40px)',
        boxSizing: "border-box",
        paddingBottom: '40px'
    },
    view: {
        display: 'flex',
        backgroundColor: '#F8F8F8',
        overflowY: 'auto',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        borderRadius: "0 0 10px 10px",
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CDCED9',
            outline: '1px solid slategrey',
            borderRadius: '5px'
        }
    },
    row: {
        width: '100%',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }
});

const Scheduler = ({ selectedDate, schedule, selectedSchedule, interval, unavailabilities, ...props }) => {
    const classes = useStyles();
    const weekStart = startOfWeek(selectedDate);
    const actualMonth = months[getMonth(selectedDate)];
    const actualYear = getYear(selectedDate);
    const shownWeek = [
        weekStart,
        addDays(weekStart, 1),
        addDays(weekStart, 2),
        addDays(weekStart, 3),
        addDays(weekStart, 4),
        addDays(weekStart, 5),
        addDays(weekStart, 6),
    ]
    const daysOfWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
    let eachDayOfSelectedSchedule = [];
    let eachDayOfSelectedScheduleInfo = [];
    let selectedScheduleUnavailabilities = unavailabilities[selectedSchedule] || [];

    if (schedule.length > 0) {
        let minorBeginMinuteOfDay = 24 * 60 //Init with major day minute

        const buildEachDay = (item) => {
            const days = []
            let day = item.beginSchedule

            do {
                const utc = getUtc(
                    new Date(`${day}T${item.beginShift}Z`),
                    new Date(`${day}T${item.beginShiftUtc}Z`)
                )
                const _beginShift = new Date(`${day}T${item.beginShift}.000${utc.toString()}:00`)
                const _endShift = new Date(`${day}T${item.endShift}.000${utc.toString()}:00`)
                const _beginShiftUtc = utcToZonedTime(_beginShift, 'UTC')
                const _endShiftUtc = utcToZonedTime(_endShift, 'UTC')

                let beginMinuteOfDay = _beginShift.getHours() * 60 + _beginShift.getMinutes()
                minorBeginMinuteOfDay = beginMinuteOfDay < minorBeginMinuteOfDay ? beginMinuteOfDay : minorBeginMinuteOfDay 
    
                days.push({
                    ...item,
                    day: parseISO(day),
                    _beginShiftUtc: _beginShift,
                    _endShiftUtc: _endShift,
                    _dayOfWeek: getDay(parseISO(day)),
                    numberOfSlots: Math.floor(((differenceInMinutes(_endShiftUtc, _beginShiftUtc))) / item.appointmentDuration) + 1 || 0,
                    beginMinuteOfDay
                })
                day = format(addDays(parseISO(day), 1), 'yyyy-MM-dd')
            } while (isSameDay(parseISO(day), parseISO(item.endSchedule)) || isBefore(parseISO(day), parseISO(item.endSchedule)));

            return days
        }

        const filterBySameDayOfWeek = day => day._dayOfWeek === weekDayFromApiTranslation[day.dayOfWeek]

        const sortByHour = (a, b) => {
            if (format(a._beginShiftUtc, 'HH:mm') < format(b._beginShiftUtc, 'HH:mm')) return -1
            if (format(a._beginShiftUtc, 'HH:mm') > format(b._beginShiftUtc, 'HH:mm')) return 1
            return 0
        }

        eachDayOfSelectedSchedule = schedule[selectedSchedule]
            .map(buildEachDay)
            .flat()
            .filter(filterBySameDayOfWeek)
            .sort(sortByHour)

        eachDayOfSelectedScheduleInfo = eachDayOfSelectedSchedule.map((value) => {
            const day = value.day
            const date = value._beginShiftUtc
            const scheduleHasProviderId = value.id
            const scheduleHasEmployeeId = value.id
            const duration = value.appointmentDuration
            const scheduleGroupProviderId = value.scheduleGroupProviderId
            const scheduleGroupEmployeeId = value.scheduleGroupEmployeeId

            const spanSlots = (value.beginMinuteOfDay - minorBeginMinuteOfDay) / duration
        
            const shifts = Array(value.numberOfSlots).fill(0).map((_, index) => {
                const begin = addMinutes(date, index * duration)
                const end = addMinutes(begin, duration)
                let emptySlot = isBefore(begin, value._beginShiftUtc) || isAfter(begin, value._endShiftUtc)

                return {
                    begin,
                    end,
                    scheduleHasProviderId,
                    scheduleHasEmployeeId,
                    duration,
                    scheduleGroupProviderId,
                    scheduleGroupEmployeeId,
                    emptySlot
                }
            }).sort(i => i.emptySlot ? -1 : 1)

            if(spanSlots) {
                shifts.unshift({
                    begin: minorBeginMinuteOfDay,
                    emptySlot: true,
                    spanSlots
                })
            }

            shifts.pop()
            return { day, shifts }
        })
    }

    const eachDayUnavailabilities = (day) => {
        const filteredByDay = selectedScheduleUnavailabilities.filter(unavailability => {
            const parsedDate = parse(unavailability.date, "yyyy-MM-dd", new Date(), { locale: ptBR });
            const isCanceled = unavailability.status === "CANCELED";
            return (isSameDay(parsedDate, day) && !isCanceled);
        });
        return filteredByDay
    };

    if (schedule.length === 0) {
        return (
            <>
                <img
                    className="without-address-img"
                    src={withoutSchedule}
                    alt=""
                />
                <Topic
                    title="Agenda ainda não foi criada"
                    subtitle="Crie a sua agenda para começar"
                    color="#272833"
                    subColor="#6B6C7E"
                    subMarginTop="8px"
                />
                <div style={{ marginTop: '16px' }}>
                    <CreateScheduleModal />
                </div>
            </>
        )
    } else {
        return (
            <div className={classes.root}>
                <div className={classes.monthAndYear}>
                    <span>{actualMonth} {actualYear}</span>
                    <div className={classes.sideBySide}>
                        <Button
                            backgroundColor="transparent"
                            border="none"
                            onClick={() => props.onPick(subWeeks(selectedDate, 1))}
                        >
                            <KeyboardArrowLeftIcon />
                        </Button>
                        <Button
                            backgroundColor="transparent"
                            border="none"
                            onClick={() => props.onPick(addWeeks(selectedDate, 1))}
                        >
                            <KeyboardArrowRightIcon />
                        </Button>
                    </div>
                </div>
                <div className={classes.dayWeek}>
                    {shownWeek.map((day, index) => (
                        <Button
                            key={index}
                            backgroundColor="#FFFFFF"
                            color={isSameDay(day, selectedDate) ? "#EDA014" : null}
                            border={index < 6 ? "1px solid #e7e7ed" : null}
                            borderLeft={index === 6 ? "1px solid #e7e7ed" : null}
                            borderTop={index === 6 ? "1px solid #e7e7ed" : null}
                            borderBottom={index === 6 ? "1px solid #e7e7ed" : null}
                            borderRadius="0px"
                            width="100%"
                            onClick={() => props.onPick(day)}
                        >
                            <span>{getDate(day)} {daysOfWeek[getDay(day)]}</span>
                        </Button>
                    ))}
                </div>
                <div className={classes.preView}>
                    <div className={classes.view}>
                        {shownWeek.map(day => {
                            return (
                                <Day
                                    key={day}
                                    day={day}
                                    unavailabilities={eachDayUnavailabilities(day)}
                                    eachDayOfSelectedScheduleInfo={eachDayOfSelectedScheduleInfo}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }

}

export default Scheduler;

const getUtc = (date, dateUtc) => {
    let utc = differenceInHours(date, dateUtc)
    if (utc > 12) {
        utc -= 24;
    }
    if (utc < -12) {
        utc += 24;
    }
    if (utc >= 0) {
        utc = `+${utc.toString().padStart(2, '0')}`;
    }
    if (utc < 0) {
        utc = `-${Math.abs(utc).toString().padStart(2, '0')}`
    }
    return utc
}
