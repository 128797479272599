const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioContext = AudioContext ? new AudioContext() : null;

const rootMeanSquare = (samples) => {
    const sumSq = samples.reduce((sumSq, sample) => {
        return (sumSq + sample * sample)
    }, 0);
    return Math.sqrt(sumSq / samples.length);
};

export const pollAudioLevel = async(track, onLevelChanged) => {
    if(!audioContext) {
        return
    }

    await audioContext.resume();

    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 1024;
    analyser.smoothingTimeConstant = 0.5;

    const stream = new MediaStream([track.mediaStreamTrack]);
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    const samples = new Uint8Array(analyser.frequencyBinCount);
    
    let level = null;

    requestAnimationFrame( function checkLevel() {
        analyser.getByteFrequencyData(samples);
        const rms = rootMeanSquare(samples);
        const log2Rms = rms && Math.log2(rms);

        const newLevel = Math.ceil(10 * log2Rms / 8);
        if(level !== newLevel) {
            level = newLevel;
            onLevelChanged(level);
        }

        if(track.mediaStreamTrack.readyState === 'live') {
            requestAnimationFrame(checkLevel)
        } else {
            console.log("exited");
        };
    })
}