import React from 'react'
import { format, parseISO } from 'date-fns';

import RadioButtons from '../../../../common/radioButton/radioButton'
import Topic from "../../../../common/topic";
import { UTCtoTimeZone } from './UTCtoTimeZone'

export default (props) => {


  const closestDate = props.appointment.closestDate

  const initialDate = closestDate ? '' : format(parseISO(props.appointment.initialDate), 'dd/MM/yyyy')
  const finalDate = closestDate ? '' : format(parseISO(props.appointment.finalDate), 'dd/MM/yyyy')
  const startTime = closestDate ? '' : UTCtoTimeZone(props.appointment.startTime)
  const endTime = closestDate ? '' : UTCtoTimeZone(props.appointment.endTime)
  const daysOfWeek = props.appointment.daysOfWeek
  
  return (
    <>
      <Topic
        title={`Um paciente solicitou um agendamento em 
            ${props.appointment.medicalAppointment?.speciality.name ?
                 props.appointment.medicalAppointment?.speciality.name :
                 props.appointment.descriptions
                }
             `}
        fontSize="14px"
        marginTop="20px"
      />

      { closestDate && 
        <Topic
          title={`O Paciente deseja ser atendido na data mais próxima.`}
          fontSize="14px"
          marginTop="20px"
        />
      }

      { !closestDate &&
        <>
          <Topic
            title='O Paciente deseja ser atendido entre os dias:'
            fontSize="14px"
            marginTop="20px"
          />
          <div>
            { initialDate } e { finalDate }
          </div>
          <Topic
            title='E prefere os seguintes dias da semana:'
            fontSize="14px"
            marginTop="20px"
          />
          <div>
            {daysOfWeek}
          </div>
          <Topic
            title='O Horário solicitado foi:'
            fontSize="14px"
            marginTop="20px"
          />
          <div>
            Entre { startTime } e { endTime }
          </div>
        </>
      }
      
      <RadioButtons
        disabled={props.disabled}
        label="Existe um horário disponível?"
        width="113px"
        onChange={(e) => props.choice(e.target.value)}
      />
      
    </>
  )
}