export const addSecretary = (payload) => {
    return ({
        type: "ADD_SECRETARY",
        payload
    })
}

export const deleteSecretary = (payload) => {
    return ({
        type: "DELETE_SECRETARY",
        payload
    })
}

export const updateSecretary = (payload) => {
    return ({
        type: "UPDATE_SECRETARY",
        payload,
    })
}

export const openAddModal = (payload) => {
    return ({
        type: "OPEN_ADD_MODAL_SECRETARIES",
        payload
    })
}

export const alterActive = (payload) => {
    return ({
        type: "ALTER_ACTIVE",
        payload
    })
}

export const getSecretariesList = () => {
    return ({
        type: "GET_SECRETARIES_LIST"
    })
}

export const getDoctorsList = () => {
    return ({
        type: "GET_DOCTORS_LIST"
    })
}


export const saveSecretariesList = (payload) => {
    return ({
        type:"SET_SECRETARIES_LIST",
        payload
    })
}

export const saveDoctorsList = (payload) => {
    return ({
        type:"SET_DOCTORS_LIST",
        payload
    })
}

export const stopWaitingData = () => {
    return ({
        type: "STOP_WAITING_SECRETARY_DATA",
    })
}