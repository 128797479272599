import React from 'react'
import { View, Text } from '@react-pdf/renderer'

import { styles } from '../styles'

export const DoctorData = ({documentPayload}) => {

    const { 
        doctorName, 
        crm, 
        crmUf, 
        localDeAtendimento, 
        CNES, 
        address,
        neighborhood,
        city,
        addressUf,
        phone,
        emission
    } = documentPayload

    return(
        <View style={styles.margined} wrap={false}>
            <Text style={styles.text}>
                Nome do(a) Médico(a): <Text style={styles.textBold}>{doctorName}</Text>, 
                CRM: <Text style={styles.textBold}>{crm}</Text>, 
                UF: {crmUf}
            </Text>
            
            { localDeAtendimento && CNES &&
                <Text style={styles.text}>
                    Local de Atendimento: {localDeAtendimento}, CNES: {CNES}
                </Text>
            }
            <Text style={styles.text}>
                Endereço: {address} 
                { neighborhood && 
                    <Text>, Bairro: {neighborhood}</Text> 
                }
            </Text>

            <Text style={styles.text}>
                Cidade: {city}, UF: {addressUf}, Telefone: {phone}
            </Text>
            <Text style={styles.text}>
                Data de Emissão: <Text style={styles.textBold}>{emission}</Text>
            </Text>
        </View>
    )
}