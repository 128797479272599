import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Topic from '../../../../common/topic';
import List from './doctorsList';
import Pagination from '../../../../common/pagination';
import SnackBar from '../../../../common/snackbar';

import * as asyncActions from '../../../../common/store/actions/async.action';

const useStyles = makeStyles({
    screen: {
        backgroundColor: '#f7f8f9',
        height: 'calc(100vh - 72px)',
        maxHeight: "100%",
        width: "100%",
        padding: '30px',
        boxSizing: 'border-box',
        overflowY: 'hidden',
    },
    scroll: {
        maxHeight: "100%",
        marginTop: '4vh',
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CDCED9',
            borderRadius: '5px'
        }
    }
})

const Doctors =(props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {secretaryId} = JSON.parse(window.localStorage.getItem("secData"));
    const doctorsList = useSelector( (state) => state.SecretariesReducer.doctors );
    const error = useSelector((state) => state.AsyncReducer.error);

    const [doctors, setDoctors] = useState([]);

    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const firstItem = (page*itemsPerPage)-(itemsPerPage);
    const lastItem = (firstItem + itemsPerPage);

    useEffect( () => {
        dispatch(asyncActions.getDoctorsList(secretaryId));
        // eslint-disable-next-line
    },[]);

    useEffect( () => {
        if(doctorsList) {
            const slicedList = doctorsList.slice(firstItem, lastItem);
            setDoctors(slicedList.map(i => ({ ...i, name: i.provider.user.name })))
        };
        // eslint-disable-next-line
    }, [doctorsList])

    return (
        <div className={classes.screen}>
            <div className={classes.table}>
                <div className="side-by-side">
                    <Topic
                        title={props.title}
                        subtitle={props.subtitle}
                        marginTop="0px"
                        subMarginTop="11px"
                    />
                </div>
                <div className={classes.scroll}>
                    <div>
                        <List 
                            fullList={doctors}
                            firstHeader="Nome"
                            firstParam="name"
                            onClick={props.onClick}
                        />
                    </div>
                </div>
            </div>
            <Pagination 
                page={page}
                setPage={setPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                firstItem={firstItem}
                lastItem={lastItem}
                length={doctors.length}
            />
            {error ?
                <SnackBar />
                :
                null
            }
        </div>
    );
}

export default Doctors;
