import React from "react";
import { useSelector, useDispatch } from 'react-redux';


import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';

import CancelSlot from './cancelSlot';
import DeactivateSlot from './deactivateSlot';

import * as scheduleActions from '../../../../common/store/actions/schedule.action';

// Material UI Styling

const useStyles = makeStyles( {
  root: {
    width: '30%',
    maxWidth: '500px',
    minWidth: '400px',
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  }
})


// Component

const ModalFullSchedule = (props) => {

  const classes = useStyles(props);
  const dispatch = useDispatch();

// Opening and Closing the Modal
  const open = useSelector( (state) => state.ScheduleReducer.openSlotModal);
  const {
    medicalAppointmentId,
    timeStamp,
    status,
    day,
    duration,
    scheduleGroupProviderId,
    scheduleHasProviderId,
    scheduleGroupEmployeeId,
    scheduleHasEmployeeId,
    unavailabilityProviderId,
    unavailabilityEstablishmentId,
  } = useSelector( (state) => state.ScheduleReducer.slotModalData);

  const handleClose = () => {
    if(props.keepState) {
      props.keepState(false)
    };
    
    dispatch(scheduleActions.updateCalendarFront(false));
    dispatch(scheduleActions.openSlotModal({open:false}));
  };

  const whatSlotToRender = (isConsultation) => {
    if(isConsultation) return <CancelSlot handleClose={handleClose} medicalAppointmentId={medicalAppointmentId}/>
    return (
      <DeactivateSlot 
        handleClose={handleClose}
        scheduleHasProviderId={scheduleHasProviderId}
        scheduleGroupProviderId={scheduleGroupProviderId}
        scheduleGroupEmployeeId={scheduleGroupEmployeeId}
        scheduleHasEmployeeId={scheduleHasEmployeeId}
        timeStamp= {timeStamp}
        day= {day}
        duration= {duration}
        status={status}
        unavailabilityProviderId={unavailabilityProviderId}
        unavailabilityEstablishmentId={unavailabilityEstablishmentId}
      />
    )
  }

  return (
      <Dialog 
          open={open}
          onClose={handleClose} 
          aria-labelledby="form-dialog-title" 
          classes={{
          paper: classes.root
          }}
          TransitionComponent={Grow}
          transitionDuration={{enter: 200, exit: 0}}
      >
        {whatSlotToRender(medicalAppointmentId)}
      </Dialog>
  );
};

export default ModalFullSchedule;