import React, { useState, Fragment } from 'react'
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { green, red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from './../button'

const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: "#F7F8F9",
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  root: {
    width: '95%',
    padding: '3vh 0',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 600
    }
  },

  table: {
    marginBottom: '30px',
    "& tbody": {
      backgroundColor: '#FFF'
    }
  },

}));

export default props => {
  const classes = useStyles()

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [indexToDelete, setIndexToDelete] = useState(null)

  const surgicalProcedures = useSelector((state) => state.ConsultationReducer.surgicalProcedure);

  const handleClickDelete = (index) => {
    setIndexToDelete(index)
    setOpenConfirmDeleteModal(true)
  }

  const handleClickConfirmDeleteModal = (confirm) => {
    if (!confirm) {
      setIndexToDelete(null)
      setOpenConfirmDeleteModal(false)
      return
    }
    if (props.onDelete) props.onDelete(indexToDelete)
    setOpenConfirmDeleteModal(false)
  }

  const handleClickBudget = row => {
    if (props.openBudget) props.openBudget({
      open: true,
      procedureName: row.surgicalProcedure.name,
      procedureCode: row.surgicalProcedure.code,
      budget: row.surgicalProcedure.budget
    })
  }

  return (
    <Fragment>
      <Fragment>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Procedimento</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surgicalProcedures.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {row.surgicalProcedure.code}
                  </TableCell>
                  <TableCell>
                    {row.surgicalProcedure.name}
                  </TableCell>
                  <TableCell>
                    {
                      (!Boolean(row.surgicalProcedure.budget) || (row.surgicalProcedure.budget.hasOwnProperty('finish') && row.surgicalProcedure.budget?.finish === false)) &&
                      <Tooltip title="Orçamento pendente" arrow placement="left">
                        <IconButton onClick={() => handleClickBudget(row)} >
                          <MonetizationOnOutlinedIcon style={{ color: green[500] }} />
                        </IconButton>
                      </Tooltip>
                    }
                    <Tooltip title="Remover" arrow placement="right">
                      <IconButton onClick={() => handleClickDelete(index)} >
                        <DeleteIcon style={{ color: red[500] }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
      {/* Modal de confirmação quando usuário quer deletar o orçamento */}
      <Fragment>
        <Dialog open={Boolean(openConfirmDeleteModal)}>
          <DialogTitle>Tem certeza que deseja finalizar este orçamento?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>Deseja deletar orçamento?</b>.<br />
              Se você optar por deletar este orçamento, perderá tudo que foi salvo nele.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClickConfirmDeleteModal(false)} backgroundColor="transparent" color="#F9B233" border="1px solid #F9B233">
              Não
            </Button>
            <Button onClick={() => handleClickConfirmDeleteModal(true)} color="white">
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    </Fragment>
  )
}
