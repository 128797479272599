 const INITIAL_STATE = {
    openStart: false,
    success: false,
    openFinish: false,
    consultationData: {},
    data: [],
    medicalHistory: [],
    statusConsultation: "not initialized",
  };
  
  export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
      case "OPEN_START_CONSULTATION_MODAL":
        return {
          ...state,
          openStart: action.payload,
        };
      case "OPEN_FINISH_CONSULTATION_MODAL":
        return {
          ...state,
          openFinish: action.payload,
        };
      
      case "STORE_START_CONSULTATION_DATA":
        return {
          ...state,
          data: action.payload,
        };
      
      case "SET_CONSULTATION_DATA":
        return {
          ...state,
          consultationData: { ...state.consultationData, ...action.payload },
        };
      case "CLEAR_CONSULTATION_DATA":
        return {
          ...state,
          consultationData: {},
        };
      
      case "UPDATE_STATUS_CONSULTATION":
        return {
          ...state,
          statusConsultation: action.payload,
        };
        default:
            return state;
    };
  };
   