import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';


import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';

import CreateSchedule from './createSchedule';
import CreateDaysOfWeek from './createDaysOfWeek';
import CreateWorkTime from './createWorkTime';
import Duration from './duration';
import ModalSuccess from './proposeSuccess';

import Button from '../../../../common/button';

import * as scheduleActions from '../../../../common/store/actions/schedule.action';
import { differenceInMinutes } from "date-fns";

// Material UI Styling

const useStyles = makeStyles( {
  root: {
    width: '39%',
    maxWidth: '500px',
    minWidth: '480px',
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  }
})


// Component

const ModalFullSchedule = (props) => {

// Opening and Closing the Modal
  const open = useSelector( (state) => state.ScheduleReducer.openCreateScheduleModal);
  const [step, setStep] = useState(0);
  const [weekDaysAvailable, setWeekDaysAvailable] = useState([]);
  const classes = useStyles(props);
  const dispatch = useDispatch();

  const [workTime, setWorkTime] = useState([]);
  const [durationTime, setDurationTime] = useState([]);

  const startShift = () => {for (let item of workTime){
      return item.beginShift
    }}
  const finalShift = () => {
    for (let item of workTime){
      return item.endShift
    }
  }



  const diffInMinutes = differenceInMinutes(finalShift(), startShift())
  
  const durationNumber = Number(durationTime)

  const invalidDuration = durationNumber > diffInMinutes || durationNumber < 0 || isNaN(durationNumber)


  const handleClickOpen = () => {
    dispatch(scheduleActions.openCreateScheduleModal(true));
  };

  const handleClose = () => {
    setStep(0);
    if(props.keepState) {
      props.keepState(false)
    };
    dispatch(scheduleActions.cleanSchedule());
    dispatch(scheduleActions.openCreateScheduleModal(false));
  };

  const handleNext = () => {
    setStep( (prevStep) => prevStep + 1);
  }
  

  const whatToRender = (flowState) => {
      switch (flowState) {
        case 1:
          return (
            <CreateDaysOfWeek
              setStep={setStep}
              step={step}
              handleClose={handleClose}
              handleNext={handleNext}
              daysAvailability={weekDaysAvailable}
            />
          );

        case 2:
            return (
              <CreateWorkTime
                setStep={setStep}
                step={step}
                handleClose={handleClose}
                handleNext={handleNext}
                onChange={setWorkTime}
                
              />
            )

        case 3:
            return (
              <Duration 
                setStep={setStep}
                step={step}
                handleClose={handleClose}
                handleNext={handleNext}
                onChange={setDurationTime}
                durationInvalid={invalidDuration}
              />
            );

        case 4:
          return (
            <ModalSuccess
                setStep={setStep}
                step={step}
                handleClose={handleClose}
                handleNext={handleNext}
            />);

        default:
          return (
            <CreateSchedule
                setStep={setStep}
                step={step}
                handleClose={handleClose}
                handleNext={handleNext}
                getDays={setWeekDaysAvailable}
            />
          );
      }
  }

  return (
    <div>
        <Button
            width={props.buttonWidth ? props.buttonWidth : "142px"}
            border={props.buttonBorder ? props.buttonBorder :"1px solid #CDCED9"}
            backgroundColor={props.buttonBackground ? props.buttonBackground : "#FFFFFF"}
            color={props.buttonColor ? props.buttonColor : "#6B6C7E"}
            className="modal-trigger space-above-item-less"
            data-target="modal-register-consultation"
            onClick={handleClickOpen}
        >
            {props.text ? props.text : "Adicionar"}
        </Button>
        <Dialog 
            open={open}
            onClose={handleClose} 
            aria-labelledby="form-dialog-title" 
            classes={{
            paper: classes.root
            }}
            TransitionComponent={Grow}
            transitionDuration={{enter: 200, exit: 0}}
        >
            {whatToRender(step)}
      </Dialog>
    </div>
  );
};

export default ModalFullSchedule;