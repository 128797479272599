const INITIAL_STATE = {
  queue: [],
  queueInfo: {
    countPatients: 0,
    countDoctor: 0,
    doctorPerPatient: 0,
    tme: 0
  },
  isAvailable: false,
  historic: []
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_QUEUE":
      return { 
        ...state,
        queue: action.payload,
      };
    case "SET_QUEUE_INFO":
      return { 
        ...state,
        queueInfo: action.payload,
      };
    case "SET_AVAILABLE":
      return { 
        ...state,
        isAvailable: action.payload,
      };
      case "SET_HISTORIC":
        return { 
          ...state,
          historic: action.payload,
        };
    default:
      return state;
  }
}
