import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../../../common/button';
import Avatar from '../../../common/avatar';
import Loading from '../../../common/loading';
import SnackBar from '../../../common/snackbar';

import * as modalActions from '../../../common/store/actions/consultation.action';
import * as asyncActions from '../../../common/store/actions/async.action';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';
import * as scheduledExamsService from '../../../services/scheduledExams'
import { handlePatientName } from "../../../common/handlePatientName";
// Material UI Styling

const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '55%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#6B6C7E',
    fontSize: '14px',
    marginTop: '16px'
  },
  responseToPatient: {
      fontSize: '18px',
  },
  success: {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
      fontWeight: 600,
  },
  box: {
      display: "inline-block",
      marginRight: "3%",
      borderRadius: "20px",
      padding: '2% 3%',
      marginBottom: '2%',
      backgroundColor: "#EEEEEE",
      color: "#424242",
  },
  patient: {
    display: 'flex',
    marginTop: '5%',
  },
  text: {
    fontSize: '18px',
    color: "#272833",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginLeft: '2%',
    "& > .cpf":{
        color: "#6B6C7E",
        fontSize: '14px',
    }
  },
})

// Component

const StartExamAttedanceModal = (props) => {
    const classes = useStyles(props);

    const dispatch = useDispatch();
    const history = useHistory();

    const appointmentRequestId = props.consultation?.id

    const data = useSelector((state) => state.ScheduledExamReducer);
    
    const open = data.openStart;

    const [isLoading, setLoading] = useState(false);

    const handleClose = () => {
        localStorage.removeItem('appointmentLocation')
        localStorage.removeItem('patientId')
        localStorage.removeItem('twilio')
        localStorage.removeItem('appointId')
        dispatch(modalActions.openStartModal(false));
    }

    const handleStartConsultation = async () => {
        setLoading(true);
            const res = await scheduledExamsService.initExamAppointment(appointmentRequestId)

            if(res.error){
                dispatch(
                  asyncActions.requestFailure(
                    "Houve um problema ao iniciar a consulta. Tente novamente, por favor."
                  )
                ); 
              }

            if (res) {
                setLoading(false);
                history.push('/atendimento/exame');
                dispatch(modalActions.openStartModal(false));
            }
    }

    return (
            <div>
            <SnackBar />
            <Dialog 
                open={open}
                onClose={handleClose} 
                aria-labelledby="form-dialog-title" 
                classes={{
                paper: classes.root
                }}
                TransitionComponent={Grow}
                transitionDuration={{enter: 200, exit: 0}}
            >
                <div className={classes.header}>
                <DialogTitle>
                    Deseja iniciar o atendimento desse paciente?
                </DialogTitle>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                </div>
                <DialogContent dividers>
                <form>
                    <div className="modal-body">
                    <div className={classes.lettering}> Paciente</div>
                        <div className={classes.patient}>
                            <Avatar />
                            <div className={classes.text}>
                                <span>{handlePatientName(data.data.patient)}</span>
                                <span className="cpf">{data.data.patient?.user?.email}</span>
                            </div>
                        </div>
                        <div className={classes.lettering}> Exames</div>
                        <div className={classes.patient}>
                        
                            <div className={classes.text}>
                                <span>{data.data.allServicesProvidedDescription}</span>
                            </div>
                        </div>
                    </div>
                    <DialogActions className={classes.extraMargin}>
                        {isLoading ?
                            <Loading width="50px" />
                            :
                            <>
                                <Button 
                                    onClick={handleClose}
                                    width="120px"
                                    border="1px solid #CDCED9"
                                    backgroundColor="#FFFFFF"
                                    color="#6B6C7E"
                                    margin="0 20px 0 0"
                                >
                                    Não
                                </Button>
                                <Button
                                    width="120px"
                                    border="1px solid #DF9000"
                                    backgroundColor="#FFFFFF"
                                    color="#DF9000"
                                    onClick={handleStartConsultation}
                                >
                                    Sim
                                </Button>
                            </>
                        }
                    </DialogActions>
                </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default StartExamAttedanceModal;