export default Object.freeze({
  BUDGET_SAVED: 'BUDGET_SAVED',
  AWAITING_BUDGET: 'AWAITING_BUDGET',
  CHOOSE_A_BUDGET: 'CHOOSE_A_BUDGET',
  AWAITING_DATE: 'AWAITING_DATE',
  CONFIRM_SCHEDULE: 'CONFIRM_SCHEDULE',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  PENDING_TOKEN_VALIDATION: 'PENDING_TOKEN_VALIDATION',
  OK: 'OK',
  CANCELED: 'CANCELED',
  FINISHED: 'FINISHED',
  IN_PROGRESS: 'IN_PROGRESS',
})
