import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme) => ({
  root: {
        width: '100%',
        padding: '0',
        fontWeight: '400',
  },
  item: {
        height: '64px',
        backgroundColor: "#FFFFFF",
        border: '1px solid #E7E7ED',
        padding: '0',
  },
  combo: {
    display: 'flex',
    width: '95%',
    margin: 'auto',
    height: '100%',
  },
  icons: {
    display: 'flex',
    width: '7%'
  },
  icon: {
    width: '48px'
  },
  headers: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    width: "88%",
    marginLeft: "2.5%",
    marginBottom: "10px",
    color: "#6B6C7E"
  },
  line: {
    height: '40px',
    width: "100%",
    margin: "auto",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },
  code: {
    width: props => props.firstColumnWidth ? props.firstColumnWidth : '100%',
  },
}));



const ListDisplay = (props) => {

  const classes = useStyles(props);

  return (
    <List className={classes.root}>
      <div className={classes.headers}>  
        <div className={classes.code}>  
          <span id={[props.firstHeader]}>
            {props.firstHeader}
          </span>
        </div>
      </div>
      {props.fullList.map((value, index) => {
        const labelId = `checkbox-label-${value[props.firstParam]}-${value[props.secondParam]}-${value[props.thirdParam]}`;
        return (
          <ListItem className={classes.item} key={labelId} role={undefined} dense button onClick={() => props.onClick(value)}>
            <div className={classes.combo}>  
              <div className={classes.line}>  
                <div className={classes.code}>  
                  <span id={value[props.firstParam]}>
                    {`${value[props.firstParam]}, ${value[props.secondParam]}${value[props.thirdParam] ? ", "+value[props.thirdParam] : ""}`}
                  </span>
                </div>
              </div>
            </div> 
          </ListItem>
        );
      })}
    </List>
  );
}

export default ListDisplay;