import React, { useState, useEffect } from "react";
import api from "../../../../services/api";
import { useDispatch, useSelector } from "react-redux";

import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grow } from "@material-ui/core";

import Input from "../../../../common/input";
import InputPassword from "../../../../common/input-password";
import Loading from "../../../../common/loading";
import Button from "../../../../common/button";
import Snack from "../../../../common/snackbar";
import Check from "../../../../assets/check-circle-full.png";

import maskCpf from "../../../../common/mask/cpf";

import empty from "../../../../common/validators/empty";
import emailValidator from "../../../../common/validators/email";
import cpfValidator from "../../../../common/validators/cpf";

import * as secretaryActions from "../../../../common/store/actions/secretaries.action";
import * as asyncActions from "../../../../common/store/actions/async.action";

const useStyles = makeStyles({
  root: {
    width: "40%",
    maxWidth: "1000px !important",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: (props) => props.backgroundColor,
  },
  add: {
    display: "flex",
    alignItems: "flex-end",
  },
  list: {
    backgroundColor: "#F7F8F9",
    borderRadius: "6px",
    marginTop: "22px",
  },
  lettering: {
    fontSize: "14px",
    color: "#6B6C7E",
  },
  letteringError: {
    backgroundColor: "#FFFFFF",
    color: "#DA1414",
    fontSize: "0.75rem !important",
    fontWeight: "400 !important",
  },
  extraMargin: {
    margin: "50px 0 21px 0",
    padding: 0,
  },
  success: {
    backgroundColor: "#EDF9F0",
    color: "#287D3C",
    fontWeight: 600,
  },
});

const AddSecretary = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { email } = JSON.parse(window.localStorage.getItem("userData"));
  const providerId = window.localStorage.getItem("Prov-Vida-Connect");
  const establishmentName = window.localStorage.getItem("estabName")
  const establishmentId = window.localStorage.getItem("establishmentId")
  const employeeId = window.localStorage.getItem("employeeId")

  const openModal = useSelector(
    (state) => state.SecretariesReducer.openAddModal
  );
  const loadingData = useSelector((state) => state.SecretariesReducer.loading);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [passwordValidation, setPasswordValidation] = useState(false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [secretaryEmail, setEmail] = useState("");
  const [secretaryEmailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [cpf, setCpf] = useState("");
  const [cpfError, setCpfError] = useState("");

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  useEffect(() => {
    setLoading(loadingData);
  }, [loadingData]);

  // const deleteAddress = (index) => {
  //     const newList = addressList.filter((el,i) => {
  //         return i !== index
  //     })
  //     setAddAddress(newList);
  // }

  function handleNext(e) {
    e.preventDefault();
    let error = [name, secretaryEmail].reduce(
      (error, field) => error || Boolean(empty(field).typeError),
      false
    );
    let invalidEmail = emailValidator(secretaryEmail).error;

    if (Boolean(cpfError)) return

    if (error || invalidEmail) {
      if (empty(name)) setNameError(empty(name).typeError);
      if (empty(secretaryEmail)) setEmailError(empty(secretaryEmail).typeError);
      if (emailValidator(secretaryEmail))
        setEmailError(emailValidator(secretaryEmail).typeError);
    } else {
      setPasswordValidation(true);
    }
  }

  const handleClose = () => {
    setName("");
    setNameError("");
    setEmail("");
    setEmailError("");
    setCpf("");
    setCpfError("");
    setPassword("");
    setPasswordError("");
    setPasswordValidation(false);
    setLoading(false);
    setSuccess(false);
    setOpen(false);
    dispatch(secretaryActions.openAddModal(false));
  };

  const checkPassword = async (email, password) => {
    const res = await api({
      data: {
        query: `mutation($input: LoginProviderInput!) {
                        loginProvider(input: $input){
                            sessionToken
                        }
                    }`,
        variables: {
          input: {
            email,
            password,
          },
        },
      },
    });
    if (res.data.errors) {
      dispatch(asyncActions.requestFailure("A senha não confere."));
      setPasswordError("A senha não confere.");
      return false;
    } else {
      window.localStorage.setItem(
        "Token-Vida-Connect",
        res.data.data.loginProvider.sessionToken
      );
      return true;
    }
  };

  const handleSave = async (e) => {
    setPasswordError("");
    dispatch(asyncActions.requestSent());
    setLoading(true);
    try {
      const checked = await checkPassword(email, password);
      if (checked) {
        const payload = { name, email: secretaryEmail, cpf: cpf.replace(/\.|-/gm,'') };
        let res = ''
        if (establishmentName === "Particular") {
          res = await dispatch(asyncActions.inviteSecretaryForDoctor({
            ...payload,
            doctorProviderId: providerId,
          }));
        } else {
          res = await dispatch(asyncActions.inviteSecretaryForClinic({
            ...payload,
            doctorProviderId: providerId,
            establishmentId,
            employeeId
          }))
        }

        if (res) { 
          setSuccess(true);
          secretaryActions.addSecretary(payload);
          // window.location.reload();
        }
      }
      setLoading(false);
    } catch (error) {
      dispatch(
        asyncActions.requestFailure(
          "Houve um erro de conexão com nosso servidor."
        )
      );
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.root,
        }}
        TransitionComponent={Grow}
        transitionDuration={{ enter: 200, exit: 0 }}
      >
        <div
          className={`${classes.header} ${success ? classes.success : null}`}
        >
          <DialogTitle>
            {success ? (
              <div>
                <img src={Check} alt="success" />
                <span> Secretária cadastrada com sucesso</span>
              </div>
            ) : (
              <span>Cadastrar Secretária</span>
            )}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          {passwordValidation ? (
            <form>
              <DialogContentText className={classes.lettering}>
                Confirme sua senha para cadastrar a nova secretária.
              </DialogContentText>
              {loading ? (
                <Loading width="30px" />
              ) : (
                <>
                  <InputPassword
                    label="Senha"
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={passwordError}
                    fullWidth
                  />
                </>
              )}
              <DialogActions className={classes.extraMargin}>
                {success ? (
                  
                  <Button
                    onClick={() => {
                      window.location.reload() 
                      handleClose()
                    }}
                    width="120px"
                    border="1px solid #DF9000"
                    backgroundColor="#FFFFFF"
                    color="#DF9000"
                  >
                    Ok
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={handleClose}
                      width="120px"
                      border="1px solid #CDCED9"
                      backgroundColor="#FFFFFF"
                      color="#6B6C7E"
                      margin="0 20px 0 0"
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleSave}
                      width="120px"
                      border="1px solid #DF9000"
                      backgroundColor="#FFFFFF"
                      color="#DF9000"
                    >
                      Salvar
                    </Button>
                  </>
                )}
              </DialogActions>
            </form>
          ) : (
            <form>
              <div className="modal-body">
                <Input
                  label="Nome Secretária"
                  placeholder="Nome da Secretária"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => setNameError(empty(name).typeError)}
                  error={nameError}
                />

                <Input
                  label="Email Secretária"
                  placeholder="Email da Secretária"
                  type="email"
                  value={secretaryEmail}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() =>
                    setEmailError(emailValidator(secretaryEmail).typeError)
                  }
                  error={secretaryEmailError}
                />

                <Input
                  label="CPF Secretária"
                  placeholder="CPF da secretária"
                  type="text"
                  value={cpf}
                  onChange={(e) => setCpf(maskCpf(e.target.value))}
                  onBlur={() => setCpfError(cpfValidator(cpf).typeError)}
                  error={cpfError}
                />
              </div>
              <DialogActions className={classes.extraMargin}>
                <Button
                  onClick={handleClose}
                  width="120px"
                  border="1px solid #CDCED9"
                  backgroundColor="#FFFFFF"
                  color="#6B6C7E"
                  margin="0 20px 0 0"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleNext}
                  width="120px"
                  border="1px solid #DF9000"
                  backgroundColor="#FFFFFF"
                  color="#DF9000"
                >
                  Próximo
                </Button>
              </DialogActions>
            </form>
          )}
        </DialogContent>
        <Snack />
      </Dialog>
    </div>
  );
};

export default AddSecretary;
