import React from "react";

import StepByStep from "../../../../common/stepByStep/stepByStep";
import Topic from "../../../../common/topic";
import AddressModal from '../../../../common/modal/address/registterAddress';

import withoutAddressImg from "../../../../assets/without-address-img.png";

export default (props) => (
  <div>
    <StepByStep
      stepper
      steps={props.steps}
      activeStep={props.activeStep}
      handleBack={props.previousStep}
      handleAdvance={props.nextStep}
      disableForward={true}
    />
    <div className="form-layout">
      <Topic
        classesNameCol="topic-datas firt-topic"
        title="Endereços de Atendimento"
        subtitle="Cadastre aqui seus endereços de atendimento"
        classesNameSubtitle="text-grey"
      />

      <div className="without-address space-above-item-less">
        <div className="without-address-layout">
            <img
              className="without-address-img"
              src={withoutAddressImg}
              alt=""
            />
            <Topic
              classesNameCol="topic-datas"
              title="Nenhum endereço cadastrado"
              subtitle="Adicione um endereço para comerçar."
              classesNameSubtitle="text-grey"
            />
            <div  style={{marginTop: '16px'}}>
              <AddressModal/>
            </div>
            </div>
      </div>
    </div>
    <StepByStep
      activeStep={props.activeStep}
      handleBack={props.previousStep}
      handleAdvance={props.nextStep}
      disableForward={true}
      backgroundColor="#FFFFFF"
    />
  </div>
);
