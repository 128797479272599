import React, {useState, useEffect, memo} from "react";
import graphqlApi from '../../../../services/api'

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Topic from "../../../../common/topic";
import Input from "../../../../common/input";
import SearchBar from '../../../../common/searchBar/searchBar';
import InputFile from "../../../../common/fileInput";
import {districts} from '../../../../assets/districts';
import empty from "../../../../common/validators/empty";
import inputFileValidator, { validationFileType } from '../../../../common/validators/fileInput'

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  checkbox:{
    marginTop: '20px',
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: '#F9B233'
    }
  }
})

const ProfessionDoctor = (props) => {
  const classes = useStyles()
  const { generalPractitioner, setGeneralPractitioner } = props

  const [listSpecialities, setListSpecialities] = useState([])
  useEffect(() => {
    graphqlApi.post('/', {
      query: `
        query {
            listSpecialities(input: {limit:10000} ) {
                rows {
                    id
                    name
                    group
                    requireRqe
                }
            }
        }
    `,
      
    }).then(res => {
      const rows = res.data.data.listSpecialities.rows
      const getGeneralPractitionerInList =  rows.filter(row => row.name === "Clínica Geral")[0]
      setGeneralPractitioner((state) => {
        return {
          ...getGeneralPractitionerInList,
          selected: state.selected,
        }
      })
      setListSpecialities(rows)
    }
    ).catch(err => console.log(err))
  }, [setGeneralPractitioner])

  useEffect( () => {
    if(!props.specialty1.requireRqe){
      props.setSpecialty1Titles({ name: "" })
      props.setRqe1('')
    }
    // eslint-disable-next-line
  },[props.specialty1])

  useEffect( () => {
    if(!props.specialty2.requireRqe){
      props.setSpecialty2Titles({ name: "" })
      props.setRqe2('')
    }

    // eslint-disable-next-line
  },[props.specialty2])

  const handleChangeDoctorIsGeneralPractitioner = () => {
    setGeneralPractitioner((state) => ({...state, selected: !generalPractitioner.selected }))
  }

  const handleChangeInputFile = (value, onChange, typesAccepted) => {
    if (!value) return onChange({name: ''})
  
    const typeIsAccpted =  validationFileType(value.type,typesAccepted)
    if (!typeIsAccpted) {
      onChange({name: ''})
      props.setOpenSyncSnackBar(true)
      props.setErrorMessageSyncSnackBar('O tipo do arquivo selecionado não é aceito para esse campo!')
      return
    } 
    return onChange(value)
  }

  const typesAcceptedSpecialty1Titles = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']
  const typesAcceptedSpecialty2Titles = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']

  return (
    <React.Fragment>
      <div className="row">
        <Topic
          classesNameCol="topic-datas middle-topics"
          title="Profissão"
          subtitle="Inclua os dados de sua profissão"
        />
      </div>

      <Input
        width="32%"
        label="Médico(a)"
        placeholder="Nome"
        type="text"
        value={props.profession}
        onChange={(e) => props.setProfession(e.target.value)}
        onBlur={() => props.setProfessionError(empty(props.profession))}
        error={props.professionError.typeError}
        dataTest="input-pf-profession"
      />
      <div className="file-input">
        <Input
          width="20%"
          label="CRM"
          placeholder="CRM"
          type="text"
          value={props.crm}
          onChange={(e) => props.setCrm(e.target.value)}
          onBlur={() => props.setCrmError(empty(props.crm))}
          error={props.crmError.typeError}
          dataTest="input-pf-crm"
        >
        </Input>
        <SearchBar
          sync
          placeholder="SP"
          width="70px"
          margin="30px 0 0 0"
          value={props.uf}
          onChange={props.setUf}
          onBlur={() => props.setUfError(empty(props.uf))}
          error={props.ufError.typeError}
          itemList={districts}
          iconDefault
          dataTest="input-pf-uf"
        />
      </div>
      <div className="file-input">
        <SearchBar
          sync
          type="specialities"
          width="30%"
          label="Especialidade 1"
          placeholder="Insira uma especialidade"
          value={props.specialty1.name}
          onChange={props.setSpecialty1}
          onBlur={() => props.setSpecialtyError(empty(props.specialty1))}
          error={props.specialtyError.typeError}
          itemList={listSpecialities}
          dataTest="input-pf-especialidade-1"
        />
        <Input
          width="20%"
          label="RQE"
          placeholder="RQE"
          disabled={!props.specialty1.requireRqe}
          type="text"
          value={props.rqe1}
          onChange={(e) => props.setRqe1(e.target.value)}
          onBlur={() => props.setRqeError(empty(props.rqe1))}
          error={props.specialty1.requireRqe && props.rqeError.typeError}
          dataTest="input-pf-rqe-1"
        />
        <InputFile
          id="specialty1Titles"
          width="50%"
          type="file"
          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .txt"
          disabled={!props.specialty1.requireRqe}
          label="Anexar - Certificado de Residência Médica ou Título de Especialista"
          subtitle="Formatos aceitos: png, jpg, jpeg, pdf, doc, docx e txt. Tamanho máximo: 25MB"
          onChange={(e) =>{
            handleChangeInputFile(e.target.files[0], props.setSpecialty1Titles, typesAcceptedSpecialty1Titles)}
          }
          value={props.specialty1Titles.name}
          onBlur={() =>
            props.setSpecialty1TitlesError(inputFileValidator(props.specialty1Titles, 25))
          }
          error={props.specialty1.requireRqe && props.specialty1TitlesError.typeError}
          dataTest="input-pf-titulo-de-especialidade-1"
        />
      </div>
      <div className="file-input">
      <SearchBar
          sync
          type="specialities"
          width="30%"
          label="Especialidade 2 (Opcional)"
          placeholder="Insira uma especialidade"
          value={props.specialty2.name}
          onChange={props.setSpecialty2}
          onBlur={() => {
            if(!props.specialty2.name) {
              props.setRqe2Error({ error: true, typeError: "" });
              props.setSpecialty2TitlesError({ error: true, typeError: "" });
            }
          }}
          itemList={listSpecialities}
          dataTest="input-pf-especialidade-2"
        />
        <Input
          width="20%"
          label="RQE"
          placeholder="RQE"
          disabled={!props.specialty2.requireRqe}
          type="text"
          value={props.rqe2}
          onChange={(e) => props.setRqe2(e.target.value)}
          onBlur={ () => {
              if(props.specialty2) {
                props.setRqe2Error(empty(props.rqe2));
              }
            }
          }
          error={props.specialty2.requireRqe && props.rqe2Error.typeError}
          dataTest="input-pf-rqe-2"
        />
        <InputFile
          id="specialty2Titles"
          width="50%"
          type="file"
          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .txt"
          disabled={!props.specialty2.requireRqe}
          label="Anexar - Certificado de Residência Médica ou Título de Especialista"
          subtitle="Formatos aceitos: png, jpg, jpeg, pdf, doc, docx e txt. Tamanho máximo: 25MB"
          onChange={(e) =>
            handleChangeInputFile(e.target.files[0], props.setSpecialty2Titles, typesAcceptedSpecialty2Titles)}
          onBlur={ () => {
              if(props.specialty2) {
                props.setSpecialty2TitlesError(inputFileValidator(props.specialty2Titles, 25));
              }
            }
          }
          value={props.specialty2Titles.name}
          error={props.specialty2.requireRqe && props.specialty2TitlesError.typeError}
          dataTest="input-pf-titulo-de-especialidade-2"
        />
      </div>
      <div className={classes.checkbox}>
      <FormControl component="fieldset">
        <FormControlLabel
          control={<Checkbox checked={generalPractitioner.selected} onChange={handleChangeDoctorIsGeneralPractitioner}  name="general-practitioner" />}
          label="Atender também como Clínico Geral. Selecionando essa opção, seu nome também aparecerá na busca por Clínicos Gerais na plataforma."
        />
      </FormControl>
      </div>
    </React.Fragment>
  );
};

export default memo(ProfessionDoctor);
