import { combineReducers } from "redux";

import RegisterConsultations from "./registerConsultations";
import Exams from "./exams";
import Proceedings from "./proceeding";
import OtherServices from "./otherServices";
import Address from "./address";
import PersonalDatas from "./personalDatas";
import NewServices from './newServices.reducer';
import SecretariesReducer from './secretaries.reducer';
import ScheduleReducer from './schedule.reducer';
import AsyncReducer from './async.reducer';
import UnitsReducer from './units.reducer';
import TriageReducer from './triage.reducer';
import ConsultationReducer from './consultation.reducer';
import ValidateToken from './validateToken.reducer'
import MedicalAppointments from './medicalAppointments.reducer'
import MedicalAppointmentRequestsReducer from './medicalAppointmentRequests.reducer'
import VirtuaReducer from './virtua.reducer'
import ScheduleRequestsReducer from './scheduleRequests.reducer'
import ExamAppointmentRequestsReducer from './examAppointmentRequests.reducer'
import ScheduledExamReducer from './scheduledExam.reducer'

export default combineReducers({
  RegisterConsultations,
  Exams,
  Proceedings,
  OtherServices,
  Address,
  PersonalDatas,
  NewServices,
  SecretariesReducer,
  ScheduleReducer,
  AsyncReducer,
  UnitsReducer,
  TriageReducer,
  ConsultationReducer,
  ValidateToken,
  MedicalAppointments,
  MedicalAppointmentRequestsReducer,
  VirtuaReducer,
  ScheduleRequestsReducer,
  ExamAppointmentRequestsReducer,
  ScheduledExamReducer
});
