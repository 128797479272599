import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import ErrorIcon from '@material-ui/icons/Error';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { format, parseISO } from 'date-fns';
import cpfMask from '../../../common/mask/cpf'
import telephoneMask from '../../../common/mask/telephone'
import Loading from '../../../common/loading';
import Pagination from '../../../common/pagination';
import SnackBar from '../../../common/snackbar';

import * as asyncActions from '../../../common/store/actions/async.action'

import { useSubscription } from '@apollo/react-hooks'
import { gql } from '@apollo/client'

const  QUEUE_DETAILS = gql`
    subscription {
      QueueDetails{
        countPatients,
        countDoctors,
        doctorPerPatient,
        tme
      }
    }
  `


const useStyles = makeStyles((theme) => ({
  root: {
        width: '100%',
        padding: '0',
        fontWeight: '400',
        paddingBottom: '60px',
  },
  headers: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingLeft: "2.5%",
    color: "#6B6C7E",
    boxSizing: 'border-box',
  },
  patient: {
    width: '30%',
    marginRight: '10px'
  },
  cpf: {
    width: '10%',
  },
  email: {
    width: '20%',
    marginLeft: '30px',
    marginRight: '10px',
    wordWrap: 'break-word'
  },
  telephone: {
    width: '10%',
    marginLeft: '30px',
    display: 'flex',
  },
  hour: {
    width: '12%',
    marginLeft: '30px',
    display: 'flex',
  },
  timeWaiting: {
    width: '10%',
    marginLeft: '30px',
    display: 'flex',
  },

  icon: {
    width: '30px',
    display: 'block',
    "& svg": {
      color: '#DA1414'
    }
  },

  combo: {
    display: 'flex',
    width: '100%',
    paddingLeft: '2.5%',
    height: '100%',
    boxSizing: 'border-box',
  },

  line: {
    height: '40px',
    width: "100%",
    margin: "auto",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },

  item: {
    height: '64px',
    backgroundColor: "#FFFFFF",
    border: '1px solid #E7E7ED',
    padding: '0',
    "&.preference": {
      backgroundColor: '#FEEFEF',
      border: '1px solid #F48989'
    }
  },

  pagination: {
    marginTop: '45px'
  }

}));

export default props => {
  const classes = useStyles()
  const dispatch = useDispatch()

  let queue = useSelector((state) => state.VirtuaReducer.queue)

  const { data } = useSubscription(QUEUE_DETAILS)

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const firstItem = (page*itemsPerPage)-(itemsPerPage);
  const lastItem = (firstItem + itemsPerPage);

  const getTimeWaiting = (date) => {
    const currentDate = new Date()
    date = new Date(date)

    const differenceInMinutes = Math.round((currentDate.getTime() - date.getTime()) /1000 / 60)
    if (differenceInMinutes >= 60) {
      const hour = `0${Math.floor(differenceInMinutes / 60)}`.slice(-2)
      const minutes =  `0${differenceInMinutes - hour * 60}`.slice(-2)
      return `${hour}h${minutes}min`
    }
    return `${differenceInMinutes}min`
  }

  useEffect(() => {
    const getQueue = async () => {
      setLoading(true)
      const { count } = await dispatch(asyncActions.virtuaGetQueue(itemsPerPage, page))
      setCount(count)
      setLoading(false)
    }
    getQueue()
    // eslint-disable-next-line
  }, [itemsPerPage, page])

  useEffect(() => {
    const getQueue = async () => {
      const { count } = await dispatch(asyncActions.virtuaGetQueue(itemsPerPage, page))
      setCount(count)
    }
    getQueue()
    // eslint-disable-next-line
  }, [data])

  return (
    <div>
      {loading && <Loading width="80px"/>}
      {!loading && (
        <>
          <List>
          <div className={classes.headers}>  
              <div className={classes.patient}>  
                <span>
                Nome do Paciente
                </span>
              </div>
              <div className={classes.cpf}>  
                <span>
                  CPF
                </span>
              </div>
              <div className={classes.email}>
                <span>
                  E-mail
                </span>
              </div>
              <div className={classes.telephone}>
                <span>
                  Telefone
                </span>
              </div>
              <div className={classes.hour}>
                <span>  
                  Horário de entrada
                </span>
              </div>
              <div className={classes.timeWaiting}>
                <span> 
                  Tempo de espera
                </span>
              </div>
              <div className={classes.icon}>
              <span>&nbsp;</span>
            </div>
            </div>

            
              
            {queue.map((patient, index) => {
              const formatedDate = format(parseISO(patient.createdAt), "HH:mm");
              const timeWaiting = getTimeWaiting(patient.createdAt)
              return (
                <ListItem 
                className={`${classes.item} ${patient.preference ? 'preference': ''}`} 
                key={index} 
                role={undefined} 
                dense 
                >  
                <div className={classes.combo}>  
                  <div className={classes.line}>
                    <div className={classes.patient}>  
                      <span>
                        {patient.patientName}
                      </span>
                    </div>  
                    <div className={classes.cpf}>  
                      <span >
                        {cpfMask(patient.patientCpf)}
                      </span>
                    </div>
                    <div className={classes.email}>
                      <span >
                        {patient.patientEmail} 
                      </span>
                    </div>
                    <div className={classes.telephone}>
                        <span>
                          {telephoneMask(patient.patientPhone)}
                        </span>
                    </div>
                    <div className={classes.hour}>
                        <span>
                          {formatedDate}
                        </span>
                    </div>
                    <div className={classes.timeWaiting}>
                        <div> 
                          {timeWaiting}
                        </div>
                    </div>
                    {patient.preference && (
                      // <div>
                        <div className={classes.icon}>
                          <ErrorIcon/>
                        </div>
                      // </div>
                    )}
                  </div>
                </div> 
              </ListItem>
              )})}
          </List>

          <div className={classes.pagination}>
          <Pagination 
              page={page}
              setPage={setPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              firstItem={firstItem}
              lastItem={lastItem}
              length={count}
              width="95%"
          />
          </div>
          <SnackBar/>
        </> 
      )}
    </div>
  )
}