const INITIAL_STATE = {
  openStart: false,
  success: false,
  openFinish: false,
  openDispensePatient: false,
  openConsent: false,
  openReferral: false,
  openSurgicalProcedure: false,
  openMedicalReport: false,
  countMedicalReport: 0,
  openPrescription: false,
  countPrescription: 0,
  openSickNote: false,
  countSickNote: 0,
  openExamRequest: false,
  countExamRequest: 0,
  openDoctorPrescriptionSpecial: false,
  countDoctorPrescriptionSpecial: 0,
  openDoctorPrescriptionAntimicrobials: false,
  countDoctorPrescriptionAntimicrobials: 0,
  participant: null,
  consultationData: {},
  data: [],
  medicalHistory: [],
  live: false,
  local: {},
  consultationIsRunning: false,
  twilioToken: "",
  statusConsultation: "not initialized",
  terms: "",
  surgicalProcedure: [],
  openExamRegisteredRequest: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "OPEN_START_CONSULTATION_MODAL":
      return {
        ...state,
        openStart: action.payload,
      };
    case "OPEN_FINISH_CONSULTATION_MODAL":
      return {
        ...state,
        openFinish: action.payload,
      };
    case "OPEN_DISPENSE_PATIENT_MODAL":
      return {
        ...state,
        openDispensePatient: action.payload,
      };
    case "OPEN_CONSENT_MODAL":
      return {
        ...state,
        openConsent: action.payload,
      };
    case "OPEN_REFERRAL_MODAL":
      return {
        ...state,
        openReferral: action.payload,
      };
    case "OPEN_SURGICAL_PROCEDURE_MODAL":
      return {
        ...state,
        openSurgicalProcedure: action.payload,
      };
    case "REGISTER_SURGICAL_PROCEDURE":
      state.surgicalProcedure.push(action.payload)
      return state;
    case "UPDATE_SURGICAL_PROCEDURE":
      return {
        ...state,
        surgicalProcedure: action.payload,
      };
    case "CLEAR_SURGICAL_PROCEDURE":
      return {
        ...state,
        surgicalProcedure: [],
      };
    case "OPEN_MEDICAL_REPORT_MODAL":
      return {
        ...state,
        openMedicalReport: action.payload,
      };
    case "OPEN_PRESCRIPTION_MODAL":
      return {
        ...state,
        openPrescription: action.payload,
      };
    case "OPEN_SICK_NOTE_MODAL":
      return {
        ...state,
        openSickNote: action.payload,
      };
    case "OPEN_EXAM_REQUEST_MODAL":
      return {
        ...state,
        openExamRequest: action.payload,
      };
      case "OPEN_EXAM_REGISTERED_REQUEST_MODAL":
        return {
          ...state,
          openExamRegisteredRequest:action.payload,
        };
    case "OPEN_DOCTOR_PRESCRIPTION_SPECIAL_MODAL":
      return {
        ...state,
        openDoctorPrescriptionSpecial: action.payload,
      };
    case "OPEN_DOCTOR_PRESCRIPTION_ANTIMICROBIALS":
      return {
        ...state,
        openDoctorPrescriptionAntimicrobials: action.payload,
      };
    case "STORE_START_CONSULTATION_DATA":
      return {
        ...state,
        data: action.payload,
      };
    case "PARTICIPANT_ENTERED":
      return {
        ...state,
        participant: action.payload,
      };
    case "WE_ARE_LIVE":
      return {
        ...state,
        live: action.payload,
      };
    case "CONSULTATION_IS_RUNNING":
      return {
        ...state,
        consultationIsRunning: action.payload,
      };
    case "SET_TWILIO_TOKEN":
      return {
        ...state,
        twilioToken: action.payload,
      };
    case "SET_CONSULTATION_DATA":
      return {
        ...state,
        consultationData: { ...state.consultationData, ...action.payload },
      };
    case "CLEAR_CONSULTATION_DATA":
      return {
        ...state,
        consultationData: {},
      };
    case "SET_COUNT_PRESCRIPTION":
      return {
        ...state,
        countPrescription: state.countPrescription + 1,
      };
    case "SET_COUNT_DOCTOR_PRESCRIPTION_ANTIMICROBIALS":
      return {
        ...state,
        countDoctorPrescriptionAntimicrobials: state.countDoctorPrescriptionAntimicrobials + 1,
      };
    case "SET_COUNT_DOCTOR_PRESCRIPTION_SPECIAL":
      return {
        ...state,
        countDoctorPrescriptionSpecial: state.countDoctorPrescriptionSpecial + 1,
      };
    case "SET_COUNT_SICK_NOTE":
      return {
        ...state,
        countSickNote: state.countSickNote + 1,
      };
    case "SET_COUNT_EXAM_REQUEST":
      return {
        ...state,
        countExamRequest: state.countExamRequest + 1,
      };
    case "SET_COUNT_MEDICAL_REPORT":
      return {
        ...state,
        countMedicalReport: state.countMedicalReport + 1,
      };
    case "RESET_COUNTERS_PRESCRIPTION":
      return {
        ...state,
        countMedicalReport: 0,
        countPrescription: 0,
        countSickNote: 0,
        countExamRequest: 0,
        countDoctorPrescriptionSpecial: 0,
        countDoctorPrescriptionAntimicrobials: 0
      }
    case "REQUEST_CONSULTATION_SUCCESS":
      return {
        ...state,
        success: action.payload,
      };
    case "STOP_WAITING_CONSULTATION":
      return {
        ...state,
        waiting: false,
      };
    case "SAVE_MEDICAL_HISTORY":
      return {
        ...state,
        medicalHistory: action.payload,
      };
    case "UPDATE_STATUS_CONSULTATION":
      return {
        ...state,
        statusConsultation: action.payload,
      };
    case "GET_TERMS_OF_DOCTOR":
      return {
        ...state,
        terms: action.payload,
      };
    case "SET_CONSULTATION_LOCAL":
      return {
        ...state,
        local: action.payload,
      }
    case "DELETE_CONSULTATION_EXAM":    
    return {
          ...state,
          examsRequests: state.consultationData.examsRequests.filter((exam, index) => index !== action.payload),
        };
    default:
      return state;
  }
}
