import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    bars : {
        position: 'relative',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    bar: {
        background: 'rgb(223, 144, 0)',
        display: 'block',
        height: '100%',
        width: '4px',
        borderRadius: '1rem 1rem 0 0',
        position: 'absolute',
        bottom: props => props.audioLevel ? `${(props.audioLevel*3) - 30}px` : '-18px',
        transition: 'bottom 0.1s',
        "&:nth-of-type(1)": {
            left: 0,
        },
        "&:nth-of-type(2)": {
            left: '50%',
            transform: 'translateX(-50%)',
        },
        "&:nth-of-type(3)": {
            right: 0,
        },
    }
})

const AudioBar = (props) => {
    const classes = useStyles(props);
    return (
        <div className={classes.bars}>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
        </div>
    );
}

export default AudioBar;