import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { DoctorData } from './doctorData'

import { styles } from '../styles'

const localStyles = StyleSheet.create({
    
    prescriptionTitle: {
        marginTop: 30,
    },
    prescription: {
        minHeight: 200
    }
})

export const SimpleRecipe = ({documentPayload}) => {
    
    const { patientName, patientCpf, text } = documentPayload
    
    return(
        <View style={ styles.text }>
            <Text>Paciente: <Text style={styles.textBold}>{patientName}</Text></Text>
            <Text>CPF: <Text style={styles.textBold}>{patientCpf}</Text></Text>
            <Text style={[localStyles.prescriptionTitle, styles.textBold]}>Prescrição:</Text>
            <Text style={localStyles.prescription}>{text}</Text>
            <DoctorData documentPayload={ documentPayload }/>
        </View>
    )
}