import apiGraphql from "../../../services/api";

export const checkProviderStatus = async () => {
  const res = await apiGraphql({
    data: {
      query: `
        query {
          checkProviderStatus
        }
      `
    }
  })

  if(res.data?.data) {
    return res.data.data.checkProviderStatus
  }

  return null

}