import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FaceIcon from '@material-ui/icons/Face';
import PersonIcon from '@material-ui/icons/Person';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import History from '@material-ui/icons/History';
import Assignment from '@material-ui/icons/Assignment';
import LocalHospital from '@material-ui/icons/LocalHospital';
// import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
// import HdrStrongIcon from '@material-ui/icons/HdrStrong';
// import NextWeekIcon from '@material-ui/icons/NextWeek';
// import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
      color: '#393A4A',
      padding: '0',
  },
  active: {
    backgroundColor: '#F9B233',
    width: '100%',
  },
  link: {
    display: 'flex',
    justifyContent: "center",
    padding: '8px 16px',
  }
}));

export default function SelectedListItem(props) {
  const classes = useStyles();
  const userType = window.localStorage.getItem("userType");


  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        
          <ListItem
              className={classes.listItem}
              button
          >
            <NavLink exact to={`${props.match.path}${props.links[0]}`} className={classes.link} activeClassName={classes.active}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Meu Perfil" />
            </NavLink>
          </ListItem>

        
          <ListItem
              className={classes.listItem}
              button
          >
            <NavLink exact to={`${props.match.path}${props.links[1]}`} className={classes.link} activeClassName={classes.active}>
              <ListItemIcon>
                <ScheduleIcon />
              </ListItemIcon>
              <ListItemText primary="Minha Agenda" />
            </NavLink>
          </ListItem>

          { ( 
              userType === "PROVIDER_DOCTOR" || 
              userType === "PROVIDER_OTHER_PROFESSIONALS"
            ) &&
          
            <>
              <ListItem
                  className={classes.listItem}
                  button
              >
                <NavLink exact to={`${props.match.path}${props.links[2]}`} className={classes.link} activeClassName={classes.active}>
                  <ListItemIcon>
                    <FaceIcon />
                  </ListItemIcon>
                  <ListItemText primary="Secretárias" />
                </NavLink>
              </ListItem>
              
              <ListItem
                  className={classes.listItem}
                  button
              >
                <NavLink exact to={`${props.match.path}${props.links[3]}`} className={classes.link} activeClassName={classes.active}>
                  <ListItemIcon>
                      <ChatBubbleIcon />
                  </ListItemIcon>
                <ListItemText primary="Consultas" />
                </NavLink>
              </ListItem>
              { userType === "PROVIDER_DOCTOR" &&
            <ListItem
                className={classes.listItem}
                button
            >
              <NavLink exact to={`${props.match.path}${props.links[4]}`} className={classes.link} activeClassName={classes.active}>
                <ListItemIcon>
                    <Assignment />
                </ListItemIcon>
              <ListItemText primary="Exames" />
              </NavLink>
            </ListItem>
            }
            <ListItem
                className={classes.listItem}
                button
            >
              <NavLink exact to={`${props.match.path}${props.links[5]}`} className={classes.link} activeClassName={classes.active}>
                <ListItemIcon>
                    <History />
                </ListItemIcon>
              <ListItemText primary="Histórico" />
              </NavLink>
            </ListItem>

            <ListItem
                className={classes.listItem}
                button
            >
              <NavLink exact to={`${props.match.path}${props.links[6]}`} className={classes.link} activeClassName={classes.active}>
                <ListItemIcon>
                    <LocalHospital />
                </ListItemIcon>
              <ListItemText primary="Convênios" />
              </NavLink>
            </ListItem>
          </>
          }

          {/* <ListItem
              className={classes.listItem}
              button
          >
            <NavLink exact to={`${props.match.path}${props.links[4]}`} className={classes.link} activeClassName={classes.active}>
              <ListItemIcon>
                  <EnhancedEncryptionIcon/>
              </ListItemIcon>
              <ListItemText primary="Exames" />
              </NavLink>
          </ListItem>

          <ListItem
              className={classes.listItem}
              button
          >
            <NavLink exact to={`${props.match.path}${props.links[5]}`} className={classes.link} activeClassName={classes.active}>
              <ListItemIcon>
                <HdrStrongIcon />
              </ListItemIcon>
              <ListItemText primary="Procedimentos" />
            </NavLink>
          </ListItem>

          <ListItem
              className={classes.listItem}
              button
          >
            <NavLink exact to={`${props.match.path}${props.links[6]}`} className={classes.link} activeClassName={classes.active}>
              <ListItemIcon>
                  <NextWeekIcon />
              </ListItemIcon>
            <ListItemText primary="Outros Serviços" />
            </NavLink>
          </ListItem>

          <ListItem
              className={classes.listItem}
              button
          >
            <NavLink exact to={`${props.match.path}${props.links[7]}`} className={classes.link} activeClassName={classes.active}>
                <ListItemIcon>
                    <WorkOutlineIcon/>
                </ListItemIcon>
              <ListItemText primary="Empresa" />
            </NavLink>
          </ListItem>

          <ListItem
              className={classes.listItem}
              button
          >
            <NavLink exact to={`${props.match.path}${props.links[8]}`} className={classes.link} activeClassName={classes.active}>
                <ListItemIcon>
                    <MonetizationOnIcon/>
                </ListItemIcon>
              <ListItemText primary="Financeiro" />
            </NavLink>
          </ListItem> */}

      </List>
    </div>
  );
}
