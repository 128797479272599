import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';

import RadioButtons from '../../../common/radioButton/radioButton';
import Button from '../../../common/button';
import Check from '../../../assets/check-circle-full.png';
import Loading from '../../../common/loading';
import SnackBar from '../../../common/snackbar/index';

import * as modalActions from '../../../common/store/actions/triage.action';
import * as asyncActions from '../../../common/store/actions/async.action';
import * as scheduleActions from '../../../common/store/actions/schedule.action';
import { handlePatientName } from "../../../common/handlePatientName";

// Material UI Styling

const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '55%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#6B6C7E',
    fontSize: '14px',
    marginTop: '16px'
  },
  responseToPatient: {
      fontSize: '18px',
  },
  success: {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
      fontWeight: 600,
  },
  box: {
      display: "inline-block",
      marginRight: "3%",
      borderRadius: "20px",
      padding: '2% 3%',
      marginBottom: '2%',
      backgroundColor: "#EEEEEE",
      color: "#424242",
      "& > a": {
          cursor: 'pointer',
      }
  }
})

// Component

const TriageModal = (props) => {
    const dispatch = useDispatch();
    const triageData = useSelector((state) => state.TriageReducer);
    const MedicalAppointmentsData = useSelector((state) => state.MedicalAppointments)
    const success = triageData.isSuccess;
    const loading = triageData.loading;

    const [isSuccess, setSuccess] = useState(false);
    const [approvesTriage, setApprovesTriage] = useState('Sim');
    const open = triageData.open;
    const classes = useStyles(props);
    const medicalAppointmentId = props.consultation?.id;
    const sintomas = (props.consultation?.triage?.symptoms || "");
    const medicamentos = (props.consultation?.triage?.drugs || "");
    const alergias = (props.consultation?.triage?.allergies || "");
    const diseases = (props.consultation?.triage?.diseases || "");
    const exames = (props.consultation?.triage?.attachmentHasTriage || []);
    const handleClose = () => {
        dispatch(modalActions.isSuccess(false));
        dispatch(modalActions.openModal(false));   
    };

    useEffect( () => {
        setSuccess(success);
    },[success])

    const handleSave = async () => {
        await dispatch(asyncActions.approveTriage({
            approves: approvesTriage === "Sim",
            medicalAppointmentId
        }));
    }

    const whatButtonsSetToRender = () => {
        if(loading) return <Loading width="50px"/>
        if(props.justToCheck) {
            return (
                <Button 
                onClick={handleClose}
                width="120px"
                border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
                margin="0 20px 0 0"
                >
                    Ok
                </Button>
            )
        };
        if(isSuccess) {
            return (
                <Button 
                onClick={
                    () => {
                        dispatch(modalActions.openModal(false));
                        dispatch(scheduleActions.updateScheduleFront(true));
                    }
                }
                width="120px"
                border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
                margin="0 20px 0 0"
                >
                    Ok
                </Button>
            )
        };
        return (
            <>
                <Button 
                    onClick={handleClose}
                    width="120px"
                    border="1px solid #CDCED9"
                    backgroundColor="#FFFFFF"
                    color="#6B6C7E"
                    margin="0 20px 0 0"
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={handleSave}
                    width="120px"
                    border="1px solid #DF9000"
                    backgroundColor="#FFFFFF"
                    color="#DF9000"
                >
                    Salvar
                </Button>
            </>
        )
    }


    return (
            <div>
            <Dialog 
                open={open}
                onClose={handleClose} 
                aria-labelledby="form-dialog-title" 
                classes={{
                paper: classes.root
                }}
                TransitionComponent={Grow}
                transitionDuration={{enter: 200, exit: 0}}
            >
                <div className={`${classes.header} ${isSuccess ? classes.success : null}`}>
                <DialogTitle>
                    {isSuccess ?
                        <div><img src={Check} alt="success"/><span> Triagem validada</span></div>
                        :
                        <span>Triagem</span>
                    }
                </DialogTitle>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                </div>
                <DialogContent dividers>
                <form>
                    <div className="modal-body">
                        <p><strong>Paciente: <span className={classes.patientName}>{handlePatientName(MedicalAppointmentsData?.data?.patient)}</span></strong></p>
                        <div>
                            <p className={classes.lettering}>Sintomas do paciente: </p>
                            <div>
                                {sintomas.length > 0 && <div className={classes.box}>{sintomas}</div>}
                            </div>
                        </div>
                        <div>
                            <p className={classes.lettering}>Medicamentos usados: </p>
                            <div>
                            <div>
                                {medicamentos.length > 0 && <div className={classes.box}>{medicamentos}</div>}
                            </div>
                            </div>
                        </div>
                        <div>
                            <p className={classes.lettering}>Alergias: </p>
                            <div>
                            <div>
                                {alergias.length > 0 && <div className={classes.box}>{alergias}</div>}
                            </div>
                            </div>
                        </div>
                        <div>
                            <p className={classes.lettering}>Doenças: </p>
                            <div>
                            <div>
                                {diseases.length > 0 && <div className={classes.box}>{diseases}</div>}
                            </div>
                            </div>
                        </div>
                        <div>
                            <p className={classes.lettering}>Resultados de exames, fotos de lesões (clique para baixar): </p>
                            <div>
                                {exames.map((exame) => <div key={exame.uri} className={classes.box}><a href={exame.uri} target="_blank" rel="noopener noreferrer">{exame.file}</a></div>)}
                            </div>
                        </div>
                        {!props.justToCheck && <RadioButtons
                            label="Liberar para atendimento online?"
                            width="113px"
                            marginTop="2%"
                            disabled={!!props.consultation?.medicalAppointmentReturnGenerated}
                            value={approvesTriage}
                            onChange={(e) => setApprovesTriage(e.target.value)}
                        />}
                        {!!props.consultation?.medicalAppointmentReturnGenerated &&
                            <div style={{color: 'red'}}>Triagem de consulta de retorno não pode ser negada</div>
                        }
                        {
                            approvesTriage === "Não" ?
                                <>
                                    <p><strong>O texto abaixo será enviado ao paciente</strong></p>
                                    <p className={classes.responseToPatient}>
                                        "Após análise da sua triagem, o profissional orienta que seu atendimento 
                                        deve ser realizado presencialmente. Solicitamos que faça essa marcação na 
                                        tela Inicial do APP, na opção Consulta Presencial."
                                    </p>
                                </>
                                :
                                null
                        }
                    </div>
                    <DialogActions className={classes.extraMargin}>
                        {whatButtonsSetToRender()}
                    </DialogActions>
                </form>
                </DialogContent>
            </Dialog>
            <SnackBar/>
        </div>
    );
};

export default TriageModal;
