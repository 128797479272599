const INITIAL_STATE = {
  active: 0,
  openModal: false,
  openAddModal: false,
  openDeleteModal: false,
  openEditModal: false,
  exams: [],
  examExists: 0,
  providerExams: [],
  selectedProviderExam: {},
  taxExamFee: 0,
  examsSpreadsheet: {},
  triggerUpdateExamList: {}
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ALTER_ACTIVE_EXAM":
      return {
        ...state,
        active: action.payload,
      };
    case "REGISTER_EXAM":
      return {
        ...state,
        exams: [...state.exams, action.payload],
      };
      case "GET_EXAM":
        return {
            ...state,
            examExists: state.exams.filter(exam => exam.examId === action.payload).length,
          };
      case "REGISTER_PROVIDER_EXAM":
        return {
          ...state,
          providerExams: [...state.providerExams, action.payload],
        };
    case "RESET_EXAMS":
      return {
        ...state,
        exams: action.payload
      }
    case "DELETE_EXAM":
      return {
        ...state,
        exams: state.exams.filter((exam, index) => index !== action.payload),
      };

    case "UPDATE_EXAM":
    return {
        ...state,
        exams: state.exams.map((exam, index) =>
          index === action.payload ? (exam = action.details) : exam
        ),
      };
      case "UPDATE_PROVIDER_EXAMS":
        return {
            ...state,
            providerExams: state.providerExams.map((providerExams, index) =>
              
              index === action.payload ? action.details: providerExams
            ), 
            
          };
    case "DELETE_ALL_EXAMS":
      return {
        ...state,
        exams: [],
        active: undefined,
      };

    case "OPEN_MODAL_EXAM":
      return {
        ...state,
        openModal: action.payload
      }
      case "OPEN_MODAL_ADD_EXAM":
      return {
        ...state,
        openAddModal: action.payload
      }
      case "OPEN_EDIT_MODAL_EXAM":
      return {
          ...state,
          openEditModal: action.payload
        }
      case "SET_CHOOSEN_PROVIDER_EXAMS":
        return {
          ...state,
          providerExams: action.payload,
        }
        case "GET_SELECTED_PROVIDER_EXAM":
        return {
          ...state,
          selectedProviderExam: action,
        }
        case "SET_EXAM_FEE":
          return {
            ...state,
            taxExamFee: action.payload,
          }
          case "REGISTER_EXAMS_FROM_SPREADSHEET":
            return {
              ...state,
              examsSpreadsheet:  action.payload
            };
            case "DELETE_EXAMS_SPREADSHEET":
              return {
                ...state,
                examsSpreadsheet: {},
              };
            
            case "TRIGGER_UPDATE_EXAM_LIST":
              return {
                ...state,
                triggerUpdateExamList:  action.payload
              };
            
    default:
      return state;
  }
}
