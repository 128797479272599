import React, {memo} from "react";

import Topic from "../../../../common/topic";
import Input from "../../../../common/input";

import empty from "../../../../common/validators/empty";
import telphoneValidator from "../../../../common/validators/telephone";
import cpfValidator from "../../../../common/validators/cpf";
import emailValidator from "../../../../common/validators/email";
import maskCpf from "../../../../common/mask/cpf";
import maskTelephone from "../../../../common/mask/telephone";

const PersonalData = (props) => {
  return (
    <>
      <div>
        <Topic
          classesNameCol="topic-datas firt-topic"
          title="Dados Pessoais"
          subtitle="Confirme os seus dados pessoais para continuar"
        />
      </div>

      <Input
        width="18%"
        label="CPF"
        placeholder="Digite seu CPF"
        type="text"
        value={props.cpf}
        onChange={(e) => props.setCpf(maskCpf(e.target.value))}
        onBlur={() => props.setCpfError(cpfValidator(props.cpf))}
        error={props.cpfError.typeError}
        dataTest="input-pf-cpf"
      />

      <Input
        width="50%"
        label="Nome"
        placeholder="Digite seu Nome"
        type="text"
        value={props.name}
        onChange={(e) => props.setName(e.target.value)}
        onBlur={() => props.setNameError(empty(props.name))}
        error={props.nameError.typeError}
        dataTest="input-pf-name"
      />

      <Input
        width="32%"
        label="Email"
        placeholder="Digite seu Email"
        type="email"
        value={props.email}
        onChange={(e) => props.setEmail(e.target.value)}
        onBlur={() => props.setEmailError(emailValidator(props.email))}
        error={props.emailError.typeError}
        dataTest="input-pf-email"
      />

      <Input
        width="18%"
        label="Telefone"
        placeholder="Digite seu Telefone"
        type="text"
        value={props.telephone}
        onChange={(e) => props.setTelephone(maskTelephone(e.target.value))}
        onBlur={() =>
          props.setTelephoneError(telphoneValidator(props.telephone))
        }
        error={props.telephoneError.typeError}
        dataTest="input-pf-phone"
      />
    </>
  );
};

export default memo(PersonalData);