const INITIAL_STATE = {
  active: 0,
  openModal: false,
  proceedings: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ALTER_ACTIVE_PROCEEDING":
      return {
        ...state,
        active: action.payload,
      };
    case "REGISTER_PROCEEDING":
      return {
        ...state,
        proceedings: [...state.proceedings, action.payload],
      };
    case "RESET_PROCEEDING":
      return {
        ...state,
        proceedings: action.payload
      }
    case "DELETE_PROCEEDING":
      return {
        ...state,
        proceedings: state.proceedings.filter(
          (proceeding, index) => index !== action.payload
        ),
      };

    case "UPDATE_PROCEEDING":
      return {
        ...state,
        proceedings: state.proceedings.map((proceeding, index) =>
          index === action.payload ? (proceeding = action.details) : proceeding
        ),
      };

    case "DELETE_ALL_PROCEEDINGS":
      return {
        ...state,
        proceedings: [],
        active: undefined,
      };

    case "OPEN_MODAL_PROCEEDING":
      return {
        ...state,
        openModal: action.payload
      }

    default:
      return state;
  }
}
