import  React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

import HorizontalList from '../../common/horizontalList'
import { Consultations, Exams, Others, Procedures, ScheduledExams } from './tabs'
import DatePicker from '../../common/datepicker'
import Pagination from '../../common/pagination'
import Snackbar from '../../common/snackbar'

const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: "#F7F8F9",
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  root: {
    width: '95%',
    padding: '3vh 0',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 600
    }
  },
  guide: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5vh 0'
  },
  pagination: {
    position: "absolute",
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
},
}));

const Attendance = () => {
  const classes = useStyles()

  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  const listOptions = [
    { text:"Consulta" },
    { text:"Exames Agendados" },
    { text:"Exames sem Agendamento" },
    { text:"Procedimento" },
    { text:"Outros Serviços" }
  ]

  const [ selectedCategory, setSelectedCategory ] = useState(Number(query?.tab || 0))
  const [ date, setDate ] = useState()
  const [ count, setCount ] = useState(0)
  const [ page, setPage ] = useState(1)
  const [ limit, setLimit ] = useState(10)

  const firstItem = (page*limit)-(limit)
  const lastItem = (firstItem + limit)

  useEffect(() => {
    query.tab = selectedCategory
    const newQuery = queryString.stringify(query)
    history.replace(`${location.pathname}?${newQuery}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])


  return(
    <>
      <div className={classes.screen}>
        <div className={classes.root}>
              
          <h1>Atendimento</h1>
          <div className={classes.guide}>
            <HorizontalList 
              width="90%"
              listItems={listOptions}
              selectedList={selectedCategory}
              setSelectedList={setSelectedCategory}
            />
            <div className={classes.calendar}>
              <span>Data:</span>
            
              <DatePicker
                disablePast={false}
                onChange={setDate}
                margin="0 0 0 10px !important" 
                width="150px" 
                backgroundColor="#FFFFFF"
                value={date}
              />           
            </div>
          </div>
          
          { selectedCategory === 0 &&
            <Consultations 
              page={page}
              limit={limit}
              date={date}
              setCount={setCount}
            />
          }
          
          { selectedCategory === 1 &&
            <ScheduledExams 
              page={page}
              limit={limit}
              date={date}
              setCount={setCount}
            />
          }

          { selectedCategory === 2 &&
            <Exams 
              page={page}
              limit={limit}
              setCount={setCount}
            />
          }

          { selectedCategory === 3 &&
            <Procedures 
              page={page}
              limit={limit}
              date={date}
              setCount={setCount}
            />
          }

          { selectedCategory === 4 &&
            <Others />
          }
        
        </div>
      </div>
      <Snackbar/>
      <div className={classes.pagination}>
        <Pagination 
            page={page}
            setPage={setPage}
            itemsPerPage={limit}
            setItemsPerPage={setLimit}
            firstItem={firstItem}
            lastItem={lastItem}
            length={count}
            width="100%"
        />
      </div>
    </>
  )
}

export default Attendance
