import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import api from '../../../services/api';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';

import Button from '../../../common/button';
import empty from '../../../common/validators/empty';
import SearchBar from '../../../common/searchBar/searchBar';
import Check from '../../../assets/check-circle-full.png';
import Loading from '../../../common/loading';


import * as modalActions from '../../../common/store/actions/consultation.action';
import * as asyncActions from '../../../common/store/actions/async.action';

// Material UI Styling

const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '55%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  term: {
    lineHeight: '150%',
    fontSize: '18px'
  },
  consentTerm: {
      border: '1px solid #E7E7ED',
      borderRadius: '4px',
      padding: '4%',
      "& > h3": {
          marginTop: 0,
          paddingBottom: '16px',
          borderBottom: '1px solid #E7E7ED'
      },
      "& > p": {
          fontSize: '16px'
      }
  },
  success: {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
      fontWeight: 600,
  },
  error: {
      fontSize: '14px',
      color:'#DA1414'
  }
})

// Component

const ReferralModal = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles(props);

    const medicalAppointmentId = window.localStorage.getItem("appointId");
    const patientId = window.localStorage.getItem("patientId");
    const providerId = window.localStorage.getItem("Prov-Vida-Connect");

    const consentData = useSelector((state) => state.ConsultationReducer);
    const open = consentData.openReferral;

    const [speciality, setSpeciality] = useState({name:""});
    const [specialityError, setSpecialityError] = useState("");
    const [referredDoctor, setReferredDoctor] = useState({ 
        name: '', id: '', specialities: [{ speciality: { id: '' } }] 
    })

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const sendToPatient = async ({medicalAppointmentId, patientId, specialityId, providerId}) => {
        setLoading(true);
        const input = {
            medicalAppointmentId,
            patientId,
            specialityId,
            providerId,
        }
        if (referredDoctor.id) Object.assign(input, { referredProviderId: referredDoctor.id } )
        try {
            const res = await api({
                data: {
                    query: 
                    `
                        mutation($input: ReferralInput!) {
                            createReferral(input: $input) {
                                medicalAppointmentId
                            }
                        }
                    `,
                    variables: {
                        input
                    }
                }
            });
            if(res.data.data) {
                setSuccess(true)
            } else {
                const error = res.data.errors[0].message;
                setError(error)
            };
            setLoading(false);
        } catch(error) {
            setLoading(false);
            setError("Algo deu errado durante o encaminhamento. Tente novamente, por favor.")
        }

    }

    const handleClick = () => {
        if (!speciality.id) setSpecialityError('Esse campo é obrigatório!')
        else sendToPatient({
            medicalAppointmentId, 
            patientId, 
            specialityId: speciality.id, 
            providerId
        });
    }

    const handleClose = () => {
        setSpeciality({name:""});
        setSpecialityError("");
        setSuccess(false);
        setLoading(false);
        setError(false);
        dispatch(modalActions.openReferralModal(false));
    };
    
    useEffect(() => {
        const referredDoctorHasSpeciality = referredDoctor.specialities.some(specialitiesDoctor => 
            specialitiesDoctor.speciality.id === speciality.id )
        
        if (!referredDoctorHasSpeciality && referredDoctor.name) {
            setReferredDoctor({ name: '', id: '', specialities: [{ speciality: { id: '' } }] })
        }    

    }, [speciality, referredDoctor])

    return (
            <div>
            <Dialog 
                open={open}
                onClose={handleClose} 
                aria-labelledby="form-dialog-title" 
                classes={{
                paper: classes.root
                }}
                TransitionComponent={Grow}
                transitionDuration={{enter: 200, exit: 0}}
            >
                <div className={`${classes.header} ${success ? classes.success : null}`}>
                    <DialogTitle>
                        {
                            success ?
                            <div><img src={Check} alt="success"/><span> Encaminhamento feito</span></div>
                            :
                            <span>Encaminhamento</span>
                        }
                    </DialogTitle>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                </div>
                <DialogContent dividers>
                    <form>
                        <div className="modal-body">
                            <SearchBar
                                type="specialities"
                                width="100%"
                                label="Especialidade que o paciente deverá ser encaminhado"
                                placeholder="Insira uma especialidade"
                                value={speciality.name}
                                onChange={setSpeciality}
                                onBlur={() => setSpecialityError(empty(speciality.name).typeError)}
                                error={specialityError}
                                asyncAction={asyncActions.getSpecialities}
                            />
                            <SearchBar
                                type="doctors"
                                width="100%"
                                label="Você pode indicar um médico para a especialidade escolhida"
                                placeholder="Indique um médico"
                                value={referredDoctor.name}
                                onChange={setReferredDoctor}
                                initialState={{ name: '', id: '', specialities: [{ speciality: { id: '' } }] }}
                                asyncAction={() => asyncActions.listDoctorsBySpeciality(speciality.id)}
                            />
                        </div>
                        <div className={classes.error}>{error}</div>
                        <DialogActions className={classes.extraMargin}>
                            {
                                success ?
                                    <Button 
                                        onClick={handleClose}
                                        width="120px"
                                        border="1px solid #CDCED9"
                                        backgroundColor="#FFFFFF"
                                        color="#6B6C7E"
                                        margin="0 20px 0 0"
                                    >
                                        Ok
                                    </Button>
                                    :
                                    loading ? 
                                    <Loading width="50px" />
                                    :
                                    <Button 
                                        onClick={handleClick}
                                        width="120px"
                                        border="1px solid #CDCED9"
                                        backgroundColor="#FFFFFF"
                                        color="#6B6C7E"
                                        margin="0 20px 0 0"
                                    >
                                        Encaminhar
                                    </Button>
                            }
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ReferralModal;