import React from 'react';
import UserPhotoBase from '../../assets/User-Icon.png';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles({
    container: {
        width: props => props.width,
        height: props => props.width,
        margin: props => props.margin
    }
});


const CustomAvatar = (props) => {
    const classes = useStyles(props);

    return (
        <Avatar src={props.src || UserPhotoBase} alt="User" className={classes.container}/>
    )
}

export default CustomAvatar;