import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { format } from 'date-fns'
import Button from '@material-ui/core/Button';
import { handlePatientName } from '../../../../common/handlePatientName';


export default (props) => {
  const {
    open,
    handleClose,
    modalContent,
  } = props
  const handleDocumentClick = (item) => {
    window.open(item.reportFile)
  }
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {modalContent?.serviceProvided?.description}<br />
              Paciente: {handlePatientName(modalContent?.medicalAppointment?.patient)}<br />
              Em: {format(new Date(modalContent?.dateTime || '2020-01-01'), 'dd/MM/yyyy - HH:mm')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
       
            <div style={{ marginTop: 40 }}>
              <h3><strong>Relatório do procedimento</strong></h3>
              {!modalContent?.reportFile ? 'Não há informação' : (
                <div >
                  <Button
                    variant="outlined"
                    style={{ borderColor: '#DF9000', color: '#DF9000', marginTop: 10 }}
                    onClick={() => handleDocumentClick(modalContent)}
                  >
                    Relatório do procedimento
                  </Button>
                </div>
              )}
            </div>
        
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            OK
              </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
