import React from 'react'
import { format, parseISO } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles'

import Topic from "../../../../common/topic";
import DateTimePicker from '../../../../common/datetimepicker'
import { UTCtoTimeZone } from './UTCtoTimeZone'

const useStyles = makeStyles({
  picker: {
    marginTop: '50px',
    marginBottom: '15px'
  }
})

export const ProposeDates = (props) => {
  const classes = useStyles()


  const closestDate = props.appointment.closestDate

  const initialDate = closestDate ? '' : format(parseISO(props.appointment.initialDate), 'dd/MM/yyyy')
  const finalDate = closestDate ? '' : format(parseISO(props.appointment.finalDate), 'dd/MM/yyyy')
  const startTime = closestDate ? '' : UTCtoTimeZone(props.appointment.startTime)
  const endTime = closestDate ? '' : UTCtoTimeZone(props.appointment.endTime)
  const daysOfWeek = props.appointment.daysOfWeek

  return (
    <>

      { closestDate && 
        <Topic
          title='O Paciente deseja ser atendido na data mais próxima.'
          fontSize="14px"
          marginTop="20px"
        />
      }

      { !closestDate &&
        <>
          <Topic
            title='O Paciente deseja ser atendido entre os dias:'
            fontSize="14px"
            marginTop="20px"
          />
          <div>
            { initialDate } e { finalDate }
          </div>
          <Topic
            title='E prefere os seguintes dias da semana:'
            fontSize="14px"
            marginTop="20px"
          />
          <div>
            { daysOfWeek }
          </div>
          <Topic
            title='O Horário solicitado foi:'
            fontSize="14px"
            marginTop="20px"
          />
          <div>
            Entre { startTime } e { endTime }
          </div>
        </>
      }

      <Topic
        title='Envie até duas opções de data para o paciente;'
        fontSize="14px"
        marginTop="20px"
      />

      <Topic
        title='Opção de Agendamento 1'
        fontSize="14px"
        marginTop="20px"
      />

      <DateTimePicker
        className={ classes.picker }
        value={ props.proposeDate1 }
        setValue={ props.setProposeDate1 }
      />

      <Topic
        title='Opção de Agendamento 2(opcional)'
        fontSize="14px"
        marginTop="20px"
      />

      <DateTimePicker
        className={ classes.picker }
        value={ props.proposeDate2 }
        setValue={ props.setProposeDate2 }
      />
      
    </>
  )
}
