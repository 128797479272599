import React from "react";
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { ApolloProvider } from '@apollo/client'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import client from './apolloSetup'
import "./global.css";
import Routes from "./routes";
import { Provider } from "react-redux";
import store from "./common/store";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat , sans-serif"
  }
})

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
              <Routes />
            </ApolloHooksProvider>
          </ApolloProvider>
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
