import React, { useState, useEffect } from "react";

import ModalBase from '../modalBaseWithouFlow';

import { useDispatch, useSelector } from "react-redux";

import empty from "../../validators/empty";
import SearchBar from "../../searchBar/searchBar";
import InputCurrency from '../../inputCurrency';

import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles'

import "../style.css";
import * as consultation from "../../store/actions/registerConsultations";
import calcTax from '../../../utils/calcTax'

const useStyles = makeStyles( {
  lettering: {
    color: '#686868',
    fontSize: '12px',
  }
})

const ModalRegisterConsultation = (props) => {
  const dispatch = useDispatch();

  const openModal = useSelector((state) => state.RegisterConsultations.openModal);
  const taxAppointmentFeeProvider = useSelector((state) => state.RegisterConsultations.taxAppointmentFeeProvider);
  const taxAppointmentFeePatient = useSelector((state) => state.RegisterConsultations.taxAppointmentFeePatient);
  const personalDatas = useSelector((state) => state.PersonalDatas.finalRegister[0].pf.personalDatas);

  const classes = useStyles();

  const [speciality, setSpecialty] = useState({
    id: personalDatas.specialityId,
    name: personalDatas.specialityName,
  });
  const [specialtyError, setSpecialtyError] = useState({
    error: true,
    typeError: "",
  });

  const [price, setValue] = useState(0);

  const activeConsultation = useSelector( (state) => state.RegisterConsultations.active)
  let consultationData = useSelector((state) => state.RegisterConsultations.consultations);

  useEffect( () => {
    if(props.professionalType === 'others') {
      setSpecialty({
        id: personalDatas.specialityId,
        name: personalDatas.specialityName,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDatas])

  useEffect( () => {
    if(consultationData.length > 0 && activeConsultation <= consultationData.length - 1 ) {
      const availableData = consultationData[activeConsultation];
      setSpecialty(availableData.speciality);
      setSpecialtyError(empty(availableData.speciality));
      setValue(availableData.price);
    }
  }, [activeConsultation, consultationData])

  const specialities = useSelector((state) => state.RegisterConsultations.availableSpecialities);

  function handleSubmit(e) {
    e.preventDefault();
    setSpecialtyError(empty(speciality.name));

    if (!specialtyError.error) {
      const payload = { speciality, price };

      if(openModal) {
        dispatch(consultation.updateConsultation(activeConsultation, payload));
      } else {
        dispatch(consultation.RegisterConsultation(payload));
      }

      dispatch(consultation.alterActive(consultationData.length + 1));    
      dispatch(consultation.openModal(false));

      setSpecialty({name: ""});
      setValue(0);
      setSpecialtyError({ error: true, typeError: "" });
      
      return true;
    }
  }

  const onClose = () => {
    if(props.professionalType !== 'others')
      setSpecialty({name: ""});
    
      setValue(0);
    setSpecialtyError({ error: true, typeError: "" });
    dispatch(consultation.openModal(false));
    dispatch(consultation.alterActive(consultationData.length + 1));
  }

  return (
    <ModalBase 
      title="Cadastrar Consulta" 
      buttonWidth={props.buttonWidth} 
      buttonMargin={props.buttonMargin} 
      buttonText={props.buttonText} 
      handleSubmit={handleSubmit} 
      open={openModal} 
      onClose={onClose}
    >
      <SearchBar
        sync
        label="Especialidade"
        placeholder="Especialidade"
        value={speciality.name}
        onChange={setSpecialty}
        onBlur={() => setSpecialtyError(empty(speciality.name))}
        error={specialtyError.typeError}
        itemList={specialities}
        alreadyChoosen={consultationData.map((speciality) => speciality.speciality)}
        dataTest="modal-cadastrar-consulta-input-especialidade"
        disabled={props.professionalType === 'others'}
      />
      <InputCurrency
        width="100%"
        label="Valor da Consulta"
        placeholder="Valor da consulta"
        value={price}
        onChange={setValue}
        dataTest="modal-cadastrar-consulta-input-preco"
      />
      <DialogContentText className={classes.lettering}>
        O valor cobrado do prestador pela plataforma será de: R$ {calcTax({ tax: taxAppointmentFeeProvider, price: price, sum: false })}
      </DialogContentText>
      <DialogContentText className={classes.lettering}>
        O valor final que a plataforma irá cobrar do paciente será de: R$ {calcTax({ tax: taxAppointmentFeePatient, price: price, sum: true })}
      </DialogContentText>  
    </ModalBase>
  );
};

export default ModalRegisterConsultation;
