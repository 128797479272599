import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import PresentationCard from './presentationCard';

import './style.css';

import Logo from '../../assets/Logo-VC.png';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  edgeStart: {
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  loginName: {
    marginRight: '0.6vw',
  },
  establishment: {
    color: "#6B6C7E",
    fontSize: '12px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: '40%',
      justifyContent: 'flex-end',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const NavBar = (props) => {
  const classes = useStyles();
  const status = window.localStorage.getItem("status");
  const estabName = window.localStorage.getItem("estabName");
  const userData = JSON.parse(window.localStorage.getItem("userData"));
  const userType = window.localStorage.getItem("userType");

  const [user, setUser] = useState({});
  const [currentEstablishment, setCurrentEstablishment] = useState("Particular");

  useEffect( () => {
    if(estabName) setCurrentEstablishment(estabName);
    if(userData) setUser(userData);
    //eslint-disable-next-line
  }, [estabName]);

  const whatRole = (userType) => {
    if(userType === "PROVIDER_DOCTOR") return <span className={classes.establishment}>Médico(a) - {currentEstablishment}</span>;
    if(userType === "PROVIDER_SECRETARY") return <span className={classes.establishment}>Secretária(o)</span>;
  }

  const whatToShow = (status) => {
    if (status === "OK") {
        return(
          <div className="links">
            {estabName !== 'Virtua' && (
              <NavLink exact to="/atendimento" activeStyle={{color: '#DF9000'}} >
                  Atendimento
              </NavLink>
            )}
            {estabName !== 'Virtua' && (
              <NavLink to="/administrador" activeStyle={{color: '#DF9000'}}>
                Administrador
              </NavLink>
            )}

            {estabName !== 'Virtua' && (
              <NavLink to="/solicitacoes" activeStyle={{color: '#DF9000'}}>
                Solicitações
              </NavLink>
            )}


            {estabName === 'Virtua' && (
              <NavLink to="/fila" activeStyle={{color: '#DF9000'}}>
                Fila
             </NavLink>
            )}
            {estabName === 'Virtua' && (
              <NavLink to="/historico" activeStyle={{color: '#DF9000'}}>
                Histórico
              </NavLink>
            )}
          
          </div>
        ) 
      } else {
          return null;
      }        
  }

  return (
    <div>
      <AppBar className="nav" position="static" classes={{root: classes.grow}}>
        <Toolbar className="nav-bar">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            disabled
          >
            <img src={Logo} alt="Logo Vida"/>
          </IconButton>
          {whatToShow(status)}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div className={`${classes.loginName} user-info`}>
              <span className="user-name">{user.name}</span>
              {status === "OK" && whatRole(userType)}
            </div>
            <PresentationCard status={status}/>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withRouter(NavBar);
