import React, { useEffect, useState } from 'react'
import { 
  Dialog, 
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  makeStyles,
  Avatar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

import InputFile from "../../../common/fileInput";
import Button from '../../../common/button'
import Loading from '../../../common/loading'
import Check from '../../../assets/check-circle-full.png'
import SyncSnackBar from '../../../common/snackbar/syncAlternative';
import { validationFileType } from '../../../common/validators/fileInput'
import * as procedureService from '../../../services/procedures'
import { handlePatientName } from '../../../common/handlePatientName';

const useStyles = makeStyles(() => ({
  close: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  success: {
    backgroundColor: '#EDF9F0',
    color: '#287D3C'
  },
  root: {
    width: '800px',
    maxWidth: '580px',
    "& .modal-body":{
      "& div.date-appointment":{
        marginBottom: '24px',
        "& span.day-appointment":{
          marginRight:"25px"
        },
        "& img":{
          marginRight:'8px'
        }
      },
      "& div.appointment-user":{
        display:'flex',
        marginBottom:'24px',
        "& div.appointment-user-information span":{
          display: 'block',
          padding: '2px 0 ',
          marginLeft: '13px'
        },
        "& div.image-rounded": {
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          border: '1px solid #E7E7ED',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },

    }
    
  },
}))

const typesAccepted = [
  'image/png', 
  'image/jpg', 
  'image/jpeg', 
  'application/pdf', 
  'application/msword', 
]

const FinalizeProcedure = ({open, handleClose, procedure, reload}) => {
  const classes = useStyles()

  const [ loading, setLoading ] = useState(false)
  const [ isSuccess, setIsSuccess ] = useState(false)
  const [ openSnackBar, setOpenSnackBar ] = useState(false)
  const [ messageSnackBar, setMessageSnackBar ] = useState('')

  const [ report, setReport ] = useState({name: ''});
  const [ reportError, setReportError ] = useState({ error: true, typeError: '' });

  const handleChangeReport = (file) => {
    
    const typeIsAccpted = validationFileType(file?.type,typesAccepted)
    
    if(!file) {
      setReport({name: ''})
    } else if(!typeIsAccpted) {
      setReport({name: ''})
      setMessageSnackBar('Tipo de arquivo inválido.')
      setOpenSnackBar(true)
    } else {
      setOpenSnackBar(false)
      setReportError({ ...reportError, typeError: '' })
      setReport(file)
    }
    
  }

  const finalizeProcedureAttendance = async () => {
    
    setLoading(true)
    const data = new FormData()
    data.append('report', report)
    data.append('procedureRequestId', procedure.id)
    const finalize = await procedureService.finalizeProcedureAttendance(data)
    setLoading(false)

    if(finalize) {
      setIsSuccess(true)
      reload()
    } else {
      setMessageSnackBar('Ocorreu um erro ao tentar finalizar o procedimento.')
      setOpenSnackBar(true)
    }
    
    
  }

  const whatButtonsSetToRender = () => {
    if(loading) return <Loading width="50px"/>
    if(isSuccess) {
      return (
        <Button
          onClick={handleClose}
          width="120px"
          border="1px solid #CDCED9"
          backgroundColor="#FFFFFF"
          color="#6B6C7E"
          margin="0 20px 0 0"
        >
          Ok
        </Button>
      )
    };
    return (
      <>
        <Button 
          onClick={handleClose}
          width="120px"
          border="1px solid #CDCED9"
          backgroundColor="#FFFFFF"
          color="#6B6C7E"
          margin="0 20px 0 0"
        >
          Cancelar
        </Button>
        <Button
          onClick={finalizeProcedureAttendance}
          width="120px"
          border="1px solid #DF9000"
          backgroundColor="#FFFFFF"
          color="#DF9000"
          disabled={report.name === ''}
        >
          Confirmar
        </Button>
      </>
    )
  }

  useEffect(() => {
    if(!open) {
      setReport({name: ''})
      setOpenSnackBar(false)
      setReportError({ ...reportError, typeError: '' })
    }
  // eslint-disable-next-line
  }, [open])

  return (
    <>
      <Dialog
        open={open}
        classes={{
          paper: classes.root
        }}
      >
        
        <DialogTitle
          className={isSuccess ? classes.success : ''}
          
        >
          { isSuccess &&
            <>
              <img src={Check} alt="success" className={classes.icon}/>
              <b>Procedimento encerrado com sucesso</b>
            </>
          }
          { !isSuccess &&
            <b>Encerrar Atendimento</b>
          }
          <IconButton 
            aria-label="close" 
            onClick={handleClose}
            className={classes.close}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          Tem certeza que deseja finalizar este atendimento?
          Esta operação é irreversível.
          <div className="modal-body">
            <h3>Paciente:</h3>
            <div className="appointment-user">
              <div className="image-rounded">
                <Avatar/>
              </div>
              <div className="appointment-user-information">
                { procedure && 
                  <>
                    <span>
                      <b>{ handlePatientName(procedure?.medicalAppointment.patient)}</b>
                    </span>
                    <span>
                      { procedure?.medicalAppointment.patient.user.email }
                    </span>
                  </>
                }
              </div>
            </div>

            <b>Procedimento: </b>
            { procedure?.serviceProvided.description }

            <InputFile
              id="reportFile"
              width="50%"
              type="file"
              accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
              disabled={loading || isSuccess}
              label="Anexar - Relatório do procedimento"
              subtitle="Formatos aceitos: png, jpg, jpeg, pdf, doc e docx. Tamanho máximo: 25MB"
              onChange={(e) =>{
                handleChangeReport(e.target.files[0])}
              }
              value={report.name}
              // onBlur={() =>
              //   props.setSpecialty1TitlesError(inputFileValidator(props.specialty1Titles, 25))
              // }
              error={reportError.typeError}
              dataTest="input-pf-titulo-de-especialidade-1"
            />
            
          </div>

        </DialogContent>

        <DialogActions>
          {whatButtonsSetToRender()}
        </DialogActions>
      </Dialog>
      <SyncSnackBar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        errorMessage={messageSnackBar}
      />
    </>
  )
}
export default FinalizeProcedure
