export function setPatientsList(payload) {
    return {
        type: "SET_PATIENTS_LIST",
        payload
    }
}

export function storeMedicalAppointmentsData(payload) {
    return {
        type: "STORE_MEDICAL_APPOINTMENTS_DATA",
        payload
    }
}

export function setTotalPatientsOnDay(payload) {
    return {
        type: "SET_TOTAL_PATIENTS_ON_DAY",
        payload
    }
}