const INITIAL_STATE = {
  open: false,
  loading: false,
  isSuccess: false,
  data: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "OPEN_VALIDATE_TOKEN_MODAL":
      return { 
        ...state, 
        open: action.payload,
        isSuccess: false,
      };
    case "WAITING_VALIDATE_TOKEN_APPROVAL":
      return {
        ...state,
        loading: action.payload,
      }
    case "STORE_VALIDATE_TOKEN_DATA":
      return {
        ...state,
        data: action.payload
      } 
    case "IS_SUCCESS_VALIDATE_TOKEN":
      return {
        ...state,
        isSuccess: action.payload,
        loading: false,
      }
    default:
      return state;
  }
}
