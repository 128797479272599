import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles({
  root: {
    width: props => props.width ? props.width : '230px'
  }
})

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#FFE466',
      main: '#F9B233',
      dark: '#C18300',
      contrastText: '#000000',
    },
    secondary: {
      light: '#999AAD',
      main: '#6B6C7E',
      dark: '#404152',
      contrastText: '#FFFFFF',
    },
  },
});

export default (props) => {
  const classes = useStyles(props)

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          classes={{ root:classes.root }}
          invalidDateMessage={'Formato de data inválido'}
          variant="inline"
          inputVariant="outlined"
          ampm={false}
          disablePast
          value={props.value}
          onChange={props.setValue}
          format="dd/MM/yyyy HH:mm"
          {...props}
          autoOk={true}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}