import React from "react";
import { useSelector } from "react-redux";

import WithoutAddress from "../common/register-address/withoutAddress"
import WithAddress from "../common/register-address/withAddress"
import { RegisterServices } from '../common/register-services/'

const RegisterAddress = (props) => {
  const addresses = useSelector((state) => state.Address.addresses);
  const showRegisterServices = useSelector(
    (state) => state.Address.showRegisterServices
  );

  if (showRegisterServices) {
    return (
      <RegisterServices 
        professionalType={props.professionalType}
      />
    )
  } else if (addresses.length) {
    return (
      <WithAddress
        steps={props.steps}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        activeStep={props.activeStep}
      />
    );
  } else {
    return (
      <WithoutAddress
        steps={props.steps}
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        activeStep={props.activeStep}
      />
    );
  }
};

export default RegisterAddress;