import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns'
import Button from '@material-ui/core/Button';
import graphqlApi from './../../services/api'
import { handlePatientName } from '../handlePatientName';

export default (props) => {
  const {
    open,
    handleClose,
    modalContent,
  } = props

  const documentTypeMap = {
    EXAM_REQUEST: "Pedido de exame",
    MEDICAL_REPORT: "Relatório médico",
    DOCTOR_PRESCRIPTION: "Prescrição",
    SICK_NOTE: "Atestado",
    DOCTOR_PRESCRIPTION_ANTIMICROBIALS: "Receituário antimicrobiano",
    DOCTOR_PRESCRIPTION_SPECIAL: "Receituário de controle especial",
  }

  const getDocumentFromApi = (id, documentType) => {
    return graphqlApi.post('/', {
      query: `
        mutation($id: ID!, $documentType: DocumentTypeEnum!) {
          getDocumentDownloadLink(id: $id, documentType: $documentType)
        }
      `,
      variables: {
        id,
        documentType
      }
    })
      .then(res => {
        const result = res.data
        if (result.errors) {
          alert('Erro ao recuperar arquivo')
          return
        }

        window.open(result.data.getDocumentDownloadLink)
      })
      .catch(() => {
        alert('Erro ao recuperar arquivo')
      })
  }

  const handleDocumentClick = (item) => {
    getDocumentFromApi(item.id, item.documentType)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {modalContent?.speciality?.name}<br />
              Paciente: {handlePatientName(modalContent?.patient)}<br />
              Em: {format(new Date(modalContent?.dateTimeBeginAppointmentUtc || '2020-01-01'), 'dd/MM/yyyy - HH:mm')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div>
              <h3><strong>História Clínica</strong></h3>
              <Typography>{Boolean(modalContent?.clinicalHistory) ? modalContent?.clinicalHistory : 'Não há informação'}</Typography>
            </div>
            <div style={{ marginTop: 40 }}>
              <h3><strong>Exame Físico</strong></h3>
              <Typography>{Boolean(modalContent?.physicalExam) ? modalContent?.physicalExam : 'Não há informação'}</Typography>
            </div>
            <div style={{ marginTop: 40 }}>
              <h3><strong>Hipótese Diagnostica</strong></h3>
              <Typography>{Boolean(modalContent?.diagnosticHypothesis) ? modalContent?.diagnosticHypothesis : 'Não há informação'}</Typography>
            </div>
            <div style={{ marginTop: 40 }}>
              <h3><strong>Tratamento</strong></h3>
              <Typography>{Boolean(modalContent?.treatment) ? modalContent?.treatment : 'Não há informação'}</Typography>
            </div>
            <div style={{ marginTop: 40 }}>
              <h3><strong>Documentos</strong></h3>
              {!modalContent?.documents?.length ? 'Não há informação' : modalContent?.documents?.map((item, index) => (
                <div key={index}>
                  <Button
                    variant="outlined"
                    style={{ borderColor: '#DF9000', color: '#DF9000', marginTop: 10 }}
                    onClick={() => handleDocumentClick(item)}
                  >
                    {documentTypeMap[item.documentType]}
                  </Button>
                </div>
              ))}
            </div>
            <div style={{ marginTop: 50 }}></div>
            <hr />
            <div style={{ marginTop: 50 }}>
              <h3><strong>Triagem</strong></h3>
              <h4 style={{ marginTop: 40 }}>Quais sintomas esta sentindo?</h4>
              <Typography>{Boolean(modalContent?.triage?.symptoms) ? modalContent?.triage?.symptoms : 'Não há informação'}</Typography>
              <h4 style={{ marginTop: 40 }}>Toma algum medicamento de uso contínuo?</h4>
              <Typography>{Boolean(modalContent?.triage?.drugs) ? modalContent?.triage?.drugs : 'Não há informação'}</Typography>
              <h4 style={{ marginTop: 40 }}>Alergia a algum medicamento?</h4>
              <Typography>{Boolean(modalContent?.triage?.allergies) ? modalContent?.triage?.allergies : 'Não há informação'}</Typography>
              <h4 style={{ marginTop: 40 }}>Possui doença crônica?</h4>
              <Typography>{Boolean(modalContent?.triage?.diseases) ? modalContent?.triage?.diseases : 'Não há informação'}</Typography>
              <h4 style={{ marginTop: 40 }}>Anexos</h4>
              {!modalContent?.triage?.attachmentHasTriage?.length ?
                <Typography>Não há informação</Typography> :
                modalContent?.triage?.attachmentHasTriage.map((item, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    style={{ borderColor: '#DF9000', color: '#DF9000', margin: 10 }}
                    href={item.uri}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Anexo {index + 1}
                  </Button>
                ))}
            </div>
            <div style={{ marginTop: 50 }}></div>
            <hr />
            <div style={{ marginTop: 50 }}>
              <h3><strong>Encaminhamento</strong></h3>
              {modalContent?.referral?.length 
                ? modalContent.referral.map((r) => (<Typography>{r.speciality?.name}</Typography>)) 
                : <Typography>Não há informação</Typography>
              }
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            OK
              </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
