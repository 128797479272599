import apiGraphql from "../../../services/api";
import * as asyncActions from "./async.action";

export function listMedicalAppointmentRequests(
  providerId,
  employeeId = null,
  limit = 10,
  page = 1
) {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
            query {
                listAppointmentRequests(
                input: {
                  page: ${page}
                  limit: ${limit}
                  employeeId: "${employeeId}"
                }
              ) {
                count,
                rows {
                medicalAppointment{
                  id,
                  status,
                  appointmentLocation
                  appointmentType
                  price
                  speciality{ name },
                  patient { id, socialName, user { name} },
                  healthOperatorRequest {
                    id
                    createdAt
                    healthOperator {
                      id
                      fantasyName
                      companyName
                    }
                  } 
                  providerId
                  employeeId
                  establishmentId
                }
                examAppointment{
                  id
                  status
                  patient { id, socialName, user { name}  },
                  providerHasServicesProvided {
                      serviceProvided {
                        description
                      }
                  }
                 examsRequests {
                   id
                   providerId
                   employeeId
                   establishmentId
                 }
                }
                descriptions
                id,
                closestDate,
                initialDate,	
                finalDate,	
                daysOfWeek,
                startTime,
                endTime,
                proposedDate1,
                proposedDate2,
                acceptedDate,
                createdAt
              }
            }
            }
          `,
        },
      });
      const appointmentRequests = res.data.data;

      const appointmentRequestsFiltered = [
        ...appointmentRequests.listAppointmentRequests.rows,
      ];
      // const appointmentRequestsFiltered = filterAppointmentsRequests(
      //   appointmentRequests.listAppointmentRequests.rows,
      //   providerId,
      //   employeeId
      // );

      const count =
        appointmentRequestsFiltered.length > 0
          ? appointmentRequests.listAppointmentRequests.count
          : 0;
      dispatch({
        type: "SET_APPOINTMENT_REQUESTS",
        payload: appointmentRequestsFiltered,
      });

      dispatch({
        type: "SET_APPOINTMENT_REQUESTS_COUNT",
        payload: count,
      });
    } catch (error) {
      console.log(error);
      dispatch(
        asyncActions.requestFailure(
          "Erro ao tentar listar as solicitações. Tente novamente, por favor."
        )
      );
    }
  };
}

export function secretaryListAppointmentRequests(
  limit = 10,
  page = 1,
  providerId,
  employeeId = null
) {
  return async (dispatch) => {
    try {
      const res = await apiGraphql({
        data: {
          query: `
            query {
              secretaryListAppointmentRequests(
                input: {
                  providerId: "${providerId}",
                  employeeId: "${employeeId}",
                  page: ${page}
                  limit: ${limit}
                }
              ) {
                count,
                rows {
                medicalAppointment{
                  id,
                  status,
                  appointmentLocation,
                  providerId
                  employeeId
                  speciality{ name },
                  patient { id, socialName, user { name}  },
                  appointmentType
                  price
                  healthOperatorRequest {
                    id
                    createdAt
                    healthOperator {
                      id
                      fantasyName
                      companyName
                    }
                  } 
                }
                examAppointment{
                id
                status
                patient { id, socialName, user { name}  },
                providerHasServicesProvided {
                    serviceProvided {
                      description
                    }
                  }
                 
                }
                
                id,
                closestDate,
                initialDate,	
                finalDate,	
                daysOfWeek,
                startTime,
                endTime,
                proposedDate1,
                proposedDate2,
                acceptedDate,
                createdAt
                descriptions
              }
            }
            }
          `,
        },
      });
      const appointmentRequests = res.data.data;

      const appointmentRequestsFiltered = [
        ...appointmentRequests.secretaryListAppointmentRequests.rows,
      ];
      // const appointmentRequestsFiltered = filterAppointmentsRequests(
      //   appointmentRequests.secretaryListAppointmentRequests.rows,
      //   providerId,
      //   employeeId
      // );

      const count =
        appointmentRequestsFiltered.length > 0
          ? appointmentRequests.secretaryListAppointmentRequests.count
          : 0;
      dispatch({
        type: "SET_APPOINTMENT_REQUESTS",
        payload: appointmentRequestsFiltered,
      });
      dispatch({
        type: "SET_APPOINTMENT_REQUESTS_COUNT",
        payload: count,
      });
    } catch (error) {
      dispatch(
        asyncActions.requestFailure(
          "Erro ao tentar listar as solicitações. Tente novamente, por favor."
        )
      );
    }
  };
}

// function filterAppointmentsRequests(rows, providerId, employeeId) {
//   return rows.filter((item) => {
//     if (item.examAppointment.length > 0 && employeeId) return false;
//     if (item.examAppointment.length > 0 && !employeeId) return true;

//     if (employeeId) {
//       return item.medicalAppointment.employeeId === employeeId;
//     }

//     return (
//       item.medicalAppointment.providerId === providerId &&
//       item.medicalAppointment.employeeId === null
//     );
//   });
// }
