import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  list: {
      display: 'flex',
      width: props => props.width ? props.width : '100%',
      justifyContent: 'flex-start',
      alignItems: "center",
  },
  listItem: {
    "& > span": {
        textAlign: 'center',
        fontSize: '14px'
    }
  },
  selected: {
    backgroundColor: 'transparent !important',
    borderBottom: '2px solid #FEC65A',
    "& > div > span" :{
        fontWeight: '600 !important',
    }
  },
  0: {
      width: props => props.width0 ? props.width0 : '12%',
  },
  1: {
      width: props => props.width1 ? props.width1 : '20%',
      marginLeft: '20px',
  },
  2: {
      width: props => props.width2 ? props.width2 :'28%',
      marginLeft: '20px',
  },
  3: {
      width: props => props.width3 ? props.width3 :'17%',
      marginLeft: '20px',
  },
  4: {
      width: props => props.width4 ? props.width4 :'17%',
      marginLeft: '20px',
  }
}));

export default function SelectedListItem(props) {
  const classes = useStyles(props);

  const handleListItemClick = (index) => {
    props.setSelectedList(index);
  };

  return (
    <div className={classes.root}>
        <List 
            component="ul" 
            aria-label="main mailbox folders"
            classes={{
                root: classes.list
            }}
        >
            {props.listItems.map((item, index) => {
                return (
                    <ListItem
                        key={item.text}
                        button
                        selected={props.selectedList === index}
                        onClick={(event) => handleListItemClick(index)}
                        classes={{root:classes[index], selected: classes.selected}}
                    >
                        <ListItemText primary={item.text} classes={{root: classes.listItem}}/>
                    </ListItem>
                )
            })}
        </List>
    </div>
  );
}