import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Grow } from '@material-ui/core';

import api from '../../../../services/api';

import Input from '../../../../common/input';
import Button from '../../../../common/button';
import InputCurrency from '../../../../common/inputCurrency';
import SearchBar from '../../../../common/searchBar/searchBar';
import SnackBar from '../../../../common/snackbar';
import Loading from '../../../../common/loading';
import Check from '../../../../assets/check-circle-full.png';
import empty from '../../../../common/validators/empty';
import calcTax from '../../../../utils/calcTax'


import * as consultationsActions from "../../../../common/store/actions/registerConsultations";
import * as asyncActions from '../../../../common/store/actions/async.action';

const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '34%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '12px',
  },
  success: {
    backgroundColor: "#EDF9F0",
    color: "#287D3C",
    fontWeight: 600,
  },
})

const ModalEditSpeciality = ({data, index, ...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userType = window.localStorage.getItem("userType")
  let providerId
  if(userType === "DOCTOR") {
    const {doctorId} = JSON.parse(window.localStorage.getItem("docData"));
    providerId = doctorId
  }
  if(userType === "PROVIDER_OTHER_PROFESSILNALS") {
    const { professionalId } = JSON.parse(window.localStorage.getItem("providerData"))
    providerId = professionalId
  }
  
  const unitId = window.localStorage.getItem("Unit-Vida-Connect");

  const openModal = useSelector((state) => state.RegisterConsultations.openAddModal);
  const taxAppointmentFeeProvider = useSelector( (state) => state.RegisterConsultations.taxAppointmentFeeProvider);
  const taxAppointmentFeePatient = useSelector( (state) => state.RegisterConsultations.taxAppointmentFeePatient);

  const [open, setOpen] = useState(false);

  const [speciality, setSpeciality] = useState({name:"", id:""});
  const [specialityError, setSpecialityError] = useState("");

  const [rqe, setRqe] = useState("");
  const [rqeError, setRqeError] = useState("");

  const [price, setPrice] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect( () => {
      setOpen(openModal);
  },[openModal]);

  const handleClose = () => {
    setSpeciality({name:"", id:""});
    setSpecialityError("");
    setRqe("");
    setPrice("");
    setLoading(false);
    setSuccess(false);
    dispatch(consultationsActions.openAddModal(false));
  };

  const addProviderConsultation = async ({providerId, unitId, specialityId, rqe, price}) => {
    try {
      const res = await api({
        data: {
          query:
          `
            mutation($input: DoctorHasSpecialitiesInput!) {
              createDoctorHasSpeciality(input: $input) {
                active
              }
            }
          `,
          variables: {
            input: {
              doctor: providerId,
              unitId,
              specialityId,
              rqe,
              price: parseFloat(price)
            }
          }
        }
      });
      if(res.data.data) {
        const {active} = res.data.data.createDoctorHasSpeciality;
        const payload = {speciality, price, active};
        dispatch(consultationsActions.RegisterConsultation(payload));
        setSuccess(true);
      } else {
        dispatch(asyncActions.requestFailure("Houve um problema durante a inclusão da nova especialidade."))
      };
    } catch(error) {
      dispatch(asyncActions.requestFailure("Houve um problema durante a inclusão da nova especialidade."))
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
      try {
        const specialityId = speciality.id;
        await addProviderConsultation({doctorId: providerId, unitId, specialityId, rqe, price});

        setLoading(false);
      } catch(error) {
        dispatch(asyncActions.requestFailure("Houve um problema durante a atualização do valor da consulta."))
        setLoading(false);
      }
  }

  return (
    <div>
      <Dialog 
        open={open}
        onClose={handleClose} 
        aria-labelledby="form-dialog-title" 
        classes={{
          paper: classes.root
        }}
        TransitionComponent={Grow}
        transitionDuration={{enter: 200, exit: 0}}
      >
        <div className={`${classes.header} ${success ? classes.success : null}`}>
          <DialogTitle>
            {success ?
              <div><img src={Check} alt="success"/><span> Especialidade adicionada</span></div>
              :
              <span>Adicionar Especialidade</span>
            }
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          <form onSubmit={props.handleSubmit}>
            <div className="modal-body">
              <SearchBar
                  type="specialities"
                  width="100%"
                  label="Nova Especialidade"
                  placeholder="Insira uma especialidade"
                  value={speciality.name}
                  onChange={setSpeciality}
                  onBlur={() => setSpecialityError(empty(speciality).typeError)}
                  error={specialityError}
                  asyncAction={asyncActions.getSpecialities}
              />
              <Input
                width="100%"
                label="RQE"
                value={rqe}
                onChange={(e) => setRqe(e.target.value)}
                onBlur={() => setRqeError(empty(rqe).typeError)}
                error={rqeError}
              />
              <InputCurrency
                width="100%"
                label="Valor da Consulta"
                value={price}
                onChange={setPrice}
                dataTest="modal-cadastrar-consulta-input-preco"
              />
              <DialogContentText className={classes.lettering}>
                O valor cobrado do médico pela plataforma será de:
                {
                  Intl.NumberFormat(
                    'pt-BR', 
                    { style: 'currency', currency: 'BRL' })
                    .format(calcTax({ tax: taxAppointmentFeeProvider, price: price, sum: false })
                  )
                }
              </DialogContentText>
              <DialogContentText className={classes.lettering}>
                O valor final que a plataforma irá cobrar do paciente será de:
                {
                  Intl.NumberFormat(
                    'pt-BR', 
                    { style: 'currency', currency: 'BRL' })
                    .format(calcTax({ tax: taxAppointmentFeePatient, price: price, sum: true })
                  )
                }
              </DialogContentText>
              <SnackBar />
            </div>
            <DialogActions className={classes.extraMargin}>
              {loading ? 
                <Loading width="80px"/>
                :
                success ?
                  (<Button 
                  onClick={handleClose}
                  width="120px"
                  border="1px solid #DF9000"
                  backgroundColor="#FFFFFF"
                  color="#DF9000"
                  >
                    Ok
                  </Button>)
                  :
                  (
                    <>
                      <Button 
                        onClick={handleClose}
                        width="120px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                        margin="0 20px 0 0"
                      >
                        Cancelar
                      </Button>
                      <Button 
                        onClick={handleSubmit}
                        width="120px"
                        border="1px solid #DF9000"
                        backgroundColor="#FFFFFF"
                        color="#DF9000"
                      >
                        Salvar
                      </Button>
                    </>
                  )
              }
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalEditSpeciality;
