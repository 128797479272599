import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';

import Button from '../../../common/button';
import Check from '../../../assets/check-circle-full.png';
import Loading from '../../../common/loading';
import RadioButtons from '../../../common/radioButton/radioButton'
import * as consultationActions from '../../../common/store/actions/consultation.action';


import * as examsActions from "../../../common/store/actions/registerExams";
import * as asyncActions from '../../../common/store/actions/async.action';
import SearchServiceProvided from '../../../common/SearchServiceProvided'


import SnackBar from '../../../common/snackbar';
const useStyles = makeStyles({
  root: {
    width: props => props.width ? props.width : '55%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  term: {
    lineHeight: '150%',
    fontSize: '18px'
  },
  consentTerm: {
    border: '1px solid #E7E7ED',
    borderRadius: '4px',
    padding: '4%',
    "& > h3": {
      marginTop: 0,
      paddingBottom: '16px',
      borderBottom: '1px solid #E7E7ED'
    },
    "& > p": {
      fontSize: '16px'
    }
  },
  success: {
    backgroundColor: "#EDF9F0",
    color: "#287D3C",
    fontWeight: 600,
  },
  error: {
    fontSize: '14px',
    color: '#DA1414'
  }
})


const ExamRequestInsidePlataformModal = (props) => {

  let examsRequests = useSelector((state) => state.ConsultationReducer.consultationData.examsRequests) || [];

  // console.log('EXAMS REQUESTS', examsRequests)


  

  const [examsFromApi, setExamsFromApi] = useState({});
  const [examError, setExamError] = useState("");

  const [examLoading, setExamLoading] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles(props);
  const examExistsState = useSelector( (state) => state.Exams.examExists);
  const [examCode, setExamCode] = useState("");
  

  const [examId, setExamId] = useState("");
  
  const [name, setName] = useState("");
  
  const [confidential, setConfidential] = useState("Sim");

  const medicalAppointmentId = window.localStorage.getItem("appointId");
  const patientId = window.localStorage.getItem("patientId");
  const providerId = window.localStorage.getItem("Prov-Vida-Connect");
  const establishmentId = window.localStorage.getItem("establishmentId");
  const employeeId = window.localStorage.getItem("employeeId")
  const consentData = useSelector((state) => state.ConsultationReducer);
  const open = consentData.openExamRegisteredRequest;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(()=> {
    setExamId(examsFromApi.id)
    setName(examsFromApi.description)
    setExamCode(examsFromApi.code)
    dispatch(examsActions.GetExam(examsFromApi.id))
    //eslint-disable-next-line
  }, [examsFromApi]) 

  const handleClose = () => {
    
    setExamCode("")
    setName("");
    
    setLoading(false);
    setSuccess(false);
    dispatch(consultationActions.openExamRegisteredRequestModal(false))
  };



  function handleSubmit(e) {
    e.preventDefault();
            
          if(!name)
          {    
            dispatch(asyncActions.requestFailure("Selecione um exame."))
            return
          }
          
        if(examExistsState > 0) { 
          dispatch(asyncActions.requestFailure("Este exame já foi incluído na lista."))
          return
        }
        const examsRequestsNew = {
          serviceProvidedId: examId,
          medicalAppointmentId,
          patientId,
          providerId,
          establishmentId,
          employeeId,
          done: false,
          isConfidential: confidential === "Sim" ? true: false
        }
        const payload = { 
          examCode,
          name,
          examId
        };
        
        dispatch(examsActions.RegisterExam(payload));
      
        
        dispatch(consultationActions.setConsultationData({examsRequests: [...examsRequests, examsRequestsNew]}))
    
      
  
      dispatch(consultationActions.openExamRegisteredRequestModal(false))
      setExamCode("")
      setName("");
      
      
      setConfidential("Sim")    

      return true;
  
  }

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.root
        }}
        TransitionComponent={Grow}
        transitionDuration={{ enter: 200, exit: 0 }}
      >
        <div className={`${classes.header} ${success ? classes.success : null}`}>
          <DialogTitle>
            {
              success ?
                <div>
                  <div>
                    <img src={Check} alt="success" />
                    <span> Procedimento adicionado</span>
                  </div>
                </div>
                :
                <span>Adicionar Exame</span>
            }
          </DialogTitle>
          
        </div>
        <DialogContent dividers>
        <form onSubmit={props.handleSubmit}>
            
            <div className="modal-body">
              

            <SearchServiceProvided
        onChange={setExamsFromApi}
        error={setExamError}
        isLoading={setExamLoading}
        value={name}
      />
      {Boolean(examError) && <span>{examError}</span>}
      {!examLoading && <span>Carregando exames...</span>}

               <RadioButtons
                label="O exame tem resultado sigiloso?"
                width="113px"
                value={confidential}
                onChange={(e) => setConfidential(e.target.value)}
                
              />
              
              <SnackBar />
            </div>
            <DialogActions className={classes.extraMargin}>
              {loading ? 
                <Loading width="80px"/>
                :
                success ?
                  (<Button 
                  onClick={handleClose}
                  width="120px"
                  border="1px solid #DF9000"
                  backgroundColor="#FFFFFF"
                  color="#DF9000"
                  >
                    Ok
                  </Button>)
                  :
                  (
                    <>
                      <Button 
                        onClick={handleClose}
                        width="120px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                        margin="0 20px 0 0"
                      >
                        Cancelar
                      </Button>
                      <Button 
                        onClick={handleSubmit}
                        width="120px"
                        border="1px solid #DF9000"
                        backgroundColor="#FFFFFF"
                        color="#DF9000"
                      >
                        Salvar
                      </Button>
                    </>
                  )
              }
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ExamRequestInsidePlataformModal;
