import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {format} from 'date-fns';

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import "../style.css";
import * as asyncActions from "../../../../common/store/actions/async.action";
import Topic from "../../../../common/topic";
import Button from '../../../../common/button';
import Input from '../../../../common/input';
import SnackBar from '../../../../common/snackbar/index';
import Loading from '../../../../common/loading';

import empty from '../../../../common/validators/empty';

const useStyles = makeStyles( {
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    borderBottom: '1px solid #E7E7ED',
  },
  sideBySide: {
    display: 'flex',
    width: '55%',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  },
  lettering2: {
    color: "#393A4A",
  },
  buttonBg: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F7F8F9',
    display: 'flex',
    justifyContent: "flex-end",
    padding: '22px',
    marginTop: '28px',
    boxSizing: 'border-box',
  }
})

const ModalCreateWorkTime = ({selectedDays, durationInvalid, ...props}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const schedule = useSelector((state) => state.ScheduleReducer);
    const loading = useSelector((state) => state.AsyncReducer.loading);
    const [duration, setDuration] = useState("");
    const [durationError, setDurationError] = useState({
      error: true,
      typeError: ""
    })


    useEffect(()=>{
      props.onChange(duration)
    }, [duration, props.onChange, props]);
    
    const onSaveAndNext = () => {
      if(duration < 1) {
        setDurationError({...durationError, typeError:"A duração precisa ser maior que zero."})
      } else {
        //Dispatching to create schedule
        const beginSchedule = format(schedule.beginSchedule, 'yyyy-MM-dd');
        const endSchedule = format(schedule.endSchedule, 'yyyy-MM-dd');
        const appointmentDuration = parseFloat(duration);
        const daysOfWeek = schedule.daysOfWeekWithHours.map( (day) => {
          //When selector bring back data from Redux, its automatically converts JSON -> Date, that's why day.beginShift is in current local time
          const beginShiftUtc = JSON.stringify(day.beginShift).slice(12,17);
          const endShiftUtc = JSON.stringify(day.endShift).slice(12,17);
          const beginShift = format(day.beginShift, 'HH:mm');
          const endShift = format(day.endShift, 'HH:mm');
          return {
            ...day,
            beginShift,
            endShift,
            beginShiftUtc, 
            endShiftUtc,
          }
        });
        const employeeId = window.localStorage.getItem("employeeId");
        const providerId = window.localStorage.getItem("Prov-Vida-Connect");
        const unitId = window.localStorage.getItem("Unit-Vida-Connect");
        const successAction= props.handleNext;
        if(employeeId) {
          const payload = {
            beginSchedule,
            endSchedule,
            appointmentDuration,
            daysOfWeek,
            employeeId,
            unitId
          };
          dispatch(asyncActions.createEmployeeSchedule({successAction, ...payload}));
        } else {
          const payload = {
            beginSchedule,
            endSchedule,
            appointmentDuration,
            daysOfWeek,
            providerId,
            unitId
          };
          dispatch(asyncActions.createSchedule({successAction, ...payload}));
        }
        ////////////////////////////////
      }
    }

    return (
      <>
        <div className={classes.header}>
          <DialogTitle>
            Criar Agenda
          </DialogTitle>
          <IconButton aria-label="close" onClick={() => props.handleClose()}>
            <CloseIcon />
          </IconButton>
      </div>
        <DialogContent>
          <form>
            <div className="modal-body">
              <Topic
                title="Qual o tempo de duração do atendimento?"
                fontSize="16px"
                marginTop="20px"
              />
              <div className={classes.sideBySide}>
                <Input
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  onBlur={() => setDurationError(empty(duration))}
                  error={durationError.typeError}
                  borderRadius="4px 0 0 4px"
                />
                <Input
                  value="minutos"
                  InputProps={{readOnly: true}}
                  backgroundColor="#F1F2F5"
                  borderRadius="0 4px 4px 0"
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <Typography
        color="error"
        align="center"
        style={{display: 'block',fontSize: '12.5px', padding: '30px 10px', margin: "0px 5px"}}
        >{durationInvalid ? 'Horário inserido é inválido. Insira um horário de acordo com o tempo selecionado anteriormente' : ''}
        </Typography>
        <div className={classes.buttonBg}>
          {loading ?
            <Loading width="50px"/>
            :
            <>
              <Button 
                onClick={() => {
                  props.setStep((prevStep) => prevStep-1);
                  dispatch(asyncActions.cleanError());
                }}
                width="120px"
                border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
                margin="0 20px 0 0"
              >
                Voltar
              </Button>
              <Button 
                onClick={onSaveAndNext}
                width="120px"
                border="1px solid #DF9000"
                backgroundColor="#FFFFFF"
                color="#DF9000"
                disabled={durationInvalid}
              >
                Próximo
              </Button>
            </>
          }
        </div>
        <SnackBar/>
      </>
  );
};

export default ModalCreateWorkTime;
