const INITIAL_STATE = {
  patientsList: [],
  totalPatientsOnDay: 0,
  data: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_PATIENTS_LIST":
      return {
        ...state,
        patientsList: action.payload,
      }
    case "STORE_MEDICAL_APPOINTMENTS_DATA":
      return {
        ...state,
        data: action.payload
      }
      case "SET_TOTAL_PATIENTS_ON_DAY":
        return {
          ...state,
          totalPatientsOnDay: action.payload
        }
    default:
      return state;
  }
}
