export function RegisterConsultation(payload) {
  return {
    type: "REGISTER_CONSULTATIONS",
    payload: payload,
  };
}

export function ResetConsultation(payload) {
  return {
    type: "RESET_CONSULTATIONS",
    payload: payload,
  };
}

export function alterActive(index) {
  return {
    type: "ALTER_ACTIVE_CONSULTATION",
    payload: index,
  };
}

export function deleteConsultation(index) {
  return {
    type: "DELETE_CONSULTATION",
    payload: index,
  };
}

export function updateConsultation(index, details) {
  return {
    type: "UPDATE_CONSULTATION",
    payload: index,
    details,
  };
}

export function deleteAllConsultations() {
  return { type: "DELETE_ALL_CONSULTATIONS" };
}

export function openModal(payload) {
  return {
    type: "OPEN_MODAL_CONSULTATION",
    payload
  }
}

export function openEditModal(payload){
  return {
    type: "OPEN_EDIT_MODAL_CONSULTATION",
    payload
  }
}

export function openDeleteModal(payload){
  return {
    type: "OPEN_DELETE_MODAL_CONSULTATION",
    payload
  }
}

export function openAddModal(payload){
  return {
    type: "OPEN_ADD_MODAL_CONSULTATION",
    payload
  }
}

export function availableSpecialities(payload) {
  return {
    type: "SET_AVAILABLE_SPECIALITIES",
    payload
  }
}

export function waitingData(payload) {
  return {
    type: "WAITING_CONSULTATIONS_DATA"
  }
}

export function setChoosenUnitConsultations(payload) {
  return {
      type:"SET_CHOOSEN_UNIT_CONSULTATIONS",
      payload
  }
}

export function setConsultationFee(payload) {
  return {
      type:"SET_CONSULTATION_FEE",
      payload
  }
}