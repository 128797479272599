import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {format} from 'date-fns';

import { generatePdf } from '../../../utils/certification/pdfGenerator'
import {documentSignature} from '../../../utils/certification/certification';

import empty from '../../../common/validators/empty';
import telphoneValidator from "../../../common/validators/telephone";
import maskTelephone from "../../../common/mask/telephone";
import cpfMask from "../../../common/mask/cpf";

import SnackBar from '../../../common/snackbar/index';
import Input from '../../../common/input';
import TextArea from '../../../common/textarea';
import Button from '../../../common/button';
import Check from '../../../assets/check-circle-full.png';
import Loading from "../../../common/loading";

import * as consultationActions from '../../../common/store/actions/consultation.action';
import * as asyncActions from '../../../common/store/actions/async.action';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';
import { handlePatientName } from "../../../common/handlePatientName";

// Material UI Styling

const useStyles = makeStyles( {
    root: {
        width: props => props.width ? props.width : '70vw',
        maxWidth: "70vw !important",
        fontSize: "inherit",
    },
    header: {
        display: 'flex',
        justifyContent: "space-between"
    },  
    topic: {
        marginBottom: "30px",
        fontSize: '16px',
        "& > .title": {
            fontWeight: '600',
        },
        "& > p": {
            lineHeight: '20px',
            color: "#6B6C7E",
            marginTop: '5px'
        }
    },
    retorno: {
        "& > div": {
            width: '100%',
            marginTop: "5%"
        }
    },
    success: {
        backgroundColor: "#EDF9F0",
        color: "#287D3C",
        fontWeight: 600,
    },
    extraMargin: {
        margin: '50px 0 21px 0',
        padding: 0
    },
    pdf: {
        height: '60vh',
    }
});

// Component

const PrescriptionModal = (props) => {
    const dispatch = useDispatch();
    const providerId = window.localStorage.getItem("Prov-Vida-Connect");
    const medicalAppointmentId = window.localStorage.getItem("appointId");
    const patientId = window.localStorage.getItem("patientId");
    const appointmentLocation = window.localStorage.getItem('appointmentLocation')
    const docData = JSON.parse(window.localStorage.getItem("docData"));
    const provData = JSON.parse(window.localStorage.getItem("providerData"));
    const  docName = docData?.name || provData?.name
    const email = docData?.email || provData?.email
    const cpf = docData?.cpf || provData?.cpf
    const crm = docData?.crm || ''
    const crmUf = docData?.uf || provData?.uf
    const data = useSelector((state) => state.ConsultationReducer)

    const open = data.openPrescription;
    const classes = useStyles(props);

    const [renderPDF, setRenderPDF] = useState(false);

    const [success, setSuccess] = useState(false);

    const [doctorName, setDoctorName]= useState("");
    const [patientName, setPatientName] = useState("");
    const [patientCpf, setPatientCpf] = useState("");

    const [prescription, setPrescription] = useState("");
    const [prescriptionError, setPrescriptionError] = useState("");

    const [location, setLocation] = useState(appointmentLocation === 'PRESENTIAL' ? 'Consultório' : 'Online');
    const [locationError, setLocationError] = useState("");

    const [CNES, setCNES] = useState(data.local.cnes);
    const [CNESError, setCNESError] = useState("");

    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");

    const [neighborhood, setNeighborhood] = useState("");
    const [neighborhoodError, setNeighborhoodError] = useState("");

    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState("");

    const [addressUf, setAddressUf] = useState("");
    const [addressUfError, setAddressUfError] = useState("");

    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");

    const [loading, setLoading] = useState(false);
    
    useEffect( () => {
        if(data.data.patient) {
            const patName = handlePatientName(data.data.patient, false)
            setPatientName(patName);
            setDoctorName(docName);
            setPatientCpf(cpfMask(data.data.patient.user.cpf))
        }

        if(data.local) {
            let address = data.local.address || ''
            if(data.local.number) address += `, ${data.local.number}`
            if(data.local.complement) address += `, ${data.local.complement}`
            
            setCNES(data.local.cnes || '')
            setAddress(address)
            setNeighborhood(data.local.neighborhood || '')
            setCity(data.local.city || '')
            setAddressUf(data.local.district || '')
            setPhone(maskTelephone(String(data.local.phone)) || '')
        }
        setLocation(appointmentLocation === 'PRESENTIAL' ? 'Consultório' : 'Online')
        //eslint-disable-next-line
    }, [data])

    const handleClose = () => {
        setDoctorName("");
        setPatientName("");
        setPatientCpf("")
        setPrescription("");
        setPrescriptionError("");
        setLocation("");
        setLocationError("");
        setCNES("");
        setCNESError("");
        setAddress("");
        setAddressError("");
        setNeighborhood("");
        setNeighborhoodError("");
        setCity("");
        setCityError("");
        setAddressUf("");
        setAddressUfError("");
        setPhone("");
        setPhoneError("");
        setRenderPDF(false);
        setSuccess(false)
        setLoading(false)
        dispatch(consultationActions.openPrescriptionModal(false));
    }

    const handleSave = async () => {
        let emptyError = [
            prescription,
            location, 
            CNES, 
            address, 
            neighborhood,
            city,
            addressUf
        ].reduce((error, field) => error || Boolean(empty(field).typeError), false);

        const invalidTelephone = telphoneValidator(phone).typeError;

        if(emptyError || invalidTelephone) {
            setPrescriptionError(empty(prescription).typeError);
            setLocationError(empty(location).typeError);
            setCNESError(empty(CNES).typeError);
            setAddressError(empty(address).typeError);
            setNeighborhoodError(empty(neighborhood).typeError);
            setCityError(empty(city).typeError);
            setAddressUfError(empty(addressUf).typeError);
            setPhoneError(invalidTelephone);
        } else {
            setLoading(true);
            const documentPayload = {
                title:"Prescrição",
                text: prescription,
                patientName,
                patientCpf,
                doctorName,
                crm,
                crmUf,
                localDeAtendimento: address,
                CNES,
                address,
                neighborhood,
                city,
                addressUf,
                phone,
                emission: format(new Date(), 'dd/MM/yyyy')
            };            
            const fileName="prescrição.pdf";
            const user = {name: docName, email, identifier: cpf};
            // const user = {
            //     name: "Alan Mathison Turing",
            //     identifier: "56072386105",
            //     email: "testturing@lacunasoftware.com"
            // };
            const authenticationPayload = {
                fileName,
                user,
                providerId,
                medicalAppointmentId,
                patientId,
                documentType: "DOCTOR_PRESCRIPTION",
            }
            try {
                const {embedUrl} = await generatePdf(documentPayload, authenticationPayload);
                setRenderPDF(true);
                await documentSignature("pdf", embedUrl, setSuccess, dispatch, consultationActions.setCountPrescription);
                consultationActions.setCountPrescription()//teste
                setLoading(false);
            } catch(error) {
                dispatch(asyncActions.requestFailure("Não foi possível exibir o PDF para assinatura agora."));
                setLoading(false);
            }
        }
    }

    return (
            <div>
                <Dialog 
                    open={open}
                    onClose={handleClose} 
                    aria-labelledby="form-dialog-title" 
                    classes={{
                    paper: classes.root
                    }}
                    TransitionComponent={Grow}
                    transitionDuration={{enter: 200, exit: 0}}
                    disableBackdropClick={renderPDF || loading}
                    disableEscapeKeyDown={renderPDF || loading}
                >
                <div className={`${classes.header} ${success ? classes.success : null}`}>
                    <DialogTitle>
                        {renderPDF ?
                            <span>Assine o documento</span>
                            :
                            success ?
                                <div><img src={Check} alt="success"/><span> Prescrição enviada</span></div>
                                :
                                <span>Prescrição</span>
                        }
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogContent dividers>
                    {renderPDF ?
                        <div id="pdf" className={classes.pdf}></div>
                        :
                        <div>
                            <div className="file-input">

                            <Input
                                width="75%"
                                label="Paciente"
                                InputProps={{readOnly: false}}
                                type="text"
                                onChange={(e) => setPatientName(e.target.value)}
                                value={patientName}
                                backgroundColor={"#F7F8F9"}
                                border={"none"}
                                fontSize="14px !important"
                                />
                            <Input
                                width="25%"
                                label="CPF"
                                InputProps={{readOnly: false}}
                                type="text"
                                // onChange={(e) => setPatientCpf(cpfMask(e.target.value))}
                                value={patientCpf}
                                backgroundColor={"#F7F8F9"}
                                border={"none"}
                                fontSize="14px !important"
                                />
                            </div>
                            <TextArea
                                width="95%"
                                label="Prescrição"
                                placeholder="Medicamentos prescritos"
                                rows={6}
                                value={prescription}
                                onChange={setPrescription}
                                onBlur={() => {
                                    if(!prescription) setPrescriptionError("Esse campo é obrigatório");
                                    if(prescription) setPrescriptionError("");
                                }}
                                error={prescriptionError}
                            />
                            <div className="file-input">
                                <Input
                                    width="55%"
                                    label="Médico"
                                    InputProps={{readOnly: true}}
                                    type="text"
                                    value={doctorName}
                                    backgroundColor={"#F7F8F9"}
                                    border={"none"}
                                    fontSize="14px !important"
                                />
                                <Input
                                    width="35%"
                                    label="CRM"
                                    InputProps={{readOnly: true}}
                                    type="text"
                                    value={crm}
                                    backgroundColor={"#F7F8F9"}
                                    border={"none"}
                                    fontSize="14px !important"
                                />
                                <Input
                                    width="10%"
                                    label="UF"
                                    InputProps={{readOnly: true}}
                                    type="text"
                                    value={crmUf}
                                    backgroundColor={"#F7F8F9"}
                                    border={"none"}
                                    fontSize="14px !important"
                                />
                            </div>
                            <div className="file-input">
                                <Input
                                    width="55%"
                                    label="Local de atendimento"
                                    placeholder="Online, no consultório etc."
                                    type="text"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    onBlur={() => {
                                        if(!location) setLocationError("Esse campo é obrigatório");
                                        if(location) setLocationError("");
                                    }}
                                    error={locationError}
                                    fontSize="14px !important"
                                />
                                <Input
                                    width="45%"
                                    label="CNES"
                                    placeholder="CNES endereço da consulta"
                                    type="text"
                                    value={CNES}
                                    onChange={(e) => setCNES(e.target.value)}
                                    onBlur={() => {
                                        if(!CNES) setCNESError("Esse campo é obrigatório");
                                        if(CNES) setCNESError("");
                                    }}
                                    error={CNESError}
                                    fontSize="14px !important"
                                />
                            </div>
                            <div className="file-input">
                                <Input
                                    width="80%"
                                    label="Endereço"
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    onBlur={() => {
                                        if(!address) setAddressError("Esse campo é obrigatório");
                                        if(address) setAddressError("");
                                    }}
                                    error={addressError}
                                    fontSize="14px !important"
                                />
                                <Input
                                    width="20%"
                                    label="Bairro"
                                    type="text"
                                    value={neighborhood}
                                    onChange={(e) => setNeighborhood(e.target.value)}
                                    onBlur={() => {
                                        if(!neighborhood) setNeighborhoodError("Esse campo é obrigatório");
                                        if(neighborhood) setNeighborhoodError("");
                                    }}
                                    error={neighborhoodError}
                                    fontSize="14px !important"
                                />
                            </div>
                            <div className="file-input">
                                <Input
                                    width="50%"
                                    label="Cidade"
                                    type="text"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    onBlur={() => {
                                        if(!city) setCityError("Esse campo é obrigatório");
                                        if(city) setCityError("");
                                    }}
                                    error={cityError}
                                    fontSize="14px !important"
                                />
                                <Input
                                    width="15%"
                                    label="UF"
                                    type="text"
                                    value={addressUf}
                                    onChange={(e) => setAddressUf(e.target.value)}
                                    onBlur={() => {
                                        if(!addressUf) setAddressUfError("Esse campo é obrigatório");
                                        if(addressUf) setAddressUfError("");
                                    }}
                                    error={addressUfError}
                                    fontSize="14px !important"
                                />
                                <Input
                                    width="35%"
                                    label="Telefone"
                                    type="text"
                                    value={maskTelephone(phone)}
                                    onChange={(e) => setPhone(maskTelephone(e.target.value))}
                                    onBlur={() => setPhoneError(telphoneValidator(phone).typeError)}
                                    error={phoneError}
                                    fontSize="14px !important"
                                />
                            </div>
                            <Input
                                width="40%"
                                label="Data de emissão"
                                InputProps={{readOnly: true}}
                                value={format(new Date(), 'dd/MM/yyyy')}
                                backgroundColor={"#F7F8F9"}
                                border={"none"}
                                fontSize="14px !important"
                            />
                            <DialogActions className={classes.extraMargin}>
                                {success ?
                                    <Button 
                                        onClick={handleClose}
                                        width="120px"
                                        border="1px solid #DF9000"
                                        backgroundColor="#FFFFFF"
                                        color="#DF9000"
                                    >
                                        Ok
                                    </Button>
                                    :
                                    loading ?
                                        <Loading width="50px" />
                                        :
                                        <>
                                            <Button 
                                                onClick={handleClose}
                                                width="120px"
                                                border="1px solid #CDCED9"
                                                backgroundColor="#FFFFFF"
                                                color="#6B6C7E"
                                                margin="0 20px 0 0"
                                            >
                                                Não
                                            </Button>
                                            <Button
                                                width="120px"
                                                border="1px solid #DF9000"
                                                backgroundColor="#FFFFFF"
                                                color="#DF9000"
                                                onClick={handleSave}
                                            >
                                                Sim
                                            </Button>
                                        </>
                                }
                            </DialogActions>
                    </div>
                }
                <SnackBar />
                </DialogContent>
                </Dialog>
            </div>
    );
};

export default PrescriptionModal;
