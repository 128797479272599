import React from 'react'
import { View, Text } from '@react-pdf/renderer'

import { DoctorData } from './doctorData'
import { styles } from '../styles'

export const ExamRequest = ({documentPayload}) => {

    const {
        patientName,
        patientCpf,
        clinicalAssessment,
        text
    } = documentPayload

    return(
        <View style={styles.text}>
            <Text>Paciente: <Text style={styles.textBold}>{patientName}</Text></Text>
            <Text>CPF: <Text style={styles.textBold}>{patientCpf}</Text></Text>
            <Text>Indicação Clínica: {clinicalAssessment}</Text>
            <Text style={[styles.topSeparator, styles.textBold]}>Solicito: </Text>
            <Text style={{minHeight: 300}}>{text}</Text>
            <DoctorData documentPayload={documentPayload}/>
        </View>
    )
}