import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Select from "../select";

const useStyles = makeStyles((theme) => ({
  pagination: {
    position: "absolute",
    bottom: "3vh",
    right: "4vw",
    left: "290px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageSelection: {
    width: "40%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export default function BasicPagination({
  page,
  setPage,
  itemsPerPage,
  setItemsPerPage,
  firstItem,
  lastItem,
  length,
  ...props
}) {
  const classes = useStyles(props);
  const count = Math.ceil(length / itemsPerPage);
  return (
    <div className={classes.pagination}>
      <div className={classes.pageSelection}>
        <div>
          <Select
            width="80px"
            border="none"
            itemList={[
              // {value:10, exhibit:"10 itens"},
              // {value:20, exhibit:"20 itens"},
              { value: 50, exhibit: "50 itens" },
            ]}
            onChange={(value) => {
              setItemsPerPage(value);
              setPage(1);
            }}
            value={itemsPerPage}
            onBlur={() => {}}
          />
        </div>
        <div className={classes.items}>
          <span>
            Mostrando do {firstItem + 1} ao{" "}
            {lastItem <= length ? lastItem : length} de {length}{" "}
          </span>
        </div>
      </div>
      <div>
        <Pagination
          count={count}
          shape="rounded"
          page={page}
          onChange={(event, page) => setPage(page)}
        />
      </div>
    </div>
  );
}
