import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import StepByStep from '../../../common/stepByStep/stepByStep';
import Input from "../../../common/input";
import Textarea from "../../../common/textarea";
import InputFile from "../../../common/fileInput";
import Topic from "../../../common/topic";
import inputFileValidator, { validationFileType } from '../../../common/validators/fileInput'
import SyncSnackBar from '../../../common/snackbar/syncAlternative'


import * as personalDatasActions from "../../../common/store/actions/personalDatas";

export default (props) => {
  const personalCurriculum = useSelector(
    (state) => state.PersonalDatas.finalRegister[0].pf.curriculum
  );
  const dispatch = useDispatch();

  const [openSyncSnackBar, setOpenSyncSnackBar] = useState(false)
  const [errorMessageSyncSnackBar, setErrorMessageSyncSnackBar] = useState('')

  const [photo, setPhoto] = useState(personalCurriculum.photo);
  const [photoError, setPhotoError] = useState({
    error: true,
    typeError: ""
  })

  const [about, setAbout] = useState(personalCurriculum.about);
  // const [aboutError, setAboutError] = useState({
  //   error: true,
  //   typeError: "",
  // })

  const [experience, setExperience] = useState(personalCurriculum.experience);
  // const [experienceError, setExperienceError] = useState({
  //   error: true,
  //   typeError: "",
  // })

  const [medicalConditions, setMedicalConditions] = useState(
    personalCurriculum.medicalConditions
  );
  // const [medicalConditionsError, setMedicalConditionsError] = useState({
  //   error: true,
  //   typeError: "",
  // })

  const [formation, setFormation] = useState(personalCurriculum.formation);
  // const [formationError, setFormationError] = useState({
  //   error: true,
  //   typeError: "",
  // })

  const [linkCurriculumLattes, setLinkCurriculumLattes] = useState(
    personalCurriculum.linkCurriculumLattes
  );
  // const [linkCurriculumLattesError, setLinkCurriculumLattesError] = useState({
  //   error: true,
  //   typeError: "",
  // })

  useEffect( () => {
    setPhotoError({
      ...photoError,
      error: inputFileValidator(photo, 25).error,
    });
    // eslint-disable-next-line
  }, [photo])

  const onDelete = (clearFile) => {
    clearFile({ name: "" })
  }

  function handleAdvance() {
    setPhotoError((inputFileValidator(photo, 25)));

    if (photoError.error) {
      return "Have some errors yet.";
    } else {
      const payload = {
        photo,
        about,
        experience,
        medicalConditions,
        formation,
        linkCurriculumLattes,
      };
      dispatch(personalDatasActions.curriculumDatasPf(payload));
      props.nextStep();
      return "Good to go."
    }
  }

  const handleChangeInputFile = (value, onChange, typesAccepted) => {
    if (!value) return onChange({name: ''})
  
    const typeIsAccpted =  validationFileType(value.type,typesAccepted)
    if (!typeIsAccpted) {
      onChange({name: ''})
      setOpenSyncSnackBar(true)
      setErrorMessageSyncSnackBar('O tipo do arquivo selecionado não é aceito para esse campo!')
      return
    } 
    return onChange(value)
  }

  const typesAcceptedPhoto = ['image/png', 'image/jpg', 'image/jpeg']

  return (
    <div>
      <SyncSnackBar 
        open={openSyncSnackBar} 
        onClose={setOpenSyncSnackBar} 
        errorMessage={errorMessageSyncSnackBar} 
      />
      <StepByStep
        stepper
        steps={props.steps}
        activeStep={props.activeStep}
        handleBack={props.previousStep}
        handleAdvance={handleAdvance} 
      />
      <form className="form-layout">
        <div className="form-content">
          <div>
            <Topic
              classesNameCol="topic-datas firt-topic"
              title="Curriculo Vida Connect"
              subtitle="Nos forneça mais alguns dados profissionais para que seu paciente te conheça melhor"
            />
          </div>

          <InputFile
            id="photo"
            width="32%"
            label="Foto"
            subtitle="Formatos aceitos: png, jpg e jpeg. Tamanho máximo: 20MB - A foto deve ser quadrada"
            accept=".png, .jpg, .jpeg,"
            onChange={(e) =>
              handleChangeInputFile(e.target.files[0], setPhoto, typesAcceptedPhoto)
            }
            onBlur={() =>
              setPhotoError(inputFileValidator(photo, 20))
            }
            onDelete={() => onDelete(setPhoto)}
            value={photo.name}
            error={photoError.typeError}
            dataTest="input-pf-photo"
          />

          <Textarea
            info="Opcional*"
            width="32%"
            label="Sobre mim"
            value={about}
            onChange={setAbout}
            dataTest="input-pf-sobre-mim"
          />

          <Textarea
            info="Opcional*"
            width="32%"
            label="Nos conte um pouco mais sobre sua experiência profissional"
            value={experience}
            onChange={setExperience}
            dataTest="input-pf-experiencia-em"
          />

          <Textarea
            info="Opcional*"
            width="32%"
            label="Quais as principais condições médicas você trata?"
            value={medicalConditions}
            onChange={setMedicalConditions}
            dataTest="input-pf-condicoes-medicas"
          />
          <Textarea
            info="Opcional*"
            width="32%"
            label="Formação"
            value={formation}
            onChange={setFormation}
            dataTest="input-pf-formacao"
          />

          <Input
            info="Opcional*"
            width="32%"
            label="Link do Curriculos Lattes"
            placeholder="Digite o link do Curriculos Lattes"
            type="text"
            value={linkCurriculumLattes}
            onChange={(e) => setLinkCurriculumLattes(e.target.value)}
            dataTest="input-pf-link-lattes"
            inputProps={{ maxLength: 100 }}
          />
        </div>
      </form>
      <StepByStep activeStep={props.activeStep} handleAdvance={handleAdvance} handleBack={props.previousStep} backgroundColor="#FFFFFF"/>
    </div>
  );
};
