import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Topic from '../../../common/topic';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import DeleteIcon from "@material-ui/icons/Delete"
import Pagination from '../../../common/pagination';
import { useQuery, gql, useMutation } from '@apollo/client';
import Button from './../../../common/button'
import DialogToCreateNewProviderHasHealthOperator from './dialog-to-create-new-provider-has-health-operator';
import ConfirmDialog from './../../../common/confirmDialog'
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  table: {
    marginBottom: '30px',
    "& tbody": {
      backgroundColor: '#FFF'
    }
  },
  screen: {
    backgroundColor: "#F7F8F9",
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  root: {
    width: '95%',
    padding: '3vh 0',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 600
    }
  },
})

const themeCircularProgress = createMuiTheme({
  palette: {
    primary: { main: "#DF9000" },
  },
});

const QUERY_LIST_PROVIDER_HAS_HEALTH_OPERATORS = gql`
  query listProviderHasHealthOperators($page: Float, $limit: Float) {
    listProviderHasHealthOperators(page: $page, limit: $limit) {
      count
      rows {
        id
        providerId
        planId
        active
        plans {
          id
          healthOperator {
            id
            fantasyName
            companyName
          } 
        }
      }
    } 
  }
`

const QUERY_LIST_ESTABLISHMENT_HAS_HEALTH_OPERATORS = gql`
  query ListEstablishmentHasHealthOperators($establishmentId: ID!, $page: Float, $limit: Float) {
    listEstablishmentHasHealthOperators(establishmentId: $establishmentId, page: $page, limit: $limit) {
      count
      rows {
        id
        establishmentId
        planId
        active
        plans {
          id
          healthOperator {
            id
            fantasyName
            companyName
          } 
        }
      }
    } 
  }
`

const QUERY_TO_GET_HEALTH_OPERATORS = gql`
  query {
    getAllHealthOperators {
      id
      plans {
        id
        name
      }
      fantasyName
      companyName
    } 
  }
`

const MUTATION_CREATE_PROVIDER_HAS_HEALTH_OPERATOR = gql`
  mutation CreateProviderHasHealthOperator($planId: ID!) {
    createProviderHasHealthOperator(planId: $planId) {
      id
      providerId
      planId
      active
      plans {
        id
        healthOperator {
          id
          fantasyName
          companyName
        }
      }
    }  
  }
`

const MUTATION_REMOVE_PROVIDER_HAS_HEALTH_OPERATOR = gql`
  mutation RemoveProviderHasHealthOperator($planId: ID!) {
    removeProviderHasHealthOperator(planId: $planId)
  }
`

export default () => {
  const classes = useStyles();

  const [_loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const firstItem = (page * itemsPerPage) - (itemsPerPage);
  const lastItem = (firstItem + itemsPerPage);
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [healthOperators, setHealthOperators] = useState([]);
  const [alreadyExists, setAlreadyExists] = useState([])
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [rowClicked, setRowClicked] = useState(null)
  const [infoMessage, setInfoMessage] = useState({ message: '', severity: '' })
  const establishmentId = localStorage.getItem('establishmentId')

  const variables = {
    page,
    limit: itemsPerPage
  }
  if (Boolean(establishmentId)) variables.establishmentId = establishmentId
  const query = Boolean(establishmentId) ? QUERY_LIST_ESTABLISHMENT_HAS_HEALTH_OPERATORS : QUERY_LIST_PROVIDER_HAS_HEALTH_OPERATORS

  const { loading, error, data } = useQuery(query, { variables })
  const { loading: healthOperatorsLoading, error: healthOperatorsError, data: healthOperatorsData } = useQuery(QUERY_TO_GET_HEALTH_OPERATORS)

  useEffect(() => {
    setHealthOperators(healthOperatorsData?.getAllHealthOperators?.map(i => ({ ...i, plans: i.plans[0], planId: i.plans[0].id })) || [])
  }, [healthOperatorsData])

  useEffect(() => {
    const _rows = data?.listProviderHasHealthOperators?.rows || data?.listEstablishmentHasHealthOperators?.rows || []
    const _count = data?.listProviderHasHealthOperators?.count || data?.listEstablishmentHasHealthOperators?.count || 0
    setRows(_rows)
    setCount(_count)
    setAlreadyExists(_rows.map(item => item.plans.healthOperator.id))
  }, [data])

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  useEffect(() => {
    console.log(error)
  }, [error])

  useEffect(() => {
    console.log(healthOperatorsLoading)
  }, [healthOperatorsLoading])

  useEffect(() => {
    console.log(healthOperatorsError)
  }, [healthOperatorsError])

  const showLoading = () => {
    return (
      <div style={{ marginTop: 30 }}>
        <ThemeProvider theme={themeCircularProgress}>
          <CircularProgress color={'primary'} size={50} />
        </ThemeProvider>
      </div>
    )
  }

  const [registerNewProviderHasHealthOperator] = useMutation(MUTATION_CREATE_PROVIDER_HAS_HEALTH_OPERATOR)

  const handleClickOpenModal = () => setOpenModal(true)

  const handleClickNewHealthOperator = (item) => {
    if (!Boolean(item.planId)) return
    const planId = item.planId
    registerNewProviderHasHealthOperator({ variables: { planId } })
      .then(() => {
        setAlreadyExists([...alreadyExists, item.id])
        setHealthOperators(healthOperators.filter(i => !alreadyExists.includes(i.id)))
        setRows([...rows, { ...item, plans: { ...item.plans, healthOperator: { ...item, plans: undefined } } }])
        setOpenModal(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const clearAlert = (time = 10000) => setTimeout(() => {setInfoMessage({ message: '', severity: '' })}, time)

  const [removeProviderHasPlan] = useMutation(MUTATION_REMOVE_PROVIDER_HAS_HEALTH_OPERATOR)

  const handleConfirmDeleteModal = async (item) => {
    if (!Boolean(item?.planId)) return
    clearAlert(0)
    setLoading(true)

    try {
      await removeProviderHasPlan({ variables: { planId: item.planId } })
      const rowsUpdated = rows.filter(i => i.id !== item.id)
      setRows(rowsUpdated)
      setAlreadyExists(alreadyExists.filter(i => i.id === item.id))
      setInfoMessage({ message: 'Convênio deletado com sucesso', severity: 'success' })
      setOpenConfirmDeleteModal(false)
      setLoading(false)
      clearAlert()
    } catch (error) {
      setInfoMessage({ message: 'Erro ao deletar convênio', severity: 'error' })
      setOpenConfirmDeleteModal(false)
      setLoading(false)
      // clearAlert(15000)
    }
  }

  const handleClickDeleteIcon = (row) => {
    setOpenConfirmDeleteModal(true)
    setRowClicked(row)
  }

  const handleCancelDelete = () => {
    setOpenConfirmDeleteModal(false)
    setRowClicked({})
  }

  return (
    <div className={classes.screen}>
      <div className={classes.root}>
        <div className={classes.table}>
          <div className="side-by-side">
            <Topic
              title="Convênios"
              subtitle={!Boolean(establishmentId) ? `Convênios que você atende.` : `Convênios que a instituição atende.`}
              marginTop="0px"
              subMarginTop="11px"
            />
            {
              !Boolean(establishmentId) &&
              <Button
                onClick={handleClickOpenModal}
                width="200px"
                border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
              >
                Cadastrar convênio
                </Button>
            }
          </div>

          {
            _loading ? showLoading() :
              <div style={{ marginTop: 10 }}>
                {Boolean(infoMessage.message) && <Alert elevation={2} severity={infoMessage.severity}>{infoMessage.message}</Alert>}
              </div>
          }

          {_loading ? showLoading() :
            <>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome social da operadora</TableCell>
                      <TableCell>Nome fantasia da operadora</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          {row.plans.healthOperator.companyName}
                        </TableCell>
                        <TableCell>
                          {row.plans.healthOperator.fantasyName}
                        </TableCell>
                        <TableCell>
                          {
                            !Boolean(establishmentId) &&
                              <DeleteIcon
                                onClick={() => handleClickDeleteIcon(row)}
                                color='error'
                                style={{ cursor: 'pointer' }}
                              />
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }
        </div>
        <div className={classes.pagination}>
          <Pagination
            page={page}
            setPage={setPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            firstItem={firstItem}
            lastItem={lastItem}
            length={count}
            width="95%"
          />
        </div>
        <div>
          <DialogToCreateNewProviderHasHealthOperator
            open={openModal}
            handleClose={() => setOpenModal(false)}
            select={handleClickNewHealthOperator}
            healthOperators={healthOperators.filter(item => !alreadyExists.includes(item.id))}
          />
        </div>
        <div>
          <ConfirmDialog
            open={openConfirmDeleteModal}
            handleCancel={handleCancelDelete}
            handleConfirm={handleConfirmDeleteModal}
            title="Deseja realmente excluir?"
            contentText="Deseja realmente excluir este convênio? Pacientes não irão mais conseguir agendar consultas com você através deste convênio"
            item={rowClicked}
          />
        </div>
      </div>
    </div>
  )
}
