import React from "react";
import AuthRoute from "./config/authRoute";
import AuthRouteVirtua from './config/authRouteVirtua'
import AuthRouteNoVirtua from './config/authRouteNoVirtua '

import Logon from "./pages/logon";
import Pf from "./pages/providerRegistration/pf";
import WaitingValidation from './pages/providerRegistration/pf/finalPage';
import Attendance from "./pages/attendance";
import PatientFile from './pages/consultation/patientFile';
import Administrator from './pages/administrator';
import Queue from './pages/queue'
import Historic from './pages/historic'
import Request from './pages/request'
import Budgets from './pages/request/budgets'
import ExamsAttendance from './pages/scheduledExams/index';

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

const Routes =  () => (
  <BrowserRouter>
    <Switch>
      <AuthRouteNoVirtua path="/cadastro/pf" exact component={Pf} />
      <AuthRouteNoVirtua path="/cadastro/pf/aguardando" component={WaitingValidation} />
      <AuthRouteNoVirtua path="/administrador" component={Administrator} />
      <AuthRouteNoVirtua path="/atendimento" exact component={Attendance} />
      <AuthRouteVirtua path="/fila" component={Queue} />
      <AuthRouteVirtua path="/historico" component={Historic} />
      <AuthRoute path="/atendimento/consulta" component={PatientFile} />
      <AuthRoute path="/solicitacoes" exact component={Request}/>
      <AuthRoute path="/solicitacoes/orcamentos/:id" children={<Budgets />} />
      <AuthRoute path="/atendimento/exame" component={ExamsAttendance} />
      <Route path="/" exact component={Logon} />
      <Redirect from="*" to='/administrador' />
    </Switch>
  </BrowserRouter>
);

export default Routes;
