import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EditMenu from '../../../../common/editMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '0'
  },
  item: {
    height: '64px',
    backgroundColor: "#FFFFFF",
    padding: '0 30px',
    border: '1px solid #E7E7ED',
  },
  combo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  icons: {
    display: 'flex',
    width: props => props.iconsWidth ? props.iconsWidth : null,
  },
  icon: {
      width: '80px',
  },
  line: {
    height: '40px',
    width: "93%",
    margin: "auto",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },
  code: {
    width: props => props.firstColumnWidth ? props.firstColumnWidth : '60px',
  }
}));

const ListDisplay = (props) => {

  const classes = useStyles(props);
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const employeeId = window.localStorage.getItem("employeeId");
  return (
    <List className={classes.root}>
      {props.firtstHeader ?
        <div className={classes.headers}>  
          <div className={classes.code}>  
            <span id={[props.firstHeader]}>
              {props.firstHeader}
            </span>
          </div>
        </div>
        :
        null
      }
      {props.fullList.map((value, index) => {
        const labelId = `checkbox-list-label-${props.firstParam ? value[props.firstParam][props.secondParam] : value}`;
        if(value.active) return (
          <ListItem 
            className={`${classes.item}`} 
            key={labelId} 
            role={undefined} 
            dense 
            button 
            onClick={handleToggle(value)}
          >
            <div className={classes.combo}>  
                <div className={classes.line}>  
                  <div className={classes.code}>  
                    <span id={value[props.firstParam][props.secondParam]}>
                      {value[props.firstParam][props.secondParam]}
                    </span>
                  </div>
                </div>
              {
                !Boolean(employeeId) && 
                <div className={classes.icons}>
                  <IconButton
                    disableRipple
                    className={classes.icon} 
                    edge="end" aria-label="delete" 
                    onClick={() => props.deleteItem(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <EditMenu
                    items={props.menuItems}
                    index={index}
                  />
                </div>
              }
            </div>
          </ListItem>
        );
        return null;
      })}
    </List>
  );
}

export default ListDisplay;