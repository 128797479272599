export function RegisterExam(payload) {
  return {
    type: "REGISTER_EXAM",
    payload: payload,
  };
}
export function GetExam(payload) {
  return {
    type: "GET_EXAM",
    payload: payload,
  };
}
export function RegisterProviderExam(payload) {
  return {
    type: "REGISTER_PROVIDER_EXAM",
    payload: payload,
  };
}

export function ResetExams(payload) {
  return {
    type: "RESET_EXAMS",
    payload: payload,
  };
}

export function alterActive(index) {
  return {
    type: "ALTER_ACTIVE_EXAM",
    payload: index,
  };
}

export function deleteExam(index) {
  return {
    type: "DELETE_EXAM",
    payload: index,
  };
}

export function updateExam(index, details) {
  
  return {
    type: "UPDATE_EXAM",
    payload: index,
    details,
  };
}
export function updateProviderExams(index, details) {
  
  return {
    type: "UPDATE_PROVIDER_EXAMS",
    payload: index,
    details,
  };
}
export function deleteAllExams() {
  return { type: "DELETE_ALL_EXAMS" };
}

export function openModal(payload) {
  return {
    type: "OPEN_MODAL_EXAM",
    payload
  }
}

export function openAddModal(payload){
  return {
    type: "OPEN_MODAL_ADD_EXAM",
    payload
  }
}
export function openEditModal(payload){
  return {
    type: "OPEN_EDIT_MODAL_EXAM",
    payload
  }
}
export function setChoosenProviderExams(payload) {
  return {
      type:"SET_CHOOSEN_PROVIDER_EXAMS",
      payload
  }
}
  export function getSelectedProviderExam(index, details) {
    return {
        type:"GET_SELECTED_PROVIDER_EXAM",
        payload: index,
        details
    }
}
export function setExamFee(payload) {
  return {
      type:"SET_EXAM_FEE",
      payload
  }
}

export function RegisterExamsFromSpreadsheet(payload) {
  return {
    type: "REGISTER_EXAMS_FROM_SPREADSHEET",
    payload: payload,
  };
}

export function deleteExamsFromSpreadsheet() {
  return { type: "DELETE_EXAMS_SPREADSHEET" };
}

export function triggerUpdateExamList(payload) {
  return {
    type: "TRIGGER_UPDATE_EXAM_LIST",
    payload: payload,
  };
}
