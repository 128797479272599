import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

import { DoctorData } from './doctorData'
import { styles } from '../styles'

const localStyles = StyleSheet.create({
  commentsTitle: {
    marginTop: 20
  },
  comments: {
    minHeight: 200
  }
})

// Create Document Component
export const Certificate = ({documentPayload}) => {

  const { time, patientName, patientCpf, text } = documentPayload

  return (
      <View>
        <Text style={styles.text}>
          Atesto que o(a) Sr.(a) <Text style={styles.textBold}>{ patientName }</Text>, portador do CPF: <Text style={styles.textBold}>{patientCpf}</Text>
          , encontra-se sob meus cuidados profissionais
          necessitando de afastamento de suas atividades pelo período de <Text style={styles.textBold}>{ time }</Text>.
        </Text>

        <Text style={[styles.text, styles.textBold, localStyles.commentsTitle]}>
          Observações:
        </Text>

        <Text style={[styles.text, localStyles.comments]}>
          { text }
        </Text>
        
        <DoctorData style={{marginTop: 50}} documentPayload={documentPayload}/>
      </View>
      
  );
};
