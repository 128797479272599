import graphqlApi from './api'

export const getProfessions = async () => {
  try {
    const res = await graphqlApi({
      data: {
        query: `query {
          getAllProfessionals {
            id,
            active,
            profession,
            speciality {
              id
              name
            }
          }
        }`
      }    
    })

    if(!res.data.data.errors) {
      return res.data.data.getAllProfessionals
    } else {
      return { error: res.data.data.errors }
    }
  
  } catch (error) {
    return { error }
  }
}

export const getAllProfessionalCouncil = async () => {
  try {
    const res = await graphqlApi({
      data: {
        query: `query{
          getAllProfessionalCouncil {
            id,
            active,
            name
          }
        }`
      }
    })

    if(!res.data.data.errors) {
      return res.data.data.getAllProfessionalCouncil
    } else {
      return { error: res.data.data.errors }
    }

  } catch (error) {
    return { error }
  }
}