const INITIAL_STATE = {
    openCreateScheduleModal: false,
    openSlotModal: false,
    slotModalData : {},
    waitingResults: false,
    done: false,
    updateScheduleFront: true,
    updateCalendarFront: true,
    beginSchedule:"",
    endSchedule:"",
    daysOfWeek: [],
    daysOfWeekWithHours: "",
    duration: "",
    registeredSchedules:[],
    appointmentsAndUnavailabilities: [],
    schedulePatients: [],
    triageList: [],
    unit:{}
}

const scheduleReducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case "OPEN_CREATE_SCHEDULE_MODAL":
            return {
                ...state,
                openCreateScheduleModal: action.payload,
            }
        case "OPEN_SLOT_MODAL":
            const {
                open,
                ...data
            } = action.payload;
            
            return {
                ...state,
                openSlotModal: open,
                slotModalData: {...data}
            }
        case "WAITING_RESULTS":
            return {
              ...state,
              waitingResults: true,
              done: false,
            }
        case "UPDATE_SCHEDULE_FRONT":
            return {
                ...state,
                updateScheduleFront:action.payload,
            }
        case "UPDATE_CALENDAR_FRONT":
            return {
                ...state,
                updateCalendarFront:action.payload,
            }
        case "SET_OK":
            return {
                ...state,
                ok: action.payload,
            }
        case "ADD_TIME_SPAN":
            return {
                ...state,
                beginSchedule: action.payload.startSelectedDate,
                endSchedule: action.payload.endSelectedDate,
            };
        case "ADD_DAYS_OF_WEEK":
            return {
                ...state,
                daysOfWeek: action.payload
            };
        case "ADD_DAY_HOURS":
            return {
                ...state,
                daysOfWeekWithHours: action.payload
            }
        case "ADD_DURATION":
            return {
                ...state,
                duration: action.payload
            };
        case "CLEAN_SCHEDULE":
            return {
                ...state,
                beginSchedule:"",
                endSchedule:"",
                daysOfWeek: [],
                daysOfWeekWithHours: "",
                duration: "",
                registeredSchedules: [],
                appointmentsAndUnavailabilities: [],
                triageList: [],
                waitingResults: false,
                done: false,
                updateScheduleFront: true,
                updateCalendarFront: true,
            }
        case "UPDATE_SCHEDULE":
            return {
                ...state,
                registeredSchedules: action.payload,
                waitingResults: false,
            }
        case "UPDATE_APPOINTMENTS_AND_UNAVAILABILITIES":
            return {
                ...state,
                appointmentsAndUnavailabilities: action.payload,
                waitingResults: false,
            }
        case "SCHEDULE_PATIENTS": 
            return {
                ...state,
                schedulePatients: action.payload,
                waitingResults: false,
            }
        case "STOP_WAITING_FOR_SCHEDULE_RESULTS":
            return {
                ...state,
                waitingResults: false,
                done: true,
            }
        case "SET_UNIT_SCHEDULE":
            return {
                ...state,
                unit: action.payload
            }
        default:
            return state;
    }
}

export default scheduleReducer;