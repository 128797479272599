import React from 'react'
import { useSelector} from 'react-redux'
import { Redirect } from 'react-router-dom';

import ExamConsultation from './examConsultation'


const ExamsAttendance = () => {
  
  const ScheduledExamReducer = useSelector((state) => state.ScheduledExamReducer.data);

  if(ScheduledExamReducer.length === 0){
    return (<Redirect from="*" to='/atendimento' />)
  }
  else{
  return(
    <ExamConsultation/>
  )
  }
}

export default ExamsAttendance