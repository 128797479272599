import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add';

import Button from './../../../common/button'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 600
  },
}));

export default (props) => {
  const classes = useStyles()

  const click = (disableEditForm) => {
    if (props.openForm) props.openForm(disableEditForm)
  }

  const toEditForm = () => (
    <>
      <Grid item>
        <Typography className={classes.title}>Solicitação</Typography>
      </Grid>
      <Grid item>
        <Button
          border="1px solid #CDCED9"
          backgroundColor="#FFF"
          color="#6B6C7E"
          onClick={() => click(false)}
        >
          Continuar preenchimento
        </Button>
      </Grid>
    </>
  )

  const readOnlyForm = () => (
    <Button
      border="1px solid #CDCED9"
      backgroundColor="#FFF"
      color="#6B6C7E"
      onClick={() => click(true)}
    >
      Visualizar detalhamento <AddIcon/>
    </Button>
  )

  const render = (continueAnsweringForm) => continueAnsweringForm ? toEditForm() : readOnlyForm()

  return (
    render(props.continueAnsweringForm)
  )
}
