import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Topic from '../../../common/topic';
import List from '../../../common/itemList/addressList';
import Pagination from '../../../common/pagination';
import Loading from '../../../common/loading';
import SnackBar from '../../../common/snackbar';

import * as asyncActions from '../../../common/store/actions/async.action';
import * as unitActions from '../../../common/store/actions/units.action';

const useStyles = makeStyles({
    screen: {
        backgroundColor: '#f7f8f9',
        height: 'calc(100vh - 70px)',
        maxHeight: "100%",
        width: "100%",
        padding: '30px',
        boxSizing: 'border-box',
    },
    pagination: {
        position: "absolute",
        bottom: "3vh",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        width: "80%",
    },
    pageSelection: {
        width: "40%",
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "center",
    },
    items: {
        marginLeft: "50px",
    },
    scroll: {
        maxHeight: "calc(77vh - 72px)",
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CDCED9',
            outline: '1px solid slategrey',
            borderRadius: '5px'
        }
    }
})

const Addresses =(props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const providerId = window.localStorage.getItem("Prov-Vida-Connect");
    const establishmentId = window.localStorage.getItem('establishmentId');
    const employeeId = window.localStorage.getItem('employeeId');
    const userType = window.localStorage.getItem('userType')
    const refresh = useSelector( (state) => state.UnitsReducer.update);
    const addresses = useSelector( (state) => state.UnitsReducer.units );
    const error = useSelector((state) => state.AsyncReducer.error);
    const waitingData = useSelector((state) => state.UnitsReducer.waitingData);
    const provider = JSON.parse(window.localStorage.getItem('Secretary-Selected-Doctor'))
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const firstItem = (page*itemsPerPage)-(itemsPerPage);
    const lastItem = (firstItem + itemsPerPage);

    useEffect( () => {
        if(refresh) {
            if (userType === "PROVIDER_SECRETARY") {
                if (provider?.employeeId) {
                    dispatch(asyncActions.getUnitsForSecretaries(props.provider.id, provider.employeeId));
                    dispatch(unitActions.updateFront(false));
                } else {
                    dispatch(asyncActions.getUnitsForSecretaries(props.provider.id));
                    dispatch(unitActions.updateFront(false));
                }
            }else if(establishmentId) {
                dispatch(asyncActions.getEmployeeUnits({employeeId, establishmentId}));
                dispatch(unitActions.updateFront(false));
            } else {
                dispatch(asyncActions.getUnits(providerId));
                dispatch(unitActions.updateFront(false));
            }
        }
        // eslint-disable-next-line
    },[refresh]);

    useEffect( () => {
        return () => dispatch(unitActions.updateFront(true));
        // eslint-disable-next-line
    },[])

    if(waitingData) {
        return (
            <div className={classes.screen}>
                <div className={classes.table}>
                    <div className="side-by-side">
                        <Loading width="80px"/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.screen}>
            <div className={classes.table}>
                <div className="side-by-side">
                    <Topic
                        title={props.title}
                        subtitle={props.subtitle}
                        marginTop="0px"
                        subMarginTop="11px"
                    />
                </div>
                <div className={`service-register ${classes.scroll}`}>
                    <div>
                        <List 
                            fullList={addresses.slice(firstItem, lastItem)}
                            firstHeader="Unidade"
                            firstParam="address"
                            secondParam="number"
                            thirdParam="complement"
                            onClick={props.onClick}
                        />
                    </div>
                </div>
            </div>
            <Pagination 
                page={page}
                setPage={setPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                firstItem={firstItem}
                lastItem={lastItem}
                length={addresses.length}
            />
            {error ?
                <SnackBar />
                :
                null
            }
        </div>
    );
}

export default Addresses;
