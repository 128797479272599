export function setQueue(payload) {
  return {
      type: "SET_QUEUE",
      payload
  }
}

export function setQueueInfo(payload) {
  return {
      type: "SET_QUEUE_INFO",
      payload
  }
}

export function setAvailable(payload) {
  return {
      type: "SET_AVAILABLE",
      payload
  }
}

export function setHistoric(payload) {
  return {
      type: "SET_HISTORIC",
      payload
  }
}
