import {leavingARoom, letApiKnowDoctorIsOut} from './leavingARoom';
const { connect } = require('twilio-video');

export const creatingChatWindow = async(localVideoRef, localTracks) => {
    try{
        localTracks.forEach((track) => {
            if(track.kind === "video") track.attach(localVideoRef.current);
        })
    } catch(error) {
        console.log(error);
    }
}

export const connectToARoom = async (
        token,
        roomName,
        localVideoRef, 
        remoteVideoRef, 
        remoteAudioRef, 
        setLive, 
        medicalAppointmentId,
        setParticipant,
        setConnectionError,
    ) => {
    try{     
    //Connecting to the pre-existing room (automatically publishes its tracks)
        const room = await connect(token, { name: roomName});
        console.log(`Successfully joined a Room: ${room}`);
        setLive(true);
    
    //LocalTracks
        let localTracks = [];
        room.localParticipant.tracks.forEach(track => {
            localTracks.push(track.track)
        } );

    //Creating the video/audio window on browser
        await creatingChatWindow(localVideoRef, localTracks);
    //Listening for Local Participant tracks
        room.localParticipant.on('trackDisabled', () => console.log("Track desabilitada"));
        room.localParticipant.on('trackEnabled', () => console.log("Track habilitada"));
        room.localParticipant.on('trackPublished', () => console.log("Track publicada"));
        room.localParticipant.on('trackStarted', () => console.log("Track começou"));
        room.localParticipant.on('trackStopped', () => console.log("Track parou"));

    //Listening for new Remote Participants
        room.on('participantConnected', participant => {
            console.log(`A Remote Participant connected: ${participant.identity}`);
            setParticipant(participant);
        });

    //Listening for new Remote Participants tracks
        room.on('trackStarted', (track, participant) => {
            if(track.kind === "audio") {
                const trackObject = track.attach();
                remoteAudioRef.current.appendChild(trackObject);
            };
            if(track.kind === "video") track.attach(remoteVideoRef.current);
        });
        //*Case LocalParticipant was the last one joining the room, we need to "scan"
        //the whole room and attach the already-published tracks
        room.participants.forEach( (participant) => {
            participant.tracks.forEach( (publication) => {
                if(publication.track) {
                    if(publication.track.kind === "audio") publication.track.attach(remoteAudioRef.current);
                    if(publication.track.kind === "video") publication.track.attach(remoteVideoRef.current);
                }
            })
        })
        //Actions to clean all tracks from Local Participant when he leaves the room
        room.on('disconnected', async (room) => {
            try {
                room.localParticipant.tracks.forEach( publication => {
                    const attachedElements = publication.track.detach();
                    attachedElements.forEach( element => element.remove)
                })
            } catch( error ) {
                console.log(error);
                setConnectionError(true);
            }

        });

    //Listen to when the Remote Participant disables a track
        room.on('trackDisabled', (track, participant) => {
            console.log("Desabilitou uma track");
            console.log(track);
            console.log(participant);
        })

    //Listen to when the Remote Participant unpublished a track
        room.on('trackUnpublished', (track, participant) => {
            console.log("Despublicou uma track");
            console.log(track);
            console.log(participant);
        })

    //Listen to when the Remote Participant enables a track
        room.on('trackEnabled', (track, participant) => {
            console.log("Habilitou uma track");
            console.log(track);
            console.log(participant);
        })

    //Listening to Remote Participant disconnection
        room.on('participantDisconnected', (room) => {
            console.log("Participant disconnected.");
            setParticipant(null);
        })

    // Listen to the "beforeunload" event on window to leave the Room
    // when the tab/browser is being closed.
        window.addEventListener('unload', (event) => {
            leavingARoom(room, setLive);
            letApiKnowDoctorIsOut(medicalAppointmentId);
        });

    // iOS Safari does not emit the "beforeunload" event on window.
    // Use "pagehide" instead.
        window.addEventListener('unload', () => {
            leavingARoom(room, setLive);
            letApiKnowDoctorIsOut(medicalAppointmentId);
        });

        return room;

    } catch(error) {
        console.error(`Unable to connect to Room: ${error.message}`);
        setConnectionError(true);
    }
};