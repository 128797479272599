import React from 'react';

import ConfigureDoctor from './configureDoctor';
import ConfigureSecretary from './configureSecretary';

const Schedule = (props) => {

    const userType = window.localStorage.getItem("userType");

    if(userType === "PROVIDER_DOCTOR" || userType === "PROVIDER_OTHER_PROFESSIONALS") return <ConfigureDoctor />
    if(userType === "PROVIDER_SECRETARY") return <ConfigureSecretary />
};

export default Schedule;