import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import empty from "../../../common/validators/empty";
import Button from "../../../common/button";
import RadioButtons from "../../../common/radioButton/radioButton";
import Select from "../../../common/select";
import Check from "../../../assets/check-circle-full.png";
import Loading from "../../../common/loading";
import { leavingARoom } from '../../../utils/videoCall/leavingARoom';
import * as asyncActions from "../../../common/store/actions/async.action";
import * as consultationActions from "../../../common/store/actions/consultation.action";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Grow } from "@material-ui/core";

// Material UI Styling

const useStyles = makeStyles({
  root: {
    width: (props) => (props.width ? props.width : "40%"),
    marginLeft: '60%',
    marginRight: "5px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  topic: {
    marginBottom: "30px",
    fontSize: "16px",
    "& > .title": {
      fontWeight: "600",
    },
    "& > p": {
      lineHeight: "20px",
      color: "#6B6C7E",
      marginTop: "5px",
    },
  },
  retorno: {
    "& > div": {
      width: "100%",
      marginTop: "5%",
    },
  },
  success: {
    backgroundColor: "#EDF9F0",
    color: "#287D3C",
    fontWeight: 600,
  },
  extraMargin: {
    margin: "50px 0 21px 0",
    padding: 0,
  },
});

// Component

const DispensePatientFromVideoCallModal = (props) => {
  const dispatch = useDispatch();
  const { Room } = props
  const estabName = window.localStorage.getItem('estabName')
  const medicalAppointmentId = window.localStorage.getItem("appointId");
  const providerId = window.localStorage.getItem("Prov-Vida-Connect");
  const patientId = window.localStorage.getItem("patientId");
  const isSuccess = useSelector((state) => state.ConsultationReducer.success);
  const data = useSelector((state) => state.ConsultationReducer);
  const error = useSelector((state) => state.AsyncReducer.error);
  const open = data.openDispensePatient;
  const classes = useStyles(props);

  const weAreLive = (boo) => {
    dispatch(consultationActions.weAreLive(boo))
}

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [needReturn, setNeedReturn] = useState("Não");

  const [returnType, setReturnType] = useState("");
  const [returnTypeError, setReturnTypeError] = useState("");

  const [returnMax, setReturnMax] = useState("");
  const [returnMaxError, setReturnMaxError] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setSuccess(isSuccess);
      setLoading(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  const handleClose = () => {
    dispatch(consultationActions.requestSuccess(false));
    dispatch(consultationActions.openDispensePatientModal(false));
  };

  const handleFinish = () => {
    let error = false;
    if (needReturn === "Sim") {
      error = [returnType, returnMax].reduce(
        (error, field) => error || Boolean(empty(field).typeError),
        false
      );
    }
    if (error) {
      if (!returnType)
        setReturnTypeError({ typeError: "Esse campo é obrigatório" });
      if (!returnMax)
        setReturnMaxError({ typeError: "Esse campo é obrigatório" });
    } else {
      setLoading(true);
      if (needReturn === "Sim") {
        const returnTypeMap = {
          PRESENCIAL_OU_ONLINE: "PRESENTIAL_AND_ONLINE",
          ONLINE: "ONLINE",
          PRESENTIAL: "PRESENTIAL",
        };
        dispatch(
          asyncActions.createMedicalAppointmentReturn({
            patientId,
            providerId,
            medicalAppointmentId,
            careModality: returnTypeMap[returnType],
            expirationDate: returnMax,
          })
        );
      }
      dispatch(asyncActions.finishVideoChat(medicalAppointmentId));
      dispatch(consultationActions.weAreLive(false));
      leavingARoom(Room, weAreLive);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.root,
        }}
        TransitionComponent={Grow}
        transitionDuration={{ enter: 200, exit: 0 }}
      >
        <div
          className={`${classes.header} ${success ? classes.success : null}`}
        >
          <DialogTitle>
            {success ? (
              <div>
                <img src={Check} alt="success" />
                <span> Paciente dispensado com sucesso</span>
              </div>
            ) : (
              <span>Dispensar Paciente da Consulta</span>
            )}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent dividers>
          <form>
            {!props.isReturnConsultation && estabName !== 'Virtua' && !success && (
              <div className={classes.retorno}>
                <div>
                  <RadioButtons
                    label="Precisa de retorno?"
                    width="30%"
                    margin="0px"
                    value={needReturn}
                    onChange={(e) => setNeedReturn(e.target.value)}
                  />
                </div>
                {needReturn === "Sim" && (
                  <>
                    <div>
                      <Select
                        label="O retorno pode ser:"
                        onChange={setReturnType}
                        onBlur={setReturnTypeError}
                        error={returnTypeError.typeError}
                        value={returnType}
                        itemList={[
                          { value: "ONLINE", exhibit: "Telemedicina" },
                          { value: "PRESENTIAL", exhibit: "Presencial" },
                        ]}
                      />
                    </div>
                    <div>
                      <Select
                        label="O retorno deverá ser feito em até:"
                        onChange={setReturnMax}
                        onBlur={setReturnMaxError}
                        error={returnMaxError.typeError}
                        value={returnMax}
                        itemList={[
                          { value: 15, exhibit: "15 dias" },
                          { value: 30, exhibit: "30 dias" },
                          { value: 45, exhibit: "45 dias" },
                          { value: 60, exhibit: "60 dias" },
                        ]}
                      />
                    </div>
                  </>
                )}
              </div>
            )}

            <DialogActions className={classes.extraMargin}>
              {loading ? (
                <Loading width="30px" />
              ) : success ? (
                <Button
                  onClick={handleClose}
                  width="120px"
                  border="1px solid #CDCED9"
                  backgroundColor="#FFFFFF"
                  color="#6B6C7E"
                  margin="0 20px 0 0"
                >
                  Ok
                </Button>
              ) : (
                <>
                  <Button
                    onClick={handleClose}
                    width="120px"
                    border="1px solid #CDCED9"
                    backgroundColor="#FFFFFF"
                    color="#6B6C7E"
                    margin="0 20px 0 0"
                  >
                    Cancelar
                  </Button>

                  <Button
                    width="120px"
                    border="1px solid #DF9000"
                    backgroundColor="#FFFFFF"
                    color="#DF9000"
                    onClick={handleFinish}
                  >
                    Ok
                  </Button>
                </>
              )}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DispensePatientFromVideoCallModal;
