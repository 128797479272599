import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import "../style.css";
import Topic from "../../../../common/topic";
import Button from '../../../../common/button';
import List from '../../../../common/selectList';


import {format} from 'date-fns';
import {Typography} from '@material-ui/core';

import * as scheduleActions from '../../../../common/store/actions/schedule.action';

const useStyles = makeStyles( {
  root: {
    width: '45%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    borderBottom: '1px solid #E7E7ED',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  },
  lettering2: {
    color: "#393A4A",
  },
  buttonBg: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F7F8F9',
    display: 'flex',
    justifyContent: "flex-end",
    padding: '22px',
    marginTop: '28px',
    boxSizing: 'border-box',
  }
})



const ModalCreateDaysOfWeek = ({setDaysOfWeek, ...props}) => {
    const dispatch = useDispatch();
    const days = useSelector((state) => state.ScheduleReducer.daysOfWeek);

    const [daysOfWeek, setDays] = useState(days);

    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(()=>{
      const weekDays = props.daysAvailability.map(day => format(day, 'i'))
      setButtonDisabled(!daysOfWeek.map(item => item.weight).every(item => weekDays.includes(String(item+1))));
      
    },[daysOfWeek, props.daysAvailability]);

    const onSaveAndNext = () => {
      dispatch(scheduleActions.addDaysOfWeek(daysOfWeek));
      props.handleNext();
    }

    const classes = useStyles();

    return (
      <>
        <div className={classes.header}>
          <DialogTitle>
            Criar Agenda
          </DialogTitle>
          <IconButton aria-label="close" onClick={() => props.handleClose()}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <form>
            <div className="modal-body">
              <Topic
                title="Deseja liberar vagas em quais dias da semana?"
                fontSize="16px"
                marginTop="20px"
              />
              <div>
                <List 
                  selectItems={
                    // [
                    //   "Domingo",
                    //   "Segunda",
                    //   "Terça",
                    //   "Quarta",
                    //   "Quinta",
                    //   "Sexta",
                    //   "Sábado",
                    // ].filter((_,index)=> props.daysAvailability.map(day => format(day, 'i')).includes(String(index+1))).map((item,index)=>({
                    //   day: item,
                    //   weight: index,
                    // })) retorna somente o dia que consta no período do createSchedule
                    [
                      {day:"Segunda", weight: 0},
                      {day:"Terça", weight: 1},
                      {day:"Quarta", weight: 2},
                      {day:"Quinta", weight: 3},
                      {day:"Sexta", weight: 4},
                      {day:"Sábado", weight: 5},
                      {day:"Domingo", weight: 6}
                    ]
                  }
                  setDays={setDays}
                  selected={daysOfWeek}
                  disabled={buttonDisabled}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        {buttonDisabled ? <Typography
          color="error"
          align="center"
          style={{display: 'block',fontSize: '12.5px', paddingTop: '30px', margin: "0px 5px"}}
          >Seleção inválida. Por favor, selecione um dia de acordo com o período selecionado anteriormente.
          </Typography> :""}
        <div className={classes.buttonBg}>
          <Button 
            onClick={() => props.setStep((prevStep) => prevStep-1)}
            width="120px"
            border="1px solid #CDCED9"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            margin="0 20px 0 0"
          >
            Voltar
          </Button>
          <Button 
            onClick={onSaveAndNext}
            width="120px"
            border="1px solid #DF9000"
            backgroundColor="#FFFFFF"
            color="#DF9000"
            disabled={daysOfWeek.length === 0 || buttonDisabled}
          >
            Próximo
          </Button>
        </div>
      </>
  );
};

export default ModalCreateDaysOfWeek;