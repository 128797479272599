import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "../../../common/button";
import Loading from '../../../common/loading';
import SnackBar from '../../../common/snackbar';

import * as asyncActions from '../../../common/store/actions/async.action'
import * as medicalAppointmentsAction from '../../../common/store/actions/medicalAppointments'
import * as consultationActions from '../../../common/store/actions/consultation.action';

import { useSubscription } from '@apollo/react-hooks'
import { gql } from '@apollo/client'

const  QUEUE_DETAILS = gql`
    subscription {
      QueueDetails{
        countPatients,
        countDoctors,
        doctorPerPatient,
        tme
      }
    }
  `

const useStyles = makeStyles((theme) => ({
  checkboxAvaibility: {
    display: 'flex',
    justifyContent: 'center',
    height: '78px',
    width: '347px',
    backgroundColor: (props) =>  props.avaiable ? '#EDF9F0' : '#FEEFEF',
    border: (props) => props.avaiable ? '1px solid #5ACA65' : '1px solid #F48989',
    "& .MuiCheckbox-root": {
      color: (props) => props.avaiable ? '#5ACA75': '#F48989'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },

  informations: {
    border: '1px solid #CDCED9',
    minHeight: '78px',
    "& div": {
      padding: '0px 10px',
      height: '100%',
      borderRight: '1px solid #CDCED9',
      display: 'inline-flex',
      alignItems: 'center',
      "& h3": {
        margin: '0 10px 0 0',
        fontSize: '32px',
        color: '#4B9BFF',
      },
      "& .time": {
        color: '#FF5F5F'
      },
      "& div": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        border: 'none',
        "& span:first-child": {
          fontSize: '12px',
          color: '#393A4A',
        },
        "& span:last-child": {
          fontSize: '10px',
          color: '#A7A9BC'
        }
      }
    }, 
    "& div:last-child": {
      border: 'none'
    }

  },

  nextConsultation: {
    marginTop: '30px',
    padding: '24px 0 59px 24px',
    backgroundColor: '#F1F2F5',
    "& .title": {
      fontSize: '12px',
      color: '#74848B',
      marginBottom: '38px'
    },
    "& .status": {
      fontSize: '16px',
      color: '#2E5AAC',
      fontWeight: 600 ,
      marginBottom: '16px'
    },
    "& .initialize": {
      fontSize: '14px',
      color: '#414A4E',
      marginBottom: '40px'
    }
  }
}))

export default props => {
  let queueInfo = useSelector((state) => state.VirtuaReducer.queueInfo)
  const availableToService = useSelector((state) => state.VirtuaReducer.isAvailable)
  const classes = useStyles({ avaiable: availableToService })

  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const history = useHistory()

  const { data } = useSubscription(QUEUE_DETAILS)
  if(data)
    queueInfo = data.QueueDetails

  const toggleAvailable = async (available) => {
    setLoading(true)
    if (available) {
      dispatch(asyncActions.virtuaRegisterDoctorOnline())
      window.sessionStorage.setItem('virtuaDoctorOnline', true)
    } 
    else {  
      dispatch(asyncActions.virtuaRegisterDoctorOffline())
    }
    setLoading(false)
    return
  }

  const captureNextPatient = async () => {
    setLoading(true)
    const { room, consultationInfo } = await dispatch(asyncActions.virtuaCaptureNextPatient())
    if (!room) {
      setLoading(false)
      return
    }
    dispatch(consultationActions.storeData(consultationInfo))
    dispatch(medicalAppointmentsAction.storeMedicalAppointmentsData(consultationInfo))
    window.localStorage.setItem('appointId', consultationInfo.medicalAppointmentId);
    window.localStorage.setItem('patientId', consultationInfo.patient.id);
    window.localStorage.setItem('appointmentLocation', consultationInfo.appointmentLocation);
    window.localStorage.setItem('twilio', JSON.stringify(room));
    history.push('/atendimento/consulta');
  }

  useEffect(() => {
    const getQueueInfo = async () => {
      setLoading(true)
      await dispatch(asyncActions.getQueueInfo())
      await dispatch(asyncActions.virtuaIsDoctorOnline())
      const virtuaDoctorOnline = window.sessionStorage.getItem('virtuaDoctorOnline')
      if(virtuaDoctorOnline && !availableToService){
        dispatch(asyncActions.virtuaRegisterDoctorOnline())
      }
      setLoading(false)
    } 
    getQueueInfo()
    // eslint-disable-next-line
  }, [])
  
  return (
    <div>
      {loading && <Loading width="120px"/>}
      <div className={classes.header}>
        <div className={classes.checkboxAvaibility} >
        <FormControlLabel
            checked={availableToService}
            value={availableToService}
            control={<Checkbox />}
            label= {availableToService ? "Disponível para atendimento" : "Indisponível para atendimento"}
            onChange={() => toggleAvailable(!availableToService)}
            disabled={loading}
          />
        </div>
        <div className={classes.informations}>
            <div>
              <h3>{queueInfo.countPatients}</h3>
              <div>
                <span>Pacientes na Fila</span>
                <span>Hoje</span>
              </div>
            </div>

            <div>
              <h3>{queueInfo.countDoctors}</h3>
              <div>
                <span>Médicos disponíveis</span>
                <span>Hoje</span>
              </div>
            </div>

            <div>
              <h3>{queueInfo.doctorPerPatient}</h3>
              <div>
                <span>Médico por paciente</span>
                <span>Hoje</span>
              </div>
            </div>

            <div>
              <h3 className="time">{queueInfo.tme ? parseInt(queueInfo.tme) : 0}<small>min</small></h3>
              <div>
                <span>TME</span>
                <span>Hoje</span>
              </div>
            </div>
        </div>
      </div>

      <div className={classes.nextConsultation}>
          <div className="title">Próximo atendimento</div>
          <div className="status">Consulta disponível!</div>
          <div className="initialize">Deseja iniciar a consulta?</div>
          <Button
            width="200px"
            border="1px solid #CDCED9"
            backgroundColor="#DF9000"
            color="#FFF"
            margin="0 20px 0 0"
            disabled={loading || !availableToService}
            onClick={() => captureNextPatient()}
          >
            Iniciar
          </Button>
      </div>
      <SnackBar/>
    </div>
  )
}