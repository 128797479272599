import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../../../common/button';
import Avatar from '../../../common/avatar';
import Loading from '../../../common/loading';
import SnackBar from '../../../common/snackbar';

import {getRoom} from '../../../utils/videoCall/createARoom';
import * as modalActions from '../../../common/store/actions/consultation.action';
import * as asyncActions from '../../../common/store/actions/async.action';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';
import { handlePatientName } from "../../../common/handlePatientName";

// Material UI Styling

const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '55%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#6B6C7E',
    fontSize: '14px',
    marginTop: '16px'
  },
  responseToPatient: {
      fontSize: '18px',
  },
  success: {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
      fontWeight: 600,
  },
  box: {
      display: "inline-block",
      marginRight: "3%",
      borderRadius: "20px",
      padding: '2% 3%',
      marginBottom: '2%',
      backgroundColor: "#EEEEEE",
      color: "#424242",
  },
  patient: {
    display: 'flex',
    marginTop: '5%',
  },
  text: {
    fontSize: '18px',
    color: "#272833",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginLeft: '2%',
    "& > .cpf":{
        color: "#6B6C7E",
        fontSize: '14px',
    }
  },
})

// Component

const StartConsultationModal = (props) => {
    const classes = useStyles(props);

    const dispatch = useDispatch();
    const history = useHistory();

    const medicalAppointmentId = props.consultation?.id

    const data = useSelector((state) => state.ConsultationReducer);
    const open = data.openStart;

    const [isLoading, setLoading] = useState(false);

    const handleClose = () => {
        localStorage.removeItem('appointmentLocation')
        localStorage.removeItem('patientId')
        localStorage.removeItem('twilio')
        localStorage.removeItem('appointId')
        dispatch(modalActions.openStartModal(false));
    }

    const handleStartConsultation = async () => {
        setLoading(true);
        const appointmentLocation = window.localStorage.getItem('appointmentLocation')
        if (appointmentLocation === "PRESENTIAL") {
            const res = await dispatch(asyncActions.initMedicalAppointmentPresential(medicalAppointmentId))
            if (res) {
                setLoading(false);
                history.push('/atendimento/consulta');
                dispatch(modalActions.openStartModal(false));
            }
        } else {
            try {
                const {roomName, identity} = await getRoom(medicalAppointmentId);
                if(!roomName) {
                    setLoading(false);
                    dispatch(asyncActions.requestFailure("Houve um problema ao entrar na sala da consulta. Tente novamente, por favor."));
                } else {
                    window.localStorage.setItem('twilio', JSON.stringify({roomName, identity}));
                    setLoading(false);
                    history.push('/atendimento/consulta');
                    dispatch(modalActions.openStartModal(false));
                }            
            } catch(error) {
                setLoading(false);
                dispatch(asyncActions.requestFailure("Houve um problema ao entrar na sala da consulta. Tente novamente, por favor."));
            }
        }

    }

    return (
            <div>
            <SnackBar />
            <Dialog 
                open={open}
                onClose={handleClose} 
                aria-labelledby="form-dialog-title" 
                classes={{
                paper: classes.root
                }}
                TransitionComponent={Grow}
                transitionDuration={{enter: 200, exit: 0}}
            >
                <div className={classes.header}>
                <DialogTitle>
                    Deseja iniciar o atendimento desse paciente?
                </DialogTitle>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                </div>
                <DialogContent dividers>
                <form>
                    <div className="modal-body">
                        <div className={classes.patient}>
                            <Avatar />
                            <div className={classes.text}>
                                <span>{handlePatientName(data.data?.patient)}</span>
                                <span className="cpf">{data.data?.patient?.email}</span>
                            </div>
                        </div>
                    </div>
                    <DialogActions className={classes.extraMargin}>
                        {isLoading ?
                            <Loading width="50px" />
                            :
                            <>
                                <Button 
                                    onClick={handleClose}
                                    width="120px"
                                    border="1px solid #CDCED9"
                                    backgroundColor="#FFFFFF"
                                    color="#6B6C7E"
                                    margin="0 20px 0 0"
                                >
                                    Não
                                </Button>
                                <Button
                                    width="120px"
                                    border="1px solid #DF9000"
                                    backgroundColor="#FFFFFF"
                                    color="#DF9000"
                                    onClick={handleStartConsultation}
                                >
                                    Sim
                                </Button>
                            </>
                        }
                    </DialogActions>
                </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default StartConsultationModal;
