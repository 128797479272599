import React, { Fragment, useEffect, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Grid from '@material-ui/core/Grid'
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton'

import { gql, useApolloClient } from '@apollo/client'

import TextArea from './../textarea'
import Input from './../input'
import Select from './../select'
import { districts } from './../../assets/districts'

const theme = createMuiTheme({
  palette: {
    primary: { main: "#DF9000" },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(2),
    flexGrow: 1,
  },
  item: {
    marginTop: theme.spacing(1),
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 600
  },
  subtitle: {
    fontSize: '0.875rem',
  },
  autocomplete: {
    height: '3rem',
    marginTop: '0.2rem',
    marginLeft: '1rem'
  },
  filterHospitalInput: {
    height: '3rem',
    marginTop: '0.2rem',
  },
  listHospitals: {
    border: '1px solid #E7E7ED',
  },
  emptyHospitalList: {
    backgroundColor: '#E8E8E8',
    color: '#6B6B6B'
  }
}));

const LIST_HOSPITALS_BY_UF_AND_CITY = gql`
  query ($uf: String) {
    listHospitalsByUfAndCity(uf: $uf) {
      uf
      cities {
        city
        hospitals {
          id
          tradingName
          companyName
          establishmentId
        }
      }
    } 
  }
`

export default function HospitalBudget(props) {
  const classes = useStyles(props);
  const api = useApolloClient()

  const [hasOpmeOrAditionalMaterials, setHasOpmeOrAditionalMaterials] = useState(props.hasOwnProperty('hasOpmeOrAditionalMaterials') && props.hasOpmeOrAditionalMaterials === true ? 'Sim' : 'Não')
  const [opmeOrAditionalMaterials, setOpmeOrAditionalMaterials] = useState(props.opmeOrAditionalMaterials || { opme: '', aditionalMaterials: '' })
  const [specialEquipment, setSpecialEquipment] = useState(props.specialEquipment || '')
  const [dailyQuantities, setDailyQuantities] = useState(parseInt(props.dailyQuantities) || 1)
  const [hasHospitalization, setHasHospitalization] = useState(props.hasOwnProperty('hasHospitalization') && props.hasHospitalization === true ? 'Sim' : 'Não')
  const [hospitalizationQuantities, setHospitalizationQuantities] = useState(parseInt(props.hospitalizationQuantities) || 1)
  const [hasSpecialMedications, setHasSpecialMedications] = useState(props.hasOwnProperty('hasSpecialMedications') && props.hasSpecialMedications === true ? 'Sim' : 'Não')
  const [specialMedications, setSpecialMedications] = useState(props.specialMedications || '')
  const [hasBloodProducts, setHasBloodProducts] = useState(props.hasOwnProperty('hasBloodProducts') && props.hasBloodProducts === true ? 'Sim' : 'Não')
  const [bloodProducts, setBloodProducts] = useState(props.bloodProducts || '')
  const [uf, setUf] = useState(props.uf || { exhibit: '', value: '' })
  const [ufList, setUfList] = useState(props.ufList || [])
  const [city, setCity] = useState(props.city || { name: '' })
  const [cities, setCities] = useState(props.cities || [{ name: '' }])
  const [hospitals, setHospitals] = useState(props.hospitals || [])
  const [hospitalsSelected, setHospitalsSelected] = useState(props.hospitalsSelected || [])
  const [hospitalFilter, setHospitalFilter] = useState('')

  useEffect(() => {
    setUfList(districts.map(district => ({ value: district.name, exhibit: district.name })))
  }, [])

  const handleChangeUf = _uf => {
    if (!_uf.hasOwnProperty('value') && Boolean(_uf)) {
      getHospitals(_uf)
        .then(result => {
          if (!result?.data?.listHospitalsByUfAndCity[0]) {
            setHospitals([])
            setCities([{ name: '' }])
            setCity({ name: '' })
            return
          }

          setCities(result.data.listHospitalsByUfAndCity[0].cities.map(item => ({ ...item, name: item.city })))
          setCity({ name: '' })
          setHospitalFilter('')
        })
    }
  }

  const handleChangeCity = _city => {
    if (_city.hospitals && Array.isArray(_city.hospitals)) {
      const hospitalsSelectedIds = hospitalsSelected.map(item => item.id)
      setHospitals(
        _city.hospitals.map(
          item => ({
            ...item, name: item.tradingName, anesthetist: false, checked: hospitalsSelectedIds.includes(item.id) ? true : false
          })
        )
      )
      setHospitalFilter('')
    }
  }

  useEffect(() => {
    handleChangeCity(city)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city])

  useEffect(() => {
    handleChangeUf(uf)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uf])

  const getHospitals = async (_uf) => api.query({ query: LIST_HOSPITALS_BY_UF_AND_CITY, variables: { uf: _uf } })

  const checkRadioButtonSelected = value => value === 'Sim'

  const handleHasOpmeOrAditionalMaterials = (event) => {
    const value = event.target.value
    if (!checkRadioButtonSelected(value)) {
      setOpmeOrAditionalMaterials({ opme: '', aditionalMaterials: '' })
      setHasOpmeOrAditionalMaterials(value)
      return
    }
    setHasOpmeOrAditionalMaterials(value)
  }

  const handleHasHospitalization = (event) => {
    const value = event.target.value
    if (!checkRadioButtonSelected(value)) {
      setHospitalizationQuantities(0)
      setHasHospitalization(value)
      return
    }
    setHasHospitalization(value)
  }

  const handleHasSpecialMedications = (event) => {
    const value = event.target.value
    if (!checkRadioButtonSelected(value)) {
      setSpecialMedications('')
      setHasSpecialMedications(value)
      return
    }
    setHasSpecialMedications(value)
  }

  const handleHasBloodProducts = (event) => {
    const value = event.target.value
    if (!checkRadioButtonSelected(value)) {
      setBloodProducts('')
      setHasBloodProducts(value)
      return
    }
    setHasBloodProducts(value)
  }

  const handleOpmeAndAditionalMaterials = ({ value, key }) => setOpmeOrAditionalMaterials({ ...opmeOrAditionalMaterials, [key]: value })

  const opmeOrAditionalMaterialsComponents = () => (
    <Fragment>
      {opmeComponent()}
      {aditionalMaterialsComponent()}
    </Fragment>
  )

  const opmeComponent = () => (
    <Fragment>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Relação de OPME
      </Typography>
      <TextArea
        rows={3}
        placeholder="Descreva aqui a relação de OPME"
        onChange={value => handleOpmeAndAditionalMaterials({ value, key: 'opme' })}
        value={opmeOrAditionalMaterials.opme}
        width="90%"
        disabled={props.disableFormToEdition}
      />
    </Fragment>
  )

  const aditionalMaterialsComponent = () => (
    <Fragment>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Materiais adicionais (opcional)
      </Typography>
      <TextArea
        rows={3}
        placeholder="Descreva aqui a relação de materiais adicionais"
        onChange={value => handleOpmeAndAditionalMaterials({ value, key: 'aditionalMaterials' })}
        value={opmeOrAditionalMaterials.aditionalMaterials}
        width="90%"
        disabled={props.disableFormToEdition}
      />
    </Fragment>
  )

  const hospitalizationComponent = () => (
    <Fragment>
      <div className={classes.subtitle}>
        <Input
          label="Número de diárias"
          width="10%"
          type="number"
          onChange={event => setHospitalizationQuantities(parseInt(event.target.value))}
          value={hospitalizationQuantities}
          disabled={props.disableFormToEdition}
        />
      </div>
    </Fragment>
  )

  const specialMedicationsComponent = () => (
    <Fragment>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Especifique os medicamentos
      </Typography>
      <TextArea
        rows={3}
        placeholder="Descreva aqui a relação de medicamentos especiais"
        onChange={setSpecialMedications}
        value={specialMedications}
        width="90%"
        disabled={props.disableFormToEdition}
      />
    </Fragment>
  )

  const bloodProductsComponent = () => (
    <Fragment>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Especifique os hemoderivados
      </Typography>
      <TextArea
        rows={3}
        placeholder="Descreva aqui a relação de hemoderivados"
        onChange={setBloodProducts}
        value={bloodProducts}
        width="90%"
        disabled={props.disableFormToEdition}
      />
    </Fragment>
  )

  const clearDuplicateDataInArrayObjects = array => array.filter(function (a) {
    return !this[JSON.stringify(a.id)] && (this[JSON.stringify(a.id)] = true);
  }, Object.create(null))

  const handleSelectHospital = hospital => {
    setHospitals(hospitals.map(item => ({ ...item, checked: item.id === hospital.id ? true : item.checked })))
    hospital.checked = true
    setHospitalsSelected(clearDuplicateDataInArrayObjects([...hospitalsSelected, hospital]))
  }

  const handleUnselectHospital = hospital => {
    const checkIfHospitalAlreadySelected = hospitals.find(item => item.id === hospital.id)

    setHospitalsSelected(clearDuplicateDataInArrayObjects(hospitalsSelected.filter(item => item.id !== hospital.id)))

    if (Boolean(checkIfHospitalAlreadySelected)) {
      setHospitals(hospitals.map(item => ({ ...item, checked: item.id === hospital.id ? false : item.checked })))
    }
  }

  const handleSearchHospital = () => {
    if (hospitalFilter === '') {
      handleChangeCity(city)
      return
    }
    const _hospitals = hospitals.filter(hospital => hospital.name.match(new RegExp(hospitalFilter, 'gi')))
    const hospitalsSelectedIds = hospitalsSelected.map(item => item.id)
    setHospitals(_hospitals.map(item => ({ ...item, checked: hospitalsSelectedIds.includes(item.id) ? true : false })))
  }

  const handleAnesthetist = ({ value, index }) => {
    hospitalsSelected[index] = { ...hospitalsSelected[index], anesthetist: checkRadioButtonSelected(value) }
    setHospitalsSelected([...hospitalsSelected])
  }

  useEffect(() => {
    if (props.handleChange) props.handleChange({
      hasOpmeOrAditionalMaterials: checkRadioButtonSelected(hasOpmeOrAditionalMaterials),
      hasHospitalization: checkRadioButtonSelected(hasHospitalization),
      dailyQuantities: parseInt(dailyQuantities),
      hospitalizationQuantities: parseInt(hospitalizationQuantities),
      specialEquipment,
      opmeOrAditionalMaterials,
      hasBloodProducts: checkRadioButtonSelected(hasBloodProducts),
      bloodProducts,
      // uf,
      // ufList,
      // city,
      // hospitals,
      hospitalsSelected,
      hasSpecialMedications: checkRadioButtonSelected(hasSpecialMedications),
      specialMedications,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasOpmeOrAditionalMaterials,
    opmeOrAditionalMaterials,
    specialEquipment,
    dailyQuantities,
    hasHospitalization,
    hospitalizationQuantities,
    hasBloodProducts,
    bloodProducts,
    // uf,
    // ufList,
    // city,
    // hospitals,
    hospitalsSelected,
    hasSpecialMedications,
    specialMedications,
  ])

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Grid container className={classes.root}>
          <Grid item className={classes.item}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Precisa de OPME ou outros materiais?</b>
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="opme" name="opme" value={hasOpmeOrAditionalMaterials} onChange={handleHasOpmeOrAditionalMaterials}>
                <FormControlLabel disabled={props.disableFormToEdition} value="Sim" control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel disabled={props.disableFormToEdition} value="Não" control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item className={classes.item}>
            {checkRadioButtonSelected(hasOpmeOrAditionalMaterials) && opmeOrAditionalMaterialsComponents()}
          </Grid>
          <Grid item className={classes.item}>
            <Typography variant="subtitle2" className={classes.title}>
              Equipamentos especiais (Ex: Aparelho de ultrassom, arco em C, etc)
            </Typography>
            <TextArea
              rows={3}
              onChange={setSpecialEquipment}
              placeholder="Especifique aqui a relação de equipamentos especiais"
              value={specialEquipment}
              width="90%"
              disabled={props.disableFormToEdition}
            />
          </Grid>
          <Grid item className={classes.item}>
            <Input
              label="Diárias de internação"
              info="Número de diárias"
              width="10%"
              type="number"
              onChange={event => setDailyQuantities(parseInt(event.target.value))}
              value={dailyQuantities}
              disabled={props.disableFormToEdition}
            />
          </Grid>
          <Grid item className={classes.item}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>É necessário internação em UTI?</b>
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="hasHospitalization" name="hasHospitalization" value={hasHospitalization} onChange={handleHasHospitalization}>
                <FormControlLabel disabled={props.disableFormToEdition} value="Sim" control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel disabled={props.disableFormToEdition} value="Não" control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item className={classes.item}>
            {checkRadioButtonSelected(hasHospitalization) && hospitalizationComponent()}
          </Grid>
          <Grid item className={classes.item}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Uso de medicamentos especiais?</b>
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="hasSpecialMedications" name="hasSpecialMedications" value={hasSpecialMedications} onChange={handleHasSpecialMedications}>
                <FormControlLabel disabled={props.disableFormToEdition} value="Sim" control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel disabled={props.disableFormToEdition} value="Não" control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item className={classes.item}>
            {checkRadioButtonSelected(hasSpecialMedications) && specialMedicationsComponent()}
          </Grid>
          <Grid item className={classes.item}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Uso de hemoderivados?</b>
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="hasBloodProducts" name="hasBloodProducts" value={hasBloodProducts} onChange={handleHasBloodProducts}>
                <FormControlLabel disabled={props.disableFormToEdition} value="Sim" control={<Radio color="primary" />} label="Sim" />
                <FormControlLabel disabled={props.disableFormToEdition} value="Não" control={<Radio color="primary" />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item className={classes.item}>
            {checkRadioButtonSelected(hasBloodProducts) && bloodProductsComponent()}
          </Grid>
          <Grid item className={classes.item}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Hospital</b>
            </Typography>
          </Grid>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Cidade
              </Typography>
              <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={4} lg={2}>
                  <Select
                    value={uf}
                    onChange={setUf}
                    itemList={ufList || []}
                    border="1px solid #CDCED9"
                    minWidth={0}
                    onBlur={() => { }}
                    disabled={props.disableFormToEdition}
                  />
                </Grid>
                <Grid item xs={6} lg={8}>
                  <Autocomplete
                    id="combo-box-city"
                    noOptionsText="Sem opções"
                    disableClearable
                    options={cities}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => setCity(newValue)}
                    classes={{ inputRoot: classes.autocomplete }}
                    disabled={props.disableFormToEdition}
                    renderInput={
                      (params) => <TextField {...params} placeholder="Digite a cidade" variant="outlined" />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Filtrar hospital pelo nome
              </Typography>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={11} sm={11}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={hospitalFilter}
                    onChange={event => setHospitalFilter(event.target.value)}
                    InputProps={{ classes: { root: classes.filterHospitalInput } }}
                    disabled={props.disableFormToEdition}
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <IconButton
                    color="primary"
                    aria-label="search"
                    disabled={props.disableFormToEdition}
                    onClick={handleSearchHospital}
                  >
                    <SearchIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={12}>
              <List>
                {
                  !hospitalsSelected.length ? (
                    <Grid container direction="row" justify="center" alignItems="center">
                      <Grid item xs={12} sm={12} className={classes.emptyHospitalList}>
                        <ListItem>
                          <ListItemText align="center">Os hospitais selecionados aparecerão aqui</ListItemText>
                        </ListItem>
                      </Grid>
                    </Grid>
                  ) : hospitalsSelected.filter(hospital => hospital.checked).map((item, index) => (
                    <ListItem className={classes.listHospitals} key={index}>
                      <Grid container justify="flex-start" alignItems="center">
                        <Grid item xs={10}>
                          <Grid container justify="flex-start" alignItems="center">
                            <Grid item>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={item.checked}
                                    color="primary"
                                    name={`selected-${item.name}`}
                                    onChange={() => handleUnselectHospital(item)}
                                    disabled={props.disableFormToEdition}
                                  />
                                }
                                label={item.name}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography style={{ fontSize: 14, fontStyle: 'normal', fontWeight: 600, color: '#6B6C7E' }}>Anestesista do hospital?</Typography>
                          <FormControl component="fieldset">
                            <RadioGroup row aria-label="anesthetist" name="anesthetist" value={item.anesthetist === true ? 'Sim' : 'Não'} onChange={(event) => handleAnesthetist({ value: event.target.value, index })}>
                              <FormControlLabel disabled={props.disableFormToEdition} value="Sim" control={<Radio color="primary" />} label="Sim" />
                              <FormControlLabel disabled={props.disableFormToEdition} value="Não" control={<Radio color="primary" />} label="Não" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
            <Grid item xs={12} sm={12}>
              <List>
                {
                  !hospitals.length && !props.disableFormToEdition ? (
                    <Grid container direction="row" justify="center" alignItems="center" >
                      <Grid item xs={12} sm={12} className={classes.emptyHospitalList}>
                        <ListItem>
                          <ListItemText align="center">Não foi encontrado nenhum hospital com os filtros de busca. Selecione pelo menos o estado e a cidade</ListItemText>
                        </ListItem>
                      </Grid>
                    </Grid>
                  ) : hospitals.filter(hospital => !hospital.checked).map((item, index) => (
                    <ListItem className={classes.listHospitals} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checked}
                            color="primary"
                            name={item.name}
                            onChange={() => handleSelectHospital(item)}
                          />
                        }
                        label={item.name}
                      />
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Fragment>
  )
}
