import React from "react";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import TimePickerIcon from '../../assets/timePicker.png';
import ptBR from 'date-fns/locale/pt-BR';

const useStyles = makeStyles({
    root: {
        width: props => props.width ? props.width : "105px",
        position: "relative",
        margin: props => props.margin ? props.margin : "0",
        border: props => props.border ? props.border : '1px solid #e7e7ed',
        height: '100%',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiFormControl-root': {
            height: '100%',
            '& .MuiInputBase-root': {
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                '& input': {
                    height: '100%',
                    paddingLeft: '10px',
                }
            }
        },
        "& > .MuiFormControl-root > .Mui-error": {
            minHeight: '100%',
        }
    },
    popOver: {
        '& > .MuiPickersBasePicker-container > .MuiPickersToolbar-toolbar': {
            backgroundColor: "#F9B233",
        },
        '& > .MuiPickersBasePicker-container > .MuiPickersBasePicker-pickerView > .MuiPickersClock-container > .MuiPickersClock-clock > .MuiPickersClock-pin': {
            backgroundColor: "#F9B233",
        },
        '& > .MuiPickersBasePicker-container > .MuiPickersBasePicker-pickerView > .MuiPickersClock-container > .MuiPickersClock-clock > .MuiPickersClockPointer-pointer': {
            backgroundColor: "#F9B233",
        },
        '& > .MuiPickersBasePicker-container > .MuiPickersBasePicker-pickerView > .MuiPickersClock-container > .MuiPickersClock-clock > .MuiPickersClockPointer-pointer > .MuiPickersClockPointer-thumb': {
            backgroundColor: "#F9B233",
            borderColor: '#F9B233',
        },
    }
})

function BasicTimePicker({selectedTime, setTime, ...props}) {
    
    const classes = useStyles(props);
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <div className={classes.root}>
                <KeyboardTimePicker
                    variant="inline"
                    invalidDateMessage="Horário Inválido"
                    autoOk
                    ampm={false}
                    initialFocusedDate={0}
                    minutesStep={5}
                    value={selectedTime}
                    onChange={(date) => {
                        setTime(date)}
                    }
                    keyboardIcon={<img src={TimePickerIcon} alt="Escolha a data" />}
                    PopoverProps={{classes:{paper: classes.popOver}}}
                    disabled={props.disabled}
                />
            </div>
        </MuiPickersUtilsProvider>
  );
}

export default BasicTimePicker;