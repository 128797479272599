import graphqlApi from './api'
import format from 'date-fns/format'

export const getMedicalAppointmentsDaily = async (
  providerId,
  medicalAppointmentId,
  userType,
  page = 1,
  limit = 10,
  status,
  date = new Date()
) => {
  try {
    if(!status)
      status = ['OK', 'PENDING_TOKEN_VALIDATION', 'PENDING_TRIAGE', 'PENDING_PAYMENT']

    const queryRequest = `
      count
      rows {
        id
        date
        beginAppointment
        dateTimeBeginAppointmentUtc
        dateTimeEndAppointmentUtc
        appointmentType
        status
        price
        appointmentLocation
        token
        payment {
          id
          status
        }
        providerId
        establishmentId
        employeeId
        medicalAppointmentReturnGenerated {
          id
        }
        patient {
          id
          socialName
          birth
          gender
          user {
            name
            email
            cpf
          }
        }
        healthOperatorRequest {
          id
          healthOperator {
            id
            fantasyName
            companyName
          }
        }
        provider {
          id
          doctor {
            crm
            uf
          }
          user {
            name
          }
        }
        triage {
          id
          status
          symptoms
          drugs
          allergies
          diseases
          attachmentHasTriage {
            file
            uri
          }
        }
      }
    `;

    if(userType === "PROVIDER_SECRETARY") {
      const res = await graphqlApi({
        data: {
          query: `
            query($input: MedicalAppointmentsSecretaryDailyInput!) {
              medicalAppointmentsSecretaryDaily(input: $input) {
                ${queryRequest}
              }
            }
          `,
          variables: {
            input: { providersId: providerId, page, limit, status, date: format(date, 'yyyy-MM-dd') },
          },
        }
      })

      if(!res?.data?.errors) {
        const totalPatientsOnDay = res.data.data.medicalAppointmentsSecretaryDaily.count;
        const patientsList = res.data.data.medicalAppointmentsSecretaryDaily.rows;

        return { totalPatientsOnDay, patientsList }
      } else {
        return { error: res.data.errors }
      }

    } else {
      const res = await graphqlApi({
        data: {
          query: `
            query($input: MedicalAppointmentsDailyInput!) {
              medicalAppointmentsDaily(input: $input) {
                ${queryRequest}
              }
            }
          `,
          variables: {
            input: {
              medicalAppointmentId,
              page,
              limit,
              status,
              date: format(date, 'yyyy-MM-dd')
            },
          },
        }
      })

      if(!res.data.data.errors) {
        const totalPatientsOnDay = res.data.data.medicalAppointmentsDaily.count;
        const patientsList = res.data.data.medicalAppointmentsDaily.rows;

        return { totalPatientsOnDay, patientsList }
      } else {
        return { error: res.data.data.errors }
      }
    }

  } catch (error) {
    return { error }
  }
}
