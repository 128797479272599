import React from "react";
import { useDispatch } from 'react-redux';

import { DialogContent, DialogContentText } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import {convertToMinutes} from '../../../../utils/time/convertToMinutes';
import {convertToText} from '../../../../utils/time/convertToText';

import Button from '../../../../common/button';

import * as scheduleActions from '../../../../common/store/actions/schedule.action';
import * as asyncActions from '../../../../common/store/actions/async.action';
import { setMinutes } from "date-fns";

// Material UI Styling

const useStyles = makeStyles( {
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: "#EDF9F0",
    borderBottom: '1px solid #E7E7ED',
  },
  title: {
    color:"#287D3C",
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '10px'
    }
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  },  
  buttonBg: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: "flex-end",
    padding: '10px 0',
    marginTop: '13px',
    boxSizing: 'border-box',
  }
})

const ModalSlotDeactivate = ({
  scheduleGroupProviderId, 
  scheduleHasProviderId, 
  scheduleGroupEmployeeId,
  scheduleHasEmployeeId,
  timeStamp, 
  day, 
  duration,
  unavailabilityProviderId,
  unavailabilityEstablishmentId,
  status,
  ...props
}) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();

  const providerId = window.localStorage.getItem('Prov-Vida-Connect');
  const employeeId = window.localStorage.getItem('employeeId');
  const unitId = window.localStorage.getItem('Unit-Vida-Connect');

  let beginUnavailability = `${timeStamp}:00`;
  let dateTimeBeginUnavailabilityUtc = "";
  let endUnavailability = "";
  let dateTimeEndUnavailabilityUtc = "";
  if(day && timeStamp) {
    const timeStampInMinutes = convertToMinutes(timeStamp);
    endUnavailability = `${convertToText(timeStampInMinutes + duration)}:00`;
    const dateBeginUnavailabilityLocal = setMinutes(day, timeStampInMinutes);
    const dateEndUnavailabilityLocal = setMinutes(day, (timeStampInMinutes + duration));
    dateTimeBeginUnavailabilityUtc = dateBeginUnavailabilityLocal.toISOString();
    dateTimeEndUnavailabilityUtc = dateEndUnavailabilityLocal.toISOString();
  }

  let isEmployee = false;
  if(employeeId) isEmployee = true;
  const whatFunctionToUpdateAnUnavailabilityShouldIDispatch = (payload) => {
    if(isEmployee) return dispatch(asyncActions.updateEmployeeUnavailability(payload));
    return dispatch(asyncActions.updateUnavailability(payload));
  }
  const whatFunctionToCreateAnUnavailabilityShouldIDispatch = (payload) => {
    if(isEmployee) return dispatch(asyncActions.createEmployeeUnavailability(payload));
    return dispatch(asyncActions.createUnavailability(payload));
  }


  const whatToDispatch = () => {
    switch (status) {
      case "DISABLED":
        return (
          whatFunctionToUpdateAnUnavailabilityShouldIDispatch({
            status: "OK",
            unavailabilityProviderId,
            unavailabilityEstablishmentId,
            providerId,
            employeeId,
            unitId
          })
        );
      case "CANCELED":
        return (
          whatFunctionToUpdateAnUnavailabilityShouldIDispatch({
            status: "DISABLED",
            unavailabilityProviderId,
            unavailabilityEstablishmentId,
            providerId,
            employeeId,
            unitId
          })
        );
      case "OK":
        return (
          whatFunctionToUpdateAnUnavailabilityShouldIDispatch({
            status: "DISABLED",
            unavailabilityProviderId,
            unavailabilityEstablishmentId,
            providerId,
            employeeId,
            unitId
          })
        )
      default:
        return (
          whatFunctionToCreateAnUnavailabilityShouldIDispatch({
            scheduleGroupProviderId,
            scheduleHasProviderId,
            scheduleGroupEmployeeId,
            scheduleHasEmployeeId,
            status: "DISABLED",
            date: day,
            beginUnavailability,
            endUnavailability,
            dateTimeBeginUnavailabilityUtc,
            dateTimeEndUnavailabilityUtc,
            providerId,
            unitId
          })
        )
    }
  };

  return (
      <DialogContent>
        <div className="modal-body">
          <DialogContentText>
            {status === "DISABLED" ? "Deseja reativar essa vaga?" : "Deseja desativar essa vaga?"}
          </DialogContentText>
          <div className={classes.buttonBg}>
            <Button
              onClick={props.handleClose}
              width="72px"
              border="1px solid #CDCED9"
              borderRadius="4px"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 0 0 20px"
            >
              Não
            </Button>
            <Button
              onClick={() => {
                whatToDispatch();
                dispatch(scheduleActions.updateCalendarFront(true));
              }}
              width="72px"
              border="1px solid #CDCED9"
              borderRadius="4px"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 0 0 20px"
            >
              Sim
            </Button>
          </div>
        </div>
      </DialogContent>
  );
};

export default ModalSlotDeactivate;