import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../common/button';
import SnackBar from '../../common/snackbar/syncAlternative';
import AsyncSnackBar from '../../common/snackbar/index';
import AudioBar from '../../common/audioBar';
// import Loading from '../../common/loading';

import * as consultationActions from '../../common/store/actions/consultation.action';
import * as asyncActions from '../../common/store/actions/async.action';

import Video from '../../assets/videocam.png';
import Audio from '../../assets/mic.png';
//import EndCall from '../../assets/call_end.png';

import {getTwilioToken} from '../../utils/videoCall/createARoom';
import {connectToARoom} from '../../utils/videoCall/connectingToARoom';
import {letApiKnowDoctorIsOut, leavingARoom} from '../../utils/videoCall/leavingARoom';
import {muteTrack, enableTrack} from '../../utils/videoCall/dealingWithTracks';
import {pollAudioLevel} from '../../utils/videoCall/pollAudioLevel';

const useStyles = makeStyles({
    callScreen: {
        width: '43%',
        display: "flex",
        alignItems: 'center',
        flexDirection: 'column',
        position: 'fixed',
        
    },
    start: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    remoteParticipant: {
        backgroundColor: 'black',
        width: '100%',
        height: '70%',
        "& video":{
            maxHeight: '60vh'
        },
        marginRight: '8px'
    },
    localParticipant: {
        width: '19%',
        position: "absolute",
        bottom: 0,
        right: 0,
        "& > video": {
            position: 'absolute',
            bottom: '0',
        },
        marginRight: '5px'
    },
    audioBar: {
        height: '20px',
        width: '20px',
        position: 'absolute',
        bottom: '0',
    },
    video: {
        width: '100%',
    },
    optionsBar: {
        width: '35%',
        margin: 'auto',
        position: "absolute",
        bottom: "5%",
        left: 0,
        right: 0,
        opacity: '0',
        transform: 'translateY(20%)',
        transition: "all 0.2s ease-in-out",
        display: 'flex',
        justifyContent: 'space-around',
    },
    button: {
        position: 'relative',
    },
    muted: {
        "&:after": {
            content: '""',
            position: 'absolute',
            top: '-15%',
            bottom: '-15%',
            left: '50%',
            width: '5%',
            backgroundColor: 'red',
            transform: "rotate(45deg) translateX(50%)"
        }
    },
    on: {
        opacity: '1',
        transform: 'translateY(0)',
        zIndex: '1'
    },
    off: {
        opacity: '0',
    }
})

const VideoCall = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {roomName, identity} = JSON.parse(window.localStorage.getItem('twilio') || '{}');
    const medicalAppointmentId = window.localStorage.getItem('appointId');

    const remoteVideoRef = useRef();
    const remoteAudioRef = useRef();
    const localVideoRef = useRef();
    const { Room, setRoom } = props;
    const [audioMuted, setAudioMuted] = useState(false);
    const [audioLevel, setAudioLevel] = useState(0);
    const [videoMuted, setVideoMuted] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    const [participant, setParticipant] = useState(null);
    const [participantStatusWarning, setParticipantWarning] = useState(false);
    const [optionsOn, setOptionsOn] = useState(false);
    const [forcedExit, setForcedExit] = useState(false);
    
    const live = useSelector((state) => state.ConsultationReducer.live);
    const statusConsultation = useSelector((state) => state.ConsultationReducer.statusConsultation);

    useEffect( () => {
        if(participant) setParticipantWarning(true);
    },[participant]);

    useEffect( () => {

        if(Room) {
            return async () => {
                await leavingARoom(Room, weAreLive);
                await letApiKnowDoctorIsOut(medicalAppointmentId);
            };
        }
        //eslint-disable-next-line
    },[Room])

    useEffect( () => {
        return () => setForcedExit(true);
    }, [])

    const weAreLive = (boo) => {
        dispatch(consultationActions.weAreLive(boo))
    }

    useEffect (() => {
        if(statusConsultation !== "initialized"){
            dispatch(consultationActions.updateStatusConsultation('not initialized'))
        }
        // eslint-disable-next-line
    },[])

    const connectingToARoom = async (localVideoRef, remoteVideoRef, remoteAudioRef, weAreLive) => {
        try {
            const {token} = await getTwilioToken(roomName, identity, medicalAppointmentId);
            let roomConfig = await connectToARoom(
                token,
                roomName,
                localVideoRef, 
                remoteVideoRef, 
                remoteAudioRef, 
                weAreLive,
                medicalAppointmentId,
                setParticipant,
                setConnectionError
            );
            setRoom(roomConfig);
            if(roomConfig) {
                let localAudioTrack = null;
                roomConfig.localParticipant.tracks.forEach(publication => {
                    if(publication.kind === "audio") localAudioTrack = publication.track
                } );
                pollAudioLevel(localAudioTrack, (level) => {
                    if(!forcedExit) setAudioLevel(level)
                });
            }
        } catch(error) {
            dispatch(asyncActions.requestFailure("Não foi possível se conectar à sala de vídeo. Tente novamente, por favor."));
        }
    };


    return (
        <div className={classes.callScreen} onMouseEnter={() => setOptionsOn(true)} onMouseLeave={() => setOptionsOn(false)}>
            <div className={classes.remoteParticipant} ref={remoteAudioRef}>
                <video className={classes.video} ref={remoteVideoRef} autoPlay={true} muted={false} />
            </div>
            <div className={`${classes.localParticipant} ${live ? null : classes.off}`}>
                <video 
                    className={classes.video} 
                    ref={localVideoRef} 
                    autoPlay={true} 
                    muted={false} 
                />
                <div className={classes.audioBar}>
                    <AudioBar audioLevel={audioLevel}/>
                </div>
            </div>
            {live ?
                <div className={`${classes.optionsBar} ${optionsOn? classes.on : ""}`}>
                    <div className={`${classes.button} ${videoMuted ? classes.muted : ""}`}>
                        <Button
                            backgroundColor="#FFFFFF"
                            opacity={videoMuted ? "0.7" : "1"}
                            width="45px"
                            height="45px"
                            clipPath="circle()"
                            padding="0"
                            onClick={() => {
                                if(videoMuted) {
                                    enableTrack(Room, "video", localVideoRef);
                                } else {
                                    muteTrack(Room, "video");
                                };
                                setVideoMuted((prevState) => !prevState);
                            }}
                        >
                            <img src={Video} alt="Desligar Vídeo"/>
                        </Button>
                    </div>
                    <div  className={`${classes.button} ${audioMuted ? classes.muted : ""}`}>
                        <Button
                            backgroundColor="#FFFFFF"
                            opacity={audioMuted ? "0.7" : "1"}
                            width="45px"
                            height="45px"
                            clipPath="circle()"
                            padding="0"
                            onClick={() => {
                                if(audioMuted) {
                                    enableTrack(Room, "audio", localVideoRef)
                                } else {
                                    muteTrack(Room, "audio");
                                };
                                setAudioMuted((prevState) => !prevState);
                            }}
                        >
                            <img src={Audio} alt="Desligar Audio"/>
                        </Button>
                    </div>
                    <div className={classes.button}>
                        {/* 
                        <Button
                            className="button"
                            backgroundColor="#FFFFFF"
                            width="45px"
                            height="45px"
                            clipPath="circle()"
                            padding="0"
                            onClick={() => {
                                leavingARoom(Room, weAreLive);
                                setRoom(null);
                            }}
                        >
                            <img src={EndCall} alt="Desligar a Chamada"/>
                        </Button>
                        */}
                    </div>
                </div>
                :
                <div className={classes.start}>
                    {statusConsultation === "not initialized" && (
                        <Button
                        backgroundColor="#DF9000"
                        disabled={live}
                        color="white"
                        fontSize="16px"
                        width="25vw"
                        onClick={() => {
                            dispatch(consultationActions.updateStatusConsultation('initialized'))
                            dispatch(consultationActions.consultationIsRunning(true))
                            connectingToARoom(localVideoRef, remoteVideoRef, remoteAudioRef, weAreLive)
                        }}
                        >
                        Iniciar videochamada
                        </Button>
                    )}
                   
                </div>
            }
            <AsyncSnackBar />
            <SnackBar 
                errorMessage="Não foi possível acessar a reunião, tente novamente por favor."
                open={connectionError ? true : false}
                onClose={setConnectionError}
                duration={10000}
            />
            <SnackBar 
                severity="success"
                errorMessage={`Um convidado está entrando na sala.`}
                open={participantStatusWarning}
                onClose={setParticipantWarning}
                duration={10000}
                backgroundColor="#DF9000"
            />
        </div>
    )
}

export default VideoCall;
