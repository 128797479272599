import React from "react";
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    border: "none",
    margin: props => props.margin ? props.margin : "12px 0 0 0",
    width: props => props.width,
    "& span.info": {
      display:'block',
      color: 'rgb(107, 108, 126)'
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
      marginTop: 6,
      color: "#000000",
      fontSize: 10
    },

    '& .MuiFormHelperText-root' : {
      color: '#DA1414'
    },

    '& .MuiInput-underline::before': {
      borderBottom: 'none !important'
    },

    '& .MuiInput-underline::after': {
      borderBottom: '1px solid #f9b233 !important'
    }
  },
  inputs: {
    fontSize: props => props.fontSize? props.fontSize : null,
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : "#FFFFFF",
    color: (props) => props.inputTextColor ? props.inputTextColor : null,
    border: props => props.border ? props.border : '1px solid #e7e7ed',
    borderRadius: props => props.borderRadius ? props.borderRadius : "4px",
    paddingLeft: '5px',
    height: props => props.height ? props.height : '40px',
  }
});

export default (props) => {
  const classes = useStyles(props);
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <span>
          <strong className="label-input">{props.label}</strong>
        </span>
        <span className="info">
          {props.info}
        </span>
        <TextField
          type={props.type}
          fullWidth
          disabled={props.disabled}
          placeholder= {props.placeholder ? props.placeholder : null}
          helperText= {props.error ? props.error : null}
          inputProps={{className: classes.inputs, ...props.inputProps, "data-test":props.dataTest}}
          InputProps={props.InputProps}
          onChange={props.onChange}
          value={props.value}
          onBlur={props.onBlur}
        >{props.children}</TextField>
      {props.link ?         
        <span className="forgot-password-text">
          <a href={props.link}>{props.linkText} </a>
        </span>
        :
        null
      }
      </Grid>
    </Grid>
  )
};
