import apiGraphql from "../../../services/api";
import * as asyncActions from './async.action'
import * as consultationAction from './consultation.action'

const virtuaAddressInfo = {
  address: 'ACSU SO 40, Avenida LO 11, Conj. 02, Lote 02, 11º Andar, Sala 1.106', 
  city: 'Palmas',
  cnes: '6463754', 
  country: 'Brasil',
  district: 'TO',
  neighborhood: 'Plano Diretor Sul',
  phone: '(63) 3228-6015', 
}

export function getLocalData(medicalAppointmentId, isVirtuaAppointment) {
  return async (dispatch) => {
    try {
      if(!isVirtuaAppointment){
      const res = await apiGraphql({
        data: {
          query: `
          query {
            getMedicalAppointmentLocal(medicalAppointmentId: "${medicalAppointmentId}") {
              cnes,
              country,
              zipCode,
              address,
              number,
              complement,
              neighborhood,
              district,
              city,
              phone
            }
          }
          `
        }
      })
      if(res.data.data) {
        await dispatch(
          consultationAction.setConsultationLocal(res.data.data.getMedicalAppointmentLocal)
        )
      }
    }
      else{
        await dispatch(
          consultationAction.setConsultationLocal(virtuaAddressInfo)
        )
      }
    } catch (error) {
      dispatch(
        asyncActions.requestFailure(
          "Houve um problema ao tentar buscar o local da consulta. Tente novamente, por favor."
        )
      )
    }
  }
}