import { StyleSheet, Font } from '@react-pdf/renderer'


Font.register({
    family: 'Montserrat',
    src: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap'
});

export const styles = StyleSheet.create({
    body: {
        paddingTop: 55,
        paddingBottom: 50,
        paddingHorizontal: 35,
    },
    header: {
        width: '100%',
        marginBottom: 30
    },
    image: {
        position: 'absolute',
        top: 20,
        left: 35,
        width: '24mm',
        height: '8mm'
    },
    title: {
        fontFamily: 'Helvetica-Bold',
        fontSize: 30,
        marginHorizontal: 'auto',
        width: '50%',
        textAlign: 'center',
        wordBreak: 'keepAll',
        position: 'relative',
        top: -20
    },
    text: {
        fontFamily: 'Helvetica',
        textAlign: 'justify',
        lineHeight: 1.5,
        fontSize: 12
    },
    textBold: {
        fontFamily: 'Helvetica-Bold'
    },
    smallText: {
        fontSize: 11,
        lineHeight: 1.5
    },
    topSeparator: {
        marginTop: 20
    },
    bordered: {
        borderWidth: 1
    },
    margined: {
        margin: 5
    },
    seassonTitle: {
        marginTop: 20,
        borderWidth: 1,
        fontFamily: 'Helvetica-Bold',
    },
    flexRow: {
        flexDirection: 'row'
    },  
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 20,
        //left: 0,
        right: 20,
        // textAlign: 'right',
        color: 'grey',
    }
})
