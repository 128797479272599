import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Filter from '../../assets/angle-down.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '180px',
    marginTop: '14px',
    padding: 0,
  },
  listItem: {
    borderRadius: '4px',
    height: '40px',
    margin: "10px 0",
    padding: '0 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icons: {
    minWidth: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginRight: '2%'
  },
  icon:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: "16px",
  },
  checkbox: {
    padding: '0 !important',
    margin: '0 !important',
  },
  selected: {
      color: "#F9B233 !important"
  },
  list: {
    display: 'flex',
    width: '48px',
    justifyContent: 'flex-start'
  },
  button: {
    width: '30%',
    minWidth: "0 !important",
  },
  svg: {
    fill: '#6b6c7e',
  },
  onTop: {
    zIndex: '1',
    width: '20%',
  },
  listContent: {
    margin: '0 1% 0 5%',
  }
}));

export default function CheckboxList(props) {
  const classes = useStyles();

  const selectToggle = (value) => () => {
    if(props.selected.length > 0) {
      const alreadySelected = props.selected.reduce((acc, item) => {
        return acc || item === value
      }, false);
      if (alreadySelected) {
        const filteredArray = props.selected.filter( (item) => {
          return item !== value
        });
        props.setSelected(filteredArray)
      } else {
        props.setSelected((prevState) => [...prevState, value]);
      }
      
    } else {
      props.setSelected([value]);
    }
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }


  return (
    <div className={classes.list}>
        <Button
          disableRipple
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          className={classes.button}
          onClick={handleToggle}
        >
            <img src={Filter} alt="filter" className={classes.svg}/>
        </Button>
        <Popper 
          open={open}
          placement="left-start"
          anchorEl={anchorRef.current} 
          role={undefined} 
          className={classes.onTop} 
          transition 
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} className={classes.listContent}>
                  {props.selectItems.map((item) => {
                    const labelId = `checkbox-list-label-${item.value}`;
                    return (
                      <ListItem 
                        key={item.value} 
                        classes={{
                            root:classes.listItem,
                        }} 
                        role={undefined} dense button 
                        onClick={selectToggle(item.value)}
                      >
                        <ListItemIcon classes={{root: classes.icons}}>
                          <Checkbox
                            edge="start"
                            checked={
                              props.selected.length > 0 ?
                                props.selected.reduce((acc, currValue) => {
                                  return acc || currValue === item.value
                                }, false)
                                :
                                false
                            }
                            icon={<CheckBoxOutlineBlankIcon classes={{root: classes.icon}}/>}
                            checkedIcon={<CheckBoxIcon classes={{root: classes.icon}}/>}
                            classes={{
                                checked: classes.selected,
                                root: classes.checkbox,
                            }}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={item.label} />
                      </ListItem>
                    );
                  })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </div>
  );
}