import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Topic from '../../../../common/topic'
import Button from '../../../../common/button';

import { Consultations } from './consultations'

import { Exams } from './exams'
//import { OtherServices } from './otherServices'

// import { Proceedings } from './proceedings'

import * as consultation from "../../../../common/store/actions/registerConsultations";
import * as examActions from "../../../../common/store/actions/registerExams";
import * as addressAction from "../../../../common/store/actions/registerAddress";
import * as proceedingAction from "../../../../common/store/actions/registerProceeding";
import * as otherServicesActions from "../../../../common/store/actions/registerOtherServices";
import * as asyncActions from '../../../../common/store/actions/async.action';


export const RegisterServices = (props) => {
  const dispatch = useDispatch();

  const activeAddress = useSelector( (state) => state.Address.active);
  const activeAddressData = useSelector( (state) => state.Address.addresses[activeAddress]);
  
  const address = activeAddressData?.addressgit;

  const consultations = useSelector((state) => state.RegisterConsultations.consultations);
  const exams = useSelector((state) => state.Exams.exams);
  const examsSpreadsheet = useSelector((state) => state.Exams.examsSpreadsheet);
  const proceedings = useSelector((state) => state.Proceedings.proceedings);
  const otherServices = useSelector( (state) => state.OtherServices.otherServices);

  function handleCancel() {
    dispatch(consultation.deleteAllConsultations());
    dispatch(examActions.deleteAllExams());
    dispatch(proceedingAction.deleteAllProceedings());
    dispatch(otherServicesActions.deleteAllOtherServices());
    dispatch(addressAction.toggleRegisterServices());
    dispatch(examActions.deleteExamsFromSpreadsheet());
  }

  const handleSave = () => {
    dispatch(
      addressAction.insertServices({
        consultations,
        exams,
        proceedings,
        otherServices,
        examsSpreadsheet
      })
    );
    dispatch(addressAction.toggleRegisterServices());
    dispatch(consultation.deleteAllConsultations());
    dispatch(examActions.deleteAllExams());
    dispatch(proceedingAction.deleteAllProceedings());
    dispatch(otherServicesActions.deleteAllOtherServices());
    dispatch(examActions.deleteExamsFromSpreadsheet());
  }

  useEffect( () => {
    dispatch(asyncActions.getAppointmentFee());
    //eslint-disable-next-line
  },[])

  const showSaveButton = () => {

    return !(consultations.length > 0 ||
    otherServices.length > 0 ||
    proceedings.length > 0 ||
    exams.length > 0 ||
    !!examsSpreadsheet?.name)
  }

  return (
    <div className="form-content">
      <div className="grey-bar grey-bar-header">
        <div className="form-layout">
          <div className="grey-bar-header">
            <div>
              <span className="address-title">
                  <Topic
                    marginTop="0px"
                    fontSize="1.7rem"
                    classesNameCol="topic-datas"
                    title={address}
                    subtitle="Nos informe quais serviços você prestará neste endereço"
                    classesNameSubtitle="text-black"
                  />
                
              </span>
            </div>

            <div className="side-by-side">
              <Button 
                onClick={handleCancel}
                width="142px"
                border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
                margin="0 20px 0 0"
              >
                Cancelar
              </Button>
              <Button
                width="142px"
                border="1px solid #DF9000"
                backgroundColor="#FFFFFF"
                color="#DF9000"
                onClick={handleSave}
                disabled={showSaveButton()}
              >
                Salvar
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="form-layout">
        <Topic
          fontSize="1.7rem"
          classesNameCol="topic-datas firt-topic"
          title="Serviços"
          subtitle="Nos informe quais serviços você prestará neste endereço"
          classesNameSubtitle="text-black"
        />
      </div>
      
      <Consultations 
      professionalType={props.professionalType}
    />
     { props.professionalType === 'doctor' &&
        <Exams />
      }
     
    </div>
  )
}
