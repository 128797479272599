import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as asyncActions from '../common/store/actions/async.action';
import { Route, useHistory } from "react-router-dom";

import Navbar from "../common/navbar";
import Loading from '../common/loading';

const AuthRoute = ({ component, location, ...rest }) => {
  const history = useHistory();
  const status = window.localStorage.getItem('status');
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.AsyncReducer.waitingTokenValidation);
  const estabName = window.localStorage.getItem('estabName')
  const pathname = location.pathname

  useEffect( () => {
    dispatch(asyncActions.checkToken(history, "/", status));
    // eslint-disable-next-line
  },[pathname])

  useEffect( () => {
    const userType = localStorage.getItem('userType')
    if (userType === "PROVIDER_SECRETARY") {
      const { secretaryId } = JSON.parse(localStorage.getItem('secData'))
      dispatch(asyncActions.getDoctorsList(secretaryId));
    }
     // eslint-disable-next-line
  }, [])

  useEffect( () => {
    if (estabName !== "Virtua") history.push('/administrador')
    
    // eslint-disable-next-line
  }, [estabName])

  if(loading) {
    return <Loading width="80px"/>
  }
  return (
    <React.Fragment>
      <Navbar />
      <Route {...rest} component={component} />
    </React.Fragment>
  )
}

 export default AuthRoute;