import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import { startOfDay, differenceInMilliseconds } from 'date-fns';

import "../style.css";
import * as scheduleActions from "../../../../common/store/actions/schedule.action";
import Topic from "../../../../common/topic";
import Button from '../../../../common/button';
import ClockList from '../../../../common/clockList';

const useStyles = makeStyles( {
  root: {
    width: '45%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    borderBottom: '1px solid #E7E7ED',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  },
  lettering2: {
    color: "#393A4A",
  },
  buttonBg: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F7F8F9',
    display: 'flex',
    justifyContent: "flex-end",
    padding: '22px',
    marginTop: '28px',
    boxSizing: 'border-box',
  }
})

const ModalCreateWorkTime = (props) => {
    const dispatch = useDispatch();

    const selectedDays = useSelector((state) => state.ScheduleReducer.daysOfWeek);
    const listToRender = selectedDays.sort( (a,b) => a.weight - b.weight).map(ordered => ordered.day);
    const initialState = listToRender.map( (value, index) => {
      const translation = {
        Domingo: "SUN",
        Segunda: "MON",
        Terça: "TUE",
        Quarta: "WED",
        Quinta: "THU",
        Sexta: "FRI",
        Sábado: "SAT",
      }
      return {
        dayOfWeek: translation[value],
        beginShift: startOfDay(new Date()),
        endShift: startOfDay(new Date()),
      }
    })
    const alreadyRegisteredSomething = useSelector((state) => state.ScheduleReducer.daysOfWeekWithHours);
  
    const [workTimesPerDay, setWorkTimes] = useState(alreadyRegisteredSomething || initialState);
    const [invalidIntervalError, setError] = useState("");
  
    const setTime = (attr, value, index) => {
      const actual = [...workTimesPerDay];
      const newWorkTime = {...actual[index], [attr]: value}; 
      actual.splice(index, 1, newWorkTime);
      setWorkTimes(actual);
    }

    useEffect(() => {
      props.onChange(workTimesPerDay) //propriedade dentro do objeto props
    },[workTimesPerDay, props.onChange, props])

    const [checked, setChecked] = useState(false);

    const onCheck = (index) => {
      setChecked((prevState) => !prevState);
      const actual = [...workTimesPerDay];
      const startTime = actual[index].beginShift;
      const endTime = actual[index].endShift;
      const newArr = actual.map((day) => {
        return { ...day, beginShift: startTime, endShift: endTime }
      })
      setWorkTimes(newArr);
    }
  
    const onSaveAndNext = () => {
      const invalidDateError = workTimesPerDay.filter((day) => {
        //eslint-disable-next-line
        return day.beginShift == 'Invalid Date' || day.endShift == 'Invalid Date'
      }).length > 0;
      const endShiftIsEarlierThanBegin = workTimesPerDay.reduce((acc, cur) => {
        
        const beginShift = cur.beginShift;
        let endShift = cur.endShift;

        return acc || (differenceInMilliseconds(beginShift, endShift) >= 0);

      }, false);

      if(endShiftIsEarlierThanBegin) {
        setError("O horário final do turno não pode ser menor ou igual que o inicial")
      };

      if(!invalidDateError && !endShiftIsEarlierThanBegin){
        dispatch(scheduleActions.addDaysHours(workTimesPerDay));
        props.handleNext();
      }
    }

    
    const classes = useStyles();

    return (
      <>
        <div className={classes.header}>
          <DialogTitle>
            Criar Agenda
          </DialogTitle>
          <IconButton aria-label="close" onClick={() => props.handleClose()}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <form>
            <div className="modal-body">
              <Topic
                title="Qual o horário de trabalho?"
                fontSize="16px"
                marginTop="20px"
              />
              <div>
                <ClockList 
                  selectItems={listToRender}
                  setTime={setTime}
                  workTimes={workTimesPerDay}
                  onCheck={onCheck}
                  checked={checked}
                  error={invalidIntervalError}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <div className={classes.buttonBg}>
          <Button 
            onClick={() => props.setStep((prevStep) => prevStep-1)}
            width="120px"
            border="1px solid #CDCED9"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            margin="0 20px 0 0"
          >
            Voltar
          </Button>
          <Button 
            onClick={onSaveAndNext}
            width="120px"
            border="1px solid #DF9000"
            backgroundColor="#FFFFFF"
            color="#DF9000"
          >
            Próximo
          </Button>
        </div>
      </>
  );
};

export default ModalCreateWorkTime;
