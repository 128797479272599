import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: props => props.margin ? props.margin : null ,
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : '#F9B233',
    border: props => props.border ? props.border : null ,
    borderLeft: props => props.borderLeft ? props.borderLeft : null ,
    borderRight: props => props.borderRight ? props.borderRight : null ,
    borderTop: props => props.borderTop ? props.borderTop : null ,
    borderBottom: props => props.borderBottom ? props.borderBottom : null ,
    borderRadius: props => props.borderRadius ? props.borderRadius : null,
    color: props => props.color ? props.color : null,
    fontFamily: 'Roboto',
    fontSize: props => props.fontSize ? props.fontSize : '16px',
    fontWeight: props => props.fontWeight ? props.fontWeight : null,
    padding: props => props.padding ? props.padding : null,
    textTransform: 'none',
    width: props => props.width,
    height: props => props.height,
    clipPath: props => props.clipPath,
    display:'flex',
    justifyContent: 'center',
    opacity: props => props.opacity ? props.opacity : null,
    '&:hover': {
      backgroundColor: '#F9B233',
      borderColor: "transparent",
      color: '#FFFFFF'
    },

    '&:disabled': {
      backgroundColor: '#fafafa',
      border: '1px solid #e7e7ed'
    },
  },
  startIcon: {
    margin: '0'
  }

}));
const StyledButton = withStyles({
  root:{
    minWidth:"77px",
    minHeight:"32px",
    border:"1px solid #DF9000",
    backgroundColor:"#FFFFFF", 
    color:"#DF9000",
    margin:"0 0 0 22px" 
  }
})(Button)

const PrimaryButton=({styled="normal", ...props}) => {
  const classes = useStyles(props);

  return (
    <div>
      {styled === "normal" ? 
      <Button
        disableElevation
        variant="contained"
        classes={{
          root:classes.root,
          startIcon: classes.startIcon}}
        onClick={props.onClick}
        onBlur={props.onBlur}
        startIcon={props.startIcon}
        disabled={props.disabled}
        data-test={props.dataTest}
      >
        {props.children}
      </Button>
      :
      <StyledButton
      disableElevation
      variant="contained"
      classes={{
        root:classes.root,
        startIcon: classes.startIcon}}
      onClick={props.onClick}
      onBlur={props.onBlur}
      startIcon={props.startIcon}
      disabled={props.disabled}
      data-test={props.dataTest}
      myButtonStyle={props.myButtonStyle}
      >
      {props.children}
      </StyledButton>
    }
    </div>
  );
}

export default PrimaryButton;
