import React from "react";
import { useSelector, useDispatch } from "react-redux";

import StepByStep from '../../../../common/stepByStep/stepByStep';
import Topic from "../../../../common/topic";
import List from '../../../../common/itemList/list';

import AddressModal from '../../../../common/modal/address/registterAddress';

import * as addressActions from '../../../../common/store/actions/registerAddress';
import * as consultationActions from '../../../../common/store/actions/registerConsultations';
import * as examActions from '../../../../common/store/actions/registerExams';
import * as proceedingActions from '../../../../common/store/actions/registerProceeding';
import * as otherServiceActions from '../../../../common/store/actions/registerOtherServices';

export default (props) => {
  const addresses = useSelector((state) => state.Address.addresses);
  const dispatch = useDispatch();

  const deleteAddress = (index) => {
    dispatch(addressActions.deleteAddress(index));
  };

  const updateAddressServices = (index) => {
    dispatch(consultationActions.ResetConsultation(addresses[index].consultations));
    dispatch(examActions.ResetExams(addresses[index].exams));
    dispatch(examActions.RegisterExamsFromSpreadsheet(addresses[index].examsSpreadsheet));
    dispatch(proceedingActions.ResetProceeding(addresses[index].proceedings));
    dispatch(otherServiceActions.ResetOtherServices(addresses[index].otherServices));
    dispatch(addressActions.alterActiveAddress(index));
    dispatch(addressActions.toggleRegisterServices());
  }

  return (
    <div className="form-content">
      <StepByStep
        stepper
        steps={props.steps}
        activeStep={props.activeStep}
        handleBack={props.previousStep}
        handleAdvance={props.nextStep}
      />

      <div className="form-layout first-field-topic">
        <div className="side-by-side">
          <Topic
            classesNameCol="topic-datas"
            title="Endereços Cadastrados"
            subtitle="Deseja cadastrar outros endereços de atendimento?"
            classesNameSubtitle="text-black"
          />
          <AddressModal
            data-target="modal-address-registration"
            buttonMargin="30px 0 0 0"
          >
            Adicionar
          </AddressModal>
        </div>
        <div className="service-register">
          <div>
            <List 
              fullList={addresses} 
              parameterToExhibit="address"
              secondParam="number"
              thirdParam="complement"
              deleteItem={deleteAddress}
              menuItems={[
                // {name:"Editar Endereço", handleItem: () => props.handleItem1(index)},
                {name:"Editar Serviços", handleItem: updateAddressServices}
              ]}
            />
          </div>
        </div>
      </div>
      <StepByStep
        activeStep={props.activeStep}
        handleBack={props.previousStep}
        handleAdvance={props.nextStep}
        backgroundColor="#FFFFFF"
      />
    </div>
  );
};
