import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';

import Input from '../../../common/input'
import Button from '../../../common/button';
import Check from '../../../assets/check-circle-full.png';
import Calendar from '../../../assets/calendar.png';
import Clock from '../../../assets/clock.png'
import UserIcon from '../../../assets/User-Icon.png'
import DoctorIcon from '../../../assets/doctor-icon.svg'
import Loading from '../../../common/loading';
import SnackBar from '../../../common/snackbar/index';

import empty from '../../../common/validators/empty'
import cpfMask from '../../../common/mask/cpf'
import medicalAppointmentTokenMask from '../../../common/mask/medicalAppointmentToken'

import * as modalActions from '../../../common/store/actions/validateToken.action';
import * as asyncActions from '../../../common/store/actions/async.action';
import * as scheduleActions from '../../../common/store/actions/schedule.action';
import { handlePatientName } from "../../../common/handlePatientName";
// Material UI Styling


const useStyles = makeStyles( {
  root: {
    width: props => props.width ? props.width : '55%',
    maxWidth: '400px',
    "& .modal-body":{
      "& div.date-appointment":{
        marginBottom: '24px',
        "& span.day-appointment":{
          marginRight:"25px"
        },
        "& img":{
          marginRight:'8px'
        }
      },
      "& div.appointment-user":{
        display:'flex',
        marginBottom:'24px',
        "& div.appointment-user-information span":{
          display: 'block',
          padding: '2px 0 ',
          marginLeft: '13px'
        },
        "& div.image-rounded": {
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          border: '1px solid #E7E7ED',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
      },

    }
    
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    fontSize: '18px',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#6B6C7E',
    fontSize: '14px',
    marginTop: '16px'
  },
  responseToPatient: {
      fontSize: '18px',
  },
  success: {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
      fontWeight: 600,
  },
  box: {
      display: "inline-block",
      marginRight: "3%",
      borderRadius: "20px",
      padding: '2% 3%',
      marginBottom: '2%',
      backgroundColor: "#EEEEEE",
      color: "#424242",
      "& > a": {
          cursor: 'pointer',
      }
  }
})

// Component

const ValidateTokenModal = (props) => {
    const dispatch = useDispatch();
    const validateToken = useSelector((state) => state.ValidateToken);
    const success = validateToken.isSuccess;
    const loading = validateToken.loading;
    const { providersId } = props
    const [isSuccess, setSuccess] = useState(false);
    const [token, setToken] = useState('')
    const [tokenError, setTokenError] = useState({error: false, typeError:''})
    const open = validateToken.open;
    const classes = useStyles(props);

    const Id = props.consultation?.id;
    const handleClose = () => {
        dispatch(modalActions.isSuccess(false));
        dispatch(modalActions.openModal(false));   
      setToken("")
      };

    useEffect( () => {
        setSuccess(success);
    },[success])

    const handleValidateToken = async () => {

        return await dispatch(asyncActions.validateExamAppointmentToken(Id, token, providersId));
      
    }

    const handleSave = async () => {
      dispatch(modalActions.waitingResults(true))
      if (empty(token).error){
        setTokenError(empty(token))
        dispatch(modalActions.waitingResults(false))
      } else {                 

        const validateToken = await handleValidateToken()
        console.log('validade token',validateToken)


        if (validateToken) {
          dispatch(modalActions.isSuccess(true));
          dispatch(scheduleActions.updateScheduleFront(true));
        } else {
          dispatch(asyncActions.requestFailure("O token inserido está incorreto!"))
        }
        dispatch(modalActions.waitingResults(false))
      }
    }

    const whatButtonsSetToRender = () => {
        if(loading) return <Loading width="50px"/>
        if(isSuccess) {
            return (
                <Button 
                onClick={
                    () => {
                        dispatch(scheduleActions.updateScheduleFront(true));
                        handleClose()
                    }
                }
                width="120px"
                border="1px solid #CDCED9"
                backgroundColor="#FFFFFF"
                color="#6B6C7E"
                margin="0 20px 0 0"
                >
                    Ok
                </Button>
            )
        };
        return (
            <>
                <Button 
                    onClick={handleClose}
                    width="120px"
                    border="1px solid #CDCED9"
                    backgroundColor="#FFFFFF"
                    color="#6B6C7E"
                    margin="0 20px 0 0"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={handleSave}
                    width="120px"
                    border="1px solid #DF9000"
                    backgroundColor="#FFFFFF"
                    color="#DF9000"
                >
                    Salvar
                </Button>
            </>
        )
    }

    return (
            <div>
            <Dialog 
                open={open}
                onClose={handleClose} 
                aria-labelledby="form-dialog-title" 
                classes={{
                paper: classes.root
                }}
                TransitionComponent={Grow}
                transitionDuration={{enter: 200, exit: 0}}
            >
                <div className={`${classes.header} ${isSuccess ? classes.success : null}`}>
                <DialogTitle>
                    {isSuccess ?
                        <div><img src={Check} alt="success"/><span>Token Validado</span></div>
                        :
                        <span>Valide o token do paciente</span>
                    }
                </DialogTitle>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                </div>
                <DialogContent dividers>
                <form>
                    <div className="modal-body">
                      <div className="date-appointment">
                        <h3>Agendamento</h3>
                        <div>
                          <span className="day-appointment">
                            <img src={Calendar} alt="Calendário" />
                            <span>
                              { props.consultation ? 
                                format(new Date(`${props.consultation?.dateTimeBeginAppointmentUtc}`), 'dd/MM/yyyy') : 
                                ''
                              }
                            </span>
                          </span>
                          <span className="hour-appointment">
                            <img src={Clock} alt="Relógio" /> 
                            <span>
                              { props.consultation ? 
                                format(new Date(`${props.consultation?.dateTimeBeginAppointmentUtc}`), 'HH:mm') : 
                                ''
                              }
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="appointment-user">
                      <div className="image-rounded">
                          <img src={UserIcon} alt="Avatar do médico" width="42" height="42"/>
                        </div>
                       <div className="appointment-user-information">
                         <span>{handlePatientName(props.consultation?.patient)}</span>
                         <span>{props.consultation?.patient ? cpfMask(props.consultation?.patient?.user?.cpf) : ''}</span>
                       </div>
                      </div>
                      <div className="appointment-user">
                        <div className="image-rounded">
                          <img src={DoctorIcon} alt="Avatar do médico" />
                        </div>
                       <div className="appointment-user-information">
                         <span>{props.consultation?.provider?.doctor?.name}</span>
                         <span>CRM: {props.consultation?.provider?.doctor?.crm}</span>
                       </div>
                      </div>
                      <Input
                        width="100%"
                        label="Insira o token para liberação"
                        placeholder="Token"
                        backgroundColor={isSuccess ?  '#EDF9F0': '#FFF'}
                        inputTextColor={isSuccess ?  '#287D3C': null}
                        border= {isSuccess ? '1px solid #5ACA75': null}
                        type="text"
                        value={token}
                        onChange={(e) => setToken(medicalAppointmentTokenMask(e.target.value))}
                        onBlur={() => setTokenError(empty(token))}
                        error={tokenError.typeError}
                      />
                    </div>
                    <DialogActions className={classes.extraMargin}>
                        {whatButtonsSetToRender()}
                    </DialogActions>
                </form>
                </DialogContent>
            </Dialog>
            <SnackBar/>
        </div>
    );
};

export default ValidateTokenModal;