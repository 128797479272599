import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Button from "../../common/button";
import Loading from '../../common/loading';
import Pagination from '../../common/pagination';
import SnackBar from '../../common/snackbar';
import HistoricDetailsDialog from './../../common/historicDetailsDialog'

import cpfMask from '../../common/mask/cpf'

import { format } from 'date-fns'

import Alert from '@material-ui/lab/Alert';

import * as asyncActions from '../../common/store/actions/async.action'

const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: "#F7F8F9",
    width: '100%',
    minHeight: 'calc(100vh - 70px)',
    boxSizing: 'border-box',
    position: 'relative',
  },
  root: {
    width: '95%',
    padding: '3vh 0',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    "& h1": {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 600
    }
  },

  table: {
    marginBottom: '30px',
    "& tbody": {
      backgroundColor: '#FFF'
    }
  },

}));

export default props => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const historicList = useSelector((state) => state.VirtuaReducer.historic)

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const firstItem = (page*itemsPerPage)-(itemsPerPage);
  const lastItem = (firstItem + itemsPerPage);

  const [modalContent, setModalContent] = useState({
    medicalAppointment: {
      date: '2020-01-01',
      clinicalHistory: "",
      physicalExam: "",
      diagnosticHypothesis: "",
      treatment: "",
      speciality: {
        name: ""
      },
      patient: {
        socialName: "",
        user: {
          name: "",
          cpf: ""
        }
      },
    },
    status: "",

  })

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickDetails = (row) => {
    setModalContent(row.medicalAppointment)
    setOpen(true)
  }

  const getLocalDateTime = (utcTime, formatDate) => {
    const date = new Date(utcTime)
    return format(date, formatDate)
  }

  useEffect(() => {
    const getHistoricList = async () => {
      setLoading(true)
      const { count } = await dispatch(asyncActions.virtuaGetHistoric(itemsPerPage, page))
      setCount(count)
      setLoading(false)
    }
    getHistoricList()
    // eslint-disable-next-line
  }, [itemsPerPage, page]) 

  
  return (
    <div className={classes.screen}>
      <div className={classes.root}>
        {loading && <Loading width='80px'/>}
        {!loading && (
          <>
 
          <h1>Histórico</h1>
          <div className={classes.table}>
          <TableContainer>
                {!historicList.length && <Alert severity="info">Não encontramos nenhuma consulta que você tenha realizado</Alert>}
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Paciente</TableCell>
                      <TableCell>CPF</TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Horário</TableCell>
                      <TableCell>Opções</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historicList.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {row.medicalAppointment.patient.user.name}
                        </TableCell>
                        <TableCell>{cpfMask(row.medicalAppointment.patient.user.cpf)}</TableCell>
                        <TableCell>{getLocalDateTime(row.medicalAppointment.dateTimeBeginAppointmentUtc, 'dd/MM/yyyy')}</TableCell>
                        <TableCell>{getLocalDateTime(row.medicalAppointment.dateTimeBeginAppointmentUtc, 'HH:mm')}</TableCell>
                        <TableCell>
                          <Button
                            width="140px"
                            border="1px solid #CDCED9"
                            backgroundColor="#FFF"
                            color="#6B6C7E"
                            onClick={() => handleClickDetails(row)}
                          >
                            Detalhes
                          </Button>
                        </TableCell>
            
                      </TableRow>
                    ))}
                  </TableBody>
          
                <TableFooter>
                  <TableRow>
      
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>

          <HistoricDetailsDialog
            open={open}
            handleClose={handleClose}
            modalContent={modalContent}
          />
          <div className={classes.pagination}>
              <Pagination 
                  page={page}
                  setPage={setPage}
                  itemsPerPage={itemsPerPage}
                  setItemsPerPage={setItemsPerPage}
                  firstItem={firstItem}
                  lastItem={lastItem}
                  length={count}
                  width="95%"
              />
            </div>
          <SnackBar/>
          </>
        )}
      </div>

    </div>
  )
}
