import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import Loading from '../../../common/loading'
import * as providerAsyncActions from '../../../common/store/actions/provider.async.action'

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: 'calc(100vh - 70px)',
    },
    text: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
})


const FinalPage = (props) => {
    const classes = useStyles();
    const history = useHistory()

    const [ loading, setLoading ] = useState(false)

    const checkProviderStatus = async () => {
        setLoading(true)
        const status = await providerAsyncActions.checkProviderStatus()
        if(status === 'OK') {
            window.localStorage.clear()
            history.push('/')
        }
        setLoading(false)
    }

    useEffect(() => {
        checkProviderStatus()
        //eslint-disable-next-line
    }, [])

    return (
        <>
            { loading && <Loading/> }
            
            { !loading &&
                <div className={classes.root}>
                    <div className={classes.text}>
                        <span className="huge-text">O seu cadastro está aguardando validação.</span>
                        <span className="huge-text-subtitle">Enviaremos um email confirmando o seu cadastro</span>
                    </div>
                </div>
            }
        </>
    )
}

export default FinalPage;