import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ModalEditConsultation from './modals/editConsultation';
import ModalDeleteConsultation from './modals/deleteConsultation';
import AddressList from '../common/addressesPage';
import ConsultationsList from './list/listsWithPagination';

import * as consultationsActions from '../../../common/store/actions/registerConsultations';
import * as asyncActions from '../../../common/store/actions/async.action';
import * as unitActions from '../../../common/store/actions/units.action';

import SnackBar from '../../../common/snackbar/syncAlternative'


const Consultation =() => {
    const dispatch = useDispatch();
    const providerId = window.localStorage.getItem("Prov-Vida-Connect");
    const employeeId = window.localStorage.getItem("employeeId");
    const establishmentId = window.localStorage.getItem("establishmentId");
    const unitId = window.localStorage.getItem("Unit-Vida-Connect");

    const consultationList = useSelector((state) => state.RegisterConsultations.consultations);

    const [consultations, setConsultations] = useState([]);
    const [snack, setSnack] = useState({ open: false, message: '' });
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [selectedSpeciality, setSpeciality] = useState(0);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect( () => {
        setConsultations(consultationList);
        dispatch(asyncActions.getAppointmentFee());
        //eslint-disable-next-line
    },[consultationList])

    useEffect( () => {
        if(selectedUnit) {
            updateConsultationList({unitId, employeeId, establishmentId, providerId})
        }
        // eslint-disable-next-line
    },[selectedUnit, employeeId, unitId, providerId])

    const handleDeleteConsultation = async (index) => {
        if (Boolean(employeeId)) {
            setSnack({ open: true, message: 'Somente o estabelecimento pode deletar esta especialidade' })
            return
        }
        setSpeciality(index);
        setOpenDeleteModal(true);
        const unitHasSpecialitiesId = consultations[index].unitHasSpecialityId;
        window.localStorage.setItem("unitHasSpecialitiesId", unitHasSpecialitiesId);
        dispatch(consultationsActions.openDeleteModal(true));
    }

    const handleUpdateConsultation = async (index) => {
        if (Boolean(employeeId)) {
            setSnack({ open: true, message: 'Somente o estabelecimento pode atualizar esta especialidade' })
            return
        }
        setSpeciality(index);
        setOpenEditModal(true);
        const unitHasSpecialitiesId = consultations[index].unitHasSpecialityId;
        window.localStorage.setItem("unitHasSpecialitiesId", unitHasSpecialitiesId);
        dispatch(consultationsActions.openEditModal(true));
    };

    const updateConsultationList = async ({unitId, employeeId, establishmentId, providerId}) => {
        try {
            if(unitId && employeeId) {
                const input = {
                    unitId,
                    employeeId,
                    establishmentId,
                    providerId
                }
                dispatch(asyncActions.getEmployeeChoosenUnitConsultations(input));
                dispatch(unitActions.updateFront(true));
            } else if(unitId){
                const input = {
                    unitId,
                    providerId,
                    establishmentId,
                }
                dispatch(asyncActions.getChoosenUnitConsultations(input));
                dispatch(unitActions.updateFront(true));
            }
        } catch(error) {
            dispatch(asyncActions.requestFailure("Houve um problema durante a atualização do valor da consulta."));
        }
    };

    const select = (value) => {
        setSelectedUnit(value);
        if(value) {
            window.localStorage.setItem("Unit-Vida-Connect", value.id);
            window.localStorage.setItem("ad", value.address);
            window.localStorage.setItem("n", value.number);
            if(value.complement) window.localStorage.setItem("comp", value.complement);
        }
    }

    if(selectedUnit){
        return (
            <>
                <ConsultationsList
                    selectedUnit={selectedUnit}
                    setSelectedUnit={select}
                    deleteItem={handleDeleteConsultation}
                    list={consultations}
                    menuItem={[
                        {name:"Editar Consulta", handleItem: handleUpdateConsultation}
                    ]}
                    firstHeader="Especialidade"
                    firstParam="speciality"
                    secondParam="name"
                />
                {openEditModal && 
                    <ModalEditConsultation 
                        data={consultations[selectedSpeciality]}
                        index={selectedSpeciality}
                        setOpenEditModal={setOpenEditModal}
                    />
                }
                {openDeleteModal &&
                    <ModalDeleteConsultation 
                        data={consultations[selectedSpeciality]}
                        index={selectedSpeciality}
                        setOpenDeleteModal={setOpenDeleteModal}
                    />
                }
                {
                    Boolean(employeeId) &&
                        <SnackBar 
                            open={snack.open}
                            errorMessage={snack.message}
                            onClose={() => setSnack({ open: false, message: '' })}
                            root={{}}
                            severity="warning"
                        />
                }
            </>
        );
    } else {
        return (
            <AddressList
                title="Consultas"
                subtitle="Selecione uma unidade para ver as consultas cadastrados"
                onClick={select}
            />
        )
    }
}

export default Consultation;
