import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDispatch, useSelector } from 'react-redux'

import Loading from '../../../../common/loading'
import Button from '../../../../common/button'
import RequestDetails from './requestDetails'
import { ProposeDates } from './proposeDates'

import * as medicalAppointmentRequestsActions from '../../../../common/store/actions/medicalAppointmentRequests.action'
import * as examAppointmentRequestsActions from '../../../../common/store/actions/examAppointmentRequests.action'

const useStyles = makeStyles({
  root: {
    width: '40%',
    maxWidth: '500px',
    minWidth: '480px',
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    borderBottom: '1px solid #E7E7ED',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '10px'
    }
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  },
  lettering2: {
    color: "#393A4A",
  },
  buttonBg: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F7F8F9',
    display: 'flex',
    justifyContent: "flex-end",
    padding: '22px',
    marginTop: '28px',
    boxSizing: 'border-box',
  },
  success: {
    backgroundColor: "#EDF9F0",
    color: "#287D3C",
  }
})

export default (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let loading = useSelector((state) => state.AsyncReducer.loading)
  let error = useSelector((state) => state.AsyncReducer.error)
  //let changed = useSelector((state) => state.MedicalAppointmentRequestsReducer.lastMedicalAppointmentChanged)

  const [ proposeDate1, setProposeDate1 ] = useState(null)
  const [ proposeDate2, setProposeDate2 ] = useState(null)
  
  const [ submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [ submitButtonText, setSubmitButtonText] = useState('Próximo')
  const [ cancelButtonDisabled, setCancelButtonDisabled] = useState(false)
  const [ cancelButtonText, setCancelButtonText ] = useState('Cancelar')

  const [ choice, setChoice ] = useState()
  const [ screen, setScreen ] = useState(1)
  const [ success, setSuccess ] = useState(false) 

  const proposeDates = async () => {
    
    if(props.appointments.medicalAppointment){
    await dispatch(medicalAppointmentRequestsActions.proposeDates(props.appointments.medicalAppointment.id, proposeDate1, proposeDate2))
    }
    else {
    
      await dispatch(examAppointmentRequestsActions.proposeDates(props.appointments.id, proposeDate1, proposeDate2))
    }
        props.getList()
  }


  const denyRequest = async () => {
    
    if(props.appointments.medicalAppointment){
    await dispatch(medicalAppointmentRequestsActions.denyRequest(props.appointments.medicalAppointment.id))
    }
    else {
    
      await dispatch(examAppointmentRequestsActions.denyRequest(props.appointments.id))
    }
        props.getList()
  }
  const closeModal = () => {
    props.handleClose()
  }

  const changeChoice = (choice) => {
    setSubmitButtonDisabled(false)
    setChoice(choice)
    if(choice === 'Sim'){
      setSubmitButtonText('Próximo')
    } else if(choice === 'Não') {
      setSubmitButtonText('Enviar')
    }
  }

  const handleSubmit = async () => {
    if(submitButtonText === "OK") {
      closeModal()
    } else if (choice === 'Sim') {
      if(screen === 1){
        setScreen(2)
        setSubmitButtonText('Enviar')
      } else if(screen === 2) {
        proposeDates()
        
      }
    } else if (choice === 'Não') {
      
      await denyRequest()
  
    }
  }

  const handleCancel = () => {
    if(screen === 1) {
      closeModal()
    } else {
      setScreen(screen - 1)
    }
  }

  useEffect(() => {
    if(screen === 1) {
      setCancelButtonText('Cancelar')
      setSubmitButtonText('Próximo')
      setSubmitButtonDisabled(true)
    } else {
      setCancelButtonText('Voltar')
    }
  }, [screen])

  useEffect(() => {
    if(submitButtonText === 'Enviar' && !loading && !error){
      setSubmitButtonText('OK')
      setSubmitButtonDisabled(false)
      setCancelButtonDisabled(true)
      setSuccess(true)
    }
    //eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    setCancelButtonDisabled(false)
    setSubmitButtonText('Próximo')
    setSubmitButtonDisabled(true)
    setScreen(1)
    setSuccess(false)
    setProposeDate1(null)
    setProposeDate2(null)
    //eslint-disable-next-line
  }, [props.open])

  return(
    <Dialog
      open={props.open}
      onClose={() => closeModal()}
      classes={{paper: classes.root}}
    >
      <div className={`${classes.header} ${success ? classes.success : null}`}>
        <DialogTitle>
          <div className={classes.title}>
            { success && 
              <>
                <CheckCircleIcon/>
                <span>Enviado</span>
              </>
            }
            {!success &&
              'Solicitação de agendamento'
            }
          </div>
        </DialogTitle>
        <IconButton aria-label="close" onClick={() => closeModal()}>
            <CloseIcon />
        </IconButton>
      </div>

      <DialogContent>
        { loading &&
          <Loading/>
        }

        { screen === 1 && !loading && submitButtonText &&
          <RequestDetails
            disabled={submitButtonText === "OK"}
            appointment={props.appointments}
            choice={changeChoice}
          />
        }

        { screen === 2 && !loading && submitButtonText &&
          <ProposeDates
            appointment={props.appointments}
            proposeDate1={proposeDate1}
            setProposeDate1={setProposeDate1}
            proposeDate2={proposeDate2}
            setProposeDate2={setProposeDate2}
          />
        }
      </DialogContent>
      
      <div className={classes.buttonBg}>
        { submitButtonText !== "OK" &&
          <Button 
            onClick={() => handleCancel()}
            width="120px"
            border="1px solid #CDCED9"
            backgroundColor="#FFFFFF"
            color="#6B6C7E"
            margin="0 20px 0 0"
            disabled={cancelButtonDisabled}
          >
            { cancelButtonText }
          </Button>
        }
        
        <Button 
          onClick={() => handleSubmit()}
          width="120px"
          border="1px solid #DF9000"
          backgroundColor="#FFFFFF"
          color="#DF9000"
          disabled={submitButtonDisabled}
        >
          { submitButtonText }
        </Button>
      </div>

    </Dialog>
  )
}