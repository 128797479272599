import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, List, ListItem, Avatar, Chip } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import ReplayIcon from '@material-ui/icons/Replay'
import { differenceInMinutes, format, parseISO } from 'date-fns'

import * as consultationService from '../../../services/consultations'
import * as triageActions from '../../../common/store/actions/triage.action'
import * as medicalAppointmentsAction from '../../../common/store/actions/medicalAppointments'
import * as consultationActions from '../../../common/store/actions/consultation.action'
import * as validateTokenActions from '../../../common/store/actions/validateToken.action'

import TriageModal from '../modals/triageModal'
import StartConsultationModal from '../modals/startConsultationModal'
import ValidateTokenModal from '../modals/validateToken'
import * as scheduleActions from '../../../common/store/actions/schedule.action';
import { handlePatientName } from '../../../common/handlePatientName';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0',
    fontWeight: '400',
    paddingBottom: '60px',
  },
  headers: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingLeft: "2.5%",
    color: "#6B6C7E",
    boxSizing: 'border-box',
  },
  item: {
    height: '64px',
    backgroundColor: "#FFFFFF",
    border: '1px solid #E7E7ED',
    padding: '0',
  },
  combo: {
    display: 'flex',
    width: '100%',
    paddingLeft: '2.5%',
    height: '100%',
    boxSizing: 'border-box',
  },
  line: {
    height: '40px',
    width: "100%",
    margin: "auto",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },
  date: {
    width: '11%',
  },
  hour: {
    width: '10%',
  },
  type: {
    width: '13%',
    marginLeft: '30px',
  },
  patient: {
    width: '25%',
    marginLeft: '30px',
    display: 'flex',
  },
  doctor: {
    width: '25%',
    marginLeft: '30px',
    display: 'flex',
  },
  appointmentType: {
    width: "10%"
  },
  text: {
    marginLeft: '3%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& > .cpf": {
      color: "#6B6C7E"
    }
  },
  status: {
    width: '20%',
    marginLeft: '30px',
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',
    ".makeStyles-headers-33 > &": {
      justifyContent: "flex-start",
    },
    "& .box": {
      width: '90%',
      // height: '25px',
      // fontSize: '10px',
    },
    "& .ONLINE_PENDING_TRIAGE": {
      backgroundColor: "#FFF8DE",
      color: "#DF9000",
    },
    "& .ONLINE_PENDING_HEALTH_OPERATOR": {
      backgroundColor: "#FFF8DE",
      color: "#DF9000",
    },
    "& .ONLINE_OK": {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
    },
    "& .ONLINE_OK_OUT_OF_HOURS": {
      backgroundColor: "#F7F8F9",
      color: "#6B6C7E",
    },
    "& .ONLINE_PENDING_TOKEN_VALIDATION": {
      backgroundColor: "#F7F8F9",
      color: "#6B6C7E",
    },
    "& .ONLINE_VALIDATED_TOKEN": {
      backgroundColor: "#EEF2FA",
      color: "#2E5AAC",
    },
    "& .ONLINE_REJECTED_TRIAGE": {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .ONLINE_REJECTED_HEALTH_OPERATOR": {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .ONLINE_START_CONSULTATION": {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
    },
    "& .ONLINE_PENDING_PAYMENT": {
      backgroundColor: "#FFF4EC",
      color: "#B95000",
    },
    "& .PRESENTIAL_PENDING_PAYMENT": {
      backgroundColor: "#FFF4EC",
      color: "#B95000",
    },
    "& .PRESENTIAL_PENDING_TRIAGE": {
      backgroundColor: "#FFF8DE",
      color: "#DF9000",
    },
    "& .PRESENTIAL_PENDING_HEALTH_OPERATOR": {
      backgroundColor: "#FFF8DE",
      color: "#DF9000",
    },
    "& .PRESENTIAL_OK": {
      backgroundColor: "#EEF2FA",
      color: "#2E5AAC",
    },
    "& .PRESENTIAL_PENDING_TOKEN_VALIDATION": {
      backgroundColor: "#F7F8F9",
      color: "#6B6C7E",
    },
    "& .PRESENTIAL_VALIDATED_TOKEN": {
      backgroundColor: "#EEF2FA",
      color: "#2E5AAC",
    },
    "& .PRESENTIAL_REJECTED_TRIAGE": {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .PRESENTIAL_REJECTED_HEALTH_OPERATOR": {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .PRESENTIAL_START_CONSULTATION": {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
    },
  },
}))

const RenderChip = ({ status, appointmentLocation, datetime }) => {
  const diffInMinutes = differenceInMinutes(new Date(datetime), new Date())

  const statusConversion = {
    OK: handleOKstatus({appointmentLocation, diffInMinutes}),
    PENDING_TRIAGE: "Triagem",
    PENDING_HEALTH_OPERATOR: "Aguardando operadora de saúde",
    REJECTED_TRIAGE: "Triagem recusada",
    REJECTED_HEALTH_OPERATOR: "Triagem recusada",
    CANCELED: "Consulta cancelada",
    PENDING_TOKEN_VALIDATION: "Aguardando paciente",
    PENDING_PAYMENT: "Aguardando pagamento",
    START_CONSULTATION: "Iniciar atendimento",
    VALIDATED_TOKEN: "Token validado"
  }

  const label = statusConversion[status]
  let _class = `${appointmentLocation}_${status}`

  if (label === 'Aguardando consulta' && appointmentLocation === "ONLINE" && diffInMinutes > 15) _class += '_OUT_OF_HOURS'

  return (
    <Chip
      size='small'
      label={statusConversion[status]}
      className={`${_class} box`}
    />
  )
}

const handleOKstatus = ({appointmentLocation, diffInMinutes}) => {
  if (appointmentLocation === "ONLINE")
   return diffInMinutes <= 15 ? "Iniciar atendimento" : "Aguardando consulta"
  else 
   return 'Token validado'
}

export const Consultations = ({ page, limit, date, setLoading, setCount }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const userType = localStorage.getItem('userType')
  const doctor = JSON.parse(window.localStorage.getItem('docData'));
  const providerId = window.localStorage.getItem('Prov-Vida-Connect')
  const employeeId = window.localStorage.getItem('employeeId')

  const [listConsultations, setListConsultations] = useState([])
  const [selectedConsultation, setSelectedConsultation] = useState()
  const updateScheduleFront = useSelector((state) => state.ScheduleReducer.updateScheduleFront)
  const doctorsList = useSelector(state => state.SecretariesReducer.doctors)

  const filterConsultations = (item) => {
    if (userType === 'PROVIDER_SECRETARY' && item.employeeId){
      return doctorsList.some(doctor => !!doctor.employeeId &&  doctor.employeeId === item.employeeId)
   }
   else if(userType === 'PROVIDER_SECRETARY' && !item.employeeId)
      return doctorsList.some(doctor => !doctor.employeeId && doctor.provider.id === item.providerId)
   if (employeeId && userType !== 'PROVIDER_SECRETARY') return item.employeeId === employeeId
   return item.providerId === providerId && item.employeeId === null
  }
  const loadConsultations = async () => {
    const consultations = await consultationService.getMedicalAppointmentsDaily(
      userType === 'PROVIDER_SECRETARY' ? doctorsList.map(doctor => doctor.provider.id) : null,
      null,
      userType,
      page,
      limit,
      null,
      date
    )

    if (!consultations.error) {
      setListConsultations(consultations.patientsList.filter(item => {
       return filterConsultations(item)
      }))
      setCount(consultations.totalPatientsOnDay)
    }

    else {
      console.log(consultations.error)
    }
  }

  const selectPatient = (consultation) => {
    const { id } = consultation
    setSelectedConsultation(consultation)
    switch (consultation.status) {
      case "PENDING_TRIAGE":
        if (userType === 'PROVIDER_DOCTOR' || userType === 'PROVIDER_OTHER_PROFESSIONALS') {
          dispatch(medicalAppointmentsAction.storeMedicalAppointmentsData(consultation));
          dispatch(triageActions.openModal(true));
        }
        break;
      case "PENDING_HEALTH_OPERATOR":
        if (
            consultation.appointmentLocation === 'ONLINE' && 
            (userType === 'PROVIDER_DOCTOR' || userType === 'PROVIDER_OTHER_PROFESSIONALS')
          ) {
          dispatch(medicalAppointmentsAction.storeMedicalAppointmentsData(consultation));
          dispatch(triageActions.openModal(true));
        }
        break;
      case "START_CONSULTATION":
      case "OK":
        if (userType === 'PROVIDER_DOCTOR' || userType === 'PROVIDER_OTHER_PROFESSIONALS') {
          if (consultation.appointmentLocation === 'ONLINE' && differenceInMinutes(new Date(consultation.dateTimeBeginAppointmentUtc), new Date()) > 15) {
            break;
          }
          window.localStorage.setItem('appointId', id);
          window.localStorage.setItem('patientId', consultation.patient.id);
          window.localStorage.setItem('appointmentLocation', consultation.appointmentLocation);
          dispatch(consultationActions.storeData({
            ...consultation,
            patient: {
              ...consultation.patient,
              ...consultation.patient.user
            }
          }));
          dispatch(medicalAppointmentsAction.storeMedicalAppointmentsData(consultation));
          dispatch(consultationActions.openStartModal(true));
        }
        break;
      case "PENDING_TOKEN_VALIDATION":
        dispatch(validateTokenActions.storeValidateTokenData(consultation))
        dispatch(validateTokenActions.openModal(true))
        break

      default:
        break;
    }
  };

  const loadList = (_doctorsList, _userType, _loadConsultations) => {
    if (_userType === 'PROVIDER_SECRETARY' && _doctorsList.length === 0) {
      return
    }
    _loadConsultations()
  }

  const renderType = (appointment) => {
    console.log(appointment)
    if(appointment.appointmentType === 'HEALTH_OPERATOR') {
      if(appointment.healthOperatorRequest){
        return appointment.healthOperatorRequest?.healthOperator?.fantasyName
      } else {
        return 'Retorno Convênio'
      }
    } else {
      if(!appointment.price) {
        return appointment.medicalAppointmentReturnGenerated ? 'Particular' : 'Virtua'
      } else {
        return 'Particular'
      }
    }
  }

  useEffect(() => {
    loadList(doctorsList, userType, loadConsultations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, date, doctorsList, userType])

  useEffect(() => {
    dispatch(scheduleActions.updateScheduleFront(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (updateScheduleFront) {
      loadConsultations()
      dispatch(scheduleActions.updateScheduleFront(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateScheduleFront])

  return (
    <>

      <Alert severity="info" style={{ marginBottom: "30px" }}>O ícone
        <ReplayIcon style={{ fontSize: '1.1rem', color: '#DF9000', margin: '0px 5px' }} />
        representa uma Consulta de Retorno!
      </Alert>

      <List className={classes.root}>
        <div className={classes.headers}>
          <div className={classes.date}>
            Data
          </div>
          <div className={classes.hour}>
            Hora
          </div>
          <div className={classes.type}>
            Atendimento
          </div>
          <div className={classes.patient}>
            Paciente
          </div>
          <div className={classes.doctor}>
            Profissional
          </div>
          <div className={classes.appointmentType}>
            Tipo
          </div>
          <div className={classes.status}>
            Status
          </div>
        </div>

        {listConsultations.map((consultation, index) => {
          const date = format(parseISO(consultation.dateTimeBeginAppointmentUtc), "dd/MM/yyyy");
          const hour = format(parseISO(consultation.dateTimeBeginAppointmentUtc), 'HH:mm');
          const doctorName = (userType === 'PROVIDER_DOCTOR') ? doctor.name : consultation?.provider?.user?.name

          return (
            <ListItem
              className={classes.item}
              key={consultation.id}
              onClick={() => selectPatient(consultation)}
              dense
              button
            >
              <div className={classes.combo}>
                <div className={classes.line}>
                  <div className={classes.date}>
                    {date}
                  </div>

                  <div className={classes.hour}>
                    {hour}
                  </div>

                  <div className={classes.type}>
                    {(consultation.appointmentLocation === 'PRESENTIAL') ? 'PRESENCIAL' : consultation.appointmentLocation}
                  </div>

                  <div className={classes.patient}>
                    <Avatar />
                    <div className={classes.text}>
                      <span>{handlePatientName(consultation.patient)} </span>
                      <span className="cpf">{consultation.patient.user.email}</span>
                    </div>
                    {consultation.medicalAppointmentReturnGenerated && (
                      <ReplayIcon style={{ margin: "8px 0 0 10px", color: '#DF9000' }} />
                    )}
                  </div>

                  <div className={classes.doctor}>
                    <Avatar />
                    <div className={classes.text}>
                      <span>{doctorName}</span>
                      <span>{consultation.provider.doctor && consultation.provider.doctor.crm}-
                      {consultation.provider.doctor && consultation.provider.doctor.uf}</span>
                    </div>
                  </div>

                  <div className={classes.appointmentType}>
                    {renderType(consultation)}
                  </div>

                  <div className={classes.status}>
                    <div className="box" >
                      <RenderChip
                        status={consultation.status}
                        appointmentLocation={consultation.appointmentLocation}
                        datetime={consultation.dateTimeBeginAppointmentUtc}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ListItem>
          )
        })
        }
      </List>

      <TriageModal
        consultation={selectedConsultation}
      />
      <StartConsultationModal
        consultation={selectedConsultation}

      />
      <ValidateTokenModal
        consultation={selectedConsultation}
        reload={loadConsultations}
      />

    </>
  )
}
