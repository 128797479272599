import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import empty from "../../../common/validators/empty";
import inputFileValidator from '../../../common/validators/fileInput'
import cpfValidator from "../../../common/validators/cpf";
import telphoneValidator from "../../../common/validators/telephone";
import emailValidator from "../../../common/validators/email";
import StepByStep from '../../../common/stepByStep/stepByStep';
import Loading from '../../../common/loading';
import SnackBar from '../../../common/snackbar';
import SyncSnackBar from '../../../common/snackbar/syncAlternative'

import * as personalDatasActions from "../../../common/store/actions/personalDatas";
import * as consultationsActions from '../../../common/store/actions/registerConsultations';
import * as asyncActions from '../../../common/store/actions/async.action';

import PersonalDatas from "./form/personalData";
import ProfessionDoctor from "./form/professionDoctor";
import ProfessionOthers from "./form/professionOthers"
import SupportType from './form/supportType';
import Attachments from "./form/attachments";
import BankData from "./form/bankData";
// import InstallmentOptions from "./form/installmentOptions";
import HealthInsurance from "./form/healthInsurance";
import ConsentForm from './form/agreementTerms';

const FullPagePersonalDatas = (props) => {

  const userId = window.localStorage.getItem("User-Vida-Connect");
  const personalDatas = useSelector(
    (state) => state.PersonalDatas.finalRegister[0].pf.personalDatas
  );
  const dispatch = useDispatch();
  const waitingData = useSelector((state) => state.PersonalDatas.waitingData);
  const error = useSelector((state) => state.PersonalDatas.error);
  const [openSyncSnackBar, setOpenSyncSnackBar] = useState(false)
  const [errorMessageSyncSnackBar, setErrorMessageSyncSnackBar] = useState('')
  const [cpf, setCpf] = useState(personalDatas.cpf);
  const [cpfError, setCpfError] = useState({ error: true, typeError: "" });

  const [name, setName] = useState(personalDatas.name);
  const [nameError, setNameError] = useState({ error: true, typeError: "" });

  const [email, setEmail] = useState(personalDatas.email);
  const [emailError, setEmailError] = useState({ error: true, typeError: "" });

  const [telephone, setTelephone] = useState(personalDatas.telephone);
  const [telephoneError, setTelephoneError] = useState({
    error: true,
    typeError: "",
  });

  const [doctor, setDoctor] = useState(personalDatas.name);
  const [doctorError, setDoctorError] = useState({
    error: true,
    typeError: "",
  });

  const [crm, setCrm] = useState(personalDatas.crm);
  const [crmError, setCrmError] = useState({ error: true, typeError: "" });

  const [uf, setUf] = useState(personalDatas.uf);
  const [ufError, setUfError] = useState({ error: true, typeError: "" });

  const [specialty1, setSpecialty1] = useState(personalDatas.specialty1);
  const [specialtyError, setSpecialtyError] = useState({
    error: true,
    typeError: "",
  });

  const [rqe1, setRqe1] = useState(personalDatas.rqe1);
  const [rqeError, setRqeError] = useState({ error: true, typeError: "" });

  const [specialty1Titles, setSpecialty1Titles] = useState(
    personalDatas.specialty1Titles
  );
  const [specialty1TitlesError, setSpecialty1TitlesError] = useState({
    error: true,
    typeError: "",
  });

  const [specialty2, setSpecialty2] = useState(personalDatas.specialty2);
  const [specialty2Error, setSpecialty2Error] = useState({
    error: true,
    typeError: "",
  });

  const [rqe2, setRqe2] = useState(personalDatas.rqe2);
  const [rqe2Error, setRqe2Error] = useState({ error: true, typeError: "" });

  const [specialty2Titles, setSpecialty2Titles] = useState(
    personalDatas.specialty2Titles
  );
  const [specialty2TitlesError, setSpecialty2TitlesError] = useState({
    error: true,
    typeError: "",
  });

  const [ profession, setProfession ] = useState(personalDatas.profession)
  const [ professionError, setProfessionError ] = useState({
    error: true,
    typeError: ""
  })
  const [ specialityId, setSpecialityId ] = useState(personalDatas.specialityId)
  const [ specialityName, setSpecialityName ] = useState(personalDatas.specialityName)
  
  const [ professionalCouncil, setProfessionalCouncil ] = useState(personalDatas.professionalCouncil)
  const [ professionalCouncilError, setProfessionalCouncilError ] = useState({
    error: true,
    typeError: ""
  })
  const [ professionalDocumentNumber, setProfessionalDocumentNumber ] = useState(personalDatas.professionalDocumentNumber)
  const [ professionalDocumentNumberError, setProfessionalDocumentNumberError ] = useState({
    error: true,
    typeError: ""
  })

  const [generalPractitioner, setGeneralPractitioner] = useState(personalDatas.generalPractitioner)

  const [supportType, setSupportType] = useState(personalDatas.supportType);
  const [supportTypeError, setSupportTypeError] = useState({
    error: true,
    typeError: "",
  })

  const [professionalDocument, setProfessionalDocument] = useState(
    personalDatas.professionalDocument
  );
  const [professionalDocumentError, setProfessionalDocumentError] = useState({
    error: true,
    typeError: "",
  });

  const [proofOfResidence, setProofOfResidence] = useState(
    personalDatas.proofOfResidence
  );
  const [proofOfResidenceError, setProofOfResidenceError] = useState({
    error: true,
    typeError: "",
  });

  const [ certificate, setCertificate ] = useState(
    personalDatas.certificate
  )
  const [ certificateError, setCertificateError ] = useState({
    error: true,
    typeError: "",
  })

  const [bank, setBank] = useState(personalDatas.bank);
  const [bankError, setBankError] = useState({ error: true, typeError: "" });

  const [agency, setAgency] = useState(personalDatas.agency);
  const [agencyError, setAgencyError] = useState({
    error: true,
    typeError: "",
  });

  const [account, setAccount] = useState(personalDatas.account);
  const [accountError, setAccountError] = useState({
    error: true,
    typeError: "",
  });

  const [numberPlots] = useState(personalDatas.numberPlots);
  const [minAmount] = useState(personalDatas.minAmount);

  const [healthInsurances, setHealthInsurances] = useState(
    personalDatas.healthInsurances
  );
  // const [healthInsurancesError, setHealthInsurancesError] = useState(
  //   {
  //     error: true,
  //     typeError:""
  //   }
  // )

  const [consentForm, setConsentForm] = useState(
    personalDatas.consentForm
  );
  const [consentFormError, setConsentFormError] = useState({
    error: true,
    typeError: "",
  });


  useEffect(() => {
    if(!cpf){
      dispatch(asyncActions.checkPreRegister(userId));
    } else {
      setCpfError({ ...cpfError, error: cpfValidator(cpf).error });
      setNameError({ ...nameError, error: empty(name).error });
      setEmailError({ ...emailError, error: emailValidator(email).error });
      setTelephoneError({
        ...telephoneError,
        error: telphoneValidator(telephone).error,
      });
      
      if(props.professionalType === 'doctor') {
        setDoctorError({ ...doctorError, error: empty(doctor).error });
        setCrmError({ ...crmError, error: empty(crm).error });
        setRqeError({ ...rqeError, error: empty(rqe1).error });
        setSpecialty1TitlesError({
          ...specialty1TitlesError,
          error: (empty(specialty1Titles.name).error) });
        setSpecialty2Error({ ...specialty2Error, error: empty(specialty2.name).error });
        if(specialty2.name){
          setRqe2Error({ ...rqe2Error, error: empty(rqe2).error });
          setSpecialty2TitlesError({
            ...specialty2TitlesError,
            error: (empty(specialty2Titles.name).error)
          });
        }
      }

      if(props.professionalType === 'others') {
        setProfessionError({
          ...professionError, 
          error: empty(profession).error, 
        })
        setProfessionalCouncilError({ 
          ...professionalCouncilError, 
          error: empty(professionalCouncil).error, 
        })
      }

      setSupportTypeError({
        ...supportTypeError,
        error: empty(supportType).error,
      })
      setProfessionalDocumentError({
        ...professionalDocumentError,
        error: empty(professionalDocument.name).error,
      });
      setProofOfResidenceError({
        ...proofOfResidenceError,
        error: empty(proofOfResidence.name).error,
      });
      setBankError({ ...bankError, error: empty(bank.name).error });
      setAgencyError({ ...agencyError, error: empty(agency).error });
      setAccountError({ ...accountError, error: empty(account).error });
      setConsentFormError({ ...consentFormError, error: empty(consentForm.name).error })
      setSpecialtyError({ ...specialtyError, error:empty(specialty1.name).error })
    };
    // eslint-disable-next-line
  }, []);

  useEffect( () => {
    if(!waitingData && !error) {
      setCpf(personalDatas.cpf);
      setCpfError({error:false, typeError: ""});
      setName(personalDatas.name);
      setNameError({error:false, typeError: ""});
      setEmail(personalDatas.email);
      setEmailError({error:false, typeError: ""});
      setTelephone(personalDatas.telephone);
      setTelephoneError({error:false, typeError: ""});
      setDoctor(personalDatas.name);
      setDoctorError({error:false, typeError: ""});
    }
    // eslint-disable-next-line
  },[waitingData])

  const deleteHealthInsurance = useCallback((index) => {
    const filtered = healthInsurances.filter( (element, i) => {
      return i !== index;
    })
    setHealthInsurances(filtered);
  },[healthInsurances]);

  // PO asked to exclude the plan field of the healthOperators part on the form,
  // Just keeping this in case field return to be compulsory
  // const healthInsurancesCheck =(healthInsurances) => {
  //   const ok = healthInsurances.reduce( (acc, currV) => {
  //     const hasOperatorAndPlan = Boolean(currV.healthOperators.id) && Boolean(currV.plan.name);
  //     const doesntHaveOperator = currV.healthOperators.name === "";
  //     return acc && (doesntHaveOperator || hasOperatorAndPlan)
  //   }, true)
  //   if(!ok){
  //     setHealthInsurancesError({error:true, typeError: "Adicione um plano ao Operador que foi escolhido."})
  //   } else {
  //     setHealthInsurancesError({error:false, typeError: ""})
  //   }
  //   return ok;
  // };

  function handleAdvance() {
    setCpfError(cpfValidator(cpf));
    setNameError(empty(name));
    setEmailError(emailValidator(email));
    setTelephoneError(telphoneValidator(telephone));
    
    if(props.professionalType === 'doctor') {
      setDoctorError(empty(doctor));
      setCrmError(empty(crm));
      setUfError(empty(uf.name));
      setSpecialtyError(empty(specialty1.name));
      specialty1.requireRqe && setRqeError(empty(rqe1));
      specialty1.requireRqe && setSpecialty1TitlesError((inputFileValidator(specialty1Titles, 25)));
    }

    if(props.professionalType === 'others') {
      setProfessionError(empty(profession))
      setProfessionalCouncilError(empty(professionalCouncil))
      setCertificateError(inputFileValidator(certificate, 25))
      setProfessionalDocumentNumberError(empty(professionalDocumentNumber))
    }

    setSupportTypeError(empty(supportType));
    setProfessionalDocumentError(inputFileValidator(professionalDocument, 25));
    setProofOfResidenceError(inputFileValidator(proofOfResidence, 25));
    setBankError(empty(bank.name));
    setAgencyError(empty(agency));
    setAccountError(empty(account));
    setConsentFormError(inputFileValidator(consentForm, 25));
    let optionalError = false;
    if(specialty2.name){
      specialty2.requireRqe && setRqe2Error(empty(rqe2));
      specialty2.requireRqe && setSpecialty2TitlesError(inputFileValidator(specialty2Titles, 25))

      optionalError = (specialty2.requireRqe && empty(rqe2).error) || 
                        (specialty2.requireRqe && inputFileValidator(specialty2Titles, 25).error);
    }
    // const healthInsuranceError = !healthInsurancesCheck(healthInsurances);

    if (
      cpfValidator(cpf).error ||
      empty(name).error ||
      emailValidator(email).error ||
      telphoneValidator(telephone).error ||
      (props.professionalType === 'doctor' && (
        empty(doctor).error ||
        empty(crm).error ||
        empty(uf.name).error ||
        empty(specialty1.name).error ||
        (specialty1.requireRqe && empty(rqe1).error) ||
        (specialty1.requireRqe && inputFileValidator(specialty1Titles, 25).error)
      )) ||
      (props.professionalType === 'others' && (
        empty(profession).error || 
        empty(professionalCouncil).error ||
        empty(professionalDocumentNumber).error ||
        inputFileValidator(certificate, 25).error
      )) ||
      empty(supportType).error ||
      inputFileValidator(professionalDocument, 25).error ||
      inputFileValidator(proofOfResidence,25).error ||
      empty(bank.name).error ||
      empty(agency).error ||
      empty(account).error ||
      // // healthInsuranceError ||
      ( supportType !== 'PRESENTIAL' && inputFileValidator(consentForm, 25).error ) ||
      (isNaN(minAmount) || !Boolean(minAmount) || minAmount < 0.01) ||
      optionalError
    ) {
      setOpenSyncSnackBar(true)
      setErrorMessageSyncSnackBar('Houve algum problema no preenchimento do formulário, por favor verifique os campos!')
      return "Have some errors yet.";
    } else {
      const payload = {
        cpf,
        name,
        email,
        telephone,
        doctor,
        crm,
        uf,
        specialty1,
        rqe1,
        specialty1Titles,
        specialty2,
        rqe2,
        specialty2Titles,
        profession,
        professionalCouncil,
        specialityId,
        specialityName,
        professionalDocumentNumber,
        supportType,
        professionalDocument,
        proofOfResidence,
        certificate,
        bank,
        agency,
        account,
        numberPlots,
        minAmount,
        healthInsurances,
        consentForm,
        generalPractitioner
      };
      dispatch(personalDatasActions.personalDatasPf(payload));
      const availableSpecialities = [specialty1, specialty2]
      generalPractitioner.selected && availableSpecialities.push(generalPractitioner)

      // Limpa as especialidades repetidas do array
      const filteredArray = availableSpecialities.filter(
        function (speciality) {
          return !this[JSON.stringify({ id: speciality.id })] && (this[JSON.stringify({ id: speciality.id })] = true);
        },
        Object.create(null)
      )

      dispatch(consultationsActions.availableSpecialities(filteredArray));
      props.nextStep();
      return "Good to go."
    }
  }

  if(waitingData) {
    return (
      <div className="row">
        <StepByStep
          stepper
          activeStep={props.activeStep}  
          handleBack={props.previousStep}
          handleAdvance={handleAdvance}/>
        <SnackBar />
        <div className="form-content">
          <Loading width="70px"/>
        </div>
      </div>
    )
  }

  return (
    <div className="row">
      <StepByStep 
        stepper
        steps={props.steps}
        activeStep={props.activeStep}
        handleBack={props.previousStep}
        handleAdvance={handleAdvance}
      />
      <SyncSnackBar 
        open={openSyncSnackBar} 
        onClose={setOpenSyncSnackBar} 
        errorMessage={errorMessageSyncSnackBar} 
      />
      <SnackBar />
      <div className="form-content">
        <form className="form-layout">
          <div>
            <PersonalDatas
              cpf={cpf}
              setCpf={setCpf}
              cpfError={cpfError}
              setCpfError={setCpfError}
              name={name}
              setName={setName}
              nameError={nameError}
              setNameError={setNameError}
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              setEmailError={setEmailError}
              telephone={telephone}
              setTelephone={setTelephone}
              telephoneError={telephoneError}
              setTelephoneError={setTelephoneError}
            />
          </div>
          <div>
            { props.professionalType === 'doctor' &&
              <ProfessionDoctor
                setOpenSyncSnackBar={setOpenSyncSnackBar}
                setErrorMessageSyncSnackBar={setErrorMessageSyncSnackBar}
                profession={doctor}
                setProfession={setDoctor}
                professionError={doctorError}
                setProfessionError={setDoctorError}
                crm={crm}
                setCrm={setCrm}
                crmError={crmError}
                setCrmError={setCrmError}
                uf={uf.name}
                setUf={setUf}
                ufError={ufError}
                setUfError={setUfError}
                specialty1={specialty1}
                setSpecialty1={setSpecialty1}
                specialtyError={specialtyError}
                setSpecialtyError={setSpecialtyError}
                rqe1={rqe1}
                setRqe1={setRqe1}
                rqeError={rqeError}
                setRqeError={setRqeError}
                specialty2={specialty2}
                setSpecialty2={setSpecialty2}
                specialty2Error={specialty2Error}
                setSpecialty2Error={setSpecialty2Error}
                rqe2={rqe2}
                setRqe2={setRqe2}
                rqe2Error={rqe2Error}
                setRqe2Error={setRqe2Error}
                specialty1Titles={specialty1Titles}
                setSpecialty1Titles={setSpecialty1Titles}
                specialty1TitlesError={specialty1TitlesError}
                setSpecialty1TitlesError={setSpecialty1TitlesError}
                specialty2Titles={specialty2Titles}
                setSpecialty2Titles={setSpecialty2Titles}
                specialty2TitlesError={specialty2TitlesError}
                setSpecialty2TitlesError={setSpecialty2TitlesError}
                generalPractitioner={generalPractitioner}
                setGeneralPractitioner={setGeneralPractitioner}
              />
            }
            { props.professionalType === 'others' && 
              <ProfessionOthers 
                profession={profession}
                setProfession={setProfession}
                specialityId={specialityId}
                setSpecialityId={setSpecialityId}
                specialityName={specialityName}
                setSpecialityName={setSpecialityName}
                professionError={professionError}
                setProfessionError={setProfessionError}
                professionalCouncil={professionalCouncil}
                setProfessionalCouncil={setProfessionalCouncil}
                professionalCouncilError={professionalCouncilError}
                setProfessionalCouncilError={setProfessionalCouncilError}
                professionalDocumentNumber={professionalDocumentNumber}
                setProfessionalDocumentNumber={setProfessionalDocumentNumber}
                professionalDocumentNumberError={professionalDocumentNumberError}
                setProfessionalDocumentNumberError={setProfessionalDocumentNumberError}
                uf={uf.name}
                setUf={setUf}
                ufError={ufError}
                setUfError={setUfError}
              />
            }
          </div>
          <div>
            <SupportType 
              setSupportType={setSupportType}
              supportType={supportType}
              supportTypeError={supportTypeError}
              setSupportTypeError={setSupportTypeError}
            />
          </div>
          <div>
            <Attachments
              professionalType={props.professionalType}
              setOpenSyncSnackBar={setOpenSyncSnackBar}
              setErrorMessageSyncSnackBar={setErrorMessageSyncSnackBar}
              professionalDocument={professionalDocument}
              setProfessionalDocument={setProfessionalDocument}
              professionalDocumentError={professionalDocumentError}
              setProfessionalDocumentError={setProfessionalDocumentError}
              proofOfResidence={proofOfResidence}
              setProofOfResidence={setProofOfResidence}
              proofOfResidenceError={proofOfResidenceError}
              setProofOfResidenceError={setProofOfResidenceError}
              certificate={certificate}
              setCertificate={setCertificate}
              certificateError={certificateError}
              setCertificateError={setCertificateError}
            />
          </div>
          <div>
            <BankData
              bank={bank.name}
              setBank={setBank}
              bankError={bankError}
              setBankError={setBankError}
              agency={agency}
              setAgency={setAgency}
              agencyError={agencyError}
              setAgencyError={setAgencyError}
              account={account}
              setAccount={setAccount}
              accountError={accountError}
              setAccountError={setAccountError}
            />
          </div>
          {/* <div>
            <InstallmentOptions
              numberPlots={numberPlots}
              setNumberPlots={setNumberPlots}
              value={minAmount}
              setMinAmount={setMinAmount}
            />
          </div> */}
          <div>
            <HealthInsurance
              healthInsurances={healthInsurances}
              setHealthInsurances={setHealthInsurances}
              deleteItem={deleteHealthInsurance}
              // healthInsurancesCheck={healthInsurancesCheck}
              // error={healthInsurancesError.typeError}
            />
          </div>
          { supportType !== 'PRESENTIAL' &&
            <div>
              <ConsentForm
                consentForm={consentForm}
                setOpenSyncSnackBar={setOpenSyncSnackBar}
                setErrorMessageSyncSnackBar={setErrorMessageSyncSnackBar}
                consentFormError={consentFormError}
                setConsentForm={setConsentForm}
                setConsentFormError={setConsentFormError}
              >
              </ConsentForm>
            </div>
          }
        </form>
      </div>
      <StepByStep 
        activeStep={props.activeStep} 
        handleBack={props.previousStep}
        handleAdvance={handleAdvance} 
        backgroundColor="#FFFFFF"
      />
    </div>
  );
};

export default FullPagePersonalDatas;
