import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Topic from '../../../../common/topic'
import ComplexList from '../../../../common/itemList/listForMultipleFields';
import ModalRegisterExamButton from '../../../../common/modal/exam/registerExam';
import InputFileButton from "../../../../common/fileInput/fileInputButton";
import { validationFileType } from '../../../../common/validators/fileInput'
import * as registerExams from "../../../../common/store/actions/registerExams";
import * as asyncActions from "../../../../common/store/actions/async.action";
import DownloadSpreadsheet from "../../../../common/DownloadSpreadsheet";

export const Exams = () => {
  const dispatch = useDispatch()
  const exams = useSelector((state) => state.Exams.exams);
  const examsSpreadsheet = useSelector((state) => state.Exams.examsSpreadsheet)

  const [disableAddExam, setDisableAddExam] = useState(!!examsSpreadsheet?.name)

  const handleUpdateExam = (index) => {
    dispatch(registerExams.alterActive(index));
    dispatch(registerExams.openModal(true));
  }

  const deleteExam = (index, value) => {
    dispatch(registerExams.deleteExam(index));
  }

  const handleChangeInputFile = async (value, typesAccepted) => {
    if (!value) {
      dispatch(asyncActions.requestFailure("Erro ao incluir planilha, por favor, tente novamente."))
      return
    }

    const typeIsAccpted = validationFileType(value.type, typesAccepted)

    if (!typeIsAccpted) {

      dispatch(asyncActions.requestFailure("O tipo do arquivo selecionado não é aceito para esse campo!"))
      return
    }

    dispatch(registerExams.RegisterExamsFromSpreadsheet(value));
    setDisableAddExam(true)
  }

  const typesAccepted = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  return (
    <>
      <div className="form-layout first-field-topic">
        <div className="side-by-side">
          <Topic
            classesNameCol="topic-datas"
            title="Exames"
            subtitle="Cadastre aqui os exames que realizará neste endereço"
            classesNameSubtitle="text-black"
          />
          <div className="handle-exam-operations">
            <DownloadSpreadsheet 
            fontWeight= "500"
            fontSize="16px"
            />

            <InputFileButton
              id="file"
              width="190px"
              accept=".xlsx"
              onChange={(e) =>
                handleChangeInputFile(e.target.files[0], typesAccepted)
              }
              value={"Importar planilha"}
              dataTest="input-pf-file"
              margin="0 5px 0 0"
              fontSize="17px"
            />

            <ModalRegisterExamButton
              data-target="modal-register-exam"
              disabled={disableAddExam}
            >
              Adicionar
          </ModalRegisterExamButton>
          </div>
        </div>
        <div className="service-register">
          <div className="register-services">
            {exams.length > 0 && <ComplexList
              fullList={exams}
              firstHeader={"Cód"}
              secondHeader={"Descrição"}
              thirdHeader={"Preparo"}
              forthHeader={"Preço"}
              firstParam={"examCode"}
              secondParam={"name"}
              thirdParam={"preparation"}
              forthParam={"price"}
              //firstColumnWidth="100%"
              menuItems={
                [
                  { name: "Editar esse exame", handleItem: handleUpdateExam }
                ]
              }
              deleteItem={deleteExam}
            />
            }
          </div>
        </div>
      </div>
    </>
  )
}
