import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import List from './secretariesList';
import Pagination from '../../../../common/pagination';
import SnackBar from '../../../../common/snackbar';


const useStyles = makeStyles({
    screen: {
        backgroundColor: '#f7f8f9',
        height: 'calc(70vh - 72px)',
        maxHeight: "100%",
        width: "100%",
        padding: '30px',
        boxSizing: 'border-box',
        overflowY: 'hidden',
    },
    scroll: {
        maxHeight: "100%",
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CDCED9',
            borderRadius: '5px'
        }
    }
})

const Doctors =() => {
    const classes = useStyles();

    const secretariesList = useSelector( (state) => state.SecretariesReducer.secretaries );
    const error = useSelector((state) => state.AsyncReducer.error);

    const [secretaries, setSecretaries] = useState([]);

    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const firstItem = (page*itemsPerPage)-(itemsPerPage);
    const lastItem = (firstItem + itemsPerPage);


    useEffect( () => {
        if(secretariesList) {
            const slicedList = secretariesList.slice(firstItem, lastItem);
            setSecretaries(slicedList)
        };
        // eslint-disable-next-line
    }, [secretariesList])


    return (
        <div className={classes.screen}>
            <div className={classes.scroll}>
                <div>
                    <List 
                        fullList={secretaries}
                        firstHeader="Nome"
                        firstParam="name"
                    />
                </div>
            </div>
            <Pagination 
                page={page}
                setPage={setPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                firstItem={firstItem}
                lastItem={lastItem}
                length={secretaries.length}
            />
            {error ?
                <SnackBar />
                :
                null
            }
        </div>
    );
}

export default Doctors;
