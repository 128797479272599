import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import LaunchIcon from '@material-ui/icons/Launch'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'

import procedureStatus from '../../../common/enums/procedureStatus'

export default (props) => {
  const history = useHistory()

  const status = useMemo(() => props.budget.status, [props])
  const reportFile = useMemo(() => props.budget.reportFile, [props])

  const openLink = () => window.open(reportFile, "_blank")

  const pendindAttendance = () => (
    <>
      <Grid item>
        <p>Vá para tela de atendimento para realizar o atendimento do procedimento.</p>
      </Grid>
      <Grid item>
        <IconButton style={{ color: '#DF9000' }} aria-label="Ir para tela de atendimento" onClick={() => history.push('/atendimento?tab=3')}>
          <LaunchIcon />
        </IconButton>
      </Grid>
    </>
  )

  const finishedAttendance = () => (
    <>
      <Grid item>
        <p>Atendimento finalizado. Clique para visualizar o documento</p>
      </Grid>
      <Grid item>
        <IconButton  aria-label="Ir para tela de atendimento" onClick={() => openLink()}>
          <LaunchIcon />
        </IconButton>
      </Grid>
    </>
  )

  const renderCorrectComponent = _status => {
    const statusMap = {
      [procedureStatus.OK]: pendindAttendance(),
      [procedureStatus.PENDING_TOKEN_VALIDATION]: pendindAttendance(),
      [procedureStatus.FINISHED]: finishedAttendance(),
    }

    return statusMap[_status] || pendindAttendance()
  }

  return (
    <>
      <Grid container>
        {renderCorrectComponent(status)}
      </Grid>
    </>
  )
}
