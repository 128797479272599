export function waitingUnits() {
    return {
        type: "WAITING_UNITS"
    }
}

export function setUnits(payload) {
    return {
        type: "SET_UNITS",
        payload
    }
}

export function updateFront(payload) {
    return {
        type: "UPDATE_UNITS_FRONT",
        payload
    }
}

export function updateSpecialitiesForUnits(payload) {
    return {
        type: "SET_SPECIALITIES_FOR_UNITS",
        payload
    }
}
