import graphqlApi from './api'
import format from 'date-fns/format'
import axios from 'axios'
export const getExamAppointmentsDaily = async (
		providerId,
		userType,
		page = 1,
		limit = 10,
		status,
		employeeId = null,
		date = new Date() 
	) => {
		try {
							
			const queryRequest = `
			rows {
				id
				allServicesProvidedDescription
					patient {
					id
					socialName
					user{
						email
						cpf
						name
					}
				}
				provider{
						doctor{
								
								uf
								crm
						}
				}  
				examAppointment {
					id
					dateTimeBeginAppointmentUtc
					status
					examAppointmentFiles{
						id
						file
					}
				providerHasServicesProvided {
					id
					provider {
						id
						doctor {
							agency
						}
					}
					serviceProvided {
						description
					}
				}   
				 
				}
	
			}
			count
			`;
	
			if(userType === "PROVIDER_SECRETARY") {
				const res = await graphqlApi({
					data: {
						query: `
							query($input: ExamAppointmentsSecretaryDailyInput!) {
								examAppointmentsSecretaryDaily(input: $input) {
										${queryRequest}
									} 
								}
							
						`,
						variables: {
							input: { providerId, page, limit, status, date: format(date, 'yyyy-MM-dd') },
						},
					}
				})
				
				if(!res.data.data.errors) {
					const totalPatientsOnDay = res.data.data.examAppointmentsSecretaryDaily.count;
					const patientsList = res.data.data.examAppointmentsSecretaryDaily.rows;
					return { totalPatientsOnDay, patientsList }
				} else {
					return { error: res.data.data.errors }
				}
	
			} else {
				const res = await graphqlApi({
					data: {
						query: `
							query($input: ExamAppointmentsDailyInput!) {
								examAppointmentsDaily(input: $input) {
									${queryRequest}
								}
							}
						`,
						variables: {
							input: {
								page,
								limit,
								status,
								date: format(date, 'yyyy-MM-dd')
							},
						},
					}
				})
				
				if(!res.data.data.errors) {
					const totalPatientsOnDay = res.data.data.examAppointmentsDaily.count;
					const patientsList = res.data.data.examAppointmentsDaily.rows.filter(() => !Boolean(employeeId));
					
					return { totalPatientsOnDay, patientsList }
				} else {
					return { error: res.data.data.errors }
				}
			}
	
		} catch (error) {
			return { error }
		}
	}   

		export const initExamAppointment = async (
			appointmentRequestId
				)  => {
				 
						const res = await graphqlApi({
							data: {
								query: `
											mutation {
												initExamAppointment(appointmentRequestId: "${appointmentRequestId}")
							
											}
													`,
							},
						});
						if (res.data.data.initExamAppointment) {
							return res.data.data.initExamAppointment  
						}
						else {
							return { error: res.data.data.errors }
						}
						
					}   
	

					export const handleInactivateExamAppointmentFile = async (
						examAppointmentFileId
							)  => {
							 
									const res = await graphqlApi({
										data: {
											query: `
														mutation {
															inactivateExamAppointmentFile(examAppointmentFileId: "${examAppointmentFileId}")
										
														}
																`,
										},
									});
									if (res.data.data.inactivateExamAppointmentFile) {
										return res.data.data.inactivateExamAppointmentFile  
									}
									else {
										return { error: res.data.data.errors }
									}
									
								} 

	 export const createExamAppointmentFile = async (payload) => {
						const urlRest = `${process.env.REACT_APP_API_URL}/servicesProvided/saveFiles`;
						
						
							try {
						
							 const res = await axios({
									method: "post",
									url: urlRest,
									data: payload,
								});
						
							 console.log('RESPOSATA DATA INPUT',res)

							 if (res.data) {
								return res.data  
							}
							else {
								return null
							}

							 
							} catch (error) {
								return null
							}
						};
	export const getExamAppointmentsAttendanceInfo = async (
							appointmentRequestId
								)  => {

									const queryRequest = `
									rows {
										id
										
											patient {
											id
											socialName
											user{
												email
												cpf
												name
											}
										}
										
										examAppointment {
											id
											dateTimeBeginAppointmentUtc
											status
											examRequest{
												id
												provider {
													id
													user {
														id
														name
													}
													doctor {
														crm
														uf
														photo
													  }
												}
											}
											examAppointmentFiles{
												id
												file
												originalName
											}
										providerHasServicesProvided {
											id
											provider {
												id
												doctor {
													agency
												}
											}
											serviceProvided {
												description
											}
										}   
										 
										}
							
									}
									count
									`;
								 
										const res = await graphqlApi({
											data: {
												query: `
												query Query{
													getExamAppointmentsAttendanceInfo(appointmentRequestId: "${appointmentRequestId}") {
														${queryRequest}	
													}
												}											
																
												
																	`,
				
											},
										});
										console.log(res.data.data.getExamAppointmentsAttendanceInfo.rows)
										if (res.data.data.getExamAppointmentsAttendanceInfo) {
											return res.data.data.getExamAppointmentsAttendanceInfo  
										}
										else {
											return { error: res.data.data.errors }
										}
										
			}  
					
	

	export const changeExamAppointmentStatusToResultsSended = async (
		appointmentRequestId
			)  => {
			 
					const res = await graphqlApi({
						data: {
							query: `
										mutation {
											changeExamAppointmentStatusToResultsSended(appointmentRequestId: "${appointmentRequestId}")
						
										}
												`,
						},
					});
					if (res.data.data.changeExamAppointmentStatusToResultsSended) {
						return res.data.data.changeExamAppointmentStatusToResultsSended  
					}
					else {
						return { error: res.data.data.errors }
					}
					
				} 
				export const finishExamAppointment = async (
					appointmentRequestId
						)  => {
						 
								const res = await graphqlApi({
									data: {
										query: `
													mutation {
														finishExamAppointment(appointmentRequestId: "${appointmentRequestId}")
									
													}
															`,
									},
								});
								console.log('finishExamAppointment', res)
								if (res.data.finishExamAppointment) {
									return res.data.finishExamAppointments  
								}
								else {
									return { error: res.data.errors }
								}
								
							} 
