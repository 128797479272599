import React from 'react'

import { 
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import StepByStep from '../../../common/stepByStep/stepByStep'


const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '50px',
    paddingLeft: '120px'
  },
  radio: {
    "& label": {
      border: "1px solid #E7E7ED",
      borderRadius: "4px",
      height: "40px",
      width: "100%",
      margin: "0 0 10px 0",
    },
    "& span": {
      fontSize: "1.14rem",
      fontWeight: "normal",
    },
    "& .MuiIconButton-root": {
      borderRadius: '0',
      borderRight: '1px solid #E7E7ED'
    },
    "& .MuiIconButton-colorSecondary:hover": {
      // backgroundColor: 'rgba(255, 193, 7, 0.18)'
      backgroundColor: 'transparent'
    },
    "& .MuiTypography-root": {
      marginLeft: '20px',
      marginRight: '20px',
    },
    "& .MuiTouchRipple-root:hover": {
      backgroundColor: 'rgba(255, 193, 7, 0.18)'
    },  
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#f4b133",
    }
  },
}))

export const ProfessionalType = (props) => {
  const classes = useStyles()

  return (
    <>
      <StepByStep 
        activeStep={props.activeStep} 
        stepper 
        handleAdvance={props.nextStep}
        steps={props.steps}  
      />
      
      <div className={classes.root}>
        <h1>Tipo de Profissional</h1>

        <FormControl className={classes.radio}>
          <FormLabel component="legend"></FormLabel>
          <RadioGroup
            aria-label="type-stablishment"
            value={props.type}
            onChange={(e) => props.setType(e.target.value)}
            name="type-account"
            data-test="type-account"
          >
            <FormControlLabel
              value="doctor"
              control={<Radio />}
              label="Médico"
            />
            <FormControlLabel
              value="others"
              control={<Radio />}
              label="Outros Profissionais"
            />
          </RadioGroup>
        </FormControl>

      </div>
    </>
  )
}