const INITIAL_STATE = {
  count: 0,
  medicalAppointmentRequests: [],
  lastMedicalAppointmentChanged: null
}

export default function (state = INITIAL_STATE, action) {
  switch(action.type) {

    case "SET_MEDICAL_APPOINTMENT_REQUESTS_COUNT":
      return {
        ...state,
        count: action.payload
      }
    
    case "SET_MEDICAL_APPOINTMENT_REQUESTS":
      return {
        ...state,
        medicalAppointmentRequests: action.payload
      }

    case "SET_LAST_CHANGED_MEDICAL_APPOINTMENT":
      return {
        ...state,
        lastMedicalAppointmentChanged: action.payload
      }

    default:
      return state
  }
}