const INITIAL_STATE = {
  count: 0,
}

export default function (state = INITIAL_STATE, action) {
  switch(action.type) {

    default:
      return state
  }
}