import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {format} from 'date-fns';

import cnpjValidator from '../../../common/validators/cnpj'
import maskTelephone from "../../../common/mask/telephone";
import maskCnpj from '../../../common/mask/cnpj'
import cpfMask from '../../../common/mask/cpf'

import SnackBar from '../../../common/snackbar/index';
import Input from '../../../common/input';
import TextArea from '../../../common/textarea';
import Button from '../../../common/button';

import * as consultationActions from '../../../common/store/actions/consultation.action';
import * as asyncActions from '../../../common/store/actions/async.action';

import { generatePdf } from '../../../utils/certification/pdfGenerator';
import { documentSignature } from '../../../utils/certification/certification';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grow } from '@material-ui/core';
import Loading from "../../../common/loading";
import { handlePatientName } from "../../../common/handlePatientName";

// Material UI Styling

const useStyles = makeStyles( {
    root: {
        width: props => props.width ? props.width : '70vw',
        maxWidth: "70vw !important",
        fontSize: "inherit",
    },
    header: {
        display: 'flex',
        justifyContent: "space-between"
    },
    topic: {
        marginBottom: "30px",
        fontSize: '16px',
        "& > .title": {
            fontWeight: '600',
        },
        "& > p": {
            lineHeight: '20px',
            color: "#6B6C7E",
            marginTop: '5px'
        }
    },
    retorno: {
        "& > div": {
            width: '100%',
            marginTop: "5%"
        }
    },
    success: {
        backgroundColor: "#EDF9F0",
        color: "#287D3C",
        fontWeight: 600,
    },
    extraMargin: {
        margin: '50px 0 21px 0',
        padding: 0
    },
    error: {
        fontSize: '12px',
        color: "#DA1414",
    },
    pdf: {
        height: '60vh',
    },
    hasBorder: {
        border: '1px solid #000',
        marginTop:"10px",
        "& .topic-title": {
            width: '100%',
            borderBottom: '1px solid #000',
            "& h3":{
                textAlign:"center"
            }
        },
        "& .topic-content": {
            padding:'10px',
        },
        "& .border":{
            borderBottom:'1px solid #000'
        }
    },
    indetifier: {
        display:'flex',
        marginBottom:'3 0px',
        justifyContent:'space-between',
        "& div":{
            maxWidth:'490px'
        }
    }
    
});

// Component

const DoctorPrescriptionAntimicrobials = (props) => {
    const dispatch = useDispatch();
    const providerId = window.localStorage.getItem("Prov-Vida-Connect");
    const medicalAppointmentId = window.localStorage.getItem("appointId");
    const patientId = window.localStorage.getItem("patientId");
    
    const docData = JSON.parse(window.localStorage.getItem("docData"));
    const provData = JSON.parse(window.localStorage.getItem("providerData"));
    const  docName = docData?.name || provData?.name
    const email = docData?.email || provData?.email
    const cpf = docData?.cpf || provData?.cpf
    const crm = docData?.crm || ''
    const crmUf = docData?.uf || provData?.uf
    
    const data = useSelector((state) => state.ConsultationReducer);
    const open = data.openDoctorPrescriptionAntimicrobials;
    const classes = useStyles(props);

    const [renderPDF, setRenderPDF] = useState(false);

    const [substancePrecribed1, setSubstancePrecribed1] = useState('')
    const [doseOrConcentration1, setDoseOrConcentration1] = useState('')
    const [pharmaceuticalForm1, setPharmaceuticalForm1] = useState('')
    const [admnistrationRoute1, setAdministrationRoute1] = useState('')
    const [dosage1, setDosage1] = useState('')
    const [quantity1, setQuantity1] = useState('')
    const [treatmentDuration1, setTreatmentDuration1] = useState('')
    const [obs1, setObs1] = useState('')
    const [substancePrecribed2, setSubstancePrecribed2] = useState('')
    const [doseOrConcentration2, setDoseOrConcentration2] = useState('')
    const [pharmaceuticalForm2, setPharmaceuticalForm2] = useState('')
    const [admnistrationRoute2, setAdministrationRoute2] = useState('')
    const [dosage2, setDosage2] = useState('')
    const [quantity2, setQuantity2] = useState('')
    const [treatmentDuration2, setTreatmentDuration2] = useState('')
    const [obs2, setObs2] = useState('')
    const [substancePrecribed3, setSubstancePrecribed3] = useState('')
    const [doseOrConcentration3, setDoseOrConcentration3] = useState('')
    const [pharmaceuticalForm3, setPharmaceuticalForm3] = useState('')
    const [admnistrationRoute3, setAdministrationRoute3] = useState('')
    const [dosage3, setDosage3] = useState('')
    const [quantity3, setQuantity3] = useState('')
    const [treatmentDuration3, setTreatmentDuration3] = useState('')
    const [obs3, setObs3] = useState('')

    const [doctorAddress, setDoctorAddress] = useState('')
    const [doctorCity, setDoctorCity] = useState('')
    const [doctorUf, setDoctorUf] = useState('')
    const [doctorTelephone, setDoctorTelephone] = useState('')

    const [pharmaceuticalName, setPharmaceuticalName] = useState('')
    const [pharmaceuticalCrf, setPharmaceuticalCrf] = useState('')
    const [pharmaceuticalUf, setPharmaceuticalUf] = useState('')
    const [pharmacyName, setPharmacyName] = useState('')
    const [pharmacyAddress, setPharmacyAddress] = useState('')
    const [pharmacyCity, setPharmacyCity] = useState('')
    const [pharmacyUf, setPharmacyUf] = useState('')
    const [pharmacyCnpj, setPharmacyCnpj] = useState('')
    const [pharmacyCnpjError, setPharmacyCnpjError] = useState({error:false, typeError:''})
    const [pharmacyTelephone, setPharmacyTelephone] = useState('')

    const [medicineName1, setMedicineName1] = useState('')
    const [laboratory1, setLaboratory1] = useState('')
    const [lotNumber1, setLotNumber1] = useState('')
    const [qtyBoxes1, setQtyBoxes1] = useState('')
    const [numberRegistry1, setNumberRegistry1] = useState('')
    const [medicineName2, setMedicineName2] = useState('')
    const [laboratory2, setLaboratory2] = useState('')
    const [lotNumber2, setLotNumber2] = useState('')
    const [qtyBoxes2, setQtyBoxes2] = useState('')
    const [numberRegistry2, setNumberRegistry2] = useState('')
    const [medicineName3, setMedicineName3] = useState('')
    const [laboratory3, setLaboratory3] = useState('')
    const [lotNumber3, setLotNumber3] = useState('')
    const [qtyBoxes3, setQtyBoxes3] = useState('')
    const [numberRegistry3, setNumberRegistry3] = useState('')

    const [medicineIntercambiality1, setMedicineIntercambiality1 ] = useState('')
    const [medicineIntercambialitySubstituted1, setMedicineIntercambialitySubstituted1 ] = useState('')
    const [medicineIntercambiality2, setMedicineIntercambiality2 ] = useState('')
    const [medicineIntercambialitySubstituted2, setMedicineIntercambialitySubstituted2 ] = useState('')
    const [medicineIntercambiality3, setMedicineIntercambiality3 ] = useState('')
    const [medicineIntercambialitySubstituted3, setMedicineIntercambialitySubstituted3 ] = useState('')

    const [success, setSuccess] = useState(false);

    const [, setDoctorName]= useState("");
    const [patientName, setPatientName] = useState("");
    const [patientCpf, setPatientCpf] = useState("")
    const [patientAge, setPatientAge] = useState('')
    const [patientGender, setPatientGender] = useState('')

    const [loading, setLoading ] = useState(false);

    const getAge = (birth) => {
        if(!birth){
            return ""
        }
      const currentDate = new Date()
      birth = new Date(birth)
      if ((currentDate.getMonth() === birth.getMonth() && currentDate.getDate() >= birth.getDate()) 
          || (currentDate.getMonth() > birth.getMonth())) {

        const age = currentDate.getFullYear() - birth.getFullYear()
        return parseFloat(age)
        
      } else {
        const age = currentDate.getFullYear() - birth.getFullYear() -1
        return parseFloat(age)
      }
    }

    useEffect( () => {
        if(data.data.patient) {
            const patName = handlePatientName(data.data.patient, false)
            const age = getAge(data.data.patient.birth)
            const gender = data.data.patient.gender === 'MALE' ? 'Masculino' : 'Feminino'
            setPatientAge(age)
            setPatientGender(gender)
            setPatientName(patName);
            setDoctorName(docName);
            setPatientCpf(cpfMask(data.data.patient.user.cpf))
        }

        if(data.local) {
            let address = data.local.address || ''
            if(data.local.number) address += `, ${data.local.number}`
            if(data.local.neighborhood) address += `, ${data.local.neighborhood}`
            if(data.local.complement) address += `, ${data.local.complement}`

            setDoctorAddress(address)
            setDoctorCity(data.local.city || '')
            setDoctorUf(data.local.district || '')
            setDoctorTelephone(data.local.phone || '')
        }

        //eslint-disable-next-line
    }, [data])

    const handleClose = () => {
        setSubstancePrecribed1('')
        setDoseOrConcentration1('')
        setPharmaceuticalForm1('')
        setAdministrationRoute1('')
        setDosage1('')
        setQuantity1('')
        setTreatmentDuration1('')
        setObs1('')
        setSubstancePrecribed2('')
        setDoseOrConcentration2('')
        setPharmaceuticalForm2('')
        setAdministrationRoute2('')
        setDosage2('')
        setQuantity2('')
        setTreatmentDuration2('')
        setObs2('')
        setSubstancePrecribed3('')
        setDoseOrConcentration3('')
        setPharmaceuticalForm3('')
        setAdministrationRoute3('')
        setDosage3('')
        setQuantity3('')
        setTreatmentDuration3('')
        setObs3('')
        setDoctorAddress('')
        setDoctorCity('')
        setDoctorUf('')
        setDoctorTelephone('')
        setPharmaceuticalName('')
        setPharmaceuticalCrf('')
        setPharmacyName('')
        setPharmacyCnpj('')
        setPharmacyAddress('')
        setPharmacyCity('')
        setPharmacyUf('')
        setPharmacyTelephone('')
        setMedicineName1('')
        setLaboratory1('')
        setLotNumber1('')
        setQtyBoxes1('')
        setNumberRegistry1('')
        setMedicineName2('')
        setLaboratory2('')
        setLotNumber2('')
        setQtyBoxes2('')
        setNumberRegistry2('')
        setMedicineName3('')
        setLaboratory3('')
        setLotNumber3('')
        setQtyBoxes3('')
        setNumberRegistry3('')
        setMedicineIntercambiality1('')
        setMedicineIntercambialitySubstituted1('')
        setMedicineIntercambiality2('')
        setMedicineIntercambialitySubstituted2('')
        setMedicineIntercambiality3('')
        setMedicineIntercambialitySubstituted3('')
        setRenderPDF(false)
        setSuccess(false)
        setLoading(false)
        dispatch(consultationActions.openDoctorPrescriptionAntimicrobials(false));
    }

    const handleSave = async () => {
        setLoading(true);
        const documentPayload  = {
            title:"Receituário antimicrobianos",
            patientName,
            patientCpf,
            patientAge,
            patientGender,
            substancePrecribed1,
            doseOrConcentration1,
            pharmaceuticalForm1,
            admnistrationRoute1,
            dosage1,
            quantity1,
            treatmentDuration1,
            obs1,
            substancePrecribed2,
            doseOrConcentration2,
            pharmaceuticalForm2,
            admnistrationRoute2,
            dosage2,
            quantity2,
            treatmentDuration2,
            obs2,
            substancePrecribed3,
            doseOrConcentration3,
            pharmaceuticalForm3,
            admnistrationRoute3,
            dosage3,
            quantity3,
            treatmentDuration3,
            obs3,
            docName,
            crm,
            crmUf,
            doctorAddress,
            doctorCity,
            doctorUf,
            doctorTelephone,
            pharmaceuticalName,
            pharmaceuticalCrf,
            pharmaceuticalUf,
            pharmacyName,
            pharmacyCnpj,
            pharmacyAddress,
            pharmacyCity,
            pharmacyUf,
            pharmacyTelephone,
            medicineName1,
            laboratory1,
            lotNumber1,
            qtyBoxes1,
            numberRegistry1,
            medicineName2,
            laboratory2,
            lotNumber2,
            qtyBoxes2,
            numberRegistry2,
            medicineName3,
            laboratory3,
            lotNumber3,
            qtyBoxes3,
            numberRegistry3,
            medicineIntercambiality1,
            medicineIntercambialitySubstituted1,
            medicineIntercambiality2,
            medicineIntercambialitySubstituted2,
            medicineIntercambiality3,
            medicineIntercambialitySubstituted3,
            emission: format(new Date(), 'dd/MM/yyyy')
        };
        const fileName="Receituário-antimicrobianos.pdf";
        const user = {name: docName, email, identifier: cpf};

        const authenticationPayload = {
            fileName,
            user,
            providerId,
            medicalAppointmentId,
            patientId,
            documentType: "DOCTOR_PRESCRIPTION_ANTIMICROBIALS",
        };
        try {
            const {embedUrl} = await generatePdf(documentPayload, authenticationPayload);
            setRenderPDF(true);
            await documentSignature("pdf", embedUrl, setSuccess, dispatch, consultationActions.setCountDoctorPrescriptionAntimicrobials);
            setLoading(false);
        } catch(error) {
            dispatch(asyncActions.requestFailure("Não foi possível exibir o PDF para assinatura agora."));
            setLoading(false);
        };

    }

    return (
            <div>
                <Dialog 
                    open={open}
                    onClose={handleClose} 
                    aria-labelledby="form-dialog-title" 
                    classes={{
                    paper: classes.root
                    }}
                    TransitionComponent={Grow}
                    transitionDuration={{enter: 200, exit: 0}}
                    disableBackdropClick={renderPDF || loading}
                    disableEscapeKeyDown={renderPDF || loading}
                >
                <div className={`${classes.header} ${success ? classes.success : null}`}>
                    <DialogTitle>
                      {
                          renderPDF ?
                          <span>Assine o documento</span>:
                          <span>RECEITUÁRIO DE ANTIMICROBIANOS</span>
                      }  
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogContent dividers>
                    {renderPDF ?
                        <div id="pdf" className={classes.pdf}></div>
                        :
                      <div>
                        <h3>Indentificação do Paciente</h3>
                        <div className="file-input">
                        <Input
                            width="75%"
                            label="Nome Completo"
                            InputProps={{readOnly: false}}
                            onChange={(e) => setPatientName(e.target.value)}
                            type="text"
                            value={patientName}
                            backgroundColor={"#F7F8F9"}
                            border={"none"}
                            fontSize="14px !important"
                        />
                        <Input
                            width="25%"
                            label="CPF"
                            InputProps={{readOnly: false}}
                            // onChange={(e) => setPatientCpf(cpfMask(e.target.value))}
                            type="text"
                            value={patientCpf}
                            backgroundColor={"#F7F8F9"}
                            border={"none"}
                            fontSize="14px !important"
                        />
                        </div>
                        <div className="file-input">
                            <Input
                            label="Idade"
                            width="30%"
                            InputProps={{readOnly: true}}
                            type="text"
                            value={patientAge}
                            backgroundColor={"#F7F8F9"}
                            border={"none"}
                            fontSize="14px !important"
                            />
                            <Input
                                label="Sexo"
                                width="30%"
                                InputProps={{readOnly: true}}
                                type="text"
                                value={patientGender}
                                backgroundColor={"#F7F8F9"}
                                border={"none"}
                                fontSize="14px !important"
                            />
                        </div>
                        <div>
                            <div className={classes.hasBorder}>
                                <div className="topic-content border">
                                    <Input
                                    label="Nome do Medicamento ou da Substância Prescrita (DCB)"
                                    type="text"
                                    value={substancePrecribed1}
                                    onChange={(e) => setSubstancePrecribed1(e.target.value)}
                                    fontSize="14px !important"
                                    />
                                    <Input
                                    label="Dose ou Concentração"
                                    type="text"
                                    value={doseOrConcentration1}
                                    onChange={(e) => setDoseOrConcentration1(e.target.value)}
                                    fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                        <Input
                                            label="Forma Farmacêutica"
                                            type="text"
                                            value={pharmaceuticalForm1}
                                            onChange={(e) => setPharmaceuticalForm1(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="Via de Admnistração"
                                            type="text"
                                            value={admnistrationRoute1}
                                            onChange={(e) => setAdministrationRoute1(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                <Input
                                    label="Posologia"
                                    type="text"
                                    value={dosage1}
                                    onChange={(e) => setDosage1(e.target.value)}
                                    fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                    <Input
                                        label="Quantidade"
                                        type="text"
                                        value={quantity1}
                                        onChange={(e) => setQuantity1(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <Input
                                        label="Duração do Tratamento"
                                        type="text"
                                        value={treatmentDuration1}
                                        onChange={(e) => setTreatmentDuration1(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    </div>
                                    <TextArea
                                    width="95%"
                                    label="OBS:"
                                    value={obs1}
                                    onChange={setObs1}
                                    placeholder=""
                                    rows={6}
                                    />
                                </div>
                                <div className="topic-content border">
                                    <Input
                                    label="Nome do Medicamento ou da Substância Prescrita (DCB)"
                                    type="text"
                                    value={substancePrecribed2}
                                    onChange={(e) => setSubstancePrecribed2(e.target.value)}
                                    fontSize="14px !important"
                                    />
                                    <Input
                                    label="Dose ou Concentração"
                                    type="text"
                                    value={doseOrConcentration2}
                                    onChange={(e) => setDoseOrConcentration2(e.target.value)}
                                    fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                        <Input
                                            label="Forma Farmacêutica"
                                            type="text"
                                            alue={pharmaceuticalForm2}
                                            onChange={(e) => setPharmaceuticalForm2(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="Via de Admnistração"
                                            type="text"
                                            value={admnistrationRoute2}
                                            onChange={(e) => setAdministrationRoute2(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                <Input
                                    label="Posologia"
                                    type="text"
                                    value={dosage2}
                                    onChange={(e) => setDosage2(e.target.value)}
                                    fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                    <Input
                                        label="Quantidade"
                                        type="text"
                                        value={quantity2}
                                        onChange={(e) => setQuantity2(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <Input
                                        label="Duração do Tratamento"
                                        type="text"
                                        value={treatmentDuration2}
                                        onChange={(e) => setTreatmentDuration2(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    </div>
                                    <TextArea
                                    width="95%"
                                    label="OBS:"
                                    value={obs2}
                                    onChange={setObs2}
                                    placeholder=""
                                    rows={6}
                                    />
                                </div>
                                <div className="topic-content">
                                    <Input
                                    label="Nome do Medicamento ou da Substância Prescrita (DCB)"
                                    type="text"
                                    value={substancePrecribed3}
                                    onChange={(e) => setSubstancePrecribed3(e.target.value)}
                                    fontSize="14px !important"
                                    />
                                    <Input
                                    label="Dose ou Concentração"
                                    type="text"
                                    value={doseOrConcentration3}
                                    onChange={(e) => setDoseOrConcentration3(e.target.value)}
                                    fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                        <Input
                                            label="Forma Farmacêutica"
                                            type="text"
                                            alue={pharmaceuticalForm3}
                                            onChange={(e) => setPharmaceuticalForm3(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="Via de Admnistração"
                                            type="text"
                                            value={admnistrationRoute3}
                                            onChange={(e) => setAdministrationRoute3(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                <Input
                                    label="Posologia"
                                    type="text"
                                    value={dosage3}
                                    onChange={(e) => setDosage3(e.target.value)}
                                    fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                    <Input
                                        label="Quantidade"
                                        type="text"
                                        value={quantity3}
                                        onChange={(e) => setQuantity3(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <Input
                                        label="Duração do Tratamento"
                                        type="text"
                                        value={treatmentDuration3}
                                        onChange={(e) => setTreatmentDuration3(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    </div>
                                    <TextArea
                                    width="95%"
                                    label="OBS:"
                                    value={obs3}
                                    onChange={setObs3}
                                    placeholder=""
                                    rows={6}
                                    />
                                </div>
                            </div>
                                
                            <div className={classes.hasBorder}>
                                <div className="topic-title">
                                    <h3>IDENTIFICAÇÃO DO EMITENTE</h3>
                                </div>
                                <div className="topic-content">
                                    <Input
                                        label="Nome Médico(a)"
                                        InputProps={{readOnly: true}}
                                        type="text"         
                                        value={docName} 
                                        backgroundColor={"#F7F8F9"}
                                        border={"none"}                           
                                        fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                        <Input
                                        label="CRM"
                                        InputProps={{readOnly: true}}
                                        width="80%"
                                        value={crm}
                                        backgroundColor={"#F7F8F9"}
                                        border={"none"}   
                                        type="text"
                                        fontSize="14px !important"
                                        />
                                        <Input
                                        label="UF"
                                        InputProps={{readOnly: true}}
                                        width="15%"
                                        value={crmUf}
                                        backgroundColor={"#F7F8F9"}
                                        border={"none"}   
                                        type="text"
                                        fontSize="14px !important"
                                        />
                                    </div>

                                    <Input
                                        label="Endereço"
                                        type="text"
                                        value={doctorAddress}
                                        onChange={(e) => setDoctorAddress(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                        <Input
                                            label="Cidade"
                                            width="80%"
                                            type="text"
                                            value={doctorCity}
                                            onChange={(e) => setDoctorCity(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="UF"
                                            width="15%"
                                            type="text"
                                            value={doctorUf}
                                            onChange={(e) => setDoctorUf(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                    <div className="file-input">
                                        <Input
                                            label="Telefone"
                                            width="80%"
                                            type="text"
                                            value={doctorTelephone}
                                            onChange={(e) => setDoctorTelephone(maskTelephone(e.target.value))}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="Data de Emissão"
                                            InputProps={{readOnly: true}}
                                            width="15%"
                                            type="text"
                                            value={format(new Date(), 'dd/MM/yyyy')}
                                            backgroundColor={"#F7F8F9"}
                                            border={"none"}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.hasBorder}>
                                <div className="topic-title">
                                    <h3>IDENTIFICAÇÃO DO FORNECEDOR</h3>
                                </div>
                                <div className="topic-content">

                                    <div className="file-input">
                                        <Input
                                            label="Nome Farmacêutico(a)"
                                            width="60%"
                                            type="text"
                                            value={pharmaceuticalName}
                                            onChange={(e) => setPharmaceuticalName(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="CRF"
                                            width="20%"
                                            type="text"
                                            value={pharmaceuticalCrf}
                                            onChange={(e) => setPharmaceuticalCrf(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="UF"
                                            width="15%"
                                            type="text"
                                            value={pharmaceuticalUf}
                                            onChange={(e) => setPharmaceuticalUf(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                    <div className="file-input">
                                        <Input
                                            label="Nome Farmácia"
                                            width="55%"
                                            type="text"
                                            value={pharmacyName}
                                            onChange={(e) => setPharmacyName(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="CNPJ"
                                            width="40%"
                                            type="text"
                                            value={pharmacyCnpj}
                                            onChange={(e) => setPharmacyCnpj(maskCnpj(e.target.value))}
                                            onBlur={() => setPharmacyCnpjError(cnpjValidator(pharmacyCnpj))}
                                            error={pharmacyCnpjError.typeError}
                                            fontSize="14px !important"
                                        />
                                    </div> 
                                    <Input
                                        label="Endereço"
                                        type="text"
                                        value={pharmacyAddress}
                                        onChange={(e) => setPharmacyAddress(e.target.value)}
                                        fontSize="14px !important"
                                    />
                                    <div className="file-input">
                                        <Input
                                            label="Cidade"
                                            width="80%"
                                            type="text"
                                            value={pharmacyCity}
                                            onChange={(e) => setPharmacyCity(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="UF"
                                            width="15%"
                                            type="text"
                                            value={pharmacyUf}
                                            onChange={(e) => setPharmacyUf(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                    <div className="file-input">
                                        <Input
                                            label="Telefone"
                                            type="text"
                                            value={pharmacyTelephone}
                                            onChange={
                                                (e) => setPharmacyTelephone(maskTelephone(e.target.value))
                                            }
                                            fontSize="14px !important"
                                        />
                                    </div>
                                </div>
                            </div>
                            
                                <div className={classes.hasBorder}>
                                    <div className="topic-title">
                                        <h3>DADOS DO(S) PRODUTO(S) DISPENSADOS</h3>
                                    </div>
                                    <div className="topic-content">
                                        <Input
                                            label="Nome do Medicamento"
                                            type="text"
                                            value={medicineName1}
                                            onChange={(e) => setMedicineName1(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="Laboratório"
                                            type="text"
                                            value={laboratory1}
                                            onChange={(e) => setLaboratory1(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <div className="file-input">
                                            <Input
                                                label="Número do Lote"
                                                type="text"
                                                value={lotNumber1}
                                                onChange={(e) => setLotNumber1(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                            <Input
                                                label="Quantidade de Caixas"
                                                type="text"
                                                value={qtyBoxes1}
                                                onChange={(e) => setQtyBoxes1(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                        </div>
                                        <Input
                                            label="Número de Registro da Receita no Livro de Receituário
                                            (Quando Medicamento Manipulado)"
                                            type="text"
                                            value={numberRegistry1}
                                            onChange={(e) => setNumberRegistry1(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                </div>

                                <div className={classes.hasBorder}>
                                    <div className="topic-content">
                                    <Input
                                            label="Nome do Medicamento"
                                            type="text"
                                            value={medicineName2}
                                            onChange={(e) => setMedicineName2(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="Laboratório"
                                            type="text"
                                            value={laboratory2}
                                            onChange={(e) => setLaboratory2(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <div className="file-input">
                                            <Input
                                                label="Número do Lote"
                                                type="text"
                                                value={lotNumber2}
                                                onChange={(e) => setLotNumber2(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                            <Input
                                                label="Quantidade de Caixas"
                                                type="text"
                                                value={qtyBoxes2}
                                                onChange={(e) => setQtyBoxes2(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                        </div>
                                        <Input
                                            label="Número de Registro da Receita no Livro de Receituário
                                            (Quando Medicamento Manipulado)"
                                            type="text"
                                            value={numberRegistry2}
                                            onChange={(e) => setNumberRegistry2(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                </div>

                                <div className={classes.hasBorder}>
                                    <div className="topic-content">
                                    <Input
                                            label="Nome do Medicamento"
                                            type="text"
                                            value={medicineName3}
                                            onChange={(e) => setMedicineName3(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <Input
                                            label="Laboratório"
                                            type="text"
                                            value={laboratory3}
                                            onChange={(e) => setLaboratory3(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                        <div className="file-input">
                                            <Input
                                                label="Número do Lote"
                                                type="text"
                                                value={lotNumber3}
                                                onChange={(e) => setLotNumber3(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                            <Input
                                                label="Quantidade de Caixas"
                                                type="text"
                                                value={qtyBoxes3}
                                                onChange={(e) => setQtyBoxes3(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                        </div>
                                        <Input
                                            label="Número de Registro da Receita no Livro de Receituário
                                            (Quando Medicamento Manipulado)"
                                            type="text"
                                            value={numberRegistry3}
                                            onChange={(e) => setNumberRegistry3(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                </div>

                                <div className={classes.hasBorder}>
                                    <div className="topic-title">
                                        <h3>INFORMAÇÕES SOBRE INTERCAMBIALIDADE</h3></div>
                                    <div className="topic-content border">
                                        <Input
                                            label="De Acordo Com a Lei 9787/99, o Medicamento:"
                                            type="text"
                                            value={medicineIntercambiality1}
                                            onChange={(e) => setMedicineIntercambiality1(e.target.value)}
                                            fontSize="14px !important"
                                            />
                                            <Input
                                                label="Foi Substituído pelo Genérico:"
                                                type="text"
                                                value={medicineIntercambialitySubstituted1}
                                                onChange={(e) => setMedicineIntercambialitySubstituted1(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                    </div>
                                    <div className="topic-content border">
                                    <Input
                                            label="De Acordo Com a Lei 9787/99, o Medicamento:"
                                            type="text"
                                            value={medicineIntercambiality2}
                                            onChange={(e) => setMedicineIntercambiality2(e.target.value)}
                                            fontSize="14px !important"
                                            />
                                            <Input
                                                label="Foi Substituído pelo Genérico:"
                                                type="text"
                                                value={medicineIntercambialitySubstituted2}
                                                onChange={(e) => setMedicineIntercambialitySubstituted2(e.target.value)}
                                                fontSize="14px !important"
                                            />
                                    </div>
                                    <div className="topic-content">
                                        <Input
                                            label="De Acordo Com a Lei 9787/99, o Medicamento:"
                                            type="text"
                                            value={medicineIntercambiality3}
                                            onChange={(e) => setMedicineIntercambiality3(e.target.value)}
                                            fontSize="14px !important"
                                            />
                                        <Input
                                            label="Foi Substituído pelo Genérico:"
                                            type="text"
                                            value={medicineIntercambialitySubstituted3}
                                            onChange={(e) => setMedicineIntercambialitySubstituted3(e.target.value)}
                                            fontSize="14px !important"
                                        />
                                    </div>
                                </div>
                            <DialogActions className={classes.extraMargin}>
                                {success ?
                                    <Button 
                                        onClick={handleClose}
                                        width="120px"
                                        border="1px solid #DF9000"
                                        backgroundColor="#FFFFFF"
                                        color="#DF9000"
                                    >
                                        Ok
                                    </Button>
                                    :
                                    loading ?
                                        <Loading width="50px" />
                                        :
                                        <>
                                            <Button 
                                                onClick={handleClose}
                                                width="120px"
                                                border="1px solid #CDCED9"
                                                backgroundColor="#FFFFFF"
                                                color="#6B6C7E"
                                                margin="0 20px 0 0"
                                            >
                                                Não
                                            </Button>
                                            <Button
                                                width="120px"
                                                border="1px solid #DF9000"
                                                backgroundColor="#FFFFFF"
                                                color="#DF9000"
                                                onClick={handleSave}
                                            >
                                                Sim
                                            </Button>
                                        </>
                                }
                            </DialogActions>
                        </div>
                        <SnackBar />
                        
                    </div>
                    }
                </DialogContent>
                </Dialog>
            </div>
    );
};

export default memo(DoctorPrescriptionAntimicrobials);
