import React, { useState } from "react";
import { useDispatch } from 'react-redux';

import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import Button from '../../../../common/button';
import Textarea from '../../../../common/textarea';

import * as scheduleActions from '../../../../common/store/actions/schedule.action';
import * as asyncActions from '../../../../common/store/actions/async.action';

// Material UI Styling

const useStyles = makeStyles( {
  root: {
    width: '55%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: "#EDF9F0",
    borderBottom: '1px solid #E7E7ED',
  },
  title: {
    color:"#287D3C",
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '10px'
    }
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  },  
  buttonBg: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: "flex-end",
    padding: '22px',
    marginTop: '28px',
    boxSizing: 'border-box',
  }
})

const ModalSlotCancel = ({medicalAppointmentId, ...props}) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const providerId = window.localStorage.getItem('Prov-Vida-Connect');
  const employeeId = window.localStorage.getItem('employeeId');
  const unitId = window.localStorage.getItem('Unit-Vida-Connect');

  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");

  const handleSaveCancel = () => {
    if(!reason) {
      setReasonError("Descreva o motivo do cancelamento.")
    } else {
      dispatch(asyncActions.cancelMedicalAppointment({
        medicalAppointmentId,
        providerId,
        employeeId,
        unitId,
        reason,
      }));
      dispatch(scheduleActions.updateCalendarFront(true));
    }
  }
  
  return (
    <>      
        <div className={classes.header}>
          <DialogTitle>
            <div className={classes.title}>
              <span>Cancelar esse agendamento?</span>
            </div>
          </DialogTitle>
          <IconButton aria-label="close" onClick={() => props.handleClose()}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
            <div className="modal-body">
              <Textarea
                label="Motivo"
                placeholder="Qual o motivo do cancelamento?"
                value={reason}
                onChange={setReason}
                onBlur={ () => { 
                  if(!reason) setReasonError("Descreva o motivo do cancelamento.");
                  if(reason) setReasonError("");
                }}
                error={reasonError}
                width="90%"
                rows="3"
              />
            </div>
        </DialogContent>
        <div className={classes.buttonBg}>
        <Button
              onClick={props.handleClose}
              width="72px"
              border="1px solid #CDCED9"
              borderRadius="4px"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 20px 0 0"
            >
              Não
            </Button>
            <Button
              onClick={handleSaveCancel}
              width="72px"
              border="1px solid #CDCED9"
              borderRadius="4px"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 20px 0 0"
            >
              Sim
            </Button>
        </div>
    </>
  );
};

export default ModalSlotCancel;