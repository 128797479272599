import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { parseISO } from "date-fns";

import timeSpanISO from "../../utils/time/timeSpanISO";
import FullFile from "./components/fullFile";
import Consultation from "./components/consultation";
import Button from "../../common/button";
import Topic from "../../common/topic";
import HorizontalList from "../../common/horizontalList";
import DispensePatientFromVideoCallModal from "./modals/dispensePatientFromVideoCallModal";
import FinishConsultationModal from "./modals/finishConsultationModal";
import ConsentTermModal from "./modals/consentTermModal";
import TriageModal from "../attendance/modals/triageModal";
import SnackBar from "../../common/snackbar";

import { makeStyles } from "@material-ui/core/styles";

import * as triageActions from "../../common/store/actions/triage.action";
import * as consultationActions from "../../common/store/actions/consultation.action";
import * as asyncActions from "../../common/store/actions/async.action";
import * as examActions from '../../common/store/actions/registerExams'

import Loading from "../../common/loading";
import { format } from "date-fns/esm";
import { Prompt } from "react-router-dom";
import { handlePatientName } from "../../common/handlePatientName";

const useStyles = makeStyles({
  screen: {
    margin: "0 30px",
    display: "grid",
    grid: "12vh auto / 0.8fr 3fr",
    gridTemplateAreas: `"header header" "records file"`,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #E7E7ED",
    gridArea: "header",
    maxHeight: '70px'
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  records: {
    gridArea: "records",
    borderRight: "1px solid #E7E7ED",
    height: 'calc(100vh - 172px)',
    overflowY: 'auto',
  },
  record: {
    width: "100%",
    margin: "3% 0",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    alignItems: "flex-start",
  },
  selected: {
    backgroundColor: "#F7F8F9",
  },
  timeStamp: {
    color: "#6B6C7E",
    fontSize: "12px",
    marginBottom: "5px",
  },
  speciality: {
    fontSize: "14px",
    textAlign: "left",
    lineHeight: "17px",
  },
  live: {
    backgroundColor: "#FFF8DE",
    color: "#DF9000",
    borderRadius: "12px",
    width: "30%",
    fontSize: "10px",
  },
  medicalAppointmentReturn: {
    backgroundColor: "transparent",
    border: "1px solid #DF9000",
    color: "#DF9000",
    borderRadius: "30px",
    width: "25%",
    fontSize: "10px",
  },
});

const PatientFile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const history = useHistory()
  const medicalAppointmentId = window.localStorage.getItem("appointId");
  const patientId = window.localStorage.getItem("patientId");
  const appointmentLocation = window.localStorage.getItem('appointmentLocation')
  const doctor = JSON.parse(window.localStorage.getItem("docData"))
  const doctorId = doctor?.doctorId

  const medicalHistory = useSelector(
    (state) => state.ConsultationReducer.medicalHistory
  );
  const openTriageModal = useSelector((state) => state.TriageReducer.open);
  const openFinishModal = useSelector(
    (state) => state.ConsultationReducer.openFinish
  );
  const openDispensePatientModal = useSelector(
    (state) => state.ConsultationReducer.openDispensePatient
  );
  const openConsentModal = useSelector(
    (state) => state.ConsultationReducer.openConsent
  );
  const live = useSelector((state) => state.ConsultationReducer.live);
  const consultationIsRunning = useSelector((state) => state.ConsultationReducer.consultationIsRunning)
  const statusConsultation = useSelector((state) => state.ConsultationReducer.statusConsultation);
  const data = useSelector((state) => state.ConsultationReducer);
  const { consultationData } = data

  const patientData = useSelector((state) => state.ConsultationReducer.data);

  const [loading, setLoading] = useState(false);
  const [patientName, setPatientName] = useState("");
  const [category, setCategory] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [patientAuthorizesMedicalRecord, setPatientAuthorizesMedicalRecord] = useState(false);

  const [patientHistory, setPatientHistory] = useState([]);
  const [isReturnConsultation, setIsReturnConsultation] = useState(false);
  const [Room, setRoom] = useState(null);

  useEffect(() => {
    if (!patientData) {
      history.push('/atendimento')
    } else {
      dispatch(asyncActions.getPatientHistory(patientId));
      setLoading(true);
      if (patientData.patient) {
        setPatientName(handlePatientName(patientData.patient, true));
        setLoading(false);
      } else {
        dispatch(asyncActions.getPatientsList({ medicalAppointmentId }));
      }
    }

    //eslint-disable-next-line
  }, [patientData]);

  useEffect(() => {
    if (medicalHistory.length) {
      const medicalHistoryWithNowStamp = medicalHistory.map((data) => ({ ...data, isNow: data.id === medicalAppointmentId }));

      const statusToFilter = ['FINISHED']
      const excludeFutureAppointments = medicalHistoryWithNowStamp.filter(
        (item) => statusToFilter.includes(item.status) || (item.id !== medicalAppointmentId && timeSpanISO(item.dateTimeBeginAppointmentUtc) > 0)
      );

      const currentConsultation = medicalHistoryWithNowStamp.filter((medicalHistory) => medicalAppointmentId === medicalHistory.id)[0]

      // Salva se o paciente autorizou ou não o médico visualizar seu histórico
      setPatientAuthorizesMedicalRecord(Boolean(currentConsultation?.patientAuthorizesMedicalRecord))

      currentConsultation && excludeFutureAppointments.unshift(currentConsultation)

      setPatientHistory(excludeFutureAppointments);

      if (excludeFutureAppointments[0]) setIsReturnConsultation(excludeFutureAppointments[0].medicalAppointmentReturnGenerated !== null);
    }

  }, [medicalHistory, medicalAppointmentId]);

  const handleTriageData = () => {
    const triage = patientData?.triage

    return !!triage ? { triage: triage }
      : {
        triage: {
          symptoms: patientData?.symptoms,
          drugs: patientData?.drugs,
          allergies: patientData?.allergies,
          diseases: patientData?.diseases,
          attachmentHasTriage: patientData?.attachmentHasTriage
        }
      }
  }
  const triageData = handleTriageData()
  const whatFileToRender = () => {
    if (patientHistory.length > 0) {
      if (
        (patientHistory[selectedRecord].isNow && patientHistory[selectedRecord].id === medicalAppointmentId)
      ) {
        return (
          <Consultation
            records={patientHistory}
            selectedRecord={selectedRecord}
            Room={Room}
            setRoom={setRoom}
            appointmentLocation={appointmentLocation}
          />
        );
      }
      return (
        <FullFile records={patientHistory} selectedRecord={selectedRecord} />
      );
    } else {
      return <Loading width="80px" />;
    }
  };

  const listItems = [{ text: "Histórico" }];

  useEffect(() => {
    if (consultationIsRunning) {
      return () => {
        localStorage.removeItem('appointmentLocation')
        localStorage.removeItem('patientId')
        localStorage.removeItem('twilio')
        localStorage.removeItem('appointId')
        dispatch(
          asyncActions.finishConsultation({
            medicalAppointmentId,
            ...consultationData,
            isNecessaryReloadPatientList: true
          })
        );
        dispatch(consultationActions.consultationIsRunning(false))
        dispatch(consultationActions.weAreLive(false))

      }
    }
    // eslint-disable-next-line
  }, [consultationIsRunning])

  useEffect(() => {
    dispatch(consultationActions.requestSuccess(false))
    consultationActions.resetCountersPrescription()
    dispatch(consultationActions.clearConsultationData())
    dispatch(examActions.deleteAllExams())
    // eslint-disable-next-line
  }, [])

  const whatModalToRender = () => {
    if (openTriageModal) return <TriageModal justToCheck consultation={triageData} />;
    if (openDispensePatientModal)
      return (
        <DispensePatientFromVideoCallModal
          isReturnConsultation={isReturnConsultation}
          Room={Room}
        />
      );
    if (openFinishModal) return <FinishConsultationModal isReturnConsultation={isReturnConsultation} />;
    if (openConsentModal) return <ConsentTermModal />;
  };

  if (loading) return <Loading width="80px" />;


  return (
    <div className={classes.screen}>
      <Prompt
        when={consultationIsRunning && statusConsultation !== "finished"}
        message={
          "Você tem certeza que quer sair dessa página? Isso encerrará a consulta atual."
        }
      />
      <SnackBar />
      <div className={classes.actions}>
        <div className={classes.title}>
          <Topic marginTop="0" title={patientName} />
        </div>
        <div className={classes.buttons}>
          <Button
            width="220px"
            heigth="32px"
            border="none"
            backgroundColor="#FFFFFF"
            color="#DF9000"
            margin="0 20px 0 0"
            fontSize="16px"
            fontWeight="600"
            padding="6px 12px"
            onClick={() => dispatch(consultationActions.openConsentModal(true))}
          >
            Termo de Consentimento{" "}
          </Button>
          {appointmentLocation === "ONLINE" && (
            <Button
              width="160px"
              heigth="32px"
              border="1px solid #CDCED9"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 20px 0 0"
              fontSize="14px"
              fontWeight="400"
              padding="6px 12px"
              onClick={() => dispatch(triageActions.openModal(true))}
            >
              Ver Triagem
            </Button>
          )
          }

          {live && (
            <Button
              width="180px"
              heigth="32px"
              border="1px solid #DF9000"
              backgroundColor="#FFFFFF"
              color="#DF9000"
              fontSize="14px"
              fontWeight="400"
              padding="6px 12px"
              onClick={() =>
                dispatch(consultationActions.openDispensePatientModal(true))
              }
            >
              Encerrar video chamada
            </Button>
          )}

          {!live && (
            <Button
              width="180px"
              heigth="32px"
              border="1px solid #dc3545"
              backgroundColor="#FFFFFF"
              color="#dc3545"
              fontSize="14px"
              fontWeight="400"
              padding="6px 12px"
              onClick={() =>
                dispatch(consultationActions.openFinishModal(true))
              }
            >
              Concluir Consulta{" "}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.records}>
        <HorizontalList
          width="100%"
          width0="35%"
          width1="65%"
          listItems={listItems}
          setSelectedList={setCategory}
          selectedList={category}
        />
        {patientHistory.map((record, index) => {
          const doctorOwner = doctorId === record.provider?.doctor?.id
          const parsedDate = parseISO(record.dateTimeBeginAppointmentUtc);
          const formatedDate = format(parsedDate, "dd/MM/yyyy - HH:mm");
          return (
            <div
              key={index}
              className={index === selectedRecord ? classes.selected : null}
            >
              <Button
                width="100%"
                backgroundColor="transparent"
                borderBottom="1px solid #E7E7ED"
                borderRadius="0px"
                onClick={() => setSelectedRecord(index)}
                // TODO: Aqui desativa ver histórico se o paciente não liberou isso
                disabled={live || (!patientAuthorizesMedicalRecord && !doctorOwner) }
              >
                <div className={classes.record}>
                  <span className={classes.timeStamp}>{formatedDate}</span>
                  <span className={classes.speciality}>
                    {record.speciality.name}
                  </span>
                </div>
                {record.id === medicalAppointmentId ? (
                  <div className={classes.live}>
                    <span>Agora</span>
                  </div>
                ) : null}
                {Boolean(record.medicalAppointmentReturnGenerated) ? (
                  <div className={classes.medicalAppointmentReturn}>
                    <span>Retorno</span>
                  </div>
                ) : null}
              </Button>
            </div>
          );
        })}
      </div>
      {whatFileToRender()}
      {whatModalToRender()}
    </div>
  );
};

export default PatientFile;
