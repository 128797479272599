import React from 'react';
import { Document, Page, Image, Text, Font } from '@react-pdf/renderer';

import { AntimicrobialRecipe } from './models/antimicrobialRecipe'
import { Certificate } from './models/certificate'
import { ExamRequest } from './models/examRequest'
import { MedicalReport } from './models/medicalReport'
import { SimpleRecipe } from './models/simpleRecipe'
import { SpecialControlRecipe } from './models/specialControlRecipe'


import { styles } from './styles'
import vidaLogo from '../../assets/Logo-VC.png';


// Create Document Component
export const RootPdf = ({documentPayload}) => {

    const { title } = documentPayload

    const printPage = (pageNumber, totalPages) => {
      if(totalPages > 1) {
        return `Página ${pageNumber} de ${totalPages}`
      }
    }

    Font.registerHyphenationCallback(word => {
        //Return entire word as unique part
        return [word];
    });
    return(
        <Document>
            <Page size="A4" style={styles.body} >
                <Image 
                    style={ styles.image } 
                    src={ vidaLogo }
                    fixed
                />
                
                <Text style={ styles.title }>
                    { title }
                </Text>

                { title === 'Atestado Médico' &&
                    <Certificate documentPayload={ documentPayload }/>
                }

                { title === 'Prescrição' &&
                    <SimpleRecipe documentPayload={ documentPayload }/>
                }

                { title === 'Receituário antimicrobianos' && 
                    <AntimicrobialRecipe documentPayload={ documentPayload }/> 
                }
                
                { title === 'Receituário de controle especial' && 
                    <SpecialControlRecipe documentPayload={ documentPayload }/>
                }

                { title === 'Solicitação de Exames' &&
                    <ExamRequest documentPayload={ documentPayload }/>
                }

                { title === 'Relatório Médico' &&
                    <MedicalReport documentPayload={ documentPayload }/>
                }

                <Text 
                  style={styles.pageNumber} 
                  //render={({ pageNumber, totalPages }) => {(`Página ${pageNumber} de ${totalPages}`)}} 
                  render={ ({ pageNumber, totalPages }) => printPage(pageNumber, totalPages) } 
                  fixed 
                />
            </Page>
        </Document>
    )
}