import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { differenceInDays, addDays, startOfDay, eachDayOfInterval, compareAsc } from 'date-fns';

import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import DatePicker from '../../../../common/datepicker';
import Topic from "../../../../common/topic";
import Button from '../../../../common/button';

import "../style.css";

import * as scheduleActions from "../../../../common/store/actions/schedule.action";



const useStyles = makeStyles( {
  root: {
    width: '45%'
  },
  header: {
    display:'flex', 
    justifyContent:'space-between', 
    alignItems:"center",
    backgroundColor: props => props.backgroundColor,
    borderBottom: '1px solid #E7E7ED',
  },
  extraMargin: {
    margin: '50px 0 21px 0',
    padding: 0
  },
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  },
  lettering2: {
    color: "#393A4A",
  },
  buttonBg: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F7F8F9',
    display: 'flex',
    justifyContent: "flex-end",
    padding: '22px',
    marginTop: '28px',
    boxSizing: 'border-box',
  },
  error: {
    display: 'block',
    fontSize: '12px',
    color: "#DA1414",
    paddingTop: '30px',
  }
})

const ModalCreateSchedule = ({getDays, ...props}) => {
    const dispatch = useDispatch();
    const startDate = useSelector((state) => state.ScheduleReducer.beginSchedule);
    const endDate = useSelector((state) => state.ScheduleReducer.endSchedule);
    const today = startOfDay(new Date());
    const tomorrow = startOfDay(addDays(new Date(), 1));

    const [startSelectedDate, setStartSelectedDate] = useState(startDate || today);
    const [endSelectedDate, setEndSelectedDate] = useState(endDate || tomorrow);
    const [intervalError, setError] = useState("");
    

    const classes = useStyles();

    useEffect(()=>{
      const startDateGreaterThanEndDate = compareAsc(startSelectedDate, endSelectedDate) === 1
      if (!startDateGreaterThanEndDate) {
        const weekDaysAvailable = eachDayOfInterval({start: startSelectedDate, end: endSelectedDate});
        getDays(weekDaysAvailable);
      }
      // eslint-disable-next-line
    },[startSelectedDate, endSelectedDate]);
    

    

    const onSaveAndNext = () => {
      const startDateGreaterThanEndDate = compareAsc(startSelectedDate, endSelectedDate) === 1
      if (startDateGreaterThanEndDate) {
        setError("A data de inicio não pode ser maior que a data final.");
        return
      }
      const isPast = differenceInDays(startSelectedDate, today) < 0 ;
      const deltaT = differenceInDays(endSelectedDate, startSelectedDate);
      if(isPast) {
        setError("Não se pode cadastrar agendas no passado. Verifique os dias selecionados.");
        return
      }
      if(deltaT <= 0 ) {
        setError("O intervalo da agenda precisa ser de pelo menos um dia.");
        return
      }
      dispatch(scheduleActions.addTimeSpan({startSelectedDate, endSelectedDate}));
      props.handleNext();
    }

    

    return (
      <>
        <div className={classes.header}>
          <DialogTitle>
            Criar Agenda
          </DialogTitle>
          <IconButton aria-label="close" onClick={() => props.handleClose()}>
            <CloseIcon />
          </IconButton>
          </div>
          <DialogContent>
          <form>
            <div className="modal-body">
              <Topic
                title="Deseja liberar vagas em qual período de data?"
                fontSize="16px"
                marginTop="20px"
              />
              <div>
                <Topic
                  title="De:"
                  fontSize="14px"
                  marginTop="17px"
                />
                <DatePicker 
                  value={startSelectedDate}
                  onChange={setStartSelectedDate}
                  minDate={new Date()}
                  disablePast
                  animateYearScrolling
                />
              </div>
              <div>
                <Topic
                  title="Até:"
                  fontSize="14px"
                  marginTop="17px"
                />
                <DatePicker 
                  value={endSelectedDate}
                  onChange={setEndSelectedDate}
                  minDate={new Date()}
                  disablePast
                  animateYearScrolling
                />
              </div>
            </div>
          </form>
          {intervalError && <span className={classes.error}>{intervalError}</span>}
          </DialogContent>
        <div className={classes.buttonBg}>
            <Button 
              onClick={() => props.handleClose()}
              width="120px"
              border="1px solid #CDCED9"
              backgroundColor="#FFFFFF"
              color="#6B6C7E"
              margin="0 20px 0 0"
            >
              Cancelar
            </Button>
            <Button 
              onClick={onSaveAndNext}
              width="120px"
              border="1px solid #DF9000"
              backgroundColor="#FFFFFF"
              color="#DF9000"
            >
              Próximo
            </Button>
        </div>
      </>
  );
};

export default ModalCreateSchedule;
