import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import empty from "../../../common/validators/empty";
import Button from "../../../common/button";
import RadioButtons from "../../../common/radioButton/radioButton";
import Select from "../../../common/select";
import Check from "../../../assets/check-circle-full.png";
import Loading from "../../../common/loading";

import * as modalActions from "../../../common/store/actions/consultation.action";
import * as asyncActions from "../../../common/store/actions/async.action";
import * as startConsultationActions from "../../../common/store/actions/consultation.action";
import * as examsActions from "../../../common/store/actions/registerExams";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Grow } from "@material-ui/core";

import { gql, useApolloClient } from '@apollo/client'


// Material UI Styling

const useStyles = makeStyles({
  root: {
    width: (props) => (props.width ? props.width : "40%"),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  topic: {
    marginBottom: "30px",
    fontSize: "16px",
    "& > .title": {
      fontWeight: "600",
    },
    "& > p": {
      lineHeight: "20px",
      color: "#6B6C7E",
      marginTop: "5px",
    },
  },
  content: {
    "& > div": {
      width: "100%",
      marginTop: "5%",
    },
  },
  retorno: {
    "& > div": {
      width: "100%",
      marginTop: "5%",
    },
  },
  success: {
    backgroundColor: "#EDF9F0",
    color: "#287D3C",
    fontWeight: 600,
  },
  extraMargin: {
    margin: "50px 0 21px 0",
    padding: 0,
  },
});

const MUTATION_CREATE_PROCEDURE = gql`
  mutation($input: CreateProcedureRequestInput!) {
    createProcedureRequest(input: $input) {
      id
      budgets {
        id
      }
    } 
  }
`

// Component

const DispensePatientFromVideoCallModal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const medicalAppointmentId = window.localStorage.getItem("appointId");
  const providerId = window.localStorage.getItem("Prov-Vida-Connect");
  const patientId = window.localStorage.getItem("patientId");
  const appointmentLocation = window.localStorage.getItem('appointmentLocation')
  const isSuccess = useSelector((state) => state.ConsultationReducer.success);
  const data = useSelector((state) => state.ConsultationReducer);
  const error = useSelector((state) => state.AsyncReducer.error);
  const { consultationData } = data;
  const open = data.openFinish;
  const classes = useStyles(props);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [needReturn, setNeedReturn] = useState("Não");
  const [returnType, setReturnType] = useState("");
  const [returnTypeError, setReturnTypeError] = useState("");

  const [returnMax, setReturnMax] = useState("");
  const [returnMaxError, setReturnMaxError] = useState("");

  const api = useApolloClient()

  const createProcedure = (data) => api.mutate({ mutation: MUTATION_CREATE_PROCEDURE, variables: { input: data } })

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  const handleClose = () => {
    dispatch(modalActions.openFinishModal(false));
    dispatch(startConsultationActions.requestSuccess(false));
    if (isSuccess) {
      localStorage.removeItem('appointmentLocation')
      localStorage.removeItem('patientId')
      localStorage.removeItem('twilio')
      localStorage.removeItem('appointId')
      dispatch(startConsultationActions.consultationIsRunning(false))
      history.push("/atendimento");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSuccess(isSuccess);
      setLoading(false);
    }
  }, [isSuccess]);

  const handleSurgicalProcedures = () => new Promise(async (resolve, reject) => {
    const surgicalProcedures = data.surgicalProcedure.map(surgicalProcedure => {
      console.log(surgicalProcedure)
      const hospitalBudget = surgicalProcedure.surgicalProcedure.budget.hospitalBudget
      const medicalTeamBudget = surgicalProcedure.surgicalProcedure.budget.medicalTeamBudget

      const helper = (medicalTeamBudget?.helper && Array.isArray(medicalTeamBudget?.helper)) ? medicalTeamBudget?.helper.filter(i => i.notFound === false) : []

      return {
        hospital_has_opme_or_aditional_materials: hospitalBudget.hasOpmeOrAditionalMaterials,
        hospital_opme: !hospitalBudget.hasOpmeOrAditionalMaterials ? null : hospitalBudget.opmeOrAditionalMaterials.opme,
        hospital_aditional_materials: !hospitalBudget.hasOpmeOrAditionalMaterials ? null : hospitalBudget.opmeOrAditionalMaterials.aditionalMaterials,
        hospital_special_equipment: !Boolean(hospitalBudget.specialEquipment) ? null : hospitalBudget.specialEquipment,
        hospital_daily_quantities: hospitalBudget.dailyQuantities,
        hospital_has_hospitalization: hospitalBudget.hasHospitalization,
        hospital_hospitalization_quantities: !hospitalBudget.hasHospitalization ? null : hospitalBudget.hospitalizationQuantities,
        hospital_has_special_medications: hospitalBudget.hasSpecialMedications,
        hospital_special_medications: !hospitalBudget.hasSpecialMedications ? null : hospitalBudget.specialMedications,
        hospital_has_blood_products: hospitalBudget.hasBloodProducts,
        hospital_blood_products: !hospitalBudget.hasBloodProducts ? null : hospitalBudget.bloodProducts,
        providerPrice: medicalTeamBudget?.price || null,
        helpers: helper.map(h => ({ providerId: h.id, price: h.price })),
        helpersPrice: helper.length === 0 ? 0 : (helper.length > 1 ? helper : [...helper, { price: 0 }]).reduce((current, accumulator) => current.price + accumulator.price),
        providerId: surgicalProcedure.providerId,
        serviceProvidedId: surgicalProcedure.surgicalProcedure.id,
        establishments: (hospitalBudget?.hospitalsSelected || []).map(hospital => ({ establishmentId: hospital.establishmentId, anesthetist: hospital.anesthetist })),
        medicalAppointmentId: surgicalProcedure.medicalAppointmentId,
        finish: surgicalProcedure.surgicalProcedure.budget.finish
      }
    })

    if (Array.isArray(surgicalProcedures) && surgicalProcedures.length > 0) {
      for (const surgicalProcedure of surgicalProcedures) {
        try {
          const request = await createProcedure(surgicalProcedure)
          if (request.errors || request.error) {
            reject()
            break
          }
        } catch (err) {
          reject()
          break
        }
      }
    }
    resolve()
  })

  const handleFinish = async () => {
    setLoading(true);

    try {
      await handleSurgicalProcedures()
      dispatch(startConsultationActions.clearSurgicalProcedure())
    } catch (err) {
      alert('Erro ao enviar procedimentos')
      setSuccess(false);
      setLoading(false);
      return
    }

    if (appointmentLocation === "PRESENTIAL") {
      let error = false;
      if (needReturn === "Sim") {
        error = [returnType, returnMax].reduce(
          (error, field) => error || Boolean(empty(field).typeError),
          false
        );
      }
      if (error) {
        setLoading(false);
        if (!returnType)
          setReturnTypeError({ typeError: "Esse campo é obrigatório" });
        if (!returnMax)
          setReturnMaxError({ typeError: "Esse campo é obrigatório" });
      } else {
        setLoading(true);
        if (needReturn === "Sim") {
          const returnTypeMap = {
            PRESENCIAL_OU_ONLINE: "PRESENTIAL_AND_ONLINE",
            ONLINE: "ONLINE",
            PRESENTIAL: "PRESENTIAL",
          };
          dispatch(
            asyncActions.createMedicalAppointmentReturn({
              patientId,
              providerId,
              medicalAppointmentId,
              careModality: returnTypeMap[returnType],
              expirationDate: returnMax,
            })
          );
        }
        dispatch(
          asyncActions.finishConsultation({
            medicalAppointmentId,
            ...consultationData,
          })
        );
        dispatch(startConsultationActions.updateStatusConsultation('finished'))
      }
    } else {
      dispatch(
        asyncActions.finishConsultation({
          medicalAppointmentId,
          ...consultationData,
        })
      );
      dispatch(startConsultationActions.updateStatusConsultation('finished'))
      
    }
    dispatch(examsActions.deleteAllExams())
    dispatch(startConsultationActions.resetCountersPrescription());
  };
  
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.root,
        }}
        TransitionComponent={Grow}
        transitionDuration={{ enter: 200, exit: 0 }}
      >
        <div
          className={`${classes.header} ${success ? classes.success : null}`}
        >
          <DialogTitle>
            {success ? (
              <div>
                <img src={Check} alt="success" />
                <span>Consulta Finalizada com sucesso!</span>
              </div>
            ) : (
              <span>Atenção!</span>
            )}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          <form>
            <div className={classes.content}>
              {!props.isReturnConsultation && appointmentLocation === "PRESENTIAL" && !success && (
                <div className={classes.retorno}>
                  <div>
                    <RadioButtons
                      label="Precisa de retorno?"
                      width="30%"
                      margin="0px"
                      value={needReturn}
                      onChange={(e) => setNeedReturn(e.target.value)}
                    />
                  </div>
                  {needReturn === "Sim" && (
                    <>
                      <div>
                        <Select
                          label="O retorno pode ser:"
                          onChange={setReturnType}
                          onBlur={setReturnTypeError}
                          error={returnTypeError.typeError}
                          value={returnType}
                          itemList={[
                            { value: "ONLINE", exhibit: "Telemedicina" },
                            { value: "PRESENTIAL", exhibit: "Presencial" },
                          ]}
                        />
                      </div>
                      <div>
                        <Select
                          label="O retorno deverá ser feito em até:"
                          onChange={setReturnMax}
                          onBlur={setReturnMaxError}
                          error={returnMaxError.typeError}
                          value={returnMax}
                          itemList={[
                            { value: 15, exhibit: "15 dias" },
                            { value: 30, exhibit: "30 dias" },
                            { value: 45, exhibit: "45 dias" },
                            { value: 60, exhibit: "60 dias" },
                          ]}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              <div>
                {success ? (
                  <p>Consulta finalizada!</p>
                ) : (
                  <>
                    <p>
                      Ao finalizar a consulta, não será mais possível editar o
                      histórico clínico do paciente.
                    </p>
                    <p> Deseja realmente finalizar?</p>
                  </>
                )}
              </div>
            </div>
            <DialogActions className={classes.extraMargin}>
              {loading ? (
                <Loading width="30px" />
              ) : success ? (

                <Button
                  width="120px"
                  border="1px solid #CDCED9"
                  backgroundColor="#FFFFFF"
                  color="#6B6C7E"
                  margin="0 20px 0 0"
                  onClick={handleClose}
                >
                  OK
                </Button>

              ) : (
                <>
                  <Button
                    onClick={handleClose}
                    width="120px"
                    border="1px solid #CDCED9"
                    backgroundColor="#FFFFFF"
                    color="#6B6C7E"
                    margin="0 20px 0 0"
                  >
                    Não
                  </Button>

                  <Button
                    width="120px"
                    border="1px solid #DF9000"
                    backgroundColor="#FFFFFF"
                    color="#DF9000"
                    onClick={handleFinish}
                  >
                    Sim
                  </Button>
                </>
              )}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DispensePatientFromVideoCallModal;
