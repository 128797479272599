import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import ModalBase from '../modalBaseWithouFlow';

import empty from "../../validators/empty";
import Input from "../../input";
import TextArea from '../../textarea';
import InputCurrency from '../../inputCurrency';

import DialogContentText from '@material-ui/core/DialogContentText'
import { makeStyles } from '@material-ui/core/styles';

import "../style.css";
import * as exam from "../../store/actions/registerExams";

import Select from '../../select';
import SnackBar from '../../snackbar';


import * as examsActions from "../../store/actions/registerExams";
import * as asyncActions from '../../store/actions/async.action';

const useStyles = makeStyles({
  lettering: {
    color: '#686868',
    fontSize: '14px',
    marginTop: '16px'
  }
})

const ModalUpdateExam = (props) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  useEffect(() => {
    dispatch(asyncActions.getExamFee());
    //eslint-disable-next-line
  }, [])

  const taxExamFee = useSelector((state) => state.Exams.taxExamFee);

  const [examId, setExamId] = useState("");

  const [preparation, setPreparation] = useState("");
  const [preparationError, setPreparationError] = useState("");

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");

  const [examCode, setExamCode] = useState("");
  const [examCodeError, setExamCodeError] = useState("");

  const [name, setName] = useState("");

  const openModal = useSelector((state) => state.Exams.openModal);
  const activeExam = useSelector((state) => state.Exams.active)
  let examData = useSelector((state) => state.Exams.exams);
    
  useEffect(() => {
    if (examData.length > 0 && activeExam <= examData.length - 1) {
      const availableData = examData[activeExam];

      setExamCode(availableData.examCode);
      setName(availableData.name);
      setPreparation(availableData.preparation);
      setPrice(availableData.price);
      setExamId(availableData.examId)

    }
  }, [activeExam, examData])

  function handleSubmit(e) {
    e.preventDefault();
    if (isNaN(price) || empty(price).typeError || empty(examCode).typeError || empty(preparation).typeError) {
      setExamCodeError(empty(examCode).typeError);
      setPriceError(empty(''));
      setPreparationError(empty(preparation));
      
      return
    }

    const payload = { examCode, name, preparation, needToSchedule: true, price, examId };
    
 
      dispatch(exam.updateExam(activeExam, payload));
 

    dispatch(exam.openModal(false));
    dispatch(exam.alterActive(examData.length + 1));
    setExamCode("")
    setName("");
    setPreparation("");
    setPrice(0);
    setExamCodeError("")
    setPriceError("")
    setPreparationError("")

    return true;
  }

  const handleClose = () => {

    setExamCode("")
    setName("");
    setPreparation("");
    setPrice("");
    setExamCodeError("")
    setPriceError("")
    setPreparationError("")
    dispatch(examsActions.openModal(false));
  };

  return (
    <ModalBase title="Atualizar Exame" buttonWidth={props.buttonWidth} buttonText={props.buttonText} handleSubmit={handleSubmit} open={openModal} onClose={handleClose}>
      <Select
        label="Tabela"
        InputProps={{ readOnly: true }}
        value={'TUSS'}
        itemList={[
          { value: "TUSS", exhibit: "TUSS" },
        ]}
      />

          <Input
                label="Nome do procedimento"
                placeholder="Nome do procedimento"
                value={name}
                InputProps={{readOnly: true}}                            
                border= "none"
                backgroundColor="#f7f8f9"
                dataTest="modal-cadastrar-exame-input-descricao"
              />

      <Input
        width="100%"
        label="Código do exame"
        value={examCode}        
        error={examCodeError}
        InputProps={{ readOnly: true }}
      />
      <InputCurrency
        width="100%"
        label="Valor em reais (R$)"
        value={price}
        onChange={setPrice}
        error={priceError.typeError}
        styles={{
          fontSize: '12px',
          marginTop: '3px',
          color: "#DA1414"
        }}
        dataTest="modal-cadastrar-consulta-input-preco"
      />
      <DialogContentText className={classes.lettering}>
        O valor cobrado pela plataforma será de: R$ {((taxExamFee / 100) * price).toFixed(2)}
      </DialogContentText>

      <TextArea
        width="93%"
        label="Preparo para o exame"
        placeholder="Instruções para o preparo"
        rows={8}
        value={preparation}
        onChange={setPreparation}
        onBlur={() => setPreparationError(empty(preparation).typeError)}
        error={preparationError.typeError}
        max={250}
      />

      <SnackBar />




    </ModalBase>
  );
};

export default ModalUpdateExam;
