export function openModal(payload) {
    return {
        type: "OPEN_TRIAGE_MODAL",
        payload
    }
}

export function isSuccess(payload) {
    return {
        type: "IS_SUCCESS",
        payload
    }
}

export function waitingResults(payload) {
    return {
        type: "WAITING_TRIAGE_APPROVAL",
        payload
    }
}
