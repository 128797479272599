import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EditMenu from '../editMenu';

const useStyles = makeStyles((theme) => ({
  root: {
        width: '100%',
        padding: '0'
  },
  item: {
        height: props => props.height ? props.height : '64px',
        backgroundColor: props => props.backgroundColor ? props.backgroundColor : "#FFFFFF",
        padding: props => props.padding? props.padding : '0 30px',
        border: props => props.border ? props.border : '1px solid #E7E7ED',
  },
  icons: {
    display: 'flex',
  },
  icon: {
      width: '80px',
  }
}));

const ListDisplay = (props) => {

  const classes = useStyles(props);
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <List className={classes.root}>
      {props.fullList.map((value, index) => {
        const labelId = `checkbox-list-label-${index}-${props.parameterToExhibit ? value[props.parameterToExhibit] : value}`;
        return (
          <ListItem className={classes.item} key={labelId} role={undefined} dense button onClick={handleToggle(value)}>
            <ListItemText 
              id={labelId} 
              primary={
                `${props.parameterToExhibit ? value[props.parameterToExhibit] : value}
                ${value[props.secondParam] ? `, ${value[props.secondParam]}` : ""}
                ${value[props.thirdParam] ? `, ${value[props.thirdParam]}` : ""}
                `
              } 
            />
            {props.noEdit ?
              null
              :
              <div className={classes.icons}>
                <IconButton className={classes.icon} edge="end" aria-label="delete" onClick={() => props.deleteItem(index)}>
                  <DeleteIcon />
                </IconButton>
                {!props.notEditable && <EditMenu
                  items={props.menuItems}
                  index={index}
                />}
              </div>
            }
          </ListItem>
        );
      })}
    </List>
  );
}

export default ListDisplay;