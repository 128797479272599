import React, { useState } from 'react';
// import {useDispatch} from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
// import AddIcon from '@material-ui/icons/Add';

import AddConsultation from '../modals/addConsultation';
import Topic from '../../../../common/topic';
import List from './consultationList';
import Pagination from '../../../../common/pagination';
import SnackBar from '../../../../common/snackbar';
import Button from '../../../../common/button';

// import * as consultationActions from '../../../../common/store/actions/registerConsultations';



const useStyles = makeStyles({
    screen: {
        backgroundColor: '#f7f8f9',
        height: 'calc(100vh - 70px)',
        maxHeight: "100%",
        width: "100%",
        padding: '30px',
        boxSizing: 'border-box',
    },
    table:{
        display: "flex",
        justifyContent: "space-between",
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
    pagination: {
        position: "absolute",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
    pageSelection: {
        width: "40%",
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "center",
    },
    items: {
        marginLeft: "50px",
    },
    scroll: {
        maxHeight: "calc(77vh - 72px)",
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CDCED9',
            outline: '1px solid slategrey',
            borderRadius: '5px'
        }
    }
})

const ListWithPagination =({selectedUnit, setSelectedUnit, modal, deleteItem, list, menuItem, firstHeader, firstParam, secondParam}) => {
    const classes = useStyles();
    // const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const firstItem = (page*itemsPerPage)-(itemsPerPage);
    const lastItem = (firstItem + itemsPerPage);

    // const handleAddSpeciality = () => {
    //     dispatch(consultationActions.openAddModal(true));
    // }

    return (
        <div className={classes.screen}>
            <div className={classes.table}>
                <div className={classes.title}>
                    <Button
                        startIcon={<NavigateBeforeIcon/>}
                        width="91px"
                        heigth="32px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                        margin="0 20px 0 0"
                        fontSize="14px"
                        fontWeight="400"
                        padding="6px 12px"
                        onClick={() => setSelectedUnit(null)}
                    >
                        Voltar
                    </Button>
                    <Topic
                        marginTop="0"
                        title={selectedUnit ? selectedUnit.address : ""}
                    />
                </div>
                <div>
                    {/* <Button
                        startIcon={<AddIcon/>}
                        width="250px"
                        heigth="32px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                        margin="0 20px 0 0"
                        fontSize="14px"
                        fontWeight="400"
                        padding="6px 12px"
                        onClick={handleAddSpeciality}
                    >
                        Adicionar Especialidade
                    </Button> */}
                    <AddConsultation />
                </div>
                {modal}
            </div>
            <div className={`service-register ${classes.scroll}`}>
                <div>
                    <List 
                        fullList={list.slice(firstItem, lastItem)}
                        deleteItem={deleteItem}
                        menuItems={menuItem}
                        firstColumnWidth="100%"
                        firstHeader={firstHeader}
                        firstParam={firstParam}
                        secondHeader=""
                        secondParam={secondParam ? secondParam : ""}
                        iconsWidth
                        thirdHeader=""
                        thirdParam=""
                        forthHeader=""
                        forthParam=""
                        fifthHeader=""
                        fifthParam=""
                    />
                </div>
            </div>
            <Pagination 
                page={page}
                setPage={setPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                firstItem={firstItem}
                lastItem={lastItem}
                length={list.length}
            />
            <SnackBar />
        </div>
    );
}

export default ListWithPagination;
