import React from 'react'
import { Text, View } from '@react-pdf/renderer';

import { DispensedProduct, Interchangeability, ManualDispensation } from './specialFields'
import { DoctorData } from './doctorData'
import { styles } from '../styles'

const Dcb = ({
                substancePrecribed,
                doseOrConcentration,
                pharmaceuticalForm,
                admnistrationRoute,
                dosage,
                quantity,
                treatmentDuration,
                obs
            }) => {
    return (
        <View style={[styles.bordered, styles.text, {lineHeight: 1.2}]} wrap={false}>
            <View style={styles.margined}>
                <Text style={{minHeight: 25}}>Nome do Medicamento ou da Substância Prescrita(DCB): {substancePrecribed}</Text>
                <Text>Dose ou Concentração: {doseOrConcentration}</Text>
                <View style={styles.flexRow}>
                    <Text style={{width: '60%'}}>Forma Farmacêutica: {pharmaceuticalForm}</Text>
                    <Text style={{width: '40%'}}>Via de Administração: {admnistrationRoute}</Text>
                </View>
                <Text>Posologia: {dosage}</Text>
                <View style={styles.flexRow}>
                    <Text style={{width: '50%'}}>Quantidade: {quantity}</Text>
                    <Text style={{width: '50%'}}>Duração do Tratamento: {treatmentDuration}</Text>
                </View>
                <Text style={{minHeight: 25}}>Obs: {obs}</Text>
            </View>
        </View>
    )
}

export const AntimicrobialRecipe = ({documentPayload}) => {

    const {
        patientName,
        patientCpf,
        patientAge,
        patientGender,
        doctorAddress,
        doctorCity,
        doctorUf,
        doctorTelephone,
        docName,
        crm,
        crmUf,
        emission,

        pharmaceuticalCrf,
        pharmaceuticalUf,
        pharmaceuticalName,
        pharmacyAddress,
        pharmacyCity,
        pharmacyCnpj,
        pharmacyName,
        pharmacyTelephone,
        pharmacyUf,

        substancePrecribed1,
        doseOrConcentration1,
        pharmaceuticalForm1,
        admnistrationRoute1,
        dosage1,
        quantity1,
        treatmentDuration1,
        obs1,
        substancePrecribed2,
        doseOrConcentration2,
        pharmaceuticalForm2,
        admnistrationRoute2,
        dosage2,
        quantity2,
        treatmentDuration2,
        obs2,
        substancePrecribed3,
        doseOrConcentration3,
        pharmaceuticalForm3,
        admnistrationRoute3,
        dosage3,
        quantity3,
        treatmentDuration3,
        obs3,
        medicineName1,
        laboratory1,
        lotNumber1,
        qtyBoxes1,
        numberRegistry1,
        medicineName2,
        laboratory2,
        lotNumber2,
        qtyBoxes2,
        numberRegistry2,
        medicineName3,
        laboratory3,
        lotNumber3,
        qtyBoxes3,
        numberRegistry3,
        medicineIntercambiality1,
        medicineIntercambialitySubstituted1,
        medicineIntercambiality2,
        medicineIntercambialitySubstituted2,
        medicineIntercambiality3,
        medicineIntercambialitySubstituted3,
    } = documentPayload

    const doctorPayload = {
        address: doctorAddress,
        city: doctorCity,
        addressUf: doctorUf,
        phone: doctorTelephone,
        doctorName: docName,
        crm, 
        crmUf,
        emission
    }

    return (
        <View>
            
            <View style={[styles.text]} wrap={false}>
                <Text>Paciente: <Text style={styles.textBold}>{patientName}</Text></Text>
                <Text>CPF: <Text style={styles.textBold}>{patientCpf}</Text></Text>
                <View style={styles.flexRow}>
                    <Text style={{width: '25%'}}>Idade: {patientAge}</Text>
                    <Text style={{width: '75%'}}>Sexo: {patientGender}</Text>
                </View>
            </View>

            <Dcb 
                substancePrecribed={substancePrecribed1}
                doseOrConcentration={doseOrConcentration1}
                pharmaceuticalForm={pharmaceuticalForm1}
                admnistrationRoute={admnistrationRoute1}
                dosage={dosage1}
                quantity={quantity1}
                treatmentDuration={treatmentDuration1}
                obs={obs1}
            />
            <Dcb 
                substancePrecribed={substancePrecribed2}
                doseOrConcentration={doseOrConcentration2}
                pharmaceuticalForm={pharmaceuticalForm2}
                admnistrationRoute={admnistrationRoute2}
                dosage={dosage2}
                quantity={quantity2}
                treatmentDuration={treatmentDuration2}
                obs={obs2}
            />
            <Dcb 
                substancePrecribed={substancePrecribed3}
                doseOrConcentration={doseOrConcentration3}
                pharmaceuticalForm={pharmaceuticalForm3}
                admnistrationRoute={admnistrationRoute3}
                dosage={dosage3}
                quantity={quantity3}
                treatmentDuration={treatmentDuration3}
                obs={obs3}
            />

            <View style={[styles.bordered, styles.smallText, styles.topSeparator]} wrap={false}>
                <Text style={[styles.bordered, styles.textBold, {textAlign: 'center', lineHeight: 1}]}>
                    Identificação do Emitente
                </Text>
                <DoctorData documentPayload={doctorPayload}/>
            </View>

            <View style={[styles.topSeparator, styles.bordered, styles.smallText]} wrap={false}>
                <Text style={[styles.bordered, styles.textBold, {textAlign: 'center', lineHeight: 1}]}>
                    Identificação do Fornecedor
                </Text>
                <View style={[styles.margined]}>
                    <View style={styles.flexRow}>
                        <Text style={{width: '70%'}}>Nome do Farmacêutico: {pharmaceuticalName}</Text>
                        <Text style={{width: '20%'}}>CRF: {pharmaceuticalCrf}</Text>
                        <Text style={{width: '10%'}}>UF: {pharmaceuticalUf}</Text>
                    </View>
                    <View style={styles.flexRow}>
                        <Text style={{width: '70%'}}>Nome da Farmácia: {pharmacyName}</Text>
                        <Text style={{width: '30%'}}>CNPJ: {pharmacyCnpj}</Text>
                    </View>
                    <View style={styles.flexRow}>
                        <View style={{width: '60%'}}>
                            <Text style={{minHeight: 30}}>Endereço: {pharmacyAddress}</Text>
                            <View style={styles.flexRow}>
                                <Text style={{width: '80%'}}>Cidade: {pharmacyCity}</Text>
                                <Text style={{width: '20%'}}>UF: {pharmacyUf}</Text>
                            </View>
                            <Text>Telefone: {pharmacyTelephone}</Text>
                        </View>
                        <View style={{width: '40%'}}>
                            <Text 
                                style={{textAlign: 'center', position: 'absolute', bottom: 0}}
                            >
                                Assinatura do Farmacêutico 
                            </Text>
                        </View>
                    </View>
                    
                    
                </View>
            </View>

            <View style={styles.smallText} wrap={false}>
                <View style={styles.seassonTitle}>
                    <Text style={{margin: 'auto', lineHeight: 1}}>
                        Dados do(s) Produto(s) Dispensado(s)
                    </Text>
                </View>
                <View>
                    <DispensedProduct 
                        medicineName={medicineName1}
                        laboratory={laboratory1}
                        lotNumber={lotNumber1}
                        qtyBoxes={qtyBoxes1}
                        numberRegistry={numberRegistry1}
                    />
                    <DispensedProduct 
                        medicineName={medicineName2}
                        laboratory={laboratory2}
                        lotNumber={lotNumber2}
                        qtyBoxes={qtyBoxes2}
                        numberRegistry={numberRegistry2}
                    />
                    <DispensedProduct 
                        medicineName={medicineName3}
                        laboratory={laboratory3}
                        lotNumber={lotNumber3}
                        qtyBoxes={qtyBoxes3}
                        numberRegistry={numberRegistry3}
                    />
                </View>
            </View>
        
            <View style={styles.smallText} wrap={false}>
                <View style={styles.seassonTitle}>
                    <Text style={{margin: 'auto', lineHeight: 1}}>
                        Informações Sobre Intercambialidade
                    </Text>
                </View>
                <View>
                    <Interchangeability 
                        medicineIntercambiality={medicineIntercambiality1}
                        medicineIntercambialitySubstituted={medicineIntercambialitySubstituted1}
                    />
                    <Interchangeability 
                        medicineIntercambiality={medicineIntercambiality2}
                        medicineIntercambialitySubstituted={medicineIntercambialitySubstituted2}
                    />
                    <Interchangeability 
                        medicineIntercambiality={medicineIntercambiality3}
                        medicineIntercambialitySubstituted={medicineIntercambialitySubstituted3}
                    />
                </View>
            </View>

            <ManualDispensation/>

        </View>
    )
}