import React from "react";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles({
  root: {
    border: "none",
    margin: "12px 0 0 0",
    width: props => props.width,
  },
  inputs: {
    height: props => props.height ? props.height : "40px",
    width: props => props.width ? props.width : "100%",
    borderRadius: props => props.borderRadius ? props.borderRadius : '4px',
    paddingLeft: props => props.paddingLeft ? props.paddingLeft : '7px',
    marginTop: props => props.marginTop ? props.marginTop : '3px',
    display: props => props.display ? props.display : "flex",
    justifyContent: props => props.justifyContent ? props.justifyContent : "flex-start",
    alignItems: props => props.alignItems ? props.alignItems : "center",
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : null,
    fontSize: props => props.fontSize ? props.fontSize : "16px",
    fontWeight: props => props.fontWeight ? props.fontWeight : "400",
    fontFamily: props => props.fontFamily ? props.fontFamily : "Montserrat, sans serif",
    color: props => props.color ? props.color : "#272833",
    "&:focus": {
      outline: props => props.outline ? props.outline : "none",
      borderBottom: props => props.borderBottom ? props.borderBottom : '1px solid #f9b233 !important',
    }
  },
  fullHeight: {
    height: '100%',
    marginTop: "0 !important",
  },
  error: {
    fontSize: '14px',
    marginTop: '7px',
    color: "#DA1414"
  },
  displayFlex: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: "100%"
  },
  readOnly: {
    backgroundColor: "transparent !important",
    border: "none",
  }
});

export default (props) => {
  const classes = useStyles(props);
  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.displayFlex}>
        {props.label &&
          <span>
            <strong className="label-input">{props.label}</strong>
          </span>
        }
        <CurrencyFormat
          className={`${classes.inputs} ${props.readOnly ? classes.readOnly : null}`}
          value={props.value ? props.value : 0}
          thousandSeparator={"."}
          decimalSeparator={","}
          decimalScale={2}
          disabled={props.disabled || false}
          fixedDecimalScale
          isNumericString={true}
          prefix={"R$ "}
          onValueChange={(values) => props.onChange(isNaN(values.floatValue) ? '' : values.floatValue)}
          placeholder="R$ 0,00"
          data-test={props.dataTest}
          readOnly={props.readOnly}
          onBlur={() => {
            if (props.value % 1 === 0) {
              props.onChange(`${Math.abs(props.value)}.00`);
            } else {
              props.onChange(Math.abs(props.value))
            }
          }}
        />
        {props.link ?
          <span className="forgot-password-text">
            <Link to={props.link}>{props.linkText} </Link>
          </span>
          :
          null
        }
        
        {props.styles ? 
        (<div style={props.styles}>
          <span>{props.error}</span>
        </div>)
        : 
        (<div className={classes.error}>
        <span>{props.error}</span>
      </div>)
        }
        
      </Grid>
    </Grid>
  )
};
