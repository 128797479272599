const INITIAL_STATE = {
    newService: []
}

export default function (state=INITIAL_STATE, action) {
    switch (action.type) {
        case "PROPOSE_NEW_SERVICE":
            return {
                ...state,
                newService: [...state.newService, action.payload]
            }
        default:
            return state
    }
}