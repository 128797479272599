import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
 
  input: {
    display: "none",
  },

  lookLikeButton: {

    backgroundColor: "#FFF",
    border:"1px solid #CDCED9",
    color:"#6B6C7E",
    
    fontFamily: (props) => (props.fontFamily ? props.fontFamily : "Roboto"),
    boxShadow: "none",
    margin: (props) => (props.margin ? props.margin : null),
    borderLeft: props => props.borderLeft ? props.borderLeft : null ,
    borderRight: props => props.borderRight ? props.borderRight : null ,
    borderTop: props => props.borderTop ? props.borderTop : null ,
    borderBottom: props => props.borderBottom ? props.borderTop : null ,
    fontSize: (props) => (props.fontSize ? props.fontSize : "16px"),
    textTransform: "none",
    fontWeight: (props) => (props.fontWeight ? props.fontWeight : null),
    width: (props) => props.width,
    heigth:(props) => props.heigth,
    maxWidth: (props) => props.maxWidth,
    maxHeight: (props) => props.maxHeight,

    "&:hover": {
      backgroundColor: "#F9B233",
      borderColor: "transparent",
      color: "#FFFFFF",
      boxShadow: "none",
    },
  },
}));

export default function FileInput(props) {
  const classes = useStyles(props);

  return (
     <> 
      
      <input
        name={props.name}
        data-test={props.datatest}
        className={classes.input}
        id={props.id}
        type="file"
        accept={props.accept || ".pdf, .doc, .docx, .jpg, .png, .jpeg, .txt"}
        onChange={props.onChange}
        disabled={props.disabled || false}
      />
      <label htmlFor={props.id} style={{ margin: "0 0 0 0" }}>
      
        <Button
          
          variant="outlined"
          component="span"
          onBlur={props.onBlur}
          classes={{
            outlined: classes.lookLikeButton,
          }}
        >
          
            {props.value.length ? (
              props.value
            ) : (
          "Selecione um documento"
            )}
          
        </Button>
     
      </label>
      <span className="error-input">{props.error}</span>
    </>
  );
}
