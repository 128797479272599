
export async function documentSignature(elem, embedUrl, setSuccess, dispatch, addCountFunction) {
		const widget = new window.LacunaSignerWidget();
		let alreadyIncrement = false
	widget.on(widget.events.documentSigned, function (e) {
				if (!alreadyIncrement)	{
					dispatch(addCountFunction())
					alreadyIncrement = true
				}	
				setSuccess(true);
	});
  
	widget.render(embedUrl, elem);
}