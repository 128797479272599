import React from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Topic from '../../../common/topic';
import SecretariesList from '../common/secretaries/secretariesPage';
import Button from '../../../common/button';
import AddSecretaryModal from './modal/index';

import * as secretaryActions from '../../../common/store/actions/secretaries.action';
import * as asyncActions from '../../../common/store/actions/async.action';
import { useEffect } from 'react';


const useStyles = makeStyles({
    screen: {
        backgroundColor: '#f7f8f9',
        height: 'calc(100vh - 70px)',
        maxHeight: "100%",
        width: "100%",
        padding: '30px',
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    pagination: {
        position: "absolute",
        bottom: "3vh",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        width: "80%",
    },
    pageSelection: {
        width: "40%",
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "center",
    },
    items: {
        marginLeft: "50px",
    },
    scroll: {
        maxHeight: "calc(77vh - 72px)",
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CDCED9',
            outline: '1px solid slategrey',
            borderRadius: '5px'
        }
    },
    hint: {
        display: 'block',
        color: 'rgb(107, 108, 126)',
        fontSize: '16px',
        margin: '10px 0 0 0',
    }
})

const Secretaries =() => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const providerId = window.localStorage.getItem("Prov-Vida-Connect");

    function loadListOfSecretaries() {
        const currentEstablishment = window.localStorage.getItem('estabName')
        const establishmentId = window.localStorage.getItem("establishmentId")
        const employeeId = window.localStorage.getItem("employeeId")
        if (currentEstablishment === "Particular"){
            dispatch(asyncActions.getSecretariesList(providerId));
                        
        } else {
            dispatch(asyncActions.getClinicSecretariesList(establishmentId, employeeId));
        }
    }

    useEffect(() => {
        loadListOfSecretaries();
        //eslint-disable-next-line 
    }, [])

    const handleOpenSecretaryModal = () => {
        dispatch(secretaryActions.openAddModal(true));
    }


    return (
        <div className={classes.screen}>
            <AddSecretaryModal/>
            <div className={classes.table}>
                <div className="side-by-side">
                    <Topic
                        title="Secretárias"
                        subtitle={`Lista de todas as secretárias(os) vinculadas a você.`}
                        marginTop="0px"
                        subMarginTop="11px"
                    />
                    <Button 
                        onClick={handleOpenSecretaryModal}
                        width="200px"
                        border="1px solid #CDCED9"
                        backgroundColor="#FFFFFF"
                        color="#6B6C7E"
                    >
                        Cadastrar Secretária
                    </Button>
                </div>
                <span className={classes.hint}>Os itens 'apagados' representam as secretárias inativas ou que ainda não aceitaram seu convite.</span>
                <SecretariesList />
            </div>

        </div>
    );
}

export default Secretaries;
