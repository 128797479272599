import apiGraphql from "../../../services/api";
import * as asyncActions from './async.action'


export function proposeDates (requestId, proposedDate1, proposedDate2) {
  return async (dispatch) => {
    try{
      dispatch(asyncActions.requestSent());
      const res = await apiGraphql({
        data: {
          query:
          `
          mutation 
            {
            proposeDatesToExamAppointmentRequest(
                proposedDate1: "${proposedDate1}",
                proposedDate2: "${proposedDate2}",
                AppointmentRequestId: "${requestId}") 
            
          }
          `
        }
      })
      if(res.data.data !== null) {
       
      } else {
        const error = res.data.errors[0]
        dispatch(
          asyncActions.requestFailure(
            error.message
          )
        )
      }
      dispatch(asyncActions.requestSuccess())
    } catch (error) {
      console.log(error, 'erro appo')
      dispatch(
        asyncActions.requestFailure(
          "Houve um problema ao tentar enviar as datas."
        )
      )
    }
  }
}

export function denyRequest(AppointmentRequestId) {
  return async (dispatch) => {
    try{
      dispatch(asyncActions.requestSent());
      const res = await apiGraphql({
        data: {
          query: `
            mutation {
              denyExamAppointmentRequest( AppointmentRequestId: "${AppointmentRequestId}" ) 
              
            }
          `
        }
      })
      if(res.data.data) {
        console.log('A CONSULTA FOI REALIZAA')
        console.log(res.data)
        await dispatch({
          type: "SET_LAST_CHANGED_MEDICAL_APPOINTMENT",
          payload: res.data.data.denyMedicalAppointmentRequest
        })
      }
      dispatch(asyncActions.requestSuccess())

    } catch (error) {
      console.log('eror deny', error)
      dispatch(
        asyncActions.requestFailure(
          "Houve um problema na hora de pegar a listagem dos médicos. Tente novamente, por favor."
        )
      )
    }
  }
}
