import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format, parseISO, addHours } from 'date-fns'
import { List, ListItem, Chip, Avatar, makeStyles } from '@material-ui/core'

import * as validateTokenActions from '../../../common/store/actions/validateToken.action'
import * as procedureService from '../../../services/procedures'
import maskCpf from '../../../common/mask/cpf'
import ValidateTokenModal from '../modals/validateToken'
import InitProcedureModal from '../modals/initProcedure'
import FinalizeProcedure from '../modals/finalizeProcedure'
import { handlePatientName } from '../../../common/handlePatientName'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '0',
    fontWeight: '400',
    paddingBottom: '60px',
  },
  headers: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingLeft: "2.5%",
    color: "#6B6C7E",
    boxSizing: 'border-box',
  },
  item: {
    height: '64px',
    backgroundColor: "#FFFFFF",
    border: '1px solid #E7E7ED',
    padding: '0',
  },
  combo: {
    display: 'flex',
    width: '100%',
    paddingLeft: '2.5%',
    height: '100%',
    boxSizing: 'border-box',
  },
  line: {
    height: '40px',
    width: "100%",
    margin: "auto",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
  },
  hour: {
    width: '10%',
  },
  patient: {
    width: '40%',
    display: 'flex',
    marginLeft: '30px',
  },
  procedure: {
    width: '30%',
    marginLeft: '30px',
  },
  text: {
    marginLeft: '3%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& > .cpf":{
        color: "#6B6C7E"
    }
  },
  status: {
    width: '20%',
    marginLeft: '30px',
    display: 'flex',
    alignItems: "center",
    justifyContent: 'space-between',
    ".makeStyles-headers-33 > &": {
      justifyContent: "flex-start",
    },
    "& .box": {
        width: '90%',
        // height: '25px',
        // fontSize: '10px',
    },
    "& .PENDING_TRIAGE" : {
        backgroundColor: "#FFF8DE",
        color: "#DF9000",
    },
    "& .PENDING_HEALTH_OPERATOR" : {
      backgroundColor: "#FFF8DE",
      color: "#DF9000",
    },
    "& .OK" : {
        backgroundColor: "#EEF2FA",
        color: "#2E5AAC",
    },
    "& .PENDING_TOKEN_VALIDATION" : {
        backgroundColor: "#F7F8F9",
        color: "#6B6C7E",
    },
    "& .VALIDATED_TOKEN" : {
      backgroundColor: "#EEF2FA",
      color: "#2E5AAC",
  },
    "& .REJECTED_TRIAGE" : {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .REJECTED_HEALTH_OPERATOR" : {
      backgroundColor: "#FEEFEF",
      color: "#DA1414",
    },
    "& .START_CONSULTATION": {
      backgroundColor: "#EDF9F0",
      color: "#287D3C",
    },
    "& .PENDING_PAYMENT": {
      backgroundColor: "#FFF4EC",
      color: "#B95000",
    },
    "& .ON_PROGRESS": {
      backgroundColor: "#FFF4EC",
      color: "#B95000",
    }
  },
}))

const RenderChip = ({status}) => {

  const statusConversion = {
    OK: 'Token validado',
    PENDING_PAYMENT: 'Aguardando pagamento',
    PENDING_TOKEN_VALIDATION: 'Aguardando paciente',
    ON_PROGRESS: 'Em atendimento'
  }

  console.log(status)

  return(
    <Chip
      size='small'
      label={statusConversion[status]}
      className={`${status} box`}
    />
  )
}

export const Procedures = ({ date, page, limit, setCount }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const userType = window.localStorage.getItem("userType");
  const employeeId = window.localStorage.getItem('employeeId')
  const doctorsList = useSelector(state => state.SecretariesReducer.doctors)

  const [ listProcedures, setListProcedures ] = useState([])
  const [ selectedProcedure, setSelectedProcedure ] = useState()

  const [ openInitProcedureModal, setOpenInitProcedureModal ] = useState(false)
  const [ openFinalizeProcedureModal, setOpenFinalizeProcedureModal ] = useState(false)

  const loadProcedures = async () => {
    const procedures = await procedureService.getProceduresDaily(date, null, page, limit, employeeId, doctorsList.map(doctor => doctor.provider.id))

    if(!procedures.error) {
      setCount(procedures.count)
      setListProcedures(procedures.rows)
    } else {
      console.log(procedures)
    }
  }

  const selectProcedure = (procedure) => {
    if (userType === 'PROVIDER_SECRETARY' && procedure.status !== 'PENDING_TOKEN_VALIDATION') return
    setSelectedProcedure(procedure)
    switch (procedure.status) {
      case 'PENDING_TOKEN_VALIDATION':
        dispatch(validateTokenActions.openModal(true))
        break;

      case 'OK':
        setOpenInitProcedureModal(true)        
        break;
      
      case 'ON_PROGRESS':
        setOpenFinalizeProcedureModal(true)
        break

      default:
        break;
    }
  }

  useEffect(() => {
    if (!(userType === 'PROVIDER_SECRETARY' && doctorsList.length === 0)) {
      loadProcedures()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, date, doctorsList, userType])

  return(
    <>
      <List className={classes.root}>
        <div className={classes.headers}>  
          <div className={classes.hour}>  
            Hora
          </div>
          <div className={classes.patient}>  
            Paciente
          </div>
          <div className={classes.procedure}>
            Procedimento
          </div>
          <div className={classes.status}>
            Status
          </div>
        </div>

        { listProcedures.map((procedure, index) => {
          const hour = format(addHours(parseISO(procedure.dateTime), 3), 'HH:mm')

          return(
            <ListItem
              className={classes.item}
              key={procedure.id}
              onClick={() => selectProcedure(procedure)}
              dense
              button
            >
              <div className={classes.combo}>
                <div className={classes.line}>
                <div className={classes.hour}>
                  { hour }
                </div>
                  
                <div className={classes.patient}>
                  <Avatar/>
                  <div className={classes.text}>
                    <span>{handlePatientName(procedure.medicalAppointment.patient)} </span>
                    <span>
                      { maskCpf(procedure.medicalAppointment.patient.user.cpf) }
                    </span>    
                  </div>
                </div>

                <div className={classes.procedure}>
                  { procedure.serviceProvided.description }
                </div>                

                <div className={classes.status}>
                    <div className="box" > 
                      <RenderChip
                        status={procedure.status}
                      />
                    </div>
                </div>
              </div>
              </div>
            </ListItem>
          )
          })
        }
      </List>

      <ValidateTokenModal
        procedure={selectedProcedure}
        reload={loadProcedures}
      />

      <InitProcedureModal
        procedure={selectedProcedure}
        reload={loadProcedures}
        open={openInitProcedureModal}
        handleClose={() => setOpenInitProcedureModal(false)}
      />

      <FinalizeProcedure 
        procedure={selectedProcedure}
        reload={loadProcedures}
        open={openFinalizeProcedureModal}
        handleClose={() => setOpenFinalizeProcedureModal(false)}
      />

    </>
  )
}
