import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';

import VideoCall from '../../../common/videoCall';
import TextArea from '../../../common/textarea';
import Button from '../../../common/button';
import ReferralModal from '../modals/referralModal';
import SurgicalProcedureModal from '../modals/surgicalProcedureModal';
import SurgicalProcedureRegistered from '../../../common/surgicalProcedureRegistered';
import SurgicalProcedureBudget from '../../../common/surgicalProcedureBudget';
import MedicalReportModal from '../modals/medicalReportModal';
import SickNoteModal from '../modals/sickNoteModal';
import ExamRequestModal from '../modals/examRequestModal';
import ExamRequestInsidePlatformModal from '../modals/examRequestInsidePlatformModal';
import PrescriptionModal from '../modals/prescriptionModal';
import DoctorPrescriptionSpecialModal from '../modals/doctorPrescriptionSpecial'
import DoctorPrescriptionAntimicrobials from '../modals/doctorPrescriptionAntimicrobials'
import ComplexList from '../../../common/itemList/listForMultipleFields';
import { format, parseISO } from 'date-fns'
import * as registerExams from "../../../common/store/actions/registerExams";
import * as consultationActions from '../../../common/store/actions/consultation.action';
import * as consultationAsyncActions from '../../../common/store/actions/consultation.async.action'
const useStyles = makeStyles({
    splittedScreen: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        gridArea: "file",
        marginTop: '1.4%',
        height: 'calc(100vh - 182px)',
        overflowY: 'auto',
        overflowX: 'hidden',

    },
    video: {
        width: '55%',
    },
    file: {
        width: (props) => props.appointmentLocation === 'ONLINE' ? '40%' : '100%',
        marginLeft: '10px'
    },
    time: {
        fontSize: "14px",
        color: "#DF9000"
    },
    section: {
        marginBottom: "30px",
        "& > h1": {
            fontSize: '20px',
            color: "#272833",
            margin: '0',
        },
        "& > p": {
            color: "#6B6C7E",
            fontSize: '14px',
        }
    },
    selectedSpeciality: {
        fontSize: "20px",
        fontWeight: '600',
        color: "#272833",
        marginBottom: '30px',
    },
    topic: {
        marginBottom: "30px",
        fontSize: '16px',
        "& > .title": {
            fontWeight: '600',
        },
        "& > p": {
            lineHeight: '20px',
            color: "#6B6C7E",
            marginTop: '5px'
        }
    },
    middle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '95%',
        margin: "auto",
    },
    retorno: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "flex-start",
        marginTop: '20px',
        "& > div": {
            width: '30%'
        }
    }
})

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -15,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

const Consultation = ({ records, selectedRecord, appointmentLocation, ...props }) => {
    const classes = useStyles({ appointmentLocation });
    const dispatch = useDispatch();

    const consultationData = useSelector((state) => state.ConsultationReducer.consultationData);

    const [clinicalHistory, setClinicalHistory] = useState(consultationData?.clinicalHistory || "");
    const [physicalExam, setPhysicalExam] = useState(consultationData?.physicalExam || "");
    const [diagnosticHypothesis, setDiagnosticHypothesis] = useState(consultationData?.diagnosticHypothesis || "");
    const [treatment, setTreatment] = useState(consultationData?.treatment || "");
    const [surgicalProcedureBudgetModal, setSurgicalProcedureBudgetModal] = useState("");

    const countPrescription = useSelector((state) => state.ConsultationReducer.countPrescription);
    const countDoctorPrescriptionAntimicrobials = useSelector((state) => state.ConsultationReducer.countDoctorPrescriptionAntimicrobials);
    const countDoctorPrescriptionSpecial = useSelector((state) => state.ConsultationReducer.countDoctorPrescriptionSpecial);
    const countSickNote = useSelector((state) => state.ConsultationReducer.countSickNote);
    const countExamRequest = useSelector((state) => state.ConsultationReducer.countExamRequest);
    const countMedicalReport = useSelector((state) => state.ConsultationReducer.countMedicalReport);
    const surgicalProcedures = useSelector((state) => state.ConsultationReducer.surgicalProcedure);
    const exams = useSelector((state) => state.Exams.exams);
    let examsRequests = useSelector((state) => state.ConsultationReducer.consultationData.examsRequests) || [];
    const placeholder = (title) => {
        return (
            <div className={classes.middle}>
                <div>
                    <span>{title}</span>
                </div>
                <AddIcon />
            </div>
        )
    }

    const setConsultationData = (data) => {
        dispatch(consultationActions.setConsultationData(data))
    };

    useEffect(() => {
        dispatch(consultationActions.requestSuccess(false));
        const appointmentId = window.localStorage.getItem('appointId')
        const estabName = window.localStorage.getItem('estabName')
        const isVirtuaAppointment = estabName === 'Virtua'
        dispatch(consultationAsyncActions.getLocalData(appointmentId, isVirtuaAppointment))
        // eslint-disable-next-line
    }, [])

    const handleOpenSurgicalProcedureBudgetModal = (row) => {
        setSurgicalProcedureBudgetModal(row)
    }

    const onSaveOrFinishSurgicalProcedureBudget = budgetData => {
        const updateSurgicalProcedures = surgicalProcedures.map(surgicalProcedure => {
            if (surgicalProcedure?.surgicalProcedure?.code === budgetData?.procedure?.code) {
                surgicalProcedure.surgicalProcedure.budget = budgetData
                setSurgicalProcedureBudgetModal({
                    ...surgicalProcedureBudgetModal,
                    procedureName: budgetData.procedure.name,
                    procedureCode: budgetData.procedure.code
                })
            }
            return surgicalProcedure
        })
        dispatch(consultationActions.updateSurgicalProcedure(updateSurgicalProcedures));
    }

    const onCloseSurgicalProcedureBudget = () => setSurgicalProcedureBudgetModal({ ...surgicalProcedureBudgetModal, open: false })

    const convertArrayToObjectByIndex = array => array.reduce((obj, item, index) => ({ ...obj, [index]: item }), {})

    const removeItemFromArrayByIndex = (array, index) => {
        const arrayToObject = convertArrayToObjectByIndex(array)
        delete arrayToObject[index]
        return Object.keys(arrayToObject).map(key => arrayToObject[key])
    }

    const handleOnDeleteSurgicalProcedureFromList = (index) => {
        const arrayWithItemDeleted = removeItemFromArrayByIndex(surgicalProcedures, index)
        dispatch(consultationActions.updateSurgicalProcedure(arrayWithItemDeleted))
    }
    const deleteExam = (index, value) => {
        dispatch(registerExams.deleteExam(index));
        dispatch(consultationActions.deleteConsultationExam(index))
        const arrayWithItemDeleted = removeItemFromArrayByIndex(examsRequests, index)
        dispatch(consultationActions.setConsultationData({examsRequests: arrayWithItemDeleted}))
        
      }

    return (
        <div className={classes.splittedScreen}>
            {appointmentLocation === 'ONLINE' && (
                <div className={classes.video}>
                    <VideoCall
                        Room={props.Room}
                        setRoom={props.setRoom} />

                </div>
            )}
            <div className={classes.file}>
                <div className={classes.section}>
                    <h1>Histórico Clínico Vida Connect</h1>
                    <p>O histórico dessa consulta é de propriedade do paciente e caso seja autorizado
                        pelo mesmo, poderá ser exibida para outros profissionais.</p>
                </div>
                <div>
                    <span className={classes.time}>{records[selectedRecord]?.date ? format(parseISO(records[selectedRecord].date), 'dd/MM/yyyy') : '-'}</span>
                    <h2 className={classes.selectedSpeciality}>{records[selectedRecord].speciality.name}</h2>
                </div>
                <div className={classes.topic}>
                    <span className="title">Exame Clínico 1: (Anotação Compartilhada com o paciente)</span>
                    <TextArea
                        width="95%"
                        onChange={setClinicalHistory}
                        onBlur={() => setConsultationData({ clinicalHistory })}
                        value={clinicalHistory}
                        dataTest="textarea-medical-history"
                        placeholder="Escreva aqui as anotações que você deseja compartilhar com o paciente."
                        rows={3}
                    />
                </div>
                <div className={classes.topic}>
                    <span className="title">Exame Clínico 2: (Anotação NÃO compartilhada com o paciente)</span>
                    <TextArea
                        width="95%"
                        onChange={setPhysicalExam}
                        onBlur={() => setConsultationData({ physicalExam })}
                        value={physicalExam}
                        dataTest="textarea-medical-history"
                        placeholder="Escreva aqui as anotações que você NÃO deseja compartilhar com o paciente."
                        rows={3}
                    />
                </div>
                <div className={classes.topic}>
                    <span className="title">Hipótese Diagnóstica</span>
                    <TextArea
                        width="95%"
                        onChange={setDiagnosticHypothesis}
                        onBlur={() => setConsultationData({ diagnosticHypothesis })}
                        value={diagnosticHypothesis}
                        dataTest="textarea-medical-history"
                        placeholder="Escreva aqui o diagnóstico do paciente."
                        rows={3}
                    />
                </div>
                <div className={classes.topic}>
                    <span className="title">Tratamento</span>
                    <TextArea
                        width="95%"
                        onChange={setTreatment}
                        onBlur={() => setConsultationData({ treatment })}
                        value={treatment}
                        dataTest="textarea-medical-history"
                        placeholder="Escreva aqui o tratamento prescrito para o paciente."
                        rows={3}
                    />
                </div>
                <div className={classes.section}>
                    <StyledBadge component="h1" badgeContent={countPrescription} color="error">
                        Receituário Simples
                    </StyledBadge>
                    <p>Clique para prescrever as receitas dos medicamentos ao paciente: </p>
                    <Button
                        onClick={() => dispatch(consultationActions.openPrescriptionModal(true))}
                        backgroundColor="#F1F2F5"
                        fontSize="14px"
                        width="24vw"
                    >
                        {placeholder("Adicionar receita")}
                    </Button>
                    <PrescriptionModal />
                </div>
                <div className={classes.section}>
                    <StyledBadge component="h1" badgeContent={countDoctorPrescriptionSpecial} color="error">
                        Receituário de controle especial
                    </StyledBadge>
                    <p>Clique para adicionar o receituário de controle especial</p>
                    <Button
                        onClick={() => dispatch(consultationActions.openDoctorPrescriptionSpecial(true))}
                        backgroundColor="#F1F2F5"
                        fontSize="14px"
                        width="24vw"
                    >
                        {placeholder("Adicionar receituário de controle especial")}
                    </Button>
                    <DoctorPrescriptionSpecialModal />
                </div>
                <div className={classes.section}>
                    <StyledBadge component="h1" badgeContent={countDoctorPrescriptionAntimicrobials} color="error">
                        Receituário antimicrobianos
                    </StyledBadge>
                    <p>Clique para adicionar o Receituário antimicrobianos</p>
                    <Button
                        onClick={() => dispatch(consultationActions.openDoctorPrescriptionAntimicrobials(true))}
                        backgroundColor="#F1F2F5"
                        fontSize="14px"
                        width="24vw"
                    >
                        {placeholder("Adicionar Receituário antimicrobianos")}
                    </Button>
                    <DoctorPrescriptionAntimicrobials />
                </div>
                <div className={classes.section}>
                    <StyledBadge component="h1" badgeContent={countSickNote} color="error">
                        Atestado médico
                    </StyledBadge>
                    <p>Clique para fornecer um atestado médico ao paciente: </p>
                    <Button
                        onClick={() => dispatch(consultationActions.openSickNoteModal(true))}
                        backgroundColor="#F1F2F5"
                        fontSize="14px"
                        width="24vw"
                    >
                        {placeholder("Adicionar atestado médico")}
                    </Button>
                    <SickNoteModal />
                </div>
                <div className={classes.section}>
                    <StyledBadge component="h1" badgeContent={countExamRequest} color="error">
                        Solicitar Exames
                    </StyledBadge>
                    <p>Solicite exames para o paciente diretamento pelo Vida Connect,
                        basta adicionar os exames abaixo: </p>
                    <Button
                        onClick={() => dispatch(consultationActions.openExamRequestModal(true))}
                        backgroundColor="#F1F2F5"
                        fontSize="14px"
                        width="24vw"
                    >
                        {placeholder("Adicionar pedido de exame")}
                    </Button>
                    <ExamRequestModal />
                </div>
                <div className={classes.section}>
                    <StyledBadge component="h1" badgeContent={exams.length} color="error">
                        Solicitar Exames pela plataforma
                    </StyledBadge>
                    <p>Solicite exames para o paciente diretamento pelo Vida Connect,
                        basta adicionar os exames abaixo: </p>
                    <Button
                        onClick={() => dispatch(consultationActions.openExamRegisteredRequestModal(true))}
                        backgroundColor="#F1F2F5"
                        fontSize="14px"
                        width="24vw"
                    >
                        {placeholder("Adicionar pedido de exame")}
                    </Button>
                    <ExamRequestInsidePlatformModal />
                    
                        {exams.length > 0 && <ComplexList 
                        fullList={exams} 
                        firstHeader={"Código"}
                        secondHeader={"Descrição"}
                        firstParam={"examCode"}
                        secondParam={"name"}
                        
                        secondColumnWidth="100%"
                        //thirdColumnWidth="60%"
                       
                        deleteItem={deleteExam}
                        />
                        
                    }
                    
                </div>
                
                
                
                <div className={classes.section}>
                    <StyledBadge component="h1" badgeContent={countMedicalReport} color="error">
                        Relatório médico
                    </StyledBadge>
                    <p>Envie um relatório com o conteúdo da consulta para o paciente: </p>
                    <Button
                        onClick={() => dispatch(consultationActions.openMedicalReportModal(true))}
                        backgroundColor="#F1F2F5"
                        fontSize="14px"
                        width="24vw"
                    >
                        {placeholder("Adicionar relatório")}
                    </Button>
                    <MedicalReportModal />
                </div>
                <div className={classes.section}>
                    <h1>Solicitar Procedimento</h1>
                    <p>Solicite procedimentos para o paciente diretamente pelo Vida Connect,
                        basta adiciona os procedimentos abaixo: </p>
                    <Button
                        onClick={() => dispatch(consultationActions.openSurgicalProcedureModal(true))}
                        backgroundColor="#F1F2F5"
                        fontSize="14px"
                        width="24vw"
                    >
                        {placeholder("Adicionar procedimento")}
                    </Button>
                    {Boolean(surgicalProcedures.length) &&
                        <SurgicalProcedureRegistered
                            openBudget={handleOpenSurgicalProcedureBudgetModal}
                            onDelete={handleOnDeleteSurgicalProcedureFromList}
                        />
                    }
                    <SurgicalProcedureModal onClose={({ procedureCode, procedureName }) => setSurgicalProcedureBudgetModal({ ...surgicalProcedureBudgetModal, procedureCode, procedureName, open: true })} />
                </div>
                <div className={classes.section}>
                    <h1>Encaminhamento</h1>
                    <p>Faça o encaminhamento para outros profissionais diretamente pelo vida Connect,
                        basta adiciona-los abaixo:</p>
                    <Button
                        onClick={() => dispatch(consultationActions.openReferralModal(true))}
                        backgroundColor="#F1F2F5"
                        fontSize="14px"
                        width="24vw"
                    >
                        {placeholder("Adicionar encaminhamento")}
                    </Button>
                    <ReferralModal />
                </div>
            </div>
            <SurgicalProcedureBudget
                procedureName={surgicalProcedureBudgetModal.procedureName}
                procedureCode={surgicalProcedureBudgetModal.procedureCode}
                open={surgicalProcedureBudgetModal.open}
                budget={surgicalProcedureBudgetModal.budget || null}
                onSave={onSaveOrFinishSurgicalProcedureBudget}
                onFinish={onSaveOrFinishSurgicalProcedureBudget}
                onClose={onCloseSurgicalProcedureBudget}
            />
        </div>
    )
};

export default Consultation;
