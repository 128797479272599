import React, { useMemo } from 'react'

import procedureStatus from '../../../common/enums/procedureStatus'

export default (props) => {
  const status = useMemo(() => props.budget.status, [props])

  const statusMap = {
    [procedureStatus.BUDGET_SAVED]: 'Pagamento ainda não disponível para o paciente',
    [procedureStatus.AWAITING_BUDGET]: 'Pagamento ainda não disponível para o paciente',
    [procedureStatus.CHOOSE_A_BUDGET]: 'Pagamento ainda não disponível para o paciente',
    [procedureStatus.AWAITING_DATE]: 'Pagamento ainda não disponível para o paciente',
    [procedureStatus.CONFIRM_SCHEDULE]: 'Pagamento ainda não disponível para o paciente',
    [procedureStatus.PENDING_PAYMENT]: 'Pendente Pagamento',
    [procedureStatus.OK]: 'Pagamento realizado',
    [procedureStatus.PENDING_TOKEN_VALIDATION]: 'Pagamento realizado',
  }

  return (
    <p>{statusMap[status] || 'Pagamento ainda não disponível para o paciente'}</p>
  )
}
