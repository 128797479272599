import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../common/loading";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";

import sidebar from "../../assets/sidebar.png";
import Input from "../../common/input";
import InputPassword from "../../common/input-password";
import Topic from "../../common/topic";
import Button from "../../common/button";

import { clickOnEnter } from "../../utils/keyboard/onClickWithEnter";
import * as asyncActions from "../../common/store/actions/async.action";

const useStyles = makeStyles({
  screen: {
    display: "grid",
    gridTemplateColumns: "minmax(200px, 1.2fr) 4fr",
    gridGap: "4vw",
    height: "100vh",
    minWidth: "1000px",
  },
  sideBar: {
    height: "100%",
    maxHeight: "100vh",
    "& > img": {
      height: "100%",
      width: "100%",
      objectFit: "fill",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  notPartner: {
    marginTop: "24px",
  },
});

export default (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.AsyncReducer.loading);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const msgError = useSelector((state) => state.AsyncReducer.error);
  const history = useHistory();
  // const autoLogin = () => {
  //   history.push("/administrador");
  // }

  // useEffect( () => {
  //   dispatch(loginActions.checkToken(props.history, "/administrador", autoLogin))
  // },[])

  useEffect(() => {
    dispatch(asyncActions.requestSent());
    const userStatus = localStorage.getItem("status");
    dispatch(asyncActions.checkToken(history, "/", userStatus));

    //eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    dispatch(asyncActions.login(email, password, history));
  };

  useEffect(() => {
    clickOnEnter();
  });

  if (loading) {
    return <Loading width="80px" />;
  }

  return (
    <div className={classes.screen}>
      <Hidden smDown>
        <div className={classes.sideBar}>
          <img src={sidebar} alt="Sidebar Vida Connect" />
        </div>
      </Hidden>
      <div className={classes.root}>
        <Topic
          title="Entre"
          subtitle="Bem-Vindo parceiro Vida Connect."
          fontSize="32px"
          subFontSize="18px"
          marginTop="0px"
          subMarginTop="6px"
        />

        <Grid container>
          <form>
            <Grid item className="space-above-item-less">
              <Input
                dataTest="input-login-email"
                label="Email"
                disabled={loading}
                width="360px"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item className="space-above-item-less">
              <InputPassword
                name="input-password-login"
                datatest="input-password-login"
                label="Senha"
                disabled={loading}
                width="360px"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={msgError}
                linkText="ESQUECI MINHA SENHA"
                link={`${process.env.REACT_APP_SIGNUP}/reset-password?type=provider`}
              />
            </Grid>
            <Grid item className="space-above-item">
              <Button
                type="submit"
                width="170px"
                height="45px"
                onClick={handleSubmit}
                disabled={loading}
                dataTest="button-login-entrar"
              >
                Entrar
              </Button>
            </Grid>
            {loading ? (
              <Grid item className="space-above-item-less">
                <Loading width="80px" />
              </Grid>
            ) : (
              <Grid item className={classes.notPartner}>
                <a
                  className="grey-text"
                  href={`${process.env.REACT_APP_SIGNUP}`}
                  data-test="link-login-ainda-nao-e-parceiro"
                >
                  Ainda não é um parceiro? Clique aqui e faça parte do Vida
                  Connect.
                </a>
              </Grid>
            )}
          </form>
        </Grid>
      </div>
    </div>
  );
};
