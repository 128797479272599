import React, { memo } from "react";

import Topic from "../../../../common/topic";
import InputFile from "../../../../common/fileInput";

import inputFileValidator, { validationFileType } from '../../../../common/validators/fileInput'

const FileInput = (props) => {

  const onDelete = (clearFile) => {
    clearFile({ name: "" })
  }

  const handleChangeInputFile = (value, onChange, typesAccepted) => {
    if (!value) return onChange({name: ''})
  
    const typeIsAccpted =  validationFileType(value.type,typesAccepted)
    if (!typeIsAccpted) {
      onChange({name: ''})
      props.setOpenSyncSnackBar(true)
      props.setErrorMessageSyncSnackBar('O tipo do arquivo selecionado não é aceito para esse campo!')
      return
    } 
    return onChange(value)
  }

  const typesAcceptedProfessionalDocument = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']
  const typesAcceptedProofOfResidence = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']
  const typesAcceptedCertificate = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']

  return (
    <React.Fragment>
      <div>
        <Topic
          classesNameCol="topic-datas middle-topics"
          title="Anexos"
        />
      </div>

      <InputFile
        id="professionalDocument"
        width="40%"
        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .txt"
        label="1.Identidade profissional"
        subtitle="Formatos aceitos: png, jpg, jpeg, pdf, doc, docx e txt. Tamanho máximo: 25MB"
        onChange={(e) =>
          handleChangeInputFile(e.target.files[0], props.setProfessionalDocument, typesAcceptedProfessionalDocument )
        }
        value={props.professionalDocument.name}
        onBlur={() =>
          props.setProfessionalDocumentError(inputFileValidator(props.professionalDocument, 25))
        }
        onDelete={() => onDelete(props.setProfessionalDocument)}
        error={props.professionalDocumentError.typeError}
        dataTest="input-pf-identidade-profissional"
      />

      <InputFile
        id="proofOfResidence"
        width="40%"
        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .txt"
        label="2.Comprovante de endereço."
        subtitle="Formatos aceitos: png, jpg, jpeg, pdf, doc, docx e txt. Tamanho máximo: 25MB"
        onChange={(e) =>
          handleChangeInputFile(e.target.files[0], props.setProofOfResidence, typesAcceptedProofOfResidence )
        }
        value={props.proofOfResidence.name}
        onBlur={() =>
          props.setProofOfResidenceError(inputFileValidator(props.proofOfResidence, 25))
        }
        onDelete={() => onDelete(props.setProofOfResidence)}
        error={props.proofOfResidenceError.typeError}
        dataTest="input-pf-comprovante-endereco"
      />

      { props.professionalType === 'others' &&
        <InputFile
          id="certificate"
          width="40%"
          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .txt"
          label="3.Diploma."
          subtitle="Formatos aceitos: png, jpg, jpeg, pdf, doc, docx e txt. Tamanho máximo: 25MB"
          onChange={(e) =>
            handleChangeInputFile(e.target.files[0], props.setCertificate, typesAcceptedCertificate )
          }
          value={props.certificate.name}
          onBlur={() =>
            props.setCertificateError(inputFileValidator(props.certificate, 25))
          }
          onDelete={() => onDelete(props.setCertificate)}
          error={props.certificateError.typeError}
          dataTest="input-pf-diploma"
        />
      }
    </React.Fragment>
  );
};

export default memo(FileInput);
