import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'

import { ProfessionalType } from './professionalType'
import PersonalDatas from "./personalDatas";
import Curriculum from "./curriculum";
import RegisterAddress from "./registerAddress";
import VidaConnectAgreementTerms from './vidaConnectAgreementTerms';
import FinalPage from './finalPage';

import * as consultation from "../../../common/store/actions/registerConsultations"
import * as examActions from "../../../common/store/actions/registerExams"
import * as proceedingAction from "../../../common/store/actions/registerProceeding"
import * as otherServicesActions from "../../../common/store/actions/registerOtherServices"
import * as personalDataActions from "../../../common/store/actions/personalDatas"

import "./style.css";

const steps = ['', '', '', '']

const Pf = () => {
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = useState(0);
  const [ professionalType, setProfessionalType ] = useState()

  const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    dispatch(consultation.deleteAllConsultations());
    dispatch(examActions.deleteAllExams());
    dispatch(proceedingAction.deleteAllProceedings());
    dispatch(otherServicesActions.deleteAllOtherServices());
    dispatch(personalDataActions.clearPersonalData());
    //eslint-disable-next-line
  }, [professionalType])

  const whatToRender = () => {
    switch (activeStep) {
      case 1:
        return (
          <PersonalDatas
            professionalType={professionalType}
            steps={steps}
            previousStep={handleBack}
            nextStep={handleNext}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
          <Curriculum
            steps={steps}
            nextStep={handleNext}
            previousStep={handleBack}
            activeStep={activeStep}
          />
        );
      case 3:
        return (
          <RegisterAddress
            professionalType={professionalType}
            steps={steps}
            nextStep={handleNext}
            previousStep={handleBack}
            activeStep={activeStep}
          />
        )
      case 4:
        return (
          <VidaConnectAgreementTerms
            professionalType={professionalType}
            steps={steps}
            nextStep={handleNext}
            previousStep={handleBack}
          />
        )
      case 5:
        return (
          <FinalPage />
        )
      default:
        return (
          <ProfessionalType 
            type={professionalType}
            setType={setProfessionalType}
            steps={steps}
            activeStep={activeStep}
            nextStep={handleNext}
          />
        )
       
    }
  }


  return (
    whatToRender()
  )

};

export default Pf;
