import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonCheckedIcon from '../../assets/RadioButton_On.png';
import { Icon } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '180px',
    marginTop: '14px',
    padding: 0,
  },
  listItem: {
    border: '1px solid #e7e7ed',
    borderRadius: '4px',
    height: '40px',
    margin: "10px 0",
    padding: '0 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icons: {
    minWidth: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderRight: '1px solid #E7E7ED',
    marginRight: '16px'
  },
  icon:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: "16px",
  },
  checkbox: {
    padding: '0 !important',
    margin: '0 !important',
  },
  selected: {
      color: "#F9B233 !important"
  }
}));

export default function CheckboxList(props) {
  const classes = useStyles();

  const handleToggle = (value) => () => {
    if(props.selected.length > 0) {
      const alreadySelected = props.selected.reduce((acc, day) => {
        return acc || day.day === value.day
      }, false);
      if (alreadySelected) {
        const filteredArray = props.selected.filter( (day) => {
          return day.day !== value.day
        });
        props.setDays(filteredArray)
      } else {
        props.setDays((prevState) => [...prevState, value]);
      }
      
    } else {
      props.setDays([value]);
    }
  };

  return (
    <List className={classes.root}>
      {props.selectItems.map((value) => {
        const labelId = `checkbox-list-label-${value.day}`;

        return (
          <ListItem 
            key={value.day} 
            classes={{
                root:classes.listItem,
            }} 
            role={undefined} dense button 
            onClick={handleToggle(value)}
          >
            <ListItemIcon classes={{root: classes.icons}}>
              <Checkbox
                edge="start"
                checked={
                  props.selected.length > 0 ?
                    props.selected.reduce((acc, currValue) => {
                      return acc || currValue.day === value.day
                    }, false)
                    :
                    false
                }
                icon={<RadioButtonUncheckedIcon classes={{root: classes.icon}}/>}
                checkedIcon={<Icon classes={{root: classes.icon}}><img src={RadioButtonCheckedIcon} alt="checked"/></Icon>}
                classes={{
                    checked: classes.selected,
                    root: classes.checkbox,
                }}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value.day} />
          </ListItem>
        );
      })}
    </List>
  );
}
